import React, { useEffect } from 'react';
import { themeActions, ThemeType } from '@/Redux/Theme/reducer';
import styles from './ThemeSwitcher.scss';
import { TopMenuIcons } from '@/Static/IconsRes';
import { useDispatch, useSelector } from 'react-redux';
import { themeSelectors } from '@/Redux/Theme/selectors';

export function ThemeSwitcher() {
  const dispatch = useDispatch();
  const currentTheme = useSelector(themeSelectors.currentTheme);

  const toggleTheme = () => {
    dispatch(themeActions.toggleTheme());
    window.location.reload();
  };

  useEffect(() => {
    if (currentTheme == ThemeType.Dark) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      import('devextreme/dist/css/dx.dark.css');
      document.body.classList.add(currentTheme);
    }
  }, []);

  return (
    <img
      className={styles.themeSwitcher}
      src={currentTheme == ThemeType.Light ? TopMenuIcons.Sun : TopMenuIcons.Moon}
      alt="theme"
      onClick={toggleTheme}
    />
  );
}
