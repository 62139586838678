import { rest } from 'src/Api/Rest';
import { ApiRoutingUtils } from 'src/Routing';
import { ScanBarcodeInternalRequest } from 'src/Api/UserWarehouse/Transfers/Internal/Requests/ScanBarcodeInternalRequest';
import { ScanBarcodeInternalResponse } from 'src/Api/UserWarehouse/Transfers/Internal/Responses/ScanBarcodeInternalResponse';
import { GetInternalTransferBatchResponse } from 'src/Api/UserWarehouse/Transfers/Internal/Responses/GetInternalTransferBatchResponse';
import { GetInternalTransferBatchRequest } from 'src/Api/UserWarehouse/Transfers/Internal/Requests/GetInternalTransferBatchRequest';

const transferUrl = ApiRoutingUtils.createUrl('user-warehouse-internal-transfer');

async function scanBarcode(request: ScanBarcodeInternalRequest): Promise<ScanBarcodeInternalResponse> {
  return rest.post(`${transferUrl}/scan-barcode`, request);
}

async function getBatch(request: GetInternalTransferBatchRequest): Promise<GetInternalTransferBatchResponse> {
  return rest.get(transferUrl, request);
}

export const WarehouseInternalTransferApi = {
  scanBarcode,
  getBatch,
};
