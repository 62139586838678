import React from 'react';
import { useLocalization } from '@/Hooks';
import { useForm } from '@/Hooks/useFormWrapper';
import styles from './AdditionalInfo.scss';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { OrderTableRow } from '@/Types';
import { salesAdditionalInfoSelectors } from './services/selectors';
import { Label } from '@/Components/Controls/Label/Label';
import { FormCheckbox } from '@/Components/Controls/Checkbox/FormCheckbox';
import { dateTimeUtils } from '@/Utils';
import { FormValue } from '@/Components/Controls/FormValue/FormValue';
import { FormMultiLineValue } from '@/Components/Controls/FormMultiLineValue/FormMultiLineValue';

type FormData = Pick<
  OrderTableRow,
  | 'saleFormat'
  | 'paymentMethod'
  | 'shippingMethodDescription'
  | 'purchaseNet'
  | 'marketplaceFee'
  | 'promotionFee'
  | 'mock'
  | 'shippingPrice'
  | 'mpCreatedTime'
  | 'mpLastModifiedTime'
  | 'synchTime'
>;

export const AdditionalInfo: React.FC = () => {
  const { register } = useForm<FormData>();
  const selectedPurchase = useSelector(purchasesPageSelectors.selectedPurchase);
  const totalPurchaseNet = useSelector(salesAdditionalInfoSelectors.totalPurchaseNet);

  const {
    salesPage: {
      AdditionalInfo: {
        SalesFormat,
        PaymentMethod,
        OriginalShippingMethod,
        PurchaseNet,
        MarketplaceFee,
        PromotionFee,
        PaymentFee,
        Shipping,
        Profit,
        WarehouseId,
        Sku,
        Archived,
        Exported,
        Canceled,
        LastModifiedDate,
        LastSynchDate,
      },
    },
  } = useLocalization();

  const warehousePlace = selectedPurchase?.orderItems
    .map((i) => i.warehousePlace)
    .filter((i) => i?.length > 0)
    .join(', ');
  const sku = selectedPurchase?.orderItems
    .map((i) => i.sku)
    .filter((i) => i?.length > 0)
    .join(', ');

  return (
    <section className={styles.form}>
      <section className={styles.form__section}>
        <Label text={SalesFormat} className={styles.form__label}>
          <FormValue value={selectedPurchase?.saleFormat} />
        </Label>
        <Label text={PaymentMethod} className={styles.form__label}>
          <FormValue value={selectedPurchase?.paymentMethod} />
        </Label>
        <Label text={OriginalShippingMethod} className={styles.form__label}>
          <FormValue value={selectedPurchase?.shippingMethodDescription} />
        </Label>
      </section>

      <section className={styles.form__section}>
        <Label text={PurchaseNet} className={styles.form__label}>
          <FormValue value={totalPurchaseNet} />
        </Label>
        <Label text={MarketplaceFee} className={styles.form__label}>
          <FormValue value={selectedPurchase?.marketplaceFee} />
        </Label>
        <Label text={PromotionFee} className={styles.form__label}>
          <FormValue value={selectedPurchase?.promotionFee} />
        </Label>

        {/* TODO: Correct property */}
        <Label text={PaymentFee} className={styles.form__label}>
          <FormValue value={selectedPurchase?.profit} />
        </Label>

        <Label text={Shipping} className={styles.form__label}>
          <FormValue value={selectedPurchase?.shippingPrice} />
        </Label>
        <Label text={Profit} className={styles.form__label}>
          <FormValue value={selectedPurchase?.profit} />
        </Label>
      </section>

      <section className={styles.form__section}>
        <Label text={WarehouseId} className={cn(styles.form__label, styles.form__label_multiline)}>
          <FormMultiLineValue value={warehousePlace} className={styles.maxHeight} />
        </Label>
        <Label text={Sku} className={cn(styles.form__label, styles.form__label_multiline)}>
          <FormMultiLineValue value={sku} className={styles.maxHeight} />
        </Label>
      </section>

      <section className={styles.form__section}>
        <Label text={LastModifiedDate} className={styles.form__label}>
          <FormValue value={dateTimeUtils.utcStringToLocalString(selectedPurchase?.mpLastModifiedTime)} />
        </Label>
        <Label text={LastSynchDate} className={styles.form__label}>
          <FormValue value={dateTimeUtils.utcStringToLocalString(selectedPurchase?.synchTime)} />
        </Label>
        <Label text={Archived} className={cn(styles.form__label, styles.form__label_checkbox)}>
          <FormCheckbox<FormData> register={register} name="mpCreatedTime" className={styles.form__inputWrapper} />
        </Label>
        <Label text={Exported} className={cn(styles.form__label, styles.form__label_checkbox)}>
          <FormCheckbox<FormData> register={register} name="mpCreatedTime" className={styles.form__inputWrapper} />
        </Label>
        <Label text={Canceled} className={cn(styles.form__label, styles.form__label_checkbox)}>
          <FormCheckbox<FormData> register={register} name="mpCreatedTime" className={styles.form__inputWrapper} />
        </Label>
      </section>
    </section>
  );
};
