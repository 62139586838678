import { useCallback, useState } from 'react';
import _ from 'underscore';

export function useForceUpdate() {
  const [, setState] = useState(true);
  return useCallback(
    _.debounce(() => {
      setState((prev) => !prev);
    }, 100),
    [setState],
  );
}
