import { useCallback } from 'react';

export const useStateStoring = (stateStoringKey: string | undefined) => {
  const saveState = useCallback(
    (state: any) => {
      if (stateStoringKey) {
        delete state.selectedRowKeys;
        localStorage.setItem(stateStoringKey, JSON.stringify(state));
      } // if
    },
    [stateStoringKey],
  );

  const loadState = useCallback(() => {
    if (stateStoringKey) {
      const item = localStorage.getItem(stateStoringKey);

      if (!item) return null;

      return JSON.parse(item);
    } // if
  }, [stateStoringKey]);

  return {
    saveState,
    loadState,
  };
};
