import { ModalDialog } from '@/Components/ModalDialog/ModalDialog';
import { useLocalization } from '@/Hooks';
import { DocumentFolderModel } from '@/Models/_Microservices/OnlineArchive/DocumentFolderModel';
import { DocumentsModalDialog } from '@/Pages/OnlineArchive/modules/Allocate/modules/AllocateTableWithControls/modules/DocumentsModalDialog/DocumentsModalDialog';
import { ModalProps } from '@/Types';
import React from 'react';
import { useAlreadyExistsDocumentNumberConfirmation } from './hooks/useAlreadyExistsDocumentNumberConfirmation';

type Props = ModalProps;

export function AlreadyExistsDocumentNumberModal({ closeModalWindow }: Props) {
  const {
    modalWindowTitle,
    confirmationText,
    onGenerateAlreadyExistsNumberClick,
    onNoOrCancelClick,
    currentModalParams,
    visibleOnlineArchiveModalWindow,
    setVisibleOnlineArchiveModalWindow,
  } = useAlreadyExistsDocumentNumberConfirmation();
  const {
    buttons: { Yes, SendToBucket },
  } = useLocalization();

  const [
    documentIds,
    documentFolderFromIds,
    documentFolderFromNames,
    documentFolderToId,
    documentFolderToName,
  ] = currentModalParams as string[];

  return (
    <>
      <ModalDialog
        title={modalWindowTitle}
        text={confirmationText}
        onOk={onGenerateAlreadyExistsNumberClick}
        onClose={closeModalWindow}
        okButtonText={Yes}
        onNo={onNoOrCancelClick}
        cancelButtonText={SendToBucket}
      />
      {visibleOnlineArchiveModalWindow && currentModalParams && (
        <DocumentsModalDialog
          documentIds={documentIds.split(',').map(Number)}
          documentFolderTo={{
            id: Number(documentFolderToId),
            name: documentFolderToName,
          }}
          documentFolderFrom={documentFolderFromIds.split(',').map(function (a, index) {
            return {
              id: Number(a),
              name: documentFolderFromNames.split(',')[index],
            } as DocumentFolderModel;
          })}
          isInOnlineArchive={false}
          closeWindow={closeModalWindow}
          setVisibleOnlineArchiveModalWindow={setVisibleOnlineArchiveModalWindow}
        />
      )}
    </>
  );
}
