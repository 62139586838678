import React from 'react';
import styles from './ModalDialog.scss';
import { ClassNameProps } from '@/Types';
import { useLocalization } from '@/Hooks';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { Modal } from '@/Components/Modal/Modal';

type Props = {
  title: string;
  text: string;
  onOk?: () => void;
  onNo?: () => void;
  onClose?: () => void;
  okButtonText?: string;
  cancelButtonText?: string;
  noButtonText?: string;
} & ClassNameProps;

export function ModalDialog({
  title,
  text,
  onOk,
  onClose,
  className,
  okButtonText,
  cancelButtonText,
  noButtonText,
  onNo,
}: Props) {
  const { buttons } = useLocalization();

  return (
    <Modal
      title={title}
      onClose={() => onClose?.()}
      needCloseIcon={false}
      className={className}
      modalClassName={styles.modal}
    >
      <div className={styles.modalWrapper} dangerouslySetInnerHTML={{ __html: text }} />
      <div className={styles.buttonsWrapper}>
        {onClose && (
          <Button
            text={cancelButtonText || buttons.Cancel}
            theme={BUTTON_THEMES.SUCCESS_TRANSPARENT}
            onClick={onClose}
          />
        )}
        {onNo && <Button text={noButtonText || buttons.No} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} onClick={onNo} />}
        {onOk && (
          <Button
            autoFocus={true}
            text={okButtonText || buttons.Ok}
            theme={BUTTON_THEMES.SUCCESS}
            onClick={() => {
              onOk();
              onClose?.();
            }}
          />
        )}
      </div>
    </Modal>
  );
}
