import React from 'react';
import { useFormSetValue, useFormError } from '@/Hooks';
import { ClassNameProps, DisabledProps, InputRefProps } from '@/Types';
import { ErrorTooltip } from '@/Components/Controls/ErrorTooltip/ErrorTooltip';
import styles from './Checkbox.scss';

type Props<TFormData extends object> = ClassNameProps &
  DisabledProps &
  InputRefProps<HTMLInputElement, TFormData, boolean | null | undefined> & {
    onChange?: (val: boolean) => void;
  };

export const FormCheckbox = <TFormData extends object>({
  name,
  register,
  rules,
  defaultChecked,
  setValue,
  valueForSet,
  error,
  disabled = false,
  className,
  readOnly = false,
  onChange,
}: Props<TFormData>) => {
  useFormSetValue(name, valueForSet, setValue);
  const errorMessage = useFormError(name, error);

  const handleChange = (ev: any) => {
    if (ev.target.readOnly) {
      ev.target.checked = !ev.target.checked;
    }
    onChange?.(ev.target.checked);
    return ev.target.checked;
  };

  return (
    <ErrorTooltip error={errorMessage} className={className}>
      <input
        onClick={handleChange}
        readOnly={readOnly}
        disabled={disabled}
        {...register?.(name as any, rules)}
        name={(name as unknown) as string}
        type="checkbox"
        className={styles.formCheckbox}
        defaultChecked={defaultChecked !== null ? defaultChecked : false}
      />
    </ErrorTooltip>
  );
};
