import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/Redux/RootReducer';

const emailSendModalState = (state: RootState) => state.modalWindows.emailSendModalState;

const selectedEmailTemplate = createSelector(emailSendModalState, (state) => state.selectedEmailTemplate);

const emailPreviewModalData = createSelector(emailSendModalState, (state) => state.emailPreviewModalData);

const rows = createSelector(emailSendModalState, (state) => state.rows);

export const emailSendModalSelectors = {
  selectedEmailTemplate,
  emailPreviewModalData,
  rows,
};
