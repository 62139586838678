import React, { useEffect } from 'react';
import { Modal } from '@/Components/Modal/Modal';
import styles from './SelectDocumentModal.scss';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { useLocalization } from '@/Hooks';
import { SelectDocumentTable } from './SelectDocumentTable';
import { documentPrintModalAsyncActions } from '@/ModalWindows/DocumentPrintModal/services/reducer';
import { useDispatch } from 'react-redux';

type Props = {
  onPrint: () => void;
  onClose: () => void;
  documentNumbers: string[];
};

export function SelectDocumentModal({ onClose, onPrint, documentNumbers }: Props) {
  const dispatch = useDispatch();

  const {
    modalWindows: {
      documentPrintModal: {
        SelectDocumentModal: { Title },
      },
    },
    buttons: { Cancel, Ok },
  } = useLocalization();

  useEffect(() => {
    dispatch(documentPrintModalAsyncActions.getDocumentsForPrint({ numbers: documentNumbers }));
  }, []);

  return (
    <Modal onClose={onClose}>
      <div className={styles.modalWrapper}>
        <h3 className={styles.modalTitle}>{Title}</h3>
        <SelectDocumentTable />

        <div className={styles.buttonsWrapper}>
          <Button text={Cancel} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} onClick={onClose} />
          <Button autoFocus={true} text={Ok} theme={BUTTON_THEMES.SUCCESS} onClick={onPrint} />
        </div>
      </div>
    </Modal>
  );
}
