import { ApiRoutingUtils } from '@/Routing';
import {
  GetContactsRequest,
  CreateContactRequest,
  CreateContactResponse,
  UpdateContactRequest,
  UpdateContactResponse,
  DeleteContactResponse,
} from './';
import { ContactModel } from '@/Models';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('contacts');

async function getContacts(request: GetContactsRequest): Promise<ContactModel[]> {
  return rest.get(`${url}`, request);
} // getContacts

async function createContact(request: CreateContactRequest): Promise<CreateContactResponse> {
  return rest.post(`${url}`, request);
} // createContact

async function updateContact(request: UpdateContactRequest): Promise<UpdateContactResponse> {
  return rest.put(url, request);
} // updateContact

async function deleteContact(request: number): Promise<DeleteContactResponse> {
  return rest.delete(`${url}/${request}`, null);
} // deleteContact

async function getFavoriteContacts(request: GetContactsRequest): Promise<ContactModel[]> {
  return rest.get(`${url}/favorite`, request);
}

export const ContactsApi = {
  getContacts,
  createContact,
  updateContact,
  deleteContact,
  getFavoriteContacts,
};
