import { createSelector } from '@reduxjs/toolkit';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';

const totalPurchaseNet = createSelector(purchasesPageSelectors.selectedPurchase, (selectedPurchase):
  | number
  | string => {
  return (
    selectedPurchase?.orderItems.reduce((acc, oi) => {
      if (typeof acc == 'string' || oi.purchaseNet === 0) {
        return 'No item in warehouse';
      }

      return acc + oi.purchaseNet;
    }, 0 as string | number) || ''
  );
});

export const salesAdditionalInfoSelectors = {
  totalPurchaseNet,
};
