import { GenerateUserAuthorizationUrlRequest, GenerateUserAuthorizationUrlResponse } from './';
import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { CheckMpConnectionRequest } from './CheckMpConnectionRequest';
import { CheckMpConnectionResponse } from './CheckMpConnectionResponse';

const url = ApiRoutingUtils.createUrl('mp-auth');

async function getEbayAuthorizeUrl(
  request: GenerateUserAuthorizationUrlRequest,
): Promise<GenerateUserAuthorizationUrlResponse> {
  return rest.post<GenerateUserAuthorizationUrlRequest, GenerateUserAuthorizationUrlResponse>(
    `${url}/ebay/generate-user-authorization-url`,
    request,
  );
}

async function getAmazonAuthorizeUrl(
  request: GenerateUserAuthorizationUrlRequest,
): Promise<GenerateUserAuthorizationUrlResponse> {
  return rest.post<GenerateUserAuthorizationUrlRequest, GenerateUserAuthorizationUrlResponse>(
    `${url}/amazon/generate-user-authorization-url`,
    request,
  );
}

async function checkMpConnection(request: CheckMpConnectionRequest): Promise<CheckMpConnectionResponse> {
  return rest.get<CheckMpConnectionResponse>(`${url}/check-mp-connection`, request);
}

export const MpAuthApi = {
  getEbayAuthorizeUrl,
  getAmazonAuthorizeUrl,
  checkMpConnection,
};
