import { createSlice } from '@reduxjs/toolkit';
import { DocumentTypeModel } from '@/Models/_Microservices/OnlineArchive/DocumentTypeModel';
import { asyncActions } from './asyncActions';

export type DocumentTypesDataState = {
  documentTypes: DocumentTypeModel[];
};

const initialState: DocumentTypesDataState = {
  documentTypes: [],
};

const documentTypesDataSlice = createSlice({
  name: 'documentTypesData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getDocumentTypes.fulfilled, (state, action) => {
        state.documentTypes = action.payload;
      })
      .addCase(asyncActions.createDocumentType.fulfilled, (state, action) => {
        state.documentTypes.push(action.payload.documentType);
      })
      .addCase(asyncActions.updateDocumentType.fulfilled, (state, action) => {
        state.documentTypes = state.documentTypes.map((item) =>
          item.id === action.payload.documentType.id ? action.payload.documentType : item,
        );
      })
      .addCase(asyncActions.deleteDocumentType.fulfilled, (state, action) => {
        state.documentTypes = state.documentTypes.filter((item) => item.id !== action.payload);
      });
  },
});

export const documentTypesDataReducer = documentTypesDataSlice.reducer;
export const documentTypesDataActions = documentTypesDataSlice.actions;
export const documentTypesDataAsyncActions = asyncActions;
