import { useCallback, useEffect, useRef } from 'react';
import styles from '../FormMultiLineValue.scss';

export const useOnClickEventHandlers = (className = styles.open) => {
  const ref = useRef<{ element: HTMLInputElement } | null>(null);

  const onOpen = useCallback((e) => {
    if (e.target) {
      ref.current = { element: e.target };
      ref.current.element.classList.add(className);
    }
  }, []);

  const onClose = useCallback((e: MouseEvent) => {
    if (ref.current && e.target !== ref.current.element) {
      ref.current.element.classList.remove(className);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', onClose);
    return () => {
      document.removeEventListener('click', onClose);
    };
  }, []);

  return { onOpen };
};
