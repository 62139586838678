import { TreeItemType } from '@/Components/TreeView/TreeView';
import { Locale } from '@/Enums';
import { TranslationModel } from '@/Models/TranslationModel';

function fillParentClassNames(item: TreeItemType, className: string) {
  if (!item.parent) return;
  item.parent.className = className;
  fillParentClassNames(item.parent, className);
} // fillParentClassNames

function getTranslationByKey(translations: TranslationModel[], key: string): TranslationModel | undefined {
  const levels = key.split('.');
  let translation: TranslationModel | undefined;
  levels.forEach((level, idx) => {
    if (idx === 0) {
      translation = translations.find((t) => t.key === level);
    } else {
      translation = translation?.childTranslations.find((t) => t.key === level);
    }
  });

  return translation;
} // getTranslationByKey

function getTranslationValueByLocaleAndKey(translations: TranslationModel[], key: string, locale: Locale): string {
  const translation = getTranslationByKey(translations, key);
  if (!translation) return '';

  switch (locale) {
    case '!Descriptions':
      return translation.description ?? '';

    case '!L-A':
      return translation.id ?? '';

    case 'de-DE':
      return translation.de ?? '';

    case 'en-EN':
      return translation.en ?? '';

    case 'ru-RU':
      return translation.ru ?? '';
  } // switch
}

function updateStateNested(
  oldTranslation: TranslationModel,
  newTranslation: TranslationModel,
): { translation: TranslationModel; paths: string[]; updatedItems: TranslationModel[] } {
  oldTranslation = { ...oldTranslation };
  let paths: string[] = [];
  let updatedItems: TranslationModel[] = [];
  if (oldTranslation.childTranslations.length) {
    oldTranslation.childTranslations = oldTranslation.childTranslations.map((oldTranslationChild) => {
      const newTranslationChild = newTranslation.childTranslations.find(
        (translation) => translation.key === oldTranslationChild.key,
      );

      if (newTranslationChild) {
        const result = updateStateNested(oldTranslationChild, newTranslationChild);
        paths = [...paths, ...result.paths];
        updatedItems = [...updatedItems, ...result.updatedItems];

        return result.translation;
      }

      return oldTranslationChild;
    });

    return { translation: oldTranslation, paths, updatedItems };
  } else {
    // here is the translation
    // Compare each fields and update old
    for (const key in oldTranslation) {
      const fieldName = key as keyof typeof oldTranslation;
      if (!excludedTranslationsFields.includes(fieldName)) {
        // Check languages. If new language has been added, we will skip it
        if (newTranslation[fieldName] !== undefined && oldTranslation[fieldName] !== newTranslation[fieldName]) {
          paths = [...paths, newTranslation.id];
          oldTranslation = {
            ...oldTranslation,
            ru: newTranslation.ru,
            de: newTranslation.de,
            en: newTranslation.en,
            description: newTranslation.description,
          };
          updatedItems = [...updatedItems, oldTranslation];
          break;
        }
      }
    }
    return { translation: oldTranslation, paths, updatedItems };
  } // if
} // updateStateNested;

const excludedTranslationsFields: (keyof TranslationModel)[] = ['childTranslations', 'id', 'key', 'lastUpdateTime'];

export const translationsEditorUtils = {
  getTranslationValueByLocaleAndKey,
  updateStateNested,
  fillParentClassNames,
  getTranslationByKey,
};
