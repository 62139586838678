import {
  GetTokenRequest,
  GetTokenResponse,
  AppAuthorizeRequest,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
  CheckEmailTokenRequest,
  CheckEmailTokenResponse,
  GetEmailTokenRequest,
  GetEmailTokenResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
} from './';
import { TokenUtils } from '@/Utils';
import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('app-auth');

async function getToken(request: GetTokenRequest): Promise<GetTokenResponse> {
  const response = await rest.post<GetTokenRequest, GetTokenResponse>(`${url}/get-token`, request);
  TokenUtils.setAccessToken(response.accessToken ?? '');
  return response;
}

async function login(request: AppAuthorizeRequest): Promise<LoginResponse> {
  return rest.post<AppAuthorizeRequest, LoginResponse>(`${url}/login`, request);
}

function registerUser(request: RegisterRequest): Promise<RegisterResponse> {
  return rest.post(`${url}/register`, request);
}

function getEmailToken(request: GetEmailTokenRequest): Promise<GetEmailTokenResponse> {
  return rest.post(`${url}/get-email-token`, request);
}

function checkEmailToken(request: CheckEmailTokenRequest): Promise<CheckEmailTokenResponse> {
  return rest.post(`${url}/check-email-token`, request);
}

function changeUserPassword(request: ChangePasswordRequest): Promise<ChangePasswordResponse> {
  return rest.post(`${url}/change-password`, request);
}

function logout(): Promise<void> {
  return rest.post(`${url}/logout`, null);
}

export const AppAuthApi = {
  getToken,
  login,
  registerUser,
  getEmailToken,
  checkEmailToken,
  changeUserPassword,
  logout,
};
