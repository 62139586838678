import { createAsyncThunk } from '@reduxjs/toolkit';
import { FunctionGroupModel } from '@/Models';
import {
  GetSubscriptionsResponse,
  CreateSubscriptionResponse,
  CreateSubscriptionRequest,
  UpdateSubscriptionResponse,
  UpdateSubscriptionRequest,
  SubscriptionsApi,
} from '@/Api/Subscriptions';
import { FunctionsApi } from '@/Api/Functions/FunctionsApi';

const getSubscriptions = createAsyncThunk<GetSubscriptionsResponse, void>(
  'subscriptionsPage/get-subscriptions',
  async () => {
    return SubscriptionsApi.getSubscriptions();
  },
);

const createSubscription = createAsyncThunk<CreateSubscriptionResponse, CreateSubscriptionRequest>(
  'subscriptionsPage/create-subscription',
  async (request: CreateSubscriptionRequest) => {
    return SubscriptionsApi.createSubscription(request);
  },
);

const updateSubscription = createAsyncThunk<UpdateSubscriptionResponse, UpdateSubscriptionRequest>(
  'subscriptionsPage/update-subscription',
  async (request: UpdateSubscriptionRequest) => {
    return SubscriptionsApi.updateSubscription(request);
  },
);

const deleteSubscription = createAsyncThunk<number, number>(
  'subscriptionsPage/delete-subscription',
  async (id: number) => {
    return SubscriptionsApi.deleteSubscription(id);
  },
);

const getFunctions = createAsyncThunk<FunctionGroupModel[], void>('subscriptionsPage/get-functions', async () => {
  return FunctionsApi.getFunctions();
});

export const asyncActions = {
  getSubscriptions,
  createSubscription,
  updateSubscription,
  deleteSubscription,
  getFunctions,
};
