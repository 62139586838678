import { DataGrid } from 'devextreme-react/data-grid';
import { useCallback } from 'react';

export const useKeyboardNavigation = () => {
  const onArrowDownClick = useCallback(
    <TData>(
      rowKey: '' | keyof TData,
      innerGridRef: React.MutableRefObject<DataGrid<TData, keyof TData> | undefined>,
    ) => {
      const selectedRowKeys = innerGridRef.current?.instance.getSelectedRowKeys();
      const lastSelectedRowKey = selectedRowKeys?.[selectedRowKeys.length - 1];

      if (lastSelectedRowKey !== undefined) {
        const lastSelectedRowIndex = innerGridRef.current?.instance.getRowIndexByKey(lastSelectedRowKey);

        if (lastSelectedRowIndex !== undefined) {
          const dataSourceItems = innerGridRef.current?.instance.getDataSource().items();
          const newSelectedRowKey = dataSourceItems
            ?.filter((item, idx) => item[rowKey] !== lastSelectedRowKey && idx > lastSelectedRowIndex)
            .slice(0, 1)[0]?.[rowKey];
          innerGridRef.current?.instance.selectRows([newSelectedRowKey], false);
        } // if
      }
    },
    [],
  );

  const onArrowUpClick = useCallback(
    <TData>(innerGridRef: React.MutableRefObject<DataGrid<TData, keyof TData> | undefined>) => {
      const selectedRowKeys = innerGridRef.current?.instance.getSelectedRowKeys();
      const firstSelectedRowKey = selectedRowKeys?.[0];

      if (firstSelectedRowKey !== undefined) {
        const firstSelectedRowIndex = innerGridRef.current?.instance.getRowIndexByKey(firstSelectedRowKey);

        if (firstSelectedRowIndex) {
          innerGridRef.current?.instance.selectRowsByIndexes([firstSelectedRowIndex - 1]);
        } // if
      } // if
    },
    [],
  );

  return {
    onArrowDownClick,
    onArrowUpClick,
  };
};
