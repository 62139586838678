import { useMemo, useState } from 'react';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { userProductsPageSelectors } from '@/Pages/Administration/LogisticProducts/modules/UserProducts/services/selectors';
import { useSelector } from 'react-redux';

export function useLogisticProductsTreeViewOptions(defaultValues?: (string | number)[]) {
  const [needToReset, setNeedToReset] = useState(false);
  const logisticProducts = useSelector(userProductsPageSelectors.logisticProdutsTreeView);

  const logisticProductsViewItems = useMemo<TreeItemType<number>[]>(() => {
    if (needToReset) {
      setNeedToReset(false);
    }
    return logisticProducts.map((item) =>
      defaultValues?.includes(item.key) ? { ...item, selected: true } : { ...item, selected: false },
    );
  }, [logisticProducts, needToReset, defaultValues]);

  return {
    logisticProductsViewItems,
    setNeedToReset,
  };
}
