import { useCallback } from 'react';

export const useOnSaving = <TData>(onSave?: (data: TData, key: string | number) => void) => {
  const onSaving = useCallback(
    (e: any) => {
      e.cancel = true;
      e.promise = onSave?.(e.changes[0]?.data, e.changes[0]?.key);
    },
    [onSave],
  );

  return {
    onSaving,
  };
};
