import { AccountChartModel, AccountChartTypeModel } from '@/Models/_Microservices/OnlineArchive/AccountChartModel';

const modelsToTableRows = (accountChartTypes: AccountChartTypeModel[], accountCharts: AccountChartModel[]) => {
  const rows: any[] = [];

  accountCharts.forEach((accountChart) => {
    const row: any = { ...accountChart };

    accountChartTypes.forEach((accountChartType) => {
      const accountChartValue = accountChart.values.find((v) => v.accountChartTypeId === accountChartType.id);

      row[`${accountChartType.name}_isActive`] = accountChartValue?.isActive ?? false;
      row[`${accountChartType.name}_value`] = accountChartValue?.value ?? '';
    });

    rows.push(row);
  });

  return rows.sort((a, b) => {
    if (a.isDefault) return -1;
    if (a.isDefault && b.isDefault) return 0;
    else return 1;
  });
}; // modelsToTableRows

const modelsToValuesPair = (accountCharts: AccountChartModel[]) => {
  return accountCharts
    .filter((a) => a.values.length > 0 && a.values[0].isActive)
    .map((a) => ({
      key: a.id,
      value: a.values[0].value,
    }));
}; // modelsToValuesPair

const modelToTitleWithValue = (accountChart: AccountChartModel | undefined) => {
  if (!accountChart) return '';

  return `${accountChart.values[0].value} - ${accountChart.title}`;
}; // modelToTitleWithValue

const modelsToTitleWithValuePair = (accountCharts: AccountChartModel[]) => {
  return accountCharts
    .filter((a) => a.values.length > 0 && a.values[0].isActive)
    .map((a) => ({
      key: a.id,
      value: modelToTitleWithValue(a),
    }))
    .sort((a, b) => a.value.localeCompare(b.value));
}; // modelsToTitleWithValuePair

export const accountChartMapping = {
  modelsToTableRows,
  modelsToValuesPair,
  modelToTitleWithValue,
  modelsToTitleWithValuePair,
};
