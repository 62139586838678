import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminCountryVatRatesApi } from '@/Api/AdminCountryVatRates/AdminCountryVatRatesApi';
import { GetCountryVatInfosResponse } from '@/Api/AdminCountryVatRates/GetCountryVatInfosResponse';
import { UpdateCountryVatRatesRequest } from '@/Api/AdminCountryVatRates/UpdateCountryVatRatesRequest';
import { GetCountryVatInfoResponse } from '@/Api/Mandats/GetCountryVatInfoResponse';
import { GetCountryVatInfoRequest } from '@/Api/Mandats/GetCountryVatInfoRequest';
import { MandatApi } from '@/Api/Mandats/MandatApi';
import { CreateCountryVatRatesRequest } from '@/Api/AdminCountryVatRates/CreateCountryVatRatesRequest';

const getCountryVatInfos = createAsyncThunk<GetCountryVatInfosResponse>('vatRates/get-country-vat-infos', async () => {
  return AdminCountryVatRatesApi.getCountryVatInfos();
});

const createCountryVatRates = createAsyncThunk<null, CreateCountryVatRatesRequest>(
  'vatRates/create-country-vat-rates',
  async (request) => {
    return AdminCountryVatRatesApi.createCountryVatRates(request);
  },
);

const updateCountryVatRates = createAsyncThunk<null, UpdateCountryVatRatesRequest>(
  'vatRates/update-country-vat-rates',
  async (request) => {
    return AdminCountryVatRatesApi.updateCountryVatRates(request);
  },
);

const getVatInfoByCountry = createAsyncThunk<GetCountryVatInfoResponse, GetCountryVatInfoRequest>(
  'vatRates/get-vat-info-by-country',
  async (request) => {
    return MandatApi.getVatInfoByCountry(request);
  },
);

export const asyncActions = {
  getCountryVatInfos,
  createCountryVatRates,
  updateCountryVatRates,
  getVatInfoByCountry,
};
