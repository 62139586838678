import { ClassNameProps } from '@/Types';
import React from 'react';
import styles from './FormMultiLineValue.scss';
import { useOnClickEventHandlers } from './hooks/useOnClickEventHandlers';

type Props = {
  value?: string | number;
} & ClassNameProps;

export function FormMultiLineValue({ value = '', className }: Props) {
  const { onOpen } = useOnClickEventHandlers(className);

  return (
    <div className={styles.formMultiLineValue} onClick={onOpen}>
      {value}
    </div>
  );
}
