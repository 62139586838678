import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { logisticTypesDataSelectors } from '@/Redux/Logistic/selector';

// Logistics country of product tree view options for form inputs
export function useCountryCodesTreeViewOptions(
  defaultValues?: (string | number)[],
  excludeValues?: (string | number)[],
) {
  let countryCodes = useSelector(logisticTypesDataSelectors.countryCodesTreeViewOptionsMemo);

  const [needToReset, setNeedToReset] = useState(false);

  const defaultIds = defaultValues;

  const countryCodesTreeViewItems = useMemo<TreeItemType[]>(() => {
    setNeedToReset(false);

    if (excludeValues) {
      countryCodes = countryCodes.filter((item) => !excludeValues.includes(item.key));
    }

    return countryCodes.map((item) =>
      defaultIds?.includes(item.key) ? { ...item, selected: true } : { ...item, selected: false },
    );
  }, [countryCodes, setNeedToReset, needToReset, defaultValues, excludeValues]);

  return {
    countryCodesTreeViewItems,
    setNeedToReset,
  };
}
