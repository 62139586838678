import { UpdateUserRequest, UpdateUserResponse } from './';
import { ApiRoutingUtils } from '@/Routing';
import { AppUserModel } from '@/Models';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('app-user');

function updateUser(request: UpdateUserRequest): Promise<UpdateUserResponse> {
  return rest.put(`${url}`, request);
}

async function getCurrentUserInfo(): Promise<AppUserModel> {
  return rest.post<object, AppUserModel>(`${url}/get-current-user-info`, {});
}

export const AppUserApi = {
  updateUser,
  getCurrentUserInfo,
};
