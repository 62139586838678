import { CountryCode } from '@/Enums';
import { AccountChartModel, AccountChartTypeModel } from '@/Models/_Microservices/OnlineArchive/AccountChartModel';
import { ModalDataType } from '@/Types/ModalDataType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';

export type AccountChartState = {
  accountChartsInfo: {
    countryCode: CountryCode;
    accountCharts: AccountChartModel[];
    accountChartTypes: AccountChartTypeModel[];
  }[];
  selectedCountryCode: CountryCode | null;
  selectedAccountChart: AccountChartModel | null;
  accountChartModalData: ModalDataType<{
    accountChart?: AccountChartModel;
    accountChartTypes: AccountChartTypeModel[];
    countryCode: CountryCode;
  }>;
  accountChartTypesModalData: ModalDataType<{
    accountChartTypes: AccountChartTypeModel[];
    countryCode: CountryCode;
  }>;
};

const initialState: AccountChartState = {
  accountChartsInfo: [],
  selectedCountryCode: null,
  selectedAccountChart: null,
  accountChartModalData: {
    visible: false,
  },
  accountChartTypesModalData: {
    visible: false,
  },
};

const accountChartSlice = createSlice({
  name: 'accountCharts',
  initialState: initialState,
  reducers: {
    setSelectedCountryCode: (state, action: PayloadAction<CountryCode>) => {
      state.selectedCountryCode = action.payload;
    },
    setSelectedAccountChart: (state, action: PayloadAction<AccountChartModel | null>) => {
      state.selectedAccountChart = action.payload;
    },
    showAccountChartModal: (
      state,
      action: PayloadAction<{
        countryCode: CountryCode;
        accountChart?: AccountChartModel;
        accountChartTypes: AccountChartTypeModel[];
      }>,
    ) => {
      state.accountChartModalData = {
        visible: true,
        data: {
          ...action.payload,
        },
      };
    },
    closeAccountChartModal: (state) => {
      state.accountChartModalData = {
        visible: false,
      };
    },
    showAccountChartTypesModal: (
      state,
      action: PayloadAction<{ countryCode: CountryCode; accountChartTypes: AccountChartTypeModel[] }>,
    ) => {
      state.accountChartTypesModalData = {
        visible: true,
        data: {
          ...action.payload,
        },
      };
    },
    closeAccountChartTypesModal: (state) => {
      state.accountChartTypesModalData = {
        visible: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getAccountCharts.fulfilled, (state, action) => {
        if (state.selectedCountryCode) {
          state.accountChartsInfo.push({
            countryCode: state.selectedCountryCode,
            accountCharts: action.payload.accountCharts,
            accountChartTypes: action.payload.accountChartTypes,
          });
        } // if
      })
      .addCase(asyncActions.createAccountChart.fulfilled, (state, action) => {
        const accountCharts = state.accountChartsInfo.find((ac) => ac.countryCode === state.selectedCountryCode)
          ?.accountCharts;

        if (accountCharts) {
          accountCharts.push(action.payload.accountChart);
        } // if
      })
      .addCase(asyncActions.updateAccountChart.fulfilled, (state, action) => {
        state.accountChartsInfo = state.accountChartsInfo.map((ac) => {
          if (ac.countryCode === state.selectedCountryCode) {
            ac.accountCharts = ac.accountCharts.map((item) => {
              return item.id === action.payload.accountChart.id ? action.payload.accountChart : item;
            });
          } // if

          return ac;
        });

        state.selectedAccountChart = action.payload.accountChart;
      })
      .addCase(asyncActions.deleteAccountChart.fulfilled, (state, action) => {
        state.accountChartsInfo = state.accountChartsInfo.map((ac) => {
          if (ac.countryCode === state.selectedCountryCode) {
            ac.accountCharts = ac.accountCharts.filter((item) => item.id !== action.payload.id);
          } // if

          return ac;
        });

        state.selectedAccountChart = null;
      })
      .addCase(asyncActions.createAccountChartType.fulfilled, (state, action) => {
        const accountChartTypes = state.accountChartsInfo.find((ac) => ac.countryCode === state.selectedCountryCode)
          ?.accountChartTypes;

        if (accountChartTypes) {
          accountChartTypes.push(action.payload.accountChartType);
          state.accountChartTypesModalData.data?.accountChartTypes.push(action.payload.accountChartType);
        } // if
      })
      .addCase(asyncActions.updateAccountChartType.fulfilled, (state, action) => {
        state.accountChartsInfo = state.accountChartsInfo.map((ac) => {
          if (ac.countryCode === state.selectedCountryCode) {
            ac.accountChartTypes = ac.accountChartTypes.map((t) => {
              return t.id === action.payload.accountChartType.id ? action.payload.accountChartType : t;
            });
          }

          return ac;
        });

        if (state.accountChartTypesModalData.data) {
          state.accountChartTypesModalData.data.accountChartTypes = state.accountChartTypesModalData.data.accountChartTypes.map(
            (d) => {
              return d.id === action.payload.accountChartType.id ? action.payload.accountChartType : d;
            },
          );
        } // if
      })
      .addCase(asyncActions.deleteAccountChartType.fulfilled, (state, action) => {
        state.accountChartsInfo = state.accountChartsInfo.map((ac) => {
          if (ac.countryCode === state.selectedCountryCode) {
            ac.accountChartTypes = ac.accountChartTypes.filter((t) => t.id !== action.payload.id);
          }

          return ac;
        });

        if (state.accountChartTypesModalData.data) {
          state.accountChartTypesModalData.data.accountChartTypes = state.accountChartTypesModalData.data.accountChartTypes.filter(
            (d) => d.id !== action.payload.id,
          );
        } // if
      });
  },
});

export const accountChartReducer = accountChartSlice.reducer;
export const accountChartActions = accountChartSlice.actions;
export const accountChartAsyncActions = asyncActions;
