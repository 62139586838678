import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactModel } from '@/Models';
import { asyncActions } from './asyncActions';

export type SelectContactModalState = {
  contacts: ContactModel[];
};

const initialState: SelectContactModalState = {
  contacts: [],
};

const selectContactModalSlice = createSlice({
  name: 'selectContactModal',
  initialState,
  reducers: {
    synchronizeContacts: (state, action: PayloadAction<ContactModel[]>) => {
      state.contacts = [
        ...action.payload.filter((item) => !state.contacts.find((contact) => contact.id === item.id)),
        ...state.contacts,
      ];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getContacts.fulfilled, (state, action) => {
      state.contacts = action.payload;
    });
  },
});

const { actions, reducer } = selectContactModalSlice;

export const selectContactModalReducer = reducer;
export const selectContactModalActions = actions;
export const selectContactModalAsyncActions = asyncActions;
