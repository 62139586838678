import { TranslationType } from '@/Localization/LanguageKeys';
import { Nullable } from '@/Types';

export enum UserProductType {
  Product = 'Product',
  AdditionalService = 'AdditionalService',
}

const getUserProductTypeNameByType = (
  userProductType: Nullable<UserProductType>,
  translation: TranslationType,
): string => {
  if (!userProductType) return '';

  const {
    Enums: { LogisticUserPoductType },
  } = translation;

  const userProductTypeMap = {
    [UserProductType.Product]: LogisticUserPoductType.Product,
    [UserProductType.AdditionalService]: LogisticUserPoductType.AdditionalService,
  };

  return userProductTypeMap[userProductType];
};

export const userProductTypeUtils = {
  getUserProductTypeNameByType,
};
