import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateGenAdditionalServiceTypeRequest,
  CreateGenAdditionalServiceTypeResponse,
  UpdateGenAdditionalServiceTypeRequest,
  UpdateGenAdditionalServiceTypeResponse,
  GenAdditionalServiceTypesApi,
} from '@/Api/GeneratorLogisticTypes/GenAdditionalServiceTypes';

const getAdditionalServiceTypes = createAsyncThunk('gen-additional-service-types/get-types', async () => {
  return await GenAdditionalServiceTypesApi.getAdditionalServiceTypes();
});

const createAdditionalServiceType = createAsyncThunk<
  CreateGenAdditionalServiceTypeResponse,
  CreateGenAdditionalServiceTypeRequest
>('gen-additional-service-types/create', async (request: CreateGenAdditionalServiceTypeRequest) => {
  return GenAdditionalServiceTypesApi.createAdditionalServiceType(request);
});

const updateAdditionalServiceType = createAsyncThunk<
  UpdateGenAdditionalServiceTypeResponse,
  UpdateGenAdditionalServiceTypeRequest
>('gen-additional-service-types/update', async (request: UpdateGenAdditionalServiceTypeRequest) => {
  return GenAdditionalServiceTypesApi.updateAdditionalServiceType(request);
});

const deleteAdditionalServiceType = createAsyncThunk<number, number>(
  'gen-additional-service-types/delete-type',
  async (id: number) => {
    return GenAdditionalServiceTypesApi.deleteAdditionalServiceType(id);
  },
);

export const asyncActions = {
  getAdditionalServiceTypes,
  createAdditionalServiceType,
  updateAdditionalServiceType,
  deleteAdditionalServiceType,
};
