import { GetOrdersAutomatizationResponse } from '@/Api/MpOrders/Responses/GetOrdersAutomatizationResponse';
import { Toasts } from '@/Components/Toast/Toast';
import { useCountryOptions } from '@/Enums/CountryTypeHooks';
import { useLocalization } from '@/Hooks';
import { shippingLabelModalActions, shippingLabelModalSelectors } from '@/ModalWindows/ShippingLabelModal/services';
import { ShippingLabelStep } from '@/ModalWindows/ShippingLabelModal/Types';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { OrderTableRow } from '@/Types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderAutomatizationActions, orderAutomatizationAsyncActions } from '../services/reducer';
import { orderLabelPrintAsyncActions } from '@/ModalWindows/OrderLabelPrintModal/services';

export const useOrderAutomatizationModalLoader = (editableOrderIds: number[], selectedOrders: OrderTableRow[]) => {
  const dispatch: AppDispatch = useDispatch();
  const isEditing = useSelector(shippingLabelModalSelectors.isEditing);

  const {
    modalWindows: { orderLinkWithProduct },
  } = useLocalization();

  const countryOptions = useCountryOptions();

  useEffect(() => {
    return () => {
      dispatch(orderAutomatizationActions.resetState());
    };
  }, []);

  useEffect(() => {
    dispatch(orderAutomatizationActions.setCountryOptions(countryOptions));

    const ids = editableOrderIds.length > 0 ? editableOrderIds : selectedOrders.map((x) => x.id);

    dispatch(orderLabelPrintAsyncActions.getOrderLabelInfoRequest({ orderIds: ids }));

    dispatch(
      orderAutomatizationAsyncActions.getOrdersAutomatizationRequest({
        orderIds: ids,
        isShippingAutomation: true,
      }),
    ).then((response) => {
      const orders = (response.payload as GetOrdersAutomatizationResponse)?.orders;

      if (orders?.length === 0) {
        dispatch(
          orderAutomatizationAsyncActions.getOrdersAutomatizationRequest({
            orderIds: ids,
            isShippingAutomation: false,
          }),
        ).then(() => {
          dispatch(shippingLabelModalActions.setShippingLabelStep(ShippingLabelStep.OrderWithoutAutomatizaion));
        });
      } // if
      else {
        let isSelectedPackageEmpty = false;

        orders?.forEach((order) => {
          if (order.selectedPackages.length === 0) {
            isSelectedPackageEmpty = true;
            return;
          }
        });

        if (isSelectedPackageEmpty) {
          dispatch(
            orderAutomatizationAsyncActions.getOrdersAutomatizationRequest({
              orderIds: ids,
              isShippingAutomation: false,
            }),
          ).then(() => {
            dispatch(shippingLabelModalActions.setShippingLabelStep(ShippingLabelStep.OrderWithoutAutomatizaion));
          });
        } else {
          if (!isEditing) {
            Toasts.showInfo({
              title: orderLinkWithProduct.Toasts.Title,
              text: orderLinkWithProduct.Toasts.Message,
            });
            dispatch(shippingLabelModalActions.setShippingLabelStep(ShippingLabelStep.OrderLabelPrint));
          }
        }
      }
    });

    return () => {
      dispatch(shippingLabelModalActions.setIsEditing(false));
    };
  }, [editableOrderIds, selectedOrders]);
};
