import { MARKETPLACE_IMAGE_OPTIONS } from '@/Enums';
import { AddressModel, orderItemModelUtils, OrderLabelModel, OrderModel, OrderShippingInfo } from '@/Models';
import { OrderAutomatizaionModel } from '@/Models/OrderUserProducts/OrderAutomatizaionModel';
import { OrderLabelPrintRow, OrderTableRow, OrderAutomatizaionRow, Pair } from '@/Types';

function fillOrderNumbers(order: OrderModel, row: OrderTableRow): OrderTableRow {
  return {
    ...row,
    deliveryNoteNumber: order.deliveryNoteNumber,
    deliveryNoteNumberCreatedTime: order.deliveryNoteNumberCreatedTime,

    orderNumber: order.orderNumber,
    orderNumberCreatedTime: order.orderNumberCreatedTime,

    customerNumber: order.customerNumber,
    customerNumberCreatedTime: order.customerNumberCreatedTime,

    invoices: order.invoices,
    currentCancelInvoice: order.currentCancelInvoice,

    currentInvoiceNumber: order.currentInvoice?.number,
    currentInvoiceDate: order.currentInvoice?.createdTime,

    currentCancelInvoiceNumber: order.currentCancelInvoice?.number,
    currentCancelInvoiceDate: order.currentCancelInvoice?.createdTime,
  };
}

function convertOrderToOrderTableRows(order: OrderModel): OrderTableRow[] {
  const result: OrderTableRow[] = [];
  const { ...other } = order;
  order.orderItems.forEach((orderItem, i) => {
    const {
      id,
      buyerFeedback,
      sellerFeedback,
      priceNetto,
      priceBrutto,
      totalPriceNettoWithoutShipping,
      totalPriceBruttoWithoutShipping,
      shippingCostNetto,
      shippingCostBrutto,
      salesTaxTotalWithShipping,
      totalPriceNettoWithShipping,
      totalPriceBruttoWithShipping,
      transactionVariableFee,
    } = orderItem;

    const isFirstRowInOrder = i === 0;

    const orderTableRow: OrderTableRow = {
      ...orderItem,
      ...other,

      warningsType: other.warnings ? other.warnings.join(' , ') : '',
      variants: orderItemModelUtils.getVariants(orderItem),
      orderItemId: id,

      shippingCostBruttoPerPosition: +shippingCostBrutto.toFixed(2),
      shippingCostNettoPerPosition: +shippingCostNetto.toFixed(2),

      priceNetto: +priceNetto.toFixed(2),
      priceBrutto: +priceBrutto.toFixed(2),
      totalPriceNettoWithoutShipping: +totalPriceNettoWithoutShipping.toFixed(2),
      totalPriceBruttoWithoutShipping: +totalPriceBruttoWithoutShipping.toFixed(2),
      salesTaxTotalWithShipping: +salesTaxTotalWithShipping.toFixed(2),
      totalPriceNettoWithShipping: +totalPriceNettoWithShipping.toFixed(2),
      totalPriceBruttoWithShipping: +totalPriceBruttoWithShipping.toFixed(2),
      transactionVariableFee: +transactionVariableFee.toFixed(2),

      currentCancelInvoice: order.currentCancelInvoice,

      currentInvoiceNumber: order.currentInvoice?.number,
      currentInvoiceDate: order.currentInvoice?.createdTime,
      currentInvoiceMailedTime: order.currentInvoice?.mailed,
      currentInvoicePrintedTime: order.currentInvoice?.printed,

      currentCancelInvoiceNumber: order.currentCancelInvoice?.number,
      currentCancelInvoiceDate: order.currentCancelInvoice?.createdTime,
      currentCancelInvoiceMailedTime: order.currentCancelInvoice?.mailed,
      currentCancelInvoicePrintedTime: order.currentCancelInvoice?.printed,

      // Order rows
      profit: isFirstRowInOrder ? other.profit : 0,
      marketplacePayout: isFirstRowInOrder ? other.marketplacePayout : 0,
      orderBalance: isFirstRowInOrder ? other.orderBalance : 0,
      refundAmount: isFirstRowInOrder ? other.refundAmount : 0,
      priceBruttoWithShipping: isFirstRowInOrder ? other.priceBruttoWithShipping : 0,
      amountPaid: isFirstRowInOrder ? other.amountPaid : 0,
      shippingPrice: isFirstRowInOrder ? +other.shippingPrice.toFixed(2) : 0,
      openAmount: isFirstRowInOrder ? other.openAmount : 0,
      orderFixFee: isFirstRowInOrder ? other.orderFixFee : 0,
      marketplaceFee: isFirstRowInOrder ? other.marketplaceFee : 0,
      commonVat1Percent: isFirstRowInOrder ? other.commonVat1Percent : 0,
      commonVat1Amount: isFirstRowInOrder ? other.commonVat1Amount : 0,
      commonNettoVat1Amount: isFirstRowInOrder ? other.commonNettoVat1Amount : 0,
      commonVat2Percent: isFirstRowInOrder ? other.commonVat2Percent : 0,
      commonVat2Amount: isFirstRowInOrder ? other.commonVat2Amount : 0,
      commonNettoVat2Amount: isFirstRowInOrder ? other.commonNettoVat2Amount : 0,
      commonVat3Percent: isFirstRowInOrder ? other.commonVat3Percent : 0,
      commonVat3Amount: isFirstRowInOrder ? other.commonVat3Amount : 0,
      commonNettoVat3Amount: isFirstRowInOrder ? other.commonNettoVat3Amount : 0,
      commonVat4Percent: isFirstRowInOrder ? other.commonVat4Percent : 0,
      commonVat4Amount: isFirstRowInOrder ? other.commonVat4Amount : 0,
      commonNettoVat4Amount: isFirstRowInOrder ? other.commonNettoVat4Amount : 0,
      commonVat5Percent: isFirstRowInOrder ? other.commonVat5Percent : 0,
      commonVat5Amount: isFirstRowInOrder ? other.commonVat5Amount : 0,
      commonNettoVat5Amount: isFirstRowInOrder ? other.commonNettoVat5Amount : 0,
    };
    if (buyerFeedback) {
      orderTableRow.buyerFeedbackText = buyerFeedback.text;
      orderTableRow.buyerFeedbackTime = buyerFeedback.commentTime;
      orderTableRow.buyerFeedbackType = buyerFeedback.ratingType;
    }

    if (sellerFeedback) {
      orderTableRow.sellerFeedbackText = sellerFeedback.text;
      orderTableRow.sellerFeedbackTime = sellerFeedback.commentTime;
      orderTableRow.sellerFeedbackType = sellerFeedback.ratingType;
    }
    result.push(orderTableRow);
  });

  return result;
}

function convertOrdersToOrderTableRows(orders: OrderModel[]): OrderTableRow[] {
  const result: OrderTableRow[] = [];
  orders.forEach((order) => {
    const tableRows = convertOrderToOrderTableRows(order);
    result.push(...tableRows);
  });

  return result;
}

function copyShippingInfo(destination: OrderTableRow, source: OrderModel): OrderTableRow {
  const {
    shippingCompanyName,
    shippingFirstName,
    shippingLastName,
    shippingAdditionalLine,
    shippingStreet,
    shippingHouseNo,
    shippingPostalCode,
    shippingCityName,
    shippingCountryCode,
    shippingCountryName,
    shippingPhoneNumber,
    isOriginalShippingModified,
  } = source;
  return {
    ...destination,
    shippingCompanyName,
    shippingFirstName,
    shippingLastName,
    shippingAdditionalLine,
    shippingStreet,
    shippingHouseNo,
    shippingPostalCode,
    shippingCityName,
    shippingCountryCode,
    shippingCountryName,
    shippingPhoneNumber,
    isOriginalShippingModified,
  };
}

function toShippingInfo(address: AddressModel): OrderShippingInfo {
  return {
    shippingAdditionalLine: address.additionalLine,
    shippingCityName: address.city,
    shippingCompanyName: address.company,
    shippingCountryCode: address.countryCode,
    shippingCountryName: address.country,
    shippingFirstName: address.firstName,
    shippingHouseNo: address.houseNo,
    shippingLastName: address.lastName,
    shippingPhoneNumber: address.phone,
    shippingPostalCode: address.postalCode,
    shippingStreet: address.street,
  };
}

function getShippingInfo(order: OrderModel): AddressModel {
  return {
    additionalLine: order.shippingAdditionalLine,
    city: order.shippingCityName,
    company: order.shippingCompanyName,
    countryCode: order.shippingCountryCode,
    country: order.shippingCountryName,
    firstName: order.shippingFirstName,
    houseNo: order.shippingHouseNo,
    lastName: order.shippingLastName,
    phone: order.shippingPhoneNumber,
    postalCode: order.shippingPostalCode,
    street: order.shippingStreet,
    email: order.buyerEmail,
  };
}

function fillOrderRowWithAddress(order: OrderTableRow, address: AddressModel): OrderTableRow {
  return {
    ...order,
    shippingCityName: address.city,
    shippingCompanyName: address.company,
    shippingAdditionalLine: address.additionalLine,
    shippingCountryCode: address.countryCode,
    shippingCountryName: address.country,
    shippingHouseNo: address.houseNo,
    shippingStreet: address.street,
    shippingFirstName: address.firstName,
    shippingLastName: address.lastName,
    shippingPhoneNumber: address.phone,
    buyerEmail: address.email,
    shippingPostalCode: address.postalCode,
    isOriginalShippingModified: true,
  };
}

function getOrderAutomatizaionRow(order: OrderAutomatizaionModel): OrderAutomatizaionRow {
  return {
    marketplaceImage: enumToString(order.marketplaceType, MARKETPLACE_IMAGE_OPTIONS),
    marketplaceType: order.marketplaceType,
    orderId: order.orderId,
    userOrderId: order.userOrderId,
    orderItems: order.orderItems,
    invoice: order.invoice,
    amount: order.amount,
    shippingCountryCode: order.shippingCountryCode,
    shippingCountryName: order.shippingCountryName,
    originalShippingMethod: order.originalShippingMethod,
    originalShippingMethodName: order.originalShippingMethodName,
    selectedPackages: order.selectedPackages,
    hasLabelGenerated: order.hasLabelGenerated,
  };
}

function convertToOrderPrintRows(orderLabels: OrderLabelModel[]): OrderLabelPrintRow[] {
  return orderLabels?.map((orderLabel) => {
    return getOrderPrintRow(orderLabel);
  });
}

function getOrderPrintRow(orderLabel: OrderLabelModel): OrderLabelPrintRow {
  return {
    marketplaceType: orderLabel.marketplaceType,
    marketplaceImage: enumToString(orderLabel.marketplaceType, MARKETPLACE_IMAGE_OPTIONS),
    id: orderLabel.id,
    orderId: orderLabel.orderId,
    userOrderId: orderLabel.userOrderId,
    invoice: orderLabel.invoice,
    userLogisticTypeId: orderLabel.userLogisticTypeId,
    userLogisticTypeName: orderLabel.userLogisticTypeName,
    country: orderLabel.country,
    countryName: orderLabel.countryName,
    packages: orderLabel.packages,
    logisticDesciptions: `${orderLabel.packages.length}x ${orderLabel.userLogisticTypeName}`,
    price: orderLabel.price,
    isCreated: orderLabel.isCreated,
    isPrinted: orderLabel.isPrinted,
    isPickup: orderLabel.isPickup,
  };
}

function enumToString<TKey>(enumKey: TKey, locs: Pair<TKey>[]): any {
  return enumKey ? locs.find((y) => y.key == enumKey)?.value || enumKey : enumKey;
}

export const orderMapping = {
  convertOrdersToOrderTableRows,
  convertOrderToOrderTableRows,
  fillOrderRowWithAddress,
  copyShippingInfo,
  toShippingInfo,
  getShippingInfo,
  fillOrderNumbers,
  getOrderAutomatizaionRow,
  convertToOrderPrintRows,
};
