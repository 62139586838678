import React from 'react';

// Hooks import
import { useLocalization } from '@/Hooks';

// Enums import
import { UserCategoryType } from '@/Enums';

// Styles import
import styles from './SignUpAddressForm.scss';
import sharedStyles from '../../SignUp.scss';
import { AddressForm } from './modules/AddressForm';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { useSignUpAddressForm } from './hooks/useSignUpAddressForm';

type Props = {
  category: UserCategoryType;
  firstName: string;
  lastName: string;
  companyName: string;
};

export function SignUpAddressForm({ category, firstName, lastName, companyName }: Props): React.ReactElement {
  const {
    authorizationPage: {
      SignUp: { AddressForm: AddressFormTranslate },
    },
  } = useLocalization();

  // custom hook
  const { needShowReturnAddress, onReturnAddressButtonClick } = useSignUpAddressForm(firstName, lastName, companyName);

  return (
    <div className={sharedStyles.signUpFormFields}>
      <h5 className={styles.signUpFormTitle}>{AddressFormTranslate.YourSenderAddressForShippingLabels}</h5>
      <AddressForm category={category} />
      <Button
        text={
          needShowReturnAddress
            ? AddressFormTranslate.RemoveDifferentReturnAddress
            : AddressFormTranslate.AddDifferentReturnAddress
        }
        theme={BUTTON_THEMES.PRIMARY_LIGHT}
        onClick={onReturnAddressButtonClick}
      />
      {needShowReturnAddress && <AddressForm category={category} isInvoiceSection={false} />}
    </div>
  );
}
