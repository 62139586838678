import React from 'react';
import { DataGrid } from 'devextreme-react/data-grid';
import { Icon } from '@/Components/Icon/Icon';
import { Icons } from '@/Static/IconsRes';
import Button from 'devextreme-react/button';
import styles from './ClearFiltersButton.scss';

type Props<TData extends object> = {
  innerGridRef: React.MutableRefObject<DataGrid<TData, keyof TData> | undefined>;
};

export const ClearFiltersButton = <TData extends object>({ innerGridRef }: Props<TData>): React.ReactElement => {
  return (
    <Button
      onClick={() => {
        innerGridRef.current?.instance.clearFilter();
        innerGridRef.current?.instance.clearSorting();
      }}
    >
      <Icon alt="clearFiltersIcon" src={Icons.Cancel} className={styles.icon} />
    </Button>
  );
};
