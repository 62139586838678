import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';
import { ModalDataType } from '@/Types/ModalDataType';
import { EmailModel } from '@/Models/EmailModel';
import { Toasts } from '@/Components/Toast/Toast';
import { stringUtils } from '@/Utils';
import { localizationUtils } from '@/Utils/localizationUtils';
import { EmailTemplateRow } from '@/Types/EmailTemplateRow';
import { AutomaticallyGeneratedDocumentType } from '@/Enums/AutomaticallyGeneratedDocumentType';

export type EmailSendModalState = {
  selectedEmailTemplate: EmailTemplateRow | null;
  emailPreviewModalData: ModalDataType<{
    email: EmailModel;
    orderId: number;
  }>;
  rows: EmailTemplateRow[];
};

const initialState: EmailSendModalState = {
  selectedEmailTemplate: null,
  emailPreviewModalData: { visible: false },
  rows: [],
};

const emailSendSlice = createSlice({
  name: 'emailSendModalSlice',
  initialState,
  reducers: {
    setSelectedEmailTemplate(state, action: PayloadAction<EmailTemplateRow | null>) {
      state.selectedEmailTemplate = action.payload;
    },
    setEmailPreviewModalData(state, action: PayloadAction<typeof initialState.emailPreviewModalData>) {
      state.emailPreviewModalData = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(asyncActions.sendEmailForOrders.fulfilled, (state, action) => {
        const {
          result: { successEmailsCount },
          translation: {
            ModalWindows: { EmailSendModal },
          },
          localization,
        } = action.payload;

        if (action.payload.result.successEmailsCount) {
          const toastText = localizationUtils.insertTranslatedParamsToString(
            EmailSendModal.EmailsSuccesfullySent,
            [successEmailsCount],
            EmailSendModal.parts,
            localization,
          );

          Toasts.showSuccess({
            title: EmailSendModal.EmailSend,
            text: stringUtils.insertParamsToString(toastText, [successEmailsCount]),
          });
        } // if
      })
      .addCase(asyncActions.sendFormattedEmailForOrder.fulfilled, (state, action) => {
        const {
          result,
          translation: {
            ModalWindows: { EmailSendModal },
          },
          localization,
        } = action.payload;

        if (!result.errors) {
          const toastText = localizationUtils.insertTranslatedParamsToString(
            EmailSendModal.EmailsSuccesfullySent,
            [1],
            EmailSendModal.parts,
            localization,
          );

          Toasts.showSuccess({
            title: EmailSendModal.EmailSend,
            text: stringUtils.insertParamsToString(toastText, [1]),
          });

          state.emailPreviewModalData = {
            visible: false,
          };
        } // if
      })
      .addCase(asyncActions.getEmailPreviewForOrder.fulfilled, (state, action) => {
        if (!action.payload.errors) {
          state.emailPreviewModalData = {
            visible: true,
            data: action.payload,
          };
        } // if
      })
      .addCase(asyncActions.getEmailTemplates.fulfilled, (state, action) => {
        state.rows = action.payload.templates.map((template) => ({
          name: template.name,
          guid: template.guid,
          previewBeforeSending: template.emailTemplateSettings?.previewBeforeSending,
          isDefaultForDeliveryNote:
            template.automaticallyGeneratedDocumentType === AutomaticallyGeneratedDocumentType.PL,
          isDefaultForSale: template.automaticallyGeneratedDocumentType === AutomaticallyGeneratedDocumentType.SORD,
          isDefaultForPurchase: template.automaticallyGeneratedDocumentType === AutomaticallyGeneratedDocumentType.PORD,
          isDefaultForInvoice: template.automaticallyGeneratedDocumentType === AutomaticallyGeneratedDocumentType.INV,
          isDefaultForCancelInvoice:
            template.automaticallyGeneratedDocumentType === AutomaticallyGeneratedDocumentType.CINV,
        }));
      }),
});

const { actions, reducer } = emailSendSlice;

export const emailSendModalReducer = reducer;
export const emailSendModalActions = actions;
export const emailSendModalAsyncActions = asyncActions;
