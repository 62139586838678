import { useUserHasBusinessSegment } from '@/Hooks/useUserHasBusinessSegment';
import { useMemo } from 'react';
import { LocalizationType } from '@/Enums';
import { LANGUAGE_SELECT_OPTIONS_MAP } from '@/Localization/constants';
import { useUserHasMarketSegment } from '@/Hooks/useUserHasMarketSegment';
import { useSelector } from 'react-redux';
import { editorSettingsSelectors } from '@/Pages/Administration/LanguageEditor/modules/EditorSettings/services/selectors';

const LANGUAGE_SELECT_OPTIONS = Object.values(LANGUAGE_SELECT_OPTIONS_MAP);

export function useLanguageSelectOptions(exludeServiceLanguage = false) {
  const isAdmin = useUserHasBusinessSegment();
  const isMarket = useUserHasMarketSegment();
  // const isSimple = useUserHasSimpleSegment();
  const languageAccessSettings = useSelector(editorSettingsSelectors.languageAccessSettings);

  // useEditorSettingsLoader();

  return useMemo(() => {
    const languageOptions =
      !exludeServiceLanguage && (isAdmin || isMarket)
        ? LANGUAGE_SELECT_OPTIONS
        : LANGUAGE_SELECT_OPTIONS.filter((el) => el.key !== LocalizationType.LA);

    // if (isSimple) {
    //   return languageOptions.filter((el) =>
    //     languageAccessSettings?.selectableLanguages.includes(EditableLocalizationType[el.key]),
    //   );
    // }

    return languageOptions;
  }, [isAdmin, languageAccessSettings]);
}
