import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DigitalProductModel, DocumentTemplateModel, FunctionGroupModel } from '@/Models';
import { asyncActions } from './asyncActions';
import { Nullable } from '@/Types';
import { ModalDataType } from '@/Types/ModalDataType';
import { DocumentTemplateAdminSettingsModel } from '@/Models/DocumentTemplateAdminSettingsModel';

export type DigitalProductsState = {
  digitalProducts: DigitalProductModel[];
  selectedDigitalProduct: Nullable<DigitalProductModel>;
  digitalProductModal: ModalDataType<DigitalProductModel>;
  digitalProductDeleteDialog: ModalDataType<void>;
  functionGroups: FunctionGroupModel[];
  documentTemplateAdminSettings: DocumentTemplateAdminSettingsModel[];
  documentTemplatesCreatedByMarket: DocumentTemplateModel[];
};

const initialState: DigitalProductsState = {
  digitalProducts: [],
  selectedDigitalProduct: null,
  digitalProductModal: {
    visible: false,
  },
  digitalProductDeleteDialog: {
    visible: false,
  },
  functionGroups: [],
  documentTemplateAdminSettings: [],
  documentTemplatesCreatedByMarket: [],
};

const digitalProductsSlice = createSlice({
  name: 'digitalProductsPage',
  initialState,
  reducers: {
    setSelectedDigitalProduct: (state, action: PayloadAction<DigitalProductModel>) => {
      state.selectedDigitalProduct = action.payload;
    },
    setDigitalProductModal: (state, action: PayloadAction<ModalDataType<DigitalProductModel>>) => {
      state.digitalProductModal = action.payload;
    },
    setDigitalProductDeleteDialog: (state, action: PayloadAction<ModalDataType<void>>) => {
      state.digitalProductDeleteDialog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getDigitalProducts.fulfilled, (state, action) => {
        state.digitalProducts = action.payload;
      })
      .addCase(asyncActions.createDigitalProduct.fulfilled, (state, action) => {
        state.digitalProducts.push(action.payload.digitalProduct);
        state.selectedDigitalProduct = action.payload.digitalProduct;
      })
      .addCase(asyncActions.updateDigitalProduct.fulfilled, (state, action) => {
        state.digitalProducts = state.digitalProducts.map((item) =>
          item.id === action.payload.digitalProduct.id ? action.payload.digitalProduct : item,
        );
        state.selectedDigitalProduct = action.payload.digitalProduct;
      })
      .addCase(asyncActions.deleteDigitalProduct.fulfilled, (state, action) => {
        state.digitalProducts = state.digitalProducts.filter((item) => item.id !== action.payload);
        state.selectedDigitalProduct = null;
      })
      .addCase(asyncActions.getFunctions.fulfilled, (state, action) => {
        state.functionGroups = action.payload;
      })

      //Template settings
      .addCase(asyncActions.getTemplateSettings.fulfilled, (state, action) => {
        state.documentTemplateAdminSettings = action.payload.documentTemplateAdminSettingsModels;
      })
      .addCase(asyncActions.getTemplatesCreatedByMarket.fulfilled, (state, action) => {
        const uniqueTemplates = action.payload.templates.filter(
          (newItem) => !state.documentTemplatesCreatedByMarket.some((item) => item.id === newItem.id),
        );
        state.documentTemplatesCreatedByMarket.push(...uniqueTemplates);
      })
      .addCase(asyncActions.createTemplateSettings.fulfilled, (state, action) => {
        state.documentTemplateAdminSettings.push(action.payload.documentTemplateAdminSettingsModel);
      })
      .addCase(asyncActions.updateTemplateSettings.fulfilled, (state, action) => {
        state.documentTemplateAdminSettings = state.documentTemplateAdminSettings.map((item) =>
          item.id === action.payload.documentTemplateAdminSettingsModel.id
            ? action.payload.documentTemplateAdminSettingsModel
            : item,
        );
      })
      .addCase(asyncActions.deleteTemplateSettings.fulfilled, (state, action) => {
        state.documentTemplateAdminSettings = state.documentTemplateAdminSettings.filter(
          (item) => item.id !== action.payload.id,
        );
      });
  },
});

const { actions, reducer } = digitalProductsSlice;

export const digitalProductsPageActions = actions;
export const digitalProductsPageReducer = reducer;
export const digitalProductsAsyncActions = asyncActions;
