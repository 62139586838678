import React, { useMemo } from 'react';
import { CrudExtended } from '@/Components/Controls/CrudExtended/CrudExtended';
import { NumberFormInput } from '@/Components/Controls/Input/NumberFormInput';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { FormData } from '@/ModalWindows/OrderAutomatizationModal/hooks/useOrderAutomatizationModalForm';
import { Icons } from '@/Static/IconsRes';
import { OrderAutomatizationPackageModel } from '@/Models/OrderUserProducts/OrderAutomatizationPackageModel';
import { usePackageRowForm } from './hooks/usePackageRowForm';
import { useLocalization } from '@/Hooks';
import styles from './PackageSelect.scss';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';

type Props = {
  index: number;
  rowIndex: number;
  disabled?: boolean;
  isAddActive: boolean;
  onAdd: () => void;
  onDelete: () => void;
  packageOptions: OrderAutomatizationPackageModel[];
  isOwnContract?: boolean;
  orderId?: number;
};

export const PackageSelect = ({
  index,
  rowIndex,
  isAddActive,
  onAdd,
  onDelete,
  packageOptions,
  disabled = false,
  isOwnContract = true,
  orderId,
}: Props) => {
  const {
    modalWindows: { orderLinkWithProduct },
    buttons: { Upload },
  } = useLocalization();

  const {
    methods: { register, setValue, errors, loadPacklinkProducts },
    rules: { required },
    options: { isPackageChanged },
  } = usePackageRowForm(rowIndex, index, packageOptions, isOwnContract, orderId);

  const packages = useMemo(() => {
    return [
      { key: '', value: orderLinkWithProduct.Placeholders.SelectPackage },
      ...packageOptions.map((p) => ({
        key: p.key,
        value: p.description,
      })),
    ];
  }, [packageOptions, orderLinkWithProduct]);

  return (
    <div className={styles.packageSelect}>
      {index === 0 ? (
        <CrudExtended
          className={styles.icons}
          iconItems={[
            {
              src: Icons.Add,
              active: isAddActive && !disabled,
              onClick: onAdd,
            },
          ]}
        />
      ) : (
        <CrudExtended
          className={styles.icons}
          iconItems={[
            {
              src: Icons.Delete,
              active: !disabled,
              onClick: onDelete,
            },
          ]}
        />
      )}

      <FormSelectInput<FormData>
        name={(`prepareLabels.${rowIndex}.packages.${index}.key` as unknown) as keyof FormData}
        register={register}
        rules={required}
        className={styles.select}
        needClearButton={false}
        error={errors}
        options={packages}
        disabled={disabled}
      />

      <NumberFormInput
        name={(`prepareLabels.${rowIndex}.packages.${index}.weight` as unknown) as keyof FormData}
        register={register}
        setValue={setValue}
        rules={required}
        type={'weight'}
        placeholder={orderLinkWithProduct.Placeholders.Width}
        error={errors}
        disabled={disabled}
      />

      {!isOwnContract && isPackageChanged && (
        <Button text={Upload} theme={BUTTON_THEMES.PRIMARY} onClick={loadPacklinkProducts} />
      )}
    </div>
  );
};
