import { ShippingServiceType } from '@/Enums';
import { OrderAutomatizaionModel } from '@/Models/OrderUserProducts/OrderAutomatizaionModel';
import { OrderAutomatizationPackageModel } from '@/Models/OrderUserProducts/OrderAutomatizationPackageModel';
import { genLogisticTypesUtils } from '@/Redux/DynamicData/GenLogisticTypesData/utils';
import { shippingMethodsUtils } from '@/Redux/DynamicData/ShippingMethodsData/utils';
import { RootState } from '@/Redux/RootReducer';
import { Nullable, Pair } from '@/Types';
import { ObjectUtils } from '@/Utils';

function enumToString<TKey>(enumKey: TKey, locs: Pair<TKey>[]): any {
  return enumKey ? locs.find((y) => y.key == enumKey)?.value || enumKey : enumKey;
}

export function getShippingServiceName(type: Nullable<ShippingServiceType>, state: RootState): string {
  if (type === null) {
    return '';
  }
  const name = type;
  const enums = state.global.translation.Enums.LogisticShippingServiceType;
  return ObjectUtils.getObjectPathValue(enums, name) || name;
}

export function fillLocalization(x: OrderAutomatizaionModel, state: RootState) {
  x.shippingCountryName = enumToString(
    x.shippingCountryCode,
    state.modalWindows.orderAutomatizationState.countryCodeOptions,
  );

  x.originalShippingMethodName = shippingMethodsUtils.getLocalizationNameByCode(x.originalShippingMethod, state);
  x.selectedPackages.forEach((p) => {
    p.products.forEach((y) => {
      const logisticName = genLogisticTypesUtils.getLocalizationNameByName(y.logisticTypeName, state);
      const productTypeName = genLogisticTypesUtils.getLocalizationNameByName(y.productTypeName, state);
      const shippingServiceName = getShippingServiceName(y.shippingServiceType, state);

      y.description = `${logisticName} ${productTypeName} ${shippingServiceName}`;
    });
  });
}

export function fillProductsLocalization(x: OrderAutomatizationPackageModel[], state: RootState) {
  x.forEach((p) => {
    p.products.forEach((y) => {
      const logisticName = genLogisticTypesUtils.getLocalizationNameByName(y.logisticTypeName, state);
      const productTypeName = genLogisticTypesUtils.getLocalizationNameByName(y.productTypeName, state);
      const shippingServiceName = getShippingServiceName(y.shippingServiceType, state);

      y.description = `${logisticName} ${productTypeName} ${shippingServiceName}`;
    });
  });
}

export const orderAutomatizationModalUtils = {
  fillLocalization,
  fillProductsLocalization,
};
