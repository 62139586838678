export enum AppUserSettingNameType {
  UiLanguage = 'UiLanguage',
  TableSalesTab1 = 'TableSalesTab1',
  TableSalesTab2 = 'TableSalesTab2',
  TablePurchaseTab1 = 'TablePurchaseTab1',
  TablePurchaseTab2 = 'TablePurchaseTab2',
  TimeZone = 'TimeZone',
  TimeFormat = 'TimeFormat',
  DateFormat = 'DateFormat',
  FirstDayOfWeek = 'FirstDayOfWeek',
  FirstWeekOfYear = 'FirstWeekOfYear',
  StartOfTheFinancialYear = 'StartOfTheFinancialYear',
  NumbersLocale = 'NumbersLocale',
  Synchronize = 'Synchronize',
  SalesDetailActiveTab = 'SalesDetailActiveTab',
  ScanUploadPath = 'ScanUploadPath',
  DeleteAfterScanUploading = 'DeleteAfterScanUploading',
}
