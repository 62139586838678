import { DateRangePicker } from 'daterangepicker';
import { useCallback } from 'react';
import { formDateInputUtils } from '../utils';

type Props = {
  format: string;
  isRange: boolean;
  onChange?: (val: string) => void;
};

export const useDateRangeEventListeners = (props: Props) => {
  const { format, isRange, onChange } = props;

  const onApply = useCallback(
    (
      name: string | number | symbol,
      picker: DateRangePicker,
      setValue: (name: any, value: any, config?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }>) => void,
    ) => {
      const value = formDateInputUtils.formatPicker(picker, format, isRange);
      setValue(name, value, {
        shouldValidate: true,
        shouldDirty: true,
      });

      onChange?.(value);
    },
    [props, format, isRange],
  ); // onApply

  const onCancel = useCallback(
    (
      name: string | number | symbol,
      setValue: (name: any, value: any, config?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }>) => void,
    ) => {
      setValue(name, '', { shouldValidate: true, shouldDirty: true });
    },
    [props],
  ); // onCancel

  return {
    onApply,
    onCancel,
  };
}; // useDateRangeEventListeners
