import { createSelector } from 'reselect';
import { RootState } from '../RootReducer';
import { LogisticTypesDataState } from './reducer';
import { TreeItemType } from '@/Components/TreeView/TreeView';

const logisticWarehouseDataStateSelector = (root: RootState) => root.logisticWarehouseData;

const internationalTypes = createSelector(
  logisticWarehouseDataStateSelector,
  (globalState: LogisticTypesDataState) => globalState.internationalTypes,
);

const countryCodes = createSelector(
  logisticWarehouseDataStateSelector,
  (globalState: LogisticTypesDataState) => globalState.countryCodes,
);

const shippingServices = createSelector(
  logisticWarehouseDataStateSelector,
  (globalState: LogisticTypesDataState) => globalState.shippingServiceTypes,
);

const internationalTypesTreeViewOptionsMemo = createSelector(
  [internationalTypes],
  (internationalTypes): TreeItemType[] => {
    return internationalTypes.map(
      (item) =>
        ({
          key: item.key,
          value: item.value,
          expanded: true,
        } as TreeItemType),
    );
  },
);

const countryCodesTreeViewOptionsMemo = createSelector([countryCodes], (countryCodes): TreeItemType[] => {
  return countryCodes.map(
    (item) =>
      ({
        key: item.key,
        value: item.value,
        expanded: true,
      } as TreeItemType),
  );
});

const shippingServicesTreeViewOptionsMemo = createSelector([shippingServices], (shippingServices): TreeItemType[] => {
  return shippingServices.map(
    (item) =>
      ({
        key: item.key,
        value: item.value,
        expanded: true,
      } as TreeItemType),
  );
});

export const logisticTypesDataSelectors = {
  internationalTypes,
  internationalTypesTreeViewOptionsMemo,
  countryCodes,
  countryCodesTreeViewOptionsMemo,
  shippingServices,
  shippingServicesTreeViewOptionsMemo,
};
