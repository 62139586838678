import { useCallback } from 'react';
import { OnRowPrepared } from '@/Types/OnRowPrepared';

export const useOnRowPrepared = <TData,>(customizeRow?: (rowData: TData) => string | undefined) => {
  const onRowPrepared = useCallback((e: OnRowPrepared<TData>) => {
    if (e.data) {
      const color = customizeRow?.(e.data);
      if (color) e.rowElement.style.background = color;
    }
  }, []);

  return {
    onRowPrepared,
  };
};
