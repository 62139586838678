import { InvoiceType } from '@/Enums';
import { OrderItemModel, ProductDynamicColumnValueModel, ProductModel } from '@/Models';
import { salesUtils } from '@/Pages/Sales/services/utils';
import { ProductTableRow, ProductTransferTableRow } from '@/Types';
import { OrderItemProductShortTableRow } from '@/Types/OrderItemProductShortTableRow';
import { v4 as uuidv4 } from 'uuid';

// Converting one product to table row
function convertProductToProductTableRow(product: ProductModel): ProductTableRow {
  const result: ProductTableRow = {
    ...product,
    productPackagesCount: product.productPackages?.length || 0,
    productPackagesString: product.productPackages?.map((item) => `${item.name}${item.sku}${item.ean}`).join(','),
    productCategoryName: product.productCategory.name,
    customProductFields: product.customProductFields
      ? (product.customProductFields.map((item) => {
          if (item.value == 'true' || item.value == 'false') return { ...item, value: item.value == 'true' };
          return item;
        }) as ProductDynamicColumnValueModel[])
      : [],
  };

  return result;
} // convertProductToProductTableRow

function convertProductToProductTransferTableRow(
  product: ProductModel,
  quantity: number,
  isLastModified: boolean,
): ProductTransferTableRow {
  return {
    ...product,
    isLastModified,
    quantity,
  };
} // convertProductToProductTransferTableRow

function convertProductTablerowToProductShortTableRow(product: ProductTableRow): OrderItemProductShortTableRow {
  const result: OrderItemProductShortTableRow = {
    category: product.productCategoryName,
    quantity: 1,
    priceNetto: 0,
    uid: '',
    priceBrutto: 0,
    totalPriceBruttoWithoutShipping: 0,
    totalPriceNettoWithoutShipping: 0,
    name: product.name,
    sku: product.sku,
    variant: product.variant,
    vat: product.vat,
    shippingCostBrutto: 0,
    shippingCostNetto: 0,
    id: 0,
  };

  return result;
}

// Converting product array to table row array
function convertProductsToProductTableRows(products: ProductModel[]): ProductTableRow[] {
  return products.map(convertProductToProductTableRow);
} // convertProductsToProductTableRows

// Converting product array to short product table row
function convertProductsTableRowToProductShortTableRows(products: ProductTableRow[]): OrderItemProductShortTableRow[] {
  return products.map(convertProductTablerowToProductShortTableRow);
}

function convertOrderItemModelToProductShortTableRow(orderItem: OrderItemModel): OrderItemProductShortTableRow {
  return {
    id: orderItem.id,
    sku: orderItem.sku,
    name: orderItem.title,
    uid: uuidv4(),
    variant: [
      orderItem.variantValue1,
      orderItem.variantValue2,
      orderItem.variantValue3,
      orderItem.variantValue4,
      orderItem.variantValue5,
    ]
      .filter((x) => x)
      .join(', '),
    category: '',
    priceNetto: orderItem.priceNetto,
    priceBrutto: orderItem.priceBrutto,
    quantity: orderItem.quantity,
    totalPriceBruttoWithoutShipping: salesUtils.calculateOrderItemPrice(InvoiceType.GrossInvoice, orderItem),
    totalPriceNettoWithoutShipping: salesUtils.calculateOrderItemPrice(InvoiceType.NetInvoice, orderItem),
    vat: orderItem.salesTaxPercent,
    shippingCostBrutto: orderItem.shippingCostBrutto,
    shippingCostNetto: orderItem.shippingCostNetto,
  };
}

function convertOrderItemModelsToProductShortTableRows(orders: OrderItemModel[]): OrderItemProductShortTableRow[] {
  return orders.map((order) => {
    return convertOrderItemModelToProductShortTableRow(order);
  });
}

export const productMapping = {
  convertProductToProductTableRow,
  convertProductToProductTransferTableRow,
  convertProductToProductShortTableRow: convertProductTablerowToProductShortTableRow,
  convertProductsToProductTableRows,
  convertProductsToProductShortTableRows: convertProductsTableRowToProductShortTableRows,
  convertOrderItemModelToProductShortTableRow,
  convertOrderItemModelsToProductShortTableRows,
};
