import { combineReducers } from '@reduxjs/toolkit';
import {
  accountChartReducer,
  AccountChartState,
} from '@/Pages/Administration/Accounting/services/accountChart/reducer';
import { vatRatesReducer, VatRatesState } from '@/Pages/Administration/Accounting/services/vatRates/reducer';

export type AccountingPageState = {
  accountChart: AccountChartState;
  vatRates: VatRatesState;
};

export const accountingPageReducer = combineReducers<AccountingPageState>({
  accountChart: accountChartReducer,
  vatRates: vatRatesReducer,
});
