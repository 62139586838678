import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactModel } from '@/Models';
import { addressBookAsyncActions } from './asyncActions';
import { AddressBookTableRow, Nullable } from '@/Types';

export type AddressBookSettingsState = {
  favoriteContacts: ContactModel[];
  selectedContact: Nullable<AddressBookTableRow>;
};

const initialState: AddressBookSettingsState = {
  favoriteContacts: [],
  selectedContact: null,
};

const addressBookSettingsSlice = createSlice({
  name: 'addressBook',
  initialState,
  reducers: {
    selectContact(state, action: PayloadAction<AddressBookTableRow | null>) {
      state.selectedContact = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addressBookAsyncActions.getFavoriteContacts.fulfilled, (state, action) => {
      state.favoriteContacts = action.payload;
    });
  },
});

const { actions, reducer } = addressBookSettingsSlice;

export const addressBookSettingsActions = actions;
export const addressBookSettingsReducer = reducer;
