import { Tour } from '@/Components/Tour/Tour';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tourSelectors } from '@/Redux/Tours/selectors';
import { tourActions } from '@/Redux/Tours/reducer';
import { useTours } from '@/Components/Tours/hooks/useTours';

export function Tours() {
  const dispatch = useDispatch();
  const runTour = useSelector(tourSelectors.runTour);
  const currentTour = useSelector(tourSelectors.currentTour);
  const activeTourSteps = useTours(runTour, currentTour);

  const toggleTour = (active: boolean) => {
    dispatch(tourActions.setRun(active));
  };

  if (activeTourSteps.length == 0) return null;

  return <Tour runTour={runTour} toggleTour={toggleTour} steps={activeTourSteps} />;
}
