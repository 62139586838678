import { useLocalization } from '@/Hooks';
import { DocumentSegmentType } from '@/Enums/_Microservices/OnlineArchive/DocumentSegmentType';

export const useDocumentSegmentOptions = (needAllOption?: boolean, needNotApplicableOption?: boolean) => {
  const {
    enums: { DocumentSegmentType: DocumentSegmentTypeLocalization },
  } = useLocalization();

  const documentSegments: { key: DocumentSegmentType; value: string }[] = [
    { key: DocumentSegmentType.Income, value: DocumentSegmentTypeLocalization.Income },
    { key: DocumentSegmentType.Expences, value: DocumentSegmentTypeLocalization.Expences },
    { key: DocumentSegmentType.Other, value: DocumentSegmentTypeLocalization.Other },
  ];

  if (needAllOption) {
    documentSegments.unshift({ key: DocumentSegmentType.All, value: DocumentSegmentTypeLocalization.All });
  } // if

  if (needNotApplicableOption) {
    documentSegments.push({
      key: DocumentSegmentType.NotApplicable,
      value: DocumentSegmentTypeLocalization.NotApplicable,
    });
  } // if

  return documentSegments;
};
