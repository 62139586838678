import { SplitOrderRequest } from '@/Api/MpOrders/Requests/SplitOrderRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MpOrdersApi } from '@/Api/MpOrders';
import { SplitOrderResponse } from '@/Api/MpOrders/Responses/SplitOrderResponse';

const splitOrder = createAsyncThunk<SplitOrderResponse, { orderId: number; request: SplitOrderRequest }>(
  'splitOrdersModal/split',
  (request: { orderId: number; request: SplitOrderRequest }) => {
    return MpOrdersApi.splitOrder(request.orderId, request.request);
  },
);

export const splitOrdersModalAsyncActions = {
  splitOrder,
};
