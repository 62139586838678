import React from 'react';
import styles from './Icons.scss';
import cn from 'classnames';
import { IconProps, Icons } from './IconsRes';
import { getIconClasses } from '@/Static/IconUtils';

type Props = {
  onClick: () => void;
} & IconProps;

export const EditIcon = ({ onClick, size = 'small', disabled = false, className }: Props) => {
  return (
    <img
      src={Icons.Edit}
      alt="img"
      className={cn(styles.icon, className, getIconClasses(size), { [styles.icon_disabled]: disabled })}
      onClick={onClick}
    />
  );
};
