import { ClassNameProps } from '@/Types';
import React, { useEffect, useRef, useState } from 'react';
import { ErrorTooltip } from '@/Components/Controls/ErrorTooltip/ErrorTooltip';
import cn from 'classnames';
import styles from './LogisticProductSelect.scss';
import { useLogisticProductIcons, useShippingServiceIcons } from './hooks/useLogisticProductSelectIcons';
import { Icon } from '@/Components/Icon/Icon';
import { useLocalization } from '@/Hooks';
import { OrderAutomatizationProductModel } from '@/Models/OrderUserProducts/OrderAutomatizationProductModel';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';

type Props = {
  products: OrderAutomatizationProductModel[];
  onChange: (p?: OrderAutomatizationProductModel) => void;
  currency?: string;
  readonly?: boolean;
} & ClassNameProps;

export const LogisticProductSelect = ({ className, products, onChange, currency, readonly = false }: Props) => {
  const {
    modalWindows: { orderLinkWithProduct },
  } = useLocalization();

  const { getLogisticProductIconByType } = useLogisticProductIcons();
  const { getShippingServiceIconByType } = useShippingServiceIcons();

  const autocompleteListRef = useRef<HTMLDivElement>(null);
  const autocompleteBlockRef = useRef<HTMLDivElement>(null);

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, [autocompleteListRef]);

  useEffect(() => {
    if (products.length) {
      setIsFocused(true);
    }
  }, [products]);

  const onClickOutside = (event: MouseEvent) => {
    if (autocompleteListRef.current && !autocompleteListRef.current.contains(event.target as HTMLElement)) {
      onBlur();
    }
  };

  const onBlur = () => {
    if (readonly) return;
    setTimeout(() => {
      setIsFocused(false);
    }, 100);
  };

  const onInputFocus = () => {
    if (readonly) return;
    setIsFocused(true);
  };

  return (
    <ErrorTooltip>
      <div ref={autocompleteListRef} className={cn(styles.logisticProductSelect, className)}>
        <input
          className={styles.input}
          autoComplete={'off'}
          placeholder={orderLinkWithProduct.Placeholders.SelectLogisticProduct}
          onFocus={onInputFocus}
          onKeyDown={(ev) => {
            if (ev.key == 'Escape') {
              ev.preventDefault();
              setIsFocused(false);
            }
          }}
          readOnly={readonly}
        />
        <div
          className={cn(styles.options, {
            [styles.active]: isFocused,
          })}
          ref={autocompleteBlockRef}
        >
          {products.map((product) => {
            const [from, to] = getShippingServiceIconByType(product.shippingServiceType);

            return (
              <div key={product.id} className={cn(styles.option)}>
                <div className={styles.logisticType}>
                  <Icon src={getLogisticProductIconByType(product.logisticTypeId)} alt={'error'} />
                  <div>{product.productTypeName?.slice(0, 6)}</div>
                </div>
                <div className={styles.shippingFrom}>
                  <Icon src={from.icon} alt={'shipping'} />
                  <div>{from.type}</div>
                </div>
                <div className={styles.shippingTo}>
                  <Icon src={to.icon} alt={'shipping'} />
                  <div>{to.type}</div>
                </div>
                <div className={styles.shippingPrice}>
                  {product.userProductPrice} {currency}
                </div>
                <div className={styles.shippingAction}>
                  <Button
                    text={orderLinkWithProduct.ShipCheaperContract.Order}
                    theme={BUTTON_THEMES.SUCCESS}
                    onClick={() => {
                      const input = autocompleteListRef.current?.children[0] as HTMLInputElement;
                      if (input) {
                        setIsFocused(false);
                        onChange(product);
                      }
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ErrorTooltip>
  );
};
