import { PackageModel } from '@/Models';

function inMM(x: PackageModel): PackageModel {
  x.height = x.height * 10;
  x.width = x.width * 10;
  x.length = x.length * 10;
  return x;
}

function inSM(x: PackageModel): PackageModel {
  x.height = x.height / 10;
  x.width = x.width / 10;
  x.length = x.length / 10;
  return x;
}

export const packagesUtils = {
  inMM,
  inSM,
};
