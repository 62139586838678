import { UserWarehouseTemplateModel } from 'src/Models/Warehouse/UserWarehouseTemplateModel';
import { UserWarehouseTemplateItemModel } from 'src/Models/Warehouse/UserWarehouseTemplateItemModel';
import { Nullable } from 'src/Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userWarehouseTemplateAsyncActions } from 'src/Pages/Settings/modules/WarehouseSettings/services/userWarehouseTemplates/asyncActions';

export type UserWarehouseTemplateSettingsState = {
  templates: UserWarehouseTemplateModel[];
  items: UserWarehouseTemplateItemModel[];
  needDeleteUnitId: Nullable<number>;
  needShowUnitModal: {
    visible: boolean;
    data?: Nullable<UserWarehouseTemplateItemModel>;
  };
};

const initialState: UserWarehouseTemplateSettingsState = {
  templates: [],
  items: [],
  needDeleteUnitId: null,
  needShowUnitModal: {
    visible: false,
  },
};

export const {
  actions: userWarehouseTemplateSettingsActions,
  reducer: userWarehouseTemplateSettingsReducer,
} = createSlice({
  name: 'userWarehouseTemplateSettings',
  initialState,
  reducers: {
    setNeedDeleteUnitId: (state, action: PayloadAction<Nullable<number>>) => {
      state.needDeleteUnitId = action.payload;
    },
    setNeedShowUnitModal: (
      state,
      action: PayloadAction<{ visible: boolean; data?: UserWarehouseTemplateItemModel }>,
    ) => {
      state.needShowUnitModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userWarehouseTemplateAsyncActions.getUserWarehouseTemplates.fulfilled, (state, action) => {
      state.templates = action.payload.templates;
    });
    builder.addCase(userWarehouseTemplateAsyncActions.createUserWarehouseTemplate.fulfilled, (state, action) => {
      state.templates = [action.payload.template, ...state.templates];
    });
    builder.addCase(userWarehouseTemplateAsyncActions.updateUserWarehouseTemplate.fulfilled, (state, action) => {
      const updatedItem = action.payload.template;
      state.templates = state.templates.map((x) => (x.id === updatedItem.id ? updatedItem : x));
    });
    builder.addCase(userWarehouseTemplateAsyncActions.deleteUserWarehouseTemplate.fulfilled, (state, action) => {
      state.templates = state.templates.filter((x) => x.id !== action.payload.templateId);
    });

    builder.addCase(userWarehouseTemplateAsyncActions.createUserWarehouseTemplateItem.fulfilled, (state, action) => {
      state.items = [action.payload.item, ...state.items];
    });
    builder.addCase(userWarehouseTemplateAsyncActions.updateUserWarehouseTemplateItem.fulfilled, (state, action) => {
      const updatedItem = action.payload.item;

      state.items = state.items.map((x) => (x.id === updatedItem.id ? updatedItem : x));
    });
    builder.addCase(userWarehouseTemplateAsyncActions.getUserWarehouseTemplateItems.fulfilled, (state, action) => {
      state.items = action.payload.items;
    });
    builder.addCase(userWarehouseTemplateAsyncActions.deleteUserWarehouseTemplateItem.fulfilled, (state, action) => {
      state.items = state.items.filter((x) => x.id !== action.payload.itemId);
    });
  },
});
