import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentFolderModel } from '@/Models/_Microservices/OnlineArchive/DocumentFolderModel';
import { asyncActions } from './asyncActions';

export type DocumentFoldersDataState = {
  documentFolders: DocumentFolderModel[];
};

const initialState: DocumentFoldersDataState = {
  documentFolders: [],
};

const documentFoldersDataSlice = createSlice({
  name: 'documentFoldersData',
  initialState: initialState,
  reducers: {
    increaseFolderDocumentsCount: (state, action: PayloadAction<{ id: number; value: number }>) => {
      state.documentFolders = state.documentFolders.map((documentFolder) => ({
        ...documentFolder,
        documentsCount:
          documentFolder.id === action.payload.id
            ? documentFolder.documentsCount + action.payload.value
            : documentFolder.documentsCount,
      }));
    },
    decreaseFolderDocumentsCount: (state, action: PayloadAction<{ id: number; value: number }>) => {
      state.documentFolders = state.documentFolders.map((documentFolder) => ({
        ...documentFolder,
        documentsCount:
          documentFolder.id === action.payload.id
            ? documentFolder.documentsCount - action.payload.value
            : documentFolder.documentsCount,
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getDocumentFolders.fulfilled, (state, action) => {
        state.documentFolders = action.payload;
      })
      .addCase(asyncActions.createDocumentFolder.fulfilled, (state, action) => {
        state.documentFolders.push(action.payload.documentFolder);
      })
      .addCase(asyncActions.updateDocumentFolder.fulfilled, (state, action) => {
        state.documentFolders = state.documentFolders.map((item) =>
          item.id === action.payload.documentFolder.id ? action.payload.documentFolder : item,
        );
      })
      .addCase(asyncActions.deleteDocumentFolder.fulfilled, (state, action) => {
        state.documentFolders = state.documentFolders.filter((item) => item.id !== action.payload);
      });
  },
});

export const documentFoldersDataReducer = documentFoldersDataSlice.reducer;
export const documentFoldersDataActions = documentFoldersDataSlice.actions;
export const documentFoldersDataAsyncActions = asyncActions;
