import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserProductsApi } from '@/Api/UserProducts/UserProductsApi';
import { OriginalProductModel } from '@/Models';
import { OriginalProductsApi } from '@/Api/OriginalProducts/OriginalProductsApi';
import {
  CreateUserProductRequest,
  CreateUserProductResponse,
  UpdateUserProductRequest,
  UpdateUserProductResponse,
} from '@/Api/UserProducts';
import { LogisticProduct } from '@/Models/LogisticProducts/LogisticProduct';

const getAllProductsRequest = createAsyncThunk('userProducts/getProducts', async () => {
  return await UserProductsApi.getProducts();
});

const getAllOriginalProductsRequest = createAsyncThunk<OriginalProductModel[]>(
  'userProducts/getOriginalProducts',
  async () => {
    return OriginalProductsApi.getProducts();
  },
);

const createProductRequest = createAsyncThunk<CreateUserProductResponse, CreateUserProductRequest>(
  'userProducts/create-product',
  async (request: CreateUserProductRequest) => {
    return await UserProductsApi.createProduct(request);
  },
);

const updateProductRequest = createAsyncThunk<UpdateUserProductResponse, UpdateUserProductRequest>(
  'userProducts/update-product',
  async (request: UpdateUserProductRequest) => {
    return await UserProductsApi.updateProduct(request);
  },
);

const deleteProductRequest = createAsyncThunk<number, number>('userProducts/delete-product', async (id: number) => {
  return UserProductsApi.deleteProduct(id);
});

const getLogisticProducts = createAsyncThunk<LogisticProduct[]>('userProducts/logistic-products', async () => {
  return UserProductsApi.getLogisticProducts();
});

export const userProductsPageAsyncActions = {
  getAllProductsRequest,
  getAllOriginalProductsRequest,
  createProductRequest,
  updateProductRequest,
  deleteProductRequest,
  getLogisticProducts,
};
