import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetUserWarehousesResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/GetUserWarehousesResponse';
import { GetUserWarehouseResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/GetUserWarehouseResponse';
import { GetUserWarehouseRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/GetUserWarehouseRequest';
import { DeleteUserWarehouseUnitRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/DeleteUserWarehouseUnitRequest';
import { DeleteUserWarehouseUnitResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/DeleteUserWarehouseUnitResponse';
import { GetWarehouseTypesForWarehouseRequest } from 'src/Api/UserWarehouse/UserWarehouses/Requests/GetWarehouseTypesForWarehouseRequest';
import { GetWarehouseTypesForWarehouseResponse } from 'src/Api/UserWarehouse/UserWarehouses/Responses/GetWarehouseTypesForWarehouseResponse';
import { CreateUserWarehouseUnitRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/CreateUserWarehouseUnitRequest';
import { CreateUserWarehouseUnitResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/CreateUserWarehouseUnitResponse';
import { UpdateUserWarehouseUnitRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/UpdateUserWarehouseUnitRequest';
import { UpdateUserWarehouseUnitResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/UpdateUserWarehouseUnitResponse';
import { CreateUnitsByTemplateRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/CreateUnitsByTemplateRequest';
import { CreateUnitsByTemplateResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/CreateUnitsByTemplateResponse';
import { GetUnitPostingsRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/GetUnitPostingsRequest';
import { GetUnitPostingsResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/GetUnitPostingsResponse';
import { GetUnitHistoryRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/GetUnitHistoryRequest';
import { GetUnitHistoryResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/GetUnitHistoryResponse';
import { GetAvailableUnitsFromTemplateRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/GetAvailableUnitsFromTemplateRequest';
import { GetAvailableUnitsFromTemplateResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/GetAvailableUnitsFromTemplateResponse';

const url = ApiRoutingUtils.createUrl('user-warehouse');
const unitUrl = ApiRoutingUtils.createUrl('user-warehouse-unit');
const postingUrl = ApiRoutingUtils.createUrl('user-warehouse-posting');
const historyUrl = ApiRoutingUtils.createUrl('user-warehouse-unit-history');

async function getUserWarehouses(): Promise<GetUserWarehousesResponse> {
  return rest.get<GetUserWarehousesResponse>(url);
}

async function getUserWarehouse(request: GetUserWarehouseRequest): Promise<GetUserWarehouseResponse> {
  return rest.get<GetUserWarehouseResponse>(`${url}/${request.id}`);
}

async function createWarehouseUnitsByTemplate(
  request: CreateUnitsByTemplateRequest,
): Promise<CreateUnitsByTemplateResponse> {
  return rest.post(`${unitUrl}/by-template`, request);
}

async function createWarehouseUnit(request: CreateUserWarehouseUnitRequest): Promise<CreateUserWarehouseUnitResponse> {
  return rest.post<CreateUserWarehouseUnitRequest, CreateUserWarehouseUnitResponse>(unitUrl, request);
}

async function getAvailableUnitsFromTemplate(
  request: GetAvailableUnitsFromTemplateRequest,
): Promise<GetAvailableUnitsFromTemplateResponse> {
  return rest.get<GetAvailableUnitsFromTemplateResponse>(
    `${unitUrl}/template-item-by-template/${request.unitId}/${request.isEntered}`,
  );
}

async function updateWarehouseUnit(request: UpdateUserWarehouseUnitRequest): Promise<UpdateUserWarehouseUnitResponse> {
  return rest.put<UpdateUserWarehouseUnitRequest, UpdateUserWarehouseUnitResponse>(unitUrl, request);
}

async function deleteWarehouseUnit(request: DeleteUserWarehouseUnitRequest): Promise<DeleteUserWarehouseUnitResponse> {
  return rest.delete(`${unitUrl}/${request.id}`, {});
}

async function getWarehouseTypesForWarehouse(
  request: GetWarehouseTypesForWarehouseRequest,
): Promise<GetWarehouseTypesForWarehouseResponse> {
  return rest.get<GetWarehouseTypesForWarehouseResponse>(`${url}/types/${request.id}`);
}

async function getUnitPostings(request: GetUnitPostingsRequest): Promise<GetUnitPostingsResponse> {
  return rest.get(`${postingUrl}/${request.warehouseUnitId}`);
}

async function getUnitHistory(request: GetUnitHistoryRequest): Promise<GetUnitHistoryResponse> {
  return rest.get(`${historyUrl}/${request.unitId}`);
}

export const UserWarehousesApi = {
  getUserWarehouses,
  getUserWarehouse,
  createWarehouseUnitsByTemplate,
  createWarehouseUnit,
  getAvailableUnitsFromTemplate,
  updateWarehouseUnit,
  deleteWarehouseUnit,
  getWarehouseTypesForWarehouse,
  getUnitPostings,
  getUnitHistory,
};
