import { DeleteUserWarehouseLocationRequest } from 'src/Api/UserWarehouse/UserWarehouseLocations/Requests/DeleteUserWarehouseLocationRequest';
import { UpdateUserWarehouseLocationResponse } from 'src/Api/UserWarehouse/UserWarehouseLocations/Responses/UpdateUserWarehouseLocationResponse';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserWarehouseLocationsApi } from 'src/Api/UserWarehouse/UserWarehouseLocations/UserWarehouseLocationsApi';
import { CreateUserWarehouseLocationResponse } from 'src/Api/UserWarehouse/UserWarehouseLocations/Responses/CreateUserWarehouseLocationResponse';
import { CreateUserWarehouseLocationRequest } from 'src/Api/UserWarehouse/UserWarehouseLocations/Requests/CreateUserWarehouseLocationRequest';
import { UpdateUserWarehouseLocationRequest } from 'src/Api/UserWarehouse/UserWarehouseLocations/Requests/UpdateUserWarehouseLocationRequest';
import { DeleteUserWarehouseLocationResponse } from 'src/Api/UserWarehouse/UserWarehouseLocations/Responses/DeleteUserWarehouseLocationResponse';
import { GetUserWarehouseLocationsResponse } from 'src/Api/UserWarehouse/UserWarehouseLocations/Responses/GetUserWarehouseLocationsResponse';

const getUserWarehouseLocations = createAsyncThunk<GetUserWarehouseLocationsResponse, void>(
  'user-warehouse-locations/get',
  () => {
    return UserWarehouseLocationsApi.getUserWarehouseLocations();
  },
);

const createUserWarehouseLocation = createAsyncThunk<
  CreateUserWarehouseLocationResponse,
  CreateUserWarehouseLocationRequest
>('user-warehouse-locations/create', (request) => {
  return UserWarehouseLocationsApi.createUserWarehouseLocation(request);
});

const updateUserWarehouseLocation = createAsyncThunk<
  UpdateUserWarehouseLocationResponse,
  UpdateUserWarehouseLocationRequest
>('user-warehouse-locations/update', (request) => {
  return UserWarehouseLocationsApi.updateUserWarehouseLocation(request);
});

const deleteUserWarehouseLocation = createAsyncThunk<
  DeleteUserWarehouseLocationResponse,
  DeleteUserWarehouseLocationRequest
>('user-warehouse-locations/delete', (request) => {
  return UserWarehouseLocationsApi.deleteUserWarehouseLocation(request);
});

export const userWarehouseLocationAsyncActions = {
  getUserWarehouseLocations,
  createUserWarehouseLocation,
  updateUserWarehouseLocation,
  deleteUserWarehouseLocation,
};
