import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { AppUserShortModel } from '@/Models/AppUserShortModel';
import { AppUserInfoModel } from '@/Models/AppUserInfoModel';
import { UpdateAppUserInfoRequest } from './UpdateAppUserInfoRequest';

const url = ApiRoutingUtils.createUrl('admin-users');

async function getUsers(): Promise<AppUserShortModel[]> {
  return rest.get(url);
}

async function getUserInfo(id: number): Promise<AppUserInfoModel> {
  return rest.get(`${url}/${id}`);
}

async function updateUserInfo(request: UpdateAppUserInfoRequest): Promise<void> {
  return rest.post(url, request);
}

export const AdminUsersApi = {
  getUsers,
  getUserInfo,
  updateUserInfo,
};
