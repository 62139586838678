import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SynchOrdersCompleteModalState = {
  newSalesCount: number;
  updatedSalesCount: number;
  newPurchasesCount: number;
  updatedPurchasesCount: number;
};

const initialState: SynchOrdersCompleteModalState = {
  newSalesCount: 0,
  updatedSalesCount: 0,
  newPurchasesCount: 0,
  updatedPurchasesCount: 0,
};

const synchOrdersCompleteModalSlice = createSlice({
  name: 'synchOrdersCompleteModal',
  initialState,
  reducers: {
    setSalesInfo: (
      state,
      action: PayloadAction<Pick<SynchOrdersCompleteModalState, 'newSalesCount' | 'updatedSalesCount'>>,
    ) => {
      state.newSalesCount = action.payload.newSalesCount;
      state.updatedSalesCount = action.payload.updatedSalesCount;
    },
    setPurchasesInfo: (
      state,
      action: PayloadAction<Pick<SynchOrdersCompleteModalState, 'newPurchasesCount' | 'updatedPurchasesCount'>>,
    ) => {
      state.newPurchasesCount = action.payload.newPurchasesCount;
      state.updatedPurchasesCount = action.payload.updatedPurchasesCount;
    },
  },
});

const { actions, reducer } = synchOrdersCompleteModalSlice;

export const synchOrdersCompleteModalReducer = reducer;
export const synchOrdersCompleteModalActions = actions;
export const synchOrdersCompleteModalAsyncActions = {};
