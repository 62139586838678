import { ApiRoutingUtils } from 'src/Routing';
import { rest } from 'src/Api/Rest';
import { GetUserWarehouseTypesResponse } from 'src/Api/UserWarehouse/UserWarehouseTypes/Responses/GetUserWarehouseTypesResponse';
import { CreateUserWarehouseTypeRequest } from 'src/Api/UserWarehouse/UserWarehouseTypes/Requests/CreateUserWarehouseTypeRequest';
import { CreateUserWarehouseTypeResponse } from 'src/Api/UserWarehouse/UserWarehouseTypes/Responses/CreateUserWarehouseTypeResponse';
import { UpdateUserWarehouseTypeRequest } from 'src/Api/UserWarehouse/UserWarehouseTypes/Requests/UpdateUserWarehouseTypeRequest';
import { UpdateUserWarehouseTypeResponse } from 'src/Api/UserWarehouse/UserWarehouseTypes/Responses/UpdateUserWarehouseTypeResponse';
import { DeleteUserWarehouseTypeRequest } from 'src/Api/UserWarehouse/UserWarehouseTypes/Requests/DeleteUserWarehouseTypeRequest';
import { DeleteUserWarehouseTypeResponse } from 'src/Api/UserWarehouse/UserWarehouseTypes/Responses/DeleteUserWarehouseTypeResponse';

const url = ApiRoutingUtils.createUrl('user-warehouse-types');

async function getUserWarehouseTypes(): Promise<GetUserWarehouseTypesResponse> {
  return rest.get<GetUserWarehouseTypesResponse>(url);
}

async function createUserWarehouseType(
  request: CreateUserWarehouseTypeRequest,
): Promise<CreateUserWarehouseTypeResponse> {
  return rest.post(url, request);
}

async function updateUserWarehouseType(
  request: UpdateUserWarehouseTypeRequest,
): Promise<UpdateUserWarehouseTypeResponse> {
  return rest.put(url, request);
}

async function deleteUserWarehouseType(
  request: DeleteUserWarehouseTypeRequest,
): Promise<DeleteUserWarehouseTypeResponse> {
  return rest.delete(`${url}/${request.id}`, null);
}

export const UserWarehouseTypesApi = {
  getUserWarehouseTypes,
  createUserWarehouseType,
  updateUserWarehouseType,
  deleteUserWarehouseType,
};
