import { ApiRoutingUtils } from '@/Routing';
import { rest } from '../Rest';
import { GetMandatResponse } from './GetMandatResponse';
import { CreateMandatRequest } from '@/Api/Mandats/CreateMandatRequest';
import { CreateMandatResponse } from '@/Api/Mandats/CreateMandatResponse';
import { DeleteMandatRequest } from '@/Api/Mandats/DeleteMandatRequest';
import { DeleteMandatResponse } from '@/Api/Mandats/DeleteMandatResponse';
import { UpdateMandatRequest } from '@/Api/Mandats/UpdateMandatRequest';
import { UpdateMandatResponse } from '@/Api/Mandats/UpdateMandatResponse';
import { GetCountryVatInfoResponse } from '@/Api/Mandats/GetCountryVatInfoResponse';
import { GetCountryVatInfoRequest } from '@/Api/Mandats/GetCountryVatInfoRequest';

const url = ApiRoutingUtils.createUrl('mandats');

async function getMandats(): Promise<GetMandatResponse> {
  return rest.get(url);
}

async function createMandat(request: CreateMandatRequest): Promise<CreateMandatResponse> {
  return rest.post(url, request);
}

async function updateMandat(request: UpdateMandatRequest): Promise<UpdateMandatResponse> {
  return rest.put(url, request);
}

async function deleteMandat(request: DeleteMandatRequest): Promise<DeleteMandatResponse> {
  return rest.delete(url, request);
}

async function getVatInfoByCountry(request: GetCountryVatInfoRequest): Promise<GetCountryVatInfoResponse> {
  return rest.get(`${url}/vat?countryCode=${request.countryCode}`);
}

export const MandatApi = {
  getMandats,
  createMandat,
  deleteMandat,
  updateMandat,
  getVatInfoByCountry,
};
