import { DateRangeParamType } from '@/Api';
import { useRequired } from '@/Hooks/Validation';
import { OrderFilterModel } from '@/Types';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { purchasesPageActions, purchasesPageAsyncActions, purchasesPageSelectors } from '@/Pages/Purchases/services';

export type FormData = OrderFilterModel & {
  dateRangeTuple: string;
};

export const usePurchaseTableFiltersForm = (orderFilters: OrderFilterModel) => {
  const dispatch = useDispatch();
  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const required = useRequired();
  const purchases = useSelector(purchasesPageSelectors.purchases);
  const updatedPurchasesExists = useSelector(purchasesPageSelectors.updatedPurchasesExists);

  useEffect(() => {
    if (!purchases?.length || updatedPurchasesExists) {
      dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
    }
  }, []);

  useEffect(() => {
    register('mpAccountIds');
    setValue('mpAccountIds', orderFilters.mpAccountIds);
  }, [orderFilters.mpAccountIds]);

  useEffect(() => {
    if (orderFilters.dateRange.timeFrom && orderFilters.dateRange.timeTo) {
      setValue('dateRangeTuple', `${orderFilters.dateRange.timeFrom} - ${orderFilters.dateRange.timeTo}`);
    } // if
  }, [orderFilters.dateRange]);

  const onSubmit = useCallback((data: FormData) => {
    const timeFrom = data.dateRangeTuple.split(' - ')[0];
    const timeTo = data.dateRangeTuple.split(' - ')[1];

    data.dateRange = {
      timeFrom,
      timeTo,
      rangeType: DateRangeParamType.CreationTime,
    };

    dispatch(
      purchasesPageActions.setOrderFilters({
        ...data,
      }),
    );
    dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
  }, []);

  return {
    methods: {
      register,
      watch,
      getValues,
      setValue,
      errors,
      handleSubmit,
    },
    rules: {
      required,
    },
    onSubmit: handleSubmit(onSubmit),
  };
};
