import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/Redux/RootReducer';

const generateDocumentNumbersConfirmationModal = (state: RootState) =>
  state.modalWindows.generateDocumentNumbersConfirmationModalState;

const numberGenerateRequest = createSelector(
  generateDocumentNumbersConfirmationModal,
  (state) => state.numberGenerateRequest,
);

const numberGenerateType = createSelector(
  generateDocumentNumbersConfirmationModal,
  (state) => state.numberGenerateRequest?.genType,
);

export const generateDocumentNumberConfirmationModalSelectors = {
  numberGenerateRequest,
  numberGenerateType,
};
