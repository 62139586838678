export enum PaymentMethod { //TODO: To database
  CVS = 'CVS',
  CashOnDelivery = 'CashOnDelivery',
  EbayPayment = 'EbayPayment',
  AmazonPayment = 'AmazonPayment',
  KauflandPayment = 'KauflandPayment',
  OzonPayment = 'OzonPayment',

  // Payment methods not from marketplaces
  BankTransfer = 'BankTransfer',
  DirectDebit = 'DirectDebit',
  CreditCard = 'CreditCard',
  GooglePay = 'GooglePay',
  AmazonPay = 'AmazonPay',
  ApplePay = 'ApplePay',
  PayPal = 'PayPal',
}
