import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserProductCategoryApi } from '@/Api/UserProductCategory/UserProductCategoryApi';
import { GetCategoriesResponse } from '@/Api/UserProductCategory/Responses/GetCategoriesResponse';
import { CreateCategoryResponse } from '@/Api/UserProductCategory/Responses/CreateCategoryResponse';
import { CreateCategoryRequest } from '@/Api/UserProductCategory/Requests/CreateCategoryRequest';
import { UpdateCategoryResponse } from '@/Api/UserProductCategory/Responses/UpdateCategoryResponse';
import { UpdateCategoryRequest } from '@/Api/UserProductCategory/Requests/UpdateCategoryRequest';
import { DeleteCategoryResponse } from '@/Api/UserProductCategory/Responses/DeleteCategoryResponse';
import { DeleteCategoryRequest } from '@/Api/UserProductCategory/Requests/DeleteCategoryRequest';

const getProductCategories = createAsyncThunk<GetCategoriesResponse>(
  'productsSettings/get-product-categories',
  async () => {
    return UserProductCategoryApi.getCategories();
  },
);

const createProductCategory = createAsyncThunk<CreateCategoryResponse, CreateCategoryRequest>(
  'productsSettings/create-product-category',
  async (request) => {
    return UserProductCategoryApi.createCategory(request);
  },
);

const updateProductCategory = createAsyncThunk<UpdateCategoryResponse, UpdateCategoryRequest>(
  'productsSettings/update-product-category',
  async (request) => {
    return UserProductCategoryApi.updateCategory(request);
  },
);

const deleteProductCategory = createAsyncThunk<DeleteCategoryResponse, DeleteCategoryRequest>(
  'productsSettings/delete-product-category',
  async (request) => {
    return UserProductCategoryApi.deleteCategory(request);
  },
);

export const productSettingsAsyncActions = {
  getProductCategories,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
};
