import React from 'react';
import { OrderTableRow } from '@/Types';
import styles from './OtherTab.scss';
import { salesPageAsyncActions, salesPageSelectors } from '@/Pages/Sales/services';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentIcon } from '@/Static';
import { useCustomerNumber } from './hooks/useCustomerNumber';
import { NumberGenerationType } from '@/Enums';
import { Label } from '@/Components/Controls/Label/Label';
import { FormCheckbox } from '@/Components/Controls/Checkbox/FormCheckbox';
import { Tooltip } from '@/Components/Tooltip/Tooltip';
import { FormValue } from '@/Components/Controls/FormValue/FormValue';
import { useLocalization } from '@/Hooks';

type FormData = Pick<
  OrderTableRow,
  'buyerEmail' | 'customerNumber' | 'shippingPhoneNumber' | 'ebayPlus' | 'buyerVatNumber'
>;

export const OtherTab = () => {
  const {
    salesPage: {
      GeneralInfo: { Other },
    },
  } = useLocalization();

  const dispatch = useDispatch();
  const selectedSale = useSelector(salesPageSelectors.selectedSale);

  const {
    fields: { customerNumberProps },
  } = useCustomerNumber();

  return (
    <div className={styles.otherTab}>
      <Label text={Other.Email} />
      <FormValue value={selectedSale?.buyerEmail} />

      <Label text={Other.CustomerNumber} />
      <div className={styles.inputWithIconWrapper}>
        <FormValue value={customerNumberProps.value} />
        <Tooltip text={customerNumberProps.disabledDocumentMessage} shouldShowOnHover={true}>
          <DocumentIcon
            disabled={customerNumberProps.isDocumentIconDisabled}
            onClick={() => {
              if (selectedSale) {
                dispatch(
                  salesPageAsyncActions.generateNumberForOrder({
                    orderIds: [selectedSale.id],
                    genType: NumberGenerationType.Customer,
                  }),
                );
              }
            }}
          />
        </Tooltip>
      </div>

      <Label text={Other.Phone} />
      <FormValue value={selectedSale?.shippingPhoneNumber} />

      <Label text={Other.Vat} />
      <FormValue value={selectedSale?.buyerVatNumber} />

      <Label text={Other.EbayPlus} />
      <FormCheckbox<FormData> name={'ebayPlus'} valueForSet={selectedSale?.ebayPlus} />
    </div>
  );
};
