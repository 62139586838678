import { DigitalProductShopModel, ShopCartItemModel, SubscriptionShopModel } from '@/Models';
import { asyncActions } from './asyncActions';
import { createSlice } from '@reduxjs/toolkit';

export type ShopState = {
  subscriptions: SubscriptionShopModel[];
  digitalProducts: DigitalProductShopModel[];
  shopCartItems: ShopCartItemModel[];
  documentTemplates: DigitalProductShopModel[];
};

const initialState: ShopState = {
  subscriptions: [],
  digitalProducts: [],
  shopCartItems: [],
  documentTemplates: [],
};

const shopSlice = createSlice({
  name: 'shopPage',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getShopProducts.fulfilled, (state, action) => {
        state.subscriptions = action.payload.subscriptions;
        state.digitalProducts = action.payload.digitalProducts;
        state.shopCartItems = action.payload.shopCart;
      })
      .addCase(asyncActions.getTemplatesForShop.fulfilled, (state, action) => {
        state.documentTemplates = action.payload.digitalProductModels;
      })
      .addCase(asyncActions.appProductToCart.fulfilled, (state, action) => {
        state.shopCartItems.push(action.payload.shopCartItem);
      })
      .addCase(asyncActions.deleteProductFromCart.fulfilled, (state, action) => {
        state.shopCartItems = state.shopCartItems.filter((item) => item.id !== action.payload);
      });
  },
});

export const shopPageReducer = shopSlice.reducer;
export const shopPageActions = shopSlice.actions;
export const shopPageAsyncActions = asyncActions;
