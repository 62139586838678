import { rest } from '@/Api/Rest';
import { ApiRoutingUtils } from '@/Routing';
import { GetRegionalSettingsResponse } from '.';

const url = ApiRoutingUtils.createUrl('regional-settings');

function getRegionalSettings(): Promise<GetRegionalSettingsResponse> {
  return rest.get(url);
}

export const RegionalSettingsApi = {
  getRegionalSettings,
};
