import { useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { useLocalization } from '@/Hooks';
import { useMemo } from 'react';

export function useIsOrderMergingDisabled() {
  const selectedSales = useSelector(salesPageSelectors.selectedSales);

  const {
    menu: { MergeOrder },
  } = useLocalization();

  const ordersMergingDisabledReason = useMemo(() => {
    const isOneOrLessOrderSelected = selectedSales.length <= 1;
    if (isOneOrLessOrderSelected) {
      return MergeOrder.OneOrLessOrderSelectedMessage;
    }

    const isOrderSent = Boolean(selectedSales?.some((o) => Boolean(o.shippedTime)));
    if (isOrderSent) {
      return MergeOrder.SentOrderSelectedMessage;
    }

    const isBlocked = Boolean(selectedSales?.some((o) => Boolean(o.isBlocked)));
    if (isBlocked) {
      return MergeOrder.OrderBlockedMessage;
    }

    const isInvoiceWithoutCancelInvoice = Boolean(selectedSales?.some((o) => Boolean(o.currentInvoiceNumber)));
    if (isInvoiceWithoutCancelInvoice) {
      return MergeOrder.InvoiceWithoutCancelMessage;
    }

    const isDifferentStatuses = Boolean([...new Set(selectedSales?.map((x) => x.orderStatus))]?.length > 1);
    if (isDifferentStatuses) {
      return MergeOrder.DifferentOrderStatusesMessage;
    }

    const isCancelStatusIsNotApplicable = Boolean(selectedSales?.some((x) => Boolean(x.cancelStatus)));
    if (isCancelStatusIsNotApplicable) {
      return MergeOrder.CancelStatusIsDifferentFromNotApplicableMessage;
    }

    const isTermsOfPaymentAreDifferent = Boolean([...new Set(selectedSales?.map((x) => x.termsOfPayment))].length > 1);
    if (isTermsOfPaymentAreDifferent) {
      return MergeOrder.DifferentTermsOfPaymentMessage;
    }

    const isPaymentMethodsDifferent = Boolean([...new Set(selectedSales?.map((x) => x.paymentMethod))].length > 1);
    if (isPaymentMethodsDifferent) {
      return MergeOrder.DifferentPaymentMethodsMessage;
    }

    const isAnyArchived = Boolean(selectedSales?.some((x) => !!x.archived));
    if (isAnyArchived) {
      return MergeOrder.OrderIsArchived;
    }

    return '';
  }, [selectedSales]);

  return {
    isOrdersMergingDisabled: Boolean(ordersMergingDisabledReason),
    ordersMergingDisabledReason,
  };
}
