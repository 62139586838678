import { ApiRoutingUtils } from '@/Routing';
import {
  CreateUserProductRequest,
  CreateUserProductResponse,
  UpdateUserProductRequest,
  UpdateUserProductResponse,
} from '.';
import { UserProductModel } from '@/Models';
import { rest } from '@/Api/Rest';
import { LogisticProduct } from '@/Models/LogisticProducts/LogisticProduct';

const url = ApiRoutingUtils.createUrl('user-products');

async function getProducts(): Promise<UserProductModel[]> {
  return rest.get(url);
}

async function createProduct(request: CreateUserProductRequest): Promise<CreateUserProductResponse> {
  return rest.post(url, request);
}

async function updateProduct(request: UpdateUserProductRequest): Promise<UpdateUserProductResponse> {
  return rest.put(url, request);
}

async function deleteProduct(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

async function getLogisticProducts(): Promise<LogisticProduct[]> {
  return rest.get(`${url}/logistic-products`);
}

export const UserProductsApi = {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  getLogisticProducts,
};
