import { ApiRoutingUtils } from '@/Routing';
import { GetProductCategoriesResponse } from './';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('product-categories');

async function getProductCategories(): Promise<GetProductCategoriesResponse> {
  return rest.get(url);
}

export const ProductCategoriesApi = {
  getProductCategories,
};
