import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useLocalization } from '@/Hooks';
import { dateTimeUtils, stringUtils } from '@/Utils';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';

type OrderNumbersFields = 'orderNumberProps';

type UseGeneralInfoType = {
  fields: { [key in OrderNumbersFields]: FieldState };
};

type FieldState = {
  value: string;
  isGenerateIconDisabled?: boolean;
  disableGenerateMessage?: string;
};

export function useGeneralInfoNumbers(): UseGeneralInfoType {
  const selectedPurchase = useSelector(purchasesPageSelectors.selectedPurchase);
  const {
    models: {
      OrderTableRow: { OrderNumber },
    },
  } = useLocalization();

  return useMemo<UseGeneralInfoType>(() => {
    const isOrderNumberExists = Boolean(selectedPurchase?.orderNumberCreatedTime);
    return {
      fields: {
        orderNumberProps: {
          value: selectedPurchase?.orderNumber ?? '',
          isGenerateIconDisabled: isOrderNumberExists,
          disableGenerateMessage: isOrderNumberExists
            ? stringUtils.insertParamsToString(OrderNumber.DisabledDocumentMessage, [
                selectedPurchase?.orderNumber ?? '',
                dateTimeUtils.utcStringToLocalString(selectedPurchase?.orderNumberCreatedTime) ?? '',
              ])
            : '',
        },
      },
    };
  }, [selectedPurchase, OrderNumber]);
}
