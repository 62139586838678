import { ApiRoutingUtils } from 'src/Routing';
import { rest } from 'src/Api/Rest';
import { CreateUserWarehouseLocationRequest } from 'src/Api/UserWarehouse/UserWarehouseLocations/Requests/CreateUserWarehouseLocationRequest';
import { CreateUserWarehouseLocationResponse } from 'src/Api/UserWarehouse/UserWarehouseLocations/Responses/CreateUserWarehouseLocationResponse';
import { UpdateUserWarehouseLocationRequest } from 'src/Api/UserWarehouse/UserWarehouseLocations/Requests/UpdateUserWarehouseLocationRequest';
import { UpdateUserWarehouseLocationResponse } from 'src/Api/UserWarehouse/UserWarehouseLocations/Responses/UpdateUserWarehouseLocationResponse';
import { DeleteUserWarehouseLocationRequest } from 'src/Api/UserWarehouse/UserWarehouseLocations/Requests/DeleteUserWarehouseLocationRequest';
import { DeleteUserWarehouseLocationResponse } from 'src/Api/UserWarehouse/UserWarehouseLocations/Responses/DeleteUserWarehouseLocationResponse';
import { GetUserWarehouseLocationsResponse } from 'src/Api/UserWarehouse/UserWarehouseLocations/Responses/GetUserWarehouseLocationsResponse';

const url = ApiRoutingUtils.createUrl('user-warehouse-location');

async function getUserWarehouseLocations(): Promise<GetUserWarehouseLocationsResponse> {
  return rest.get<GetUserWarehouseLocationsResponse>(url);
}

async function createUserWarehouseLocation(
  request: CreateUserWarehouseLocationRequest,
): Promise<CreateUserWarehouseLocationResponse> {
  return rest.post(url, request);
}

async function updateUserWarehouseLocation(
  request: UpdateUserWarehouseLocationRequest,
): Promise<UpdateUserWarehouseLocationResponse> {
  return rest.put(url, request);
}

async function deleteUserWarehouseLocation(
  request: DeleteUserWarehouseLocationRequest,
): Promise<DeleteUserWarehouseLocationResponse> {
  return rest.delete(`${url}/${request.id}`, null);
}

export const UserWarehouseLocationsApi = {
  getUserWarehouseLocations,
  createUserWarehouseLocation,
  updateUserWarehouseLocation,
  deleteUserWarehouseLocation,
};
