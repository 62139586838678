import { CreatePaymentMethodRequest } from '@/Api/PaymentMethods/CreatePaymentMethodRequest';
import { CreatePaymentMethodResponse } from '@/Api/PaymentMethods/CreatePaymentMethodResponse';
import { GetPaymentMethodsResponse } from '@/Api/PaymentMethods/GetPaymentMethodsResponse';
import { PaymentMethodsApi } from '@/Api/PaymentMethods/PaymentMethodsApi';
import { PaymentInfoModel } from '@/Models/PaymentInfoModel';
import { createAsyncThunk } from '@reduxjs/toolkit';

const createPaymentMethod = createAsyncThunk<CreatePaymentMethodResponse, CreatePaymentMethodRequest>(
  'payment-methods/create',
  async (request: CreatePaymentMethodRequest) => {
    return PaymentMethodsApi.createPaymentMethod(request);
  },
);

const getUserPaymentMethods = createAsyncThunk<GetPaymentMethodsResponse, void>('payment-methods/get', async () => {
  return PaymentMethodsApi.getUserPaymentMethods();
});

const deletePaymentMethod = createAsyncThunk<number, number>('payment-methods/delete', async (id: number) => {
  return PaymentMethodsApi.deletePaymentMethod(id);
});

const getMarketUserPaymentInfo = createAsyncThunk<PaymentInfoModel, void>(
  'payment-methods/get-market-payment-info',
  async () => {
    return PaymentMethodsApi.getMarketUserPaymentInfo();
  },
);

export const paymentMethodsAsyncActions = {
  createPaymentMethod,
  getUserPaymentMethods,
  deletePaymentMethod,
  getMarketUserPaymentInfo,
};
