import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { OrderMarker } from '@/Enums/OrderMarker';
import { useForm } from '@/Hooks/useFormWrapper';
import { useRequired } from '@/Hooks/Validation/useRequired';
import { purchasesPageAsyncActions } from '@/Pages/Purchases/services';
import { salesPageActions, salesPageAsyncActions } from '@/Pages/Sales/services';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { OrderTableRow } from '@/Types';
import { Nullable } from '@/Types/Nullable';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { OrderStatusFilterGroups } from 'src/Enums';

type FormData = {
  orderMarker: Nullable<OrderMarker>;
};

export const useChangeOtherStatusesForm = (
  selectedOrders: OrderTableRow[],
  closeModalWindow: () => void,
  isSalesPage: boolean,
) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      orderMarker: null,
    },
  });

  const orderIds = useMemo(() => selectedOrders.map((o) => o.id), [selectedOrders]);

  //  rules
  const required = useRequired();

  const submit = useCallback(
    ({ orderMarker }: FormData) => {
      if (!orderMarker) return;

      const afterChange = () => {
        if (!isSalesPage) {
          dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
          return;
        }

        const orderMarkerToFilterMap = {
          [OrderMarker.NotPaid]: OrderStatusFilterGroups.NotPaidGroup,
          [OrderMarker.NotShipped]: OrderStatusFilterGroups.NotShippedGroup,
          [OrderMarker.Shipped]: OrderStatusFilterGroups.ShippedGroup,
          [OrderMarker.Paid]: [], // It also runs background task, so it will update after operation complete
        };

        const filters = orderMarkerToFilterMap[orderMarker as keyof typeof orderMarkerToFilterMap];

        if (!filters) {
          dispatch(salesPageAsyncActions.getOrdersByIds(orderIds));
          return;
        }

        dispatch(
          salesPageActions.removeOrdersFromStateByCurrentFilters({
            orderIds,
            filters,
          }),
        );
      };

      switch (orderMarker) {
        case OrderMarker.AddressEditDisabled:
        case OrderMarker.AddressEditEnabled:
          MpOrdersMarkingApi.changeAddressEditDisabled({ orderIds, orderMarker }).then(() => {
            afterChange();
          });
          break;

        case OrderMarker.Archived:
        case OrderMarker.NotArchived:
          MpOrdersMarkingApi.changeOrderArchived({ orderIds, orderMarker }).then(() => {
            afterChange();
          });
          break;

        case OrderMarker.CanceledNotPaid:
        case OrderMarker.NotCanceledNotPaid:
          MpOrdersMarkingApi.changeOrderCanceledNotPaid({ orderIds, orderMarker }).then(() => {
            afterChange();
          });
          break;

        case OrderMarker.Exported:
        case OrderMarker.NotExported:
          MpOrdersMarkingApi.changeOrderExported({ orderIds, orderMarker }).then(() => {
            afterChange();
          });
          break;

        case OrderMarker.FeedbackSubmitted:
        case OrderMarker.NotFeedbackSubmitted:
          MpOrdersMarkingApi.changeOrderFeedbackSubmited({ orderIds, orderMarker }).then(() => {
            afterChange();
          });
          break;

        case OrderMarker.Notified:
        case OrderMarker.NotNotified:
          MpOrdersMarkingApi.changeOrderNotified({ orderIds, orderMarker }).then(() => {
            afterChange();
          });
          break;

        case OrderMarker.OrderEditDisabled:
        case OrderMarker.OrderEditEnabled:
          MpOrdersMarkingApi.changeOrderEditDisabled({ orderIds, orderMarker }).then(() => {
            afterChange();
          });
          break;

        case OrderMarker.Received:
        case OrderMarker.NotReceived:
          MpOrdersMarkingApi.changeOrderReceived({ orderIds, orderMarker }).then(() => {
            afterChange();
          });
          break;

        case OrderMarker.RefundedAndNotReturned:
        case OrderMarker.NotRefundedAndNotReturned:
          MpOrdersMarkingApi.changeOrderRefundedAndNotReturned({ orderIds, orderMarker }).then(() => {
            afterChange();
          });
          break;

        case OrderMarker.RefundedPaid:
        case OrderMarker.NotRefundedPaid:
          MpOrdersMarkingApi.changeOrderRefundedPaid({ orderIds, orderMarker }).then(() => {
            afterChange();
          });
          break;
      }
      closeModalWindow();
    },
    [selectedOrders],
  );

  return {
    register,
    errors,
    setValue,
    submit: handleSubmit(submit),
    rules: {
      required,
    },
  };
};
