import React from 'react';
import cn from 'classnames';
import { useFormSetValue, useFormError } from '@/Hooks';
import { ClassNameProps, DisabledProps, InputRefProps } from '@/Types';
import { ErrorTooltip, errorTooltipClass } from '@/Components/Controls/ErrorTooltip/ErrorTooltip';
import styles from './FormTextarea.scss';

type Props<TFormData extends object> = {
  isExpanded?: boolean;
  onFocus: () => void;
  maxLength?: number;
} & ClassNameProps &
  DisabledProps &
  InputRefProps<HTMLInputElement, TFormData, string | number>;

export const FocusedFormTextarea = <TFormData extends object>({
  name,
  register,
  rules,
  setValue,
  valueForSet = '',
  error,
  isExpanded = false,
  onFocus,
  className,
  readOnly,
  maxLength = 256,
}: Props<TFormData>) => {
  useFormSetValue(name, valueForSet, setValue);
  const errorMessage = useFormError(name, error);

  return (
    <div
      className={cn(className, {
        [styles.wrapper]: true,
      })}
    >
      <ErrorTooltip error={errorMessage}>
        <textarea
          name={(name as unknown) as string}
          maxLength={maxLength}
          style={{ resize: 'none' }}
          {...register?.(name as any, rules)}
          onFocus={onFocus}
          readOnly={readOnly}
          className={cn({
            [styles.formTextarea]: true,
            [errorTooltipClass]: errorMessage,
            [styles.textarea_focus]: isExpanded,
          })}
        />
      </ErrorTooltip>
    </div>
  );
};
