import { IncomingTransferModel } from 'src/Models/Warehouse/IncomingTransferModel';
import { Nullable } from 'src/Types';
import { UserWarehouseUnitWithPostingsModel } from 'src/Models/Warehouse/UserWarehouseUnitWithPostingsModel';
import { UserWarehousePostingBatchModel } from 'src/Models/Warehouse/UserWarehousePostingBatchModel';
import { createSlice } from '@reduxjs/toolkit';
import { incomingTransferAsyncActions } from 'src/Pages/Products/modules/ProductTransfer/modules/TransferSection/modules/modals/IncomingTransferModal/services/asyncActions';

export type IncomingTransferState = {
  unitInfo: Nullable<UserWarehouseUnitWithPostingsModel>;
  batchInfo: Nullable<UserWarehousePostingBatchModel>;
  incomingTransfers: IncomingTransferModel[];
};

const initialState: IncomingTransferState = {
  unitInfo: null,
  batchInfo: null,
  incomingTransfers: [],
};

export const { actions: incomingTransferActions, reducer: incomingTransferReducer } = createSlice({
  name: 'incoming-transfer',
  initialState,
  reducers: {
    clean: (state) => {
      state.incomingTransfers = [];
      state.unitInfo = null;
      state.batchInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(incomingTransferAsyncActions.scanBarcode.fulfilled, (state, action) => {
        state.unitInfo = action.payload.transferState.unitInfo;
        state.batchInfo = action.payload.transferState.batchInfo;
      })
      .addCase(incomingTransferAsyncActions.getBatch.fulfilled, (state, action) => {
        state.unitInfo = action.payload.transferState.unitInfo;
        state.batchInfo = action.payload.transferState.batchInfo;
      })
      .addCase(incomingTransferAsyncActions.getIncomingTransfers.fulfilled, (state, action) => {
        state.incomingTransfers = action.payload.incomingTransfers;
      });
  },
});
