import { useModalWindows } from '@/ModalWindows/hooks/useModalWindows';
import { useTriggeredValue } from '@/Hooks';
import { useDispatch, useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { mergeOrdersUtils } from '@/ModalWindows/MergeOrdersModal/utils';
import { useCallback, useEffect, useState } from 'react';
import { MergeOrderDifferenceSources } from '@/Api/MpOrders/Requests/MergeOrdersRequest';
import { mergeOrdersAsyncActions } from '@/ModalWindows/MergeOrdersModal/services/asyncActions';
import { AppDispatch } from '@/Redux/ConfigureStore';

export function useMergeOrdersModal() {
  const dispatch: AppDispatch = useDispatch();
  const { closeModalWindow } = useModalWindows();
  const [mergeData, setMergeData] = useState<MergeOrderDifferenceSources>({});
  const selectedSalesIds = useSelector(salesPageSelectors.selectedSalesIds);

  const { value: isConfirmMergeDialogShown, on: showConfirmMergeDialog } = useTriggeredValue(false, true, false);
  const { value: isDiffMergeDialogShown, on: showDiffMergeDialog, off: closeDiffMergeDialog } = useTriggeredValue(
    false,
    true,
    false,
  );

  const saveMergeResult = useCallback((data: MergeOrderDifferenceSources) => {
    setMergeData(data);
    closeDiffMergeDialog();
    showConfirmMergeDialog();
  }, []);

  const mergeOrders = useCallback(() => {
    dispatch(
      mergeOrdersAsyncActions.mergeOrders({
        orderIds: selectedSalesIds,
        differenceSources: mergeData,
      }),
    ).then(() => {
      closeModalWindow();
    });
  }, [mergeData, closeModalWindow, selectedSalesIds, dispatch]);

  const selectedOrders = useSelector(salesPageSelectors.selectedSales);
  const diff = mergeOrdersUtils.getOrderDifference(selectedOrders);

  useEffect(() => {
    if (mergeOrdersUtils.isDifferenceExist(diff)) {
      showDiffMergeDialog();
    } else {
      showConfirmMergeDialog();
    }
  }, []);

  return {
    isConfirmMergeDialogShown,
    isDiffMergeDialogShown,
    closeDiffMergeDialog,
    diff,
    saveMergeResult,
    mergeOrders,
  };
}
