import { createSlice } from '@reduxjs/toolkit';
import { CustomerShoppingCardModel } from '@/Models/CustomerShoppingCardModel';
import { billingPageAsyncActions } from '@/Pages/Administration/Billing/services/asyncActions';

export type BillingPageState = {
  userProductsCarts: CustomerShoppingCardModel[];
};

const initialState: BillingPageState = {
  userProductsCarts: [],
};

const billingPageSlice = createSlice({
  name: 'adminBillingPage',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(billingPageAsyncActions.getBillingProductCarts.fulfilled, (state, action) => {
      state.userProductsCarts = action.payload.userProductsCarts;
    });
  },
});

const { actions, reducer } = billingPageSlice;

export const billingPageActions = actions;
export const billingPageReducer = reducer;
