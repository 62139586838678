import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateGenLogisticTypeRequest,
  CreateGenLogisticTypeResponse,
  UpdateGenLogisticTypeRequest,
  UpdateGenLogisticTypeResponse,
  GenLogisticTypesApi,
} from '@/Api/GeneratorLogisticTypes/GenLogisticTypes';
import { RootState } from '@/Redux/RootReducer';
import { genLogisticTypesUtils } from './utils';

const getLogisticTypes = createAsyncThunk('gen-logistic-types/get-types', async (params: undefined, api) => {
  const state: RootState = api.getState() as RootState;
  const types = await GenLogisticTypesApi.getLogisticTypes();
  types.forEach((x) => {
    x.locName = genLogisticTypesUtils.getLocalizationName(x, state);
  });
  return types;
});

const createLogisticType = createAsyncThunk<CreateGenLogisticTypeResponse, CreateGenLogisticTypeRequest>(
  'gen-logistic-types/create',
  async (request: CreateGenLogisticTypeRequest) => {
    return GenLogisticTypesApi.createLogisticType(request);
  },
);

const updateLogisticType = createAsyncThunk<UpdateGenLogisticTypeResponse, UpdateGenLogisticTypeRequest>(
  'gen-logistic-types/update',
  async (request: UpdateGenLogisticTypeRequest) => {
    return GenLogisticTypesApi.updateLogisticType(request);
  },
);

const deleteLogisticType = createAsyncThunk<number, number>('gen-logistic-types/delete', async (id: number) => {
  return GenLogisticTypesApi.deleteLogisticType(id);
});

export const asyncActions = {
  getLogisticTypes,
  createLogisticType,
  updateLogisticType,
  deleteLogisticType,
};
