import React, { useMemo } from 'react';
import { DataTable } from '@/Components/DataTable/DataTable';
import { PaymentTableRow } from '@/Types';
import { usePaymentsColumnInfo } from './ColumnInfo';
import { withModules } from '@/Hocs';
import styles from './ProfilePaymentsPage.scss';

const ProfilePaymentsPageComponent = (): React.ReactElement => {
  const columnInfos = usePaymentsColumnInfo();

  return useMemo(() => {
    return (
      <section className={styles.paymentsPage}>
        <DataTable<PaymentTableRow>
          rowKey={'payId'}
          rows={[]}
          columnInfos={columnInfos}
          className={styles.table}
          filterOptions={{
            needShowFiltersButton: false,
          }}
          selectOptions={{
            onFocusedRowChanged: () => null,
          }}
        />
      </section>
    );
  }, [columnInfos]);
};

export const ProfilePaymentsPage = withModules(ProfilePaymentsPageComponent);
