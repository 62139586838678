import { GenAdditionalServiceTypeModel } from '@/Models';
import { createSlice } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';

export type GenAdditionalServiceTypesDataState = {
  additionalServiceTypes: GenAdditionalServiceTypeModel[];
};

const initialState: GenAdditionalServiceTypesDataState = {
  additionalServiceTypes: [],
};

const genAdditionalServiceTypesDataSlice = createSlice({
  name: 'genAdditionalServiceTypesData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getAdditionalServiceTypes.fulfilled, (state, action) => {
        state.additionalServiceTypes = action.payload;
      })
      .addCase(asyncActions.createAdditionalServiceType.fulfilled, (state, action) => {
        state.additionalServiceTypes.push(action.payload.additionalServiceType);
      })
      .addCase(asyncActions.updateAdditionalServiceType.fulfilled, (state, action) => {
        state.additionalServiceTypes = state.additionalServiceTypes.map((item) =>
          item.id === action.payload.additionalServiceType.id ? action.payload.additionalServiceType : item,
        );
      })
      .addCase(asyncActions.deleteAdditionalServiceType.fulfilled, (state, action) => {
        state.additionalServiceTypes = state.additionalServiceTypes.filter((item) => item.id !== action.payload);
      });
  },
});

export const genAdditionalServiceTypesDataReducer = genAdditionalServiceTypesDataSlice.reducer;
export const genAdditionalServiceTypesDataActions = genAdditionalServiceTypesDataSlice.actions;
export const genAdditionalServiceTypesDataAsyncActions = asyncActions;
