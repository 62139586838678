import React, { PropsWithChildren } from 'react';
import { ClassNameProps } from '@/Types';
import { useWindowResize } from '@/Hooks/useWindowResize';
import { DropDownContent } from '@/Components/DropDownContent/DropDownContent';
import styles from './AdaptiveDropDownContent.scss';
import cn from 'classnames';

type Props = {
  title: string;
  onTitleClick?: () => void;
  isActive?: boolean;
  isOpened?: boolean;
  dropDownTitleClassName?: string;
  dropDownBodyClassName?: string;
} & ClassNameProps;

export const AdaptiveDropDownContent = ({
  title,
  children,
  className,
  dropDownTitleClassName,
  dropDownBodyClassName,
  ...props
}: PropsWithChildren<Props>) => {
  const { isMobileSize } = useWindowResize();

  return isMobileSize ? (
    <DropDownContent
      {...props}
      title={title}
      className={cn(styles.dropDownContent, className)}
      dropDownTitleClassName={cn(styles.dropDownTitle, dropDownTitleClassName)}
      dropDownBodyClassName={cn(styles.dropDownBody, dropDownBodyClassName)}
    >
      {children}
    </DropDownContent>
  ) : (
    <>{children}</>
  );
};
