import { useCallback } from 'react';
import { MpOrdersApi } from '@/Api/MpOrders';
import { useForm } from '@/Hooks/useFormWrapper';
import { useRequired } from '@/Hooks/Validation';
export type FormData = {
  mpAccountId: number;
  file: any;
};

export const useUpdateByFileTab = (file: any, closeModalWindow: () => void) => {
  const methods = useForm<FormData>();

  const onSubmit = useCallback(
    (data: FormData) => {
      const promise = new Promise<string>((resolve) => {
        if (!file) {
          resolve('');
        }
        const reader = new FileReader();

        reader.onload = () => {
          const result = reader.result as string;
          if (result) {
            resolve(result);
          } // if
        };

        reader.readAsDataURL(file);
      });

      promise.then((binaryFile) => {
        Promise.all([MpOrdersApi.updateOrdersByFile({ file: binaryFile, mpAccountId: data.mpAccountId })]).then(() => {
          closeModalWindow();
        });
      });
    },
    [closeModalWindow, file],
  );

  const required = useRequired();

  return {
    methods,
    rules: {
      required,
    },
    onSubmit: methods.handleSubmit(onSubmit),
  };
};
