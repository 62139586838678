import { OrderMarker } from '@/Enums/OrderMarker';
import { useLocalization } from '@/Hooks/useLocalization';
import { useMemo } from 'react';

export const useShippedAndNotShippedFormOptions = () => {
  const {
    enums: { OrderMarker: OrderMarkerLocalization },
  } = useLocalization();

  const markerOptions = useMemo(
    () => [
      { key: OrderMarker.Shipped, value: OrderMarkerLocalization.Shipped },
      { key: OrderMarker.NotShipped, value: OrderMarkerLocalization.NotShipped },
    ],
    [OrderMarkerLocalization],
  );

  return {
    markerOptions,
  };
};
