import { ColumnInfo, ColumnType, InvoiceTableRow } from '@/Types';
import { useLocalization } from '@/Hooks';
import { useMemo } from 'react';

export function useInvoicesColumnInfo() {
  const { invoiceColumns } = useLocalization();

  return useMemo((): ColumnInfo<InvoiceTableRow>[] => {
    return [
      {
        columnType: ColumnType.String,
        fieldName: 'date',
        headerText: invoiceColumns.Date,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'number',
        headerText: invoiceColumns.InvoiceNumber,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'amount',
        headerText: invoiceColumns.Amount,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'outstandingBalance',
        headerText: invoiceColumns.OutstandingBalance,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'paymentMethod',
        headerText: invoiceColumns.PaymentMethod,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'paymentDueDate',
        headerText: invoiceColumns.PaymentDueDate,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'invoiceCloseDate',
        headerText: invoiceColumns.InvoiceCloseDate,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'openInvoice',
        headerText: invoiceColumns.OpenInvoice,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'openSDR',
        headerText: invoiceColumns.OpenSdr,
      },
    ];
  }, [invoiceColumns]);
}
