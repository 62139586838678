import { ContactsApi } from '@/Api/Contacts';
import { ContactModel } from '@/Models';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getContacts = createAsyncThunk<ContactModel[], void>('selectContactModal/get-contacts', async () => {
  return ContactsApi.getContacts({});
});

export const asyncActions = {
  getContacts,
};
