import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { OrderMarker } from '@/Enums';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { salesPageActions } from '@/Pages/Sales/services';
import { Toasts } from '@/Components/Toast/Toast';
import { stringUtils } from '@/Utils';
import { useLocalization } from '@/Hooks';

export const useAllPaidForm = (orderIds: number[], isSalesPage: boolean, onClose: () => void) => {
  const dispatch = useDispatch();

  const {
    notifications: {
      ChangePaymentStatusCompleted: { title, text },
    },
  } = useLocalization();

  const submit = useCallback(() => {
    MpOrdersMarkingApi.changeOrderPaymentStatus({
      orderIds,
      orderMarker: OrderMarker.NotPaid,
    }).then((response) => {
      // We have not paid filters, so there is nothing to update
      // All will update after bg task will complete
      dispatch(salesPageActions.ordersUpdate(response));

      if (response) {
        response.forEach((order) => {
          Toasts.showSuccess({ title: title, text: stringUtils.insertParamsToString(text, [order.userOrderId]) });
        });
      }

      onClose();
    });
  }, [onClose, orderIds, isSalesPage]);

  return {
    submit,
  };
};
