import { createAsyncThunk } from '@reduxjs/toolkit';

// Models import
import { ProductModel } from '@/Models';

// Api import
import { ProductsApi } from '@/Api/Products/Product/ProductsApi';
import { CreateProductRequest } from '@/Api/Products/Product/Requests/CreateProductRequest';
import { CreateProductResponse } from '@/Api/Products/Product/Responses/CreateProductResponse';
import { UpdateProductRequest } from '@/Api/Products/Product/Requests/UpdateProductRequest';
import { UpdateProductResponse } from '@/Api/Products/Product/Responses/UpdateProductResponse';
import { GetProductsParams } from '@/Api/Products/Product/Requests/GetProductsParams';
import { GetProductCategoriesResponse, ProductCategoriesApi } from '@/Api/ProductCategories';
import { IsAdminRequest } from '@/Api';
import { GetProductOriginalImageResponse } from '@/Api/Products/Product/Responses/GetProductOriginalImageResponse';

const getProducts = createAsyncThunk<ProductModel[], IsAdminRequest<GetProductsParams>>(
  'productsPage/get-products',
  async (request: IsAdminRequest<GetProductsParams>) => {
    return ProductsApi.getProducts(request.requestParam, request.isAdmin);
  },
);

const getProductCategories = createAsyncThunk<GetProductCategoriesResponse>(
  'productsPage/get-product-categories',
  async () => {
    return ProductCategoriesApi.getProductCategories();
  },
);

const createProduct = createAsyncThunk<CreateProductResponse, IsAdminRequest<CreateProductRequest>>(
  'productsPage/create-product',
  async (request: IsAdminRequest<CreateProductRequest>) => {
    return ProductsApi.createProduct(request.requestParam, request.isAdmin);
  },
);

const updateProduct = createAsyncThunk<UpdateProductResponse, IsAdminRequest<UpdateProductRequest>>(
  'productsPage/update-product',
  async (request: IsAdminRequest<UpdateProductRequest>) => {
    return ProductsApi.updateProduct(request.requestParam, request.isAdmin);
  },
);

const deleteProduct = createAsyncThunk<number, IsAdminRequest<number>>(
  'productsPage/delete-product',
  async (request: IsAdminRequest<number>) => {
    return ProductsApi.deleteProduct(request.requestParam, request.isAdmin);
  },
);

const deleteProductPackage = createAsyncThunk<number, IsAdminRequest<number>>(
  'productsPage/delete-product-package',
  async (request: IsAdminRequest<number>) => {
    return ProductsApi.deleteProductPackage(request.requestParam, request.isAdmin);
  },
);

const getProductOriginalImage = createAsyncThunk<GetProductOriginalImageResponse, number>(
  'productsPage/get-product-full-image',
  async (request) => {
    return ProductsApi.getProductOriginalImage(request);
  },
);

export const asyncActions = {
  getProducts,
  getProductCategories,
  createProduct,
  updateProduct,
  deleteProduct,
  deleteProductPackage,
  getProductOriginalImage,
};
