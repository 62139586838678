import { GenProductTypeModel } from '@/Models';
import { createSlice } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';

export type GenProductTypesDataState = {
  productTypes: GenProductTypeModel[];
};

const initialState: GenProductTypesDataState = {
  productTypes: [],
};

const genProductTypesDataSlice = createSlice({
  name: 'genProductTypesData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getProductTypes.fulfilled, (state, action) => {
        state.productTypes = action.payload;
      })
      .addCase(asyncActions.createProductType.fulfilled, (state, action) => {
        state.productTypes.push(action.payload.productType);
      })
      .addCase(asyncActions.updateProductType.fulfilled, (state, action) => {
        state.productTypes = state.productTypes.map((item) =>
          item.id === action.payload.productType.id ? action.payload.productType : item,
        );
      })
      .addCase(asyncActions.deleteProductType.fulfilled, (state, action) => {
        state.productTypes = state.productTypes.filter((item) => item.id !== action.payload);
      });
  },
});

export const genProductTypesDataReducer = genProductTypesDataSlice.reducer;
export const genProductTypesDataActions = genProductTypesDataSlice.actions;
export const genProductTypesDataAsyncActions = asyncActions;
