import { UpdateMpCredentialsResponse } from '@/Api/MpCredentials/UpdateMpCredentialsResponse';
import { ApiRoutingUtils } from '@/Routing';
import { UpdateMpCredentialsRequest } from '@/Api/MpCredentials/UpdateMpCredentialsRequest';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('mp-credentials');

function updateCredentials(request: UpdateMpCredentialsRequest): Promise<UpdateMpCredentialsResponse> {
  return rest.post<UpdateMpCredentialsRequest, UpdateMpCredentialsResponse>(url, request);
}

export const MpCredentialsApi = {
  updateCredentials,
};
