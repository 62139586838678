import { ShippingMethodModel } from '@/Models';
import { ApiRoutingUtils } from '@/Routing';
import {
  CreateShippingMethodRequest,
  CreateShippingMethodResponse,
  UpdateShippingMethodRequest,
  UpdateShippingMethodResponse,
} from '.';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('shipping-methods');

function getShippingMethods(): Promise<ShippingMethodModel[]> {
  return rest.get(url);
}

async function createShippingMethod(request: CreateShippingMethodRequest): Promise<CreateShippingMethodResponse> {
  return rest.post(url, request);
}

async function updateShippingMethod(request: UpdateShippingMethodRequest): Promise<UpdateShippingMethodResponse> {
  return rest.put(url, request);
}

async function deleteShippingMethod(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

export const ShippingMethodsApi = {
  getShippingMethods,
  createShippingMethod,
  updateShippingMethod,
  deleteShippingMethod,
};
