import {
  DeutschePostInternetmarkeContractsApi,
  GetDeutschePostInternetmarkeContractResponse,
} from '@/Api/DeutschePostInternetmarkeContracts';
import { GetAllLogisticContractResponse } from '@/Api/LogisticContracts';
import { LogisticContractsApi } from '@/Api/LogisticContracts/LogisticContractsApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getAllLogisticContracts = createAsyncThunk<GetAllLogisticContractResponse>(
  'LogisticSettings/getAllLogisticContracts',
  () => {
    return LogisticContractsApi.getAllLogisticContracts();
  },
);

const getDeutschePostInternetmarkeContract = createAsyncThunk<GetDeutschePostInternetmarkeContractResponse>(
  'LogisticSettings/getDeutschePostInternetmarkeContract',
  () => {
    return DeutschePostInternetmarkeContractsApi.getContract();
  },
);

export const asyncActions = {
  getAllLogisticContracts,
  getDeutschePostInternetmarkeContract,
};
