import { SynchronizeFeedbacksRequest } from './';
import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetFeedbackSettingResponse } from './GetFeedbackSettingResponse';
import { CreateFeedbackSettingRequest } from './CreateFeedbackSettingRequest';
import { CreateFeedbackSettingResponse } from './CreateFeedbackSettingResponse';
import { CreateUpdateFeedbackSettingResponse } from '@/Api/MpFeedback/CreateUpdateFeedbackSettingResponse';

const url = ApiRoutingUtils.createUrl('mp-feedbacks');

async function synchFeedbacks(request: SynchronizeFeedbacksRequest) {
  return rest.post(`${url}/synch`, request);
}

async function createFeedbacks(request: CreateFeedbackSettingRequest): Promise<CreateFeedbackSettingResponse> {
  return rest.post(url, request);
}

async function updateFeedbacks(
  request: CreateUpdateFeedbackSettingResponse,
): Promise<CreateUpdateFeedbackSettingResponse> {
  return rest.put(url, request);
}
async function getFeedbacks(): Promise<GetFeedbackSettingResponse> {
  return rest.get(url);
}

export const MpFeedbacksApi = {
  synchFeedbacks,
  createFeedbacks,
  getFeedbacks,
  updateFeedbacks,
};
