import { UserProductModel } from '@/Models/UserProducts/UserProductModel';
import { TranslationType } from '@/Localization/LanguageKeys';
import { countryTypeUtils } from '@/Utils/CountryTypesUtils';
import {
  internationalTypeUtils,
  measurePackageTypeUtils,
  shippingServiceTypeUtils,
  userProductTypeUtils,
} from '@/Enums';

const fillUserProductLocalization = (
  userProducts: UserProductModel[],
  translation: TranslationType,
): UserProductModel[] => {
  if (!userProducts.length) {
    return [];
  }

  return userProducts.map((userProduct) => {
    let translatedProduct = {
      ...userProduct,
      productTypeName: userProductTypeUtils.getUserProductTypeNameByType(userProduct.productType, translation),
      internationalTypeName: internationalTypeUtils.getInternationalTypeNameByType(
        userProduct.internationalType,
        translation,
      ),
      shippingServiceTypeName: shippingServiceTypeUtils.getShippingServiceTypeNameByType(
        userProduct.shippingServiceType,
        translation,
      ),
      countriesSenderNames: userProduct.countriesSender.map((country) =>
        countryTypeUtils.getCountryNameByCountryCode(country, translation),
      ),
    };

    if (userProduct.measure) {
      translatedProduct = {
        ...translatedProduct,
        measure: {
          ...userProduct.measure,
          measurePackageTypeName: measurePackageTypeUtils.getMeasurePackageTypeNameByType(
            userProduct.measure.measurePackageType,
            translation,
          ),
        },
      };
    }

    return translatedProduct;
  });
};

export const userProductMapping = {
  fillUserProductLocalization,
};
