export enum LogisticContractLabelFormat {
  DeutschePostA4 = 'DeutschePostA4',
  DeutschePost910300700 = 'DeutschePost910300700',
  DeutschePost910300700oz = 'DeutschePost910300700oz',
  DeutschePost910300600 = 'DeutschePost910300600',
  DeutschePost910300710 = 'DeutschePost910300710',
  DeutschePost10070mm = 'DeutschePost10070mm',
  DPDA4 = 'DPDA4',
  DPDA6 = 'DPDA6',
  DPDA7 = 'DPDA7',
}
