import { TreeItemType } from '@/Components/TreeView/TreeView';
import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { LogisticSettingsState } from './reducer';
import { LogisticTypeKeys } from '@/Enums';

const logisticSettingsPage = (state: RootState) => state.settingsPage.logisticCombineSettings.logisticSettings;

const logisticModalType = createSelector(
  logisticSettingsPage,
  (logisticSettings: LogisticSettingsState) => logisticSettings.packageModalType,
);

const companies = createSelector(
  logisticSettingsPage,
  (logisticSettings: LogisticSettingsState) => logisticSettings.companies,
);

const activeLogisticTypesForUserTreeView = createSelector(
  [companies],
  (companies): TreeItemType<LogisticTypeKeys>[] => {
    const filteredCompanies = companies.filter(
      (currentCompany, index, arr) =>
        arr.findIndex((company) => company.logisticTypeId === currentCompany.logisticTypeId) === index,
    );

    return filteredCompanies.map((item) => {
      return {
        key: item.logisticTypeId,
        value: LogisticTypeKeys[item.logisticTypeId],
        expanded: true,
      } as TreeItemType<LogisticTypeKeys>;
    });
  },
);

const selectedCompany = createSelector(
  logisticSettingsPage,
  (logisticSettings: LogisticSettingsState) => logisticSettings.selectedCompany,
);

const isDeutschePostInternetmarkeContract = createSelector(
  logisticSettingsPage,
  (logisticSettings: LogisticSettingsState) =>
    logisticSettings.selectedCompany?.name === logisticSettings.DeutschePostInternetmarkeName,
);

const connectionStatus = createSelector(
  logisticSettingsPage,
  (logisticSettings: LogisticSettingsState) => logisticSettings.connectionStatus,
);

const actions = createSelector(
  logisticSettingsPage,
  (logisticSettings: LogisticSettingsState) => logisticSettings.actions,
);

export const logisticSettingsSelectors = {
  logisticModalType,
  selectedCompany,
  isDeutschePostInternetmarkeContract,
  companies,
  connectionStatus,
  actions,
  activeLogisticTypesForUserTreeView,
};
