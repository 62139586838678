import React, { FC } from 'react';
import { ClassNameProps } from '@/Types';
import cn from 'classnames';
import styles from './OrderItemsInfoSection.scss';
import { useSelector } from 'react-redux';
import { orderItemInfoSectionSelectors } from './services/selectors';
import { useLocalization } from '@/Hooks';

type Props = ClassNameProps;

export const OrderItemsInfoSection: FC<Props> = ({ className }) => {
  const shipping = useSelector(orderItemInfoSectionSelectors.shippingCost);
  const netAmount = useSelector(orderItemInfoSectionSelectors.netAmount);
  const vat = useSelector(orderItemInfoSectionSelectors.VAT);
  const totalAmount = useSelector(orderItemInfoSectionSelectors.totalAmount);

  const {
    salesPage: {
      OrderItemsSection: { NetAmount, Shipping, TotalAmount, Vat },
    },
  } = useLocalization();

  return (
    <section className={cn(className, styles.wrapper)}>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>{Shipping}:</td>
            <td>{shipping}</td>
          </tr>
          <tr>
            <td>{NetAmount}:</td>
            <td>{netAmount}</td>
          </tr>
          <tr>
            <td>{Vat}:</td>
            <td>{vat}</td>
          </tr>
          <tr>
            <td>{TotalAmount}:</td>
            <td>{totalAmount}</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
