import { documentFoldersDataSelectors } from './DocumentFoldersData/selector';
import { documentCategoriesDataSelectors } from './DocumentCategoriesData/selector';
import { documentTypesDataSelectors } from './DocumentTypesData/selector';
import { shippingMethodsDataSelectors } from './ShippingMethodsData/selector';
import { genLogisticTypesDataSelectors } from './GenLogisticTypesData/selector';
import { genProductTypesDataSelectors } from './GenProductTypesData/selector';
import { genAdditionalServiceTypesDataSelectors } from './GenAdditionalServiceTypesData/selector';
import { genSubProductTypesDataSelectors } from './GenSubProductTypesData/selector';
import { genPackageTypesDataSelectors } from './GenPackageTypesData/selector';
import { regionalSettingsDataSelectors } from './RegionalSettingsData/selector';
import { uploadTemplatesDataSelectors } from './UploadTemplatesData/selector';
import { accountChartsDataSelectors } from './AccountChartsData/selectors';

export const dynamicDataSelectors = {
  shippingMethodsData: shippingMethodsDataSelectors,
  documentCategoriesData: documentCategoriesDataSelectors,
  documentTypesData: documentTypesDataSelectors,
  documentFoldersData: documentFoldersDataSelectors,
  genLogisticTypesData: genLogisticTypesDataSelectors,
  genProductTypesData: genProductTypesDataSelectors,
  genAdditionalServiceTypesData: genAdditionalServiceTypesDataSelectors,
  genSubProductTypesData: genSubProductTypesDataSelectors,
  genPackageTypesData: genPackageTypesDataSelectors,
  regionalSettingsData: regionalSettingsDataSelectors,
  uploadTemplatesData: uploadTemplatesDataSelectors,
  accountChartsData: accountChartsDataSelectors,
};
