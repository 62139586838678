import { RootState } from '@/Redux/RootReducer';
import { stringUtils } from '@/Utils';
import { createSelector } from 'reselect';
import { RegionalSettingsDataState } from './reducer';

const regionalSettingsDataSelector = (root: RootState) => root.dynamicData.regionalSettingsData;
const translation = (root: RootState) => root.global.translation;

const timeZones = createSelector(
  [regionalSettingsDataSelector, translation],
  (state: RegionalSettingsDataState, translation) => {
    const regionsTranslation = translation.Enums.TimeZoneRegion;
    const timeZonesTranslation = translation.Common['TimeZones' as keyof typeof translation.Common];

    return state.timeZones.map((timeZone) => ({
      ...timeZone,
      region: regionsTranslation?.[timeZone.region] ?? timeZone.region,
      description: timeZonesTranslation?.[timeZone.name as keyof typeof timeZonesTranslation] ?? timeZone.name,
    }));
  },
);

const timeFormats = createSelector(
  [regionalSettingsDataSelector, translation],
  (state: RegionalSettingsDataState, translation) => {
    const timeFormatsTranslation = translation.Common['TimeFormats' as keyof typeof translation.Common];

    return state.timeFormats.map((timeFormat) => ({
      ...timeFormat,
      description:
        timeFormatsTranslation?.[
          stringUtils.replaceAll(timeFormat.format, ['.'], ['_']) as keyof typeof timeFormatsTranslation
        ] ?? timeFormat.format,
    }));
  },
);

const dateFormats = createSelector(
  regionalSettingsDataSelector,
  (state: RegionalSettingsDataState) => state.dateFormats,
);

const numberFormats = createSelector(
  regionalSettingsDataSelector,
  (state: RegionalSettingsDataState) => state.numberFormats,
);

export const regionalSettingsDataSelectors = {
  timeZones,
  timeFormats,
  dateFormats,
  numberFormats,
};
