import React from 'react';
import { Label } from '@/Components/Controls/Label/Label';
import { FormInput } from '@/Components/Controls/Input/FormInput';
import styles from './MergeOrdersAddressContactSection.scss';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { useMergeOrdersAddressContactSectionForm, FormData } from './hooks/useMergeOrdersAddressContactSectionForm';
import { OrderTableRow, Pair } from '@/Types';
import { useLocalization } from '@/Hooks';

type Props = {
  isInvoice: boolean;
  orders: OrderTableRow[];
  differentOrders: Pair<number>[];
};

export function MergeOrdersAddressContactSection({ isInvoice, orders, differentOrders }: Props) {
  const {
    modalWindows: {
      mergeOrders: { AddressContactSection },
    },
  } = useLocalization();

  const {
    register,
    setValue,
    errors,
    rules: { required },
  } = useMergeOrdersAddressContactSectionForm(isInvoice, orders);
  return (
    <div className={styles.mergeOrdersSection}>
      <div className={styles.mergeOrdersSectionHeader}>
        <Label
          text={isInvoice ? AddressContactSection.InvoiceContactInfo : AddressContactSection.ShippingContactInfo}
        />
        <FormSelectInput
          options={[{ key: '', value: '' }, ...differentOrders]}
          register={register}
          needClearButton={false}
          setValue={setValue}
          rules={required}
          error={errors}
          name={isInvoice ? 'invoiceContactInfoSourceOrderId' : 'shippingContactInfoSourceOrderId'}
        />
      </div>
      <Label text={AddressContactSection.Company} />
      <FormInput<FormData>
        readOnly={true}
        register={register}
        name={isInvoice ? 'invoiceCompanyName' : 'shippingCompanyName'}
      />

      <Label text={AddressContactSection.Name} />
      <div className={styles.addressNameFields}>
        <FormInput<FormData>
          readOnly={true}
          register={register}
          name={isInvoice ? 'invoiceFirstName' : 'shippingFirstName'}
        />
        <FormInput<FormData>
          readOnly={true}
          register={register}
          name={isInvoice ? 'invoiceLastName' : 'shippingLastName'}
        />
      </div>
      <Label text={AddressContactSection.Phone} />
      <FormInput<FormData>
        readOnly={true}
        register={register}
        name={isInvoice ? 'invoicePhoneNumber' : 'shippingPhoneNumber'}
      />
    </div>
  );
}
