import { useLocalization } from '..';

export const useShouldBePassword = () => {
  const { validation } = useLocalization();

  return {
    pattern: {
      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      message: validation.ValueMustBePassword,
    },
  };
};
