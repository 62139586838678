export enum IconColor {
  Grey,
  Blue,
  Yellow,
  Red,
  Lila,
  Orange,
}

export type ColoredIconInfo = {
  color: IconColor;
  tooltipText: string;
};
