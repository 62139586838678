import { useCallback, useRef } from 'react';

type DebouncedCallback<T extends any[]> = (...args: T) => void;

export function useDebounce<T extends any[]>(
  callback: DebouncedCallback<T>,
  delay: number,
): [DebouncedCallback<T>, () => void] {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const debouncedCallback = useCallback(
    (...args: T) => {
      clearTimeout(timeoutRef.current!);
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  const cancelDebounce = useCallback(() => {
    clearTimeout(timeoutRef.current!);
  }, []);

  return [debouncedCallback, cancelDebounce];
}
