import { ApiRouting } from '@/Routing';
import { BaseNotificationHub } from '@/SignalR/Hubs/BaseNotificationHub';

export class WebNotificationHub extends BaseNotificationHub {
  private static instance = new WebNotificationHub();

  constructor() {
    super(ApiRouting.Hubs.NotificationHubRoute);

    if (WebNotificationHub.instance) {
      return WebNotificationHub.instance;
    }

    WebNotificationHub.instance = this;
  }
}
