/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import { IPrintService } from './services/PrintService/IPrintService';
import { IDocumentStoreService } from './services/DocumentStoreService/IDocumentStoreService';
import { IFolderBrowserDialogService } from './services/FolderBrowserDialogService/IFolderBrowserDialogService';
import { IDocumentScanService } from '@/Electron/services/DocumentScanService/IDocumentScanService';

let _printService: IPrintService;
let _documentStoreService: IDocumentStoreService;
let _folderBrowserDialogService: IFolderBrowserDialogService;
let _documentScanService: IDocumentScanService;

if (IS_ELECTRON) {
  const { printService } = require('./services/PrintService/ElectronPrintService');
  const { documentStoreService } = require('./services/DocumentStoreService/ElectronDocumentStoreService');
  const {
    folderBrowserDialogService,
  } = require('./services/FolderBrowserDialogService/ElectronFolderBrowserDialogService');
  const { documentScanService } = require('./services/DocumentScanService/ElectronDocumentScanService');

  _printService = printService;
  _documentStoreService = documentStoreService;
  _folderBrowserDialogService = folderBrowserDialogService;
  _documentScanService = documentScanService;
} else {
  const { printService } = require('./services/PrintService/WebPrintService');
  const { documentStoreService } = require('./services/DocumentStoreService/WebDocumentStoreService');
  const { folderBrowserDialogService } = require('./services/FolderBrowserDialogService/WebFolderBrowserDialogService');
  const { documentScanService } = require('./services/DocumentScanService/WebDocumentScanService');

  _printService = printService;
  _documentStoreService = documentStoreService;
  _folderBrowserDialogService = folderBrowserDialogService;
  _documentScanService = documentScanService;
}

export const printService = _printService;
export const documentStoreService = _documentStoreService;
export const folderBrowserDialogService = _folderBrowserDialogService;
export const documentScanService = _documentScanService;
