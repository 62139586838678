import { CustomerShoppingCardModel } from '@/Models/CustomerShoppingCardModel';
import { CustomerShoppingCardTableRow } from '@/Types/CustomerShoppingCardTableRow';

const modelToAllocateTableRow = (data: CustomerShoppingCardModel): CustomerShoppingCardTableRow => {
  return {
    ...data,
    unitPriceNet: Number(data.unitPriceNet).toFixed(2),
  };
}; // modelToAllocateTableRow

export const userProductsCartMapping = {
  modelToAllocateTableRow,
};
