import { createAsyncThunk } from '@reduxjs/toolkit';
import { MpOrdersApi, OrderLabelsRequest } from '@/Api/MpOrders';
import { RootState } from '@/Redux/RootReducer';
import { GenerateLabelsResponse } from '@/Api/Logistics/GenerateLabelsResponse';
import { GenerateLabelsRequest } from '@/Api/Logistics/GenerateLabelsRequest';
import { LabelPrintedStatusResponse, LogisticsApi } from '@/Api/Logistics';
import { OrderLabelModel } from '@/Models';
import { Pair } from '@/Types';

function enumToString<TKey>(enumKey: TKey, locs: Pair<TKey>[]): any {
  return enumKey ? locs.find((y) => y.key == enumKey)?.value || enumKey : enumKey;
}

function fillLocalization(x: OrderLabelModel, state: RootState): any {
  x.countryName = enumToString(x.country, state.modalWindows.orderLabelPrintModalState.countryCodeOptions);
  x.userLogisticTypeName = enumToString(
    x.userLogisticTypeName,
    state.modalWindows.orderLabelPrintModalState.logisticTypeOptions,
  );
}

const getOrderLabelInfoRequest = createAsyncThunk(
  'orderLabelPrintModal/getOrderLabelInfo',
  async (request: OrderLabelsRequest, api) => {
    const state: RootState = api.getState() as RootState;
    const response = await MpOrdersApi.getLabelInfoRequest(request);
    response.orderLabels?.forEach((x) => fillLocalization(x, state));
    return response;
  },
);

const generateLabelsRequest = createAsyncThunk<GenerateLabelsResponse, GenerateLabelsRequest>(
  'orderLabelPrintModal/savePackages',
  async (request) => {
    return LogisticsApi.generateLabels(request);
  },
);

const setPrintedStatusRequest = createAsyncThunk<LabelPrintedStatusResponse, number[]>(
  'orderLabelPrintModal/setLabelPrintedStatus',
  async (orderLabelIds: number[]) => {
    return LogisticsApi.setLabelPrintedStatus(orderLabelIds);
  },
);

export const asyncActions = {
  getOrderLabelInfoRequest,
  generateLabelsRequest,
  setPrintedStatusRequest,
};
