import React from 'react';
import { useFormContext } from '@/Hooks/useFormWrapper';
import styles from './AddressForm.scss';
import { ValidationType } from '@/Enums';
import cn from 'classnames';
import { useLocalization } from '@/Hooks';
import { FormInput } from '@/Components/Controls/Input/FormInput';

type Props<T> = {
  fieldsInfo: AddressFields<T>;
  disabled?: boolean;
};

type FieldProps<T> = {
  name: keyof T;
  value: string | number | undefined;
  validationType?: ValidationType;
};

type AddressFields<T> = {
  company: FieldProps<T>;
  firstName: FieldProps<T>;
  lastName: FieldProps<T>;
  additional: FieldProps<T>;
  street: FieldProps<T>;
  houseNo: FieldProps<T>;
  zip: FieldProps<T>;
  city: FieldProps<T>;
  country: FieldProps<T>;
  countryCode: FieldProps<T>;
  email: FieldProps<T>;
  phone: FieldProps<T>;
};

export function AddressForm<T extends object>({ fieldsInfo, disabled = false }: Props<T>) {
  const {
    inputLabels: { Additional, Company, Country, Email, Name, Phone, Street, ZipCity },
  } = useLocalization();
  const { register, setValue } = useFormContext<T>();

  return (
    <section className={styles.formSection}>
      <label>{Company}</label>
      <FormInput<T>
        name={fieldsInfo.company.name}
        register={register}
        setValue={setValue}
        valueForSet={fieldsInfo.company.value}
        disabled={disabled}
      />
      <label>{Name}</label>
      <div className={styles.inputsWrapper}>
        <FormInput<T>
          name={fieldsInfo.firstName.name}
          register={register}
          setValue={setValue}
          valueForSet={fieldsInfo.firstName.value}
          disabled={disabled}
        />
        <FormInput<T>
          name={fieldsInfo.lastName.name}
          register={register}
          setValue={setValue}
          valueForSet={fieldsInfo.lastName.value}
          disabled={disabled}
        />
      </div>
      <label>{Additional}</label>
      <FormInput<T>
        name={fieldsInfo.additional.name}
        register={register}
        setValue={setValue}
        valueForSet={fieldsInfo.additional.value}
        disabled={disabled}
      />
      <label>{Street}</label>
      <div className={styles.inputsWrapper}>
        <FormInput<T>
          className={cn(getFieldClassName(fieldsInfo.street.validationType), styles.growInput)}
          name={fieldsInfo.street.name}
          register={register}
          setValue={setValue}
          valueForSet={fieldsInfo.street.value}
          disabled={disabled}
        />
        <FormInput<T>
          className={cn(getFieldClassName(fieldsInfo.houseNo.validationType), styles.houseNo)}
          name={fieldsInfo.houseNo.name}
          register={register}
          setValue={setValue}
          valueForSet={fieldsInfo.houseNo.value}
          disabled={disabled}
        />
      </div>
      <label>{ZipCity}</label>
      <div className={styles.inputsWrapper}>
        <FormInput<T>
          className={cn(getFieldClassName(fieldsInfo.zip.validationType), styles.zip)}
          name={fieldsInfo.zip.name}
          register={register}
          setValue={setValue}
          valueForSet={fieldsInfo.zip.value}
          disabled={disabled}
        />
        <FormInput<T>
          className={cn(getFieldClassName(fieldsInfo.city.validationType), styles.growInput)}
          name={fieldsInfo.city.name}
          register={register}
          setValue={setValue}
          valueForSet={fieldsInfo.city.value}
          disabled={disabled}
        />
      </div>
      <label>{Country}</label>
      <div className={styles.inputsWrapper}>
        <FormInput<T>
          className={cn(getFieldClassName(fieldsInfo.countryCode.validationType), styles.countryCode)}
          name={fieldsInfo.countryCode.name}
          register={register}
          setValue={setValue}
          valueForSet={fieldsInfo.countryCode.value}
          disabled={disabled}
        />
        <FormInput<T>
          className={cn(getFieldClassName(fieldsInfo.country.validationType), styles.growInput)}
          name={fieldsInfo.country.name}
          register={register}
          setValue={setValue}
          valueForSet={fieldsInfo.country.value}
          disabled={disabled}
        />
      </div>
      <label>{Email}</label>
      <FormInput<T>
        name={fieldsInfo.email.name}
        register={register}
        setValue={setValue}
        valueForSet={fieldsInfo.email.value}
        disabled={disabled}
      />
      <label>{Phone}</label>
      <FormInput<T>
        name={fieldsInfo.phone.name}
        register={register}
        setValue={setValue}
        valueForSet={fieldsInfo.phone.value}
        disabled={disabled}
      />
    </section>
  );
}

function getFieldClassName(validationType: ValidationType | undefined): string {
  switch (validationType) {
    case ValidationType.Valid:
      return styles.valid;
    case ValidationType.Invalid:
      return styles.invalid;
    case ValidationType.None:
    default:
      return '';
  }
}
