import { useLocalization } from '@/Hooks';

export const useShouldBeEmail = () => {
  const { validation } = useLocalization();

  return {
    pattern: {
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\d-]+\.)+[a-zA-Z]{2,}))$/,
      message: validation.ValueMustBeEmail,
    },
  };
};
