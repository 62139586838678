import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { DeleteTemplateSettingsRequest } from '@/Api/DocumentTemplateAdminSettings/Requests/DeleteTemplateSettingsRequest';
import { DeleteTemplateSettingsResponse } from '@/Api/DocumentTemplateAdminSettings/Responses/DeleteTemplateSettingsResponse';
import { GetTemplateSettingsResponse } from '@/Api/DocumentTemplateAdminSettings/Responses/GetTemplateSettingsResponse';
import { CreateTemplateSettingsRequest } from '@/Api/DocumentTemplateAdminSettings/Requests/CreateTemplateSettingsRequest';
import { CreateTemplateSettingsResponse } from '@/Api/DocumentTemplateAdminSettings/Responses/CreateTemplateSettingsResponse';
import { UpdateTemplateSettingsRequest } from '@/Api/DocumentTemplateAdminSettings/Requests/UpdateTemplateSettingsRequest';
import { UpdateTemplateSettingsResponse } from '@/Api/DocumentTemplateAdminSettings/Responses/UpdateTemplateSettingsResponse';

const url = ApiRoutingUtils.createUrl('admin-document-templates');

async function getTemplateSettings(): Promise<GetTemplateSettingsResponse> {
  return rest.get(url);
}

async function createTemplateSettings(request: CreateTemplateSettingsRequest): Promise<CreateTemplateSettingsResponse> {
  return rest.post(url, request);
}

async function updateTemplateSettings(request: UpdateTemplateSettingsRequest): Promise<UpdateTemplateSettingsResponse> {
  return rest.put(url, request);
}

async function deleteTemplateSettings(request: DeleteTemplateSettingsRequest): Promise<DeleteTemplateSettingsResponse> {
  return rest.delete(`${url}/${request.id}`, null);
}

export const DocumentTemplateAdminSettingsApi = {
  deleteTemplateSettings,
  getTemplateSettings,
  createTemplateSettings,
  updateTemplateSettings,
};
