import { ApiRoutingUtils } from 'src/Routing';
import { rest } from 'src/Api/Rest';
import { ScanBarcodeExternalRequest } from 'src/Api/UserWarehouse/Transfers/External/Requests/ScanBarcodeExternalRequest';
import { ScanBarcodeExternalResponse } from 'src/Api/UserWarehouse/Transfers/External/Responses/ScanBarcodeExternalResponse';
import { SendFulfillmentTransferRequest } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Requests/SendFulfillmentTransferRequest';
import { SendFulfillmentTransferResponse } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Responses/SendFulfillmentTransferResponse';
import { GetFulfillmentTransferBatchRequest } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Requests/GetFulfillmentTransferBatchRequest';
import { GetFulfillmentTransferBatchResponse } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Responses/GetFulfillmentTransferBatchResponse';

const transferUrl = ApiRoutingUtils.createUrl('user-warehouse-fulfillment-transfer');

async function scanBarcode(request: ScanBarcodeExternalRequest): Promise<ScanBarcodeExternalResponse> {
  return rest.post(`${transferUrl}/scan-barcode`, request);
}

async function sendFulfillment(request: SendFulfillmentTransferRequest): Promise<SendFulfillmentTransferResponse> {
  return rest.post(`${transferUrl}/send-fulfillment`, request);
}

async function getBatch(request: GetFulfillmentTransferBatchRequest): Promise<GetFulfillmentTransferBatchResponse> {
  return rest.get(transferUrl, request);
}
export const WarehouseFulfillmentTransferApi = {
  scanBarcode,
  sendFulfillment,
  getBatch,
};
