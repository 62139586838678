import React, { ReactChild, useEffect } from 'react';
import { LocalizationType, SettingValueType } from '@/Enums';
import { useDispatch, useSelector } from 'react-redux';
import { ClassNameProps, Pair } from '@/Types';
import cn from 'classnames';
import { PagesRouting } from '@/Routing';
import { userAsyncActions } from '@/Redux/User/asyncActions';
import { useLocalization } from '@/Hooks';
import { userSelectors } from '@/Redux/User';
import { AppUserSettingNameType } from '@/Enums/AppUserSettingNameType';
import { useLanguageSelectOptions } from '@/Hooks/useLanguageSelectOptions';
import { globalSelectors } from '@/Redux/Global/selectors';
import { globalAsyncActions } from '@/Redux/Global/asyncActions';
import { SelectInput } from '@/Components/Controls/SelectInput/SelectInput';
import { Link } from '@/Components/Controls/Link/Link';
import { Label } from '@/Components/Controls/Label/Label';

import styles from './DropMenu.scss';

type Props = {
  children: ReactChild;
} & ClassNameProps;

export const DropMenu = ({ children, className }: Props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(userSelectors.currentUser);
  const selectedLanguage = useSelector(globalSelectors.selectedLanguage);
  const { dropMenu } = useLocalization();
  const uiLanguageSetting = useSelector(userSelectors.getSetting)(AppUserSettingNameType.UiLanguage);
  const languageOptions = useLanguageSelectOptions();

  const onLanguageChange = (value: Pair<LocalizationType>) => {
    dispatch(globalAsyncActions.getTranslation(value.key));
    dispatch(globalAsyncActions.getDocumentDesignerTranslation(value.key));
    dispatch(globalAsyncActions.getProductsTranslation(value.key));
    dispatch(
      userAsyncActions.addOrUpdateSetting({
        name: AppUserSettingNameType.UiLanguage,
        settingType: SettingValueType.String,
        value: value.key,
        id: uiLanguageSetting?.id ?? 0,
      }),
    );
  };

  useEffect(() => {
    if (languageOptions.length == 1) {
      onLanguageChange(languageOptions[0]);
      return;
    }

    const canUseSelectedLanguage = !!languageOptions.find((lang) => lang.key == selectedLanguage.key);

    if (!canUseSelectedLanguage && languageOptions.length != 0) {
      onLanguageChange(languageOptions[0]);
    }
  }, [languageOptions]);

  return (
    <div className={styles.dropMenu}>
      <div className={styles.dropMenu__fix} />
      <div className={styles.dropMenu__button}>{children}</div>
      <div className={cn(styles.dropMenu__body, className)}>
        <ul className={styles.dropMenu__header}>
          <li className={styles.dropMenu__headerItem}>{`${currentUser?.firstName} ${currentUser?.lastName}`}</li>
          <li className={styles.dropMenu__headerItem}>{currentUser?.login}</li>
          <li className={styles.dropMenu__headerItem}>{dropMenu.CustomerNo}</li>
          <li className={styles.dropMenu__headerItem}>{currentUser?.customerNo}</li>
        </ul>
        <ul className={styles.dropMenu__list}>
          <li className={styles.dropMenu__listItem}>
            <Link text={dropMenu.ProfileSettings} to={PagesRouting.SettingsPages.AccountSettings} />
          </li>
          <li className={styles.dropMenu__listItem}>
            <Link text={dropMenu.UpgradeAccount} to="" />
          </li>
          <li className={styles.dropMenu__listItem}>
            <Link text={dropMenu.Subscriptions} to={PagesRouting.ProfilePages.SubscriptionsPage} />
          </li>
          <li className={styles.dropMenu__listItem}>
            <Link text={dropMenu.Billing} to={PagesRouting.ProfilePages.BillingPage} />
          </li>
          <li className={styles.dropMenu__listItem}>
            <Link text={dropMenu.Payments} to={PagesRouting.ProfilePages.PaymentsPage} />
          </li>
          <li className={styles.dropMenu__listItem}>
            <Link text={dropMenu.Security} to="" />
          </li>
          <li className={styles.dropMenu__listItem}>
            <Link text={dropMenu.Language} to="" />
            <SelectInput
              options={languageOptions}
              name={'LanguageSelect'}
              className={styles.dropMenu__langInput}
              onChange={(value) => {
                if (value) onLanguageChange(value);
              }}
              value={selectedLanguage}
            />
          </li>
          <li className={styles.dropMenu__listItem}>
            <Link text={dropMenu.Support} to="" />
          </li>
          <li className={styles.dropMenu__listItem}>
            <Link text={dropMenu.Help} to={PagesRouting.SettingsPages.HelpSettings} />
          </li>
          <li className={styles.dropMenu__listItem}>
            <Link text={dropMenu.DeleteAccount} to="" />
          </li>
        </ul>
        <div className={styles.dropMenu__footer}>
          <Label
            className={styles.dropMenu__logout}
            text={dropMenu.LogOut}
            onClick={() => dispatch(userAsyncActions.logoutRequest())}
          />
        </div>
      </div>
    </div>
  );
};
