import React, { PropsWithChildren } from 'react';
import { BackgroundTaskItem } from './modules/BackgroundTaskItem/BackgroundTaskItem';
import styles from './BackgroundTasks.scss';
import Loader from 'react-loader-spinner';
import { backgroundTasksSelectors } from '@/Redux/BackgroundTasks/selectors';
import { useSelector } from 'react-redux';
import { useLocalization } from '@/Hooks';
import { Icons } from '@/Static/IconsRes';
import { Icon } from '@/Components/Icon/Icon';

const EmptyWrapper = ({ children }: PropsWithChildren<object>) => {
  return <>{children}</>;
};

export function BackgroundTasks() {
  const tasks = useSelector(backgroundTasksSelectors.backgroundTasks);

  const {
    enums: { BackgroundTaskType },
  } = useLocalization();

  return (
    <div className={styles.wrapper}>
      {tasks.map(({ id, maximum, current, taskType }) => (
        <EmptyWrapper key={id}>
          <label>{BackgroundTaskType[taskType as keyof typeof BackgroundTaskType]?.Title}:</label>
          <BackgroundTaskItem current={current} maximum={maximum} id={id} />
          <div className={styles.taskActions}>
            <Loader type={'Oval'} width={11} height={11} color={'#000'} />
            {/*<Icon src={Icons.Check} alt={'success'} />*/}
            {/*<Icon src={Icons.Invalid} alt={'invalid'} />*/}
            <Icon src={Icons.Delete} alt={'delete'} />
          </div>
        </EmptyWrapper>
      ))}
      {!tasks?.length && 'No tasks'}
    </div>
  );
}
