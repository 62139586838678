import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MpOrdersApi, GenerateNumberRequest } from '@/Api/MpOrders';
import { OrderTableRow, Nullable, OrderFilterModel } from '@/Types';
import { orderMapping } from '@/Mapping';
import { OrderType } from '@/Enums';
import { RootState } from '@/Redux/RootReducer';
import { dateTimeUtils } from '@/Utils';
import { purchasesPageSelectors } from '@/Pages/Purchases/services/selectors';
import { CheckGeneratedNumberForDuplicateRequest } from '@/Api/MpOrders/Requests/CheckGeneratedNumberForDuplicateRequest';
import { CheckGeneratedNumberForDuplicateResponse } from '@/Api/MpOrders/Responses/CheckGeneratedNumberForDuplicateResponse';

export type PurchasesPageState = {
  purchases: OrderTableRow[];
  selectedPurchase: Nullable<OrderTableRow>;
  selectedPurchases: OrderTableRow[];
  orderFilters: Nullable<OrderFilterModel>;
  newPurchasesCount: number;
  updatedPurchasesExists: boolean;
};

const initialState: PurchasesPageState = {
  purchases: [],
  selectedPurchase: null,
  selectedPurchases: [],
  orderFilters: null,
  newPurchasesCount: 0,
  updatedPurchasesExists: false,
};

const getPurchasesWithCurrentFilters = createAsyncThunk(
  'purchasesPage/get-sales-with-current-filters',
  async (params: undefined, api) => {
    const state: RootState = api.getState() as RootState;
    const orderFilters = purchasesPageSelectors.orderFilters(state);

    return MpOrdersApi.getOrders({
      dateRange: dateTimeUtils.localDateRangeToUtcString(orderFilters.dateRange),
      orderType: OrderType.Purchase,
      mpAccountIds: orderFilters.mpAccountIds ?? [],
      orderStatusFilter: orderFilters.orderStatusFilter,
    });
  },
);

const generateNumberForOrder = createAsyncThunk<void, GenerateNumberRequest>(
  'purchasesPage/generate-number-for-order',
  async (request: GenerateNumberRequest) => {
    return MpOrdersApi.generateNumberForOrder(request);
  },
);

const checkGeneratedNumberForOrder = createAsyncThunk<
  CheckGeneratedNumberForDuplicateResponse,
  CheckGeneratedNumberForDuplicateRequest
>('purchasesPage/check-generated-number', (request: CheckGeneratedNumberForDuplicateRequest) => {
  return MpOrdersApi.checkGeneratedNumberForOrder(request);
});
const purchasesPageSlice = createSlice({
  name: 'purchasesPage',
  initialState,
  reducers: {
    setSelectedPurchase: (state, action: PayloadAction<Nullable<OrderTableRow>>) => {
      state.selectedPurchase = action.payload;
    },
    setSelectedPurchases: (state, action: PayloadAction<OrderTableRow[]>) => {
      state.selectedPurchases = action.payload;
    },
    setOrderFilters: (state, action: PayloadAction<OrderFilterModel>) => {
      state.orderFilters = action.payload;
    },
    setNewPurchasesCount: (state, action: PayloadAction<number>) => {
      state.newPurchasesCount = action.payload;
    },
    addNewPurchasesCount: (state, action: PayloadAction<number>) => {
      state.newPurchasesCount += action.payload;
    },
    setUpdatedPurchasesExists: (state) => {
      state.updatedPurchasesExists = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPurchasesWithCurrentFilters.fulfilled, (state, action) => {
      state.purchases = orderMapping.convertOrdersToOrderTableRows(action.payload);
      state.newPurchasesCount = 0;
      state.updatedPurchasesExists = false;
    });
  },
});

export const purchasesPageReducer = purchasesPageSlice.reducer;
export const purchasesPageActions = purchasesPageSlice.actions;
export const purchasesPageAsyncActions = {
  getPurchasesWithCurrentFilters,
  generateNumberForOrder,
  checkGeneratedNumberForOrder,
};
