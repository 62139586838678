import { createSlice } from '@reduxjs/toolkit';
import { TranslationSettingModel } from '@/Models/LanguageEditor/TranslationSettingModel';
import { Nullable } from '@/Types';
import { editorSettingsAsyncActions } from '@/Pages/Administration/LanguageEditor/modules/EditorSettings/services/asyncActions';
import { LanguageSettingModel } from '@/Models/LanguageEditor/LanguageSettingModel';

export type EditorSettingsState = {
  languageEditorSettings: Nullable<TranslationSettingModel>;
  languageAccessSettings: Nullable<LanguageSettingModel>;
};

const initialState: EditorSettingsState = {
  languageEditorSettings: null,
  languageAccessSettings: null,
};

const editorSettingsSlice = createSlice({
  name: 'languageEditorPage/editor-settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editorSettingsAsyncActions.getTranslationSetting.fulfilled, (state, action) => {
        state.languageEditorSettings = action.payload.setting;
      })
      .addCase(editorSettingsAsyncActions.updateTranslationSetting.fulfilled, (state, action) => {
        state.languageEditorSettings = action.payload.setting;
      })
      .addCase(editorSettingsAsyncActions.getLanguageSetting.fulfilled, (state, action) => {
        state.languageAccessSettings = action.payload.setting;
      })
      .addCase(editorSettingsAsyncActions.updateLanguageSetting.fulfilled, (state, action) => {
        state.languageAccessSettings = action.payload.setting;
      });
  },
});

export const { reducer: editorSettingsReducer, actions: editorSettingsActions } = editorSettingsSlice;
