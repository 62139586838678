import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';
import { GenSubProductTypesDataState } from './reducer';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { ObjectUtils } from '@/Utils';

const genSubProductTypesDataSelector = (root: RootState) => root.dynamicData.genSubProductTypesData;
const translation = (root: RootState) => root.global.translation;

const subProductTypes = createSelector(
  genSubProductTypesDataSelector,
  (state: GenSubProductTypesDataState) => state.subProductTypes,
);

const subProductTypesOptions = createSelector(
  [genSubProductTypesDataSelector, translation],
  (state: GenSubProductTypesDataState, translation) => {
    const {
      Pages: {
        WarehouseLogistics: { SettingsProducts },
      },
    } = translation;
    return state.subProductTypes.map((x) => {
      return {
        key: x.id,
        value: ObjectUtils.getObjectPathValue(SettingsProducts, `Types.GenSubProductType.${x.name}`) ?? x.name,
      };
    });
  },
);

const subProductTypesTreeViewOptionsMemo = createSelector(
  [subProductTypesOptions],
  (subProductTypesOptions): TreeItemType[] => {
    return subProductTypesOptions.map(
      (item) =>
        ({
          key: item.key,
          value: item.value,
          expanded: true,
        } as TreeItemType),
    );
  },
);

export const genSubProductTypesDataSelectors = {
  subProductTypes,
  subProductTypesOptions,
  subProductTypesTreeViewOptionsMemo,
};
