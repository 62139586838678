import { rest } from '@/Api/Rest';
import { ApiRoutingUtils } from '@/Routing';
import { CreateAdminAccountChartTypeRequest } from './Admin/CreateAdminAccountChartTypeRequest';
import { CreateAdminAccountChartTypeResponse } from './Admin/CreateAdminAccountChartTypeResponse';
import { DeleteAdminAccountChartTypeRequest } from './Admin/DeleteAdminAccountChartTypeRequest';
import { DeleteAdminAccountChartTypeResponse } from './Admin/DeleteAdminAccountChartTypeResponse';
import { UpdateAdminAccountChartTypeRequest } from './Admin/UpdateAdminAccountChartTypeRequest';
import { UpdateAdminAccountChartTypeResponse } from './Admin/UpdateAdminAccountChartTypeResponse';
import { GetAvailableAccountChartTypesRequest } from './GetAvailableAccountChartTypesRequest';
import { GetAvailableAccountChartTypesResponse } from './GetAvailableAccountChartTypesResponse';

const url = ApiRoutingUtils.createOnlineArchiveUrl('account-chart-types');
const adminUrl = ApiRoutingUtils.createOnlineArchiveUrl('admin-account-chart-types');

/* User api */
const getAvailableAccountChartTypes = (
  request: GetAvailableAccountChartTypesRequest,
): Promise<GetAvailableAccountChartTypesResponse> => {
  return rest.get(url, request);
};

/* Admin api */
const createAccountChartType = (
  request: CreateAdminAccountChartTypeRequest,
): Promise<CreateAdminAccountChartTypeResponse> => {
  return rest.post(adminUrl, request);
};

const updateAccountChartType = (
  request: UpdateAdminAccountChartTypeRequest,
): Promise<UpdateAdminAccountChartTypeResponse> => {
  return rest.put(adminUrl, request);
};

const deleteAccountChartType = (
  request: DeleteAdminAccountChartTypeRequest,
): Promise<DeleteAdminAccountChartTypeResponse> => {
  return rest.delete(`${adminUrl}/${request.id}`, {});
};

export const AccountChartTypesApi = {
  user: {
    getAvailableAccountChartTypes,
  },
  admin: {
    createAccountChartType,
    updateAccountChartType,
    deleteAccountChartType,
  },
};
