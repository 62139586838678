import { createSlice } from '@reduxjs/toolkit';
import { UserProductCategoryModel } from '@/Models';
import { productSettingsAsyncActions } from './asyncActions';

export type ProductsSettingsState = {
  categories: UserProductCategoryModel[];
};

const initialState: ProductsSettingsState = {
  categories: [],
};

const productsSettingsSlice = createSlice({
  name: 'productsSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Locations
    builder.addCase(productSettingsAsyncActions.getProductCategories.fulfilled, (state, action) => {
      state.categories = action.payload.categories;
    });
  },
});

export const productsSettingsReducer = productsSettingsSlice.reducer;
export const productsSettingsActions = productsSettingsSlice.actions;
