import { ClassNameProps } from '@/Types';
import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './Tabs.scss';

type Props = ClassNameProps;

export function Tabs({ className, children }: PropsWithChildren<Props>) {
  return <div className={cn(className, styles.tabs)}>{children}</div>;
}
