import { FunctionGroupModel } from '@/Models';
import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('functions');

async function getFunctions(): Promise<FunctionGroupModel[]> {
  return rest.get(url);
}

export const FunctionsApi = {
  getFunctions,
};
