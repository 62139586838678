import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetRegionalSettingsResponse, RegionalSettingsApi } from '@/Api/RegionalSettings';

const getRegionalSettings = createAsyncThunk<GetRegionalSettingsResponse, void>(
  'regional-settings/get-regional-settings',
  async () => {
    return RegionalSettingsApi.getRegionalSettings();
  },
);

export const asyncActions = {
  getRegionalSettings,
};
