import {
  UpdateDocumentsRequest,
  UpdateDocumentsResponse,
  GetDocumentsRequest,
  DocumentsApi,
  CreateDocumentsResponse,
  CreateDocumentsRequest,
} from '@/Api/_Microservices/OnlineArchive/Documents';
import { CreateReportRequest, CreateReportResponse, ReportsApi } from '@/Api/_Microservices/OnlineArchive/Reports';
import { DocumentInfoModel } from '@/Models/_Microservices/OnlineArchive/DocumentInfoModel';
import { ReportInfoModel } from '@/Models/_Microservices/OnlineArchive/ReportInfoModel';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getDocuments = createAsyncThunk<DocumentInfoModel[], GetDocumentsRequest>(
  'onlineArchivePage/get-documents',
  async (request: GetDocumentsRequest) => {
    return DocumentsApi.getDocuments(request);
  },
);

const createDocuments = createAsyncThunk<
  CreateDocumentsResponse,
  {
    request: CreateDocumentsRequest;
    onUploadProgress: (progressEvent: any) => void;
  }
>(
  'onlineArchivePage/create-documents',
  async (requestParams: { request: CreateDocumentsRequest; onUploadProgress: (progressEvent: any) => void }) => {
    return DocumentsApi.createDocuments(requestParams.request, requestParams.onUploadProgress);
  },
);

const updateDocuments = createAsyncThunk<UpdateDocumentsResponse, UpdateDocumentsRequest>(
  'onlineArchivePage/update-documents',
  async (request: UpdateDocumentsRequest) => {
    return DocumentsApi.updateDocuments(request);
  },
);

const getReports = createAsyncThunk<ReportInfoModel[], void>('onlineArchivePage/get-reports', async () => {
  return ReportsApi.getReports();
});

const getReport = createAsyncThunk<ReportInfoModel, number>('onlineArchivePage/get-report', async (id: number) => {
  return ReportsApi.getReport(id);
});

const createReport = createAsyncThunk<CreateReportResponse, CreateReportRequest>(
  'onlineArchivePage/create-report',
  async (request: CreateReportRequest) => {
    return ReportsApi.createReport(request);
  },
);

const deleteReport = createAsyncThunk<number, number>('onlineArchivePage/delete-report', async (id: number) => {
  return ReportsApi.deleteReport(id);
});

export const asyncActions = {
  getDocuments,
  createDocuments,
  updateDocuments,
  getReports,
  getReport,
  createReport,
  deleteReport,
};
