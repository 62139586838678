import { LogisticsLogoModel } from '@/Models/Logistics/LogisticsLogoModel';
import { createSlice } from '@reduxjs/toolkit';
import { logisticsLogoSettingsAsyncActions } from './asyncActions';

export type LogisticsLogoSettingsState = {
  logoSettings: LogisticsLogoModel[];
};

const initialState: LogisticsLogoSettingsState = {
  logoSettings: [],
};

const logisticsLogoSettingsSlice = createSlice({
  name: 'logisticsLogoSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logisticsLogoSettingsAsyncActions.getLogisticsLogoSettings.fulfilled, (state, action) => {
        state.logoSettings = action.payload.logisticsLogoModels;
      })
      .addCase(logisticsLogoSettingsAsyncActions.createLogisticLogoSettings.fulfilled, (state, action) => {
        state.logoSettings.push(action.payload.logisticsLogoModel);
      })
      .addCase(logisticsLogoSettingsAsyncActions.updateLogisticLogoSettings.fulfilled, (state, action) => {
        state.logoSettings = state.logoSettings.map((item) =>
          item.id === action.payload.logisticsLogoModel.id ? action.payload.logisticsLogoModel : item,
        );
      })
      .addCase(logisticsLogoSettingsAsyncActions.deleteLogisticLogoSettings.fulfilled, (state, action) => {
        state.logoSettings = state.logoSettings.filter((item) => item.id !== action.payload.id);
      });
  },
});

export const logisticsLogoSettingsActions = logisticsLogoSettingsSlice.actions;
export const logisticsLogoSettingsReducer = logisticsLogoSettingsSlice.reducer;
