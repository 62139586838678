import { combineReducers } from '@reduxjs/toolkit';
import {
  shippingMethodsDataAsyncActions,
  shippingMethodsDataReducer,
  ShippingMethodsDataState,
} from './ShippingMethodsData/reducer';
import {
  documentCategoriesDataAsyncActions,
  documentCategoriesDataReducer,
  DocumentCategoriesDataState,
} from './DocumentCategoriesData/reducer';
import {
  documentTypesDataAsyncActions,
  documentTypesDataReducer,
  DocumentTypesDataState,
} from './DocumentTypesData/reducer';
import {
  documentFoldersDataActions,
  documentFoldersDataAsyncActions,
  documentFoldersDataReducer,
  DocumentFoldersDataState,
} from './DocumentFoldersData/reducer';
import {
  genLogisticTypesDataAsyncActions,
  genLogisticTypesDataReducer,
  GenLogisticTypesDataState,
} from './GenLogisticTypesData/reducer';
import {
  genProductTypesDataAsyncActions,
  genProductTypesDataReducer,
  GenProductTypesDataState,
} from './GenProductTypesData/reducer';
import {
  genAdditionalServiceTypesDataAsyncActions,
  genAdditionalServiceTypesDataReducer,
  GenAdditionalServiceTypesDataState,
} from './GenAdditionalServiceTypesData/reducer';
import {
  genSubProductTypesDataAsyncActions,
  genSubProductTypesDataReducer,
  GenSubProductTypesDataState,
} from './GenSubProductTypesData/reducer';
import {
  genPackageTypesDataAsyncActions,
  genPackageTypesDataReducer,
  GenPackageTypesDataState,
} from './GenPackageTypesData/reducer';
import {
  regionalSettingsDataAsyncActions,
  regionalSettingsDataReducer,
  RegionalSettingsDataState,
} from './RegionalSettingsData/reducer';
import {
  uploadTemplatesDataAsyncActions,
  uploadTemplatesDataReducer,
  UploadTemplatesDataState,
} from './UploadTemplatesData/reducer';
import {
  accountChartsDataAsyncActions,
  accountChartsDataReducer,
  AccountChartsDataState,
} from './AccountChartsData/reducer';

export type DynamicDataState = {
  shippingMethodsData: ShippingMethodsDataState;
  documentCategoriesData: DocumentCategoriesDataState;
  documentTypesData: DocumentTypesDataState;
  documentFoldersData: DocumentFoldersDataState;
  genLogisticTypesData: GenLogisticTypesDataState;
  genProductTypesData: GenProductTypesDataState;
  genAdditionalServiceTypesData: GenAdditionalServiceTypesDataState;
  genSubProductTypesData: GenSubProductTypesDataState;
  genPackageTypesData: GenPackageTypesDataState;
  regionalSettingsData: RegionalSettingsDataState;
  uploadTemplatesData: UploadTemplatesDataState;
  accountChartsData: AccountChartsDataState;
};

export const dynamicDataReducer = combineReducers<DynamicDataState>({
  shippingMethodsData: shippingMethodsDataReducer,
  documentCategoriesData: documentCategoriesDataReducer,
  documentTypesData: documentTypesDataReducer,
  documentFoldersData: documentFoldersDataReducer,
  genLogisticTypesData: genLogisticTypesDataReducer,
  genProductTypesData: genProductTypesDataReducer,
  genAdditionalServiceTypesData: genAdditionalServiceTypesDataReducer,
  genSubProductTypesData: genSubProductTypesDataReducer,
  genPackageTypesData: genPackageTypesDataReducer,
  regionalSettingsData: regionalSettingsDataReducer,
  uploadTemplatesData: uploadTemplatesDataReducer,
  accountChartsData: accountChartsDataReducer,
});

export const dynamicDataAsyncActions = {
  shippingMethodsData: shippingMethodsDataAsyncActions,
  documentCategoriesData: documentCategoriesDataAsyncActions,
  documentTypesData: documentTypesDataAsyncActions,
  documentFoldersData: documentFoldersDataAsyncActions,
  genLogisticTypesData: genLogisticTypesDataAsyncActions,
  genProductTypesData: genProductTypesDataAsyncActions,
  genAdditionalServiceTypesData: genAdditionalServiceTypesDataAsyncActions,
  genSubProductTypesData: genSubProductTypesDataAsyncActions,
  genPackageTypesData: genPackageTypesDataAsyncActions,
  regionalSettingsData: regionalSettingsDataAsyncActions,
  uploadTemplatesData: uploadTemplatesDataAsyncActions,
  accountChartsData: accountChartsDataAsyncActions,
};

export const dynamicDataActions = {
  documentFolderData: documentFoldersDataActions,
};
