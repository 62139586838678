import { AppUserModel } from '@/Models';
import { PagesRouting } from '@/Routing';

function getRedirectRoute(user: AppUserModel): PagesRouting {
  if (!user.isManualMpAccountCreateStepCompleted || !user.isNeedNewMpAccountStepCompleted) {
    return PagesRouting.SettingsPages.MarketplacesSettings;
  } // if

  return PagesRouting.MainPages.SalesPage.Sales;
}

export const userUtils = {
  getRedirectRoute,
};
