import React from 'react';
import { Label } from '@/Components/Controls/Label/Label';
import { FormInput } from '@/Components/Controls/Input/FormInput';
import styles from './MergeOrdersAddressSection.scss';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { OrderTableRow, Pair } from '@/Types';
import { useMergeOrdersAddressSectionForm, FormData } from './hooks/useMergeOrdersAddressSectionForm';
import { useLocalization } from '@/Hooks';

type Props = {
  isInvoice: boolean;
  orders: OrderTableRow[];
  differentOrders: Pair<number>[];
};

export function MergeOrdersAddressSection({ isInvoice, orders, differentOrders }: Props) {
  const {
    modalWindows: {
      mergeOrders: { AddressSection },
    },
  } = useLocalization();
  const {
    setValue,
    register,
    errors,
    rules: { required },
  } = useMergeOrdersAddressSectionForm(isInvoice, orders);

  return (
    <div className={styles.mergeOrdersSection}>
      <div className={styles.mergeOrdersSectionHeader}>
        <Label text={isInvoice ? AddressSection.InvoiceAddress : AddressSection.ShippingAddress} />
        <FormSelectInput
          options={[{ key: '', value: '' }, ...differentOrders]}
          register={register}
          needClearButton={false}
          setValue={setValue}
          rules={required}
          error={errors}
          name={isInvoice ? 'invoiceAddressSourceOrderId' : 'shippingAddressSourceOrderId'}
        />
      </div>
      <Label text={AddressSection.Additional} />
      <FormInput<FormData>
        register={register}
        readOnly={true}
        name={isInvoice ? 'invoiceAdditionalLine' : 'shippingAdditionalLine'}
      />

      <Label text={AddressSection.Street} />
      <div className={styles.addressStreetFields}>
        <FormInput<FormData>
          register={register}
          readOnly={true}
          name={isInvoice ? 'invoiceStreet' : 'shippingStreet'}
        />
        <FormInput<FormData>
          register={register}
          readOnly={true}
          name={isInvoice ? 'invoiceHouseNo' : 'shippingHouseNo'}
        />
      </div>

      <Label text={AddressSection.ZipAndCity} />
      <div className={styles.addressCityFields}>
        <FormInput<FormData>
          register={register}
          readOnly={true}
          name={isInvoice ? 'invoicePostalCode' : 'shippingPostalCode'}
        />
        <FormInput<FormData>
          register={register}
          readOnly={true}
          name={isInvoice ? 'invoiceCityName' : 'shippingCityName'}
        />
      </div>

      <Label text={AddressSection.Country} />
      <div className={styles.addressCountryFields}>
        <FormInput<FormData>
          register={register}
          readOnly={true}
          name={isInvoice ? 'invoiceCountryCode' : 'shippingCountryCode'}
        />
        <FormInput<FormData>
          register={register}
          readOnly={true}
          name={isInvoice ? 'invoiceCountryName' : 'shippingCountryName'}
        />
      </div>
    </div>
  );
}
