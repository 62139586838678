import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShippingLabelIcon } from '@/Static';
import styles from './Menu.scss';
import { PagesRouting } from '@/Routing';
import { MenuItemType, ModalType, NumberGenerationType } from '@/Enums';
import { modalWindowsActions } from '@/ModalWindows/services';
import { useLocalization, useShowGenerateDocumentNumberConfirmationDialog } from '@/Hooks';
import { useGenerationNumbers } from '@/Components/Header/modules/Menu/hooks/useGenerationNumbers';
import { Label } from '@/Components/Controls/Label/Label';
import { Tooltip } from '@/Components/Tooltip/Tooltip';
import { Icons, TopMenuIcons } from '@/Static/IconsRes';
import { useSplitAndMergeOrders } from '@/Components/Header/modules/Menu/hooks/useSplitAndMergeOrders';
import { useIsOrderSplittingDisabled } from '@/ModalWindows/SplitOrdersModal/hooks/useIsOrderSplittingDisabled';
import { useIsOrderMergingDisabled } from '@/ModalWindows/MergeOrdersModal/hooks/useIsOrderMergingDisabled';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { useOrderFulfillment } from '@/Components/Header/modules/Menu/hooks/useOrderFullfilment';
import { useChangeDeliveryStatus } from 'src/Components/Header/modules/Menu/hooks/useChangeDeliveryStatus';

const MenuComponent: React.FC = () => {
  const dispatch = useDispatch();

  const { menu } = useLocalization();

  const path = location.pathname;

  const {
    orderGenType,
    fields: { invoiceNumberProps, cancelInvoiceNumberProps, orderNumberProps, deliveryNoteNumberProps },
  } = useGenerationNumbers();

  const {
    changeOrderStatusDisabledProps,
    shippingLabelFulfilledByMarketplaceProps,
    feedbackFulfilledByMarketplaceProps,
  } = useOrderFulfillment();

  const { changeDeliveryStatusDisabledMessage, isChangeDeliveryStatusDisabled } = useChangeDeliveryStatus();

  const { openSplitOrdersModal } = useSplitAndMergeOrders();
  const { showGenerateDocumentNumberConfirmationDialog } = useShowGenerateDocumentNumberConfirmationDialog();

  const { isOrdersSplittingDisabled, ordersSplittingDisabledReason } = useIsOrderSplittingDisabled();
  const { isOrdersMergingDisabled, ordersMergingDisabledReason } = useIsOrderMergingDisabled();

  const selectedSale = useSelector(salesPageSelectors.selectedSale);
  const selectedSales = useSelector(salesPageSelectors.selectedSales);
  return (
    <div className={styles.menu}>
      {(isMenuItemVisible(path, MenuItemType.AssignDeliveryNoteNumber) && (
        <Tooltip text={deliveryNoteNumberProps.disabledDocumentMessage} shouldShowOnHover={true}>
          <Label
            disabled={deliveryNoteNumberProps.isDocumentIconDisabled || !selectedSales.length}
            reverse={true}
            text={menu.AssignDeliveryNoteNumber.LabelTitle}
            className={styles.menu__item}
            onClick={() => showGenerateDocumentNumberConfirmationDialog(NumberGenerationType.PackList)}
          >
            <div className={styles.imageWrapper}>
              <img src={TopMenuIcons.Doc} alt={menu.AssignDeliveryNoteNumber.LabelTitle} />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.AssignInvoiceNumber) && (
        <Tooltip text={invoiceNumberProps.disabledDocumentMessage} shouldShowOnHover={true}>
          <Label
            disabled={invoiceNumberProps.isDocumentIconDisabled || !selectedSales.length}
            reverse={true}
            text={menu.AssignInvoiceNumber.LabelTitle}
            className={styles.menu__item}
            onClick={() => showGenerateDocumentNumberConfirmationDialog(NumberGenerationType.Invoice)}
          >
            <div className={styles.imageWrapper}>
              <img src={TopMenuIcons.Doc} alt={menu.AssignInvoiceNumber.LabelTitle} />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.ChangePaymentStatus) && (
        <Tooltip text={changeOrderStatusDisabledProps.disabledDocumentMessage} shouldShowOnHover={true}>
          <Label
            disabled={!selectedSales.length || changeOrderStatusDisabledProps.isDocumentIconDisabled}
            reverse={true}
            text={menu.ChangePaymentStatus.LabelTitle}
            className={styles.menu__item}
            onClick={() => dispatch(modalWindowsActions.openModal({ modalType: ModalType.ChangePaymentStatus }))}
          >
            <div className={styles.imageWrapper}>
              <img src={TopMenuIcons.Euro} alt={menu.ChangePaymentStatus.LabelTitle} />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.Document) && (
        <Label
          disabled={!selectedSales.length}
          reverse={true}
          text={menu.Document.LabelTitle}
          className={styles.menu__item}
          onClick={() => dispatch(modalWindowsActions.openModal({ modalType: ModalType.DocumentTemplates }))}
          dataAttributes={{
            'data-testid': 'document-print',
          }}
        >
          <div className={styles.imageWrapper}>
            <img src={Icons.Printer} alt={menu.Document.LabelTitle} width={19} height={17} />
          </div>
        </Label>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.SplitOrders) && (
        <Tooltip text={ordersSplittingDisabledReason} shouldShowOnHover={true}>
          <Label
            reverse={true}
            text={menu.SplitOrder.LabelTitle}
            className={styles.menu__item}
            onClick={openSplitOrdersModal}
            disabled={isOrdersSplittingDisabled || !selectedSale}
          >
            <div className={styles.imageWrapper}>
              <img src={Icons.Divide} alt={menu.SplitOrder.LabelTitle} width={15} height={17} />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.CreateFeedback) && (
        <Tooltip text={feedbackFulfilledByMarketplaceProps.disabledDocumentMessage} shouldShowOnHover={true}>
          <Label
            reverse={true}
            text={menu.CreateFeedback.LabelTitle}
            className={styles.menu__item}
            disabled={!selectedSale || feedbackFulfilledByMarketplaceProps.isDocumentIconDisabled}
          >
            <div className={styles.imageWrapper}>
              <img src={TopMenuIcons.CreateFeedback} alt={menu.CreateFeedback.LabelTitle} />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.ChangeOtherStatuses) && (
        <Tooltip text={changeOrderStatusDisabledProps.disabledDocumentMessage} shouldShowOnHover={true}>
          <Label
            disabled={!selectedSales.length || changeOrderStatusDisabledProps.isDocumentIconDisabled}
            reverse={true}
            text={menu.ChangeOrderStatuses.LabelTitle}
            className={styles.menu__item}
            onClick={() => dispatch(modalWindowsActions.openModal({ modalType: ModalType.ChangeOtherStatuses }))}
          >
            <div className={styles.imageWrapper}>
              <img src={TopMenuIcons.ChangeOtherStatuses} alt={menu.ChangeOrderStatuses.LabelTitle} />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.AssignOrderNumber) && (
        <Tooltip text={orderNumberProps.disabledDocumentMessage} shouldShowOnHover={true}>
          <Label
            disabled={orderNumberProps.isDocumentIconDisabled || !selectedSales.length}
            reverse={true}
            text={menu.AssignOrderNumber.LabelTitle}
            className={styles.menu__item}
            onClick={() => showGenerateDocumentNumberConfirmationDialog(orderGenType)}
          >
            <div className={styles.imageWrapper}>
              <img src={TopMenuIcons.Doc} alt={menu.AssignOrderNumber.LabelTitle} />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.AssignCancelInvoiceNumber) && (
        <Tooltip text={cancelInvoiceNumberProps.disabledDocumentMessage} shouldShowOnHover={true}>
          <Label
            disabled={cancelInvoiceNumberProps.isDocumentIconDisabled || !selectedSales.length}
            reverse={true}
            text={menu.AssignCancelInvoiceNumber.LabelTitle}
            className={styles.menu__item}
            onClick={() => showGenerateDocumentNumberConfirmationDialog(NumberGenerationType.CancelInvoice)}
          >
            <div className={styles.imageWrapper}>
              <img src={TopMenuIcons.Doc} alt={menu.AssignCancelInvoiceNumber.LabelTitle} />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.ChangeDeliveryStatus) && (
        <Tooltip text={changeDeliveryStatusDisabledMessage} shouldShowOnHover={true}>
          <Label
            disabled={!selectedSales.length || isChangeDeliveryStatusDisabled}
            reverse={true}
            text={menu.ChangeDeliveryStatus.LabelTitle}
            className={styles.menu__item}
            onClick={() => dispatch(modalWindowsActions.openModal({ modalType: ModalType.ChangeDeliveryStatus }))}
          >
            <div className={styles.imageWrapper}>
              <img src={TopMenuIcons.Delivery} alt={menu.ChangeDeliveryStatus.LabelTitle} />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.Email) && (
        <Label
          disabled={!selectedSales.length}
          reverse={true}
          text={menu.Email.LabelTitle}
          className={styles.menu__item}
          onClick={() => dispatch(modalWindowsActions.openModal({ modalType: ModalType.EmailSend }))}
        >
          <div className={styles.imageWrapper}>
            <img src={TopMenuIcons.EmailAt} alt={menu.Email.LabelTitle} />
          </div>
        </Label>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.OrderMerge) && (
        <Tooltip text={ordersMergingDisabledReason} shouldShowOnHover={true}>
          <Label
            reverse={true}
            text={menu.MergeOrder.LabelTitle}
            className={styles.menu__item}
            disabled={isOrdersMergingDisabled}
            onClick={() => dispatch(modalWindowsActions.openModal({ modalType: ModalType.MergeOrders }))}
          >
            <div className={styles.imageWrapper}>
              <img src={Icons.Merge} alt={menu.MergeOrder.LabelTitle} width={13} height={17} />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}

      {(isMenuItemVisible(path, MenuItemType.ShippingLabel) && (
        <Tooltip text={shippingLabelFulfilledByMarketplaceProps.disabledDocumentMessage} shouldShowOnHover={true}>
          <Label
            reverse={true}
            disabled={!selectedSales.length || shippingLabelFulfilledByMarketplaceProps.isDocumentIconDisabled}
            text={menu.ShippingLabel.LabelTitle}
            className={styles.menu__item}
            onClick={() => dispatch(modalWindowsActions.openModal({ modalType: ModalType.ShippingLabel }))}
            dataAttributes={{
              'data-testid': 'shipping-label',
            }}
          >
            <div className={styles.imageWrapper}>
              <ShippingLabelIcon />
            </div>
          </Label>
        </Tooltip>
      )) || <div className={styles.menu__item} />}
    </div>
  );
};

const ALL_MENU_ITEMS = {
  [MenuItemType.AssignOrderNumber]: true,
  [MenuItemType.AssignDeliveryNoteNumber]: true,
  [MenuItemType.AssignInvoiceNumber]: true,
  [MenuItemType.AssignCancelInvoiceNumber]: true,
  [MenuItemType.ChangePaymentStatus]: true,
  [MenuItemType.ChangeDeliveryStatus]: true,
  [MenuItemType.Document]: true,
  [MenuItemType.Email]: true,
  [MenuItemType.SplitOrders]: true,
  [MenuItemType.OrderMerge]: true,
  [MenuItemType.ShippingLabel]: true,
  [MenuItemType.CreateFeedback]: true,
  [MenuItemType.ShippingLabel]: true,
  [MenuItemType.ChangeOtherStatuses]: true,
};

const MENU_ITEMS_VISIBILITY_MAP: Record<string, Partial<{ [key in MenuItemType]: boolean }>> = {
  [PagesRouting.MainPages.SalesPage.Sales]: ALL_MENU_ITEMS,
  [PagesRouting.MainPages.PurchasesPage]: {
    [MenuItemType.AssignOrderNumber]: true,
    [MenuItemType.ChangePaymentStatus]: true,
    [MenuItemType.ChangeDeliveryStatus]: true,
    [MenuItemType.Document]: true,
    [MenuItemType.Email]: true,
    [MenuItemType.ShippingLabel]: true,
    [MenuItemType.CreateFeedback]: true,
    [MenuItemType.ShippingLabel]: true,
    [MenuItemType.ChangeOtherStatuses]: true,
  },
  [PagesRouting.MainPages.LogisticsPage.Logistics]: {
    [MenuItemType.Document]: true,
    [MenuItemType.Email]: true,
    [MenuItemType.ShippingLabel]: true,
  },
  [PagesRouting.MainPages.MessagesPage]: {},
  [PagesRouting.MainPages.SuppliersPage.Suppliers]: {
    [MenuItemType.Document]: true,
    [MenuItemType.Email]: true,
  },
  [PagesRouting.MainPages.WarehousePage.Warehouse]: {
    [MenuItemType.Document]: true,
    [MenuItemType.Email]: true,
  },
  [PagesRouting.MainPages.LogisticsPage.Logistics]: {
    [MenuItemType.Document]: true,
    [MenuItemType.Email]: true,
  },
  [PagesRouting.MainPages.CustomersPage.Customers]: {
    [MenuItemType.Document]: true,
    [MenuItemType.Email]: true,
  },
  [PagesRouting.SettingsPages.Settings]: {},
};

function isMenuItemVisible(pathName: string, menuItemType: MenuItemType): boolean {
  if (MENU_ITEMS_VISIBILITY_MAP[pathName]) {
    const pathMenuItems = MENU_ITEMS_VISIBILITY_MAP[pathName];
    return Boolean(pathMenuItems[menuItemType]);
  }
  return false;
}

export const Menu = MenuComponent;
