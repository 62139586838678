import { orderMapping } from '@/Mapping/OrderMapping';
import { OrderModel } from '@/Models';
import { stringUtils } from '@/Utils';
import { Nullable } from './Nullable';
import { OrderTableRow } from './OrderTableRow';
import { MarketplaceType, OrderStatus } from '@/Enums';

const isOriginalShippingModified = (orderTableRow: Nullable<OrderTableRow>): boolean => {
  return !!orderTableRow?.isOriginalShippingModified;
};

const isInvoiceAndOriginalShippingAddressesEqual = (orderTableRow: Nullable<OrderTableRow>): boolean => {
  return (
    stringUtils.isStringsEqual(orderTableRow?.invoiceCompanyName, orderTableRow?.originalShippingCompanyName) &&
    stringUtils.isStringsEqual(orderTableRow?.invoiceFirstName, orderTableRow?.originalShippingFirstName) &&
    stringUtils.isStringsEqual(orderTableRow?.invoiceLastName, orderTableRow?.originalShippingLastName) &&
    stringUtils.isStringsEqual(orderTableRow?.invoiceAdditionalLine, orderTableRow?.originalShippingAdditionalLine) &&
    stringUtils.isStringsEqual(orderTableRow?.invoiceStreet, orderTableRow?.originalShippingStreet) &&
    stringUtils.isStringsEqual(orderTableRow?.invoiceHouseNo, orderTableRow?.originalShippingHouseNo) &&
    stringUtils.isStringsEqual(orderTableRow?.invoicePostalCode, orderTableRow?.originalShippingPostalCode) &&
    stringUtils.isStringsEqual(orderTableRow?.invoiceCityName, orderTableRow?.originalShippingCityName) &&
    stringUtils.isStringsEqual(orderTableRow?.invoiceCountryCode, orderTableRow?.originalShippingCountryCode)
  );
};

type UpdatedOrderTableRowsArgs = {
  selectedOrder: Nullable<OrderTableRow>;
  selectedOrders: OrderTableRow[];
  orderRows: OrderTableRow[];
};

const updateOrderTableRows = (
  { selectedOrder, selectedOrders, orderRows }: UpdatedOrderTableRowsArgs,
  updatedOrders: OrderModel[],
): UpdatedOrderTableRowsArgs => {
  const resultOrderRows: OrderTableRow[] = orderRows;
  const updatedOrderRows = orderMapping.convertOrdersToOrderTableRows(updatedOrders);

  updatedOrderRows.forEach((sale) => {
    const orderIdx = resultOrderRows.findIndex((o) => o.id === sale.id && o.orderItemId === sale.orderItemId);

    if (orderIdx != -1) {
      resultOrderRows[orderIdx] = sale;
    } else {
      resultOrderRows.push(sale);
    }
  });

  const resultSelectedOrder = updatedOrderRows.find(
    (o) => o.id === selectedOrder?.id && o.orderItemId === selectedOrder?.orderItemId,
  );

  const resultSelectedOrders = selectedOrders.map((sale) => {
    const order = updatedOrderRows.find((o) => o.id === sale.id && o.orderItemId === sale.orderItemId);
    return order || sale;
  });

  return {
    orderRows: resultOrderRows,
    selectedOrder: resultSelectedOrder || null,
    selectedOrders: resultSelectedOrders,
  };
};

const isOrderIsAmazonPending = (order: OrderTableRow) => {
  return order.orderStatus === OrderStatus.Pending && order.marketplaceType === MarketplaceType.Amazon;
};

export const orderTableRowUtils = {
  isOriginalShippingModified,
  isInvoiceAndOriginalShippingAddressesEqual,
  updateOrderTableRows,
  isOrderIsAmazonPending,
};
