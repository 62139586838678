import { ApiRoutingUtils } from '@/Routing';
import {
  CreateGenProductTypeRequest,
  CreateGenProductTypeResponse,
  UpdateGenProductTypeRequest,
  UpdateGenProductTypeResponse,
} from '.';
import { GenProductTypeModel } from '@/Models';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('gen-product-types');

async function getProductTypes(): Promise<GenProductTypeModel[]> {
  return rest.get(url);
}

async function createProductType(request: CreateGenProductTypeRequest): Promise<CreateGenProductTypeResponse> {
  return rest.post(url, request);
}

async function updateProductType(request: UpdateGenProductTypeRequest): Promise<UpdateGenProductTypeResponse> {
  return rest.put(url, request);
}

async function deleteProductType(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

export const GenProductTypesApi = {
  getProductTypes,
  createProductType,
  updateProductType,
  deleteProductType,
};
