export enum DateRangeType {
  Last30Days,
  Last90Days,
  Today,
  Yesterday,
  ThisWeek,
  LastWeek,
  ThisMonth,
  LastMonth,
  Custom,
}
