export enum OrderStatus {
  /**
   Order in cart
  */
  Pending = 'Pending',

  /**
  Work on order
  */

  Active = 'Active',

  /**
  Order completed
  */
  Completed = 'Completed',
  /**
  Order canceled by buyer or seller
  */
  Canceled = 'Canceled',

  /**
  Order archived
  */
  Archived = 'Archived',
}
