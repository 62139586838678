import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogisticsLogosApi } from '@/Api/LogisticsLogos/LogisticsLogosApi';
import { GetLogisticLogosResponse } from '@/Api/LogisticsLogos/Responses/GetLogisticLogosResponse';
import { CreateLogisticLogoResponse } from '@/Api/LogisticsLogos/Responses/CreateLogisticLogoResponse';
import { CreateLogisticLogoRequest } from '@/Api/LogisticsLogos/Requests/CreateLogisticLogoRequest';
import { UpdateLogisticLogoResponse } from '@/Api/LogisticsLogos/Responses/UpdateLogisticLogoResponse';
import { UpdateLogisticLogoRequest } from '@/Api/LogisticsLogos/Requests/UpdateLogisticLogoRequest';
import { DeleteLogisticLogoResponse } from '@/Api/LogisticsLogos/Responses/DeleteLogisticLogoResponse';

const getLogisticsLogoSettings = createAsyncThunk<GetLogisticLogosResponse>(
  'logisticsLogoSettings/get-logo-settings',
  async () => {
    return await LogisticsLogosApi.getLogisticLogos();
  },
);

const createLogisticLogoSettings = createAsyncThunk<CreateLogisticLogoResponse, CreateLogisticLogoRequest>(
  'logisticsLogoSettings/create-logo-settings',
  async (request) => {
    return await LogisticsLogosApi.createLogisticLogo(request);
  },
);

const updateLogisticLogoSettings = createAsyncThunk<UpdateLogisticLogoResponse, UpdateLogisticLogoRequest>(
  'logisticsLogoSettings/update-logo-settings',
  async (request) => {
    return await LogisticsLogosApi.updateLogisticLogo(request);
  },
);

const deleteLogisticLogoSettings = createAsyncThunk<DeleteLogisticLogoResponse, number>(
  'logisticsLogoSettings/delete-logo-settings',
  async (request) => {
    return await LogisticsLogosApi.deleteLogisticLogo(request);
  },
);

export const logisticsLogoSettingsAsyncActions = {
  getLogisticsLogoSettings,
  createLogisticLogoSettings,
  updateLogisticLogoSettings,
  deleteLogisticLogoSettings,
};
