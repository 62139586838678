export enum SaleFormat {
  Unknown = 'Unknown',
  Chinese = 'Chinese',
  Dutch = 'Dutch',
  Live = 'Live',
  Auction = 'Auction',
  AdType = 'AdType',
  StoresFixedPrice = 'StoresFixedPrice',
  PersonalOffer = 'PersonalOffer',
  FixedPriceItem = 'FixedPriceItem',
  Half = 'Half',
  LeadGeneration = 'LeadGeneration',
  Express = 'Express',
  Shopping = 'Shopping',
  CustomCode = 'CustomCode',
  StandardOrder = 'StandardOrder',
  LongLeadTimeOrder = 'LongLeadTimeOrder',
  Preorder = 'Preorder',
  BackOrder = 'BackOrder',
  SourcingOnDemandOrder = 'SourcingOnDemandOrder',
}
