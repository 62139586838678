import { LocalizationType } from '@/Enums/LocalizationType';
import { Pair } from '@/Types';

export const LANGUAGE_SELECT_OPTIONS_MAP: { [key: string]: Pair<LocalizationType> } = {
  [LocalizationType.LA]: {
    key: LocalizationType.LA,
    value: 'L-E',
  },
  [LocalizationType.enEN]: {
    key: LocalizationType.enEN,
    value: 'ENG',
  },
  [LocalizationType.ruRU]: {
    key: LocalizationType.ruRU,
    value: 'RUS',
  },
  [LocalizationType.deDE]: {
    key: LocalizationType.deDE,
    value: 'GER',
  },
};
