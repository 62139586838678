import React, { useEffect } from 'react';
import { ModalProps } from '@/Types';
import { Modal } from '@/Components/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { EmailTemplatesTable } from './modules/EmailTemplatesTable/EmailTemplatesTable';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { useLocalization } from '@/Hooks';
import { emailSendModalSelectors } from './services/selectors';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useEmailSendModalActions } from './hooks/useEmailSendModalActions';
import styles from './EmailSendModal.scss';
import { EmailPreviewModal } from './modules/EmailPreviewModal/EmailPreviewModal';
import { emailSendModalActions, emailSendModalAsyncActions } from './services/reducer';

export const EmailSendModal = ({ closeModalWindow }: ModalProps): React.ReactElement | null => {
  const dispatch: AppDispatch = useDispatch();

  const {
    modalWindows: { emailSendModal },
    buttons,
  } = useLocalization();

  const emailTemplates = useSelector(emailSendModalSelectors.rows);
  const selectedSalesIds = useSelector(salesPageSelectors.selectedSalesIds);
  const selectedEmailTemplate = useSelector(emailSendModalSelectors.selectedEmailTemplate);
  const emailPreviewModalData = useSelector(emailSendModalSelectors.emailPreviewModalData);

  const { sendEmail } = useEmailSendModalActions(selectedEmailTemplate, selectedSalesIds, closeModalWindow);

  useEffect(() => {
    if (!emailTemplates.length) {
      dispatch(emailSendModalAsyncActions.getEmailTemplates());
    } // if
  }, []);

  if (!selectedSalesIds.length) {
    closeModalWindow();
    return null;
  } // if

  return (
    <Modal title={emailSendModal.Title} onClose={closeModalWindow} className={styles.modal}>
      {emailPreviewModalData.visible && emailPreviewModalData.data && (
        <EmailPreviewModal
          data={emailPreviewModalData.data}
          onClose={() => dispatch(emailSendModalActions.setEmailPreviewModalData({ visible: false }))}
        />
      )}
      <EmailTemplatesTable />

      <div className={styles.modalButtons}>
        <Button text={buttons.Cancel} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} onClick={closeModalWindow} />

        <Button
          autoFocus={true}
          text={buttons.Send}
          theme={BUTTON_THEMES.SUCCESS}
          disabled={Boolean(!selectedEmailTemplate)}
          onClick={sendEmail}
        />
      </div>
    </Modal>
  );
};
