import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { OrderMarker } from '@/Enums/OrderMarker';
import { useCallback } from 'react';
import { salesPageActions } from '@/Pages/Sales/services';
import { useDispatch } from 'react-redux';
import { OrderStatusFilterGroups } from 'src/Enums';

export const useAllShippedForm = (orderIds: number[], onClose: () => void) => {
  const dispatch = useDispatch();

  const submit = useCallback(() => {
    onClose();

    MpOrdersMarkingApi.changeDeliveryStatus({ orderIds, orderMarker: OrderMarker.NotShipped }).then(() => {
      // remove sales from table if current filters is shipped
      dispatch(
        salesPageActions.removeOrdersFromStateByCurrentFilters({
          orderIds: orderIds,
          filters: OrderStatusFilterGroups.ShippedGroup,
        }),
      );
    });
  }, [orderIds]);

  return {
    submit,
  };
};
