import { OrderLabelStatus } from '@/Enums/OrderLabelStatus';
import { RootState } from '@/Redux/RootReducer';
import { OrderLabelPrintRow } from '@/Types';
import { createSelector } from '@reduxjs/toolkit';

const orderLabelPrintModalData = (state: RootState) => state.modalWindows.orderLabelPrintModalState;

const isMatchStatus = (row: OrderLabelPrintRow, status: string) => {
  if (status == OrderLabelStatus.All) {
    return true;
  }

  if (status == OrderLabelStatus.New) {
    return !row.isCreated || !row.isPrinted;
  }

  if (status == OrderLabelStatus.NotPrinted) {
    return row.isCreated && !row.isPrinted;
  }

  if (status == OrderLabelStatus.Printed) {
    return row.isCreated && row.isPrinted;
  }

  return true;
};

const filterLogistics = createSelector(orderLabelPrintModalData, (state) => state.filterLogistics || []);

const filterStatus = createSelector(orderLabelPrintModalData, (state) => state.filterStatus);

const orderLabelPrintRows = createSelector(orderLabelPrintModalData, (state) => {
  let result = state.orderLabelPrintRows || [];

  if (state.filterLogistics.length > 0) {
    result = result.filter((x) => state.filterLogistics.findIndex((y) => y == x.userLogisticTypeId) > -1);
  }

  if (state.filterStatus.length > 0) {
    result = result.filter((x) => isMatchStatus(x, state.filterStatus));
  }

  return result;
});

const isAllSelectedCreated = createSelector(orderLabelPrintModalData, (state) => {
  return state.selectedOrderLabelPrintRows.every((x) => x.isCreated);
});

const isAllLabelsIsPickup = createSelector(orderLabelPrintModalData, (state) => state.isAllLabelsIsPickup);

const selectedOrderLabelPrintRows = createSelector(
  orderLabelPrintModalData,
  (state) => state.selectedOrderLabelPrintRows,
);

const selectedRowKeys = createSelector(orderLabelPrintModalData, (state) =>
  state.selectedOrderLabelPrintRows.map((x) => x.orderId),
);

export const orderLabelPrintModalSelectors = {
  orderLabelPrintRows,
  selectedOrderLabelPrintRows,
  filterLogistics,
  filterStatus,
  isAllSelectedCreated,
  selectedRowKeys,
  isAllLabelsIsPickup,
};
