import { rest } from '@/Api/Rest';
import { ApiRoutingUtils } from '@/Routing/ApiRouting';
import { SetAccountChartSettingRequest } from './SetAccountChartSettingRequest';
import { SetAccountChartSettingResponse } from './SetAccountChartSettingResponse';

const url = ApiRoutingUtils.createOnlineArchiveUrl('account-chart-settings');

const setAccountChartSetting = (request: SetAccountChartSettingRequest): Promise<SetAccountChartSettingResponse> => {
  return rest.post(url, request);
};

export const AccountChartSettingsApi = {
  setAccountChartSetting,
};
