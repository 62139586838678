import { Images } from '@/Static';
import { Pair } from '@/Types';

export enum MarketplaceType {
  Market = 'Market',
  Manual = 'Manual',
  Ebay = 'Ebay',
  Amazon = 'Amazon',
  Kaufland = 'Kaufland',
  Ozon = 'Ozon',
}

export const MARKETPLACE_OPTIONS: Pair<MarketplaceType>[] = [
  {
    value: 'Market',
    key: MarketplaceType.Market,
  },
  {
    value: 'Manual',
    key: MarketplaceType.Manual,
  },
  {
    value: 'Ebay',
    key: MarketplaceType.Ebay,
  },
  {
    value: 'Amazon',
    key: MarketplaceType.Amazon,
  },
  {
    value: 'Kaufland',
    key: MarketplaceType.Kaufland,
  },
  {
    value: 'Ozon',
    key: MarketplaceType.Ozon,
  },
];

export const MARKETPLACE_IMAGE_OPTIONS: Pair<MarketplaceType>[] = [
  {
    value: '',
    key: MarketplaceType.Market,
  },
  {
    value: '',
    key: MarketplaceType.Manual,
  },
  {
    value: Images.Ebay,
    key: MarketplaceType.Ebay,
  },
  {
    value: Images.Amazon,
    key: MarketplaceType.Amazon,
  },
  {
    value: Images.Kaufland,
    key: MarketplaceType.Kaufland,
  },
  {
    value: Images.Ozon,
    key: MarketplaceType.Ozon,
  },
];

const isMarketplaceWithoutApiCallback = (marketplaceType: MarketplaceType) => {
  const withoutCallback = [MarketplaceType.Ozon, MarketplaceType.Kaufland];

  return withoutCallback.includes(marketplaceType);
};

export const marketplaceTypeUtils = {
  isMarketplaceWithoutApiCallback,
};
