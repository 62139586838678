import React from 'react';
import { ColumnInfo, ColumnType } from '@/Types';
import { FormInput } from '@/Components/Controls/Input/FormInput';
import { DocumentForPrintModel } from '@/Models/DocumentForPrintModel';
import { useLocalization } from '@/Hooks';

export function useDocumentPrintTableColumnInfo(
  onCountCopyChanged: (row: DocumentForPrintModel, value: number) => void,
) {
  const {
    modalWindows: {
      documentPrintModal: {
        SelectDocumentModal: { DocumentNumber, DocumentDate, CreatedDate, CountCopy },
      },
    },
  } = useLocalization();

  const columnInfos: ColumnInfo<DocumentForPrintModel>[] = [
    {
      columnType: ColumnType.String,
      fieldName: 'countCopy',
      headerText: CountCopy,
      alignment: 'center',
      width: '80px',
      render: (rowData: DocumentForPrintModel) => (
        <FormInput
          name={'countCopy'}
          valueForSet={rowData.countCopy}
          type={'number'}
          min={1}
          max={10}
          onChange={(val) => {
            onCountCopyChanged(rowData, Number(val));
          }}
        />
      ),
    },
    {
      columnType: ColumnType.String,
      fieldName: 'documentNumber',
      alignment: 'left',
      headerText: DocumentNumber,
    },
    {
      columnType: ColumnType.Date,
      fieldName: 'fileDateCreated',
      alignment: 'left',
      headerText: CreatedDate,
    },
    {
      columnType: ColumnType.Date,
      fieldName: 'documentDate',
      alignment: 'left',
      headerText: DocumentDate,
    },
  ];

  return columnInfos;
}
