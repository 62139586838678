import { useDispatch, useSelector } from 'react-redux';
import { splitOrdersModalSelectors } from '@/ModalWindows/SplitOrdersModal/services/selectors';
import { sharedMapping } from '@/Mapping/shared';
import { useCallback, useEffect, useMemo } from 'react';
import { OrderItemModel } from '@/Models';
import { DraggableBetweenTablesData } from '@/Components/DataTable/types';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { splitOrdersModalActions } from '@/ModalWindows/SplitOrdersModal/services/reducer';
import { OrderTableRow } from '@/Types';
import { SplitOrderRequest } from '@/Api/MpOrders/Requests/SplitOrderRequest';
import { splitOrdersModalAsyncActions } from '@/ModalWindows/SplitOrdersModal/services/asyncActions';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useModalWindows } from '@/ModalWindows/hooks/useModalWindows';

type UseSplitOrderModalReturnType = {
  initialDraggableOrderItems: (OrderItemModel & DraggableBetweenTablesData)[];
  newOrders: number[];
  splitOrdersDisabled: boolean;
  orderForSplitting: OrderTableRow | null;
  addNewOrder: () => void;
  removeOrder: (orderNumber: number) => void;
  splitOrder: (data: { key: string; items: OrderItemModel[] }[]) => void;
  isAddOrderButtonDisabled: boolean;
};

export function useSplitOrdersModal(): UseSplitOrderModalReturnType {
  const dispatch: AppDispatch = useDispatch();
  const { closeModalWindow } = useModalWindows();

  const orderForSplitting = useSelector(splitOrdersModalSelectors.order);
  const newOrders = useSelector(splitOrdersModalSelectors.newOrders);

  const selectedOrders = useSelector(salesPageSelectors.selectedSales);
  const splitOrdersDisabled = useMemo(() => {
    return selectedOrders.length !== 1;
  }, [selectedOrders]);

  useEffect(() => {
    if (selectedOrders.length === 1) {
      dispatch(splitOrdersModalActions.setOrderForSplitting(selectedOrders[0]));
    }
  }, []);

  const initialDraggableOrderItems = useMemo(() => {
    return sharedMapping.toDraggableData(orderForSplitting?.orderItems ?? [], '1');
  }, [orderForSplitting]);

  const addNewOrder = useCallback(() => {
    dispatch(splitOrdersModalActions.addNewOrder());
  }, [dispatch]);

  const removeOrder = useCallback(
    (orderNumber: number) => {
      dispatch(splitOrdersModalActions.removeOrder(orderNumber));
    },
    [dispatch],
  );

  const splitOrder = useCallback(
    (data: { key: string; items: OrderItemModel[] }[]) => {
      if (!orderForSplitting?.id) {
        return;
      }
      const splitOrderRequest: SplitOrderRequest = {
        newOrders: data.map((_) => ({ orderIndex: +_.key, orderItemIds: _.items.map((i) => i.id) })),
      };

      dispatch(
        splitOrdersModalAsyncActions.splitOrder({ orderId: orderForSplitting?.id, request: splitOrderRequest }),
      ).then(() => {
        closeModalWindow();
      });
    },
    [dispatch, orderForSplitting],
  );

  const isAddOrderButtonDisabled = newOrders.length >= (orderForSplitting?.orderItems.length ?? 0);

  return {
    initialDraggableOrderItems,
    newOrders,
    splitOrdersDisabled,
    orderForSplitting,
    addNewOrder,
    removeOrder,
    splitOrder,
    isAddOrderButtonDisabled,
  };
}
