import _ from 'underscore';

type ValueOf<T> = T[keyof T];

function groupBy<T extends Record<string, any>>(elements: T[], key: keyof T): Record<ValueOf<T>, T[]> {
  const result: Record<ValueOf<T>, T[]> = {} as Record<ValueOf<T>, T[]>;

  elements.forEach((el) => {
    const keyValue: ValueOf<T> = el[key];
    if (!result[keyValue]) {
      result[keyValue] = [];
    }

    result[keyValue].push(el);
  });

  return result;
}

const range = (start: number, end: number, step = 1): number[] => {
  return _.range(start, end, step);
};

function distinct<T extends Record<string, any>>(array: T[], key: keyof T) {
  return _.uniq(array, (x) => x[key]);
}

const sortBy = <T>(array: T[], fieldName: string, reverse?: boolean | undefined): T[] => {
  let result = _.sortBy(array, fieldName);

  if (reverse) result = result.reverse();

  return result;
};

export const arrayUtils = {
  groupBy,
  range,
  distinct,
  sortBy,
};
