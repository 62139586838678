import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';
import { ProductDynamicColumnModel } from '@/Models';

export type DynamicColumnsState = {
  columnsModalIsOpen: boolean;
  addColumnsToProductsModalIsOpen: boolean;
  columns: ProductDynamicColumnModel[];
};

const initialState: DynamicColumnsState = {
  columnsModalIsOpen: false,
  columns: [],
  addColumnsToProductsModalIsOpen: false,
};

const dynamicColumnsSlice = createSlice({
  name: 'dynamic-columns',
  initialState,
  reducers: {
    setVisibleDynamicColumnsModal(state, action: PayloadAction<boolean>) {
      state.columnsModalIsOpen = action.payload;
    },
    setVisibleAddColumnsModal(state, action: PayloadAction<boolean>) {
      state.addColumnsToProductsModalIsOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Dynamic columns
      .addCase(asyncActions.getDynamicColumns.fulfilled, (state, action) => {
        state.columns = action.payload.productDynamicColumnModels.map((item) => ({
          ...item,
          columnName: `Custom.${item.columnName}`,
        }));
      })
      .addCase(asyncActions.createDynamicColumn.fulfilled, (state, action) => {
        const column = action.payload.productDynamicColumn;
        state.columns.push({ ...column, columnName: `Custom.${column.columnName}` });
      })
      .addCase(asyncActions.updateDynamicColumn.fulfilled, (state, action) => {
        const column = action.payload.productDynamicColumn;
        state.columns = state.columns.map((item) => {
          if (item.id == column.id) return { ...column, columnName: `Custom.${column.columnName}` };
          return item;
        });
      })
      .addCase(asyncActions.deleteDynamicColumn.fulfilled, (state, action) => {
        state.columns = state.columns.filter((item) => item.id != action.payload.id);
      });
  },
});

const { actions, reducer } = dynamicColumnsSlice;

export const dynamicColumnsActions = actions;
export const dynamicColumnsReducer = reducer;
export const dynamicColumnsAsyncActions = asyncActions;
