import styles from './Icons.scss';

export function getIconClasses(size: 'small' | 'normal' | 'large', disabled = false) {
  return {
    [styles.icon_small]: size === 'small',
    [styles.icon_normal]: size === 'normal',
    [styles.icon_big]: size === 'large',
    [styles.icon_disabled]: disabled,
  };
}
