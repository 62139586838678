import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { ChangeOrderDeliveryStatusByMarkerRequest } from '@/Api/MpOrdersMarking/Requests/ChangeOrderDeliveryStatusByMarkerRequest';
import { DateFormatType, OrderStatusFilterGroups } from '@/Enums';
import { OrderMarker } from '@/Enums/OrderMarker';
import { useForm } from '@/Hooks/useFormWrapper';
import { dateTimeUtils } from '@/Utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { salesPageActions } from '@/Pages/Sales/services';

type FormData = ChangeOrderDeliveryStatusByMarkerRequest;

export const useAllNotShippedForm = (orderIds: number[], onClose: () => void) => {
  const dispatch = useDispatch();

  const { register, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      orderMarker: OrderMarker.Paid,
      orderIds,
      shippingDate: dateTimeUtils.toFormattedString(new Date(), DateFormatType.LOCAL_DATE_TIME),
    },
  });

  const submit = useCallback(
    (data: FormData) => {
      onClose();

      MpOrdersMarkingApi.changeDeliveryStatus({
        ...data,
        shippingDate: dateTimeUtils.localStringToUtcString(data?.shippingDate),
        orderMarker: OrderMarker.Shipped,
      }).then(() => {
        // remove sales from table if current filters is notShipped
        dispatch(
          salesPageActions.removeOrdersFromStateByCurrentFilters({
            orderIds: orderIds,
            filters: OrderStatusFilterGroups.NotShippedGroup,
          }),
        );
      });
    },
    [orderIds],
  );

  return {
    submit: handleSubmit(submit),
    register,
    setValue,
  };
};
