import { OrderTableRow } from '@/Types';
import { OrdersMergeDiffResult } from '@/Api/MpOrders/Requests/MergeOrdersRequest';

function isDifferenceExist(data: OrdersMergeDiffResult): boolean {
  return !!(
    data.differentBuyerInfoOptions.length ||
    data.differentInvoiceContactInfoOptions.length ||
    data.differentSecondContactOptions.length ||
    data.differentMpAccountOptions.length ||
    data.differentInvoiceAddressOptions.length ||
    data.differentShippingAddressOptions.length ||
    data.differentShippingContactInfoOptions.length
  );
}

const getNameByOrder = (order: OrderTableRow): string => {
  const result = [
    order.invoiceCompanyName,
    order.invoiceFirstName,
    order.invoiceLastName,
    order.invoiceStreet,
    order.invoiceHouseNo,
    order.invoicePostalCode,
    order.invoiceCityName,
    order.invoiceCountryName,
  ].reduce((acc, cur) => {
    if (cur) {
      acc += `${cur} `;
    }

    return acc;
  }, '');

  console.log(result);
  return result;
};

function getOrderDifference(orders: OrderTableRow[]): OrdersMergeDiffResult {
  if (orders.length <= 1) {
    return {
      differentMpAccountOptions: [],
      differentInvoiceContactInfoOptions: [],
      differentInvoiceAddressOptions: [],
      differentSecondContactOptions: [],
      differentShippingContactInfoOptions: [],
      differentShippingAddressOptions: [],
      differentBuyerInfoOptions: [],
    };
  }

  const [firstOrder, ...otherOrders] = orders;
  let differentMpAccountOptions = otherOrders
    .filter((x) => x.mpAccountId !== firstOrder.mpAccountId)
    .map((p) => ({
      key: p.id,
      value: getNameByOrder(p),
    }));

  if (differentMpAccountOptions.length) {
    differentMpAccountOptions = [
      ...differentMpAccountOptions,
      {
        key: firstOrder.id,
        value: getNameByOrder(firstOrder),
      },
    ];
  }
  let differentBuyerInfoOptions = otherOrders
    .filter(
      (x) =>
        x.buyerEmail !== firstOrder.buyerEmail ||
        x.buyerNote !== firstOrder.buyerNote ||
        x.buyerMarketplaceAccount !== firstOrder.buyerMarketplaceAccount ||
        x.buyerVatNumber !== firstOrder.buyerVatNumber,
    )
    .map((p) => ({
      key: p.id,
      value: getNameByOrder(p),
    }));

  if (differentBuyerInfoOptions.length) {
    differentBuyerInfoOptions = [
      ...differentBuyerInfoOptions,
      {
        key: firstOrder.id,
        value: getNameByOrder(firstOrder),
      },
    ];
  }

  // TODO: Compare Original shipping and validated shipping
  let differentShippingAddressOptions = otherOrders
    .filter(
      (x) =>
        !(
          x.shippingAdditionalLine === firstOrder.shippingAdditionalLine &&
          x.shippingStreet === firstOrder.shippingStreet &&
          x.shippingHouseNo === firstOrder.shippingHouseNo &&
          x.shippingPostalCode === firstOrder.shippingPostalCode &&
          x.shippingCityName === firstOrder.shippingCityName &&
          x.shippingCountryCode === firstOrder.shippingCountryCode &&
          x.shippingCountryName === firstOrder.shippingCountryName
        ),
    )
    .map((p) => ({
      key: p.id,
      value: getNameByOrder(p),
    }));

  if (differentShippingAddressOptions.length) {
    differentShippingAddressOptions = [
      ...differentShippingAddressOptions,
      {
        key: firstOrder.id,
        value: getNameByOrder(firstOrder),
      },
    ];
  }

  let differentShippingContactInfoOptions = otherOrders
    .filter(
      (x) =>
        !(
          x.shippingCompanyName === firstOrder.shippingCompanyName &&
          x.shippingFirstName === firstOrder.shippingFirstName &&
          x.shippingLastName === firstOrder.shippingLastName &&
          x.shippingPhoneNumber === firstOrder.shippingPhoneNumber
        ),
    )
    .map((p) => ({
      key: p.id,
      value: getNameByOrder(p),
    }));

  if (differentShippingContactInfoOptions.length) {
    differentShippingContactInfoOptions = [
      ...differentShippingContactInfoOptions,
      {
        key: firstOrder.id,
        value: getNameByOrder(firstOrder),
      },
    ];
  }

  let differentSecondContactOptions = otherOrders
    .filter(
      (x) =>
        !(
          x.secondContactPersonEmail === firstOrder.secondContactPersonEmail &&
          x.secondContactPersonName === firstOrder.secondContactPersonName &&
          x.secondContactPersonNotes === firstOrder.secondContactPersonNotes &&
          x.secondContactPersonPhones === firstOrder.secondContactPersonPhones &&
          x.secondContactPersonTitle === firstOrder.secondContactPersonTitle
        ),
    )
    .map((p) => ({
      key: p.id,
      value: getNameByOrder(p),
    }));

  if (differentSecondContactOptions.length) {
    differentSecondContactOptions = [
      ...differentSecondContactOptions,
      {
        key: firstOrder.id,
        value: getNameByOrder(firstOrder),
      },
    ];
  }
  let differentInvoiceAddressOptions = otherOrders
    .filter(
      (x) =>
        !(
          x.invoiceAdditionalLine === firstOrder.invoiceAdditionalLine &&
          x.invoiceStreet === firstOrder.invoiceStreet &&
          x.invoiceHouseNo === firstOrder.invoiceHouseNo &&
          x.invoicePostalCode === firstOrder.invoicePostalCode &&
          x.invoiceCityName === firstOrder.invoiceCityName &&
          x.invoiceCountryCode === firstOrder.invoiceCountryCode &&
          x.invoiceCountryName === firstOrder.invoiceCountryName
        ),
    )
    .map((p) => ({
      key: p.id,
      value: getNameByOrder(p),
    }));

  if (differentInvoiceAddressOptions.length) {
    differentInvoiceAddressOptions = [
      ...differentInvoiceAddressOptions,
      {
        key: firstOrder.id,
        value: getNameByOrder(firstOrder),
      },
    ];
  }

  let differentInvoiceContactInfoOptions = otherOrders
    .filter(
      (x) =>
        !(
          x.invoiceCompanyName === firstOrder.invoiceCompanyName &&
          x.invoiceFirstName === firstOrder.invoiceFirstName &&
          x.invoiceLastName === firstOrder.invoiceLastName &&
          x.invoicePhoneNumber === firstOrder.invoicePhoneNumber
        ),
    )
    .map((p) => ({
      key: p.id,
      value: getNameByOrder(p),
    }));

  if (differentInvoiceContactInfoOptions.length) {
    differentInvoiceContactInfoOptions = [
      ...differentInvoiceContactInfoOptions,
      {
        key: firstOrder.id,
        value: getNameByOrder(firstOrder),
      },
    ];
  }

  return {
    differentInvoiceAddressOptions,
    differentShippingAddressOptions,
    differentMpAccountOptions,
    differentShippingContactInfoOptions,
    differentSecondContactOptions,
    differentInvoiceContactInfoOptions,
    differentBuyerInfoOptions,
  };
}

export const mergeOrdersUtils = {
  getOrderDifference,
  isDifferenceExist,
};
