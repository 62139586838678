import { DataGrid } from 'devextreme-react/data-grid';
import { useCallback } from 'react';
import { getSelectedDataByKey } from '../services/utils';

export const useSelectionChanged = <TData>(
  rowKey: '' | keyof TData,
  innerGridRef: React.MutableRefObject<DataGrid<TData, keyof TData> | undefined>,
  onSelectedRowsChanged?: (data: TData[]) => void,
  onFocusedRowChanged?: (data: TData | null, rows: TData[] | null) => void,
) => {
  const selectionChanged = useCallback(
    (ev) => {
      if (onSelectedRowsChanged || onFocusedRowChanged) {
        const selectedRowsData = getSelectedDataByKey(innerGridRef?.current, rowKey);
        onSelectedRowsChanged?.(selectedRowsData);

        const lastSelectedElement =
          selectedRowsData.find((data) => data[rowKey as keyof typeof data] === ev.currentSelectedRowKeys?.[0]) ??
          selectedRowsData?.[selectedRowsData.length - 1] ??
          null;
        onFocusedRowChanged?.(lastSelectedElement, selectedRowsData);
      } // if

      if (ev.selectedRowsData?.length === 0) {
        innerGridRef?.current?.instance.selectRowsByIndexes([0]);
      } // if
    },
    [onSelectedRowsChanged, onFocusedRowChanged],
  );

  return {
    selectionChanged,
  };
};
