const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'LOCAL_STORAGE_ACCESS_TOKEN_KEY';

export class TokenUtils {
  public static getAccessToken(): string | null {
    return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  }

  public static setAccessToken(token: string): void {
    return localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, token);
  }
}
