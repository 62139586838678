import { createAsyncThunk } from '@reduxjs/toolkit';
import { BillingProductCartsApi } from '@/Api/BillingProductCarts/BillingProductCartsApi';
import { GetBillingProductCartsResponse } from '@/Api/BillingProductCarts/Responses/GetBillingProductCartsResponse';

const getBillingProductCarts = createAsyncThunk<GetBillingProductCartsResponse>(
  'adminBillingPage/get-product-carts',
  async () => {
    return BillingProductCartsApi.getBillingProductCarts();
  },
);

export const billingPageAsyncActions = {
  getBillingProductCarts,
};
