import { OrderMarker } from '@/Enums/OrderMarker';
import { useLocalization } from '@/Hooks/useLocalization';
import { useMemo } from 'react';

export const usePaidAndNotPaidFormOptions = () => {
  const {
    enums: { OrderMarker: OrderMarkerLocalization },
  } = useLocalization();

  const markerOptions = useMemo(
    () => [
      { key: OrderMarker.Paid, value: OrderMarkerLocalization.Paid },
      { key: OrderMarker.NotPaid, value: OrderMarkerLocalization.NotPaid },
    ],
    [OrderMarkerLocalization],
  );

  return {
    markerOptions,
  };
};
