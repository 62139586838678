import { useSelector } from 'react-redux';
import { globalSelectors } from '@/Redux/Global/selectors';
import { useEffect, useState } from 'react';

export function useRerenderOnChangeLocale() {
  const _locale = useSelector(globalSelectors.locale);
  const [shouldRerender, setShouldRerender] = useState(false);
  useEffect(() => {
    setShouldRerender(true);
  }, [_locale]);

  const resetShouldRerender = () => setTimeout(() => setShouldRerender(false), 400);

  return {
    shouldRerender,
    resetShouldRerender,
  };
}
