import React, { useMemo } from 'react';
import { withModules } from '@/Hocs';
import { DataTable } from '@/Components/DataTable/DataTable';
import { InvoiceTableRow } from '@/Types';
import { useInvoicesColumnInfo } from './ColumnInfo';
import styles from './ProfileBillingPage.scss';

const ProfileBillingPageComponent = (): React.ReactElement => {
  const columnInfos = useInvoicesColumnInfo();

  return useMemo(() => {
    return (
      <section className={styles.billingPage}>
        <DataTable<InvoiceTableRow>
          rowKey={'number'}
          rows={[]}
          columnInfos={columnInfos}
          className={styles.table}
          filterOptions={{
            needShowFiltersButton: false,
          }}
          selectOptions={{
            onFocusedRowChanged: () => null,
            // TODO: Restriction by user roles
            //onRowDblClick={() => null}
          }}
        />
      </section>
    );
  }, [columnInfos]);
};

export const ProfileBillingPage = withModules(ProfileBillingPageComponent);
