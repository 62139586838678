import { Modal } from '@/Components/Modal/Modal';
import { useLocalization } from '@/Hooks';
import { ShippingStatusModel } from '@/Models/ShippingStatusModel';
import { ClassNameProps, ModalProps } from '@/Types';
import { dateTimeUtils } from '@/Utils';
import React from 'react';
import styles from './DeliveryProgressTimeModal.scss';

type Props = {
  deliveryProgressHistory: ShippingStatusModel[];
} & ModalProps &
  ClassNameProps;

export function DeliveryProgressTimeModal({ closeModalWindow, deliveryProgressHistory }: Props) {
  const { modalWindows } = useLocalization();

  return (
    <Modal title={modalWindows.deliveryProgressTimeModal.title} className={styles.modal} onClose={closeModalWindow}>
      <p className={styles.text}>{deliveryProgressHistory[0].packageName}</p>

      <dl className={styles.summary}>
        {deliveryProgressHistory.map((x) => {
          return (
            <div key={x.orderId} className={styles.dContents}>
              <dt>{dateTimeUtils.utcStringToLocalString(x.createdTime)}</dt>
              <dd>{x.status}</dd>
            </div>
          );
        })}
      </dl>
    </Modal>
  );
}
