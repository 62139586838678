import { createSlice } from '@reduxjs/toolkit';
import { DocumentCategoryModel } from '@/Models/_Microservices/OnlineArchive/DocumentCategoryModel';
import { asyncActions } from './asyncActions';

export type DocumentCategoriesDataState = {
  documentCategories: DocumentCategoryModel[];
};

const initialState: DocumentCategoriesDataState = {
  documentCategories: [],
};

const documentCategoriesDataSlice = createSlice({
  name: 'documentCategoriesData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getDocumentCategories.fulfilled, (state, action) => {
        state.documentCategories = [...action.payload]?.sort((a, b) => a.name.localeCompare(b.name));
      })
      .addCase(asyncActions.createDocumentCategory.fulfilled, (state, action) => {
        state.documentCategories = [action.payload.documentCategory, ...state.documentCategories]?.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
      })
      .addCase(asyncActions.updateDocumentCategory.fulfilled, (state, action) => {
        state.documentCategories = state.documentCategories
          .map((item) => (item.id === action.payload.documentCategory.id ? action.payload.documentCategory : item))
          ?.sort((a, b) => a.name.localeCompare(b.name));
      })
      .addCase(asyncActions.deleteDocumentCategory.fulfilled, (state, action) => {
        state.documentCategories = state.documentCategories.filter((item) => item.id !== action.payload);
      });
  },
});

export const documentCategoriesDataReducer = documentCategoriesDataSlice.reducer;
export const documentCategoriesDataActions = documentCategoriesDataSlice.actions;
export const documentCategoriesDataAsyncActions = asyncActions;
