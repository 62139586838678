import { ChangeUserPasswordRequest } from '@/Api/UserAccount/ChangeUserPasswordRequest';
import { ChangeUserPasswordResponse } from '@/Api/UserAccount/ChangeUserPasswordResponse';
import { GetUserAccountInfoResponse } from '@/Api/UserAccount/GetUserAccountInfoResponse';
import { UpdateUserAccountFieldRequest } from '@/Api/UserAccount/UpdateUserAccountFieldRequest';
import { UpdateUserAccountFieldResponse } from '@/Api/UserAccount/UpdateUserAccountFieldResponse';
import { UserAccountApi } from '@/Api/UserAccount/UserAccountApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getUserAccountInfo = createAsyncThunk<GetUserAccountInfoResponse, void>('userAccountInfo/get', async () => {
  return UserAccountApi.getUserAccountInfo();
});

const changePassword = createAsyncThunk<ChangeUserPasswordResponse, ChangeUserPasswordRequest>(
  'userAccountInfo/update-password',
  async (request: ChangeUserPasswordRequest) => {
    return UserAccountApi.changeUserPassword(request);
  },
);

const updateField = createAsyncThunk<UpdateUserAccountFieldResponse, UpdateUserAccountFieldRequest>(
  'userAccountInfo/update-field',
  async (request: UpdateUserAccountFieldRequest) => {
    return UserAccountApi.updateUserAccountInfoField(request);
  },
);

export const userAccountSettingsAsyncActions = {
  getUserAccountInfo,
  changePassword,
  updateField,
};
