import { ApiRoutingUtils } from 'src/Routing';
import { rest } from 'src/Api/Rest';
import { CreateWarehousePostingBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CreateWarehousePostingBatchRequest';
import { CreateWarehousePostingBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CreateWarehousePostingBatchResponse';
import { SetUserWarehouseBatchStateRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/SetUserWarehouseBatchStateRequest';
import { SetUserWarehouseBatchStateResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/SetUserWarehouseBatchStateResponse';
import { CloseUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CloseUserWarehouseBatchResponse';
import { CloseUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CloseUserWarehouseBatchRequest';
import { GetAvailableOutWarehousesForTransferResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/GetAvailableOutWarehousesForTransferResponse';
import { GetAvailableOutWarehousesForTransferRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/GetAvailableOutWarehousesForTransferRequest';
import { RemoveUserWarehousePostingBatchItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/RemoveUserWarehousePostingBatchItemRequest';
import { RemoveUserWarehousePostingBatchItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/RemoveUserWarehousePostingBatchItemResponse';
import { CancelUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CancelUserWarehouseBatchResponse';
import { CancelUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CancelUserWarehouseBatchRequest';

const transferBatchUrl = ApiRoutingUtils.createUrl('user-warehouse-transfer-batch');

async function createBatch(request: CreateWarehousePostingBatchRequest): Promise<CreateWarehousePostingBatchResponse> {
  return rest.post(transferBatchUrl, request);
}

async function setBatchState(request: SetUserWarehouseBatchStateRequest): Promise<SetUserWarehouseBatchStateResponse> {
  return rest.put(transferBatchUrl, request);
}

async function closeBatch(request: CloseUserWarehouseBatchRequest): Promise<CloseUserWarehouseBatchResponse> {
  return rest.patch(transferBatchUrl, request);
}

async function cancelBatch(request: CancelUserWarehouseBatchRequest): Promise<CancelUserWarehouseBatchResponse> {
  return rest.delete(transferBatchUrl, request);
}

async function removeBatchItem(
  request: RemoveUserWarehousePostingBatchItemRequest,
): Promise<RemoveUserWarehousePostingBatchItemResponse> {
  return rest.delete(`${transferBatchUrl}/batch-item`, request);
}

async function getAvailableOutWarehouses(
  request: GetAvailableOutWarehousesForTransferRequest,
): Promise<GetAvailableOutWarehousesForTransferResponse> {
  return rest.get(`${transferBatchUrl}/available-out-warehouses`, request);
}

export const UserWarehouseTransferBatchApi = {
  createBatch,
  setBatchState,
  closeBatch,
  cancelBatch,
  removeBatchItem,
  getAvailableOutWarehouses,
};
