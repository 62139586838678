import { ApiRoutingUtils } from '@/Routing';
import {
  GetSubscriptionsResponse,
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  UpdateSubscriptionRequest,
  UpdateSubscriptionResponse,
} from './';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('subscriptions');

async function getSubscriptions(): Promise<GetSubscriptionsResponse> {
  return rest.get(url);
}

async function createSubscription(request: CreateSubscriptionRequest): Promise<CreateSubscriptionResponse> {
  return rest.post(url, request);
}

async function updateSubscription(request: UpdateSubscriptionRequest): Promise<UpdateSubscriptionResponse> {
  return rest.put(url, request);
}

async function deleteSubscription(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, {});
}

export const SubscriptionsApi = {
  getSubscriptions,
  createSubscription,
  updateSubscription,
  deleteSubscription,
};
