import { createSlice } from '@reduxjs/toolkit';
import { UploadTemplateModel } from '@/Models/_Microservices/OnlineArchive/UploadTemplateModel';
import { asyncActions } from './asyncActions';

export type UploadTemplatesDataState = {
  uploadTemplates: UploadTemplateModel[];
};

const initialState: UploadTemplatesDataState = {
  uploadTemplates: [],
};

const uploadTemplatesDataSlice = createSlice({
  name: 'uploadTemplatesData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getUploadTemplates.fulfilled, (state, action) => {
        state.uploadTemplates = [...action.payload].sort((a, b) => a.templateName.localeCompare(b.templateName));
      })
      .addCase(asyncActions.createUploadTemplate.fulfilled, (state, action) => {
        state.uploadTemplates.push(action.payload.uploadTemplate);

        if (action.payload.uploadTemplate.isForAutoSave) {
          state.uploadTemplates = state.uploadTemplates
            .map((item) => {
              if (
                item.id !== action.payload.uploadTemplate.id &&
                item.isForAutoSave &&
                item.documentTemplateGuid === action.payload.uploadTemplate.documentTemplateGuid &&
                item.marketplaceAccountIds.filter((i) =>
                  action.payload.uploadTemplate.marketplaceAccountIds.includes(i),
                ).length !== 0
              ) {
                return { ...item, isForAutoSave: false };
              } // if

              return item;
            })
            ?.sort((a, b) => a.templateName.localeCompare(b.templateName));
        } // if
      })
      .addCase(asyncActions.updateUploadTemplate.fulfilled, (state, action) => {
        state.uploadTemplates = state.uploadTemplates.map((item) => {
          if (item.id === action.payload.uploadTemplate.id) {
            return action.payload.uploadTemplate;
          } // if

          if (
            action.payload.uploadTemplate.isForAutoSave &&
            item.isForAutoSave &&
            item.documentTemplateGuid === action.payload.uploadTemplate.documentTemplateGuid &&
            item.marketplaceAccountIds.filter((i) => action.payload.uploadTemplate.marketplaceAccountIds.includes(i))
              .length !== 0
          ) {
            return { ...item, isForAutoSave: false };
          } // if

          return item;
        });
      })
      .addCase(asyncActions.deleteUploadTemplate.fulfilled, (state, action) => {
        state.uploadTemplates = state.uploadTemplates.filter((item) => item.id !== action.payload);
      });
  },
});

export const uploadTemplatesDataReducer = uploadTemplatesDataSlice.reducer;
export const uploadTemplatesDataActions = uploadTemplatesDataSlice.actions;
export const uploadTemplatesDataAsyncActions = asyncActions;
