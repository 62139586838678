import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

type Props = {
  token: string | null;
  onChange: (token: string | null) => void;
};

export function Captcha({ token, onChange }: Props): React.ReactElement {
  const reCaptchaRef = React.createRef<ReCAPTCHA>();

  useEffect(() => {
    if (!token) reCaptchaRef.current?.reset();
  }, [token]);

  return <ReCAPTCHA sitekey={GOOGLE_RE_CAPTCHA_SITE_KEY} ref={reCaptchaRef} onChange={onChange} />;
}
