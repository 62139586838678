import { GetProductsByBarcodeResponse } from '@/Api/Products/Transfer/Responses/GetProductsByBarcodeResponse';
import { rest } from '@/Api/Rest';
import { LoadReportForProductsRequest } from '@/Api/Products/Transfer/Requests/LoadReportForProductsRequest';
import { BinaryResponse } from '@/Api';
import { GetProductTransferErrorsResponse } from '@/Api/Products/Transfer/Responses/GetProductTransferErrorsResponse';
import { GetProductReportsResponse } from '@/Api/Products/Transfer/Responses/GetProductReportsResponse';
import { GetNextReportNumberResponse } from '@/Api/Products/Transfer/Responses/GetNextReportNumberResponse';
import { ApiRoutingUtils } from '@/Routing';

const url = ApiRoutingUtils.createUrl('products');
const errorUrl = ApiRoutingUtils.createUrl('errors');
const reportUrl = ApiRoutingUtils.createUrl('product-reports');

async function getProductsByBarcode(request: { ean: string; quantity: number }): Promise<GetProductsByBarcodeResponse> {
  return rest.get(`${url}/get-products-by-barcode/${request.ean}/${request.quantity}`);
}

async function loadReportForProducts(request: LoadReportForProductsRequest): Promise<BinaryResponse> {
  return rest.postBinary(`${url}/load-report-for-products`, request);
}

async function getProductTransferErrors(): Promise<GetProductTransferErrorsResponse> {
  return rest.get(errorUrl);
}

async function clearProductTransferErrors(): Promise<null> {
  return rest.delete(errorUrl, {});
}

async function getProductReports(): Promise<GetProductReportsResponse> {
  return rest.get(reportUrl);
}

async function getProductReport(request: number): Promise<BinaryResponse> {
  return rest.getBinary(`${reportUrl}/load-report/${request}`);
}

async function getNextReportNumber(): Promise<GetNextReportNumberResponse> {
  return rest.get(`${reportUrl}/get-next-report-number`);
}

async function deleteProductReports(request: number[]): Promise<number[]> {
  return rest.delete(reportUrl, request);
}

export const TransferApi = {
  getProductsByBarcode,
  loadReportForProducts,
  getProductTransferErrors,
  clearProductTransferErrors,
  getProductReports,
  getProductReport,
  getNextReportNumber,
  deleteProductReports,
};
