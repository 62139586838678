import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/Redux/RootReducer';

const modalWindows = (state: RootState) => state.modalWindows;

const shared = (state: RootState) => state.modalWindows.shared;
const modalType = createSelector(shared, (state) => state.modalType);
const modalParams = createSelector(shared, (state) => state.params);

export const modalWindowsSelectors = {
  modalWindows,
  modalType,
  modalParams,
};
