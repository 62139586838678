import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import {
  GetDeutschePostInternetmarkeContractResponse,
  CreateDeutschePostInternetmarkeContractCommand,
  CreateDeutschePostInternetmarkeContractResponse,
  UpdateDeutschePostInternetmarkeContractCommand,
  UpdateDeutschePostInternetmarkeContractResponse,
  GetDeutschePostInternetmarkeContractStatusQuery,
  GetDeutschePostInternetmarkeContractStatusResponse,
  GetDeutschePostInternetmarkeFormatsResponse,
} from '.';

const url = ApiRoutingUtils.createUrl('deutsche-post-internetmarke-contracts');

async function getContract(): Promise<GetDeutschePostInternetmarkeContractResponse> {
  return rest.get(`${url}`);
}

async function createContract(
  request: CreateDeutschePostInternetmarkeContractCommand,
): Promise<CreateDeutschePostInternetmarkeContractResponse> {
  return rest.post(url, request);
}

async function updateContract(
  request: UpdateDeutschePostInternetmarkeContractCommand,
): Promise<UpdateDeutschePostInternetmarkeContractResponse> {
  return rest.put(url, request);
}

async function deleteContract(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

async function checkConnection(
  request: GetDeutschePostInternetmarkeContractStatusQuery,
): Promise<GetDeutschePostInternetmarkeContractStatusResponse> {
  return rest.post(`${url}/check-connection`, request);
}

async function getMarkeFormats(): Promise<GetDeutschePostInternetmarkeFormatsResponse> {
  return rest.get(`${url}/marke-formats`);
}

export const DeutschePostInternetmarkeContractsApi = {
  getContract,
  createContract,
  updateContract,
  deleteContract,
  checkConnection,
  getMarkeFormats,
};
