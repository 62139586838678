export enum LogisticType {
  Dpd = 'Dpd',
  Dhl = 'Dhl',
  Hermes = 'Hermes',
  Gls = 'Gls',
  Ups = 'Ups',
  DeutschePost = 'DeutschePost',
  DhlExpress = 'DhlExpress',
}

export enum LogisticTypeKeys {
  ShipCheaper = -1,
  Dhl = -2,
  Dpd = -3,
  Hermes = -4,
  DeutschePost = -5,
  DhlExpress = -6,
  Gls = -7,
  Ups = -8,
}
