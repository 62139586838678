import { createAsyncThunk } from '@reduxjs/toolkit';

// Api import
import { MpAuthApi, GenerateUserAuthorizationUrlRequest, GenerateUserAuthorizationUrlResponse } from '@/Api/MpAuth';
import {
  MpAccountApi,
  GetMpAccountsResponse,
  CreateMpAccountRequest,
  CreateMpAccountResponse,
  UpdateMpAccountRequest,
  UpdateMpAccountResponse,
  DeleteMpAccountRequest,
  DeleteMpAccountResponse,
} from '@/Api/MpAccount';
import { MpCredentialsApi, UpdateMpCredentialsRequest } from '@/Api/MpCredentials';
import { UpdateMpCredentialsResponse } from '@/Api/MpCredentials/UpdateMpCredentialsResponse';
import { CheckMpConnectionRequest } from '@/Api/MpAuth/CheckMpConnectionRequest';
import { CheckMpConnectionResponse } from '@/Api/MpAuth/CheckMpConnectionResponse';

const getEbayAuthorizeUrl = createAsyncThunk<GenerateUserAuthorizationUrlResponse, GenerateUserAuthorizationUrlRequest>(
  'accountSettings/getEbayAuthorizeUrl',
  async (authorizeRequest: GenerateUserAuthorizationUrlRequest) => {
    return MpAuthApi.getEbayAuthorizeUrl(authorizeRequest);
  },
);

const getMpAccounts = createAsyncThunk<GetMpAccountsResponse>('accountSettings/getMpAccounts', async () => {
  return MpAccountApi.getMpAccounts();
});

const createMpAccount = createAsyncThunk<CreateMpAccountResponse, CreateMpAccountRequest>(
  'accountSettings/createMpAccount',
  async (request: CreateMpAccountRequest) => {
    return MpAccountApi.createMpAccount(request);
  },
);

const updateMpAccount = createAsyncThunk<UpdateMpAccountResponse, UpdateMpAccountRequest>(
  'accountSettings/updateMpAccount',
  async (request: UpdateMpAccountRequest) => {
    return MpAccountApi.updateMpAccount(request);
  },
);

const deleteMpAccount = createAsyncThunk<DeleteMpAccountResponse, DeleteMpAccountRequest>(
  'accountSettings/deleteMpAccount',
  async (request: DeleteMpAccountRequest) => {
    return MpAccountApi.deleteMpAccount(request);
  },
);

const updateMpCredentials = createAsyncThunk<UpdateMpCredentialsResponse, UpdateMpCredentialsRequest>(
  'accountSettings/updateCredentials',
  async (request: UpdateMpCredentialsRequest) => {
    return MpCredentialsApi.updateCredentials(request);
  },
);

const getAmazonAutorizeUrl = createAsyncThunk<
  GenerateUserAuthorizationUrlResponse,
  GenerateUserAuthorizationUrlRequest
>('accountSettings/getAmazonAuthorizeUrl', async (authorizeRequest: GenerateUserAuthorizationUrlRequest) => {
  return MpAuthApi.getAmazonAuthorizeUrl(authorizeRequest);
});

const checkMpConnection = createAsyncThunk<CheckMpConnectionResponse, CheckMpConnectionRequest>(
  'accountSettings/checkConnection',
  async (request) => {
    return MpAuthApi.checkMpConnection(request);
  },
);

export const mpAccountsAsyncThunks = {
  getEbayAuthorizeUrl,
  getMpAccounts,
  createMpAccount,
  updateMpAccount,
  deleteMpAccount,
  updateMpCredentials,
  getAmazonAutorizeUrl,
  checkMpConnection,
};
