import React, { useCallback } from 'react';
import sharedStyles from '../ModalWindows.scss';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { useLocalization, useRouting } from '@/Hooks';
import { PagesRouting } from '@/Routing';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { orderModelExtensions } from '@/Models';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { Modal } from '@/Components/Modal/Modal';
import { ModalProps } from '@/Types';
import { FormDateInput } from '@/Components/Controls/DateInput/FormDateInput';
import { FormProvider } from 'react-hook-form';
import styles from './ChangePaymentStatusModal.scss';
import {
  ChangeOrderPaymentStatusByMarkerRequest,
  ChangeOrderPaymentStatusPartiallyByMarkerRequest,
  ChangeOrderPaymentStatusToPaidForSingleOrderRequest,
} from '@/Api/MpOrdersMarking/Requests';
import { useAllPaidForm } from './hooks/useAllPaidForm';
import { useAllNotPaidForm } from './hooks/useAllNotPaidForm';
import { usePaidAndNotPaidForm } from './hooks/usePaidAndNotPaidForm';
import { usePaidAndNotPaidFormOptions } from './hooks/usePaidAndNotPaidFormOptions';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { Label } from '@/Components/Controls/Label/Label';
import { FormCheckbox } from '@/Components/Controls/Checkbox/FormCheckbox';
import { useSingleNotPaidForm } from './hooks/useSingleNotPaidForm';
import { NumberFormInput } from '@/Components/Controls/Input/NumberFormInput';
import cn from 'classnames';

type Props = ModalProps;

type BodyProps = {
  onClose: () => void;
  orderIds: number[];
  isSalesPage: boolean;
};

export function ChangePaymentStatusModal({ closeModalWindow }: Props) {
  const { currentPage } = useRouting();
  const {
    modalWindows: { changePaymentStatus },
  } = useLocalization();

  const isSalesPage = currentPage === PagesRouting.MainPages.SalesPage.Sales;

  const selectedOrders = useSelector(
    isSalesPage ? salesPageSelectors.selectedSales : purchasesPageSelectors.selectedPurchases,
  );

  const getBody = useCallback(() => {
    const isSingleAndNotPaid = selectedOrders.length === 1 && !orderModelExtensions.isOrderPayed(selectedOrders[0]);
    const isAllPayed = selectedOrders.every((o) => orderModelExtensions.isOrderPayed(o));
    const isAllNotPayed = selectedOrders.every((o) => !orderModelExtensions.isOrderPayed(o));

    const orderIds = selectedOrders.map((o) => o.id);

    if (isSingleAndNotPaid) {
      return (
        <SingleNotPaidBody
          onClose={closeModalWindow}
          orderIds={orderIds}
          isSalesPage={isSalesPage}
          openAmount={selectedOrders[0].openAmount}
        />
      );
    } else if (isAllPayed) {
      return <AllPayedBody onClose={closeModalWindow} orderIds={orderIds} isSalesPage={isSalesPage} />;
    } else if (isAllNotPayed) {
      return <AllNotPayedBody onClose={closeModalWindow} orderIds={orderIds} isSalesPage={isSalesPage} />;
    } else {
      return <PayedAndNotPayedBody onClose={closeModalWindow} orderIds={orderIds} isSalesPage={isSalesPage} />;
    }
  }, [closeModalWindow, isSalesPage]);

  return (
    <Modal className={sharedStyles.modalWrapper} onClose={closeModalWindow} title={changePaymentStatus.Title}>
      {getBody()}
    </Modal>
  );
}

function AllPayedBody({ onClose, orderIds, isSalesPage }: BodyProps) {
  const {
    buttons,
    modalWindows: { changePaymentStatus },
  } = useLocalization();

  const { submit } = useAllPaidForm(orderIds, isSalesPage, onClose);
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: changePaymentStatus.TextForAllPaid }} />
      <div className={sharedStyles.buttonsWrapper}>
        <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={buttons.Cancel} onClick={onClose} />
        <Button autoFocus={true} theme={BUTTON_THEMES.SUCCESS} text={buttons.Change} onClick={submit} />
      </div>
    </>
  );
}

function AllNotPayedBody({ onClose, orderIds, isSalesPage }: BodyProps) {
  type FormData = ChangeOrderPaymentStatusByMarkerRequest;

  const {
    buttons,
    modalWindows: { changePaymentStatus },
  } = useLocalization();

  const {
    methods,
    submit,
    rules: { required },
  } = useAllNotPaidForm(orderIds, isSalesPage, onClose);

  return (
    <FormProvider {...methods}>
      <p dangerouslySetInnerHTML={{ __html: changePaymentStatus.TextForAllNotPaid }} />

      <form className={styles.form} onSubmit={submit}>
        <Label text={changePaymentStatus.AllNotPaidForm.PaymentDate} className={styles.formLabel} />
        <FormDateInput<FormData>
          name={'paymentDate'}
          pickerSetValue={methods.setValue}
          needTimePicker={true}
          register={methods.register}
          rangesType={'defaultDateRanges'}
          className={styles.formControl}
          error={methods.formState.errors}
          rules={required}
        />
        <div className={cn(sharedStyles.buttonsWrapper, sharedStyles.buttonsContainer)}>
          <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={buttons.Cancel} onClick={onClose} />
          <Button
            autoFocus={true}
            type={'submit'}
            theme={BUTTON_THEMES.SUCCESS}
            text={buttons.Change}
            onClick={submit}
          />
        </div>
      </form>
    </FormProvider>
  );
}

function PayedAndNotPayedBody({ onClose, orderIds, isSalesPage }: BodyProps) {
  type FormData = ChangeOrderPaymentStatusPartiallyByMarkerRequest;

  const {
    buttons,
    modalWindows: {
      changePaymentStatus: { TextForPayedAndNotPaid, PaidAndNotPaidForm },
    },
  } = useLocalization();

  const {
    methods,
    needShowDate,
    submit,
    rules: { required, dateRequired },
  } = usePaidAndNotPaidForm(orderIds, isSalesPage, onClose);

  const { markerOptions } = usePaidAndNotPaidFormOptions();
  return (
    <FormProvider {...methods}>
      <p dangerouslySetInnerHTML={{ __html: TextForPayedAndNotPaid }} />
      <form className={styles.form} onSubmit={submit}>
        <Label text={PaidAndNotPaidForm.SelectPaymentStatus} className={styles.formLabel} />
        <FormSelectInput<FormData>
          name={'orderMarker'}
          options={markerOptions}
          register={methods.register}
          needClearButton={false}
          error={methods.formState.errors}
          rules={required}
        />
        {needShowDate && (
          <>
            <Label text={PaidAndNotPaidForm.PaymentDate} className={styles.formLabel} />

            <FormDateInput<FormData>
              name={'paymentDate'}
              pickerSetValue={methods.setValue}
              needTimePicker={true}
              register={methods.register}
              rangesType={'defaultDateRanges'}
              className={styles.formControl}
              error={methods.formState.errors}
              rules={dateRequired}
            />
            <Label text={PaidAndNotPaidForm.OverwriteDates} className={styles.formLabel}></Label>
            <FormCheckbox<FormData>
              register={methods.register}
              setValue={methods.setValue}
              name={'overwritePreviousDates'}
              error={methods.formState.errors}
              className={styles.formControl}
            />
          </>
        )}
        <div className={cn(sharedStyles.buttonsWrapper, sharedStyles.buttonsContainer)}>
          <Button
            autoFocus={true}
            type={'submit'}
            theme={BUTTON_THEMES.SUCCESS}
            text={buttons.Update}
            onClick={submit}
          />
        </div>
      </form>
    </FormProvider>
  );
}

function SingleNotPaidBody({ onClose, orderIds, isSalesPage, openAmount }: BodyProps & { openAmount: number }) {
  type FormData = ChangeOrderPaymentStatusToPaidForSingleOrderRequest;
  const {
    buttons,
    modalWindows: {
      changePaymentStatus: {
        TextForSingleNotPaid,
        SingleNotPaidOrderForm: { Amount, PaymentDate },
      },
    },
  } = useLocalization();

  const {
    methods,
    rules: { required, shouldBeCurrency, shouldBeMoreThan },
    submit,
  } = useSingleNotPaidForm(orderIds[0], isSalesPage, onClose, openAmount);

  return (
    <FormProvider {...methods}>
      <p dangerouslySetInnerHTML={{ __html: TextForSingleNotPaid }} />

      <form className={styles.form} onSubmit={submit}>
        <Label text={PaymentDate} className={sharedStyles.form__label} />
        <FormDateInput<FormData>
          name={'paymentDate'}
          pickerSetValue={methods.setValue}
          needTimePicker={true}
          register={methods.register}
          rangesType={'defaultDateRanges'}
          className={styles.formControl}
          rules={required}
          error={methods.formState.errors}
        />
        <Label text={Amount} className={sharedStyles.form__label} />
        <NumberFormInput<FormData>
          register={methods.register}
          rules={{ ...shouldBeCurrency, ...required, ...shouldBeMoreThan }}
          setValue={methods.setValue}
          error={methods.formState.errors}
          type="currency"
          name={'amount'}
          className={styles.formControl}
        />
        <div className={cn(sharedStyles.buttonsWrapper, sharedStyles.buttonsContainer)}>
          <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={buttons.Cancel} onClick={onClose} />
          <Button
            autoFocus={true}
            type={'submit'}
            theme={BUTTON_THEMES.SUCCESS}
            text={buttons.Change}
            onClick={submit}
          />
        </div>
      </form>
    </FormProvider>
  );
}
