import { useLocalization } from '@/Hooks';
import { stringUtils } from '@/Utils/StringUtils';
import { useMemo } from 'react';

export const useShouldBeLessThan = (value: number) => {
  const { validation } = useLocalization();

  const message = useMemo(() => {
    return stringUtils.insertParamsToString(validation.ValueMustBeLessThan, [value]);
  }, [validation, value]);

  return {
    max: {
      value: value - 1,
      message,
    },
  };
};
