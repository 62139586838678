import { useLocation } from 'react-router';
import { useMemo } from 'react';
import { PagesRouting } from '@/Routing';

export function useAppRouting() {
  const { pathname } = useLocation();

  return useMemo(() => {
    return {
      isSalesPage: pathname === PagesRouting.MainPages.SalesPage.Sales,
      isPurchasesPage: pathname === PagesRouting.MainPages.PurchasesPage,
    } as const;
  }, [pathname]);
}
