import { ErrorDetail } from './ErrorDetail';
import { localizationUtils } from '@/Utils/localizationUtils';
import { RootState } from '@/Redux/RootReducer';
import { Toasts } from '@/Components/Toast/Toast';
import { globalSelectors } from '@/Redux/Global/selectors';

export class ErrorHandler {
  private static instance: ErrorHandler;
  private readonly getState: () => RootState;

  constructor(getState: () => RootState) {
    this.getState = getState;
    ErrorHandler.instance = this;
  }

  static start(getState: () => RootState) {
    ErrorHandler.instance = new ErrorHandler(getState);
  }

  public static getInstance(): ErrorHandler {
    return this.instance;
  }

  // Error handler
  public handle(errors: ErrorDetail[]) {
    const translation = globalSelectors.translation(this.getState());
    const localiation = globalSelectors.localization(this.getState());

    errors.forEach((error) => {
      const errorTranslation = translation.Errors[error.errorCode as keyof typeof translation.Errors] as {
        title: string;
        text: string;
        parts: any;
      };

      const title = errorTranslation?.title ?? error.errorCode;
      const text = errorTranslation?.text ?? error?.errorMessage ?? 'Unknown error';
      const parts = errorTranslation?.parts;

      Toasts.showError({
        title: title,
        text: error.localizationParams
          ? localizationUtils.insertTranslatedParamsToString(text, error.localizationParams ?? [], parts, localiation)
          : text,
      });
    });
  } // handle
}
