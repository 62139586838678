import React, { useEffect } from 'react';
import { DocumentTemplateRow, ModalProps } from '@/Types';
import { useGenerateDocumentNumbersTexts } from './hooks/useGenerateDocumentNumbersTexts';
import { useLocalization } from '@/Hooks';
import sharedStyles from '@/ModalWindows/ModalWindows.scss';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Modal } from '@/Components/Modal/Modal';
import { Label } from '@/Components/Controls/Label/Label';
import { FormCheckbox } from '@/Components/Controls/Checkbox/FormCheckbox';
import { FormProvider } from '@/Hooks/useFormWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { emailSendModalAsyncActions } from '@/ModalWindows/EmailSendModal/services/reducer';
import {
  documentPrintModalActions,
  documentPrintModalAsyncActions,
  SetCountCopy,
  SetIsSortWhenPrinting,
} from '@/ModalWindows/DocumentPrintModal/services/reducer';
import { WINDOW_MODAL_TYPE } from '@/ModalWindows/services';
import { generateDocumentNumberConfirmationModalSelectors } from './services/selectors';
import { useGenerateDocumentSelectTemplate } from './hooks/useGenerateDocumentSelectTemplate';
import { documentPrintModalSelectors } from '@/ModalWindows/DocumentPrintModal/services/selectors';
import { emailSendModalSelectors } from '@/ModalWindows/EmailSendModal/services/selectors';
import { FormInput } from '@/Components/Controls/Input/FormInput';
import { stringUtils } from '@/Utils';
import { FormData } from './hooks/useGenerateDocumentNumbersForm';
import { useGenerateDocumentNumbersForm } from './hooks/useGenerateDocumentNumbersForm';

type Props = ModalProps;

export function GenerateDocumentNumberConfirmationModal({ closeModalWindow }: Props) {
  const dispatch = useDispatch();
  const documentPrintType = WINDOW_MODAL_TYPE[location.pathname];
  const numberGenerationType = useSelector(generateDocumentNumberConfirmationModalSelectors.numberGenerateType);
  const documentTemplates = useSelector(documentPrintModalSelectors.rows);
  const emailTemplates = useSelector(emailSendModalSelectors.rows);

  const {
    buttons: { Yes, No },
    modalWindows: { generateDocumentNumbers },
  } = useLocalization();

  const { selectedPrintTemplate, selectedEmailTemplate } = useGenerateDocumentSelectTemplate(numberGenerationType);
  const { onSubmit, methods } = useGenerateDocumentNumbersForm(numberGenerationType);
  const { confirmationText, modalWindowTitle } = useGenerateDocumentNumbersTexts(numberGenerationType);

  const onCountCopyChanged = (row: DocumentTemplateRow, value: number) => {
    const setCountCopy: SetCountCopy = {
      guid: row.guid,
      count: value,
    };
    dispatch(documentPrintModalActions.setGenerateNumberCountCopy(setCountCopy));
  };

  const onIsSortChanged = (row: DocumentTemplateRow, value: boolean) => {
    const setIsSortWhenPrinting: SetIsSortWhenPrinting = {
      guid: row.guid,
      isSort: value,
    };
    dispatch(documentPrintModalActions.setGenerateNumberSortWhenPrinting(setIsSortWhenPrinting));
  };

  useEffect(() => {
    if (!emailTemplates.length || !documentTemplates.length) {
      dispatch(emailSendModalAsyncActions.getEmailTemplates());
      dispatch(documentPrintModalAsyncActions.getTemplatesRequest(documentPrintType));
    }
  }, []);

  const countCopy = methods.watch('countCopy');

  useEffect(() => {
    if (!countCopy) {
      methods.setValue('countCopy', selectedPrintTemplate?.generateNumberCountCopy);
    }
  }, [selectedPrintTemplate]);

  return (
    <Modal onClose={closeModalWindow} className={sharedStyles.modalWrapper} title={modalWindowTitle}>
      <p dangerouslySetInnerHTML={{ __html: confirmationText }} />
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className={sharedStyles.modalActions}>
            <div className={sharedStyles.printWrapper}>
              <Label
                text={stringUtils.insertParamsToString(generateDocumentNumbers.PrintDocument, [
                  String(selectedPrintTemplate?.name || ''),
                ])}
                reverse
              >
                <FormCheckbox<FormData>
                  name={'printDocument'}
                  register={methods.register}
                  disabled={!selectedPrintTemplate}
                />
              </Label>

              {selectedPrintTemplate && (
                <>
                  <FormInput<FormData>
                    register={methods.register}
                    name={'countCopy'}
                    type={'number'}
                    min={1}
                    max={10}
                    onChange={(val) => onCountCopyChanged(selectedPrintTemplate, Number(val))}
                  />

                  {countCopy != 1 && (
                    <Label text={generateDocumentNumbers.Sorting} reverse>
                      <FormCheckbox<FormData>
                        name={'isSortWhenPrinting'}
                        register={methods.register}
                        valueForSet={selectedPrintTemplate.generateNumberSortWhenPrinting ?? false}
                        onChange={(val) => onIsSortChanged(selectedPrintTemplate, val)}
                      />
                    </Label>
                  )}
                </>
              )}
            </div>
            {!selectedPrintTemplate && (
              <div className={sharedStyles.validationMessage}>{generateDocumentNumbers.TemplateNotExists}</div>
            )}
          </div>

          <div className={sharedStyles.modalActions}>
            <Label
              text={stringUtils.insertParamsToString(generateDocumentNumbers.SendEmail, [
                String(selectedEmailTemplate?.name || ''),
              ])}
              reverse
            >
              <FormCheckbox<FormData>
                name={'sendOrderEmail'}
                register={methods.register}
                disabled={!selectedEmailTemplate}
              />
            </Label>
            {!selectedEmailTemplate && (
              <div className={sharedStyles.validationMessage}>{generateDocumentNumbers.TemplateNotExists}</div>
            )}
          </div>

          <div className={sharedStyles.buttonsWrapper}>
            <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={No} onClick={closeModalWindow} />
            <Button type={'submit'} autoFocus={true} theme={BUTTON_THEMES.SUCCESS} text={Yes} />
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}
