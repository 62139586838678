import { combineReducers } from '@reduxjs/toolkit';
import { productsPageReducer as productsReducer, ProductsState } from './products/reducer';
import { transferPageReducer as transferReducer, TransferState } from '@/Pages/Products/services/transfer';

import {
  externalTransferReducer,
  ExternalTransferState,
} from 'src/Pages/Products/modules/ProductTransfer/modules/TransferSection/modules/modals/ExternalTransferModal/services/reducer';
import {
  incomingTransferReducer,
  IncomingTransferState,
} from 'src/Pages/Products/modules/ProductTransfer/modules/TransferSection/modules/modals/IncomingTransferModal/services/reducer';
import {
  internalTransferReducer,
  InternalTransferState,
} from 'src/Pages/Products/modules/ProductTransfer/modules/TransferSection/modules/modals/InternalTransferModal/services/reducer';
import {
  fulfillmentTransferReducer,
  FulfillmentTransferState,
} from 'src/Pages/Products/modules/ProductTransfer/modules/TransferSection/modules/modals/FulfillmentTransferModal/services/reducer';
import {
  incomingManualTransferReducer,
  IncomingManualTransferState,
} from 'src/Pages/Products/modules/ProductTransfer/modules/TransferSection/modules/modals/IncomingManualTransferModal/services/reducer';
import {
  customerTransferReducer,
  CustomerTransferState,
} from '@/Pages/Products/modules/ProductTransfer/modules/TransferSection/modules/modals/CustomerTransferModal/services/reducer';
import { DynamicColumnsState, dynamicColumnsReducer } from '@/Pages/Products/services/dynamic-columns/reducer';
import {
  uncompletedTransferReducer,
  UncompletedTransferState,
} from '@/Pages/Products/modules/ProductTransfer/modules/TransferSection/modules/modals/UncompletedTransfersModal/services/reducer';

export type ProductsPageState = {
  products: ProductsState;
  transfer: TransferState;
  incomingManualTransfer: IncomingManualTransferState;
  externalTransfer: ExternalTransferState;
  incomingTransfer: IncomingTransferState;
  internalTransfer: InternalTransferState;
  fulfillmentTransfer: FulfillmentTransferState;
  sendingToCustomerTransfer: CustomerTransferState;
  uncompletedTransfer: UncompletedTransferState;
  dynamicColumns: DynamicColumnsState;
};

export const productsPageReducer = combineReducers<ProductsPageState>({
  products: productsReducer,
  transfer: transferReducer,
  incomingManualTransfer: incomingManualTransferReducer,
  externalTransfer: externalTransferReducer,
  incomingTransfer: incomingTransferReducer,
  internalTransfer: internalTransferReducer,
  fulfillmentTransfer: fulfillmentTransferReducer,
  sendingToCustomerTransfer: customerTransferReducer,
  uncompletedTransfer: uncompletedTransferReducer,
  dynamicColumns: dynamicColumnsReducer,
});
