import React from 'react';
import { useSelector } from 'react-redux';
import { modalWindowsSelectors } from '@/ModalWindows/services';
import { ModalType } from '@/Enums';
import { DocumentPrintModal } from '@/ModalWindows/DocumentPrintModal/DocumentPrintModal';
import { ModalProps } from '@/Types';
import { ShippingLabelModal } from '@/ModalWindows/ShippingLabelModal/ShippingLabelModal';
import { ChangePaymentStatusModal } from '@/ModalWindows/ChangePaymentStatusModal/ChangePaymentStatusModal';
import { PagesRouting } from '@/Routing';
import { ChangeOtherStatusesModal } from '@/ModalWindows/ChangeOtherStatusesModal/ChangeOtherStatusesModal';
import { ChangeDeliveryStatusModal } from '@/ModalWindows/ChangeDeliveryStatusModal/ChangeDeliveryStatusModal';
import { useRouting } from '@/Hooks';
import { ChangeReceivedStatusModal } from '@/ModalWindows/ChangeReceivedStatusModal/ChangeReceivedStatusModal';
import { GenerateDocumentNumberConfirmationModal } from '@/ModalWindows/GenerateDocumentNumberConfirmationModal/GenerateDocumentNumberConfirmationModal';
import { SplitOrdersModal } from '@/ModalWindows/SplitOrdersModal/SplitOrdersModal';
import { MergeOrdersModal } from '@/ModalWindows/MergeOrdersModal/MergeOrdersModal';
import { useModalWindows } from '@/ModalWindows/hooks/useModalWindows';
import { SynchOrdersCompleteModal } from '@/ModalWindows/SynchOrdersCompleteModal/SynchOrdersCompleteModal';
import { EmailSendModal } from './EmailSendModal/EmailSendModal';
import { AlreadyExistsDocumentNumberModal } from './AlreadyExistsDocumentNumberModal/AlreadyExistsDocumentNumberModal';

export * from './ChangePaymentStatusModal/ChangePaymentStatusModal';

const ALLOWED_ROUTES_CONFIG: Record<ModalType, Record<string, boolean> | boolean> = {
  [ModalType.DocumentTemplates]: {
    [PagesRouting.MainPages.SalesPage.Sales]: true,
    [PagesRouting.MainPages.PurchasesPage]: true,
    [PagesRouting.MainPages.CustomersPage.Customers]: true,
  },
  [ModalType.ChangePaymentStatus]: {
    [PagesRouting.MainPages.SalesPage.Sales]: true,
    [PagesRouting.MainPages.PurchasesPage]: true,
  },
  [ModalType.ChangeOtherStatuses]: {
    [PagesRouting.MainPages.SalesPage.Sales]: true,
    [PagesRouting.MainPages.PurchasesPage]: true,
  },
  [ModalType.ChangeDeliveryStatus]: {
    [PagesRouting.MainPages.SalesPage.Sales]: true,
    [PagesRouting.MainPages.PurchasesPage]: true,
  },
  [ModalType.ShippingLabel]: {
    [PagesRouting.MainPages.SalesPage.Sales]: true,
  },
  [ModalType.GenerateDocumentNumberConfirmationModal]: {
    [PagesRouting.MainPages.SalesPage.Sales]: true,
    [PagesRouting.MainPages.PurchasesPage]: true,
  },
  [ModalType.AlreadyExistsDocumentNumber]: {
    [PagesRouting.MainPages.SalesPage.Sales]: true,
    [PagesRouting.MainPages.PurchasesPage]: true,
    [PagesRouting.MainPages.OnlineArchivePage.AllocateTab]: true,
  },
  [ModalType.SplitOrders]: {
    [PagesRouting.MainPages.SalesPage.Sales]: true,
  },
  [ModalType.MergeOrders]: {
    [PagesRouting.MainPages.SalesPage.Sales]: true,
  },
  [ModalType.SynchOrdersComplete]: true,
  [ModalType.None]: {},
  [ModalType.EmailSend]: {
    [PagesRouting.MainPages.SalesPage.Sales]: true,
  },
};

export function ModalWindowsController() {
  const { currentPage } = useRouting();
  const isSalesPage = currentPage === PagesRouting.MainPages.SalesPage.Sales;
  const { closeModalWindow } = useModalWindows();

  let CurrentModal: ((props: ModalProps) => JSX.Element | null) | null = null;

  const currentModalType = useSelector(modalWindowsSelectors.modalType);
  const isAllowed = isModalAllowed(currentModalType);

  switch (currentModalType) {
    case ModalType.DocumentTemplates:
      CurrentModal = DocumentPrintModal;
      break;
    case ModalType.ShippingLabel:
      CurrentModal = ShippingLabelModal;
      break;
    case ModalType.ChangePaymentStatus:
      CurrentModal = ChangePaymentStatusModal;
      break;
    case ModalType.ChangeDeliveryStatus:
      CurrentModal = isSalesPage ? ChangeDeliveryStatusModal : ChangeReceivedStatusModal;
      break;
    case ModalType.ChangeOtherStatuses:
      CurrentModal = ChangeOtherStatusesModal;
      break;
    case ModalType.GenerateDocumentNumberConfirmationModal:
      CurrentModal = GenerateDocumentNumberConfirmationModal;
      break;
    case ModalType.AlreadyExistsDocumentNumber:
      CurrentModal = AlreadyExistsDocumentNumberModal;
      break;
    case ModalType.SplitOrders:
      CurrentModal = SplitOrdersModal;
      break;
    case ModalType.MergeOrders:
      CurrentModal = MergeOrdersModal;
      break;
    case ModalType.SynchOrdersComplete:
      CurrentModal = SynchOrdersCompleteModal;
      break;
    case ModalType.EmailSend:
      CurrentModal = EmailSendModal;
  }

  if (!isAllowed) {
    closeModalWindow();
    return <></>;
  }

  return CurrentModal && <CurrentModal closeModalWindow={closeModalWindow} />;
}

function isModalAllowed(modalType: ModalType): boolean {
  const value = ALLOWED_ROUTES_CONFIG[modalType];
  return value === true || (value !== false && value[location.pathname]);
}
