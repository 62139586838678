import React, { PropsWithChildren } from 'react';
import styles from './MainContent.scss';
import { ClassNameProps } from '@/Types';
import cn from 'classnames';

type Props = ClassNameProps;

export const MainContent: React.FC<Props> = ({ children, className }: PropsWithChildren<Props>) => {
  return <main className={cn(styles.main, className)}>{children}</main>;
};
