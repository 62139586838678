import React, { FC } from 'react';
import { ClassNameProps } from '@/Types';
import styles from './SideMenu.scss';
import { PagesRouting } from '@/Routing';
import { useHistory } from 'react-router';
import cn from 'classnames';
import { getModuleItemClassNames } from './Utils';
import { Icons } from '@/Static/IconsRes';
import { useAdminSideMenuItems } from './hooks/useAdminSideMenuItems';

type Props = ClassNameProps;

export const AdminSideMenu: FC<Props> = ({ className }: Props) => {
  const history = useHistory();
  const pathName = history.location.pathname;

  const items = useAdminSideMenuItems();

  return (
    <div className={cn(className, styles.adminSideMenu)}>
      <div className={styles.logo} onClick={() => history.push(PagesRouting.MainPages.SalesPage.Sales)}>
        <img src={Icons.Logo} alt="logo" />
      </div>

      {items.map((item) => (
        <label
          key={item.title}
          className={getModuleItemClassNames(item.classRoutes, pathName)}
          onClick={() => history.push(item.route)}
        >
          <img src={item.icon} alt={item.title} className={styles.modules__image} />
          <span className={styles.modules__text}>{item.title}</span>
        </label>
      ))}
    </div>
  );
};
