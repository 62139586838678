import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models import
import { ProductCategoryModel, ProductModel } from '@/Models';

// Mapping
import { productMapping } from '@/Mapping';

// Enums import
import { PageModalType } from '@/Enums';

// Types import
import { ProductModalType } from '@/Pages/Products/types/ProductModalType';
import { Nullable, ProductTableRow } from '@/Types';

// Services import
import { asyncActions } from './asyncActions';

export type ProductsState = {
  selectedProduct: Nullable<ProductModel>;
  formProduct: Nullable<Partial<ProductModel>>;
  productModal: ProductModalType;
  products: ProductTableRow[];
  productCategories: ProductCategoryModel[];
  detailViewActiveTab: number;
};

const initialState: ProductsState = {
  selectedProduct: null,
  formProduct: null,
  productModal: { type: PageModalType.None, isOnlyPackages: false, model: null },
  products: [],
  productCategories: [],
  detailViewActiveTab: 0,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setDetailViewActiveTab: (state, action: PayloadAction<number>) => {
      state.detailViewActiveTab = action.payload;
    },
    selectProduct: (state, action: PayloadAction<Nullable<ProductModel>>) => {
      state.selectedProduct = action.payload;
    },
    setModalType(state, action: PayloadAction<ProductModalType>) {
      state.productModal = action.payload;
    },
    clearProducts(state) {
      state.products = [];
      state.selectedProduct = null;
    },
    closeModal(state) {
      state.productModal = { type: PageModalType.None, isOnlyPackages: false, model: null };
    },
  },
  extraReducers: (builder) => {
    builder
      // Getting products
      .addCase(asyncActions.getProducts.fulfilled, (state, action) => {
        state.products = productMapping.convertProductsToProductTableRows(action.payload);
      })

      // Getting product categories
      .addCase(asyncActions.getProductCategories.fulfilled, (state, action) => {
        state.productCategories = action.payload.productCategories;
      })

      // Creating product
      .addCase(asyncActions.createProduct.fulfilled, (state, action) => {
        state.products.push(productMapping.convertProductToProductTableRow(action.payload.product));
        state.selectedProduct = action.payload.product;

        state.productModal = { type: PageModalType.None, isOnlyPackages: false, model: null };
      })

      // Updating product
      .addCase(asyncActions.updateProduct.fulfilled, (state, action) => {
        state.products = state.products.map((x) =>
          x.id == action.payload.product.id
            ? productMapping.convertProductToProductTableRow(action.payload.product)
            : x,
        );
        state.selectedProduct = action.payload.product;

        state.productModal = { type: PageModalType.None, isOnlyPackages: false, model: null };
      })

      // Removing product
      .addCase(asyncActions.deleteProduct.fulfilled, (state, action) => {
        state.products = state.products.filter((x) => x.id !== action.payload);
        state.selectedProduct = null;
      })

      // Removing product package
      .addCase(asyncActions.deleteProductPackage.fulfilled, (state, action) => {
        if (state.selectedProduct) {
          state.selectedProduct.productPackages = state.selectedProduct.productPackages.filter(
            (item) => item.id != action.payload,
          );
          state.products = state.products.map((item) =>
            item.id === state.selectedProduct?.id
              ? productMapping.convertProductToProductTableRow(state.selectedProduct)
              : item,
          );
        } // if
      });
  },
});

const { actions, reducer } = productsSlice;

export const productsPageActions = actions;
export const productsPageReducer = reducer;
export const productsAsyncActions = asyncActions;
