import { RootState } from '@/Redux/RootReducer';

export class NumberFormatHandler {
  private static instance: NumberFormatHandler;
  private readonly getState: () => RootState;

  constructor(getState: () => RootState) {
    this.getState = getState;
    NumberFormatHandler.instance = this;
  }

  static start(getState: () => RootState) {
    NumberFormatHandler.instance = new NumberFormatHandler(getState);
  }

  public static getInstance(): NumberFormatHandler {
    return this.instance;
  }

  // Error handler
  public getNumberFormatLocales(): string[] {
    const {
      user: {
        settings: { NumbersLocale },
      },
    } = this.getState();

    return NumbersLocale?.value.split(', ') ?? [];
  }
}
