import { useState } from 'react';

export const useTabs = (initialTabIndex = 0) => {
  const [activeTab, setActiveTab] = useState(initialTabIndex);

  return {
    activeTab,
    setActiveTab,
    isActive: (idx: number) => idx === activeTab,
  };
};
