import { UserWarehouseLocationModel } from 'src/Models/Warehouse/UserWarehouseLocationModel';
import { createSlice } from '@reduxjs/toolkit';
import { userWarehouseLocationAsyncActions } from 'src/Pages/Settings/modules/WarehouseSettings/services/userWarehouseLocations/asyncActions';

export type UserWarehouseLocationSettingsState = {
  warehouseLocations: UserWarehouseLocationModel[];
};

export const initialState: UserWarehouseLocationSettingsState = {
  warehouseLocations: [],
};

export const {
  actions: userWarehouseLocationSettingsActions,
  reducer: userWarehouseLocationSettingsReducer,
} = createSlice({
  name: 'userWarehouseLocationSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Locations
    builder.addCase(userWarehouseLocationAsyncActions.getUserWarehouseLocations.fulfilled, (state, action) => {
      state.warehouseLocations = action.payload.userWarehouseLocations;
    });
    builder.addCase(userWarehouseLocationAsyncActions.createUserWarehouseLocation.fulfilled, (state, action) => {
      state.warehouseLocations = [...state.warehouseLocations, action.payload.userWarehouseLocation];
    });
    builder.addCase(userWarehouseLocationAsyncActions.updateUserWarehouseLocation.fulfilled, (state, action) => {
      state.warehouseLocations = state.warehouseLocations.map((x) =>
        x.id === action.payload.userWarehouseLocation.id ? action.payload.userWarehouseLocation : x,
      );
    });
    builder.addCase(userWarehouseLocationAsyncActions.deleteUserWarehouseLocation.fulfilled, (state, action) => {
      state.warehouseLocations = state.warehouseLocations.filter((x) => x.id !== action.payload.id);
    });
  },
});
