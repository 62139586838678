import { TreeItemType } from '@/Components/TreeView/TreeView';
import { Node } from 'devextreme/ui/tree_view';
import { LogisticType } from '@/Enums';

const DOT_REGEX = /\b\.\b/;

// Get selected keys in tree view
function getSelectedKeys(
  items: TreeItemType<string | number | LogisticType>[],
  onlyChildren = true,
): (string | number)[] {
  if (!items?.length) {
    return [];
  }

  const result = items.reduce((acc, curr) => {
    if (onlyChildren) {
      if (!curr.items || curr.items?.length == 0) {
        return (curr as { selected?: boolean }).selected ? [...acc, curr.key] : acc;
      }
    } else {
      if ((curr as { selected?: boolean }).selected) {
        acc = [...acc, curr.key];
      }
    }

    return [...acc, ...getSelectedKeys(curr.items || [], onlyChildren)];
  }, [] as (string | number)[]);

  return [...new Set(result)];
} // getSelectedKeys

// Get selected values in tree view
function getSelectedValues(items: TreeItemType<string | number>[], onlyChildren = true): (string | number)[] {
  if (!items?.length) {
    return [];
  }

  const result = items.reduce((acc, curr) => {
    if (onlyChildren) {
      if (!curr.items || curr.items?.length == 0) {
        return (curr as { selected?: boolean }).selected ? [...acc, curr.value] : acc;
      }
    } else {
      if ((curr as { selected?: boolean }).selected) {
        acc = [...acc, curr.value];
      }
    }

    return [...acc, ...getSelectedValues(curr.items || [], onlyChildren)];
  }, [] as (string | number)[]);

  return [...new Set(result)];
} // getSelectedValues

// Find item in tree view by key
function findViewItemByKey(path: string, items: TreeItemType[]): TreeItemType | undefined {
  if (!path) return undefined;

  const pathParts = path.split(DOT_REGEX);
  if (!pathParts.length) return undefined;

  let curKey = pathParts[0];
  let obj = items.find((el) => el.key === curKey);
  obj = pathParts.slice(1).reduce((curr, k) => {
    curKey = curKey ? curKey + '.' + k : k;
    return curr?.items?.find((el) => el.key === curKey);
  }, obj);

  return obj;
} // findViewItemByKey

// Change children parent key
function changeChildrenParentKey(item: TreeItemType) {
  item.items?.forEach((i) => {
    i.key = item.key + '.' + i.value;
    if (i.items?.length) {
      changeChildrenParentKey(i);
    } // if
  });
} // changeChildrenParentKey

// Convert Node view to TreeViewItem
function convertNodeToTreeViewItem<T extends string | number = string>(element: Node<T>): TreeItemType<T> {
  return {
    key: element.key!,
    value: element.text!,
    parent: element.parent ? convertNodeToTreeViewItem(element.parent) : undefined,
  };
} // convertNodeToTreeViewItem

function getFlattenedTreeViewItems<T extends string | number = string>(
  items: TreeItemType<T>[],
  result: TreeItemType<T>[] = [],
) {
  for (const item of items) {
    result.push(item);
    if (item.items && item.items.length > 0) {
      getFlattenedTreeViewItems(item.items, result);
    }
  }
  return result;
}

export const treeViewUtils = {
  changeChildrenParentKey,
  getSelectedKeys,
  getSelectedValues,
  findViewItemByKey,
  convertNodeToTreeViewItem,
  getFlattenedTreeViewItems,
};
