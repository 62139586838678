import { ApiRoutingUtils } from '@/Routing';
import { CreateReportRequest, CreateReportResponse } from './';
import { ReportInfoModel } from '@/Models/_Microservices/OnlineArchive/ReportInfoModel';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createOnlineArchiveUrl('reports');

function getReports(): Promise<ReportInfoModel[]> {
  return rest.get(url, {});
}

function getReport(id: number): Promise<ReportInfoModel> {
  return rest.get(`${url}/${id}`, {});
}

function createReport(request: CreateReportRequest): Promise<CreateReportResponse> {
  return rest.post(url, request);
}

function deleteReport(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, {});
}

export const ReportsApi = {
  getReports,
  getReport,
  createReport,
  deleteReport,
};
