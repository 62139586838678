import {
  CreateDeutschePostInternetmarkeContractCommand,
  CreateDeutschePostInternetmarkeContractResponse,
  DeutschePostInternetmarkeContractsApi,
  GetDeutschePostInternetmarkeContractResponse,
  GetDeutschePostInternetmarkeContractStatusQuery,
  GetDeutschePostInternetmarkeContractStatusResponse,
  GetDeutschePostInternetmarkeFormatsResponse,
  UpdateDeutschePostInternetmarkeContractCommand,
  UpdateDeutschePostInternetmarkeContractResponse,
} from '@/Api/DeutschePostInternetmarkeContracts';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getContract = createAsyncThunk<GetDeutschePostInternetmarkeContractResponse>(
  'DeutschePostInternetmarkeContractSettings/getDeutschePostInternetmarkeContract',
  () => {
    return DeutschePostInternetmarkeContractsApi.getContract();
  },
);

const getMarkeFormats = createAsyncThunk<GetDeutschePostInternetmarkeFormatsResponse>(
  'DeutschePostInternetmarkeContractSettings/getDeutschePostInternetmarkeMarkeFormats',
  () => {
    return DeutschePostInternetmarkeContractsApi.getMarkeFormats();
  },
);

const createContract = createAsyncThunk<
  CreateDeutschePostInternetmarkeContractResponse,
  CreateDeutschePostInternetmarkeContractCommand
>(
  'DeutschePostInternetmarkeContractSettings/createDeutschePostInternetmarkeContract',
  async (request: CreateDeutschePostInternetmarkeContractCommand) => {
    return await DeutschePostInternetmarkeContractsApi.createContract(request);
  },
);

const updateContract = createAsyncThunk<
  UpdateDeutschePostInternetmarkeContractResponse,
  UpdateDeutschePostInternetmarkeContractCommand
>(
  'DeutschePostInternetmarkeContractSettings/updateDeutschePostInternetmarkeContract',
  async (request: UpdateDeutschePostInternetmarkeContractCommand) => {
    return await DeutschePostInternetmarkeContractsApi.updateContract(request);
  },
);

const deleteContract = createAsyncThunk<number, number>(
  'DeutschePostInternetmarkeContractSettings/deleteDeutschePostInternetmarkeContract',
  (request: number) => {
    return DeutschePostInternetmarkeContractsApi.deleteContract(request);
  },
);

const checkConnection = createAsyncThunk<
  GetDeutschePostInternetmarkeContractStatusResponse,
  GetDeutschePostInternetmarkeContractStatusQuery
>(
  'DeutschePostInternetmarkeContractSettings/checkDeutschePostInternetmarkeContractConnection',
  (request: GetDeutschePostInternetmarkeContractStatusQuery) => {
    return DeutschePostInternetmarkeContractsApi.checkConnection(request);
  },
);

export const asyncActions = {
  getContract,
  createContract,
  updateContract,
  deleteContract,
  checkConnection,
  getMarkeFormats,
};
