import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OriginalProductModel, UserProductModel } from '@/Models';
import { ShippingServiceType, UserProductType } from '@/Enums';
import { Nullable, Pair, UserProductTableRow } from '@/Types';
import { LogisticProduct } from '@/Models/LogisticProducts/LogisticProduct';
import { userProductsPageAsyncActions } from './asyncActions';

export type UserProductsState = {
  selectedProduct: Nullable<UserProductModel>;
  products: UserProductTableRow[];
  originalProducts: OriginalProductModel[];
  userProductType: UserProductType;
  shippingServiceTypes: Pair<ShippingServiceType>[];
  isSkuBillable: boolean;
  logisticProducts: LogisticProduct[];
};

const initialState: UserProductsState = {
  selectedProduct: null,
  products: [],
  originalProducts: [],
  userProductType: UserProductType.Product,
  shippingServiceTypes: [],
  isSkuBillable: true,
  logisticProducts: [],
};

const userProductsSlice = createSlice({
  name: 'userProducts',
  initialState,
  reducers: {
    setShippingServiceTypes(state, action: PayloadAction<Pair<ShippingServiceType>[]>) {
      state.shippingServiceTypes = action.payload;
    },
    selectProduct(state, action: PayloadAction<Nullable<UserProductModel>>) {
      state.selectedProduct = action.payload;
    },
    setProductType(state, action: PayloadAction<UserProductType>) {
      state.userProductType = action.payload;
    },
    setIsSkuBillable(state, action: PayloadAction<boolean>) {
      state.isSkuBillable = action.payload;
    },
    clearData(state) {
      state.products = [];
      state.originalProducts = [];
      state.shippingServiceTypes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userProductsPageAsyncActions.getAllProductsRequest.fulfilled, (state, action) => {
        state.products = action.payload;
      })
      .addCase(userProductsPageAsyncActions.createProductRequest.fulfilled, (state, action) => {
        state.products.push(action.payload.product);
        state.selectedProduct = action.payload.product;
      })
      .addCase(userProductsPageAsyncActions.updateProductRequest.fulfilled, (state, action) => {
        state.products = state.products.map((x) => (x.id == action.payload.product.id ? action.payload.product : x));
        state.selectedProduct = action.payload.product;
      })
      .addCase(userProductsPageAsyncActions.deleteProductRequest.fulfilled, (state, action) => {
        state.products = state.products.filter((x) => x.id !== action.payload);
      })
      .addCase(userProductsPageAsyncActions.getAllOriginalProductsRequest.fulfilled, (state, action) => {
        state.originalProducts = action.payload;
      })
      .addCase(userProductsPageAsyncActions.getLogisticProducts.fulfilled, (state, action) => {
        state.logisticProducts = action.payload;
      });
  },
});

const { actions, reducer } = userProductsSlice;

export const userProductsPageActions = actions;
export const userProductsPageReducer = reducer;
