export enum MenuItemType {
  AssignOrderNumber,
  AssignDeliveryNoteNumber,
  AssignInvoiceNumber,
  AssignCancelInvoiceNumber,
  ChangePaymentStatus,
  ChangeDeliveryStatus,
  Document,
  Email,
  SplitOrders,
  OrderMerge,
  CreateFeedback,
  ShippingLabel,
  ChangeOtherStatuses,
}
