import { createSlice } from '@reduxjs/toolkit';
import { ShippingMethodModel } from '@/Models';
import { asyncActions } from './asyncActions';

export type ShippingMethodsDataState = {
  shippingMethods: ShippingMethodModel[];
};

const initialState: ShippingMethodsDataState = {
  shippingMethods: [],
};

const shippingMethodsDataSlice = createSlice({
  name: 'shippingMethodsData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getShippingMethods.fulfilled, (state, action) => {
        state.shippingMethods = action.payload;
      })
      .addCase(asyncActions.createShippingMethod.fulfilled, (state, action) => {
        state.shippingMethods.push(action.payload.shippingMethod);
      })
      .addCase(asyncActions.updateShippingMethod.fulfilled, (state, action) => {
        state.shippingMethods = state.shippingMethods.map((item) =>
          item.id === action.payload.shippingMethod.id ? action.payload.shippingMethod : item,
        );
      })
      .addCase(asyncActions.deleteShippingMethod.fulfilled, (state, action) => {
        state.shippingMethods = state.shippingMethods.filter((item) => item.id !== action.payload);
      });
  },
});

export const shippingMethodsDataReducer = shippingMethodsDataSlice.reducer;
export const shippingMethodsDataActions = shippingMethodsDataSlice.actions;
export const shippingMethodsDataAsyncActions = asyncActions;
