import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '@/Types';
import { asyncActions } from './asyncActions';
import { ContactModel } from '@/Models';
import { ModalDataType } from '@/Types/ModalDataType';

export type SuppliersPageState = {
  suppliers: ContactModel[];
  selectedSupplier: Nullable<ContactModel>;
  supplierModalData: ModalDataType<ContactModel>;
};

const initialState: SuppliersPageState = {
  suppliers: [],
  selectedSupplier: null,
  supplierModalData: {
    visible: false,
  },
};

const suppliersPageSlice = createSlice({
  name: 'suppliersPage',
  initialState,
  reducers: {
    setSelectedSupplier: (state, action: PayloadAction<Nullable<ContactModel>>) => {
      state.selectedSupplier = action.payload;
    },
    setSupplierModalData: (state, action: PayloadAction<ModalDataType<ContactModel>>) => {
      state.supplierModalData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getSuppliers.fulfilled, (state, action) => {
        state.suppliers = action.payload;
      })
      .addCase(asyncActions.createSupplier.fulfilled, (state, action) => {
        if (!action.payload.errors) {
          state.suppliers.push(action.payload.contact);
        } // if
      })
      .addCase(asyncActions.updateSupplier.fulfilled, (state, action) => {
        if (!action.payload.errors) {
          state.suppliers = state.suppliers.map((i) =>
            i.id === action.payload.contact.id ? action.payload.contact : i,
          );
          state.selectedSupplier = action.payload.contact;
        } // if
      })
      .addCase(asyncActions.deleteSupplier.fulfilled, (state, action) => {
        if (!action.payload.errors) {
          state.suppliers = state.suppliers.filter((i) => i.id !== action.payload.id);
          state.selectedSupplier = null;
        } // if
      });
  },
});

export const suppliersPageReducer = suppliersPageSlice.reducer;
export const suppliersPageActions = suppliersPageSlice.actions;
export const suppliersPageAsyncActions = asyncActions;
