import { combineReducers } from '@reduxjs/toolkit';
import {
  userWarehouseTypeSettingsReducer,
  UserWarehouseTypeSettingsState,
} from 'src/Pages/Settings/modules/WarehouseSettings/services/userWarehouseTypes/reducer';
import {
  userWarehouseLocationSettingsReducer,
  UserWarehouseLocationSettingsState,
} from 'src/Pages/Settings/modules/WarehouseSettings/services/userWarehouseLocations/reducer';
import {
  userWarehouseTemplateSettingsReducer,
  UserWarehouseTemplateSettingsState,
} from 'src/Pages/Settings/modules/WarehouseSettings/services/userWarehouseTemplates/reducer';

export type WarehouseSettingsState = {
  userWarehouseTypeSettings: UserWarehouseTypeSettingsState;
  userWarehouseLocationSettings: UserWarehouseLocationSettingsState;
  userWarehouseTemplateSettings: UserWarehouseTemplateSettingsState;
};

export const warehouseSettingsPageReducer = combineReducers<WarehouseSettingsState>({
  userWarehouseTypeSettings: userWarehouseTypeSettingsReducer,
  userWarehouseLocationSettings: userWarehouseLocationSettingsReducer,
  userWarehouseTemplateSettings: userWarehouseTemplateSettingsReducer,
});
