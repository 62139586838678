import { ApiRoutingUtils } from 'src/Routing';
import { rest } from 'src/Api/Rest';
import { ScanBarcodeIMTRequest } from 'src/Api/UserWarehouse/Transfers/IncomingManual/Requests/ScanBarcodeIMTRequest';
import { ScanBarcodeIMTResponse } from 'src/Api/UserWarehouse/Transfers/IncomingManual/Responses/ScanBarcodeIMTResponse';
import { CreatePostingsIMTRequest } from 'src/Api/UserWarehouse/Transfers/IncomingManual/Requests/CreatePostingsIMTRequest';
import { CreatePostingsIMTResponse } from 'src/Api/UserWarehouse/Transfers/IncomingManual/Responses/CreatePostingsIMTResponse';

const transferUrl = ApiRoutingUtils.createUrl('user-warehouse-incoming-manual-transfer');

async function scanBarcode(request: ScanBarcodeIMTRequest): Promise<ScanBarcodeIMTResponse> {
  return rest.post(`${transferUrl}/scan-barcode`, request);
}

async function createPostings(request: CreatePostingsIMTRequest): Promise<CreatePostingsIMTResponse> {
  return rest.post(`${transferUrl}/create-postings`, request);
}

export const WarehouseIncomingManualTransferApi = {
  scanBarcode,
  createPostings,
};
