import { FormData as MergeOrdersFormData } from '../../../hooks/useMergeDiffForm';
import { useFormContext } from '@/Hooks/useFormWrapper';
import { OrderTableRow } from '@/Types';
import { useEffect } from 'react';
import { useRequired } from '@/Hooks/Validation';

export type FormData = Pick<
  MergeOrdersFormData,
  | 'invoiceContactInfoSourceOrderId'
  | 'invoiceFirstName'
  | 'invoiceLastName'
  | 'invoiceCompanyName'
  | 'invoicePhoneNumber'
  | 'shippingContactInfoSourceOrderId'
  | 'shippingFirstName'
  | 'shippingLastName'
  | 'shippingCompanyName'
  | 'shippingPhoneNumber'
>;
export const useMergeOrdersAddressContactSectionForm = (isInvoice: boolean, orders: OrderTableRow[]) => {
  const { register, setValue, watch, formState } = useFormContext<FormData>();

  const contactInfoSourceId = watch(isInvoice ? 'invoiceContactInfoSourceOrderId' : 'shippingContactInfoSourceOrderId');

  useEffect(() => {
    if (!contactInfoSourceId) return;

    const order = orders.find((o) => o.id == contactInfoSourceId);
    if (!order) return;

    if (isInvoice) {
      setValue('invoiceCompanyName', order.invoiceCompanyName);
      setValue('invoiceFirstName', order.invoiceFirstName);
      setValue('invoiceLastName', order.invoiceLastName);
      setValue('invoicePhoneNumber', order.invoicePhoneNumber);
    } else {
      setValue('shippingCompanyName', order.shippingCompanyName);
      setValue('shippingFirstName', order.shippingFirstName);
      setValue('shippingLastName', order.shippingLastName);
      setValue('shippingPhoneNumber', order.shippingPhoneNumber);
    } // if
  }, [contactInfoSourceId, orders, isInvoice, setValue]);

  const required = useRequired();

  return {
    register,
    setValue,
    errors: formState.errors,
    rules: {
      required,
    },
  };
};
