import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetChartDataResponse } from '@/Api/Dashboard/Responses/GetChartDataResponse';
import { GetChartDataRequest } from '@/Api/Dashboard/Requests/GetChartDataRequest';
import { DashboardApi } from '@/Api/Dashboard/DashboardApi';
import { GetDashboardFilterResponse } from '@/Api/Dashboard/Responses/GetDashboardFilterResponse';

const getChartData = createAsyncThunk<GetChartDataResponse, GetChartDataRequest>(
  'dashboardPage/get-chart-data',
  async (request) => {
    return DashboardApi.getChartData(request);
  },
);

const getDashboardFilter = createAsyncThunk<GetDashboardFilterResponse>(
  'dashboardPage/get-dashboard-filter',
  async () => {
    return DashboardApi.getDashboardFilter();
  },
);

export const dashboardPageAsyncActions = {
  getChartData,
  getDashboardFilter,
};
