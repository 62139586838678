import { CountryCode } from '@/Enums';
import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { countryTypeUtils } from '@/Utils/CountryTypesUtils';
import { arrayUtils } from '@/Utils/ArrayUtils';

const generatorProductsStateSelector = (state: RootState) => state.warehouseLogisticsPage.generationTemplates;
const translation = (state: RootState) => state.global.translation;

const templates = createSelector(generatorProductsStateSelector, (state) => state.templates || []);
const templatesTranslated = createSelector([templates, translation], (state, translation) => {
  const countryList = state.map((x) => ({
    ...x,
    countryName: countryTypeUtils.getCountryNameByCountryCode(x.country, translation),
  }));
  return arrayUtils.sortBy(countryList, 'countryName');
});

const modalWindowType = createSelector(generatorProductsStateSelector, (state) => state.modalWindowType);

const logisticTypeOptions = createSelector(generatorProductsStateSelector, (state) => state.logisticTypeOptions);

const availableLogisticTypeOptions = (country: CountryCode) =>
  createSelector(generatorProductsStateSelector, (state) => {
    const templateCountry = state.templates.find((x) => x.country === country);
    if (templateCountry) {
      return state.logisticTypeOptions.filter((x) => templateCountry.logistics.every((y) => y.logisticId != x.key));
    }
    return state.logisticTypeOptions;
  });

const duplicatedProducts = createSelector(generatorProductsStateSelector, (state) => state.duplicatedProducts);

export const generatorProductSelectors = {
  templates: templatesTranslated,
  modalWindowType,
  logisticTypeOptions,
  availableLogisticTypeOptions,
  duplicatedProducts,
};
