import React, { PropsWithChildren } from 'react';
import { ClassNameProps } from '@/Types';
import cn from 'classnames';
import styles from './TabList.scss';

type Props = {
  needPlug?: boolean;
} & ClassNameProps;

export const TabList: React.FC<Props> = ({ className, children, needPlug = true }: PropsWithChildren<Props>) => {
  return (
    <div
      className={cn(className, {
        [styles.tabsList]: true,
        [styles.tabsList_width_100]: needPlug,
      })}
    >
      {children}
    </div>
  );
};
