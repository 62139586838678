import { TranslationsApi } from '@/Api/Translations/TranslationsApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LanguageResourceType } from '@/Enums/LanguageResourceType';
import { UpdateTranslationsRequest } from '@/Api/Translations/UpdateTranslationsRequest';
import { RootState } from '@/Redux/RootReducer';
import { TranslationModel } from '@/Models/TranslationModel';
import { translationsEditorUtils } from '@/Pages/Administration/LanguageEditor/services/TranslationsEditor/utils';

const load = createAsyncThunk('languageEditorPage/product-translations/load', async () => {
  return TranslationsApi.getTranslations({
    languageResource: LanguageResourceType.Products,
  });
}); // load

const syncTranslations = createAsyncThunk(
  'languageEditorPage/product-translations/syncTranslations',
  async (_, thunkApi) => {
    const { translations: newTranslations } = await TranslationsApi.getTranslations({
      languageResource: LanguageResourceType.Products,
    });

    const {
      languageEditorPage: {
        productTranslationsEditor: {
          translations: { items: oldTranslations },
        },
      },
    } = thunkApi.getState() as RootState;

    let updatedTranslations: TranslationModel[] = [];
    let updatedPaths: string[] = [];
    // Update
    const translations = oldTranslations.map((oldTranslation) => {
      const newTranslation = newTranslations.find((item) => item.key === oldTranslation.key);
      if (newTranslation) {
        const { translation, paths, updatedItems } = translationsEditorUtils.updateStateNested(
          oldTranslation,
          newTranslation,
        );
        updatedTranslations = [...updatedTranslations, ...updatedItems];
        updatedPaths = [...updatedPaths, ...paths];
        return translation;
      }

      return oldTranslation;
    });

    // Return new translations and keys array
    return {
      updatedTranslations,
      translations,
      updatedPaths,
    };
  },
); // syncTranslations

const update = createAsyncThunk(
  'languageEditorPage/product-translations/update',
  async (request: UpdateTranslationsRequest) => {
    return TranslationsApi.updateTranslations(request);
  },
); // update

export const asyncActions = {
  load,
  update,
  syncTranslations,
};
