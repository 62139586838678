import { useTriggeredValue } from '@/Hooks';

type UseModalDialogReturnType = {
  isDialogOpened: boolean;
  openDialog: () => void;
  closeDialog: () => void;
};

export function useModalDialog(): UseModalDialogReturnType {
  const { off, on, value } = useTriggeredValue(false, true, false);

  return {
    isDialogOpened: value,
    openDialog: on,
    closeDialog: off,
  };
}
