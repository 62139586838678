import React, { useEffect } from 'react';
import { OrderAutomatizaionRow } from '@/Types';
import { useFormContext } from '@/Hooks/useFormWrapper';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { useRequired } from '@/Hooks/Validation';
import { useLocalization } from '@/Hooks';
import { useOrderPackagesLoader } from './hooks/useOrderPackagesLoader';
import { FormData } from '@/ModalWindows/OrderAutomatizationModal/hooks/useOrderAutomatizationModalForm';
import { OwnContractLogistic } from './modules/OwnContractLogistic/OwnContractLogistic';
import { ShipCheaperLogistic } from './modules/ShipCheaperLogistic/ShipCheaperLogistic';
import styles from './OrderPackagesCell.scss';
import { shippingLabelModalActions } from '@/ModalWindows/ShippingLabelModal/services';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@/Components/Icon/Icon';
import { TopMenuIcons } from '@/Static/IconsRes';
import { globalHistory } from '@/GlobalHistory';
import { PagesRouting } from '@/Routing';
import { userSelectors } from '@/Redux/User';
import { orderLabelPrintModalSelectors } from '@/ModalWindows/OrderLabelPrintModal/services';

type Props = {
  rowIndex: number;
  rowData: OrderAutomatizaionRow;
};

export function OrderPackagesCell({ rowIndex, rowData }: Props) {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userSelectors.currentUser);
  const orderLabelPrintRows = useSelector(orderLabelPrintModalSelectors.orderLabelPrintRows);

  const {
    common: { InputPlaceholders },
    modalWindows: { orderLinkWithProduct },
  } = useLocalization();

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<FormData>();

  const required = useRequired();

  const ownContract = watch(`prepareLabels.${rowIndex}.ownContract`);

  useOrderPackagesLoader(rowData, ownContract);

  useEffect(() => {
    dispatch(shippingLabelModalActions.setOwnContract(ownContract === 'yes'));
  }, [ownContract]);

  const orderLabel = orderLabelPrintRows.find((item) => item.userOrderId == rowData.userOrderId);

  return (
    <div className={styles.packagesWrapper}>
      <div className={styles.packageSelectWithIcon}>
        <FormSelectInput<FormData>
          name={(`prepareLabels.${rowIndex}.ownContract` as unknown) as keyof FormData}
          register={register}
          options={
            user?.canUsePacklink
              ? [
                  { key: '', value: InputPlaceholders.Select },
                  { key: 'yes', value: orderLinkWithProduct.Options.OwnShippingContract },
                  { key: 'no', value: orderLinkWithProduct.Options.OrderShippingLableByShipCheaper },
                ]
              : [
                  { key: '', value: InputPlaceholders.Select },
                  { key: 'yes', value: orderLinkWithProduct.Options.OwnShippingContract },
                ]
          }
          needClearButton={false}
          rules={required}
          error={errors}
          className={styles.select}
          disabled={orderLabel?.isPrinted}
        />

        <Icon
          alt={''}
          src={TopMenuIcons.SettingsIcon}
          className={styles.selectInputIcon}
          onClick={() => globalHistory.push(PagesRouting.SettingsPages.LogisticSettings)}
        />
      </div>

      <>
        {ownContract === 'yes' && (
          <OwnContractLogistic rowIndex={rowIndex} rowData={rowData} disabled={orderLabel?.isPrinted} />
        )}
        {ownContract === 'no' && (
          <ShipCheaperLogistic rowIndex={rowIndex} rowData={rowData} disabled={orderLabel?.isPrinted} />
        )}
      </>
    </div>
  );
}
