import { MergeOrderDifferenceSources } from '@/Api/MpOrders/Requests/MergeOrdersRequest';
import { OrderTableRow } from '@/Types';
import { useForm } from '@/Hooks/useFormWrapper';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userSelectors } from '@/Redux/User';
import { useRequired } from '@/Hooks/Validation';

export type FormData = MergeOrderDifferenceSources &
  Pick<
    OrderTableRow,
    | 'currencyId'
    | 'mpAccountId'
    | 'buyerEmail'
    | 'buyerNote'
    | 'buyerVatNumber'
    | 'buyerMarketplaceAccount'
    | 'orderStatus'
    | 'paymentStatus'
    | 'invoiceAdditionalLine'
    | 'invoiceFirstName'
    | 'invoiceLastName'
    | 'invoiceCompanyName'
    | 'invoiceHouseNo'
    | 'invoicePostalCode'
    | 'invoiceStreet'
    | 'invoiceCityName'
    | 'invoiceCountryCode'
    | 'invoiceCountryName'
    | 'invoicePhoneNumber'
    | 'shippingAdditionalLine'
    | 'shippingFirstName'
    | 'shippingLastName'
    | 'shippingCompanyName'
    | 'shippingStreet'
    | 'shippingHouseNo'
    | 'shippingPostalCode'
    | 'shippingCityName'
    | 'shippingCountryCode'
    | 'shippingCountryName'
    | 'shippingPhoneNumber'
    | 'returnFirstName'
    | 'returnLastName'
    | 'returnCompanyName'
    | 'returnStreet'
    | 'returnHouseNo'
    | 'returnPostalCode'
    | 'returnCountryCode'
    | 'returnCountryName'
    | 'returnPhoneNumber'
    | 'accountName'
  >;

export const useMergeDiffForm = (orders: OrderTableRow[], onSave: (data: MergeOrderDifferenceSources) => void) => {
  const methods = useForm<FormData>();
  const allUserAccounts = useSelector(userSelectors.currentUserAccountOptions);

  const accountOptions = useMemo(() => {
    const orderAccountIds = orders.map((x) => x.mpAccountId);

    return allUserAccounts.filter((x) => orderAccountIds.includes(x.key));
  }, [allUserAccounts, orders]);

  const onSubmit = useCallback(
    (data: FormData) => {
      onSave(getMergeOrdersRequestFromFormData(data));
    },
    [onSave],
  );

  const getMergeOrdersRequestFromFormData = useCallback((data: FormData): MergeOrderDifferenceSources => {
    return {
      invoiceAddressSourceOrderId: data.invoiceAddressSourceOrderId,
      shippingAddressSourceOrderId: data.shippingAddressSourceOrderId,
      shippingContactInfoSourceOrderId: data.shippingContactInfoSourceOrderId,
      invoiceContactInfoSourceOrderId: data.invoiceContactInfoSourceOrderId,
      accountId: data.accountId,
      secondContactPersonSourceOrderId: data.secondContactPersonSourceOrderId,
      buyerInfoSourceOrderId: data.buyerInfoSourceOrderId,
    };
  }, []);

  const required = useRequired();
  return {
    rules: {
      required,
    },
    options: {
      accountOptions,
    },
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  };
};
