import { ClassNameProps } from '@/Types';
import React from 'react';
import styles from './OrderItemSection.scss';
import cn from 'classnames';
import { Icons } from '@/Static/IconsRes';
import { useSelector } from 'react-redux';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';

type Props = ClassNameProps;

export const OrderItemsSection = ({ className }: Props) => {
  const focusedOrderItems = useSelector(purchasesPageSelectors.focusedOrderItems);
  return (
    <section className={cn(styles.wrapper, className)}>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <tbody>
            {focusedOrderItems.map(({ quantity, variantTitle, priceBrutto, totalPriceBruttoWithoutShipping, id }) => {
              return (
                <tr key={id}>
                  <td className={styles.nowrap}>{`${quantity} x`}</td>
                  <td>{variantTitle}</td>
                  <td>{priceBrutto.toCurrencyString()}</td>
                  <td>{totalPriceBruttoWithoutShipping.toCurrencyString()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.imagesWrapper}>
        <img src={Icons.BlueDock} alt="doc" />
        <img src={Icons.Lock} alt="lock" />
      </div>
    </section>
  );
};
