import { useLocalization } from '@/Hooks';

export const useOwnContractLogisticTooltips = (selectedPackagesLength: number) => {
  const {
    modalWindows: { orderLinkWithProduct },
  } = useLocalization();

  const productTooltipMessage =
    selectedPackagesLength === 0
      ? orderLinkWithProduct.OwnShippingContract.ChooseAPackageFirst
      : orderLinkWithProduct.OwnShippingContract.TherIsNoSingleLogisticsForTheSelectedPackages;

  return {
    productTooltipMessage,
  };
};
