import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetProductReportsResponse } from '@/Api/Products/Transfer/Responses/GetProductReportsResponse';
import { BinaryResponse } from '@/Api';
import { GetProductTransferErrorsResponse } from '@/Api/Products/Transfer/Responses/GetProductTransferErrorsResponse';
import { TransferApi } from '@/Api/Products/Transfer/TransferApi';
import { GetNextReportNumberResponse } from '@/Api/Products/Transfer/Responses/GetNextReportNumberResponse';
import { LoadReportForProductsRequest } from '@/Api/Products/Transfer/Requests/LoadReportForProductsRequest';
import { GetProductsByBarcodeResponse } from '@/Api/Products/Transfer/Responses/GetProductsByBarcodeResponse';

const getProductsByBarcode = createAsyncThunk<GetProductsByBarcodeResponse, { ean: string; quantity: number }>(
  'transferPage/get-products-by-barcode',
  async (request) => {
    return TransferApi.getProductsByBarcode(request);
  },
);

const loadReportForProducts = createAsyncThunk<BinaryResponse, LoadReportForProductsRequest>(
  'transferPage/load-report-for-products',
  async (request) => {
    return TransferApi.loadReportForProducts(request);
  },
);

const getProductReports = createAsyncThunk<GetProductReportsResponse>('transferPage/get-product-reports', async () => {
  return TransferApi.getProductReports();
});

const getProductReport = createAsyncThunk<BinaryResponse, number>(
  'transferPage/get-product-report',
  async (request) => {
    return TransferApi.getProductReport(request);
  },
);

const getNextReportNumber = createAsyncThunk<GetNextReportNumberResponse>(
  'transferPage/get-next-report-number',
  async () => {
    return TransferApi.getNextReportNumber();
  },
);

const deleteProductReports = createAsyncThunk<number[], number[]>(
  'transferPage/delete-product-reports',
  async (request) => {
    return TransferApi.deleteProductReports(request);
  },
);

const getProductTransferErrors = createAsyncThunk<GetProductTransferErrorsResponse>(
  'transferPage/get-product-transfer-errors',
  async () => {
    return TransferApi.getProductTransferErrors();
  },
);

const clearProductTransferErrors = createAsyncThunk<null>('transferPage/clear-product-transfer-errors', async () => {
  return TransferApi.clearProductTransferErrors();
});

export const asyncActions = {
  getProductsByBarcode,
  loadReportForProducts,
  getProductReports,
  getProductReport,
  getNextReportNumber,
  deleteProductReports,
  getProductTransferErrors,
  clearProductTransferErrors,
};
