import React from 'react';
import { useFormContext } from '@/Hooks/useFormWrapper';
import cn from 'classnames';
import { useLocalization } from '@/Hooks';
import { useRequired } from '@/Hooks/Validation';
import { RegistrationModel } from '@/Models';
import sharedStyles from '../../SignUp.scss';
import { SignInput } from '@/Components/Controls/SignInputs/SignInput';
import styles from './SignUpOrdersForm.scss';

type FormData = Pick<
  RegistrationModel,
  | 'nationalUninshuredShippingsCount'
  | 'nationalInshuredShippingsCount'
  | 'internationalUninshuredShippingsCount'
  | 'internationalInshuredShippingsCount'
>;

export function SignUpOrdersForm(): React.ReactElement {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormData>();

  const {
    authorizationPage: {
      SignUp: { OrdersForm },
    },
  } = useLocalization();

  const required = useRequired();

  return (
    <div className={cn(sharedStyles.signUpFormFields, styles.signUpOrdersCountFields)}>
      <h4 className={styles.signUpFormTitle}>{OrdersForm.HowManyOrdersDoYouExpectToSendAveragePerMonth}</h4>

      <p className={styles.signUpFormSubTitle}>{OrdersForm.UninsuredShipping}</p>
      <SignInput<FormData>
        name={'nationalUninshuredShippingsCount'}
        register={register}
        rules={required}
        placeholder={OrdersForm.National}
        error={errors}
        type={'number'}
      />

      <SignInput<FormData>
        name={'internationalUninshuredShippingsCount'}
        register={register}
        rules={required}
        placeholder={OrdersForm.International}
        error={errors}
        type={'number'}
      />

      <p className={styles.signUpFormSubTitle}>{OrdersForm.InsuredShipping}</p>
      <SignInput<FormData>
        name={'nationalInshuredShippingsCount'}
        register={register}
        rules={required}
        placeholder={OrdersForm.National}
        error={errors}
        type={'number'}
      />

      <SignInput<FormData>
        name={'internationalInshuredShippingsCount'}
        register={register}
        rules={required}
        placeholder={OrdersForm.International}
        error={errors}
        type={'number'}
      />
    </div>
  );
}
