import { useLocalization } from '@/Hooks';
import { ValidateResult } from 'react-hook-form';

export const useRequiredTreeList = (isRequired = true) => {
  const { validation } = useLocalization();

  return {
    validate: (values: any[]): ValidateResult | Promise<ValidateResult> =>
      isRequired ? (values && values.length > 0) || validation.Required : true,
  };
};
