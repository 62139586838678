import { createSlice } from '@reduxjs/toolkit';

export const LOCAL_STORAGE_THEME_KEY = 'ship_cheaper_theme';

export enum ThemeType {
  Light = 'light_theme',
  Dark = 'dark_theme',
}

export type ThemeState = {
  theme: ThemeType;
};

const initialState: ThemeState = {
  theme: (localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as ThemeType) || ThemeType.Light,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme(state) {
      const newTheme = state.theme === ThemeType.Dark ? ThemeType.Light : ThemeType.Dark;
      state.theme = newTheme;
      localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme);
    },
  },
});

export const themeReducer = themeSlice.reducer;
export const themeActions = themeSlice.actions;
