import { FeedbackSettingModel } from '@/Models';
import { Nullable } from '@/Types/Nullable';
import { createSlice } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';

export type FeedbackSettingsState = {
  feedbackSettings: Nullable<FeedbackSettingModel>;
};

const initialState: FeedbackSettingsState = {
  feedbackSettings: null,
};

const feedbackSettingsSlice = createSlice({
  name: 'feedbackSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getFeedbackSettings.fulfilled, (state, action) => {
        state.feedbackSettings = action.payload.feedbackSettingModel;
      })
      .addCase(asyncActions.updateFeedbackSettings.fulfilled, (state, action) => {
        state.feedbackSettings = action.payload.feedbackSettingModel;
      })
      .addCase(asyncActions.createFeedbackSettings.fulfilled, (state, action) => {
        state.feedbackSettings = action.payload.feedbackSettingModel;
      });
  },
});

export const feedbackSettingsReducer = feedbackSettingsSlice.reducer;
export const feedbackSettingsActions = feedbackSettingsSlice.actions;
export const feedbackSettingsAsyncActions = asyncActions;
