import React from 'react';
import styles from './MergeDiff.scss';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { ButtonsWrapper } from '@/Components/Forms/ButtonsSection/ButtonsSection';
import { useLocalization } from '@/Hooks';
import { MergeOrderDifferenceSources, OrdersMergeDiffResult } from '@/Api/MpOrders/Requests/MergeOrdersRequest';
import { Label } from '@/Components/Controls/Label/Label';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { MergeOrdersAddressSection } from './modules/MergeOrdersAddressSection/MergeOrdersAddressSection';
import { MergeOrdersAddressContactSection } from './modules/MergeOrdersAddressContactSection/MergeOrdersAddressContactSection';
import { useMergeDiffForm } from '@/ModalWindows/MergeOrdersModal/modules/MergeDiff/hooks/useMergeDiffForm';
import { MergeOrdersBuyerSection } from './modules/MergeOrdersBuyerSection/MergeOrdersBuyerSection';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { FormProvider } from '@/Hooks/useFormWrapper';
import { FormData } from './hooks/useMergeDiffForm';

type Props = {
  diff: OrdersMergeDiffResult;
  onSave: (data: MergeOrderDifferenceSources) => void;
  onCancel: () => void;
};

export function MergeDiff({ diff, onCancel, onSave }: Props) {
  const {
    buttons: { Ok, Cancel },
    modalWindows: {
      mergeOrders: { Description, MarketplaceAccountSection },
    },
  } = useLocalization();

  const orders = useSelector(salesPageSelectors.selectedSales);

  const {
    methods,
    onSubmit,
    options: { accountOptions },
    rules: { required },
  } = useMergeDiffForm(orders, onSave);

  return (
    <FormProvider {...methods}>
      <form className={styles.mergeDiffWrapper} onSubmit={onSubmit}>
        <div className={styles.modalDescription}>
          <h4>{Description.Title}</h4>
          <p>{Description.Text}</p>
        </div>

        <div className={styles.mergeDiffSections}>
          {!!diff.differentMpAccountOptions.length && (
            <div className={styles.mergeOrdersSection}>
              <Label text={MarketplaceAccountSection.Account} />
              <FormSelectInput<FormData>
                register={methods.register}
                rules={required}
                options={accountOptions}
                name={'accountId'}
              />
            </div>
          )}
          {!!diff.differentInvoiceContactInfoOptions.length && (
            <MergeOrdersAddressContactSection
              isInvoice={true}
              differentOrders={diff.differentInvoiceContactInfoOptions}
              orders={orders}
            />
          )}
          {!!diff.differentInvoiceAddressOptions.length && (
            <MergeOrdersAddressSection
              isInvoice={true}
              orders={orders}
              differentOrders={diff.differentInvoiceAddressOptions}
            />
          )}
          {!!diff.differentShippingContactInfoOptions.length && (
            <MergeOrdersAddressContactSection
              isInvoice={false}
              differentOrders={diff.differentShippingContactInfoOptions}
              orders={orders}
            />
          )}
          {!!diff.differentShippingAddressOptions.length && (
            <MergeOrdersAddressSection
              isInvoice={false}
              orders={orders}
              differentOrders={diff.differentShippingAddressOptions}
            />
          )}
          {!!diff.differentBuyerInfoOptions.length && (
            <MergeOrdersBuyerSection orders={orders} differentOrders={diff.differentBuyerInfoOptions} />
          )}
        </div>

        <ButtonsWrapper>
          <Button type={'button'} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={Cancel} onClick={onCancel} />
          <Button type={'submit'} theme={BUTTON_THEMES.SUCCESS} text={Ok} />
        </ButtonsWrapper>
      </form>
    </FormProvider>
  );
}
