import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetContactsRequest, ContactsApi, CreateContactRequest, UpdateContactRequest } from '@/Api/Contacts';

const getCustomers = createAsyncThunk('customersPage/get-customers', async (request: GetContactsRequest) => {
  return ContactsApi.getContacts(request);
});

const createCustomer = createAsyncThunk('customersPage/create-customer', async (request: CreateContactRequest) => {
  return ContactsApi.createContact(request);
});

const updateCustomer = createAsyncThunk('customersPage/update-customer', async (request: UpdateContactRequest) => {
  return ContactsApi.updateContact(request);
});

const deleteCustomer = createAsyncThunk('customersPage/delete-customer', async (request: number) => {
  return ContactsApi.deleteContact(request);
});

export const asyncActions = {
  getCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
};
