import { rest } from 'src/Api/Rest';
import { ApiRoutingUtils } from 'src/Routing';
import { GetUncompletedTransfersResponse } from './Responses/GetUncompletedTransfersResponse';
import { GetUncompletedTransfersRequest } from './Requests/GetUncompletedTransfersRequest';

const transferUrl = ApiRoutingUtils.createUrl('user-warehouse-uncompleted-transfer');

async function getUncompletedTransfers(
  request: GetUncompletedTransfersRequest,
): Promise<GetUncompletedTransfersResponse> {
  return rest.get(transferUrl, request);
}

export const UncompletedTransferApi = {
  getUncompletedTransfers,
};
