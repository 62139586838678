import { useEffect } from 'react';
import { CookieName } from '../Enums/CookieName';
import { useAppDispatch } from '../Redux/ConfigureStore';
import { cookieActions } from '../ModalWindows/CookieModal/services/reducer';
import { cookieUtils } from '../Utils/cookieUtils';

export const useCheckCookie = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const cookieAgreement = cookieUtils.getCookie(CookieName.CookieAgreement);

    if (!cookieAgreement) {
      dispatch(cookieActions.setModalVisible(true));
    } // if
  }, []);
};
