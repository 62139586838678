import { ApiRoutingUtils } from '@/Routing';
import { GetShopProductsResponse } from './Responses/GetProductsResponse';
import { rest } from '../Rest';
import { AddProductToCartResponse } from './Responses/AddProductToCartResponse';
import { AddProductToCartRequest } from './Requests/AddProductToCartRequest';
import { CreateShopOrderRequest } from './Requests/CreateShopOrderRequest';
import { CreateShopOrderResponse } from './Responses/CreateShopOrderResponse';
import { GetTemplatesForShopRequest } from '@/Api/Shop/Requests/GetTemplatesForShopRequest';
import { GetTemplatesForShopResponse } from '@/Api/Shop/Responses/GetTemplatesForShopResponse';

const url = ApiRoutingUtils.createUrl('shop');
const templateUrl = ApiRoutingUtils.createUrl('document-templates-for-shop');

const getShopProducts = (): Promise<GetShopProductsResponse> => {
  return rest.get(url);
};

const addProductToCart = (request: AddProductToCartRequest): Promise<AddProductToCartResponse> => {
  return rest.post(url, request);
};

const deleteProductFromCart = (request: number): Promise<number> => {
  return rest.delete(`${url}/${request}`, {});
};

const createOrderRequest = (request: CreateShopOrderRequest): Promise<CreateShopOrderResponse> => {
  return rest.post(`${url}/create-order`, request);
};

const getTemplatesForShopByCountry = (request: GetTemplatesForShopRequest): Promise<GetTemplatesForShopResponse> => {
  return rest.get(`${templateUrl}/by-country`, {
    country: request.countries,
  });
};

export const ShopApi = {
  getShopProducts,
  addProductToCart,
  deleteProductFromCart,
  createOrderRequest,
  getTemplatesForShopByCountry,
};
