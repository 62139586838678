import { createSlice } from '@reduxjs/toolkit';
import { CurrencyModel } from '@/Models/CurrencyModel';
import { asyncActions } from './asyncActions';

export type SelectCurrencyModalState = {
  currencies: CurrencyModel[];
};

const initialState: SelectCurrencyModalState = {
  currencies: [],
};

const selectCurrencyModalSlice = createSlice({
  name: 'selectCurrencyModal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getCurrencies.fulfilled, (state, action) => {
      state.currencies = action.payload.sort((a, b) => a.title.localeCompare(b.title));
    });
  },
});

const { actions, reducer } = selectCurrencyModalSlice;

export const selectCurrencyModalReducer = reducer;
export const selectCurrencyModalActions = actions;
export const selectCurrencyModalAsyncActions = asyncActions;
