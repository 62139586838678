import { CountryCode } from '@/Enums';
import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { countryZoneUtils } from './utils';

const generatorCountryZoneStateSelector = (state: RootState) => state.warehouseLogisticsPage.countryZones;
const countryZones = createSelector(generatorCountryZoneStateSelector, (state) => state.countryZones || []);
const countryZoneById = (countryZoneId: string | number) =>
  createSelector(generatorCountryZoneStateSelector, (state) => {
    return countryZoneUtils.countryZoneById(countryZoneId, state.countryZones);
  });

const selectedCountryCode = createSelector(generatorCountryZoneStateSelector, (state) => state.selectedCountryCode);
const selectedCountryZone = createSelector(generatorCountryZoneStateSelector, (state) =>
  state.countryZones.find((x) => x.country === state.selectedCountryCode),
);

const modalWindowType = createSelector(generatorCountryZoneStateSelector, (state) => state.modalWindowType);

const logisticTypeOptions = createSelector(generatorCountryZoneStateSelector, (state) => state.logisticTypeOptions);

const availableLogisticTypeOptions = (country: CountryCode) =>
  createSelector(generatorCountryZoneStateSelector, (state) => {
    const countryZone = state.countryZones.find((x) => x.country === country);
    if (countryZone) {
      return state.logisticTypeOptions.filter((x) => countryZone.logistics.every((y) => y.logisticId != x.key));
    }
    return state.logisticTypeOptions;
  });

export const generatorCountryZoneSelectors = {
  countryZones,
  countryZoneById,
  modalWindowType,
  logisticTypeOptions,
  availableLogisticTypeOptions,
  selectedCountryCode,
  selectedCountryZone,
};
