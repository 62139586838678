import { useEffect, useState } from 'react';
import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { OrderMarker } from '@/Enums';
import { localizationUtils } from '@/Utils/localizationUtils';
import { useSelector } from 'react-redux';
import { globalSelectors } from '@/Redux/Global/selectors';
import { useLocalization } from '@/Hooks';

export const useAllShippedBodyWarnings = (orderIds: number[]) => {
  const { warnings: warningTranslations } = useLocalization();

  const [warnings, setWarnings] = useState<string[]>([]);
  const localization = useSelector(globalSelectors.localization);

  useEffect(() => {
    MpOrdersMarkingApi.checkOrdersCanBeMarked({ orderIds: orderIds, orderMarker: OrderMarker.NotShipped }).then(
      (response) => {
        if (!response.errors) {
          setWarnings(
            response.warnings.map((warn) => {
              const warningMessage = warningTranslations[warn.warningCode as keyof typeof warningTranslations]?.message;
              const parts = warningTranslations[warn.warningCode as keyof typeof warningTranslations]?.parts;
              return localizationUtils.insertTranslatedParamsToString(
                warningMessage,
                warn.localizationParams,
                parts,
                localization,
              );
            }),
          );
        }
      },
    );
  }, [orderIds]);

  return {
    warnings,
  };
};
