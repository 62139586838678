import { useLocalization } from '@/Hooks';
import { userSelectors } from '@/Redux/User';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

type Props = {
  needTimePicker: boolean;
};

export const useDaterangePickerLocale = (props: Props) => {
  const {
    buttons,
    enums: { DateRangeType },
    common: { DaysOfWeek, Months },
  } = useLocalization();

  const { timeFormat, dateFormat, firstDayOfWeek } = useSelector(userSelectors.regionalSettings);

  return useMemo(() => {
    const { needTimePicker } = props;

    return {
      customRangeLabel: DateRangeType.Custom,
      cancelLabel: buttons.Clear,
      applyLabel: buttons.Ok,
      firstDay: Number(firstDayOfWeek?.value ?? 1),
      format: `${dateFormat?.value ?? ''}${needTimePicker ? ` ${timeFormat?.value}` : ''}`,
      daysOfWeek: [
        DaysOfWeek.Sunday,
        DaysOfWeek.Monday,
        DaysOfWeek.Tuesday,
        DaysOfWeek.Wednesday,
        DaysOfWeek.Thursday,
        DaysOfWeek.Friday,
        DaysOfWeek.Saturday,
      ],
      monthNames: [
        Months.January,
        Months.February,
        Months.March,
        Months.April,
        Months.May,
        Months.June,
        Months.July,
        Months.August,
        Months.September,
        Months.October,
        Months.November,
        Months.December,
      ],
    };
  }, [buttons, DaysOfWeek, Months, timeFormat, dateFormat, firstDayOfWeek, props.needTimePicker]);
};
