import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';
import { useDispatch } from 'react-redux';
import { userAccountSettingsActions } from '@/Pages/Settings/modules/AccountSettings/services/reducer';

export function useAccountSettings() {
  const dispatch = useDispatch();

  const tour1: TourStep[] = [
    {
      content: (
        <div>
          Региональные настройки - здесь вы устанавливаете тайм зону, выбираете формат дат и чисел в соответствии с
          региональными особенностями ведения бизнеса в вашей стране, а также выбираете валюту по умолчанию
        </div>
      ),
      placement: 'auto',
      target: '.regionalSettingsBtn',
      title: 'Региональные настройки',
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: (
        <div>
          Здесь вы выбираете способы оплаты, отвечающие ожиданиям и потребностям ваших клиентов, что будет
          способствовать развитию бизнеса, повышению продаж и улучшению конкурентных позиций
        </div>
      ),
      placement: 'auto',
      target: '.paymentMethodsBtn',
      title: 'Способы оплаты',
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: (
        <div>
          Базовые настройки - здесь вы можете выбрать язык и поменять пароль. E-mail устанавливается автоматически при
          первичной регистрации
        </div>
      ),
      placement: 'auto',
      target: '.basicSettings',
      title: 'Базовые настройки',
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: (
        <div>
          Автозаполняемые данные. Значение полям (Код рынка, Страна, Номер покупателя) присваивается автоматически при
          первичной регистрации пользователя
        </div>
      ),
      placement: 'auto',
      target: '.additionalSettings',
      title: 'Автозаполняемые данные',
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: (
        <div>
          Редактируемые настройки - здесь вы можете изменить код плательщика НДС, номер заказа и E-mail для счетов.
          Нажмите на значок и введите соответствующие данные в поле.
        </div>
      ),
      placement: 'auto',
      target: '.additionalEditableSettings',
      title: 'Редактируемые настройки',
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: (
        <div>
          Основные параметры и жизненный цикл учетной записи - значение полям присваивается автоматически при первичной
          регистрации пользователя
        </div>
      ),
      placement: 'auto',
      target: '.basicParameters',
      title: 'Жизненный цикл учетной записи',
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(userAccountSettingsActions.setRegionalSettingModal(false)),
    },
    {
      content: (
        <div>
          Для обеспечивает эффективного управление временем и правильным отображение данных - выберите из выпадающего
          списка нужный формат времени, даты, а также тайм-зону
        </div>
      ),
      placement: 'auto',
      target: '.basicTimezoneSettings',
      title: 'Региональные настройки',
      disableBeacon: true,
      link: 'https://www.google.com/',
      callback: () => dispatch(userAccountSettingsActions.setRegionalSettingModal(true)),
    },
    {
      content: (
        <div>
          Для правильного учета финансовых операций, а также для обеспечения эффективного планирования и управления
          рабочими процессами, выберите из выпадающего списка значение начала рабочего года, недели, финансово года, а
          также установите формат числа
        </div>
      ),
      placement: 'auto',
      target: '.extraTimezoneSettings',
      title: 'Региональные настройки',
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
    {
      content: (
        <div>
          В данной таблице вы можете добавлять, удалять и выбирать валюту по умолчанию из списка. Так же здесь можно
          управлять списком валют используя поиск и фильтр
        </div>
      ),
      placement: 'auto',
      target: '.currencyTable',
      title: 'Региональные настройки',
      disableBeacon: true,
      link: 'https://www.google.com/',
    },
  ];

  return {
    tour1,
  };
}
