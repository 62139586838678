import styles from './DeliveryInfo.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { DeliveryStatusIcons } from '@/Pages/Logistics/modules/DeliveryStatusIcons/DeliveryStatusIcons';

export function DeliveryInfo() {
  const selectedSale = useSelector(salesPageSelectors.selectedSale);

  const isStatusNotUndefinedAndEmpty = selectedSale?.shippingStatuses?.length;

  return (
    <div style={{ padding: 0 }} className={styles.section}>
      {isStatusNotUndefinedAndEmpty && (
        <DeliveryStatusIcons isFirst={true} deliveryProgressInfo={selectedSale.shippingStatuses} />
      )}
    </div>
  );
}
