import { createSlice } from '@reduxjs/toolkit';
import { Nullable } from 'src/Types';
import { UserWarehouseUnitWithPostingsModel } from 'src/Models/Warehouse/UserWarehouseUnitWithPostingsModel';
import { UserWarehousePostingBatchModel } from 'src/Models/Warehouse/UserWarehousePostingBatchModel';
import { fulfillmentTransferAsyncActions } from './asyncActions';
import { UserWarehouseShortModel } from 'src/Models/Warehouse/UserWarehouseModel';

export type FulfillmentTransferState = {
  unitInfo: Nullable<UserWarehouseUnitWithPostingsModel>;
  batchInfo: Nullable<UserWarehousePostingBatchModel>;
  availableOutWarehouses: UserWarehouseShortModel[];
};

const initialState: FulfillmentTransferState = {
  unitInfo: null,
  batchInfo: null,
  availableOutWarehouses: [],
};

export const { actions: fulfillmentTransferActions, reducer: fulfillmentTransferReducer } = createSlice({
  name: 'fulfillment-transfer',
  initialState,
  reducers: {
    clean: (state) => {
      state.batchInfo = null;
      state.unitInfo = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fulfillmentTransferAsyncActions.scanBarcode.fulfilled, (state, action) => {
        state.unitInfo = action.payload.transferState.unitInfo;
        state.batchInfo = action.payload.transferState.batchInfo;
      })
      .addCase(fulfillmentTransferAsyncActions.createBatch.fulfilled, (state, action) => {
        state.batchInfo = {
          state: action.payload.state,
          id: action.payload.batchId,
          postings: [],
        };
      })
      .addCase(fulfillmentTransferAsyncActions.getBatch.fulfilled, (state, action) => {
        state.unitInfo = action.payload.transferState.unitInfo;
        state.batchInfo = action.payload.transferState.batchInfo;
      })
      .addCase(fulfillmentTransferAsyncActions.getAvailableWarehouses.fulfilled, (state, action) => {
        state.availableOutWarehouses = action.payload.warehouses;
      })
      .addCase(fulfillmentTransferAsyncActions.setBatchState.fulfilled, (state, action) => {
        if (state.batchInfo) {
          state.batchInfo.state = action.payload.newState;
        }
      });
  },
});
