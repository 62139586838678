import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalType } from '@/Enums';
import { combineReducers } from 'redux';
import { DocumentTemplatePrintType } from '@/Models';
import { PagesRouting } from '@/Routing/PagesRouting';
import { shippingLabelModalReducer, ShippingLabelModalState } from '@/ModalWindows/ShippingLabelModal/services';
import { documentPrintModalReducer, DocumentPrintModalState } from '@/ModalWindows/DocumentPrintModal/services/reducer';
import {
  OrderAutomatizationModalState,
  orderAutomatizationReducer,
} from '../OrderAutomatizationModal/services/reducer';
import { selectContactModalReducer, SelectContactModalState } from '../SelectContactModal/services/reducer';
import { OrderLabelPrintModalState, orderLabelPrintReducer } from '../OrderLabelPrintModal/services';
import {
  generateDocumentNumberConfirmationModalReducer,
  GenerateDocumentNumberConfirmationModalState,
} from '@/ModalWindows/GenerateDocumentNumberConfirmationModal/services/reducer';
import { selectCurrencyModalReducer, SelectCurrencyModalState } from '../SelectCurrencyModal/services/reducer';
import { splitOrdersModalReducer, SplitOrdersModalState } from '@/ModalWindows/SplitOrdersModal/services/reducer';
import { emailSendModalReducer, EmailSendModalState } from '../EmailSendModal/services/reducer';
import {
  synchOrdersCompleteModalReducer,
  SynchOrdersCompleteModalState,
} from '../SynchOrdersCompleteModal/services/reducer';
import { cookieReducer, CookieState } from '@/ModalWindows/CookieModal/services/reducer';

export const WINDOW_MODAL_TYPE: Record<string, DocumentTemplatePrintType> = {
  [PagesRouting.MainPages.SalesPage.Sales]: DocumentTemplatePrintType.Sales,
  [PagesRouting.MainPages.PurchasesPage]: DocumentTemplatePrintType.Purchases,
  [PagesRouting.MainPages.CustomersPage.Customers]: DocumentTemplatePrintType.Customer,
};

export type SharedModalWindowsState = {
  modalType: ModalType;
  params?: string[];
};

const initialState: SharedModalWindowsState = {
  modalType: ModalType.None,
  params: [],
};

export type ModalData = {
  modalType: ModalType;
  params?: string[];
};

const modalWindowsSlice = createSlice({
  name: 'modalWindowsReducer',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<ModalData>) {
      state.modalType = action.payload.modalType;
      state.params = action.payload.params;
    },
    closeModal(state) {
      state.modalType = ModalType.None;
    },
  },
});

export const modalWindowsActions = modalWindowsSlice.actions;

export type ModalWindowsState = {
  shared: SharedModalWindowsState;
  documentPrintModalState: DocumentPrintModalState;
  shippingLabelModalState: ShippingLabelModalState;
  selectContactModalState: SelectContactModalState;
  orderAutomatizationState: OrderAutomatizationModalState;
  orderLabelPrintModalState: OrderLabelPrintModalState;
  generateDocumentNumbersConfirmationModalState: GenerateDocumentNumberConfirmationModalState;
  selectCurrencyModalState: SelectCurrencyModalState;
  splitOrdersModalState: SplitOrdersModalState;
  emailSendModalState: EmailSendModalState;
  synchOrdersCompleteModalState: SynchOrdersCompleteModalState;
  cookieState: CookieState;
};

export const modalWindowsReducer = combineReducers<ModalWindowsState>({
  shared: modalWindowsSlice.reducer,
  documentPrintModalState: documentPrintModalReducer,
  shippingLabelModalState: shippingLabelModalReducer,
  selectContactModalState: selectContactModalReducer,
  orderAutomatizationState: orderAutomatizationReducer,
  orderLabelPrintModalState: orderLabelPrintReducer,
  generateDocumentNumbersConfirmationModalState: generateDocumentNumberConfirmationModalReducer,
  selectCurrencyModalState: selectCurrencyModalReducer,
  splitOrdersModalState: splitOrdersModalReducer,
  emailSendModalState: emailSendModalReducer,
  synchOrdersCompleteModalState: synchOrdersCompleteModalReducer,
  cookieState: cookieReducer,
});
