import { createSelector } from '@reduxjs/toolkit';
import { modalWindowsSelectors } from '@/ModalWindows/services';

const root = createSelector(
  modalWindowsSelectors.modalWindows,
  (modalWindowsState) => modalWindowsState.splitOrdersModalState,
);

const order = createSelector(root, (state) => state.order);
const newOrders = createSelector(root, (state) => state.newOrders);

export const splitOrdersModalSelectors = {
  order,
  newOrders,
};
