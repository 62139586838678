import { createSlice } from '@reduxjs/toolkit';
import { translationsEditorReducerPart } from '../../../services/TranslationsEditor/reducerPart';
import { asyncActions } from './asyncActions';
import { editorSettingsAsyncActions } from '@/Pages/Administration/LanguageEditor/modules/EditorSettings/services/asyncActions';
const { initialState, actions } = translationsEditorReducerPart;

const websiteTranslationsEditorSlice = createSlice({
  name: 'languageEditorPage/website-translations',
  initialState,
  reducers: actions,
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.load.fulfilled, (state, action) => {
        state.translations.items = action.payload.translations;
        state.translations.action = {
          state: 'load',
          param: null,
        };
      })
      .addCase(asyncActions.update.fulfilled, (state) => {
        state.translations.isModified = false;
        state.translations.updatedItems = [];
      })
      .addCase(asyncActions.syncTranslations.fulfilled, (state, action) => {
        const { translations, updatedTranslations, updatedPaths } = action.payload;
        state.translations.items = translations;
        state.translations.updatedItems = updatedTranslations.map((updatedTranslation) => ({
          ...updatedTranslation,
          path: updatedTranslation.id,
          l_a: updatedTranslation.id,
          description: updatedTranslation.description ?? '',
          en: updatedTranslation.en ?? '',
          de: updatedTranslation.de ?? '',
          ru: updatedTranslation.ru ?? '',
        }));

        state.translations.action = {
          state: 'sync',
          param: {
            paths: updatedPaths,
          },
        };

        state.translations.isModified = !!updatedPaths.length;
        console.log('is modified', state.translations.isModified);
      })
      .addCase(editorSettingsAsyncActions.getTranslationSetting.fulfilled, (state, action) => {
        state.languageEditorSettings = action.payload.setting;
      })
      .addCase(editorSettingsAsyncActions.updateTranslationSetting.fulfilled, (state, action) => {
        state.languageEditorSettings = action.payload.setting;
      });
  },
});

export const websiteTranslationsEditorActions = websiteTranslationsEditorSlice.actions;
export const websiteTranslationsEditorReducer = websiteTranslationsEditorSlice.reducer;
export const websiteTranslationsEditorAsyncActions = asyncActions;
