import { ApiRoutingUtils } from '@/Routing';
import { rest } from '../Rest';
import { GetEmailPreviewForOrderRequest } from './GetEmailPreviewForOrderRequest';
import { GetEmailPreviewForOrderResponse } from './GetEmailPreviewForOrderResponse';
import { SendEmailForOrdersRequest } from './SendEmailForOrdersRequest';
import { SendEmailForOrdersResponse } from './SendEmailForOrdersResponse';
import { SendFormattedEmailForOrderRequest } from './SendFormattedEmailForOrderRequest';
import { SendFormattedEmailForOrderResponse } from './SendFormattedEmailForOrderResponse';
import { CheckEmailCredentialsRequest } from 'src/Api/Email/CheckEmailCredentialsRequest';
import { CheckEmailCredentialsResponse } from 'src/Api/Email/CheckEmailCredentialsResponse';

const url = ApiRoutingUtils.createUrl('email');

const sendEmailForOrders = (request: SendEmailForOrdersRequest): Promise<SendEmailForOrdersResponse> => {
  return rest.post(`${url}/send-email-for-orders`, request);
};

const sendFormatterEmailForOrder = (
  request: SendFormattedEmailForOrderRequest,
): Promise<SendFormattedEmailForOrderResponse> => {
  return rest.post(`${url}/send-formatted-email-for-order`, request);
};

const getEmailPreviewForOrder = (request: GetEmailPreviewForOrderRequest): Promise<GetEmailPreviewForOrderResponse> => {
  return rest.post(`${url}/get-email-preview-for-order`, request);
};

const checkEmailCredentials = (request: CheckEmailCredentialsRequest): Promise<CheckEmailCredentialsResponse> => {
  return rest.post(`${url}/check-email-credentials`, request);
};

export const EmailApi = {
  sendEmailForOrders,
  sendFormatterEmailForOrder,
  getEmailPreviewForOrder,
  checkEmailCredentials,
};
