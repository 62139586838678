import { useMemo } from 'react';
import moment from 'moment';
import { CustomDateRange } from '@/Components/Controls/DateInput/types';
import { TranslationType } from '@/Localization/LanguageKeys';
import { useSelector } from 'react-redux';
import { globalSelectors } from '@/Redux/Global/selectors';

const orderDateRanges = (localization: TranslationType) => {
  const now = new Date();

  return {
    [localization.Enums.DateRangeType.Last30Days]: [
      moment(now).subtract(30, 'days').startOf('day'),
      moment(now).endOf('day'),
    ],
    [localization.Enums.DateRangeType.Last90Days]: [
      moment(now).subtract(90, 'days').startOf('day'),
      moment(now).endOf('day'),
    ],
    [localization.Enums.DateRangeType.Today]: [moment(now).startOf('day'), moment(now).endOf('day')],
    [localization.Enums.DateRangeType.Yesterday]: [
      moment(now).subtract(1, 'day').startOf('day'),
      moment(now).subtract(1, 'day').endOf('day'),
    ],
    [localization.Enums.DateRangeType.ThisWeek]: [moment(now).startOf('week'), moment(now).endOf('week')],
    [localization.Enums.DateRangeType.LastWeek]: [
      moment(now).subtract(1, 'week').startOf('week'),
      moment(now).subtract(1, 'week').endOf('week'),
    ],
    [localization.Enums.DateRangeType.ThisMonth]: [moment(now).startOf('month'), moment(now).endOf('month')],
    [localization.Enums.DateRangeType.LastMonth]: [
      moment(now).subtract(1, 'month').startOf('month'),
      moment(now).subtract(1, 'month').endOf('month'),
    ],
    [localization.Enums.DateRangeType.ThisQuartal]: [moment().startOf('quarter'), moment(now).endOf('day')],
    [localization.Enums.DateRangeType.LastQuartal]: [
      moment(now).subtract(1, 'quarter').startOf('quarter'),
      moment(now).subtract(1, 'quarter').endOf('quarter'),
    ],
    [localization.Enums.DateRangeType.ThisYear]: [moment(now).startOf('year'), moment(now).endOf('year')],
    [localization.Enums.DateRangeType.LastYear]: [
      moment(now).subtract(1, 'year').startOf('year'),
      moment(now).subtract(1, 'year').endOf('year'),
    ],
  } as CustomDateRange;
};

const defaultDateRanges = (localization: TranslationType) => {
  const now = new Date();

  return {
    [localization.Enums.DateRangeType.Today]: [moment(now).startOf('day'), moment(now).endOf('day')],
    [localization.Enums.DateRangeType.Yesterday]: [
      moment(now).subtract(1, 'days').startOf('day'),
      moment(now).subtract(1, 'days').endOf('day'),
    ],
    [localization.Enums.DateRangeType.ThisWeek]: [moment(now).startOf('week'), moment(now).endOf('week')],
    [localization.Enums.DateRangeType.LastWeek]: [
      moment(now).subtract(1, 'week').startOf('week'),
      moment(now).subtract(1, 'week').endOf('week'),
    ],
    [localization.Enums.DateRangeType.ThisMonth]: [moment(now).startOf('month'), moment(now).endOf('month')],
    [localization.Enums.DateRangeType.LastMonth]: [
      moment(now).subtract(1, 'month').startOf('month'),
      moment(now).subtract(1, 'month').endOf('month'),
    ],
    [localization.Enums.DateRangeType.ThisYear]: [moment(now).startOf('year'), moment(now).endOf('year')],
    [localization.Enums.DateRangeType.LastYear]: [
      moment(now).subtract(1, 'year').startOf('year'),
      moment(now).subtract(1, 'year').endOf('year'),
    ],
  } as CustomDateRange;
};

export const useDaterangePickerRanges = (rangesType?: 'orderDateRanges' | 'defaultDateRanges') => {
  const translation = useSelector(globalSelectors.translation);

  return useMemo(() => {
    switch (rangesType) {
      case 'orderDateRanges':
        return orderDateRanges(translation);

      case 'defaultDateRanges':
        return defaultDateRanges(translation);

      default:
        return;
    } // switch
  }, [rangesType, translation]);
};
