import { OrderFilterModel } from '@/Types';
import { FormData } from './usePurchaseTableFiltersForm';
import { UseFormGetValues } from 'react-hook-form';
import { useEffect } from 'react';

export const usePurchaseTableFiltersUpdate = (
  onSubmit: () => Promise<void>,
  initialOrderFilters: OrderFilterModel,
  watch: (key: string) => any,
  getValues: UseFormGetValues<FormData>,
  setIsFilterChanged: (value: boolean) => void,
) => {
  const isFilterChanged = (initialOrderFilters: OrderFilterModel, newOrderFilters: FormData): boolean => {
    const timeFrom = newOrderFilters.dateRangeTuple.split(' - ')[0];
    const timeTo = newOrderFilters.dateRangeTuple.split(' - ')[1];

    const isDateRangeEqual =
      initialOrderFilters.dateRange.timeFrom === timeFrom && initialOrderFilters.dateRange.timeTo === timeTo;

    let isMpAccountIdsEqual = initialOrderFilters.mpAccountIds?.length === newOrderFilters.mpAccountIds?.length;
    if (isMpAccountIdsEqual) {
      for (let i = 0; i < (newOrderFilters.mpAccountIds?.length ?? 0); i++) {
        isMpAccountIdsEqual = initialOrderFilters.mpAccountIds?.[i] === newOrderFilters.mpAccountIds?.[i];
        if (!isMpAccountIdsEqual) break;
      } // for
    } // if

    const isOrderStatusEqual = initialOrderFilters.orderStatusFilter === newOrderFilters.orderStatusFilter;

    return !isDateRangeEqual || !isMpAccountIdsEqual || !isOrderStatusEqual;
  };

  const OrderStatusFilter = watch('orderStatusFilter');
  const OrderMpAccountIds = watch('mpAccountIds');
  const OrderDateRangeTuple = watch('dateRangeTuple');

  useEffect(() => {
    const newOrderFilters = getValues();
    setIsFilterChanged(isFilterChanged(initialOrderFilters, newOrderFilters));
  }, [OrderStatusFilter, OrderMpAccountIds, OrderDateRangeTuple]);

  return {
    executeTask: onSubmit,
  };
};
