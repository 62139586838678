import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllGeneratorCountryZoneResponse } from '@/Api/GeneratorCountryZones';
import { GeneratorCountryZonesApi } from '@/Api/GeneratorCountryZones/GeneratorCountryZonesApi';

const getGeneratorCountryZonesRequest = createAsyncThunk<GetAllGeneratorCountryZoneResponse>(
  'warehouseLogisticsPageState/get-generator-country-zone',
  async () => {
    return GeneratorCountryZonesApi.getGeneratorCountryZones();
  },
);

export const warehouseLogisticsPageAsyncActions = {
  getGeneratorCountryZonesRequest,
};
