import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetContactsRequest, ContactsApi, CreateContactRequest, UpdateContactRequest } from '@/Api/Contacts';

const getSuppliers = createAsyncThunk('suppliersPage/get-suppliers', async (request: GetContactsRequest) => {
  return ContactsApi.getContacts(request);
});

const createSupplier = createAsyncThunk('suppliersPage/create-supplier', async (request: CreateContactRequest) => {
  return ContactsApi.createContact(request);
});

const updateSupplier = createAsyncThunk('suppliersPage/update-supplier', async (request: UpdateContactRequest) => {
  return ContactsApi.updateContact(request);
});

const deleteSupplier = createAsyncThunk('suppliersPage/delete-supplier', async (request: number) => {
  return ContactsApi.deleteContact(request);
});

export const asyncActions = {
  getSuppliers,
  createSupplier,
  updateSupplier,
  deleteSupplier,
};
