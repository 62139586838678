import FakeShipping from './Images/ShippingFake.png';
import FakeReturn from './Images/ReturnFake.png';
import Frog from './Images/Frog.png';
import Dpd from './Images/dpd.png';
import Ebay from './Images/ebay.png';
import ShipCheaper from './Images/shipcheaper.png';
import ScanerScreen from './Images/scanerScreen.jpg';
import Amazon from './Images/Amazon_logo.svg';
import Kaufland from './Images/Kaufland_Logo.svg';
import DeutschePost from './Images/Deutsche_Post.svg';
import Dhl from './Images/DHL_Logo.svg';
import Gls from './Images/GLS_Logo_2021.svg';
import Ups from './Images/United_Parcel_Service_logo_2014.svg';
import DhlExpress from './Images/DHL_Express_logo.svg';
import Ozon from './Images/Ozon_Logo.svg';
import ImagePlaceholder from './Images/imagePlaceholder.png';

export const Images = {
  FakeShipping,
  FakeReturn,
  Frog,
  Dpd,
  Ebay,
  ShipCheaper,
  ScanerScreen,
  Amazon,
  Kaufland,
  DeutschePost,
  Dhl,
  Gls,
  Ups,
  DhlExpress,
  Ozon,
  ImagePlaceholder,
};
