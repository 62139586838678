import { ApiRouting } from '@/Routing';
import { BaseNotificationHub } from '@/SignalR/Hubs/BaseNotificationHub';

export class OnlineArchiveNotificationHub extends BaseNotificationHub {
  private static instance = new OnlineArchiveNotificationHub();

  constructor() {
    super(ApiRouting.Hubs.OnlineArchiveNotificationHubRoute);

    if (OnlineArchiveNotificationHub.instance) {
      return OnlineArchiveNotificationHub.instance;
    }

    OnlineArchiveNotificationHub.instance = this;
  }
}
