import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateDynamicColumnResponse } from '@/Api/Products/ProductDynamicColumn/Responses/UpdateDynamicColumnResponse';
import { UpdateDynamicColumnRequest } from '@/Api/Products/ProductDynamicColumn/Requests/UpdateDynamicColumnRequest';
import { CreateDynamicColumnResponse } from '@/Api/Products/ProductDynamicColumn/Responses/CreateDynamicColumnResponse';
import { CreateDynamicColumnRequest } from '@/Api/Products/ProductDynamicColumn/Requests/CreateDynamicColumnRequest';
import { GetDynamicColumnsResponse } from '@/Api/Products/ProductDynamicColumn/Responses/GetDynamicColumnsResponse';
import { ProductDynamicColumnsApi } from '@/Api/Products/ProductDynamicColumn/ProductDynamicColumnsApi';
import { AddColumnsToSelectedProductsRequest } from '@/Api/Products/ProductDynamicColumn/Requests/AddColumnsToSelectedProductsRequest';
import { DeleteDynamicColumnResponse } from '@/Api/Products/ProductDynamicColumn/Responses/DeleteDynamicColumnResponse';

const getDynamicColumns = createAsyncThunk<GetDynamicColumnsResponse>('productsPage/get-dynamic-columns', async () => {
  return ProductDynamicColumnsApi.getDynamicColumns();
});

const createDynamicColumn = createAsyncThunk<CreateDynamicColumnResponse, CreateDynamicColumnRequest>(
  'productsPage/create-dynamic-column',
  async (request) => {
    return ProductDynamicColumnsApi.createDynamicColumn(request);
  },
);

const updateDynamicColumn = createAsyncThunk<UpdateDynamicColumnResponse, UpdateDynamicColumnRequest>(
  'productsPage/update-dynamic-column',
  async (request) => {
    return ProductDynamicColumnsApi.updateDynamicColumn(request);
  },
);

const deleteDynamicColumn = createAsyncThunk<DeleteDynamicColumnResponse, number>(
  'productsPage/delete-dynamic-column',
  async (request) => {
    return ProductDynamicColumnsApi.deleteDynamicColumn(request);
  },
);

const addColumnsToSelectedProducts = createAsyncThunk<null, AddColumnsToSelectedProductsRequest>(
  'productsPage/add-columns-to-products',
  async (request) => {
    return ProductDynamicColumnsApi.addColumnsToSelectedProducts(request);
  },
);

export const asyncActions = {
  getDynamicColumns,
  createDynamicColumn,
  updateDynamicColumn,
  deleteDynamicColumn,
  addColumnsToSelectedProducts,
};
