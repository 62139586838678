import React, { PropsWithChildren } from 'react';
import { ClassNameProps } from '@/Types';
import styles from './TabPanel.scss';
import cn from 'classnames';

type Props = {
  isActive: boolean;
} & ClassNameProps;

export function TabsPanel({ className, children, isActive }: PropsWithChildren<Props>) {
  if (!isActive) return null;

  return <div className={cn(styles.tabsPanel, className)}>{children}</div>;
}
