import { useEffect, useState } from 'react';
import { Breakpoints } from '@/Enums/Breakpoints';

export const useWindowResize = () => {
  const [isMobileSize, setIsMobileSize] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > Breakpoints.Tablet) {
        setIsMobileSize(() => false);
      } else {
        setIsMobileSize(() => true);
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobileSize,
  };
};
