import { useFormContext } from '@/Hooks/useFormWrapper';
import { useRequired } from '@/Hooks/Validation';
import { FormData } from '@/ModalWindows/OrderAutomatizationModal/hooks/useOrderAutomatizationModalForm';
import { useFieldArray } from 'react-hook-form';

export const useShipCheaperLogisticForm = (rowIndex: number) => {
  const {
    register,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useFormContext<FormData>();

  const required = useRequired();

  const packages = watch(`prepareLabels.${rowIndex}.packages`);
  const { append, remove, fields } = useFieldArray({
    name: `prepareLabels.${rowIndex}.packages`,
    control: control,
    keyName: 'array_id',
  });
  const selectedPackages = fields.map((field, idx) => ({
    ...field,
    ...packages[idx],
  }));

  return {
    methods: {
      register,
      setValue,
      errors,
      watch,
      getValues,
    },
    rules: {
      required,
    },
    selectedPackages: {
      array: selectedPackages,
      append,
      remove,
    },
  };
};
