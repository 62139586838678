import { TranslationType } from '@/Localization/LanguageKeys';
import { ReportInfoModel } from '@/Models/_Microservices/OnlineArchive/ReportInfoModel';
import { ApiRoutingUtils } from '@/Routing';
import { ReportTableRow } from '@/Types/_Microservices/OnlineArchive';
import { fileUtils } from '@/Utils';

const modelToTableRow = (model: ReportInfoModel, translation: TranslationType): ReportTableRow => {
  return {
    id: model.id,
    title: model.title,
    createTime: model.createTime,
    validTime: model.validTime,
    documentsCount: model.documentsCount ?? '-',
    fileSize: model.fileSize ? fileUtils.getFileSizeString(model.fileSize, translation.Common.FileSizes) : '-',
    downloadLink: model.fileSize
      ? `${ApiRoutingUtils.createOnlineArchiveFilesUrl('reports', model.title, 'load')}/${model.id}`
      : undefined,
    printStatus: model.printStatus,
    packageTrackingId: model.packageTrackingId ?? '-',
    mandatName: model.mandatName,
  };
};

export const reportInfoMapping = {
  modelToTableRow,
};
