const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}`;
};

const clearCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

const getCookie = (name: string) => {
  const regex = new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)');
  const matches = regex.exec(document.cookie);

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const cookieUtils = {
  setCookie,
  getCookie,
  clearCookie,
};
