import { rest } from 'src/Api/Rest';
import { ApiRoutingUtils } from 'src/Routing';
import { GetUnshippedOrdersRequest } from './Requests/GetUnshippedOrdersRequest';
import { GetUnshippedOrdersResponse } from './Responses/GetUnshippedOrdersResponse';
import { GetProductWarehouseUnitsResponse } from './Responses/GetProductWarehouseUnitsResponse';
import { GetProductWarehouseUnitsRequest } from './Requests/GetProductWarehouseUnitsRequest';
import { ScanBarcodeCustomerTransferRequest } from './Requests/ScanBarcodeCustomerTransferRequest';
import { ScanBarcodeCustomerTransferResponse } from './Responses/ScanBarcodeCustomerTransferResponse';
import { RemoveProductFromBatchRequest } from './Requests/RemoveProductFromBatchRequest';
import { RemoveProductFromBatchResponse } from './Responses/RemoveProductFromBatchResponse';
import { GetCustomerTransferBatchRequest } from '@/Api/UserWarehouse/Transfers/Customer/Requests/GetCustomerTransferBatchRequest';
import { GetCustomerTransferBatchResponse } from '@/Api/UserWarehouse/Transfers/Customer/Responses/GetCustomerTransferBatchResponse';

const transferBatchUrl = ApiRoutingUtils.createUrl('user-warehouse-customer-transfer');

async function getUnshippedOrders(request: GetUnshippedOrdersRequest): Promise<GetUnshippedOrdersResponse> {
  return rest.get(`${transferBatchUrl}/unshipped-orders`, request);
}

async function getProductUnits(request: GetProductWarehouseUnitsRequest): Promise<GetProductWarehouseUnitsResponse> {
  return rest.get(`${transferBatchUrl}/product-units`, request);
}

async function scanBarcode(request: ScanBarcodeCustomerTransferRequest): Promise<ScanBarcodeCustomerTransferResponse> {
  return rest.post(`${transferBatchUrl}/scan-barcode`, request);
}

async function removeProductFromBatch(request: RemoveProductFromBatchRequest): Promise<RemoveProductFromBatchResponse> {
  return rest.delete(`${transferBatchUrl}/product`, request);
}

async function getBatch(request: GetCustomerTransferBatchRequest): Promise<GetCustomerTransferBatchResponse> {
  return rest.get(`${transferBatchUrl}/customer-transfer-batch`, request);
}

export const CustomerTransferApi = {
  getUnshippedOrders,
  getProductUnits,
  scanBarcode,
  removeProductFromBatch,
  getBatch,
};
