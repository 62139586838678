import { GetMandatResponse } from '@/Api/Mandats/GetMandatResponse';
import { MandatApi } from '@/Api/Mandats/MandatApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateMandatResponse } from '@/Api/Mandats/CreateMandatResponse';
import { CreateMandatRequest } from '@/Api/Mandats/CreateMandatRequest';
import { UpdateMandatResponse } from '@/Api/Mandats/UpdateMandatResponse';
import { UpdateMandatRequest } from '@/Api/Mandats/UpdateMandatRequest';
import { DeleteMandatResponse } from '@/Api/Mandats/DeleteMandatResponse';
import { DeleteMandatRequest } from '@/Api/Mandats/DeleteMandatRequest';
import { GetCountryVatInfoResponse } from '@/Api/Mandats/GetCountryVatInfoResponse';
import { GetCountryVatInfoRequest } from '@/Api/Mandats/GetCountryVatInfoRequest';

const getMandats = createAsyncThunk<GetMandatResponse>('mandatSettings/getMandats', async () => {
  return MandatApi.getMandats();
});

const createMandat = createAsyncThunk<CreateMandatResponse, CreateMandatRequest>(
  'mandatSettings/createMandat',
  async (request: CreateMandatRequest) => {
    return MandatApi.createMandat(request);
  },
);

const updateMandat = createAsyncThunk<UpdateMandatResponse, UpdateMandatRequest>(
  'mandatSettings/updateMandat',
  async (request: UpdateMandatRequest) => {
    return MandatApi.updateMandat(request);
  },
);

const deleteMandat = createAsyncThunk<DeleteMandatResponse, DeleteMandatRequest>(
  'mandatSettings/deleteMandat',
  async (request: DeleteMandatRequest) => {
    return MandatApi.deleteMandat(request);
  },
);

const getVatInfoByCountry = createAsyncThunk<GetCountryVatInfoResponse, GetCountryVatInfoRequest>(
  'mandatSettings/get-vat-info-by-country',
  async (request) => {
    return MandatApi.getVatInfoByCountry(request);
  },
);

export const mandatsAsyncThunks = {
  getMandats,
  createMandat,
  updateMandat,
  deleteMandat,
  getVatInfoByCountry,
};
