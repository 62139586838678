import { OrderType } from '@/Enums/OrderType';
import { Pair } from '@/Types';
import { useLocalization } from '@/Hooks';
import { useMemo } from 'react';
import { OrderStatusFilter } from '@/Enums/OrderStatusFilter';

export function useOrderStatusFilterOptions(orderType: OrderType) {
  const localization = useLocalization();

  const { OrderStatusFilter: OrderStatusFilterLoc, OrderStatusFilterGroups } = localization.enums;

  return useMemo(() => {
    const result: Pair<OrderStatusFilter>[] = [];

    if (orderType === OrderType.Sale) {
      result.push(
        ...[
          {
            key: OrderStatusFilter.NotShippedAll,
            value: OrderStatusFilterLoc.NotShippedAll,
            group: OrderStatusFilterGroups.Shipping,
          },
          {
            key: OrderStatusFilter.NotShippedFulfillment,
            value: OrderStatusFilterLoc.NotShippedFulfillment,
            group: OrderStatusFilterGroups.Shipping,
          },
          {
            key: OrderStatusFilter.NotShippedShippingToday,
            value: OrderStatusFilterLoc.NotShippedShippingToday,
            group: OrderStatusFilterGroups.Shipping,
          },
          {
            key: OrderStatusFilter.NotShippedShippingLater,
            value: OrderStatusFilterLoc.NotShippedShippingLater,
            group: OrderStatusFilterGroups.Shipping,
          },
          {
            key: OrderStatusFilter.NotShippedShippingOverdue,
            value: OrderStatusFilterLoc.NotShippedShippingOverdue,
            group: OrderStatusFilterGroups.Shipping,
          },
          {
            key: OrderStatusFilter.NotShippedNoShippingRequired,
            value: OrderStatusFilterLoc.NotShippedNoShippingRequired,
            group: OrderStatusFilterGroups.Shipping,
          },
          {
            key: OrderStatusFilter.NotShippedArchived,
            value: OrderStatusFilterLoc.NotShippedArchived,
            group: OrderStatusFilterGroups.Shipping,
          },
          {
            key: OrderStatusFilter.ShippedToday,
            value: OrderStatusFilterLoc.ShippedToday,
            group: OrderStatusFilterGroups.Shipping,
          },
          {
            key: OrderStatusFilter.ShippedOn,
            value: OrderStatusFilterLoc.ShippedOn,
            group: OrderStatusFilterGroups.Shipping,
          },

          {
            key: OrderStatusFilter.NotPaidAll,
            value: OrderStatusFilterLoc.NotPaidAll,
            group: OrderStatusFilterGroups.Payment,
          },
          {
            key: OrderStatusFilter.NotPaidFulfillment,
            value: OrderStatusFilterLoc.NotPaidFulfillment,
            group: OrderStatusFilterGroups.Payment,
          },
          {
            key: OrderStatusFilter.NotPaidOverdue,
            value: OrderStatusFilterLoc.NotPaidOverdue,
            group: OrderStatusFilterGroups.Payment,
          },
          {
            key: OrderStatusFilter.NotPaidArchived,
            value: OrderStatusFilterLoc.NotPaidArchived,
            group: OrderStatusFilterGroups.Payment,
          },
        ],
      );
    }

    if (orderType === OrderType.Purchase) {
      result.push(
        ...[
          {
            key: OrderStatusFilter.PaidNotReceived,
            value: OrderStatusFilterLoc.PaidNotReceived,
            group: OrderStatusFilterGroups.Statuses,
          },
          {
            key: OrderStatusFilter.NotPaidNotReceived,
            value: OrderStatusFilterLoc.NotPaidNotReceived,
            group: OrderStatusFilterGroups.Statuses,
          },
          {
            key: OrderStatusFilter.ReceivedToday,
            value: OrderStatusFilterLoc.ReceivedToday,
            group: OrderStatusFilterGroups.Statuses,
          },
          {
            key: OrderStatusFilter.ReceivedOn,
            value: OrderStatusFilterLoc.ReceivedOn,
            group: OrderStatusFilterGroups.Statuses,
          },
          {
            key: OrderStatusFilter.ReceivedNotFeedbackReceived,
            value: OrderStatusFilterLoc.ReceivedNotFeedbackReceived,
            group: OrderStatusFilterGroups.Statuses,
          },
          {
            key: OrderStatusFilter.ReceivedNoFeedbackSubmitted,
            value: OrderStatusFilterLoc.ReceivedNoFeedbackSubmitted,
            group: OrderStatusFilterGroups.Statuses,
          },
        ],
      );
    }

    result.push(
      ...[
        {
          key: OrderStatusFilter.FeedbackReceivedButNotSubmitted,
          value: OrderStatusFilterLoc.FeedbackReceivedButNotSubmitted,
          group: OrderStatusFilterGroups.Feedback,
        },
        {
          key: OrderStatusFilter.FeedbackSubmittedButNotReceived,
          value: OrderStatusFilterLoc.FeedbackSubmittedButNotReceived,
          group: OrderStatusFilterGroups.Feedback,
        },
        {
          key: OrderStatusFilter.AllNotArchived,
          value: OrderStatusFilterLoc.AllNotArchived,
          group: OrderStatusFilterGroups.All,
        },
        {
          key: OrderStatusFilter.AllArchived,
          value: OrderStatusFilterLoc.AllArchived,
          group: OrderStatusFilterGroups.All,
        },
        {
          key: OrderStatusFilter.All,
          value: OrderStatusFilterLoc.All,
          group: OrderStatusFilterGroups.All,
        },
      ],
    );

    return {
      orderStatusFilterOptions: result,
    };
  }, [OrderStatusFilterLoc]);
}
