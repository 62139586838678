import React, { useMemo } from 'react';
import styles from './PurchasesPage.scss';
import { withModules } from '@/Hocs';
import { useLocalization, useTabs } from '@/Hooks';
import { GeneralInfo, AdditionalInfo, OrderItemsTable, PurchasesTable } from './modules';
import { FormProvider, useForm } from '@/Hooks/useFormWrapper';
import { TabList } from '@/Components/Tabs/modules/TabList/TabList';
import { TabItem } from '@/Components/Tabs/modules/TabItem/TabItem';
import { TabsPanel } from '@/Components/Tabs/modules/TabPanel/TabPanel';
import { Tabs } from '@/Components/Tabs/Tabs';
import { OrderTableRow } from '@/Types';

export const PurchasesPageComponent = () => {
  const {
    salesPage: { GeneralInfo: GeneralInfoTranslation, AdditionalInfo: AdditionalInfoTranslation },
  } = useLocalization();

  const { setActiveTab, isActive } = useTabs(0);

  const tabs = useMemo(() => {
    return [GeneralInfoTranslation.GeneralInfo, AdditionalInfoTranslation.AdditionalInfo];
  }, [GeneralInfoTranslation, AdditionalInfoTranslation]);

  const methods = useForm<OrderTableRow>({ defaultValues: {} });

  return (
    <section className={styles.wrapper}>
      <FormProvider {...methods}>
        <form>
          <Tabs className={styles.orderInfo}>
            <TabList>
              {tabs.map((tabName, idx) => (
                <TabItem idx={idx} tabName={tabName} key={tabName} isActive={isActive(idx)} onClick={setActiveTab} />
              ))}
            </TabList>
            <TabsPanel isActive={isActive(0)} className={styles.tabsPanel}>
              <GeneralInfo />
            </TabsPanel>
            <TabsPanel isActive={isActive(1)} className={styles.tabsPanel}>
              <AdditionalInfo />
            </TabsPanel>
          </Tabs>
        </form>
      </FormProvider>
      <OrderItemsTable />
      <PurchasesTable />
    </section>
  );
};

export const PurchasesPage = withModules(PurchasesPageComponent);
