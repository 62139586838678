import { useForm } from '@/Hooks/useFormWrapper';
import { shippingLabelModalActions } from '@/ModalWindows/ShippingLabelModal/services';
import { ShippingLabelStep } from '@/ModalWindows/ShippingLabelModal/Types';
import { PrepareLabelModel } from '@/Models';
import { OrderAutomatizationPackageModel } from '@/Models/OrderUserProducts/OrderAutomatizationPackageModel';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { OrderAutomatizaionRow } from '@/Types';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { orderAutomatizationAsyncActions } from '../services/reducer';

export type FormData = {
  prepareLabels: (Omit<PrepareLabelModel, 'packages'> & {
    userProductId: number | string;
    userProductPrice: number;
    ownContract: '' | 'yes' | 'no';
    packages: {
      key: string;
      weight: number | string;
      price: number;
    }[];
  })[];
};

export const useOrderAutomatizationModalForm = (
  orderAutomatizationRows: OrderAutomatizaionRow[],
  orderPackages: {
    orderId: number;
    isOwnContract: boolean;
    packages: OrderAutomatizationPackageModel[];
  }[],
  isSaveMixAutomationRules: boolean,
) => {
  const dispatch: AppDispatch = useDispatch();

  const methods = useForm<FormData>();

  useEffect(() => {
    methods.reset({
      prepareLabels: orderAutomatizationRows.map((row) => ({
        orderId: row.orderId,
        logisticTypeId: row.selectedPackages?.[0]?.products?.[0]?.logisticTypeId ?? '',
        userProductId: row.selectedPackages?.[0]?.products?.[0]?.id,
        packages: row.selectedPackages.length
          ? row.selectedPackages.map((p) => ({
              key: p.key,
              weight: p.weight,
              price: p.products?.[0].userProductPrice,
            }))
          : [{ key: '', weight: '' }],
        ownContract: !row.selectedPackages.length ? '' : row.selectedPackages[0].products.length ? 'yes' : 'no',
      })),
    });
  }, [orderAutomatizationRows, methods]);

  const onSubmit = useCallback(
    (values: FormData) => {
      dispatch(
        orderAutomatizationAsyncActions.prepareLabelsRequest({
          prepareLabels: values.prepareLabels.map((l) => {
            const currentOrderPackages = orderPackages.find(
              (op) => op.orderId === l.orderId && op.isOwnContract == (l.ownContract === 'yes'),
            );

            return {
              orderId: l.orderId,
              logisticTypeId: l.logisticTypeId,
              isOwnContract: l.ownContract === 'yes',
              deliveryDate: l.deliveryDate,
              packages: l.packages.map((p, idx) => {
                const pack = currentOrderPackages?.packages.find((i) => i.key === p.key);

                return {
                  id: orderAutomatizationRows.find((o) => o.orderId === l.orderId)?.selectedPackages?.[idx]?.id ?? 0,
                  packageId: pack?.packageId ?? 0,
                  userProductId: l.userProductId,
                  userProductPrice: l.userProductPrice ?? 0,
                  description: pack?.description ?? '',
                  weight: Number(p.weight),
                };
              }),
            };
          }),
          needCreateMixRules: isSaveMixAutomationRules,
        }),
      ).then((response: any) => {
        if (response.payload?.errors) {
          return;
        }

        dispatch(shippingLabelModalActions.setShippingLabelStep(ShippingLabelStep.OrderLabelPrint));
      });
    },
    [orderAutomatizationRows, orderPackages, isSaveMixAutomationRules],
  );

  return {
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  };
};
