import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderTableRow } from '@/Types';
import { arrayUtils } from '@/Utils';

export type SplitOrdersModalState = {
  order: OrderTableRow | null;
  newOrders: number[];
};

const initialState: SplitOrdersModalState = {
  order: null,
  newOrders: [],
};

const splitOrdersModalSlice = createSlice({
  name: 'splitOrdersModal',
  initialState,
  reducers: {
    setOrderForSplitting: (state, action: PayloadAction<OrderTableRow>) => {
      state.order = action.payload;
    },
    setInitialNewOrdersCount: (state, action: PayloadAction<number>) => {
      if (action.payload < 0) {
        state.newOrders = [];
        return;
      }

      state.newOrders = arrayUtils.range(1, action.payload + 1);
    },
    addNewOrder: (state) => {
      const ordersCount = state.newOrders.length;
      const nextOrderIndex = ordersCount === 0 ? 1 : state.newOrders[ordersCount - 1] + 1;
      state.newOrders.push(nextOrderIndex);
    },
    removeOrder: (state, action: PayloadAction<number>) => {
      state.newOrders = state.newOrders.filter((o) => o !== action.payload);
    },
  },
});

export const splitOrdersModalReducer = splitOrdersModalSlice.reducer;
export const splitOrdersModalActions = splitOrdersModalSlice.actions;
