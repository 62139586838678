import React, { FC } from 'react';
import RDropzone from 'react-dropzone';
import cn from 'classnames';
import { useLocalization } from '@/Hooks';
import { ClassNameProps } from '@/Types';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import styles from './Dropzone.scss';

type Props = {
  filesHandler: (files: any[]) => void;
  btnText?: string;
  accept?: string;
  multiple?: boolean;
  error?: boolean;
} & ClassNameProps;

export const Dropzone: FC<Props> = ({ filesHandler, btnText, accept, multiple = false, error, className }: Props) => {
  const { common } = useLocalization();

  return (
    <RDropzone onDrop={filesHandler} accept={accept} multiple={multiple}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={cn(styles.dropzone, { [styles.error]: error }, className)}>
          <input {...getInputProps()} />
          <p className={styles.dropzoneText}>
            <b>{common.DragNDrop}</b> <br />
            {btnText && common.Or}
          </p>
          {btnText && <Button text={btnText} theme={BUTTON_THEMES.PRIMARY_LIGHT} />}
        </div>
      )}
    </RDropzone>
  );
};
