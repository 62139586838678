import { CustomerShoppingCartApi } from '@/Api/ShoppingCarts/CustomerShoppingCartApi';
import { GetShoppingCartsResponse } from '@/Api/ShoppingCarts/GetShoppingCartsResponse';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetUserProductCartForUserResponse } from '@/Api/ShoppingCarts/GetUserProductCartForUserResponse';

const getCustomerCartItems = createAsyncThunk<GetShoppingCartsResponse>(
  'user-product-carts/get-user-cart-items',
  async () => {
    return CustomerShoppingCartApi.getUserProductCarts();
  },
);
const getUserProductCartForUser = createAsyncThunk<GetUserProductCartForUserResponse, number>(
  'user-product-carts/get-user-product-cart',
  async (userId: number) => {
    return CustomerShoppingCartApi.getUserProductCartForUser(userId);
  },
);

export const customerCartItemsAsyncActions = {
  getCustomerCartItems,
  getUserProductCartForUser,
};
