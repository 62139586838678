import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';

const themeState = (root: RootState) => root.theme;

const currentTheme = createSelector([themeState], (theme) => theme.theme);

export const themeSelectors = {
  currentTheme,
};
