import React from 'react';
import styles from './WithModules.scss';
import { MainContent } from '@/Components/MainContent/MainContent';
import { AdminHeader } from '@/Components/Header/AdminHeader';
import { AdminSideMenu } from '@/Components/SideMenu/AdminSideMenu';

export function withAdminModules<Props extends object>(Component: React.FC<Props>): React.FC<Props> {
  return function WithAdminModulesComponent(props: Props) {
    return (
      <div className={styles.wrapper}>
        <AdminHeader className={styles.topMenu} />
        <AdminSideMenu className={styles.sideBar} />
        <MainContent className={styles.main}>
          <Component {...props} className={styles.component} />
        </MainContent>
      </div>
    );
  };
}
