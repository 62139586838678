import { CountryCode } from '@/Enums';
import { CountryHolidayModel } from '@/Models/Admin/AdminCalendar/CountryHolidayModel';
import { CountryHoulidayYearModel } from '@/Models/Admin/AdminCalendar/CountryHoulidayYearModel';
import { Nullable } from '@/Types';
import { ModalDataType } from '@/Types/ModalDataType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { holidaysAsyncActions } from './asyncActions';

export type AdminHolidaysState = {
  countriesHolidays: CountryHoulidayYearModel[];
  selectedCountry: Nullable<CountryCode>;
  holidayModal: ModalDataType<{ year: number; holiday?: CountryHolidayModel }>;
};

const initialState: AdminHolidaysState = {
  countriesHolidays: [],
  selectedCountry: null,
  holidayModal: { visible: false, data: undefined },
};

const adminHolidaysSlice = createSlice({
  name: 'holidayPage',
  initialState,
  reducers: {
    setSelectedCountryCode(state, action: PayloadAction<CountryCode>) {
      state.selectedCountry = action.payload;
    },
    setHolidayModal(state, action: PayloadAction<ModalDataType<{ year: number; holiday?: CountryHolidayModel }>>) {
      state.holidayModal = action.payload;
    },
    addNewYear(state, action: PayloadAction<number>) {
      if (!state.countriesHolidays.some((c) => c.year == action.payload)) {
        state.countriesHolidays.push({
          holidays: [],
          year: action.payload,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(holidaysAsyncActions.getHolidays.fulfilled, (state, action) => {
      state.countriesHolidays = action.payload.countryHolidays;
    });

    builder.addCase(holidaysAsyncActions.createCountryHoliday.fulfilled, (state, action) => {
      state.countriesHolidays = state.countriesHolidays.map((h) =>
        h.year === moment(action.payload.holiday.date).year()
          ? {
              ...h,
              holidays: [...h.holidays, action.payload.holiday],
            }
          : h,
      );
    });

    builder.addCase(holidaysAsyncActions.updateCountryHoliday.fulfilled, (state, action) => {
      state.countriesHolidays = state.countriesHolidays.map((h) =>
        h.year === moment(action.payload.holiday.date).year()
          ? {
              ...h,
              holidays: h.holidays.map((x) => (x.id === action.payload.holiday.id ? action.payload.holiday : x)),
            }
          : h,
      );
    });

    builder.addCase(holidaysAsyncActions.deleteCountryHoliday.fulfilled, (state, { payload: { id } }) => {
      for (let i = 0; i < state.countriesHolidays.length; i++) {
        const holiday = state.countriesHolidays[i].holidays.find((x) => x.id === id);

        if (!holiday) continue;

        state.countriesHolidays[i].holidays.splice(state.countriesHolidays[i].holidays.indexOf(holiday), 1);
        break;
      }
    });
  },
});

const { actions, reducer } = adminHolidaysSlice;

export const holidaysPageActions = actions;
export const holidaysPageReducer = reducer;
