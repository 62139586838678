import { UserAccountInfoModel } from '@/Models/UserAccountInfoModel';
import { Nullable } from '@/Types/Nullable';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userAccountSettingsAsyncActions } from './asyncActions';

export type UserAccountSettingsState = {
  accountInfo: Nullable<UserAccountInfoModel>;
  needRegionalSettingModal: boolean;
  needPaymentMethodsModal: boolean;
};

const initialState: UserAccountSettingsState = {
  accountInfo: null,
  needRegionalSettingModal: false,
  needPaymentMethodsModal: false,
};

const userAccountSettingsSlice = createSlice({
  name: 'userAccount',
  initialState,
  reducers: {
    setRegionalSettingModal(state, action: PayloadAction<boolean>) {
      state.needRegionalSettingModal = action.payload;
    },
    setPaymentMethodsModal(state, action: PayloadAction<boolean>) {
      state.needPaymentMethodsModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userAccountSettingsAsyncActions.getUserAccountInfo.fulfilled, (state, action) => {
      state.accountInfo = action.payload.accountInfo;
    });

    builder.addCase(userAccountSettingsAsyncActions.updateField.fulfilled, (state, action) => {
      state.accountInfo = action.payload.accountInfo;
    });
  },
});

const { actions, reducer } = userAccountSettingsSlice;

export const userAccountSettingsActions = actions;
export const userAccountSettingsReducer = reducer;
