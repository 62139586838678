import { Nullable } from '@/Types';
import { AutomaticallyGeneratedDocumentType } from '@/Enums/AutomaticallyGeneratedDocumentType';
import { BinaryResponse } from '@/Api';
import { PrinterSettingsResponse } from '@/Api/DocumentTemplates';

export enum PrinterScale {
  NoScale = 'noscale',
  Shrink = 'shrink',
  Fit = 'fit',
}

export enum PaperKind {
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5',
  A6 = 'A6',
  Letter = 'letter',
  Legal = 'legal',
  Tabloid = 'tabloid',
  Statement = 'statement',
}

export type PrinterSettings = {
  printerName: string;
  isPreview: boolean;
  isSaveWhenPrinting: boolean;
  pathSaveWhenPrinting: string;
  copyCount: number;
  isSortWhenPrinting: boolean;
  automaticallyGeneratedDocumentType: AutomaticallyGeneratedDocumentType;
};

export interface IPrintService {
  getPrinters: () => IPrinterInfo[];
  getDefaultPrinter: () => Nullable<IPrinterInfo>;
  print: (
    guid: string,
    orderIds: number[],
    printerSettings: PrinterSettings,
    callback?: (params: number[]) => void,
  ) => Promise<PrintResult>;
  printDocument: (documentBase64: string, isPrintPreview: boolean, assignPrinter: string) => Promise<PrintResult>;
  printDirect: (
    response: BinaryResponse,
    printerSettings: PrinterSettings,
    templatePrinterSettings?: PrinterSettingsResponse,
  ) => Promise<PrintResult>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IOptions {}

export interface IPrinterInfo {
  description: string;
  /**
   * the name of the printer as shown in Print Preview.
   */
  displayName: string;
  /**
   * whether or not a given printer is set as the default printer on the OS.
   */
  isDefault: boolean;
  /**
   * the name of the printer as understood by the OS.
   */
  name: string;
  /**
   * an object containing a variable number of platform-specific printer information.
   */
  options: IOptions;
  /**
   * the current status of the printer.
   */
  status: number;
}

export enum PrintResult {
  None,
  ErrorDownloading,
  ErrorSaving,
  ErrorPrint,
  Success,
}
