import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { CurrencyModel } from '@/Models/CurrencyModel';

const url = ApiRoutingUtils.createUrl('currencies');

async function getCurrencies(): Promise<CurrencyModel[]> {
  return rest.get(url);
} // getCurrencies

export const CurrenciesApi = {
  getCurrencies,
};
