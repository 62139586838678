import { createAsyncThunk } from '@reduxjs/toolkit';
import { TranslationsApi } from '@/Api/Translations/TranslationsApi';
import { UpdateTranslationSettingRequest } from '@/Api/Translations/UpdateTranslationSettingRequest';
import { UpdateLanguageSettingRequest } from '@/Api/Translations/UpdateLanguageSettingRequest';

const getTranslationSetting = createAsyncThunk(
  'languageEditorPage/translations-settings/get-translation-setting',
  async () => {
    return TranslationsApi.getTranslationSetting();
  },
);

const updateTranslationSetting = createAsyncThunk(
  'languageEditorPage/translations-settings/update-translation-setting',
  async (request: UpdateTranslationSettingRequest) => {
    return TranslationsApi.updateTranslationSetting(request);
  },
);

const getLanguageSetting = createAsyncThunk(
  'languageEditorPage/translations-settings/get-language-setting',
  async () => {
    return TranslationsApi.getLanguageSetting();
  },
);

const updateLanguageSetting = createAsyncThunk(
  'languageEditorPage/translations-settings/update-language-setting',
  async (request: UpdateLanguageSettingRequest) => {
    return TranslationsApi.updateLanguageSetting(request);
  },
);

export const editorSettingsAsyncActions = {
  getTranslationSetting,
  updateTranslationSetting,
  getLanguageSetting,
  updateLanguageSetting,
};
