import { DateRangeParamType } from '@/Api';
import { DateFormatType, OrderStatusFilter } from '@/Enums';
import { RootState } from '@/Redux/RootReducer';
import { countryTypeUtils } from '@/Utils/CountryTypesUtils';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

const purchasesPage = (state: RootState) => state.purchasesPage;
const translation = (state: RootState) => state.global.translation;

const purchasesItems = (state: RootState) => state.purchasesPage?.purchases;

const purchases = createSelector([purchasesItems, translation], (purcheseItems, translation) => {
  return (
    purcheseItems.map((purchase) => ({
      ...purchase,
      returnCountryName: countryTypeUtils.getCountryNameByCountryCode(purchase.returnCountryCode, translation),
      invoiceCountryName: countryTypeUtils.getCountryNameByCountryCode(purchase.invoiceCountryCode, translation),
      shippingCountryName: countryTypeUtils.getCountryNameByCountryCode(purchase.shippingCountryCode, translation),
      originalShippingCountryName: countryTypeUtils.getCountryNameByCountryCode(
        purchase.originalShippingCountryCode,
        translation,
      ),
    })) || []
  );
});

const selectedPurchase = createSelector(purchasesPage, (state) => state.selectedPurchase);

const selectedPurchases = createSelector(purchasesPage, (state) => state.selectedPurchases);

const newPurchasesCount = createSelector(purchasesPage, (state) => state.newPurchasesCount);

const updatedPurchasesExists = createSelector(
  purchasesPage,
  (state) => state.updatedPurchasesExists || state.newPurchasesCount > 0,
);

const orderFilters = createSelector(
  purchasesPage,
  (state) =>
    state.orderFilters ?? {
      mpAccountIds: [],
      orderStatusFilter: OrderStatusFilter.PaidNotReceived,
      dateRange: {
        rangeType: DateRangeParamType.CreationTime,
        timeFrom: moment().subtract(30, 'days').startOf('day').format(DateFormatType.LOCAL_DATE_TIME),
        timeTo: moment().endOf('day').format(DateFormatType.LOCAL_DATE_TIME),
      },
    },
);

const focusedOrderItems = createSelector([selectedPurchase], (selectedPurchase) => {
  return selectedPurchase?.orderItems || [];
});

export const purchasesPageSelectors = {
  purchases,
  selectedPurchase,
  selectedPurchases,
  focusedOrderItems,
  orderFilters,
  newPurchasesCount,
  updatedPurchasesExists,
};
