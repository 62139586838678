import { useState } from 'react';

export const useProgressSteps = (maxStepsCount: number, initialProgressStep: number) => {
  const [activeStep, setActiveStep] = useState(initialProgressStep);

  const setPrev = () => {
    if (activeStep <= 0) return 0;

    return setActiveStep((prev) => prev - 1);
  };

  const setNext = () => {
    if (activeStep >= maxStepsCount) return maxStepsCount;

    return setActiveStep((prev) => prev + 1);
  };

  return {
    activeStep,
    setActiveStep,
    setPrev,
    setNext,
    isStepDone: (idx: number) => idx <= activeStep,
    isStepActive: (idx: number) => idx === activeStep,
  };
};
