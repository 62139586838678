import { ContactsApi, GetContactsRequest } from '@/Api/Contacts';
import { ContactModel } from '@/Models';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getFavoriteContacts = createAsyncThunk<ContactModel[], GetContactsRequest>(
  'addresBook/get',
  async (request: GetContactsRequest) => {
    return ContactsApi.getFavoriteContacts(request);
  },
);

export const addressBookAsyncActions = {
  getFavoriteContacts,
};
