export enum NotificationCode {
  GetMpAccountTokenSuccess = 'GetMpAccountTokenSuccess',
  SynchOrdersComplete = 'SynchOrdersComplete',
  SynchOrdersTokenError = 'SynchOrdersTokenError',
  SynchFeedbacksTokenError = 'SynchFeedbacksTokenError',
  SynchShippingStatusesComplete = 'SynchShippingStatusesComplete',
  SynchEmptyOrdersComplete = 'SynchEmptyOrdersComplete',
  SynchEmptyPurchasesComplete = 'SynchEmptyPurchasesComplete',
  SynchEmptySalesComplete = 'SynchEmptySalesComplete',
  UploadInvoiceFailed = 'UploadInvoiceFailed',
  CancelInvoiceFailed = 'CancelInvoiceFailed',
  ChangeDeliveryStatusCompleted = 'ChangeDeliveryStatusCompleted',
  ChangeDeliveryStatusUnhandledError = 'ChangeDeliveryStatusUnhandledError',
}
