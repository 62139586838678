import { TranslationType } from '@/Localization/LanguageKeys';
import { ContactModel } from '@/Models';
import { ContactTableRow, OrderTableRow, ShortContactTableRow } from '@/Types';
import { orderTableRowUtils } from '@/Types/OrderTableRowUtils';
import { countryTypeUtils } from '@/Utils/CountryTypesUtils';

const contactModelToTableRow = (data: ContactModel, translation: TranslationType): ContactTableRow => {
  return {
    ...data,
    invoiceCountryName: countryTypeUtils.getCountryNameByCountryCode(data.invoiceCountryCode, translation),
    shippingCountryName: data.shippingCountryCode
      ? countryTypeUtils.getCountryNameByCountryCode(data.shippingCountryCode, translation)
      : '',
  };
};

const shortContactTableRowToContactModel = ({ ...data }: ShortContactTableRow): ContactModel => {
  return {
    ...data,
    vatNumber: '',
    isFavorite: false,
    bankDataBankName: '',
    bankDataName: '',
    bankDataBIC: '',
    bankDataIBAN: '',
    type: data.typeEnumValue,
  };
};

const orderTableRowToContactModel = (orderTableRow: OrderTableRow): Partial<ContactModel> => {
  const isOriginalShippingModified = orderTableRowUtils.isOriginalShippingModified(orderTableRow);

  // Filling static data
  const contactModel: Partial<ContactModel> = {
    number: orderTableRow.customerNumber,
    invoiceCityName: orderTableRow.invoiceCityName,
    invoiceCompanyName: orderTableRow.invoiceCompanyName,
    invoiceAdditionalLine: orderTableRow.invoiceAdditionalLine,
    invoiceCountryCode: orderTableRow.invoiceCountryCode ?? null,
    invoiceFirstName: orderTableRow.invoiceFirstName,
    invoiceHouseNo: orderTableRow.invoiceHouseNo,
    invoiceLastName: orderTableRow.invoiceLastName,
    invoicePostalCode: orderTableRow.invoicePostalCode,
    invoiceStreet: orderTableRow.invoiceStreet,
    vatNumber: orderTableRow.buyerVatNumber,
    firstContactPersonEmail: orderTableRow.buyerEmail,
    firstContactPersonNotes: orderTableRow.buyerNote,
    firstContactPersonPhones: orderTableRow.shippingPhoneNumber,
    firstContactPersonName:
      orderTableRow.invoiceFirstName &&
      orderTableRow.invoiceLastName &&
      `${orderTableRow.invoiceFirstName} ${orderTableRow.invoiceLastName}`,
    secondContactPersonPhones: orderTableRow.secondContactPersonPhones,
    secondContactPersonEmail: orderTableRow.secondContactPersonEmail,
    secondContactPersonNotes: orderTableRow.secondContactPersonNotes,
    secondContactPersonTitle: orderTableRow.secondContactPersonTitle,
    secondContactPersonName: orderTableRow.secondContactPersonName,

    // Filling conditianal data
    shippingAdditionalLine: isOriginalShippingModified
      ? orderTableRow.shippingAdditionalLine
      : orderTableRow.originalShippingAdditionalLine,
    shippingCityName: isOriginalShippingModified
      ? orderTableRow.shippingCityName
      : orderTableRow.originalShippingCityName,
    shippingCompanyName: isOriginalShippingModified
      ? orderTableRow.shippingCompanyName
      : orderTableRow.originalShippingCompanyName,
    shippingCountryCode: isOriginalShippingModified
      ? orderTableRow.shippingCountryCode
      : orderTableRow.originalShippingCountryCode,
    shippingFirstName: isOriginalShippingModified
      ? orderTableRow.shippingFirstName
      : orderTableRow.originalShippingFirstName,
    shippingHouseNo: isOriginalShippingModified ? orderTableRow.shippingHouseNo : orderTableRow.originalShippingHouseNo,
    shippingLastName: isOriginalShippingModified
      ? orderTableRow.shippingLastName
      : orderTableRow.originalShippingLastName,
    shippingPostalCode: isOriginalShippingModified
      ? orderTableRow.shippingPostalCode
      : orderTableRow.originalShippingPostalCode,
    shippingStreet: isOriginalShippingModified ? orderTableRow.shippingStreet : orderTableRow.originalShippingStreet,
  };

  return contactModel;
};

// Copy invoice address to shipping address
const copyInvoiceToShippingAddress = (data: ContactModel) => {
  data.shippingCompanyName = data.invoiceCompanyName;
  data.shippingFirstName = data.invoiceFirstName;
  data.shippingLastName = data.invoiceLastName;
  data.shippingAdditionalLine = data.invoiceAdditionalLine;
  data.shippingStreet = data.invoiceStreet;
  data.shippingHouseNo = data.invoiceHouseNo;
  data.shippingPostalCode = data.invoicePostalCode;
  data.shippingCityName = data.invoiceCityName;
  data.shippingCountryCode = data.invoiceCountryCode;
}; // copyInvoiceToShippingAddress

export const contactMapping = {
  contactModelToTableRow,
  shortContactTableRowToContactModel,
  orderTableRowToContactModel,
  copyInvoiceToShippingAddress,
};
