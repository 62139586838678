import BarcodeSuccess from './Audios/barcode-scanner-success-sound.mp3';
import BarcodeError from './Audios/barcode-scanner-error-sound.mp3';
import BarcodeCapslockOn from './Audios/barcode-capslock-on-sound.mp3';

export const Audios = {
  Barcode: {
    BarcodeSuccess,
    BarcodeError,
    BarcodeCapslockOn,
  },
};
