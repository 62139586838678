import React, { useCallback, useState } from 'react';
import { Dropzone } from '@/Components/Dropzone/Dropzone';
import { useUpdateByFileTab } from '@/Components/SideMenu/modules/UpdateFromFileTab/hooks/useUpdateByFileTab';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { FormProvider } from '@/Hooks/useFormWrapper';
import { useUpdateByFileTabOptions } from '@/Components/SideMenu/modules/UpdateFromFileTab/hooks/useUpdateByFileTabOptions';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { FormData } from './hooks/useUpdateByFileTab';
import { useLocalization } from '@/Hooks';
import { Label } from '@/Components/Controls/Label/Label';
import styles from './UpdateByFileTab.scss';
import { CrudExtended } from '@/Components/Controls/CrudExtended/CrudExtended';
import { Icons } from '@/Static/IconsRes';

type Props = {
  closeModalWindow: () => void;
};
export function UpdateByFileTab({ closeModalWindow }: Props) {
  const {
    modalWindows: {
      mpSynchronization: {
        ModalTabs: { UpdateByFile },
        UpdateByFileTab,
      },
    },
  } = useLocalization();
  const [file, setFile] = useState<any>('');

  const { onSubmit, methods, rules } = useUpdateByFileTab(file, closeModalWindow);
  const { mpOptions } = useUpdateByFileTabOptions();

  const filesHandler = useCallback(
    (files: any[]) => {
      setFile(files[0]);
    },
    [methods],
  ); // filesHandler

  console.log(UpdateByFileTab);
  return (
    <FormProvider {...methods}>
      <p className={styles.description}> {UpdateByFile.text} </p>
      <div className={styles.form}>
        <div className={styles.formItemWrapper}>
          <Label nowrap text={UpdateByFileTab.form.mpAccountLabel} />
          <FormSelectInput<FormData>
            register={methods.register}
            rules={{ ...rules.required }}
            error={methods.formState.errors}
            name={'mpAccountId'}
            options={mpOptions}
            needClearButton={false}
          />
        </div>

        {file ? (
          <div className={styles.uploadedFile}>
            <span>{file.name}</span>{' '}
            <CrudExtended
              iconItems={[
                {
                  onClick: () => setFile(null),
                  src: Icons.Delete,
                  active: true,
                },
              ]}
            />
          </div>
        ) : (
          <Dropzone
            filesHandler={filesHandler}
            btnText={UpdateByFileTab.dropzoneText}
            accept={'.txt, .csv'}
            className={styles.dropzone}
          />
        )}

        <Button
          autoFocus={true}
          className={styles.wideButton}
          text={UpdateByFileTab.updateOrdersButton}
          theme={BUTTON_THEMES.SUCCESS}
          disabled={!methods.formState.isValid || !file}
          onClick={onSubmit}
        />
      </div>
    </FormProvider>
  );
}
