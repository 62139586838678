import React from 'react';
import { ModalProps } from '@/Types';
import { useLocalization } from '@/Hooks';
import modalWindowsStyles from '../ModalWindows.scss';
import styles from './DocumentPrintModal.scss';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { Modal } from '@/Components/Modal/Modal';
import { DocumentPrintTable } from './DocumentPrintTable';
import { useDocumentPrintModalActions, FormData } from './hooks/useDocumentPrintModalActions';
import { useDocumentPrintModalOptions } from './hooks/useDocumentPrintModalOptions';
import { CreateDocumentModal } from './modules/CreateDocumentModal/CreateDocumentModal';
import { SelectDocumentModal } from './modules/SelectDocumentModal/SelectDocumentModal';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { useRequired } from '@/Hooks/Validation';
import { Label } from '@/Components/Controls/Label/Label';
import { Tooltip } from '@/Components/Tooltip/Tooltip';

export function DocumentPrintModal({ closeModalWindow }: ModalProps) {
  const {
    buttons,
    common: {
      InputPlaceholders: { Select },
    },
    modalWindows: {
      documentPrintModal: { Title, PrintDisabled, PrintingOrder },
    },
  } = useLocalization();

  const required = useRequired();

  const {
    selectedTemplate,
    selectedSalesIds,
    selectedSales,
    documentTemplates,
    documentPrintType,
    numberGenerationType,
    documentNumbers,
    orderTypeOptions,
  } = useDocumentPrintModalOptions();

  const {
    print,
    printDirect,
    selectDocumentModal,
    setSelectDocumentModal,
    createDocumentModal,
    setCreateDocumentModal,
    methods: {
      register,
      setValue,
      formState: { errors },
    },
  } = useDocumentPrintModalActions(
    selectedTemplate,
    selectedSalesIds,
    documentNumbers,
    numberGenerationType,
    closeModalWindow,
  );

  const isMultiDocumentPrint = documentNumbers.length > 1 && Boolean(numberGenerationType);
  const needShowPrintOrderSelect = isMultiDocumentPrint && documentNumbers.some((item) => item.numbers.length > 1);
  const isDocumentPrintDisabled = isMultiDocumentPrint && documentNumbers.some((item) => item.numbers.length == 0);

  if (selectedSales === null || selectedSales.length === 0 || !documentPrintType) {
    closeModalWindow();
    return null;
  }

  return (
    <Modal onClose={closeModalWindow}>
      {createDocumentModal && numberGenerationType && (
        <CreateDocumentModal
          numberGenerationType={numberGenerationType}
          onClose={() => setCreateDocumentModal(false)}
        />
      )}

      {selectDocumentModal && (
        <SelectDocumentModal
          onPrint={() => {
            printDirect();
            closeModalWindow();
          }}
          documentNumbers={documentNumbers[0].numbers}
          onClose={() => setSelectDocumentModal(false)}
        />
      )}

      <form className={styles.modalWrapper} onSubmit={print}>
        <h3 className={styles.modalTitle}>{Title}</h3>
        <DocumentPrintTable rows={documentTemplates} />

        {needShowPrintOrderSelect && (
          <Label text={PrintingOrder} className={styles.printOrder}>
            <FormSelectInput<FormData>
              options={[{ key: '', value: Select }, ...orderTypeOptions]}
              name="orderType"
              register={register}
              setValue={setValue}
              rules={required}
              error={errors}
            />
          </Label>
        )}

        <div className={modalWindowsStyles.buttonsWrapper}>
          <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={buttons.Cancel} onClick={closeModalWindow} />
          <Tooltip
            className={styles.disabledMessage}
            shouldShowOnHover={isDocumentPrintDisabled}
            text={isDocumentPrintDisabled ? PrintDisabled : ''}
          >
            <Button
              autoFocus={true}
              text={buttons.Print}
              theme={BUTTON_THEMES.SUCCESS}
              type={'submit'}
              disabled={selectedTemplate == null || isDocumentPrintDisabled}
            />
          </Tooltip>
        </div>
      </form>
    </Modal>
  );
}
