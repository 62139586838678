import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';
import { GenAdditionalServiceTypesDataState } from './reducer';
import { ObjectUtils } from '@/Utils';

const logisticAdditionalServiceTypesDataSelector = (root: RootState) => root.dynamicData.genAdditionalServiceTypesData;
const translation = (state: RootState) => state.global.translation;

const additionalServiceTypes = createSelector(
  logisticAdditionalServiceTypesDataSelector,
  (state: GenAdditionalServiceTypesDataState) => state.additionalServiceTypes,
);

const translatedAdditionalServiceTypes = createSelector([additionalServiceTypes, translation], (state, translation) => {
  const settingsProducts = translation.Pages.WarehouseLogistics.SettingsProducts;

  return state.map((x) => ({
    ...x,
    locName: ObjectUtils.getObjectPathValue(settingsProducts, `Types.GenAdditionalServiceType.${x.name}`) || x.name,
  }));
});

const additionalServiceTypesOptions = createSelector(translatedAdditionalServiceTypes, (state) =>
  state.map((x) => {
    return { key: x.id, value: x.locName ?? x.name };
  }),
);

export const genAdditionalServiceTypesDataSelectors = {
  additionalServiceTypes: translatedAdditionalServiceTypes,
  additionalServiceTypesOptions,
};
