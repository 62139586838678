import { combineReducers } from 'redux';
import { erpTranslationsEditorReducer } from '../modules/ErpTranslationsEditor/services/reducer';
import { productTranslationsEditorReducer } from '../modules/ProductTranslationsEditor/services/reducer';
import { websiteTranslationsEditorReducer } from '../modules/WebsiteTranslationsEditor/services/reducer';
import { editorSettingsReducer } from '../modules/EditorSettings/services/reducer';

export const languageEditorPageReducer = combineReducers({
  erpTranslationsEditor: erpTranslationsEditorReducer,
  websiteTranslationsEditor: websiteTranslationsEditorReducer,
  productTranslationsEditor: productTranslationsEditorReducer,
  editorSettings: editorSettingsReducer,
});
