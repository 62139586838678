import { createSlice } from '@reduxjs/toolkit';
import { DateFormatModel, TimeFormatModel, TimeZoneModel } from '@/Models/RegionalSettings';
import { asyncActions } from './asyncActions';
import { NumberFormatModel } from '@/Models/NumberFormatModel';

export type RegionalSettingsDataState = {
  timeZones: TimeZoneModel[];
  timeFormats: TimeFormatModel[];
  dateFormats: DateFormatModel[];
  numberFormats: NumberFormatModel[];
};

const initialState: RegionalSettingsDataState = {
  timeZones: [],
  timeFormats: [],
  dateFormats: [],
  numberFormats: [],
};

const regionalSettingsDataSlice = createSlice({
  name: 'regionalSettingsData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getRegionalSettings.fulfilled, (state, action) => {
      state.timeZones = action.payload.timeZones;
      state.timeFormats = action.payload.timeFormats;
      state.dateFormats = action.payload.dateFormats;
      state.numberFormats = action.payload.numberFormats;
    });
  },
});

export const regionalSettingsDataReducer = regionalSettingsDataSlice.reducer;
export const regionalSettingsDataActions = regionalSettingsDataSlice.actions;
export const regionalSettingsDataAsyncActions = asyncActions;
