import { Nullable } from '@/Types';
import { PackageModel } from '@/Models';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageableRequest } from '@/Api';
import {
  GetPackagesParams,
  PackagesApi,
  CreatePackageRequest,
  CreatePackageResponse,
  UpdatePackageRequest,
  UpdatePackageResponse,
  DeletePackageRequest,
} from '@/Api/Packages';
import { PageModalType } from '@/Enums';
import { packagesUtils } from './utils';

export type PackagesSettingsState = {
  selectedPackage: Nullable<PackageModel>;
  packages: PackageModel[];
  packageModalType: PageModalType;
  defaultPackage: Nullable<PackageModel>;
};

const initialState: PackagesSettingsState = {
  selectedPackage: null,
  packages: [],
  packageModalType: PageModalType.None,
  defaultPackage: null,
};

const createPackage = createAsyncThunk<CreatePackageResponse, CreatePackageRequest>(
  'package/create',
  async (request: CreatePackageRequest) => {
    request.package = packagesUtils.inSM(request.package);
    return PackagesApi.createPackage(request);
  },
);

const getPackages = createAsyncThunk('packages/get', async (request: PageableRequest<GetPackagesParams>) => {
  return PackagesApi.getPackages(request);
});

const updatePackage = createAsyncThunk<UpdatePackageResponse, UpdatePackageRequest>(
  'package/update',
  async (request: UpdatePackageRequest) => {
    request.package = packagesUtils.inSM(request.package);
    return PackagesApi.updatePackage(request);
  },
);

const deletePackage = createAsyncThunk<number, DeletePackageRequest>(
  'package/delete',
  async (request: DeletePackageRequest) => {
    return PackagesApi.deletePackage(request);
  },
);

const packagesSettingsSlice = createSlice({
  name: 'packagesSettings',
  initialState,
  reducers: {
    createPackage(state, action: PayloadAction<PackageModel>) {
      state.packageModalType = PageModalType.CreateItem;
      state.selectedPackage = action.payload;
    },
    editPackage(state, action: PayloadAction<PackageModel>) {
      state.selectedPackage = action.payload;
    },
    selectPackage(state, action: PayloadAction<PackageModel | null>) {
      state.packageModalType = PageModalType.None;
      state.selectedPackage = action.payload;
    },
    setModalType(state, action: PayloadAction<PageModalType>) {
      state.packageModalType = action.payload;
    },
    closeModals(state) {
      state.packageModalType = PageModalType.None;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPackage.fulfilled, (state, action) => {
        state.packageModalType = PageModalType.None;
        const model = packagesUtils.inMM(action.payload.package);
        state.packages.push(model);
        state.packages = state.packages.map((x) => {
          if (model.isDefault && x.isDefault && x.id !== model.id) {
            x.isDefault = false;
          }
          return x;
        });
        state.selectedPackage = model;
      })
      .addCase(getPackages.fulfilled, (state, action) => {
        state.packages = action.payload.items.map((x) => packagesUtils.inMM(x));
      })
      .addCase(updatePackage.fulfilled, (state, action) => {
        state.packageModalType = PageModalType.None;
        const model = packagesUtils.inMM(action.payload.package);
        state.selectedPackage = model;
        state.packages = state.packages.map((x) => {
          if (model.isDefault && x.isDefault && x.id !== model.id) {
            x.isDefault = false;
          }
          return x.id === model.id ? model : x;
        });
      })
      .addCase(deletePackage.fulfilled, (state, action) => {
        state.packages = state.packages.filter((x) => x.id !== action.payload);
      });
  },
});

const { actions, reducer } = packagesSettingsSlice;

export const packagesSettingsActions = actions;
export const packagesSettingsReducer = reducer;
export const packagesSettingsAsyncActions = {
  createPackage,
  getPackages,
  updatePackage,
  deletePackage,
};
