import { TreeItemType } from '@/Components/TreeView/TreeView';
import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { userProductMapping } from '@/Mapping/UserProductMapping';

const translation = (state: RootState) => state.global.translation;

const userProductsPageState = (state: RootState) => state.warehouseLogisticsPage.userProducts;
const products = createSelector([userProductsPageState], (state) => state.products);

const translatedeProducts = createSelector([products, translation], (state, translation) => {
  return userProductMapping.fillUserProductLocalization(state, translation);
});

const selectedProduct = createSelector(userProductsPageState, (state) => state.selectedProduct);
const userProductType = createSelector(userProductsPageState, (state) => state.userProductType);
const originalProducts = createSelector(userProductsPageState, (state) => state.originalProducts || []);
const isSkuBillable = createSelector(userProductsPageState, (state) => state.isSkuBillable);
const logiscticProducts = createSelector(userProductsPageState, (state) => state.logisticProducts);

const logisticProdutsTreeView = createSelector([logiscticProducts], (logiscticProducts): TreeItemType<number>[] => {
  return logiscticProducts.map((item) => {
    return {
      key: item.id,
      value: `${item.userLogisticTypeName} ${item.userProductTypeName} ${item.shippingServiceType}`,
      expanded: true,
    } as TreeItemType<number>;
  });
});

export const userProductsPageSelectors = {
  products: translatedeProducts,
  selectedProduct,
  userProductType,
  originalProducts,
  isSkuBillable,
  logiscticProducts,
  logisticProdutsTreeView,
};
