import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogisticContractStatus, LogisticTypeKeys, PageModalType } from '@/Enums';
import { Nullable } from '@/Types';
import { asyncActions } from './asyncThunks';
import { LogisticCompanyModel } from '@/Models';
import deutschePost from '@/Static/Logos/deutsche_post.png';
import {
  logisticContractModalCombineReducer,
  LogisticContractModalCombineState,
} from '@/ModalWindows/LogisticContractModal/services/reducer';

export type LogisticSettingsState = {
  DeutschePostInternetmarkeName: string;
  companies: LogisticCompanyModel[];
  selectedCompany: Nullable<LogisticCompanyModel>;
  packageModalType: PageModalType;
  connectionStatus: Nullable<LogisticContractStatus>;
  actions: {
    delete: (data: any) => void;
    setConnectionStatus: (status: LogisticContractStatus) => void;
    setModalType: (modalType: PageModalType) => void;
    closeModalWindow: () => void;
  };
};

const initialState: LogisticSettingsState = {
  DeutschePostInternetmarkeName: 'Deutsche Post Marke',
  companies: [
    {
      name: 'Deutsche Post Marke',
      logo: deutschePost,
      enabled: true,
      contract: null,
      pickUpDays: [],
      logisticTypeId: LogisticTypeKeys.DeutschePost,
    },
  ],
  selectedCompany: null,
  packageModalType: PageModalType.None,
  connectionStatus: null,
  actions: {
    delete: () => null,
    setConnectionStatus: () => null,
    setModalType: () => null,
    closeModalWindow: () => null,
  },
};

const logisticSettingsSlice = createSlice({
  name: 'logisticSettings',
  initialState,
  reducers: {
    setModalType(state, action: PayloadAction<PageModalType>) {
      state.packageModalType = action.payload;
    },
    setSelectedCompany(state, action: PayloadAction<LogisticCompanyModel>) {
      state.selectedCompany = action.payload;
      state.packageModalType = PageModalType.CreateItem;
    },
    setSelectedCompanyContract(state, action: PayloadAction<any>) {
      if (state.selectedCompany) {
        const company = state.companies.find((x) => x.name == state.selectedCompany?.name);
        if (company) {
          state.selectedCompany.contract = action.payload;
          company.contract = action.payload;
        }
      }
    },
    setConnectionStatus(state, action: PayloadAction<LogisticContractStatus>) {
      state.connectionStatus = action.payload;
    },
    resetConnectionStatus(state) {
      state.connectionStatus = null;
    },
    resetState(state) {
      state.packageModalType = PageModalType.None;
      state.selectedCompany = null;
    },
    setActions(
      state,
      action: PayloadAction<{
        delete: (data: any) => void;
        setConnectionStatus: (status: LogisticContractStatus) => void;
        setModalType: (modalType: PageModalType) => void;
        closeModalWindow: () => void;
      }>,
    ) {
      state.actions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getAllLogisticContracts.fulfilled, (state, action) => {
      const companies: LogisticCompanyModel[] = action.payload.contracts.map((contract) => ({
        name: contract.logisticTypeName,
        logisticTypeId: contract.logisticTypeId,
        logo: contract.logo,
        enabled: contract.isActive,
        contract: contract,
        pickUpDays: [],
      }));

      if (state.companies.length == 1) {
        const logisticCompanyIdx = companies.findIndex((item) => item.logisticTypeId == LogisticTypeKeys.ShipCheaper);
        const shipCheaperLogistic = companies.splice(logisticCompanyIdx, 1)[0];
        state.companies = [shipCheaperLogistic, ...state.companies, ...companies];
      } else {
        state.companies = state.companies.map((company) => {
          const updatedCompany = companies.find((item) => item.logisticTypeId == company.logisticTypeId);
          return updatedCompany ?? company;
        });
      }
    });
    builder.addCase(asyncActions.getDeutschePostInternetmarkeContract.fulfilled, (state, action) => {
      const company = state.companies.find((x) => x.name === state.DeutschePostInternetmarkeName);
      if (company) {
        company.contract = action.payload;
      }
    });
  },
});

const { actions, reducer } = logisticSettingsSlice;

export const logisticSettingsActions = actions;
export const logisticSettingsReducer = reducer;
export const logisticSettingsAsyncActions = asyncActions;

export type LogisticSettingsCombineState = {
  logisticSettings: LogisticSettingsState;
  logisticContractModal: LogisticContractModalCombineState;
};

export const logisticSettingsCombineReducer = combineReducers<LogisticSettingsCombineState>({
  logisticSettings: logisticSettingsReducer,
  logisticContractModal: logisticContractModalCombineReducer,
});
