import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';
import { GenProductTypesDataState } from './reducer';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { ObjectUtils } from '@/Utils';

const genProductTypesData = (root: RootState) => root.dynamicData.genProductTypesData;
const translation = (root: RootState) => root.global.translation;

const productTypes = createSelector(genProductTypesData, (state: GenProductTypesDataState) => state.productTypes);

const productTypesOptions = createSelector(
  [genProductTypesData, translation],
  (state: GenProductTypesDataState, translation) => {
    const {
      Pages: {
        WarehouseLogistics: { SettingsProducts },
      },
    } = translation;

    return state.productTypes.map((x) => {
      return {
        key: x.id,
        value: ObjectUtils.getObjectPathValue(SettingsProducts, `Types.GenProductType.${x.name}`) ?? x.name,
      };
    });
  },
);

const productTypesTreeViewOptionsMemo = createSelector([productTypesOptions], (productTypesOptions): TreeItemType[] => {
  return productTypesOptions.map(
    (item) =>
      ({
        key: item.key,
        value: item.value,
        expanded: true,
      } as TreeItemType),
  );
});

export const genProductTypesDataSelectors = {
  productTypes,
  productTypesOptions,
  productTypesTreeViewOptionsMemo,
};
