import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '@/Types';
import { asyncActions } from './asyncActions';
import { ContactModel } from '@/Models';
import { ModalDataType } from '@/Types/ModalDataType';

export type CustomersPageState = {
  customers: ContactModel[];
  selectedCustomer: Nullable<ContactModel>;
  customerModalData: ModalDataType<ContactModel>;
};

const initialState: CustomersPageState = {
  customers: [],
  selectedCustomer: null,
  customerModalData: {
    visible: false,
  },
};

const customersPageSlice = createSlice({
  name: 'customersPage',
  initialState,
  reducers: {
    setSelectedCustomer: (state, action: PayloadAction<Nullable<ContactModel>>) => {
      state.selectedCustomer = action.payload;
    },
    setCustomerModalData: (state, action: PayloadAction<ModalDataType<ContactModel>>) => {
      state.customerModalData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getCustomers.fulfilled, (state, action) => {
        state.customers = action.payload;
      })
      .addCase(asyncActions.createCustomer.fulfilled, (state, action) => {
        if (!action.payload.errors) {
          state.customers.push(action.payload.contact);
        } // if
      })
      .addCase(asyncActions.updateCustomer.fulfilled, (state, action) => {
        if (!action.payload.errors) {
          state.customers = state.customers.map((i) =>
            i.id === action.payload.contact.id ? action.payload.contact : i,
          );
          state.selectedCustomer = action.payload.contact;
        } // if
      })
      .addCase(asyncActions.deleteCustomer.fulfilled, (state, action) => {
        if (!action.payload.errors) {
          state.customers = state.customers.filter((i) => i.id !== action.payload.id);
          state.selectedCustomer = null;
        } // if
      });
  },
});

export const customersPageReducer = customersPageSlice.reducer;
export const customersPageActions = customersPageSlice.actions;
export const customersPageAsyncActions = asyncActions;
