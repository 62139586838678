import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateWarehousePostingBatchResponse } from '@/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CreateWarehousePostingBatchResponse';
import { CreateWarehousePostingBatchRequest } from '@/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CreateWarehousePostingBatchRequest';
import { UserWarehouseTransferBatchApi } from '@/Api/UserWarehouse/UserWarehouseTransferBatch/UserWarehouseTransferBatchApi';
import { ScanBarcodeCustomerTransferRequest } from 'src/Api/UserWarehouse/Transfers/Customer/Requests/ScanBarcodeCustomerTransferRequest';
import { ScanBarcodeCustomerTransferResponse } from 'src/Api/UserWarehouse/Transfers/Customer/Responses/ScanBarcodeCustomerTransferResponse';
import { CustomerTransferApi } from 'src/Api/UserWarehouse/Transfers/Customer/CustomerTransferApi';
import { GetUnshippedOrdersResponse } from 'src/Api/UserWarehouse/Transfers/Customer/Responses/GetUnshippedOrdersResponse';
import { GetUnshippedOrdersRequest } from 'src/Api/UserWarehouse/Transfers/Customer/Requests/GetUnshippedOrdersRequest';
import { GetProductWarehouseUnitsResponse } from 'src/Api/UserWarehouse/Transfers/Customer/Responses/GetProductWarehouseUnitsResponse';
import { GetProductWarehouseUnitsRequest } from 'src/Api/UserWarehouse/Transfers/Customer/Requests/GetProductWarehouseUnitsRequest';
import { RemoveProductFromBatchResponse } from 'src/Api/UserWarehouse/Transfers/Customer/Responses/RemoveProductFromBatchResponse';
import { RemoveProductFromBatchRequest } from 'src/Api/UserWarehouse/Transfers/Customer/Requests/RemoveProductFromBatchRequest';
import { CloseUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CloseUserWarehouseBatchRequest';
import { CloseUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CloseUserWarehouseBatchResponse';
import { CancelUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CancelUserWarehouseBatchRequest';
import { CancelUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CancelUserWarehouseBatchResponse';

import { GetCustomerTransferBatchResponse } from '@/Api/UserWarehouse/Transfers/Customer/Responses/GetCustomerTransferBatchResponse';
import { GetCustomerTransferBatchRequest } from '@/Api/UserWarehouse/Transfers/Customer/Requests/GetCustomerTransferBatchRequest';

const createBatch = createAsyncThunk<CreateWarehousePostingBatchResponse, CreateWarehousePostingBatchRequest>(
  'customer-transfer/create-posting-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.createBatch(request);
  },
);

const scanBarcode = createAsyncThunk<ScanBarcodeCustomerTransferResponse, ScanBarcodeCustomerTransferRequest>(
  'customer-transfer/scan-barcode',
  async (request) => {
    return CustomerTransferApi.scanBarcode(request);
  },
);
const getUnshippedOrders = createAsyncThunk<GetUnshippedOrdersResponse, GetUnshippedOrdersRequest>(
  'customer-transfer/get-unshipped-orders',
  async (request) => {
    return CustomerTransferApi.getUnshippedOrders(request);
  },
);

const getProductUnits = createAsyncThunk<GetProductWarehouseUnitsResponse, GetProductWarehouseUnitsRequest>(
  'customer-transfer/get-product-units',
  async (request) => {
    return CustomerTransferApi.getProductUnits(request);
  },
);

const closeBatch = createAsyncThunk<CloseUserWarehouseBatchResponse, CloseUserWarehouseBatchRequest>(
  'customer-transfer/close-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.closeBatch(request);
  },
);

const removeProductFromBatch = createAsyncThunk<RemoveProductFromBatchResponse, RemoveProductFromBatchRequest>(
  'customer-transfer/remove-product',
  async (request) => {
    return CustomerTransferApi.removeProductFromBatch(request);
  },
);

const cancelBatch = createAsyncThunk<CancelUserWarehouseBatchResponse, CancelUserWarehouseBatchRequest>(
  'customer-transfer/cancel',
  async (request) => {
    return UserWarehouseTransferBatchApi.cancelBatch(request);
  },
);

const getBatch = createAsyncThunk<GetCustomerTransferBatchResponse, GetCustomerTransferBatchRequest>(
  'customer-transfer/get-batch',
  async (request) => {
    return CustomerTransferApi.getBatch(request);
  },
);

export const customerTransferAsyncActions = {
  createBatch,
  scanBarcode,
  getProductUnits,
  getUnshippedOrders,
  closeBatch,
  removeProductFromBatch,
  cancelBatch,
  getBatch,
};
