import React from 'react';
import styles from './CookieModal.scss';
import { Modal } from '@/Components/Modal/Modal';
import { Button } from '@/Components/Controls/Button/Button';
import { useCookieModalActions } from './hooks/useCookieModal';
import { useSelector } from 'react-redux';
import { cookieSelectors } from './services/selector';
import { useLocalization } from '@/Hooks';

export function CookieModal() {
  const { onClose } = useCookieModalActions();
  const { isModalVisible } = useSelector(cookieSelectors.cookieData);

  const {
    modalWindows: {
      cookieModal: { Text },
    },

    buttons: { Accept },
  } = useLocalization();

  return (
    <Modal isOpen={isModalVisible} modalClassName={styles.modal} onClose={onClose}>
      <div className={styles.cookieModal}>
        <p>{Text}</p>
        <Button text={Accept} className={styles.buttonSize} onClick={onClose}></Button>
      </div>
    </Modal>
  );
}
