import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Pair } from '@/Types';
import { RootState } from '@/Redux/RootReducer';
import {
  CreateGeneratorCountryZoneRequest,
  CreateGeneratorCountryZoneResponse,
  UpdateGeneratorCountryZoneRequest,
  UpdateGeneratorCountryZoneResponse,
} from '@/Api/GeneratorCountryZones';
import { GeneratorCountryZonesApi } from '@/Api/GeneratorCountryZones/GeneratorCountryZonesApi';
import { GeneratorCountryZoneCountryModel, GeneratorCountryZoneModel } from '@/Models';
import { CountryCode, GeneratorCountryZoneModalType } from '@/Enums';
import { countryZoneUtils } from './utils';

const getGeneratorCountryZonesRequest = createAsyncThunk(
  'generatorCountryZones/get-generator-country-zones',
  async (params: CountryCode, api) => {
    const state: RootState = api.getState() as RootState;
    const response = await GeneratorCountryZonesApi.getGeneratorCountryZonesGroupedByCountry(params);
    response.countryZones.forEach((x) => countryZoneUtils.fillLocalization(x, state));
    return response;
  },
);

const createGeneratorCountryZoneRequest = createAsyncThunk<
  CreateGeneratorCountryZoneResponse,
  CreateGeneratorCountryZoneRequest
>('generatorCountryZones/create-generator-country-zone', async (request: CreateGeneratorCountryZoneRequest) => {
  const response = await GeneratorCountryZonesApi.createGeneratorCountryZone(request);
  return response;
});

const updateGeneratorCountryZoneRequest = createAsyncThunk<
  UpdateGeneratorCountryZoneResponse,
  UpdateGeneratorCountryZoneRequest
>('generatorCountryZones/update-generator-country-zone', async (request: UpdateGeneratorCountryZoneRequest) => {
  const response = await GeneratorCountryZonesApi.updateGeneratorCountryZone(request);
  return response;
});

const deleteGeneratorCountryZoneRequest = createAsyncThunk<number, number>(
  'generatorCountryZones/delete-generator-country-zone',
  async (id: number) => {
    return GeneratorCountryZonesApi.deleteGeneratorCountryZone(id);
  },
);

export type GeneratorCountryZoneState = {
  countryZones: GeneratorCountryZoneCountryModel[];
  selectedUuid: string;
  logisticTypeOptions: Pair<string | number>[];
  modalWindowType: GeneratorCountryZoneModalType;
  selectedCountryCode: CountryCode | null;
};

const initialState: GeneratorCountryZoneState = {
  countryZones: [],
  selectedUuid: '',
  logisticTypeOptions: [],
  selectedCountryCode: null,
  modalWindowType: GeneratorCountryZoneModalType.None,
};

const generationProductsSlice = createSlice({
  name: 'generatorCountryZonesState',
  initialState,
  reducers: {
    setLogisticTypes(state, action: PayloadAction<Pair<string | number>[]>) {
      state.logisticTypeOptions = action.payload;
    },
    setSelectedUuid(state, action: PayloadAction<string>) {
      state.selectedUuid = action.payload;
    },
    setSelectedCountryCode: (state, action: PayloadAction<CountryCode>) => {
      state.selectedCountryCode = action.payload;
    },
    addCountryZone(state, action: PayloadAction<GeneratorCountryZoneModel>) {
      countryZoneUtils.addZone(state.countryZones, action.payload);
    },
    deleteCountryZone(state, action: PayloadAction<GeneratorCountryZoneModel>) {
      countryZoneUtils.deleteZone(state.countryZones, action.payload.id);
    },
    openModal(state, action: PayloadAction<GeneratorCountryZoneModalType>) {
      state.modalWindowType = action.payload;
    },
    closeModal(state) {
      state.modalWindowType = GeneratorCountryZoneModalType.None;
    },
    clearData(state) {
      state.logisticTypeOptions = [];
      state.countryZones = [];
      state.selectedUuid = '';
      state.modalWindowType = GeneratorCountryZoneModalType.None;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneratorCountryZonesRequest.fulfilled, (state, action) => {
        state.countryZones = action.payload.countryZones.sort((x, y) => (x.countryName > y.countryName ? 1 : -1));
      })
      .addCase(createGeneratorCountryZoneRequest.fulfilled, (state, action) => {
        countryZoneUtils.updateZone(state.countryZones, action.payload.countryZone, state.selectedUuid);
        state.selectedUuid = '';
      })
      .addCase(updateGeneratorCountryZoneRequest.fulfilled, (state, action) => {
        countryZoneUtils.updateZone(state.countryZones, action.payload.countryZone);
        state.selectedUuid = '';
      })
      .addCase(deleteGeneratorCountryZoneRequest.fulfilled, (state, action) => {
        countryZoneUtils.deleteZone(state.countryZones, action.payload);
        state.selectedUuid = '';
      });
  },
});

const { actions, reducer } = generationProductsSlice;

export const generatorCountryZonesActions = actions;
export const generatorCountryZonesReducer = reducer;
export const generatorCountryZonesAsyncActions = {
  getGeneratorCountryZonesRequest,
  createGeneratorCountryZoneRequest,
  updateGeneratorCountryZoneRequest,
  deleteGeneratorCountryZoneRequest,
};
