import { GenLogisticTypeModel } from '@/Models';
import { createSlice } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';

export type GenLogisticTypesDataState = {
  logisticTypes: GenLogisticTypeModel[];
};

const initialState: GenLogisticTypesDataState = {
  logisticTypes: [],
};

const genLogisticTypesDataSlice = createSlice({
  name: 'genLogisticTypesData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getLogisticTypes.fulfilled, (state, action) => {
        state.logisticTypes = action.payload;
      })
      .addCase(asyncActions.createLogisticType.fulfilled, (state, action) => {
        state.logisticTypes.push(action.payload.logisticType);
      })
      .addCase(asyncActions.updateLogisticType.fulfilled, (state, action) => {
        state.logisticTypes = state.logisticTypes.map((item) =>
          item.id === action.payload.logisticType.id ? action.payload.logisticType : item,
        );
      })
      .addCase(asyncActions.deleteLogisticType.fulfilled, (state, action) => {
        state.logisticTypes = state.logisticTypes.filter((item) => item.id !== action.payload);
      });
  },
});

export const genLogisticTypesDataReducer = genLogisticTypesDataSlice.reducer;
export const genLogisticTypesDataActions = genLogisticTypesDataSlice.actions;
export const genLogisticTypesDataAsyncActions = asyncActions;
