import { useOrderFulfillment } from 'src/Components/Header/modules/Menu/hooks/useOrderFullfilment';
import { useMemo } from 'react';
import { useAppRouting } from 'src/Hooks/useAppRouting';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from 'src/Pages/Sales/services';
import { globalSelectors } from 'src/Redux/Global/selectors';
import { useLocalization } from 'src/Hooks';
import { paymentStatusUtils, TermsOfPaymentType } from 'src/Enums';
import { localizationUtils } from 'src/Utils/localizationUtils';

export const useChangeDeliveryStatus = () => {
  const { changeOrderStatusDisabledProps } = useOrderFulfillment();

  const { isSalesPage } = useAppRouting();
  const selectedSales = useSelector(salesPageSelectors.selectedSales);
  const localization = useSelector(globalSelectors.localization);

  const {
    menu: { Tooltips },
  } = useLocalization();

  const changeDeliveryStatusProps = useMemo((): { message: string; isDisabled: boolean } => {
    // Using default errors
    if (changeOrderStatusDisabledProps.isDocumentIconDisabled) {
      return {
        isDisabled: true,
        message: changeOrderStatusDisabledProps.disabledDocumentMessage,
      };
    }

    if (!isSalesPage) {
      return {
        isDisabled: false,
        message: '',
      };
    }

    const notPostpaidNotPaidOrders = selectedSales.filter(
      (x) => x.termsOfPayment !== TermsOfPaymentType.Postpaid && !paymentStatusUtils.isOrderPaid(x.paymentStatus),
    );

    if (notPostpaidNotPaidOrders.length) {
      return {
        isDisabled: true,
        message: localizationUtils.insertTranslatedParamsToString(
          Tooltips.ChangeDeliveryStatusNotPostpaidNotPaid.Message,
          [notPostpaidNotPaidOrders.length, notPostpaidNotPaidOrders.map((x) => x.userOrderId).join(', ')],
          Tooltips.parts,
          localization,
        ),
      };
    }

    return { message: '', isDisabled: false };
  }, [
    changeOrderStatusDisabledProps,
    selectedSales,
    isSalesPage,
    Tooltips,
    localization,
    paymentStatusUtils,
    localizationUtils,
  ]);
  return {
    isChangeDeliveryStatusDisabled: changeDeliveryStatusProps.isDisabled,
    changeDeliveryStatusDisabledMessage: changeDeliveryStatusProps.message,
  };
};
