import { LogisticsApi } from '@/Api/Logistics';
import { GetPackagesForOrderRequest } from '@/Api/Logistics/GetPackagesForOrderRequest';
import { PrepareLabelsRequest } from '@/Api/Logistics/PrepareLabelsRequest';
import { MpOrdersApi } from '@/Api/MpOrders';
import { GetOrdersAutomatizationRequest } from '@/Api/MpOrders/Requests/GetOrdersAutomatizationRequest';
import { RootState } from '@/Redux/RootReducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { orderAutomatizationModalUtils } from './utils';
import { GetPacklinkProductsRequest } from '@/Api/Logistics/GetPacklinkProductsRequest';

const getOrdersAutomatizationRequest = createAsyncThunk(
  'orderAutomatization/get-orders-automatization',
  async (request: GetOrdersAutomatizationRequest) => {
    return MpOrdersApi.getOrdersAutomatization(request);
  },
);

const getPackagesForOrderRequest = createAsyncThunk(
  'orderAutomatization/get-packages-for-orders',
  async (request: GetPackagesForOrderRequest, api) => {
    const state = api.getState() as RootState;

    const selectedOrderPackages = state.modalWindows.orderAutomatizationState.orderPackages.find(
      (op) => op.orderId === request.orderId && op.isOwnContract === request.isOwnContract,
    );

    if (selectedOrderPackages?.packages.length) {
      return;
    } // if

    const result = await LogisticsApi.getPackagesForOrders(request);

    orderAutomatizationModalUtils.fillProductsLocalization(result.packages, state);

    return {
      ...result,
      isOwnContract: request.isOwnContract,
    };
  },
);

const prepareLabelsRequest = createAsyncThunk<void, PrepareLabelsRequest>(
  'orderAutomatization/prepare-labels-request',
  async (request: PrepareLabelsRequest) => {
    return LogisticsApi.prepareLabels(request);
  },
);

const getPacklinkProducts = createAsyncThunk(
  'orderAutomatization/get-packlink-products',
  async (request: GetPacklinkProductsRequest) => {
    return LogisticsApi.getPacklinkProducts(request);
  },
);

export const asyncActions = {
  getOrdersAutomatizationRequest,
  getPackagesForOrderRequest,
  prepareLabelsRequest,
  getPacklinkProducts,
};
