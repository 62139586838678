import { AccountChartSettingsApi } from '@/Api/_Microservices/OnlineArchive/AccountChartSettings/AccountChartSettingsApi';
import { AccountChartTypesApi } from '@/Api/_Microservices/OnlineArchive/AccountChartTypes/AccountChartTypesApi';
import { AccountChartsApi } from '@/Api/_Microservices/OnlineArchive/AccountCharts/AccountChartsApi';
import { GetAvailableAccountChartTypesRequest } from '@/Api/_Microservices/OnlineArchive/AccountChartTypes/GetAvailableAccountChartTypesRequest';
import { GetAccountChartsForPreviewRequest } from '@/Api/_Microservices/OnlineArchive/AccountCharts/GetAccountChartsForPreviewRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SetAccountChartSettingRequest } from '@/Api/_Microservices/OnlineArchive/AccountChartSettings/SetAccountChartSettingRequest';
import { CreateAccountChartRequest } from '@/Api/_Microservices/OnlineArchive/AccountCharts/CreateAccountChartRequest';
import { UpdateAccountChartRequest } from '@/Api/_Microservices/OnlineArchive/AccountCharts/UpdateAccountChartRequest';
import { DeleteAccountChartRequest } from '@/Api/_Microservices/OnlineArchive/AccountCharts/DeleteAccountChartRequest';
import { GetAccountChartsRequest } from '@/Api/_Microservices/OnlineArchive/AccountCharts/GetAccountChartsRequest';

/** Account charts async actions */
const getAccountCharts = createAsyncThunk(
  'accountChartsData/get-account-charts',
  async (request: GetAccountChartsRequest) => {
    return AccountChartsApi.user.getAccountCharts(request);
  },
);

const getAccountChartsForPreview = createAsyncThunk(
  'accountChartsData/get-account-charts-for-preview',
  async (request: GetAccountChartsForPreviewRequest) => {
    return AccountChartsApi.user.getAccountChartsForPreview(request);
  },
);

const createAccountChart = createAsyncThunk(
  'accountChartsData/create-account-chart',
  async (request: CreateAccountChartRequest) => {
    return AccountChartsApi.user.createAccountChart(request);
  },
);

const updateAccountChart = createAsyncThunk(
  'accountChartsData/update-account-chart',
  async (request: UpdateAccountChartRequest) => {
    return AccountChartsApi.user.updateAccountChart(request);
  },
);

const deleteAccountChart = createAsyncThunk(
  'accountChartsData/delete-account-chart',
  async (request: DeleteAccountChartRequest) => {
    return AccountChartsApi.user.deleteAccountChart(request);
  },
);

/** Account chart setting async actions */
const setAccountChartSetting = createAsyncThunk(
  'accountChartsData/set-account-chat-setting',
  async (request: SetAccountChartSettingRequest) => {
    return AccountChartSettingsApi.setAccountChartSetting(request);
  },
);

/** Account chart types async actions */
const getAvailableAccountChartTypes = createAsyncThunk(
  'accountChartsData/get-available-account-chart-types',
  async (request: GetAvailableAccountChartTypesRequest) => {
    return AccountChartTypesApi.user.getAvailableAccountChartTypes(request);
  },
);

export const asyncActions = {
  getAccountCharts,
  getAccountChartsForPreview,
  createAccountChart,
  updateAccountChart,
  deleteAccountChart,
  getAvailableAccountChartTypes,
  setAccountChartSetting,
};
