import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetAutomationRulesRequest,
  GetAutomationRulesResponse,
  CreateAutomationRuleRequest,
  CreateAutomationRuleResponse,
  UpdateAutomationRulesRequest,
  UpdateAutomationRulesResponse,
  DeleteAutomationRuleRequest,
  DeleteAutomationRuleResponse,
  ShippingAutomationApi,
} from '@/Api/ShippingAutomation';

const getAutomationRules = createAsyncThunk<GetAutomationRulesResponse, GetAutomationRulesRequest>(
  'shipping-automation/get-automation-rules',
  async (request: GetAutomationRulesRequest) => {
    return ShippingAutomationApi.getAutomationRules(request);
  },
);

const createAutomationRule = createAsyncThunk<CreateAutomationRuleResponse, CreateAutomationRuleRequest>(
  'shipping-automation/create-automation-rule',
  async (request: CreateAutomationRuleRequest) => {
    return ShippingAutomationApi.createAutomationRule(request);
  },
);

const updateAutomationRules = createAsyncThunk<UpdateAutomationRulesResponse, UpdateAutomationRulesRequest>(
  'shipping-automation/update-automation-rules',
  async (request: UpdateAutomationRulesRequest) => {
    return ShippingAutomationApi.updateAutomationRules(request);
  },
);

const deleteAutomationRule = createAsyncThunk<DeleteAutomationRuleResponse, DeleteAutomationRuleRequest>(
  'shipping-automation/delete-automation-rule',
  async (request: DeleteAutomationRuleRequest) => {
    return ShippingAutomationApi.deleteAutomationRule(request);
  },
);

export const asyncActions = {
  getAutomationRules,
  createAutomationRule,
  updateAutomationRules,
  deleteAutomationRule,
};
