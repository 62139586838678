import { useAccountSettings } from '../modules/AccountSettings/AccountSettings';
import { useAddressBookSettings } from '../modules/AddressBookSettings/AddressBookSettings';
import { useMemo } from 'react';
import { PagesRouting } from '@/Routing';
import { globalHistory } from '@/GlobalHistory';

export function useTours(runTour: boolean, currentTour: string) {
  const route = globalHistory.location.pathname;
  const accountSettings = useAccountSettings();
  const addressBookSettings = useAddressBookSettings();

  return useMemo(() => {
    const currentPageTours = {
      [PagesRouting.SettingsPages.AccountSettings]: accountSettings,
      [PagesRouting.SettingsPages.AddressBookSettings]: addressBookSettings,
    }[route];

    if (!currentPageTours) return [];

    return currentPageTours[currentTour as keyof typeof currentPageTours];
  }, [route, runTour]);
}
