export const BUTTON_THEMES = {
  SIMPLE: {
    backgroundColor: 'transparent',
    color: '#4298e0',
    border: '1px solid #4298e0',
  },
  PRIMARY: {
    backgroundColor: '#4298e0',
    color: 'white',
  },
  PRIMARY_LIGHT: {
    backgroundColor: '#AADEFF',
    color: '#5E7380',
  },
  SUCCESS: {
    backgroundColor: '#83BA63',
    color: 'white',
  },
  SUCCESS_LIGHT: {
    backgroundColor: '#cde3c1',
    color: '#2c2c2c',
  },
  SUCCESS_TRANSPARENT: {
    backgroundColor: 'white',
    color: '#83BA63',
    border: '1px solid #83BA63',
  },
  INFO_LIGHT: {
    backgroundColor: '#ffdec0',
    color: '#2c2c2c',
  },
  FILLED_BLUE: {
    backgroundColor: '#AADEFF',
    color: '#5E7380',
  },
  FILLED_RED: {
    backgroundColor: '#FFC2BA',
    color: '#2C2C2C',
  },
  TRANSPARENT: {
    backgroundColor: 'transparent',
    color: '#83BA63',
  },
} as const;
