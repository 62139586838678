import {
  CopyTemplateRequest,
  DocumentTemplateApi,
  RestoreTemplateRequest,
  UpdateDocumentTemplateRequest,
} from '@/Api/DocumentTemplates';
import { DocumentTemplateType } from '@/Enums/DocumentTemplateType';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getEmailTemplates = createAsyncThunk('emailTemplatesSettings/get-email-templates', async () => {
  return DocumentTemplateApi.getAllDocumentTemplates(DocumentTemplateType.Email);
});

const updateEmailTemplate = createAsyncThunk(
  'emailTemplatesSettings/update-email-template',
  async (request: UpdateDocumentTemplateRequest) => {
    return DocumentTemplateApi.updateDocumentTemplate(request);
  },
);

const restoreEmailTemplate = createAsyncThunk(
  'emailTemplatesSettings/restore-email-template',
  async (request: RestoreTemplateRequest) => {
    return DocumentTemplateApi.restoreDocumentTemplate(request);
  },
);

const copyEmailTemplate = createAsyncThunk(
  'emailTemplatesSettings/copy-email-template',
  async (request: CopyTemplateRequest) => {
    return DocumentTemplateApi.copyDocumentTemplate(request);
  },
);

const deleteEmailTemplate = createAsyncThunk(
  'emailTemplatesSettings/delete-email-template',
  async (request: string) => {
    return DocumentTemplateApi.deleteDocumentTemplate(request);
  },
);

export const asyncActions = {
  getEmailTemplates,
  updateEmailTemplate,
  restoreEmailTemplate,
  copyEmailTemplate,
  deleteEmailTemplate,
};
