import { createSlice, PayloadAction, combineReducers } from '@reduxjs/toolkit';
import { PageModalType } from '@/Enums';
import { generatorProductsReducer, GeneratorProductState } from '../modules/GeneratorProducts/services';
import { userProductsPageReducer, UserProductsState } from '../modules/UserProducts/services/reducer';
import { generatorCountryZonesReducer, GeneratorCountryZoneState } from '../modules/GeneratorCountryZones/services';
import { applicationAccessReducer, ApplicationAccessState } from '../modules/ApplicationAccess/services';
import { GeneratorCountryZoneModel } from '@/Models';
import { warehouseLogisticsPageAsyncActions } from './asyncActions';
import {
  logisticsLogoSettingsReducer,
  LogisticsLogoSettingsState,
} from '../modules/LogisticsLogoSettings/services/reducer';

export type WarehouseLogisticsPageState = {
  countryZones: GeneratorCountryZoneModel[];
  productModal: PageModalType;
};

const initialState: WarehouseLogisticsPageState = {
  countryZones: [],
  productModal: PageModalType.None,
};

const warehouseLogisticsPageSlice = createSlice({
  name: 'warehouseLogisticsPageState',
  initialState,
  reducers: {
    setModalType(state, action: PayloadAction<PageModalType>) {
      state.productModal = action.payload;
    },
    closeModal(state) {
      state.productModal = PageModalType.None;
    },
    clearData(state) {
      state.productModal = PageModalType.None;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(warehouseLogisticsPageAsyncActions.getGeneratorCountryZonesRequest.fulfilled, (state, action) => {
      state.countryZones = action.payload.countryZones;
    });
  },
});

export const warehouseLogisticsPageActions = warehouseLogisticsPageSlice.actions;
export const warehouseLogisticsPageReducer = warehouseLogisticsPageSlice.reducer;

export type WarehouseLogisticsPageCombineState = {
  warehouseLogisticsPage: WarehouseLogisticsPageState;
  userProducts: UserProductsState;
  generationTemplates: GeneratorProductState;
  countryZones: GeneratorCountryZoneState;
  applicationAccess: ApplicationAccessState;
  logisticsLogoSettings: LogisticsLogoSettingsState;
};

export const warehouseLogisticsPageCombineReducer = combineReducers<WarehouseLogisticsPageCombineState>({
  warehouseLogisticsPage: warehouseLogisticsPageReducer,
  userProducts: userProductsPageReducer,
  generationTemplates: generatorProductsReducer,
  countryZones: generatorCountryZonesReducer,
  applicationAccess: applicationAccessReducer,
  logisticsLogoSettings: logisticsLogoSettingsReducer,
});
