import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { PagesRouting } from '@/Routing';

// Hooks import
import { useLocalization } from '@/Hooks';

// Enums import
import { LocalizationType } from '@/Enums';

// Styles import
import styles from './AuthorizationHeader.scss';
import { useLanguageSelectOptions } from '@/Hooks/useLanguageSelectOptions';
import { globalSelectors } from '@/Redux/Global/selectors';
import { globalAsyncActions } from '@/Redux/Global/asyncActions';
import { Link } from '../Controls/Link/Link';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { SelectInput } from '@/Components/Controls/SelectInput/SelectInput';
import { ThemeSwitcher } from './modules/ThemeSwitcher/ThemeSwitcher';

export function AuthorizationHeader(): React.ReactElement {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(globalSelectors.selectedLanguage);

  const route = useLocation<string>();
  const isRegistration = route.pathname === PagesRouting.AuthorizationPages.RegistrationPage;

  const {
    authorizationPage: { AlreadyRegistered },
  } = useLocalization();

  useEffect(() => {
    dispatch(globalAsyncActions.getTranslation(selectedLanguage.key));
  }, []);

  const languageOptions = useLanguageSelectOptions();
  return (
    <header className={styles.authorizationHeader}>
      <div className={styles.authorizationLoginLink}>
        {isRegistration && (
          <>
            <span>{AlreadyRegistered}</span>
            <Link to={PagesRouting.AuthorizationPages.LoginPage}>
              <Button text={'Login'} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} />
            </Link>
          </>
        )}
      </div>

      <ThemeSwitcher />

      <SelectInput
        options={languageOptions}
        name={'LanguageSelect'}
        className={styles.authorizationLanguage}
        onChange={(value) => {
          if (!value) return;
          dispatch(globalAsyncActions.getTranslation(value.key as LocalizationType));
        }}
        value={selectedLanguage}
      />
    </header>
  );
}
