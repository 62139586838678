import React, { useEffect } from 'react'; //
import { useDispatch } from 'react-redux';
import { useLocalization } from '@/Hooks';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { ModalProps, OrderLabelPrintRow } from '@/Types';
import styles from './OrderLabelPrintModal.scss';
import { orderLabelPrintActions, orderLabelPrintAsyncActions } from './services';
import { OrderLabelTable } from './modules/OrderLabelTable/OrderLabelTable';
import { shippingLabelModalActions } from '../ShippingLabelModal/services';
import { ShippingLabelStep } from '../ShippingLabelModal/Types';
import { Modal } from '@/Components/Modal/Modal';
import { TreeViewSelectInput } from '@/Components/Controls/SelectInput/TreeViewSelectInput';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { orderAutomatizationActions } from '../OrderAutomatizationModal/services/reducer';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { useOrderLabelStatusOptions } from '@/Enums/OrderLabelStatusHooks';
import { TimeRange } from '@/Components/Controls/TimeRange/TimeRange';
import { FormDateInput } from '@/Components/Controls/DateInput/FormDateInput';
import moment from 'moment-timezone';
import { dateTimeUtils } from '@/Utils';
import { useOrderLabelPrintForm, FormData } from './hooks/useOrderLabelPrintForm';
import { useOrderLabelPrintOptions } from './hooks/useOrderLabelPrintOptions';

type Props = {
  isSales: boolean;
} & ModalProps;

const defaultDate = moment().startOf('day').add(1, 'day').format('DD.MM.YYYY');
const defaultRange = [
  moment().startOf('day').add(8, 'hours').toDate(),
  moment().startOf('day').add(18, 'hours').toDate(),
];

export function OrderLabelPrintModal({ isSales, closeModalWindow }: Props) {
  const dispatch: AppDispatch = useDispatch();

  const {
    buttons,
    modalWindows: {
      orderLabelPrint: { Title, Buttons },
    },
  } = useLocalization();

  const {
    methods: { unregister, setValue, register, onSubmit, onPrintLabels },
  } = useOrderLabelPrintForm(closeModalWindow);

  const {
    selectedOrders,
    selectedOrderLabelPrintRows,
    logisticTypesOptions,
    logisticTypesTreeViewItems,
    filterLogistics,
    filterStatus,
    isAllSelectedCreated,
    isAllLabelsIsPickup,
  } = useOrderLabelPrintOptions(isSales);

  const onChangeRange = (interval: Date[]) => {
    const startTime = dateTimeUtils.convertDateToHours(interval[0], true, true);
    const endTime = dateTimeUtils.convertDateToHours(interval[1], true, true);
    setValue('timeRange', `${startTime}-${endTime}`);
  };

  const editRowClick = (row: OrderLabelPrintRow): void => {
    const orders = selectedOrders.filter((x) => x.id === row.orderId);
    if (orders) {
      dispatch(orderAutomatizationActions.setSelectedOrders(orders.map((x) => x.id)));
      dispatch(shippingLabelModalActions.setShippingLabelStep(ShippingLabelStep.OrderWithoutAutomatizaion));
      dispatch(shippingLabelModalActions.setIsEditing(true));
    }
  };

  useEffect(() => {
    setValue('filterLogistics', filterLogistics as string[]);
    setValue('filterStatus', filterStatus);
  }, [filterLogistics, filterStatus]);

  useEffect(() => {
    onChangeRange(defaultRange);

    if (selectedOrders?.length > 0) {
      dispatch(orderLabelPrintActions.setLogisticTypes(logisticTypesOptions));
      dispatch(
        orderLabelPrintAsyncActions.getOrderLabelInfoRequest({
          orderIds: selectedOrders.map((x) => x.id),
        }),
      );
    }

    register('filterLogistics');
    register('filterStatus');

    return () => {
      unregister('filterLogistics');
      unregister('filterStatus');

      dispatch(orderLabelPrintActions.resetState());
    };
  }, []);

  return (
    <Modal modalClassName={styles.modal} onClose={closeModalWindow} shouldCloseOnOverlayClick={false}>
      <h3 className={styles.modalTitle}>{Title}</h3>

      <form className={styles.formWrapper} onSubmit={onSubmit}>
        <div className={styles.filterWrapper}>
          <TreeViewSelectInput
            name={'filterLogistics'}
            onChange={(values: TreeItemType[]) => {
              const ids = values.map((el) => el.key);
              setValue('filterLogistics', ids);
            }}
            options={logisticTypesTreeViewItems}
          />

          <FormSelectInput<FormData>
            name={'filterStatus'}
            options={useOrderLabelStatusOptions()}
            setValue={setValue}
            register={register}
            needClearButton={false}
          />
          <Button text={buttons.Search} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} type={'submit'} />
        </div>

        {!isAllLabelsIsPickup && (
          <div className={styles.dateWrapper}>
            <FormDateInput<FormData>
              name={'date'}
              register={register}
              setValue={setValue}
              pickerSetValue={setValue}
              valueForSet={defaultDate}
            />
            <TimeRange defaultRange={defaultRange} onChange={onChangeRange} />
          </div>
        )}

        <div className={styles.tableWrapper}>
          <OrderLabelTable editRowClick={editRowClick} />
        </div>
        <div className={styles.buttonsWrapper}>
          <Button text={buttons.Cancel} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} onClick={closeModalWindow} />
          <Button
            text={Buttons.CreateLabes}
            theme={BUTTON_THEMES.SUCCESS}
            onClick={() => onPrintLabels(false)}
            disabled={isAllSelectedCreated}
          />
          <Button
            text={Buttons.PrintLables}
            theme={BUTTON_THEMES.SUCCESS}
            onClick={() => onPrintLabels(true)}
            disabled={!selectedOrderLabelPrintRows.every((x) => x.isCreated)}
          />
          <Button
            text={Buttons.CreatePrintLables}
            theme={BUTTON_THEMES.SUCCESS}
            onClick={() => onPrintLabels(true)}
            disabled={isAllSelectedCreated}
          />
        </div>
      </form>
    </Modal>
  );
}
