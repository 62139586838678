import { useMemo, useState } from 'react';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { useSelector } from 'react-redux';
import { warehouseLogisticsPageSelectors } from '@/Pages/Administration/LogisticProducts/services';
import { CountryCode } from '@/Enums';

export function useCountryZoneLevelTreeViewOptions(
  logisticTypeId: string | number,
  countryCode?: CountryCode,
  defaultValues?: (string | number)[],
) {
  const countryZones = useSelector(warehouseLogisticsPageSelectors.countryZones(logisticTypeId, countryCode));

  const [needToReset, setNeedToReset] = useState(false);

  const countryZoneLevelsTreeViewItems = useMemo<TreeItemType<string | number>[]>(() => {
    setNeedToReset(false);

    return countryZones.map((zone) => {
      return {
        key: zone.key,
        value: zone.value,
        selected: defaultValues?.includes(zone.key),
        expanded: true,
        items: zone.levels.map((level) => {
          return {
            key: level.key,
            value: level.value,
            selected: defaultValues?.includes(level.key),
          };
        }),
      };
    });
  }, [countryCode, needToReset, defaultValues, logisticTypeId, countryZones.length]);

  return {
    countryZoneLevelsTreeViewItems,
    setNeedToReset,
  };
}
