import { CountryCode, CountryType } from '@/Enums';
import { TranslationType } from '@/Localization/LanguageKeys';

const countryTypeMapping: { [key in CountryCode]: CountryType } = {
  [CountryCode.AF]: CountryType.Afghanistan,
  [CountryCode.AX]: CountryType.AlandIslands,
  [CountryCode.AL]: CountryType.Albania,
  [CountryCode.DZ]: CountryType.Algeria,
  [CountryCode.AS]: CountryType.AmericanSamoa,
  [CountryCode.AD]: CountryType.Andorra,
  [CountryCode.AO]: CountryType.Angola,
  [CountryCode.AI]: CountryType.Anguilla,
  [CountryCode.AQ]: CountryType.Antarctica,
  [CountryCode.AG]: CountryType.AntiguaAndBarbuda,
  [CountryCode.AR]: CountryType.Argentina,
  [CountryCode.AM]: CountryType.Armenia,
  [CountryCode.AW]: CountryType.Aruba,
  [CountryCode.AU]: CountryType.Australia,
  [CountryCode.AT]: CountryType.Austria,
  [CountryCode.AZ]: CountryType.Azerbaijan,
  [CountryCode.BS]: CountryType.Bahamas,
  [CountryCode.BH]: CountryType.Bahrain,
  [CountryCode.BD]: CountryType.Bangladesh,
  [CountryCode.BB]: CountryType.Barbados,
  [CountryCode.BY]: CountryType.Belarus,
  [CountryCode.BE]: CountryType.Belgium,
  [CountryCode.BZ]: CountryType.Belize,
  [CountryCode.BJ]: CountryType.Benin,
  [CountryCode.BM]: CountryType.Bermuda,
  [CountryCode.BT]: CountryType.Bhutan,
  [CountryCode.BO]: CountryType.Bolivia,
  [CountryCode.BA]: CountryType.BosniaAndHerzegovina,
  [CountryCode.BW]: CountryType.Botswana,
  [CountryCode.BV]: CountryType.BouvetIsland,
  [CountryCode.BR]: CountryType.Brazil,
  [CountryCode.IO]: CountryType.BritishIndianOceanTerritory,
  [CountryCode.BN]: CountryType.Brunei,
  [CountryCode.BG]: CountryType.Bulgaria,
  [CountryCode.BF]: CountryType.BurkinaFaso,
  [CountryCode.MM]: CountryType.BurmaMyanmar,
  [CountryCode.BI]: CountryType.Burundi,
  [CountryCode.KH]: CountryType.Cambodia,
  [CountryCode.CM]: CountryType.Cameroon,
  [CountryCode.CA]: CountryType.Canada,
  [CountryCode.CV]: CountryType.CapeVerde,
  [CountryCode.KY]: CountryType.CaymanIslands,
  [CountryCode.CF]: CountryType.CentralAfricanRepublic,
  [CountryCode.TD]: CountryType.Chad,
  [CountryCode.CL]: CountryType.Chile,
  [CountryCode.CN]: CountryType.China,
  [CountryCode.CX]: CountryType.ChristmasIsland,
  [CountryCode.CC]: CountryType.CocosKeelingIslands,
  [CountryCode.CO]: CountryType.Colombia,
  [CountryCode.KM]: CountryType.Comoros,
  [CountryCode.CD]: CountryType.CongoDemRepublic,
  [CountryCode.CG]: CountryType.CongoRepublic,
  [CountryCode.CK]: CountryType.CookIslands,
  [CountryCode.CR]: CountryType.CostaRica,
  [CountryCode.HR]: CountryType.Croatia,
  [CountryCode.CU]: CountryType.Cuba,
  [CountryCode.CY]: CountryType.Cyprus,
  [CountryCode.CZ]: CountryType.CzechRepublic,
  [CountryCode.DK]: CountryType.Denmark,
  [CountryCode.DJ]: CountryType.Djibouti,
  [CountryCode.DM]: CountryType.Dominica,
  [CountryCode.DO]: CountryType.DominicanRepublic,
  [CountryCode.TL]: CountryType.EastTimor,
  [CountryCode.EC]: CountryType.Ecuador,
  [CountryCode.EG]: CountryType.Egypt,
  [CountryCode.SV]: CountryType.ElSalvador,
  [CountryCode.GQ]: CountryType.EquatorialGuinea,
  [CountryCode.ER]: CountryType.Eritrea,
  [CountryCode.EE]: CountryType.Estonia,
  [CountryCode.ET]: CountryType.Ethiopia,
  [CountryCode.FK]: CountryType.FalklandIslands,
  [CountryCode.FO]: CountryType.FaroeIslands,
  [CountryCode.FJ]: CountryType.Fiji,
  [CountryCode.FI]: CountryType.Finland,
  [CountryCode.FR]: CountryType.France,
  [CountryCode.GF]: CountryType.FrenchGuiana,
  [CountryCode.PF]: CountryType.FrenchPolynesia,
  [CountryCode.TF]: CountryType.FrenchSouthernTerritories,
  [CountryCode.GA]: CountryType.Gabon,
  [CountryCode.GM]: CountryType.Gambia,
  [CountryCode.GE]: CountryType.Georgia,
  [CountryCode.DE]: CountryType.Germany,
  [CountryCode.GH]: CountryType.Ghana,
  [CountryCode.GI]: CountryType.Gibraltar,
  [CountryCode.GR]: CountryType.Greece,
  [CountryCode.GL]: CountryType.Greenland,
  [CountryCode.GD]: CountryType.Grenada,
  [CountryCode.GP]: CountryType.Guadeloupe,
  [CountryCode.GU]: CountryType.Guam,
  [CountryCode.GT]: CountryType.Guatemala,
  [CountryCode.GG]: CountryType.Guernsey,
  [CountryCode.GN]: CountryType.Guinea,
  [CountryCode.GW]: CountryType.GuineaBissau,
  [CountryCode.GY]: CountryType.Guyana,
  [CountryCode.HT]: CountryType.Haiti,
  [CountryCode.HM]: CountryType.HeardIslandAndMcDonaldIslands,
  [CountryCode.HN]: CountryType.Honduras,
  [CountryCode.HK]: CountryType.HongKong,
  [CountryCode.HU]: CountryType.Hungary,
  [CountryCode.IS]: CountryType.Iceland,
  [CountryCode.IN]: CountryType.India,
  [CountryCode.ID]: CountryType.Indonesia,
  [CountryCode.IR]: CountryType.Iran,
  [CountryCode.IQ]: CountryType.Iraq,
  [CountryCode.IE]: CountryType.Ireland,
  [CountryCode.IL]: CountryType.Israel,
  [CountryCode.IT]: CountryType.Italy,
  [CountryCode.CI]: CountryType.IvoryCoast,
  [CountryCode.JM]: CountryType.Jamaica,
  [CountryCode.JP]: CountryType.Japan,
  [CountryCode.JE]: CountryType.Jersey,
  [CountryCode.JO]: CountryType.Jordan,
  [CountryCode.KZ]: CountryType.Kazakhstan,
  [CountryCode.KE]: CountryType.Kenya,
  [CountryCode.KI]: CountryType.Kiribati,
  [CountryCode.KP]: CountryType.KoreaDemRepublicOf,
  [CountryCode.KW]: CountryType.Kuwait,
  [CountryCode.KG]: CountryType.Kyrgyzstan,
  [CountryCode.LA]: CountryType.Laos,
  [CountryCode.LV]: CountryType.Latvia,
  [CountryCode.LB]: CountryType.Lebanon,
  [CountryCode.LS]: CountryType.Lesotho,
  [CountryCode.LR]: CountryType.Liberia,
  [CountryCode.LY]: CountryType.Libya,
  [CountryCode.LI]: CountryType.Liechtenstein,
  [CountryCode.LT]: CountryType.Lithuania,
  [CountryCode.LU]: CountryType.Luxemburg,
  [CountryCode.MO]: CountryType.Macau,
  [CountryCode.MK]: CountryType.Macedonia,
  [CountryCode.MG]: CountryType.Madagascar,
  [CountryCode.MW]: CountryType.Malawi,
  [CountryCode.MY]: CountryType.Malaysia,
  [CountryCode.MV]: CountryType.Maldives,
  [CountryCode.ML]: CountryType.Mali,
  [CountryCode.MT]: CountryType.Malta,
  [CountryCode.IM]: CountryType.ManIsland,
  [CountryCode.MH]: CountryType.MarshallIslands,
  [CountryCode.MQ]: CountryType.Martinique,
  [CountryCode.MR]: CountryType.Mauritania,
  [CountryCode.MU]: CountryType.Mauritius,
  [CountryCode.YT]: CountryType.Mayotte,
  [CountryCode.MX]: CountryType.Mexico,
  [CountryCode.FM]: CountryType.Micronesia,
  [CountryCode.MD]: CountryType.Moldova,
  [CountryCode.MC]: CountryType.Monaco,
  [CountryCode.MN]: CountryType.Mongolia,
  [CountryCode.ME]: CountryType.Montenegro,
  [CountryCode.MS]: CountryType.Montserrat,
  [CountryCode.MA]: CountryType.Morocco,
  [CountryCode.MZ]: CountryType.Mozambique,
  [CountryCode.NA]: CountryType.Namibia,
  [CountryCode.NR]: CountryType.Nauru,
  [CountryCode.NP]: CountryType.Nepal,
  [CountryCode.NL]: CountryType.Netherlands,
  [CountryCode.AN]: CountryType.NetherlandsAntilles,
  [CountryCode.NC]: CountryType.NewCaledonia,
  [CountryCode.NZ]: CountryType.NewZealand,
  [CountryCode.NI]: CountryType.Nicaragua,
  [CountryCode.NE]: CountryType.Niger,
  [CountryCode.NG]: CountryType.Nigeria,
  [CountryCode.NU]: CountryType.Niue,
  [CountryCode.NF]: CountryType.NorfolkIsland,
  [CountryCode.MP]: CountryType.NorthernMarianaIslands,
  [CountryCode.NO]: CountryType.Norway,
  [CountryCode.OM]: CountryType.Oman,
  [CountryCode.PK]: CountryType.Pakistan,
  [CountryCode.PW]: CountryType.Palau,
  [CountryCode.PS]: CountryType.PalestinianTerritories,
  [CountryCode.PA]: CountryType.Panama,
  [CountryCode.PG]: CountryType.PapuaNewGuinea,
  [CountryCode.PY]: CountryType.Paraguay,
  [CountryCode.PE]: CountryType.Peru,
  [CountryCode.PH]: CountryType.Philippines,
  [CountryCode.PN]: CountryType.Pitcairn,
  [CountryCode.PL]: CountryType.Poland,
  [CountryCode.PT]: CountryType.Portugal,
  [CountryCode.PR]: CountryType.PuertoRico,
  [CountryCode.QA]: CountryType.Qatar,
  [CountryCode.RE]: CountryType.ReunionIsland,
  [CountryCode.RO]: CountryType.Romania,
  [CountryCode.RU]: CountryType.RussianFederation,
  [CountryCode.RW]: CountryType.Rwanda,
  [CountryCode.BL]: CountryType.SaintBarthelemy,
  [CountryCode.KN]: CountryType.SaintKittsAndNevis,
  [CountryCode.LC]: CountryType.SaintLucia,
  [CountryCode.MF]: CountryType.SaintMartin,
  [CountryCode.PM]: CountryType.SaintPierreAndMiquelon,
  [CountryCode.VC]: CountryType.SaintVincentAndTheGrenadines,
  [CountryCode.WS]: CountryType.Samoa,
  [CountryCode.SM]: CountryType.SanMarino,
  [CountryCode.ST]: CountryType.SaoTomeAndPrincipe,
  [CountryCode.SA]: CountryType.SaudiArabia,
  [CountryCode.SN]: CountryType.Senegal,
  [CountryCode.RS]: CountryType.Serbia,
  [CountryCode.SC]: CountryType.Seychelles,
  [CountryCode.SL]: CountryType.SierraLeone,
  [CountryCode.SG]: CountryType.Singapore,
  [CountryCode.SK]: CountryType.Slovakia,
  [CountryCode.SI]: CountryType.Slovenia,
  [CountryCode.SB]: CountryType.SolomonIslands,
  [CountryCode.SO]: CountryType.Somalia,
  [CountryCode.ZA]: CountryType.SouthAfrica,
  [CountryCode.GS]: CountryType.SouthGeorgiaAndTheSouthSandwichIslands,
  [CountryCode.KR]: CountryType.SouthKorea,
  [CountryCode.ES]: CountryType.Spain,
  [CountryCode.LK]: CountryType.SriLanka,
  [CountryCode.SD]: CountryType.Sudan,
  [CountryCode.SR]: CountryType.Suriname,
  [CountryCode.SJ]: CountryType.SvalbardAndJanMayen,
  [CountryCode.SZ]: CountryType.Swaziland,
  [CountryCode.SE]: CountryType.Sweden,
  [CountryCode.CH]: CountryType.Switzerland,
  [CountryCode.SY]: CountryType.Syria,
  [CountryCode.TW]: CountryType.Taiwan,
  [CountryCode.TJ]: CountryType.Tajikistan,
  [CountryCode.TZ]: CountryType.Tanzania,
  [CountryCode.TH]: CountryType.Thailand,
  [CountryCode.TG]: CountryType.Togo,
  [CountryCode.TK]: CountryType.Tokelau,
  [CountryCode.TO]: CountryType.Tonga,
  [CountryCode.TT]: CountryType.TrinidadAndTobago,
  [CountryCode.TN]: CountryType.Tunisia,
  [CountryCode.TR]: CountryType.Turkey,
  [CountryCode.TM]: CountryType.Turkmenistan,
  [CountryCode.TC]: CountryType.TurksAndCaicosIslands,
  [CountryCode.TV]: CountryType.Tuvalu,
  [CountryCode.UG]: CountryType.Uganda,
  [CountryCode.UA]: CountryType.Ukraine,
  [CountryCode.AE]: CountryType.UnitedArabEmirates,
  [CountryCode.GB]: CountryType.UnitedKingdom,
  [CountryCode.US]: CountryType.UnitedStates,
  [CountryCode.UY]: CountryType.Uruguay,
  [CountryCode.UZ]: CountryType.Uzbekistan,
  [CountryCode.VU]: CountryType.Vanuatu,
  [CountryCode.VA]: CountryType.VaticanCityState,
  [CountryCode.VE]: CountryType.Venezuela,
  [CountryCode.VN]: CountryType.Vietnam,
  [CountryCode.VG]: CountryType.VirginIslandsBritish,
  [CountryCode.VI]: CountryType.VirginIslandsUS,
  [CountryCode.WF]: CountryType.WallisAndFutuna,
  [CountryCode.EH]: CountryType.WesternSahara,
  [CountryCode.YE]: CountryType.Yemen,
  [CountryCode.ZM]: CountryType.Zambia,
  [CountryCode.ZW]: CountryType.Zimbabwe,
  [CountryCode.None]: CountryType.None,
};

function getCountryNameByCountryCode(countryCode: CountryCode | undefined, translation: TranslationType): string {
  if (!countryCode) {
    return '';
  }
  const {
    Enums: { Countries },
  } = translation;

  const countryType = countryTypeMapping[countryCode];

  return Countries[countryType as keyof typeof Countries];
}

// Call from selector with localization
export const countryTypeUtils = {
  getCountryNameByCountryCode,
};
