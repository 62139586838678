export class SoundUtils {
  public static playSound(src: any, options?: { volume?: number; playbackRate?: number; loop?: boolean }) {
    const audio = new Audio();
    audio.src = src;
    audio.volume = options?.volume ?? 1;
    audio.volume = options?.volume ?? 1;
    audio.playbackRate = options?.playbackRate ?? 1;
    audio.play();

    return audio;
  }
}
