import { useCallback } from 'react';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import FileSaver from 'file-saver';

export const useOnExporting = (exportFileName: string, exportWorksheetName?: string) => {
  const onExporting = useCallback(
    (e: any) => {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(exportWorksheetName);

      exportDataGrid({ component: e.component, worksheet: worksheet }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), exportFileName);
        });
      });
      e.cancel = true;
    },
    [exportFileName, exportWorksheetName],
  );

  return {
    onExporting,
  };
};
