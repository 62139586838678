import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { salesPageActions, salesPageAsyncActions } from '@/Pages/Sales/services';
import { purchasesPageActions, purchasesPageAsyncActions } from '@/Pages/Purchases/services';
import { userAsyncActions } from '@/Redux/User/asyncActions';
import { AppUserSettingNameType, SettingValueType } from '@/Enums';
import { SynchronizeType } from '@/Enums/SynchronizeType';
import { UserSettingModel } from '@/Models';

export const useSynchOrdersCompleteModal = (
  needToRemember: boolean,
  synchronizeSetting: UserSettingModel | null,
  newSalesCount: number,
  newPurchasesCount: number,
  onClose: () => void,
) => {
  const dispatch = useDispatch();

  const onUpdate = useCallback(() => {
    dispatch(salesPageAsyncActions.getSalesWithCurrentFilters());
    dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());

    if (needToRemember) {
      dispatch(
        userAsyncActions.addOrUpdateSetting({
          id: synchronizeSetting?.id ?? 0,
          name: AppUserSettingNameType.Synchronize,
          settingType: SettingValueType.String,
          value: SynchronizeType.Automatic,
        }),
      );
    } // if

    onClose();
  }, [dispatch, synchronizeSetting, needToRemember, onClose]);

  const onLater = useCallback(() => {
    dispatch(salesPageActions.setNewSalesCount(newSalesCount));
    dispatch(purchasesPageActions.setNewPurchasesCount(newPurchasesCount));

    if (needToRemember) {
      dispatch(
        userAsyncActions.addOrUpdateSetting({
          id: synchronizeSetting?.id ?? 0,
          name: AppUserSettingNameType.Synchronize,
          settingType: SettingValueType.String,
          value: SynchronizeType.Manual,
        }),
      );
    } // if

    onClose();
  }, [dispatch, synchronizeSetting, needToRemember, newSalesCount, newPurchasesCount, onClose]);

  return {
    onUpdate,
    onLater,
  };
};
