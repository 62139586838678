import DataSource from 'devextreme/data/data_source';

export const useOnReorder = <TData>(rows: TData[], onTableReorder?: (rows: TData[]) => void) => {
  const onReorder = (e: any) => {
    if (Array.isArray(rows)) {
      rows.splice(e.fromIndex, 1);
      rows.splice(e.toIndex, 0, e.itemData);

      onTableReorder?.(rows);
      e.component.refresh();
    } else {
      (rows as DataSource).store();
    } // if
  };

  return {
    onReorder,
  };
};
