import { FieldState } from '@/Types';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { useLocalization } from '@/Hooks';
import { dateTimeUtils, stringUtils } from '@/Utils';
import { useMemo } from 'react';

type OtherFields = 'customerNumberProps';

type UseCustomerNumberType = {
  fields: { [key in OtherFields]: FieldState };
};

export function useCustomerNumber(): UseCustomerNumberType {
  const selectedSale = useSelector(salesPageSelectors.selectedSale);

  const {
    models: {
      OrderTableRow: { CustomerNumber },
    },
  } = useLocalization();

  return useMemo(() => {
    const isCurrentCustomerNumberExists = Boolean(selectedSale?.customerNumberCreatedTime);

    return {
      fields: {
        customerNumberProps: {
          value: selectedSale?.customerNumber ?? '',
          isDocumentIconDisabled: isCurrentCustomerNumberExists,
          disabledDocumentMessage: isCurrentCustomerNumberExists
            ? stringUtils.insertParamsToString(CustomerNumber.DisabledDocumentMessage, [
                selectedSale?.customerNumber ?? '',
                dateTimeUtils.utcStringToLocalString(selectedSale?.customerNumberCreatedTime),
              ])
            : '',
        },
      },
    };
  }, [selectedSale, CustomerNumber]);
}
