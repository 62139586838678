/**
 * Enum for language
 */
import { Pair } from '@/Types';

export enum EditableLocalizationType {
  enEN = 1,
  deDE = 2,
  ruRU = 3,
  LA = 4,
  Description = 5,
}

export const EDITABLE_LOCALE_OPTIONS: { [key in EditableLocalizationType]: Locale } = {
  [EditableLocalizationType.LA]: '!L-A',
  [EditableLocalizationType.enEN]: 'en-EN',
  [EditableLocalizationType.deDE]: 'de-DE',
  [EditableLocalizationType.ruRU]: 'ru-RU',
  [EditableLocalizationType.Description]: '!Descriptions',
} as const;

export enum LocalizationType {
  /**
   * Translation keys for admins
   */
  LA = 'LA',
  enEN = 'enEN',
  deDE = 'deDE',
  ruRU = 'ruRU',
}

export const LOCALE_OPTIONS: Pair<Locale>[] = [
  { key: '!Descriptions', value: '!Descriptions' },
  { key: '!L-A', value: '!L-E' },
  { key: 'de-DE', value: 'de-DE' },
  { key: 'en-EN', value: 'en-EN' },
  { key: 'ru-RU', value: 'ru-RU' },
];

export type Locale = '!Descriptions' | '!L-A' | 'en-EN' | 'de-DE' | 'ru-RU';

const LOCALIZATION_VALUE_MAP: { [key in LocalizationType]: Locale } = {
  [LocalizationType.LA]: '!L-A',
  [LocalizationType.enEN]: 'en-EN',
  [LocalizationType.deDE]: 'de-DE',
  [LocalizationType.ruRU]: 'ru-RU',
} as const;

export function convertLocalizationToLocale(localization: LocalizationType): Locale {
  return LOCALIZATION_VALUE_MAP[localization];
}

export function convertLocaleToLocalization(localization: Locale): LocalizationType {
  const keys = Object.keys(LOCALIZATION_VALUE_MAP) as LocalizationType[];

  const foundedLocale = keys.find((k) => LOCALIZATION_VALUE_MAP[k] === localization);

  return foundedLocale ?? LocalizationType.enEN;
}
