import { OrderTableRow, Pair } from '@/Types';
import { FormData, useMergeOrdersBuyerSectionForm } from './hooks/useMergeOrdersBuyerSectionForm';
import styles from '../MergeOrdersAddressSection/MergeOrdersAddressSection.scss';
import { Label } from '@/Components/Controls/Label/Label';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { FormInput } from '@/Components/Controls/Input/FormInput';
import React from 'react';
import { useLocalization } from '@/Hooks';

type Props = {
  orders: OrderTableRow[];
  differentOrders: Pair<number>[];
};
export function MergeOrdersBuyerSection({ orders, differentOrders }: Props) {
  const {
    modalWindows: {
      mergeOrders: { MergeBuyerSection },
    },
  } = useLocalization();
  const {
    setValue,
    register,
    errors,
    rules: { required },
  } = useMergeOrdersBuyerSectionForm(orders);

  return (
    <div className={styles.mergeOrdersSection}>
      <div className={styles.mergeOrdersSectionHeader}>
        <Label text={MergeBuyerSection.BuyerInfoLabel} />
        <FormSelectInput
          options={[{ key: '', value: '' }, ...differentOrders]}
          register={register}
          needClearButton={false}
          setValue={setValue}
          rules={required}
          error={errors}
          name={'buyerInfoSourceOrderId'}
        />
      </div>
      <Label text={MergeBuyerSection.BuyerEmail} />
      <FormInput<FormData> register={register} readOnly={true} name={'buyerEmail'} />
      <Label text={MergeBuyerSection.BuyerMarketplaceAccount} />
      <FormInput<FormData> register={register} readOnly={true} name={'buyerMarketplaceAccount'} />
      <Label text={MergeBuyerSection.BuyerNote} />
      <FormInput<FormData> register={register} readOnly={true} name={'buyerNote'} />
      <Label text={MergeBuyerSection.BuyerVatNumber} />
      <FormInput<FormData> register={register} readOnly={true} name={'buyerVatNumber'} />
    </div>
  );
}
