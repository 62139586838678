import { OrderModel } from '@/Models';
import { ApiRoutingUtils } from '@/Routing/ApiRouting';
import { rest } from '../Rest';
import {
  ChangeAddressEditDisabledByMarkerRequest,
  ChangeOrderArchivedByMarkerRequest,
  ChangeOrderCanceledNotPaidByMarkerRequest,
  ChangeOrderDeliveryStatusByMarkerRequest,
  ChangeOrderDeliveryStatusPartiallyByMarkerRequest,
  ChangeOrderEditDisabledByMarkerRequest,
  ChangeOrderExportedByMarkerRequest,
  ChangeOrderFeedbackSubmitedByMarkerRequest,
  ChangeOrderNotifiedByMarkerRequest,
  ChangeOrderPaymentStatusByMarkerRequest,
  ChangeOrderPaymentStatusPartiallyByMarkerRequest,
  ChangeOrderPaymentStatusToPaidForSingleOrderRequest,
  ChangeOrderReceivedByMarkerRequest,
  ChangeOrderRefundedAndNotReturnedByMarkerRequest,
  ChangeOrderRefundedPaidByMarkerRequest,
} from './Requests';
import { CheckOrdersCanBeMarkedRequest } from '@/Api/MpOrdersMarking/Requests/CheckOrdersCanBeMarkedRequest';
import { CheckOrdersCanBeMarkedResponse } from '@/Api/MpOrdersMarking/Requests/CheckOrdersCanBeMarkedResponse';
const url = ApiRoutingUtils.createUrl('mp-orders-marking');

async function changeAddressEditDisabled(request: ChangeAddressEditDisabledByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-order-address`, request);
}

async function changeOrderArchived(request: ChangeOrderArchivedByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-order-archived`, request);
}

async function changeOrderCanceledNotPaid(request: ChangeOrderCanceledNotPaidByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-order-canceled`, request);
}

async function changeDeliveryStatus(request: ChangeOrderDeliveryStatusByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-delivery-statuses`, request);
}

async function changeDeliveryStatusPartially(
  request: ChangeOrderDeliveryStatusPartiallyByMarkerRequest,
): Promise<OrderModel[]> {
  return rest.post(`${url}/change-delivery-statuses-partially`, request);
}

async function changeOrderEditDisabled(request: ChangeOrderEditDisabledByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-order-edit-disabled`, request);
}

async function changeOrderExported(request: ChangeOrderExportedByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-order-exported`, request);
}

async function changeOrderFeedbackSubmited(request: ChangeOrderFeedbackSubmitedByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-order-feedback-submited`, request);
}

async function changeOrderNotified(request: ChangeOrderNotifiedByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-order-notified`, request);
}

async function changeOrderPaymentStatus(request: ChangeOrderPaymentStatusByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-payment-status`, request);
}

async function changePaymentStatusSingle(
  request: ChangeOrderPaymentStatusToPaidForSingleOrderRequest,
): Promise<OrderModel> {
  return rest.post(`${url}/change-payment-status-single`, request);
}

async function changeOrderPaymentStatusPartially(
  request: ChangeOrderPaymentStatusPartiallyByMarkerRequest,
): Promise<OrderModel[]> {
  return rest.post(`${url}/change-payment-status-partially`, request);
}

async function changeOrderReceived(request: ChangeOrderReceivedByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-order-received`, request);
}

async function changeOrderRefundedAndNotReturned(
  request: ChangeOrderRefundedAndNotReturnedByMarkerRequest,
): Promise<OrderModel[]> {
  return rest.post(`${url}/change-order-refunded-not-returned`, request);
}

async function changeOrderRefundedPaid(request: ChangeOrderRefundedPaidByMarkerRequest): Promise<OrderModel[]> {
  return rest.post(`${url}/change-order-refunded-paid`, request);
}

async function checkOrdersCanBeMarked(request: CheckOrdersCanBeMarkedRequest): Promise<CheckOrdersCanBeMarkedResponse> {
  return rest.post(`${url}/check-orders-can-be-marked`, request);
}

export const MpOrdersMarkingApi = {
  changeAddressEditDisabled,
  changeOrderArchived,
  changeOrderCanceledNotPaid,
  changeDeliveryStatus,
  changeDeliveryStatusPartially,
  changeOrderEditDisabled,
  changeOrderExported,
  changeOrderFeedbackSubmited,
  changeOrderNotified,
  changeOrderPaymentStatus,
  changeOrderPaymentStatusPartially,
  changePaymentStatusSingle,
  changeOrderReceived,
  changeOrderRefundedAndNotReturned,
  changeOrderRefundedPaid,

  checkOrdersCanBeMarked,
};
