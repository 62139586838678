import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserWarehousesApi } from '@/Api/UserWarehouse/UserWarehouses/UserWarehousesApi';
import { GetUserWarehousesResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/GetUserWarehousesResponse';
import { GetUserWarehouseResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/GetUserWarehouseResponse';
import { GetUserWarehouseRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/GetUserWarehouseRequest';
import { DeleteUserWarehouseUnitResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/DeleteUserWarehouseUnitResponse';
import { DeleteUserWarehouseUnitRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/DeleteUserWarehouseUnitRequest';
import { GetWarehouseTypesForWarehouseResponse } from 'src/Api/UserWarehouse/UserWarehouses/Responses/GetWarehouseTypesForWarehouseResponse';
import { GetWarehouseTypesForWarehouseRequest } from 'src/Api/UserWarehouse/UserWarehouses/Requests/GetWarehouseTypesForWarehouseRequest';
import { GetWarehouseTemplatesInfoResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/GetWarehouseTemplatesInfoResponse';
import { UserWarehouseTemplatesApi } from 'src/Api/UserWarehouse/UserWarehouseTemplates/UserWarehouseTemplatesApi';
import { CreateUserWarehouseUnitResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/CreateUserWarehouseUnitResponse';
import { CreateUserWarehouseUnitRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/CreateUserWarehouseUnitRequest';
import { UpdateUserWarehouseUnitResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/UpdateUserWarehouseUnitResponse';
import { UpdateUserWarehouseUnitRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/UpdateUserWarehouseUnitRequest';
import { CreateUnitsByTemplateResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/CreateUnitsByTemplateResponse';
import { CreateUnitsByTemplateRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/CreateUnitsByTemplateRequest';
import { GetUnitPostingsResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/GetUnitPostingsResponse';
import { GetUnitPostingsRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/GetUnitPostingsRequest';
import { GetUnitHistoryResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/GetUnitHistoryResponse';
import { GetUnitHistoryRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/GetUnitHistoryRequest';
import { GetAvailableUnitsFromTemplateResponse } from '@/Api/UserWarehouse/UserWarehouses/Responses/GetAvailableUnitsFromTemplateResponse';
import { GetAvailableUnitsFromTemplateRequest } from '@/Api/UserWarehouse/UserWarehouses/Requests/GetAvailableUnitsFromTemplateRequest';

const getUserWarehouses = createAsyncThunk<GetUserWarehousesResponse>('warehousePage/get-warehouses', async () => {
  return UserWarehousesApi.getUserWarehouses();
});

const getUserWarehouse = createAsyncThunk<GetUserWarehouseResponse, GetUserWarehouseRequest>(
  'warehousePage/get-warehouse',
  async (request: GetUserWarehouseRequest) => {
    return UserWarehousesApi.getUserWarehouse(request);
  },
);

const createUnitsByTemplate = createAsyncThunk<CreateUnitsByTemplateResponse, CreateUnitsByTemplateRequest>(
  'warehousePage/create-units-by-template',
  async (request: CreateUnitsByTemplateRequest) => {
    return UserWarehousesApi.createWarehouseUnitsByTemplate(request);
  },
);

const createUserWarehouseUnit = createAsyncThunk<CreateUserWarehouseUnitResponse, CreateUserWarehouseUnitRequest>(
  'warehousePage/create-warehouse-unit',
  async (request: CreateUserWarehouseUnitRequest) => {
    return UserWarehousesApi.createWarehouseUnit(request);
  },
);

const getAvailableUnitsFromTemplate = createAsyncThunk<
  GetAvailableUnitsFromTemplateResponse,
  GetAvailableUnitsFromTemplateRequest
>('warehousePage/get-available-units-from-template', async (request: GetAvailableUnitsFromTemplateRequest) => {
  return UserWarehousesApi.getAvailableUnitsFromTemplate(request);
});

const updateUserWarehouseUnit = createAsyncThunk<UpdateUserWarehouseUnitResponse, UpdateUserWarehouseUnitRequest>(
  'warehousePage/update-warehouse-unit',
  async (request: UpdateUserWarehouseUnitRequest) => {
    return UserWarehousesApi.updateWarehouseUnit(request);
  },
);

const deleteUserWarehouseUnit = createAsyncThunk<DeleteUserWarehouseUnitResponse, DeleteUserWarehouseUnitRequest>(
  'warehousePage/delete-warehouse-unit',
  async (request: DeleteUserWarehouseUnitRequest) => {
    return UserWarehousesApi.deleteWarehouseUnit(request);
  },
);

const getWarehouseTypesForWarehouse = createAsyncThunk<
  GetWarehouseTypesForWarehouseResponse,
  GetWarehouseTypesForWarehouseRequest
>('warehousePage/get-types-for-warehouse', async (request: GetWarehouseTypesForWarehouseRequest) => {
  return UserWarehousesApi.getWarehouseTypesForWarehouse(request);
});

const getWarehouseTemplatesInfo = createAsyncThunk<GetWarehouseTemplatesInfoResponse, void>(
  'warehousePage/get-templates-info',
  async () => {
    return UserWarehouseTemplatesApi.getWarehouseTemplatesInfo();
  },
);

const getUnitPostings = createAsyncThunk<GetUnitPostingsResponse, GetUnitPostingsRequest>(
  'warehousePage/get-unit-products',
  async (request: GetUnitPostingsRequest) => {
    return UserWarehousesApi.getUnitPostings(request);
  },
);

const getUnitHistory = createAsyncThunk<GetUnitHistoryResponse, GetUnitHistoryRequest>(
  'warehousePage/get-unit-history',
  async (request: GetUnitHistoryRequest) => {
    return UserWarehousesApi.getUnitHistory(request);
  },
);

export const warehousePageAsyncActions = {
  getUserWarehouses,
  getUserWarehouse,
  createUnitsByTemplate,
  createUserWarehouseUnit,
  getAvailableUnitsFromTemplate,
  updateUserWarehouseUnit,
  deleteUserWarehouseUnit,
  getWarehouseTypesForWarehouse,
  getWarehouseTemplatesInfo,
  getUnitPostings,
  getUnitHistory,
};
