import { useAppRouting } from '@/Hooks/useAppRouting';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { OrderTableRow } from '@/Types';

type UseSelectedOrdersRowsType = {
  orderRows: OrderTableRow[];
  orderIds: number[];
  isSalesPage: boolean;
  isPurchasesPage: boolean;
};

export function useSelectedOrdersRows() {
  const { isSalesPage, isPurchasesPage } = useAppRouting();
  const sales = useSelector(salesPageSelectors.selectedSales);
  const purchases = useSelector(purchasesPageSelectors.selectedPurchases);

  return useMemo<UseSelectedOrdersRowsType>(() => {
    if (isSalesPage)
      return {
        orderRows: sales,
        orderIds: Array.from(new Set(sales.map((sale) => sale.id))),
        isSalesPage,
        isPurchasesPage,
      };
    if (isPurchasesPage)
      return {
        orderRows: purchases,
        orderIds: Array.from(new Set(purchases.map((sale) => sale.id))),
        isSalesPage,
        isPurchasesPage,
      };

    return { orderRows: [], orderIds: [], isSalesPage: false, isPurchasesPage: false };
  }, [isSalesPage, isPurchasesPage, sales, purchases]);
}
