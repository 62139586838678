import React from 'react';
import styles from './Icons.scss';
import cn from 'classnames';
import { IconProps, TopMenuIcons } from './IconsRes';
import { getIconClasses } from '@/Static/IconUtils';

type Props = IconProps;

export const ShippingLabelIcon = ({ onClick, size = 'small' }: Props) => {
  return (
    <div onClick={onClick} className={cn(styles.icon, styles.button, getIconClasses(size))}>
      <img src={TopMenuIcons.ShippingLabel} alt="Shipping Label" />
    </div>
  );
};
