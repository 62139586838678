import { Moment } from 'moment';
import { CustomDateRange } from './types';
import { DateFormatType } from '@/Enums';

function convertDateOrRangeToString(
  dateOrRange: string | [string, string] | undefined,
  ranges: CustomDateRange | undefined,
  format: DateFormatType,
): string {
  if (!dateOrRange) return '';

  const isArray = Array.isArray(dateOrRange);
  const isDateRangeString = dateOrRange.includes(' - ');
  if (isArray || isDateRangeString) {
    const dateRangeStrings = isArray ? dateOrRange : dateOrRange.split(' - ');

    if (ranges) {
      const rangeName = findDateRangeNameByValue(ranges, [dateRangeStrings[0], dateRangeStrings[1]], format);
      if (rangeName) return String(rangeName);
    } // if

    return `${dateRangeStrings[0]} - ${dateRangeStrings[1]}`;
  }

  return dateOrRange;
}

function convertDateStringToDates(dateString: string): string | [string, string] {
  const isDateRange = dateString.includes(' - ');

  if (isDateRange) {
    const dateRangeStrings = dateString.split(' - ');
    return [dateRangeStrings[0], dateRangeStrings[1]];
  } // if

  return dateString;
}

const formatPicker = (picker: { startDate: Moment; endDate: Moment }, format: string, isRange: boolean): string => {
  const startDate = picker.startDate;
  const endDate = picker.endDate;

  return isRange ? `${startDate.format(format)} - ${endDate.format(format)}` : startDate.format(format);
};

const findDateRangeNameByValue = (
  ranges: CustomDateRange,
  dateRange: [string, string],
  format: DateFormatType,
): string | undefined => {
  for (const key in ranges) {
    const timeFromDiff = dateRange[0].localeCompare((ranges[key][0] as Moment).format(format));
    const timeToDiff = dateRange[1].localeCompare((ranges[key][1] as Moment).format(format));

    if (timeFromDiff === 0 && timeToDiff === 0) {
      return key;
    }
  } // for
};

export const formDateInputUtils = {
  convertDateOrRangeToString,
  convertDateStringToDates,
  findDateRangeNameByValue,
  formatPicker,
};
