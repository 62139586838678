import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetTranslationsRequest } from './GetTranslationsRequest';
import { GetTranslationsResponse } from './GetTranslationsResponse';
import { GetTranslationRequest } from './GetTranslationRequest';
import { GetTranslationResponse } from './GetTranslationResponse';
import { UpdateTranslationsRequest } from './UpdateTranslationsRequest';
import { GetTranslationSettingResponse } from '@/Api/Translations/GetTranslationSettingResponse';
import { UpdateTranslationSettingRequest } from '@/Api/Translations/UpdateTranslationSettingRequest';
import { UpdateTranslationSettingResponse } from '@/Api/Translations/UpdateTranslationSettingResponse';
import { GetLanguageSettingResponse } from '@/Api/Translations/GetLanguageSettingResponse';
import { UpdateLanguageSettingRequest } from '@/Api/Translations/UpdateLanguageSettingRequest';
import { UpdateLanguageSettingResponse } from '@/Api/Translations/UpdateLanguageSettingResponse';

const url = ApiRoutingUtils.createUrl('translations');
const otherServerUtl = ApiRoutingUtils.createSyncTranslationsUrl('translations');

async function getTranslations(params: GetTranslationsRequest): Promise<GetTranslationsResponse> {
  return rest.get(url, params);
} // getTranslations

async function getTranslation(params: GetTranslationRequest): Promise<GetTranslationResponse> {
  return rest.get(`${url}/translation`, params);
} // getTranslation

async function updateTranslations(request: UpdateTranslationsRequest): Promise<void> {
  return rest.put(url, request);
} // updateTranslations

async function getTranslationsFromOtherServer(params: GetTranslationsRequest): Promise<GetTranslationsResponse> {
  return rest.get(otherServerUtl, params);
} // getTranslationsFromOtherServer

async function getTranslationSetting(): Promise<GetTranslationSettingResponse> {
  return rest.get(`${url}/get-translation-setting`);
}

async function updateTranslationSetting(
  request: UpdateTranslationSettingRequest,
): Promise<UpdateTranslationSettingResponse> {
  return rest.post(`${url}/update-translation-setting`, request);
}

async function getLanguageSetting(): Promise<GetLanguageSettingResponse> {
  return rest.get(`${url}/get-language-setting`);
}

async function updateLanguageSetting(request: UpdateLanguageSettingRequest): Promise<UpdateLanguageSettingResponse> {
  return rest.post(`${url}/update-language-setting`, request);
}

export const TranslationsApi = {
  getTranslations,
  getTranslation,
  updateTranslations,
  getTranslationsFromOtherServer,
  getTranslationSetting,
  updateTranslationSetting,
  getLanguageSetting,
  updateLanguageSetting,
};
