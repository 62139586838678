import { useSelector } from 'react-redux';
import { useLocalization, useSelectedOrdersRows } from '@/Hooks';
import { localizationUtils } from '@/Utils/localizationUtils';
import { globalSelectors } from '@/Redux/Global/selectors';
import { stringUtils } from '@/Utils';
import { useMemo } from 'react';
import { NumberGenerationType } from 'src/Enums';

export function useGenerateDocumentNumbersTexts(numberGenerationType?: NumberGenerationType) {
  const { orderIds } = useSelectedOrdersRows();

  const {
    enums: { NumberGenerationType: NumberGenerationTypeTranslation },
    modalWindows: { generateDocumentNumbers },
  } = useLocalization();

  const localization = useSelector(globalSelectors.localization);

  const confirmationText = useMemo(() => {
    const confirmationTextPart = numberGenerationType
      ? localizationUtils.insertTranslatedParamsToString(
          NumberGenerationTypeTranslation[numberGenerationType],
          [orderIds.length],
          NumberGenerationTypeTranslation.parts,
          localization,
        )
      : '';

    return numberGenerationType
      ? stringUtils.insertParamsToString(generateDocumentNumbers.ConfirmationText, [confirmationTextPart])
      : '';
  }, [
    NumberGenerationTypeTranslation,
    numberGenerationType,
    generateDocumentNumbers.ConfirmationText,
    orderIds.length,
    localization,
  ]);

  const modalWindowTitle = useMemo(() => generateDocumentNumbers.ModalWindowTitle, [
    generateDocumentNumbers.ModalWindowTitle,
  ]);

  return {
    confirmationText,
    modalWindowTitle,
  };
}
