import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TourState = {
  run: boolean;
  tour: string;
};

const initialState: TourState = {
  run: false,
  tour: 'tour1',
};

const tourSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setRun(state, action: PayloadAction<boolean>) {
      state.run = action.payload;
    },
    setActiveTour(state, action: PayloadAction<TourState>) {
      state.tour = action.payload.tour;
    },
  },
});

export const tourReducer = tourSlice.reducer;
export const tourActions = tourSlice.actions;
