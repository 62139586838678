import { ApiRoutingUtils } from '@/Routing/ApiRouting';
import { rest } from '../Rest';
import { GetHolidaysResponse } from './GetHolidaysResponse';
import { GetHolidaysRequest } from './GetHolidaysRequest';
import { CreateHolidayRequest } from './CreateHolidayRequest';
import { CreateHolidayResponse } from './CreateHolidayResponse';
import { UpdateHolidayResponse } from './UpdateHolidayResponse';
import { UpdateHolidayRequest } from './UpdateHolidayRequest';
import { DeleteHolidayRequest } from './DeleteHolidayRequest';
import { DeleteHolidayResponse } from './DeleteHolidayresponse';

const url = ApiRoutingUtils.createUrl('admin-holidays');

async function getHolidays(request: GetHolidaysRequest): Promise<GetHolidaysResponse> {
  return rest.get(url, request);
}

async function createHoliday(request: CreateHolidayRequest): Promise<CreateHolidayResponse> {
  return rest.post(url, request);
}

async function updateHoliday(request: UpdateHolidayRequest): Promise<UpdateHolidayResponse> {
  return rest.put(url, request);
}

async function deleteHoliday(request: DeleteHolidayRequest): Promise<DeleteHolidayResponse> {
  return rest.delete(url, request);
}

export const AdminCountryHolidaysApi = {
  getHolidays,
  createHoliday,
  updateHoliday,
  deleteHoliday,
};
