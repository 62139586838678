import { OrderTableRow } from '@/Types';
import { FormData as MergeOrdersFormData } from '../../../hooks/useMergeDiffForm';
import { useFormContext } from '@/Hooks/useFormWrapper';
import { useEffect } from 'react';
import { useRequired } from '@/Hooks/Validation';

export type FormData = Pick<
  MergeOrdersFormData,
  | 'invoiceAddressSourceOrderId'
  | 'invoiceAdditionalLine'
  | 'invoiceStreet'
  | 'invoiceHouseNo'
  | 'invoiceCountryName'
  | 'invoiceCountryCode'
  | 'invoicePostalCode'
  | 'invoiceCityName'
  | 'shippingAddressSourceOrderId'
  | 'shippingAdditionalLine'
  | 'shippingStreet'
  | 'shippingHouseNo'
  | 'shippingCountryName'
  | 'shippingCountryCode'
  | 'shippingPostalCode'
  | 'shippingCityName'
>;
export const useMergeOrdersAddressSectionForm = (isInvoice: boolean, orders: OrderTableRow[]) => {
  const { register, setValue, watch, formState } = useFormContext<FormData>();

  const contactInfoSourceId = watch(isInvoice ? 'invoiceAddressSourceOrderId' : 'shippingAddressSourceOrderId');

  useEffect(() => {
    if (!contactInfoSourceId) return;

    const order = orders.find((o) => o.id == contactInfoSourceId);
    if (!order) return;

    if (isInvoice) {
      setValue('invoiceAdditionalLine', order.invoiceAdditionalLine);
      setValue('invoiceStreet', order.invoiceStreet);
      setValue('invoiceHouseNo', order.invoiceHouseNo);
      setValue('invoiceCountryName', order.invoiceCountryName);
      setValue('invoiceCountryCode', order.invoiceCountryCode);
      setValue('invoicePostalCode', order.invoicePostalCode);
      setValue('invoiceCityName', order.invoiceCityName);
    } else {
      const hasValidatedAddress = order.isOriginalShippingModified;

      setValue(
        'shippingAdditionalLine',
        hasValidatedAddress ? order.shippingAdditionalLine : order.originalShippingAdditionalLine,
      );
      setValue('shippingStreet', hasValidatedAddress ? order.shippingStreet : order.originalShippingStreet);
      setValue('shippingHouseNo', hasValidatedAddress ? order.shippingHouseNo : order.originalShippingHouseNo);
      setValue(
        'shippingCountryName',
        hasValidatedAddress ? order.shippingCountryName : order.originalShippingCountryName,
      );
      setValue(
        'shippingCountryCode',
        hasValidatedAddress ? order.shippingCountryCode : order.originalShippingCountryCode,
      );
      setValue('shippingPostalCode', hasValidatedAddress ? order.shippingPostalCode : order.originalShippingPostalCode);
      setValue('shippingCityName', hasValidatedAddress ? order.shippingCityName : order.originalShippingCityName);
    } // if
  }, [contactInfoSourceId, orders, isInvoice, setValue]);

  const required = useRequired();

  return {
    register,
    setValue,
    errors: formState.errors,
    rules: {
      required,
    },
  };
};
