import { stringUtils } from '@/Utils';
import { useMemo } from 'react';
import { useLocalization } from '../useLocalization';

export const useMinDate = (date: string) => {
  const { validation: Validation } = useLocalization();

  const message = useMemo(() => {
    return stringUtils.insertParamsToString(Validation.DateMustBeMoreThan, [date]);
  }, [Validation, date]);

  return {
    min: {
      value: date,
      message,
    },
  };
};
