import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateGenProductTypeRequest,
  CreateGenProductTypeResponse,
  UpdateGenProductTypeRequest,
  UpdateGenProductTypeResponse,
  GenProductTypesApi,
} from '@/Api/GeneratorLogisticTypes/GenProductTypes';

const getProductTypes = createAsyncThunk('gen-product-types/get-types', async () => {
  return await GenProductTypesApi.getProductTypes();
});

const createProductType = createAsyncThunk<CreateGenProductTypeResponse, CreateGenProductTypeRequest>(
  'gen-product-types/create',
  async (request: CreateGenProductTypeRequest) => {
    return GenProductTypesApi.createProductType(request);
  },
);

const updateProductType = createAsyncThunk<UpdateGenProductTypeResponse, UpdateGenProductTypeRequest>(
  'gen-product-types/update',
  async (request: UpdateGenProductTypeRequest) => {
    return GenProductTypesApi.updateProductType(request);
  },
);

const deleteProductType = createAsyncThunk<number, number>('gen-product-types/delete', async (id: number) => {
  return GenProductTypesApi.deleteProductType(id);
});

export const asyncActions = {
  getProductTypes,
  createProductType,
  updateProductType,
  deleteProductType,
};
