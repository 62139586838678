import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';

const tourState = (root: RootState) => root.tour;

const runTour = createSelector([tourState], (tourState) => tourState.run);
const currentTour = createSelector([tourState], (tourState) => tourState.tour);

export const tourSelectors = {
  runTour,
  currentTour,
};
