import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';

const orderAutomatizationData = (state: RootState) => state.modalWindows.orderAutomatizationState;

const orderAutomatizaionRows = createSelector(orderAutomatizationData, (state) => state.orderAutomatizaionRows);

const editableOrderIds = createSelector(orderAutomatizationData, (state) => state.editableOrderIds);

const orderPackages = createSelector(orderAutomatizationData, (state) => state.orderPackages);

export const orderAutomatizationSelectors = {
  orderAutomatizaionRows,
  orderPackages,
  editableOrderIds,
};
