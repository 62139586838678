import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';

const documentFoldersData = (root: RootState) => root.dynamicData.documentFoldersData;
const translation = (root: RootState) => root.global.translation;

const documentFolders = createSelector(documentFoldersData, (state) => state.documentFolders);
const localizedDocumentFolders = createSelector([documentFolders, translation], (documentFolders, translation) => {
  const {
    Pages: {
      Settings: {
        OnlineArchiveSettings: {
          AllocateCategories: { DisabledCategories },
        },
      },
    },
  } = translation;

  return documentFolders
    .map((f) => ({
      ...f,
      name: DisabledCategories[f.name as keyof typeof DisabledCategories] ?? f.name,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});

const sortedDocumentFolders = createSelector([localizedDocumentFolders, translation], (state, translation) => {
  const {
    Pages: {
      Settings: {
        OnlineArchiveSettings: {
          AllocateCategories: {
            DisabledCategories: { Open, Completed, Deleted },
          },
        },
      },
    },
  } = translation;

  const defaultFolderNames = [Open, Completed, Deleted];

  return [
    ...state.filter((item) => item.name === defaultFolderNames[0]),
    ...state.filter((item) => !defaultFolderNames.includes(item.name)),
    ...state.filter((item) => defaultFolderNames.slice(1).includes(item.name)),
  ];
});

export const documentFoldersDataSelectors = {
  documentFolders: localizedDocumentFolders,
  sortedDocumentFolders,
};
