import { jsPDF } from 'jspdf';

const convertImageToPdf = (file: any): Promise<{ data: Blob; name: string }> => {
  const fileName = file.name as string;
  const fileType = file.type as string;
  const fileExtension = file.name.split('.').slice(-1) as string;

  return new Promise<{ data: Blob; name: string }>((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const imgWidth = image.naturalWidth;
      const imgHeight = image.naturalHeight;
      const canvas = document.createElement('canvas');

      canvas.width = imgWidth;
      canvas.height = imgHeight;
      canvas.getContext('2d')?.drawImage(image, 0, 0, imgWidth, imgHeight);

      const orientation = image.width / image.height < 1 ? 'p' : 'l';

      const pdf = new jsPDF({ orientation: orientation, compress: true, format: [imgWidth, imgHeight] });
      pdf.addImage(
        canvas.toDataURL(fileType, 0.95),
        'JPEG',
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight(),
      );

      return resolve({
        data: pdf.output('blob'),
        name: fileName.replace(fileExtension, 'pdf'),
      });
    };
  });
}; // convertImageToPdf

async function convertImageToBase64(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export const imageUtils = {
  convertImageToPdf,
  convertImageToBase64,
};
