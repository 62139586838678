import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { rootReducer, RootState } from './RootReducer';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./RootReducer', async () => {
    const { rootReducer } = await import('./RootReducer'); // TODO: check
    store.replaceReducer(rootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
