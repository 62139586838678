import React, { CSSProperties } from 'react';
import styles from './Button.scss';
import { ClassNameProps, DataProps } from '@/Types';
import cn from 'classnames';
import { NotifyCircle } from '@/Components/NotifyCircle/NotifyCircle';

const NOTIFICATION_OFFSETS = {
  right: -4,
  top: -4,
};

type Props = {
  text: string | React.ReactElement;
  onClick?: (event?: any) => void;
  theme?: CSSProperties;
  type?: 'button' | 'submit';
  disabled?: boolean;
  notificationNumber?: number;
  showEmptyNotification?: boolean;
  dataAttributes?: DataProps;
  autoFocus?: boolean;
  enableAnimation?: boolean;
} & ClassNameProps;

export const Button: React.FC<Props> = ({
  disabled = false,
  text,
  onClick,
  theme,
  className,
  type = 'button',
  notificationNumber,
  showEmptyNotification,
  dataAttributes,
  autoFocus,
  enableAnimation,
}: Props) => {
  return (
    <button
      disabled={disabled}
      type={type}
      autoFocus={autoFocus}
      style={{ ...theme }}
      className={cn(styles.button, className, { [styles.disabled]: disabled, [styles.animate]: enableAnimation })}
      onClick={onClick}
      {...dataAttributes}
    >
      <span className={cn(styles.wave, styles.wave1)} />
      <span className={cn(styles.wave, styles.wave2)} />
      <span className={cn(styles.wave, styles.wave3)} />

      <span className={styles.content}>{text}</span>
      <NotifyCircle
        displayNumber={notificationNumber}
        offsets={NOTIFICATION_OFFSETS}
        showEmpty={showEmptyNotification}
      />
    </button>
  );
};
