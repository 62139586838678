export enum DocumentTemplateType {
  Document = 'Document',
  Email = 'Email',
}

export enum DocumentTemplateSharingType {
  Free,
  Buy,
  AdminTemplate,
}
