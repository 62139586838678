import { IconProps, Icons } from './IconsRes';
import React from 'react';
import styles from './Icons.scss';
import cn from 'classnames';
import { getIconClasses } from './IconUtils';

type Props = {
  onClick: () => void;
} & IconProps;

export const FolderIcon = ({ onClick, className, size = 'small' }: Props) => {
  return (
    <img
      src={Icons.Folder}
      onClick={onClick}
      className={cn(styles.icon, styles.button, getIconClasses(size), className)}
    />
  );
};
