import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { UserState } from './';
import { AppUserModel, UserSettingModel } from '@/Models';
import { Nullable, Pair } from '@/Types';
import { AppUserSettingNameType, MarketplaceType } from '@/Enums';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { CURRENCIES_CODES } from '@/Constants/CurrenciesCodes';

const userStateSelector = (root: RootState) => root.user;

const currentUser = createSelector(
  userStateSelector,
  (globalState: UserState): Nullable<AppUserModel> => globalState.currentUser,
);

const currentUserMpAccounts = createSelector(currentUser, (user) => user?.mpAccount || []);

const getSetting = createSelector(
  userStateSelector,
  (userState: UserState) => (settingKey: AppUserSettingNameType): Nullable<UserSettingModel> => {
    return userState.settings[settingKey] ?? null;
  },
);

const getSettings = createSelector(
  userStateSelector,
  (userState: UserState): { [key in AppUserSettingNameType]?: UserSettingModel } => userState.settings,
);

const currentUserAccountOptions = createSelector(currentUserMpAccounts, (accounts): Pair<number>[] => {
  return accounts.map((acc) => ({ key: acc.id, value: acc.name }));
});

const currentUserManualMpAccount = createSelector(currentUserMpAccounts, (accounts) => {
  return accounts.find((a) => a.marketplaceType === MarketplaceType.Manual);
});

const currentUserAccountTreeViewOptionsMemo = createSelector([currentUserMpAccounts], (accounts) => {
  const accountsMap: Partial<Record<MarketplaceType, TreeItemType<number | string>>> = {};
  accounts.forEach((acc) => {
    if (!accountsMap[acc.marketplaceType]) {
      accountsMap[acc.marketplaceType] = {
        key: acc.marketplaceType,
        value: acc.marketplaceType,
        expanded: true,
        items: [],
      };
    }

    accountsMap[acc.marketplaceType]?.items?.push({ key: acc.id, value: acc.name, expanded: true });
  });

  return Object.values(accountsMap) as TreeItemType<number | string>[];
});
const currentUserAccountTreeViewOptions = (state: RootState) => {
  const currentUser = state.user?.currentUser;
  const accountsMap: Partial<Record<MarketplaceType, TreeItemType<string | number>>> = {};
  if (!currentUser) return [];

  currentUser.mpAccount.forEach((acc) => {
    if (!accountsMap[acc.marketplaceType]) {
      accountsMap[acc.marketplaceType] = {
        key: acc.marketplaceType,
        value: acc.marketplaceType,
        expanded: true,
        items: [],
      };
    }

    accountsMap[acc.marketplaceType]?.items?.push({ key: acc.id, value: acc.name, expanded: true });
  });

  return Object.values(accountsMap) as TreeItemType[];
};

const currentUserMarketCountries = createSelector([currentUser], (user) => {
  return user?.marketCountries;
});

const userSegment = createSelector([currentUser], (user) => user?.segment);
const regionalSettings = createSelector(userStateSelector, (userState: UserState) => {
  const settings = userState.settings;

  return {
    timeZone: settings.TimeZone,
    timeFormat: settings.TimeFormat,
    dateFormat: settings.DateFormat,
    firstDayOfWeek: settings.FirstDayOfWeek,
    firstWeekOfYear: settings.FirstWeekOfYear,
    startOfTheFinancialYear: settings.StartOfTheFinancialYear,
    numbersLocale: settings.NumbersLocale,
  };
});

const isRegionalSettingsEmpty = createSelector([regionalSettings], (regionalSettings) => {
  return (
    !regionalSettings.timeZone ||
    !regionalSettings.timeFormat ||
    !regionalSettings.dateFormat ||
    !regionalSettings.firstDayOfWeek ||
    !regionalSettings.firstWeekOfYear ||
    !regionalSettings.startOfTheFinancialYear
  );
});

const userCurrencies = createSelector([currentUser], (user) => {
  return user?.currencies ?? [];
});

const userDefaultCurrencyId = createSelector([currentUser], (user) => {
  return user?.currencies.find((c) => c.isDefault)?.id ?? CURRENCIES_CODES.EUR;
});

const userDefaultCurrencySymbol = createSelector([currentUser], (user) => {
  return user?.currencies.find((c) => c.isDefault)?.symbol;
});

const userCategory = createSelector([currentUser], (user) => {
  return user?.category;
});

export const userSelectors = {
  currentUser,
  currentUserMpAccounts,
  currentUserAccountOptions,
  currentUserAccountTreeViewOptions,
  currentUserAccountTreeViewOptionsMemo,
  currentUserMarketCountries,
  getSetting,
  getSettings,
  userSegment,
  regionalSettings,
  isRegionalSettingsEmpty,
  userCurrencies,
  userDefaultCurrencyId,
  userDefaultCurrencySymbol,
  currentUserManualMpAccount,
  userCategory,
};
