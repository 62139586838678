import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';

const synchOrdersCompleteModalState = (state: RootState) => state.modalWindows.synchOrdersCompleteModalState;

const newSalesCount = createSelector(synchOrdersCompleteModalState, (state) => state.newSalesCount);
const updatedSalesCount = createSelector(synchOrdersCompleteModalState, (state) => state.updatedSalesCount);
const newPurchasesCount = createSelector(synchOrdersCompleteModalState, (state) => state.newPurchasesCount);
const updatedPurchasesCount = createSelector(synchOrdersCompleteModalState, (state) => state.updatedPurchasesCount);

export const synchOrdersCompleteModalSelectors = {
  newSalesCount,
  updatedSalesCount,
  newPurchasesCount,
  updatedPurchasesCount,
};
