import { OrderAutomationSettingsModel } from '@/Models/OrderAutomation/OrderAutomationSettingsModel';
import { Nullable } from '@/Types/Nullable';
import { createSlice } from '@reduxjs/toolkit';
import { orderAutomationSettingsAsyncActions } from './asyncActions';

export type OrderAutomationSettingsState = {
  orderAutomationSettings: Nullable<OrderAutomationSettingsModel>;
};

const initialState: OrderAutomationSettingsState = {
  orderAutomationSettings: null,
};

const orderAutomationSettingsSlice = createSlice({
  name: 'orderAutomation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(orderAutomationSettingsAsyncActions.getOrderAutomationSettings.fulfilled, (state, action) => {
      state.orderAutomationSettings = action.payload;
    });
    builder.addCase(orderAutomationSettingsAsyncActions.createOrderAutomationSettings.fulfilled, (state, action) => {
      state.orderAutomationSettings = action.payload.orderAutomationSettings;
    });
    builder.addCase(orderAutomationSettingsAsyncActions.updateOrderAutomationSettings.fulfilled, (state, action) => {
      state.orderAutomationSettings = action.payload.orderAutomationSettings;
    });
  },
});

export const orderAutomationSettingsReducer = orderAutomationSettingsSlice.reducer;
