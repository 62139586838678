import { useAppRouting } from '@/Hooks/useAppRouting';
import { NumberGenerationType } from '@/Enums';
import { FieldState } from '@/Types';
import { useInvoiceTooltipMessage } from 'src/Components/Header/modules/Menu/hooks/useInvoiceTooltipMessage';
import { useCancelInvoiceTooltipMessage } from 'src/Components/Header/modules/Menu/hooks/useCancelInvoiceTooltipMessage';
import { useDeliveryNoteNumberTooltipMessage } from 'src/Components/Header/modules/Menu/hooks/useDeliveryNoteNumberTooltipMessage';
import { useOrderNumberTooltipMessage } from 'src/Components/Header/modules/Menu/hooks/useOrderNumberTooltipMessage';

type OrderNumbersFields =
  | 'orderNumberProps'
  | 'deliveryNoteNumberProps'
  | 'invoiceNumberProps'
  | 'cancelInvoiceNumberProps';

type UseGenerationNumbersType = {
  orderGenType: NumberGenerationType;
  fields: { [key in OrderNumbersFields]: FieldState };
};

export function useGenerationNumbers(): UseGenerationNumbersType {
  const { isSalesPage } = useAppRouting();

  const orderNumberProps = useOrderNumberTooltipMessage();
  const invoiceNumberProps = useInvoiceTooltipMessage();
  const deliveryNoteNumberProps = useDeliveryNoteNumberTooltipMessage();
  const cancelInvoiceNumberProps = useCancelInvoiceTooltipMessage();

  return {
    orderGenType: isSalesPage ? NumberGenerationType.SaleOrder : NumberGenerationType.PurchaseOrder,
    fields: {
      orderNumberProps,
      invoiceNumberProps,
      deliveryNoteNumberProps,
      cancelInvoiceNumberProps,
    },
  };
}
