import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { globalSelectors } from '@/Redux/Global/selectors';

export function useLocalization() {
  const translation = useSelector(globalSelectors.translation);
  const translationDocumentDesigner = useSelector(globalSelectors.translationDocumentDesigner);

  return useMemo(
    () => ({
      buttons: translation.Buttons,
      common: translation.Common,
      enums: translation.Enums,
      errors: translation.Errors,
      warnings: translation.Warnings,
      modules: translation.Modules,
      adminModules: translation.AdminModules,
      translationDocumentDesigner: translationDocumentDesigner,
      validation: translation.Validation,
      inputLabels: translation.Common.InputLabels,
      notifications: translation.Notifications,
      controls: translation.Controls,
      // Pages
      salesPage: translation.Pages.Sales,
      productsPage: translation.Pages.Products,
      warehousePage: translation.Pages.Warehouse,
      settingsPage: translation.Pages.Settings,
      suppliersPage: translation.Pages.Suppliers,
      customersPage: translation.Pages.Customers,
      contactsPage: translation.Pages.Contacts,
      packageSettings: translation.Pages.Settings.PackageSettings,
      logisticsPage: translation.Pages.Logistics,
      authorizationPage: translation.Pages.Authorization,
      documentTemplateSettingsPage: translation.Pages.Settings.DocumentTemplateSettings,
      usersPage: translation.Pages.Users,
      purchasesPage: translation.Pages.Purchases,
      designServicePage: translation.Pages.DesignServicePage,
      onlineArchive: translation.Pages.OnlineArchive,
      shop: translation.Pages.Shop,
      warehouseLogisticsPage: translation.Pages.WarehouseLogistics,
      subscriptionsPage: translation.Pages.Subscriptions,
      digitalProductsPage: translation.Pages.DigitalProducts,
      emailTemplateSettingsPage: translation.Pages.Settings.EmailTemplateSettings,
      accountingPage: translation.Pages.Accounting,
      administratorPage: translation.Pages.Administrator,
      paymentsPage: translation.Pages.Payments,
      orderAutomation: translation.Pages.Settings.OrderAutomationSettings,
      adminCalendarPage: translation.Pages.Administrator.Calendar,
      billingPage: translation.Pages.Billing,
      dashboardPage: translation.Pages.Dashboard,

      // Modals
      modalWindows: {
        confirmation: translation.ModalWindows.Confirmation,
        changePaymentStatus: translation.ModalWindows.ChangePaymentStatus,
        changeDeliveryStatus: translation.ModalWindows.ChangeDeliveryStatus,
        changeOtherStatuses: translation.ModalWindows.ChangeOtherStatuses,
        addressValidationResolving: translation.ModalWindows.AddressValidationResolving,
        selectContact: translation.ModalWindows.SelectContact,
        orderLinkWithProduct: translation.ModalWindows.OrderLinkWithProduct,
        orderLabelPrint: translation.ModalWindows.OrderLabelPrint,
        mpSynchronization: translation.ModalWindows.MpSynchronization,
        generateDocumentNumbers: translation.ModalWindows.GenerateDocumentNumberConfirmation,
        generateAlreadyExistsDocumentNumbers: translation.ModalWindows.GenerateAlreadyExistsDocumentNumberConfirmation,
        selectCurrency: translation.ModalWindows.SelectCurrency,
        splitOrders: translation.ModalWindows.SplitOrder,
        mergeOrders: translation.ModalWindows.MergeOrders,
        logisticContract: translation.ModalWindows.LogisticContract,
        documentPrintModal: translation.ModalWindows.DocumentPrintModal,
        emailSendModal: translation.ModalWindows.EmailSendModal,
        synchOrdersCompleteModal: translation.ModalWindows.SynchOrdersCompleteModal,
        deliveryProgressTimeModal: translation.ModalWindows.DeliveryProgressTimeModal,
        cookieModal: translation.ModalWindows.CookieModal,
        connectionLostModal: translation.ModalWindows.ConnectionLostModal,
      },

      // Tables
      orderColumns: translation.TableColumns.OrderColumns,
      productColumns: translation.TableColumns.ProductColumns,
      productTransferErrorColumns: translation.TableColumns.ProductTransferErrorColumns,
      transferReportColumns: translation.TableColumns.TransferReportColumns,
      contactColumns: translation.TableColumns.ContactColumns,
      userColumns: translation.TableColumns.UserColumns,
      ticketColumns: translation.TableColumns.TicketColumns,
      fileColumns: translation.TableColumns.FileColumns,
      transactionColumns: translation.TableColumns.TransactionColumns,
      invoiceColumns: translation.TableColumns.InvoiceColumns,
      paymentColumns: translation.TableColumns.PaymentColumns,
      addressBookColumns: translation.TableColumns.AddressBookColumns,
      mpAccountColumns: translation.TableColumns.MpAccountColumns,
      subscriptionColumns: translation.TableColumns.SubscriptionColumns,
      userProductColumns: translation.TableColumns.UserProductColumns,
      shortDocumentColumns: translation.TableColumns.ShortDocumentColumns,
      documentColumns: translation.TableColumns.DocumentColumns,
      documentReportColumns: translation.TableColumns.DocumentReportColumns,
      orderItemColumns: translation.TableColumns.OrderItemColumns,
      digitalProductColumns: translation.TableColumns.DigitalProductColumns,
      userProductDuplicatedColumns: translation.TableColumns.UserProductDuplicatedColumns,
      currencyColumns: translation.TableColumns.CurrencyColumns,
      uploadTemplateColumns: translation.TableColumns.UploadTemplateColumns,
      emailTemplateColumns: translation.TableColumns.EmailTemplateColumns,
      customerShoppingCardColumns: translation.TableColumns.CustomerShoppingCardColumns,
      accountChartColumns: translation.TableColumns.AccountChartColumns,
      billingShoppingCard: translation.TableColumns.BillingShoppingCardColumns,
      warehouseTransfer: translation.TableColumns.WarehouseTransferColumns,
      mandatsSettingsColumns: translation.TableColumns.MandatsSettingsColumns,
      warehousePostingColumns: translation.TableColumns.WarehousePostingColumns,
      unitHistoryColumns: translation.TableColumns.WarehouseUnitHistoryColumns,
      transferModalTableColumns: translation.TableColumns.TransferModalTableColumns,
      // Select Options
      selectOptions: translation.SelectOptions,
      dropMenu: translation.DropMenu,
      menu: translation.Menu,

      // Models
      models: translation.Models,
      functionGroups: translation.FunctionGroups,
      functions: translation.Functions,
      //
    }),
    [translation, translationDocumentDesigner],
  );
}
