import { ApiRoutingUtils } from '@/Routing';
import {
  CreateGenSubProductTypeRequest,
  CreateGenSubProductTypeResponse,
  UpdateGenSubProductTypeRequest,
  UpdateGenSubProductTypeResponse,
} from '.';
import { GenSubProductTypeModel } from '@/Models';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('gen-sub-product-types');

async function getSubProductTypes(): Promise<GenSubProductTypeModel[]> {
  return rest.get(url);
}

async function createSubProductType(request: CreateGenSubProductTypeRequest): Promise<CreateGenSubProductTypeResponse> {
  return rest.post(url, request);
}

async function updateSubProductType(request: UpdateGenSubProductTypeRequest): Promise<UpdateGenSubProductTypeResponse> {
  return rest.put(url, request);
}

async function deleteSubProductType(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

export const GenSubProductTypesApi = {
  getSubProductTypes,
  createSubProductType,
  updateSubProductType,
  deleteSubProductType,
};
