import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { useSelector } from 'react-redux';
import { orderAutomatizationSelectors } from '../services/selectors';

export const useOrderAutomatizationModalOptions = (isSales: boolean) => {
  const selectedOrders = useSelector(
    isSales ? salesPageSelectors.selectedSales : purchasesPageSelectors.selectedPurchases,
  );

  const orderPackages = useSelector(orderAutomatizationSelectors.orderPackages);
  const editableOrderIds = useSelector(orderAutomatizationSelectors.editableOrderIds);
  const orderAutomatizationRows = useSelector(orderAutomatizationSelectors.orderAutomatizaionRows);

  return {
    selectedOrders,
    orderPackages,
    editableOrderIds,
    orderAutomatizationRows,
  };
};
