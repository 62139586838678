import { ReportInfoModel } from '@/Models/_Microservices/OnlineArchive/ReportInfoModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '@/Types';
import { asyncActions } from '../../../services/asyncActions';

export type ReportsSectionState = {
  reportInfos: ReportInfoModel[];
  focusedReportInfo: Nullable<ReportInfoModel>;
};

const initialState: ReportsSectionState = {
  reportInfos: [],
  focusedReportInfo: null,
};

const reportsSectionSlice = createSlice({
  name: 'onlineArchivePage/reportsSection',
  initialState,
  reducers: {
    setFocusedReportInfo: (state, action: PayloadAction<ReportInfoModel>) => {
      state.focusedReportInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getReports.fulfilled, (state, action) => {
        state.reportInfos = action.payload;
      })
      .addCase(asyncActions.createReport.fulfilled, (state, action) => {
        if (action.payload.reportInfo) {
          state.reportInfos.unshift(action.payload.reportInfo);
        }
      })
      .addCase(asyncActions.getReport.fulfilled, (state, action) => {
        state.reportInfos = state.reportInfos.map((item) => (item.id === action.payload.id ? action.payload : item));
      })
      .addCase(asyncActions.deleteReport.fulfilled, (state, action) => {
        state.reportInfos = state.reportInfos.filter((item) => item.id !== action.payload);
      });
  },
});

export const reportsSectionReducer = reportsSectionSlice.reducer;
export const reportsSectionActions = reportsSectionSlice.actions;
export const reportsSectionAsyncActions = asyncActions;
