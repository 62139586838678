import { ClassNameProps } from '@/Types';
import React from 'react';
import cn from 'classnames';
import styles from './Header.scss';
import { AdminMenu } from '@/Components/Header/modules/AdminMenu/AdminMenu';
import { UserMenu } from '@/Components/Header/modules/UserMenu/UserMenu';

type Props = ClassNameProps;

export const AdminHeader: React.FC<Props> = ({ className }: Props) => {
  return (
    <header className={cn(className, styles.header, styles.header_admin)}>
      <AdminMenu />
      <UserMenu />
    </header>
  );
};
