import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '@/Types';
import { ChartDataFilterModel } from '@/Models/Dashboard/ChartDataFilterModel';
import { ChardDataModel } from '@/Models/Dashboard/ChardDataModel';
import { dashboardPageAsyncActions } from '@/Pages/Dashboard/services/asyncActions';
import { StepType } from '@/Enums/StepType';
import { dateTimeUtils } from '@/Utils';

export type DashboardPageState = {
  chartData: ChardDataModel[];
  chartDataFilters: Nullable<ChartDataFilterModel>;
};

const initialState: DashboardPageState = {
  chartData: [],
  chartDataFilters: null,
};

const dashboardPageSlice = createSlice({
  name: 'dashboardPage',
  initialState,
  reducers: {
    setChartFilter(state, action: PayloadAction<ChartDataFilterModel>) {
      state.chartDataFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboardPageAsyncActions.getDashboardFilter.fulfilled, (state, action) => {
        const filter = action.payload.dashboardFilter;
        if (filter?.isDefaultFilter) {
          state.chartDataFilters = filter;
        }
      })
      .addCase(dashboardPageAsyncActions.getChartData.fulfilled, (state, action) => {
        const filterStep = state?.chartDataFilters?.step;
        const needShortDate = filterStep != StepType.ByHour;

        if (state?.chartDataFilters?.step == StepType.ByWeek) {
          state.chartData = action.payload.chartDatas
            .sort((a, b) => {
              return new Date(a.arg.split(' - ')[0]).getTime() - new Date(b.arg.split(' - ')[0]).getTime();
            })
            .map((data) => {
              const range = data.arg.split(' - ');
              const start = dateTimeUtils.utcStringToLocalString(range[0], true);
              const end = dateTimeUtils.utcStringToLocalString(range[1], true);

              return {
                ...data,
                arg: `${start} - ${end}`,
              };
            });
        } else {
          state.chartData = action.payload.chartDatas
            .sort((a, b) => new Date(a.arg).getTime() - new Date(b.arg).getTime())
            .map((data) => {
              let date = dateTimeUtils.utcStringToLocalString(data.arg, needShortDate);
              const dateParts = date.split('.');

              if (filterStep == StepType.ByYear) {
                date = dateParts[2];
              }

              if (filterStep == StepType.ByMonth) {
                date = `${dateParts[1]}.${dateParts[2]}`;
              }

              return {
                ...data,
                arg: date,
              };
            });
        }
      });
  },
});

export const dashboardPageReducer = dashboardPageSlice.reducer;
export const dashboardPageActions = dashboardPageSlice.actions;
