import { ModalType, NumberGenerationType } from '@/Enums';
import { generateDocumentNumberConfirmationModalActions } from '@/ModalWindows/GenerateDocumentNumberConfirmationModal/services/reducer';
import { modalWindowsActions } from '@/ModalWindows/services';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useSelectedOrdersRows } from '@/Hooks/useSelectedOrdersRows';

export function useShowGenerateDocumentNumberConfirmationDialog() {
  const dispatch = useDispatch();
  const { orderIds } = useSelectedOrdersRows();

  const showGenerateDocumentNumberConfirmationDialog = useCallback(
    (documentType: NumberGenerationType) => {
      if (orderIds.length > 0) {
        dispatch(
          generateDocumentNumberConfirmationModalActions.setNumberGenerationRequest({
            genType: documentType,
            orderIds,
          }),
        );
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.GenerateDocumentNumberConfirmationModal }));
      }
    },
    [orderIds],
  );

  return {
    showGenerateDocumentNumberConfirmationDialog,
  };
}
