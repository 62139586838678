import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalSelectors } from '@/Redux/Global/selectors';
import { EMPTY_ACTION } from '@/Constants/SharedConstants';
import { Modal } from '@/Components/Modal/Modal';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { globalActions } from '@/Redux/Global/reducer';
import styles from './ConnectionLostModal.scss';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { useLocalization } from '@/Hooks';

export function ConnectionLostModal() {
  const {
    modalWindows: {
      connectionLostModal: { Title, Text },
    },
    buttons: { TryAgain },
  } = useLocalization();

  const dispatch: AppDispatch = useDispatch();
  const isConnectionLost = useSelector(globalSelectors.isConnectionLost);

  const checkConnection = () => {
    dispatch(globalActions.setConnectionLost(!window.navigator.onLine));
  };

  useEffect(() => {
    window.addEventListener('online', checkConnection);
    window.addEventListener('offline', checkConnection);

    return () => {
      window.removeEventListener('online', checkConnection);
      window.removeEventListener('offline', checkConnection);
    };
  }, []);

  return (
    <Modal
      isOpen={isConnectionLost}
      onClose={EMPTY_ACTION}
      needCloseIcon={false}
      needRefDocsIcon={false}
      shouldCloseOnOverlayClick={false}
      overlayClassName={styles.overlay}
    >
      <div className={styles.block}>
        <svg
          fill="#376B8D"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.icon}
        >
          <title>wifi-slash</title>
          <path d="M16 20.75c-2.347 0-4.25 1.903-4.25 4.25s1.903 4.25 4.25 4.25c2.347 0 4.25-1.903 4.25-4.25v0c-0.003-2.346-1.904-4.247-4.25-4.25h-0zM16 26.75c-0.966 0-1.75-0.784-1.75-1.75s0.784-1.75 1.75-1.75c0.966 0 1.75 0.784 1.75 1.75v0c-0.001 0.966-0.784 1.749-1.75 1.75h-0zM2.205 8.149c-0.706 0.581-1.089 0.967-1.089 0.967-0.226 0.226-0.367 0.539-0.367 0.884 0 0.69 0.56 1.25 1.25 1.25 0.345 0 0.657-0.14 0.883-0.366v0c0.021-0.021 0.342-0.336 0.912-0.806 0.279-0.231 0.455-0.577 0.455-0.965 0-0.69-0.56-1.25-1.25-1.25-0.303 0-0.581 0.108-0.797 0.287l0.002-0.002zM8.367 13.837c-1.232 0.718-2.297 1.52-3.259 2.43l0.008-0.008c-0.227 0.226-0.367 0.539-0.367 0.884 0 0.69 0.559 1.25 1.25 1.25 0.345 0 0.657-0.14 0.883-0.365l-0 0c0.809-0.752 1.71-1.425 2.679-1.995l0.072-0.039c0.372-0.221 0.617-0.621 0.617-1.078 0-0.69-0.56-1.25-1.25-1.25-0.233 0-0.452 0.064-0.639 0.175l0.006-0.003zM15.883 14.114c0.039-0.001 0.078-0.007 0.117-0.007 3.547 0.219 6.718 1.67 9.125 3.927l-0.008-0.007c0.226 0.224 0.537 0.363 0.881 0.363 0.69 0 1.25-0.56 1.25-1.25 0-0.344-0.139-0.656-0.364-0.882l0 0c-2.845-2.713-6.645-4.447-10.846-4.65l-0.039-0.002c-0.848 0.010-1.67 0.098-2.466 0.257l0.085-0.014-4.866-4.866c2.116-1.054 4.603-1.689 7.233-1.734l0.015-0c5.103 0.308 9.667 2.396 13.128 5.645l-0.011-0.010c0.226 0.226 0.539 0.366 0.884 0.366 0.69 0 1.25-0.56 1.25-1.25 0-0.345-0.14-0.658-0.366-0.884v0c-3.891-3.71-9.086-6.083-14.831-6.364l-0.054-0.002c-3.346 0.058-6.476 0.928-9.218 2.421l0.105-0.052-4.003-4.002c-0.226-0.225-0.537-0.363-0.881-0.363-0.69 0-1.25 0.56-1.25 1.25 0 0.344 0.139 0.655 0.363 0.881l27.999 28.001c0.226 0.226 0.539 0.366 0.884 0.366 0.691 0 1.251-0.56 1.251-1.251 0-0.345-0.14-0.658-0.366-0.884l0 0z"></path>
        </svg>

        <div className={styles.description}>
          <h2>{Title}</h2>
          <p>{Text}</p>
          <Button text={TryAgain} theme={BUTTON_THEMES.SIMPLE} className={styles.btn} onClick={checkConnection} />
        </div>
      </div>
    </Modal>
  );
}
