import { ApiRoutingUtils } from '@/Routing';
import {
  CreateGenPackageTypeRequest,
  CreateGenPackageTypeResponse,
  UpdateGenPackageTypeRequest,
  UpdateGenPackageTypeResponse,
} from '.';
import { GenPackageTypeModel } from '@/Models';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('gen-package-types');

async function getPackageTypes(): Promise<GenPackageTypeModel[]> {
  return rest.get(url);
}

async function createPackageType(request: CreateGenPackageTypeRequest): Promise<CreateGenPackageTypeResponse> {
  return rest.post(url, request);
}

async function updatePackageType(request: UpdateGenPackageTypeRequest): Promise<UpdateGenPackageTypeResponse> {
  return rest.put(url, request);
}

async function deletePackageType(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

export const GenPackageTypesApi = {
  getPackageTypes,
  createPackageType,
  updatePackageType,
  deletePackageType,
};
