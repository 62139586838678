import React, { useState, PropsWithChildren } from 'react';
import cn from 'classnames';

// Types import
import { ClassNameProps } from '@/Types';

// Icons import
import { Icons } from '@/Static/IconsRes';

// Styles import
import styles from './DropDownContent.scss';
import { Icon } from '@/Components/Icon/Icon';

type Props = {
  title: string;
  onTitleClick?: () => void;
  isActive?: boolean;
  isOpened?: boolean;
  dropDownTitleClassName?: string;
  dropDownBodyClassName?: string;
} & ClassNameProps;

export const DropDownContent: React.FC<Props> = ({
  title,
  onTitleClick,
  isActive = false,
  isOpened = false,
  className,
  dropDownTitleClassName,
  dropDownBodyClassName,
  children,
}: PropsWithChildren<Props>) => {
  const [isVisible, setIsVisible] = useState(isOpened);

  const isHasChildren = Boolean(children);

  return (
    <div className={cn(styles.dropDownContent, className)}>
      <div
        className={cn(styles.dropDownTitle, dropDownTitleClassName)}
        onClick={() => {
          setIsVisible(!isVisible);
          if (onTitleClick) {
            onTitleClick();
          }
        }}
      >
        <Icon src={isVisible ? Icons.DropDownShown : Icons.DropDownHidden} alt="dropDownIcon" hidden={!isHasChildren} />

        <p className={cn({ [styles.active]: isActive })} onClick={onTitleClick}>
          {title}
        </p>
      </div>

      <div
        className={cn(
          {
            [styles.dropDownBody]: true,
            [styles.hidden]: !isVisible,
          },
          dropDownBodyClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};
