import { Nullable } from '@/Types';
import { UserWarehouseUnitWithPostingsModel } from '@/Models/Warehouse/UserWarehouseUnitWithPostingsModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductPostingModel } from 'src/Models/Warehouse/ProductPostingModel';
import { ProductPostingTableRow } from '@/Types/UserWarehouse/Transfer/IncomeManualTransfer/ProductPostingTableRow';
import { asyncActions } from './asyncActions';
import { v4 as uuidv4 } from 'uuid';

export type IncomingManualTransferState = {
  postings: ProductPostingTableRow[];
  unitInfo: Nullable<UserWarehouseUnitWithPostingsModel>;
};

const initialState: IncomingManualTransferState = {
  postings: [],
  unitInfo: null,
};

export const { actions: incomingManualTransferActions, reducer: incomingManualTransferReducer } = createSlice({
  name: 'incoming-manual-transfer',
  initialState,
  reducers: {
    changePosting(state, action: PayloadAction<{ data: ProductPostingModel; productId: number }>) {
      const { data, productId } = action.payload;
      state.postings = state.postings.map((item) => {
        if (item.productId == productId)
          return {
            ...item,
            ...data,
          };
        return item;
      });
    },
    deletePosting(state, action: PayloadAction<number>) {
      state.postings = state.postings.filter((item) => item.productId != action.payload);
    },
    clean: (state) => {
      state.postings = [];
      state.unitInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.scanBarcode.fulfilled, (state, action) => {
        const { unit, posting } = action.payload;
        if (unit) {
          state.unitInfo = unit;
        } else if (state.unitInfo) {
          state.postings = [
            ...state.postings,
            {
              ...posting,
              unitName: state.unitInfo.fullName ?? '',
              uuid: uuidv4(),
              unitId: state.unitInfo.id,
            },
          ];
        }
      })
      .addCase(asyncActions.createPostings.fulfilled, (state) => {
        state.postings = [];
        state.unitInfo = null;
      });
  },
});

export const incomingManualTransferAsyncActions = asyncActions;
