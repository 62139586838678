import { useMemo } from 'react';
import { purchasesPageActions } from '@/Pages/Purchases/services';
import { useDispatch } from 'react-redux';
import { OrderTableRow } from '@/Types';

export function usePurchaseTableSelectOptions() {
  const dispatch = useDispatch();

  return useMemo(() => {
    return {
      onFocusedRowChanged: (row: OrderTableRow | null, selectedRows?: OrderTableRow[] | null) => {
        if (selectedRows && selectedRows.length > 1) {
          dispatch(purchasesPageActions.setSelectedPurchase(null));
        } else {
          dispatch(purchasesPageActions.setSelectedPurchase(row));
        }
      },
      onSelectedRowsChanged: (rows: OrderTableRow[]) => {
        dispatch(purchasesPageActions.setSelectedPurchases(rows));
      },
    };
  }, []);
}
