import React from 'react';
import { useLocalization } from '@/Hooks';
import sharedStyles from '../ModalWindows.scss';
import { useDispatch, useSelector } from 'react-redux';
import { purchasesPageAsyncActions, purchasesPageSelectors } from '@/Pages/Purchases/services';
import { orderModelExtensions } from '@/Models';
import { OrderMarker } from '@/Enums';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { Modal } from '@/Components/Modal/Modal';
import { ModalProps } from '@/Types';
import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { dateTimeUtils } from '@/Utils/DateTimeUtils';

type Props = ModalProps;

type BodyProps = {
  onClose: () => void;
  orderIds: number[];
};

export function ChangeReceivedStatusModal({ closeModalWindow }: Props) {
  const {
    modalWindows: { changeDeliveryStatus },
  } = useLocalization();

  const selectedOrders = useSelector(purchasesPageSelectors.selectedPurchases);

  const isAllReceived = selectedOrders.every((o) => orderModelExtensions.isOrderReceived(o));
  const isAllNotReceived = selectedOrders.every((o) => !orderModelExtensions.isOrderReceived(o));

  let Body: (props: BodyProps) => JSX.Element;
  const orderIds = selectedOrders.map((o) => o.id);

  if (isAllReceived) {
    Body = AllReceivedBody;
  } else if (isAllNotReceived) {
    Body = AllNotReceivedBody;
  } else {
    Body = ReceivedAndNotReceivedBody;
  }

  return (
    <Modal onClose={closeModalWindow} className={sharedStyles.modalWrapper} title={changeDeliveryStatus.Title}>
      <Body onClose={closeModalWindow} orderIds={orderIds} />
    </Modal>
  );
}

function AllReceivedBody({ onClose, orderIds }: BodyProps) {
  const {
    buttons,
    modalWindows: { changeDeliveryStatus },
  } = useLocalization();
  const dispatch = useDispatch();
  const submit = () => {
    MpOrdersMarkingApi.changeOrderReceived({ orderIds, orderMarker: OrderMarker.NotReceived }).then(() => {
      dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
      onClose();
    });
  };

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: changeDeliveryStatus.TextForAllReceived }} />
      <div className={sharedStyles.buttonsWrapper}>
        <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={buttons.Reset} onClick={onClose} />
        <Button autoFocus={true} theme={BUTTON_THEMES.SUCCESS} text={buttons.Change} onClick={submit} />
      </div>
    </>
  );
}

function AllNotReceivedBody({ onClose, orderIds }: BodyProps) {
  const {
    buttons,
    modalWindows: { changeDeliveryStatus },
  } = useLocalization();
  const dispatch = useDispatch();
  const submit = () => {
    MpOrdersMarkingApi.changeOrderReceived({ orderIds, orderMarker: OrderMarker.Received }).then(() => {
      dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
      onClose();
    });
  };

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: changeDeliveryStatus.TextForAllNotReceived }} />
      <div className={sharedStyles.buttonsWrapper}>
        <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={buttons.Reset} onClick={onClose} />
        <Button autoFocus={true} theme={BUTTON_THEMES.SUCCESS} text={buttons.Change} onClick={submit} />
      </div>
    </>
  );
}

function ReceivedAndNotReceivedBody({ onClose, orderIds }: BodyProps) {
  const {
    buttons,
    modalWindows: { changeDeliveryStatus },
  } = useLocalization();
  const dispatch = useDispatch();
  const submitReceived = () => {
    MpOrdersMarkingApi.changeDeliveryStatus({
      orderIds,
      orderMarker: OrderMarker.Shipped,
      shippingDate: dateTimeUtils.toFormatterUtcString(new Date()),
    }).then(() => {
      dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
      onClose();
    });
  };
  const submitNotReceived = () => {
    MpOrdersMarkingApi.changeDeliveryStatus({ orderIds, orderMarker: OrderMarker.NotShipped }).then(() => {
      dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
      onClose();
    });
  };

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: changeDeliveryStatus.TextForReceivedAndNotReceived }} />
      <div className={sharedStyles.buttonsWrapper}>
        <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={buttons.Reset} onClick={onClose} />
        <Button theme={BUTTON_THEMES.SUCCESS} text={buttons.NotReceived} onClick={submitNotReceived} />
        <Button theme={BUTTON_THEMES.SUCCESS} text={buttons.Received} onClick={submitReceived} />
      </div>
    </>
  );
}
