import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserWarehouseModel,
  UserWarehouseShortModel,
  UserWarehouseUnitModel,
} from '@/Models/Warehouse/UserWarehouseModel';

// Services import
import { warehousePageAsyncActions } from 'src/Pages/Warehouse/services/warehousePageAsyncActions';
import { Nullable } from '@/Types';
import { UserWarehouseTypeShortModel } from 'src/Models/Warehouse/UserWarehouseTypeShortModel';
import {
  UserWarehouseTemplateDictionaryModel,
  UserWarehouseTemplateInfoModel,
} from 'src/Models/Warehouse/UserWarehouseTemplateInfoModel';
import { UserWarehouseUnitHistoryModel } from '@/Models/Warehouse/UserWarehouseUnitHistoryModel';
import { UserWarehousePostingModel } from 'src/Models/Warehouse/UserWarehousePostingModel';

// Services import

export type WarehouseState = {
  warehouses: UserWarehouseShortModel[];
  warehouse: Nullable<UserWarehouseModel>;
  generateModalData: ModalData;
  blockModalData: ModalData;
  createModalData: CreateUnitsModalData;
  updateModalData: UpdateUnitsModalData;
  deleteModalData: DeleteUnitsModalData;
  warehouseTypes: UserWarehouseTypeShortModel[];
  warehouseTemplateInfos: UserWarehouseTemplateInfoModel[];
  warehouseTemplateDictionary: Nullable<UserWarehouseTemplateDictionaryModel>;
  selectedUnitData: SelectedUnitData;
  unitPostings: UserWarehousePostingModel[];
  unitHistory: UserWarehouseUnitHistoryModel[];
};

const initialState: WarehouseState = {
  warehouses: [],
  warehouse: null,
  warehouseTypes: [],
  warehouseTemplateInfos: [],
  warehouseTemplateDictionary: null,
  generateModalData: {
    isVisible: false,
  },
  blockModalData: {
    isVisible: false,
  },
  createModalData: {
    isVisible: false,
    canSelectStartFrom: false,
  },
  updateModalData: {
    isVisible: false,
  },
  deleteModalData: {
    isVisible: false,
  },
  selectedUnitData: {
    unitId: null,
    unitNumber: null,
  },
  unitPostings: [],
  unitHistory: [],
};

type ModalData = {
  isVisible: boolean;
};

type CreateUnitsModalData = {
  isVisible: boolean;
  canSelectStartFrom?: boolean;
  unitId?: number;
  parentId?: number;
};

type UpdateUnitsModalData = {
  isVisible: boolean;
  unit?: UserWarehouseUnitModel;
};

type DeleteUnitsModalData = {
  isVisible: boolean;
  unitId?: number;
  parentId?: number;
};

type SelectedUnitData = {
  unitId: Nullable<number>;
  unitNumber: Nullable<string>;
};

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    setGenerateModal: (state, action: PayloadAction<ModalData>) => {
      state.generateModalData = action.payload;
    },
    setBlockModal: (state, action: PayloadAction<ModalData>) => {
      state.blockModalData = action.payload;
    },
    setCreateModal: (state, action: PayloadAction<CreateUnitsModalData>) => {
      state.createModalData = action.payload;
    },
    setUpdateModal: (state, action: PayloadAction<UpdateUnitsModalData>) => {
      state.updateModalData = action.payload;
    },
    setDeleteModal: (state, action: PayloadAction<DeleteUnitsModalData>) => {
      state.deleteModalData = action.payload;
    },
    setSelectedUnit: (state, action: PayloadAction<SelectedUnitData>) => {
      state.selectedUnitData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(warehousePageAsyncActions.getUserWarehouses.fulfilled, (state, action) => {
        state.warehouses = action.payload.warehouses;
      })
      .addCase(warehousePageAsyncActions.getUserWarehouse.fulfilled, (state, action) => {
        state.warehouse = action.payload.warehouse;
      })
      .addCase(warehousePageAsyncActions.getWarehouseTypesForWarehouse.fulfilled, (state, action) => {
        state.warehouseTypes = action.payload.warehouseTypes;
      })
      .addCase(warehousePageAsyncActions.getWarehouseTemplatesInfo.fulfilled, (state, action) => {
        state.warehouseTemplateInfos = action.payload.templates;
      })
      .addCase(warehousePageAsyncActions.getAvailableUnitsFromTemplate.fulfilled, (state, action) => {
        state.warehouseTemplateDictionary = action.payload.templateItemsForTemplate;
      })
      .addCase(warehousePageAsyncActions.getUnitPostings.fulfilled, (state, action) => {
        state.unitPostings = action.payload.userWarehousePostings;
      })
      .addCase(warehousePageAsyncActions.getUnitHistory.fulfilled, (state, action) => {
        state.unitHistory = action.payload.userWarehouseUnitHistoryModels;
      });
  },
});

const { actions, reducer } = warehouseSlice;

export const warehousePageActions = actions;
export const warehousePageReducer = reducer;
