import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApplyAddressChangesRequest,
  ApplyAddressChangesResponse,
  LogisticsApi,
  OrderAddressValidationRequest,
  OrderAddressValidationResponse,
} from '@/Api/Logistics';
import { AddressValidationResultModel } from '@/Models/AddressValidationResultModel';
import { AddressValidationResultType, LoadingType } from '@/Enums';
import { orderMapping } from '@/Mapping';
import { ShippingLabelStep } from '@/ModalWindows/ShippingLabelModal/Types';

const validateOrderAddresses = createAsyncThunk<OrderAddressValidationResponse, OrderAddressValidationRequest>(
  'shippingLabelModal/validate',
  async (request: OrderAddressValidationRequest) => {
    return LogisticsApi.validateOrdersAddresses(request);
  },
);

const applyAddressChanges = createAsyncThunk<ApplyAddressChangesResponse, ApplyAddressChangesRequest>(
  'shippingLabelModal/apply-address-changes',
  async (request: ApplyAddressChangesRequest) => {
    return LogisticsApi.applyAddressChanges(request);
  },
);

export type ShippingLabelModalState = {
  validationConflicts: AddressValidationResultModel[];
  isOnlyAddressValidation: boolean;
  shouldShowValidationFinishedToast: boolean;
  currentConflictIndex: number;
  loadingType: LoadingType;
  currentStep: ShippingLabelStep;
  isOwnContract: boolean;
  isEditing: boolean;
};

const initialState: ShippingLabelModalState = {
  validationConflicts: [],
  isOnlyAddressValidation: false,
  shouldShowValidationFinishedToast: false,
  currentConflictIndex: 0,
  loadingType: LoadingType.None,
  currentStep: ShippingLabelStep.None,
  isOwnContract: true,
  isEditing: false,
};

const shippingLabelSlice = createSlice({
  name: 'shippingLabelModal',
  initialState,
  reducers: {
    resetState: (state) => {
      state.loadingType = LoadingType.None;
      state.currentConflictIndex = 0;
      state.validationConflicts = [];
      state.currentStep = ShippingLabelStep.None;
      state.isOnlyAddressValidation = false;
    },
    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    setShippingLabelStep: (state, action: PayloadAction<ShippingLabelStep>) => {
      state.currentStep = action.payload;
    },
    setOnlyValidation: (state) => {
      state.isOnlyAddressValidation = true;
    },
    setShouldShowValidationFinishedToast: (state, action: PayloadAction<boolean>) => {
      state.shouldShowValidationFinishedToast = action.payload;
    },
    setOwnContract: (state, action: PayloadAction<boolean>) => {
      state.isOwnContract = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateOrderAddresses.fulfilled, (state, action) => {
        state.currentConflictIndex = 0;
        state.validationConflicts = action.payload.compares.filter(
          (c) =>
            c.result === AddressValidationResultType.NotFound ||
            c.result === AddressValidationResultType.FoundWithChanges,
        );
        state.loadingType = LoadingType.Loaded;

        if (state.validationConflicts.length === 0) {
          state.shouldShowValidationFinishedToast = true;
        }

        if (state.validationConflicts.length > 0) {
          state.currentStep = ShippingLabelStep.Validation;
        } else if (state.isOnlyAddressValidation) {
          state.currentStep = ShippingLabelStep.Close;
        } else {
          state.currentStep = ShippingLabelStep.OrderWithoutAutomatizaion;
        }
      })
      .addCase(applyAddressChanges.fulfilled, (state, action) => {
        state.validationConflicts[state.currentConflictIndex].suggestedAddress = orderMapping.getShippingInfo(
          action.payload.order,
        );
        state.currentConflictIndex = state.currentConflictIndex + 1;

        if (state.currentConflictIndex > state.validationConflicts.length - 1) {
          state.shouldShowValidationFinishedToast = true;
          state.currentStep = state.isOnlyAddressValidation
            ? ShippingLabelStep.Close
            : ShippingLabelStep.OrderWithoutAutomatizaion;
        }
      });
  },
});

const { actions, reducer } = shippingLabelSlice;

export const shippingLabelModalReducer = reducer;
export const shippingLabelModalActions = actions;
export const shippingLabelModalAsyncActions = {
  validateOrderAddresses,
  applyAddressChanges,
};
