import { ApiRoutingUtils } from '@/Routing';
import { CreateOrderAutomationSettingsRequest } from './CreateOrderAutomationSettingsRequest';
import { CreateOrderAutomationSettingsResponse } from './CreateOrderAutomationSettingsResponse';
import { rest } from '@/Api/Rest';
import { OrderAutomationSettingsModel } from '@/Models/OrderAutomation/OrderAutomationSettingsModel';
import { UpdateOrderAutomationSettingsRequest } from './UpdateOrderAutomationSettingsRequest';
import { UpdateOrderAutomationSettingsResponse } from './UpdateOrderAutomationSettingsResponse';

const url = ApiRoutingUtils.createUrl('order-automation');

async function createOrderAutomationSettings(
  request: CreateOrderAutomationSettingsRequest,
): Promise<CreateOrderAutomationSettingsResponse> {
  return rest.post(url, request);
}

async function updateOrderAutomationSettings(
  request: UpdateOrderAutomationSettingsRequest,
): Promise<UpdateOrderAutomationSettingsResponse> {
  return rest.put(url, request);
}

async function getOrderAutomationSettings(): Promise<OrderAutomationSettingsModel> {
  return rest.get(url);
}

export const OrderAutomationApi = {
  createOrderAutomationSettings,
  getOrderAutomationSettings,
  updateOrderAutomationSettings,
};
