import { ApplicationAccessCountryZoneCountryModel } from '@/Models/ApplicationAccessCountryZones/ApplicationAccessCountryZoneCountryModel';
import { ApplicationAccessCountryZoneModel } from '@/Models/ApplicationAccessCountryZones/ApplicationAccessCountryZoneModel';
import { RootState } from '@/Redux/RootReducer';
import { Pair } from '@/Types';

export function addZone(
  countryZones: ApplicationAccessCountryZoneCountryModel[],
  model: ApplicationAccessCountryZoneModel,
) {
  if (isTopLevel(model)) {
    addCountryZone(countryZones, model);
  } else {
    const countryZone = countryZoneUtils.countryZoneById(model.parentId, countryZones);
    if (countryZone) {
      countryZone.zoneLevels.push(model);
    }
  }
}

export function updateZone(
  countryZones: ApplicationAccessCountryZoneCountryModel[],
  model: ApplicationAccessCountryZoneModel,
  selectedUuid = '',
) {
  if (isTopLevel(model)) {
    updateCountryZone(countryZones, model, selectedUuid);
  } else {
    const countryZone = countryZoneUtils.countryZoneById(model.parentId, countryZones);
    if (countryZone) {
      countryZone.zoneLevels = countryZone.zoneLevels.map((x) => {
        if (selectedUuid !== '') {
          return x.uuid == selectedUuid ? model : x;
        }
        return x.id === model.id ? model : x;
      });
    }
  }
}

export function deleteZone(countryZones: ApplicationAccessCountryZoneCountryModel[], modelId: string | number) {
  deleteCountryZone(countryZones, modelId);
  deleteZoneLevel(countryZones, modelId);
}

export function fillLocalization(x: ApplicationAccessCountryZoneCountryModel, state: RootState): any {
  x.countryName = enumToString(x.country, state.logisticWarehouseData.countryCodes);
}

export function isTopLevel(x: ApplicationAccessCountryZoneModel): boolean {
  return !x.parentId || x.parentId === '';
}

function enumToString<TKey>(enumKey: TKey, locs: Pair<TKey>[]): any {
  return enumKey ? locs.find((y) => y.key == enumKey)?.value || enumKey : enumKey;
}

function countryZoneById(countryZoneId: string | number, zones: ApplicationAccessCountryZoneCountryModel[]) {
  for (let i = 0; i < zones.length; i++) {
    const zone = zones[i];
    for (let j = 0; j < zone.logistics.length; j++) {
      const logistic = zone.logistics[j];
      for (let k = 0; k < logistic.countryZones.length; k++) {
        const countryZone = logistic.countryZones[k];
        if (countryZone.id == countryZoneId) {
          return countryZone;
        }
      }
    }
  }

  return null;
}

function addCountryZone(
  countryZones: ApplicationAccessCountryZoneCountryModel[],
  model: ApplicationAccessCountryZoneModel,
) {
  const countryZone = countryZones.find((x) => x.country === model.countryCode);
  if (countryZone) {
    let logistic = countryZone.logistics.find((x) => x.logisticId == model.logisticTypeId);
    if (logistic) {
      logistic.countryZones.push(model);
    } else {
      logistic = {
        country: model.countryCode,
        logisticId: Number(model.logisticTypeId),
        countryZones: [model],
      };
      countryZone.logistics.push(logistic);
    }
  }
}

function updateCountryZone(
  countryZones: ApplicationAccessCountryZoneCountryModel[],
  model: ApplicationAccessCountryZoneModel,
  selectedUuid = '',
) {
  const countryZone = countryZones.find((x) => x.country === model.countryCode);
  if (countryZone) {
    const logistic = countryZone.logistics.find((x) => x.logisticId == model.logisticTypeId);
    if (logistic) {
      logistic.countryZones = logistic.countryZones.map((x) => {
        if (selectedUuid !== '') {
          return x.uuid == selectedUuid ? model : x;
        }
        return x.id === model.id ? model : x;
      });
    }
  }
}

function deleteCountryZone(countryZones: ApplicationAccessCountryZoneCountryModel[], id: string | number) {
  countryZones.forEach((x) => {
    x.logistics.forEach((y) => (y.countryZones = y.countryZones.filter((z) => z.id !== id)));
    x.logistics = x.logistics.filter((y) => y.countryZones.length !== 0);
  });
}

function deleteZoneLevel(zones: ApplicationAccessCountryZoneCountryModel[], id: string | number) {
  zones.forEach((x) => {
    x.logistics.forEach((y) =>
      y.countryZones.forEach((z) => {
        z.zoneLevels = z.zoneLevels.filter((level) => level.id != id);
      }),
    );
  });
}

export const countryZoneUtils = {
  addZone,
  updateZone,
  deleteZone,
  fillLocalization,
  countryZoneById,
  isTopLevel,
};
