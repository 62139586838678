import React from 'react';
import sharedStyles from '../../../SignUp.scss';
import { SignInput } from '@/Components/Controls/SignInputs/SignInput';
import { SignSelectInput } from '@/Components/Controls/SignInputs/SignSelectInput';
import { RegistrationModel } from '@/Models';
import { UserCategoryType } from '@/Enums';
import { useCountryOptions } from '@/Enums/CountryTypeHooks';
import { useFormContext } from '@/Hooks/useFormWrapper';
import { useLocalization } from '@/Hooks';

type Props = {
  category: UserCategoryType;
  isInvoiceSection?: boolean;
};

type FormData = Pick<
  RegistrationModel,
  | 'invoiceCompany'
  | 'invoiceFirstName'
  | 'invoiceLastName'
  | 'invoiceStreet'
  | 'invoiceHouseNo'
  | 'invoiceZip'
  | 'invoiceCity'
  | 'invoiceCountry'
  | 'invoicePhone'
  | 'returnCompany'
  | 'returnFirstName'
  | 'returnLastName'
  | 'returnStreet'
  | 'returnHouseNo'
  | 'returnZip'
  | 'returnCity'
  | 'returnCountry'
  | 'returnPhone'
>;

export function AddressForm({ category, isInvoiceSection = true }: Props) {
  const {
    authorizationPage: {
      SignUp: { AddressForm },
    },
  } = useLocalization();

  const countryOptions = useCountryOptions();
  const { register } = useFormContext<FormData>();

  return (
    <>
      {category === UserCategoryType.B2B && (
        <SignInput<FormData>
          name={isInvoiceSection ? 'invoiceCompany' : 'returnCompany'}
          register={register}
          placeholder={AddressForm.Company}
        />
      )}

      <div className={sharedStyles.signUpNameWrapper}>
        <SignInput<FormData>
          name={isInvoiceSection ? 'invoiceFirstName' : 'returnFirstName'}
          register={register}
          placeholder={AddressForm.FirstName}
        />

        <SignInput<FormData>
          name={isInvoiceSection ? 'invoiceLastName' : 'returnLastName'}
          register={register}
          placeholder={AddressForm.LastName}
        />
      </div>

      <div className={sharedStyles.signUpStreetWrapper}>
        <SignInput<FormData>
          name={isInvoiceSection ? 'invoiceStreet' : 'returnStreet'}
          register={register}
          placeholder={AddressForm.Street}
        />

        <SignInput<FormData>
          name={isInvoiceSection ? 'invoiceHouseNo' : 'returnHouseNo'}
          register={register}
          placeholder={AddressForm.HouseNo}
        />
      </div>

      <div className={sharedStyles.signUpCityWrapper}>
        <SignInput<FormData>
          name={isInvoiceSection ? 'invoiceZip' : 'returnZip'}
          register={register}
          placeholder={AddressForm.Zip}
        />

        <SignInput<FormData>
          name={isInvoiceSection ? 'invoiceCity' : 'returnCity'}
          register={register}
          placeholder={AddressForm.City}
        />
      </div>

      <SignSelectInput<FormData>
        name={isInvoiceSection ? 'invoiceCountry' : 'returnCountry'}
        register={register}
        placeholder={AddressForm.County}
        options={countryOptions}
      />

      <SignInput<FormData>
        name={isInvoiceSection ? 'invoicePhone' : 'returnPhone'}
        register={register}
        placeholder={AddressForm.Phone}
      />
    </>
  );
}
