import { useMemo } from 'react';
import { useAppRouting } from '@/Hooks/useAppRouting';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { FulfillmentChannel } from '@/Enums/FulfillmentChannel';
import { useLocalization } from '@/Hooks';
import { localizationUtils } from '@/Utils/localizationUtils';
import { globalSelectors } from '@/Redux/Global/selectors';

export const useOrderFulfillment = () => {
  const { isSalesPage } = useAppRouting();
  const selectedSales = useSelector(salesPageSelectors.selectedSales);
  const selectedPurchases = useSelector(purchasesPageSelectors.selectedPurchases);
  const localization = useSelector(globalSelectors.localization);

  const {
    menu: { Tooltips },
  } = useLocalization();

  const {
    changeOrderStatusDisabledProps,
    feedbackFulfilledByMarketplaceProps,
    shippingLabelFulfilledByMarketplaceProps,
  } = useMemo(() => {
    const orders = isSalesPage ? selectedSales : selectedPurchases;

    const ordersFulfilledByMarketplace = orders
      .filter((o) => o.fulfillmentChannel === FulfillmentChannel.Marketplace)
      .map((o) => o.userOrderId);

    return {
      changeOrderStatusDisabledProps: {
        value: '',
        disabledDocumentMessage: ordersFulfilledByMarketplace.length
          ? localizationUtils.insertTranslatedParamsToString(
              Tooltips.ChangeOrderStatusDisabledFulfilledByMarketplace.Message,
              [ordersFulfilledByMarketplace.join(', '), ordersFulfilledByMarketplace.length],
              Tooltips.parts,
              localization,
            )
          : '',
        isDocumentIconDisabled: Boolean(ordersFulfilledByMarketplace.length),
      },

      feedbackFulfilledByMarketplaceProps: {
        value: '',
        disabledDocumentMessage: ordersFulfilledByMarketplace.length
          ? localizationUtils.insertTranslatedParamsToString(
              Tooltips.CreateFeedbackDisabledFulfilledByMarketplace.Message,
              [ordersFulfilledByMarketplace.join(', '), ordersFulfilledByMarketplace.length],
              Tooltips.parts,
              localization,
            )
          : '',
        isDocumentIconDisabled: Boolean(ordersFulfilledByMarketplace.length),
      },
      shippingLabelFulfilledByMarketplaceProps: {
        value: '',
        disabledDocumentMessage: ordersFulfilledByMarketplace.length
          ? localizationUtils.insertTranslatedParamsToString(
              Tooltips.CreateShippingLabelDisabledFulfilledByMarketplace.Message,
              [ordersFulfilledByMarketplace.join(', '), ordersFulfilledByMarketplace.length],
              Tooltips.parts,
              localization,
            )
          : '',
        isDocumentIconDisabled: Boolean(ordersFulfilledByMarketplace.length),
      },
    };
  }, [isSalesPage, selectedSales, selectedPurchases]);

  return {
    changeOrderStatusDisabledProps,
    feedbackFulfilledByMarketplaceProps,
    shippingLabelFulfilledByMarketplaceProps,
  };
};
