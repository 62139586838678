import { createAsyncThunk } from '@reduxjs/toolkit';
import { WarehouseInternalTransferApi } from 'src/Api/UserWarehouse/Transfers/Internal/WarehouseInternalTransferApi';
import { ScanBarcodeInternalResponse } from 'src/Api/UserWarehouse/Transfers/Internal/Responses/ScanBarcodeInternalResponse';
import { ScanBarcodeInternalRequest } from 'src/Api/UserWarehouse/Transfers/Internal/Requests/ScanBarcodeInternalRequest';
import { CreateWarehousePostingBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CreateWarehousePostingBatchResponse';
import { CreateWarehousePostingBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CreateWarehousePostingBatchRequest';
import { UserWarehouseTransferBatchApi } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/UserWarehouseTransferBatchApi';
import { SetUserWarehouseBatchStateRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/SetUserWarehouseBatchStateRequest';
import { SetUserWarehouseBatchStateResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/SetUserWarehouseBatchStateResponse';
import { CloseUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CloseUserWarehouseBatchResponse';
import { CloseUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CloseUserWarehouseBatchRequest';
import { RemoveUserWarehousePostingBatchItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/RemoveUserWarehousePostingBatchItemResponse';
import { RemoveUserWarehousePostingBatchItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/RemoveUserWarehousePostingBatchItemRequest';
import { GetInternalTransferBatchResponse } from 'src/Api/UserWarehouse/Transfers/Internal/Responses/GetInternalTransferBatchResponse';
import { GetInternalTransferBatchRequest } from 'src/Api/UserWarehouse/Transfers/Internal/Requests/GetInternalTransferBatchRequest';
import { CancelUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CancelUserWarehouseBatchResponse';
import { CancelUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CancelUserWarehouseBatchRequest';

const scanBarcode = createAsyncThunk<ScanBarcodeInternalResponse, ScanBarcodeInternalRequest>(
  'internal-transfer/scan-barcode',
  async (request) => {
    return WarehouseInternalTransferApi.scanBarcode(request);
  },
);

const createBatch = createAsyncThunk<CreateWarehousePostingBatchResponse, CreateWarehousePostingBatchRequest>(
  'internal-transfer/create-posting-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.createBatch(request);
  },
);

const removeBatchItem = createAsyncThunk<
  RemoveUserWarehousePostingBatchItemResponse,
  RemoveUserWarehousePostingBatchItemRequest
>('internal-transfer/remove-batch-item', async (request) => {
  return UserWarehouseTransferBatchApi.removeBatchItem(request);
});

const setBatchState = createAsyncThunk<SetUserWarehouseBatchStateResponse, SetUserWarehouseBatchStateRequest>(
  'internal-transfer/set-batch-state',
  async (request) => {
    return UserWarehouseTransferBatchApi.setBatchState(request);
  },
);

const closeBatch = createAsyncThunk<CloseUserWarehouseBatchResponse, CloseUserWarehouseBatchRequest>(
  'internal-transfer/close-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.closeBatch(request);
  },
);

const getBatch = createAsyncThunk<GetInternalTransferBatchResponse, GetInternalTransferBatchRequest>(
  'internal-transfer/get-batch',
  async (request) => {
    return WarehouseInternalTransferApi.getBatch(request);
  },
);

const cancelBatch = createAsyncThunk<CancelUserWarehouseBatchResponse, CancelUserWarehouseBatchRequest>(
  'internal-transfer/cancel-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.cancelBatch(request);
  },
);

export const internalTransferAsyncActions = {
  scanBarcode,
  createBatch,
  setBatchState,
  closeBatch,
  removeBatchItem,
  getBatch,
  cancelBatch,
};
