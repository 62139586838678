import { ApiRoutingUtils } from '@/Routing';
import {
  CreateGenLogisticTypeRequest,
  CreateGenLogisticTypeResponse,
  UpdateGenLogisticTypeRequest,
  UpdateGenLogisticTypeResponse,
} from '.';
import { GenLogisticTypeModel } from '@/Models';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('gen-logistic-types');

async function getLogisticTypes(): Promise<GenLogisticTypeModel[]> {
  return rest.get(url);
}

async function createLogisticType(request: CreateGenLogisticTypeRequest): Promise<CreateGenLogisticTypeResponse> {
  return rest.post(url, request);
}

async function updateLogisticType(request: UpdateGenLogisticTypeRequest): Promise<UpdateGenLogisticTypeResponse> {
  return rest.put(url, request);
}

async function deleteLogisticType(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

export const GenLogisticTypesApi = {
  getLogisticTypes,
  createLogisticType,
  updateLogisticType,
  deleteLogisticType,
};
