import { createSlice } from '@reduxjs/toolkit';

// Enums import
import { adminUsersAsyncActions } from './asyncActions';
import { AppUserShortModel } from '@/Models/AppUserShortModel';
import { AppUserInfoModel } from '@/Models/AppUserInfoModel';
import { Nullable } from '@/Types';

export type AdminUsersPageState = {
  users: AppUserShortModel[];
  userInfo: Nullable<AppUserInfoModel>;
};

const initialState: AdminUsersPageState = {
  users: [],
  userInfo: null,
};

const adminUsersPageSlice = createSlice({
  name: 'adminUsersPage',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(adminUsersAsyncActions.getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });

    builder.addCase(adminUsersAsyncActions.getUserInfo.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
  },
});

export const adminUsersPageReducer = adminUsersPageSlice.reducer;
export const adminUsersPageActions = adminUsersPageSlice.actions;
