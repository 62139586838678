import { combineReducers } from '@reduxjs/toolkit';
import { uploadSectionReducer, UploadSectionState } from '../modules/Upload/services/reducer';
import { allocateSectionReducer, AllocateSectionState } from '../modules/Allocate/services/reducer';
import { archiveSectionReducer, ArchiveSectionState } from '../modules/Archive/services/reducer';
import { ReportsSectionState, reportsSectionReducer } from '../modules/Reports/services/reducer';

export type OnlineArchivePageState = {
  uploadSection: UploadSectionState;
  allocateSection: AllocateSectionState;
  archiveSection: ArchiveSectionState;
  reportsSection: ReportsSectionState;
};

export const onlineArchivePageReducer = combineReducers<OnlineArchivePageState>({
  uploadSection: uploadSectionReducer,
  allocateSection: allocateSectionReducer,
  archiveSection: archiveSectionReducer,
  reportsSection: reportsSectionReducer,
});
