import { ApiRoutingUtils } from '@/Routing';
import { UserSettingModel } from '@/Models';
import { UpdateRegionalSettingsRequest } from './UpdateRegionalSettingsRequest';
import { UpdateRegionalSettingsResponse } from './UpdateRegionalSettingsResponse';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('user-settings');

async function addOrUpdateSetting(request: UserSettingModel): Promise<UserSettingModel> {
  return rest.post<UserSettingModel, UserSettingModel>(url, request);
}

async function updateRegionalSettings(request: UpdateRegionalSettingsRequest): Promise<UpdateRegionalSettingsResponse> {
  return rest.post(`${url}/update-regional-settings`, request);
}

export const UserSettingsApi = {
  addOrUpdateSetting,
  updateRegionalSettings,
};
