import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { modalWindowsActions } from '@/ModalWindows/services';

type UseModalWindowsReturnType = {
  closeModalWindow: () => void;
};

export function useModalWindows(): UseModalWindowsReturnType {
  const dispatch = useDispatch();

  const closeModalWindow = useCallback(() => {
    setTimeout(() => dispatch(modalWindowsActions.closeModal()), 50);
  }, [dispatch]);

  return {
    closeModalWindow,
  };
}
