import {
  CheckLogisticContractRequest,
  CheckLogisticContractResponse,
  CreateLogisticContractRequest,
  CreateLogisticContractResponse,
  UpdateLogisticContractRequest,
  UpdateLogisticContractResponse,
} from '@/Api/LogisticContracts';
import { LogisticContractsApi } from '@/Api/LogisticContracts/LogisticContractsApi';
import { createAsyncThunk } from '@reduxjs/toolkit';

const createContract = createAsyncThunk<CreateLogisticContractResponse, CreateLogisticContractRequest>(
  'LogisticContractSettings/createLogisticContract',
  async (request: CreateLogisticContractRequest) => {
    return await LogisticContractsApi.createLogisticContract(request);
  },
);

const updateContract = createAsyncThunk<UpdateLogisticContractResponse, UpdateLogisticContractRequest>(
  'LogisticContractSettings/updateLogisticContract',
  async (request: UpdateLogisticContractRequest) => {
    return await LogisticContractsApi.updateLogisticContract(request);
  },
);

const deleteContract = createAsyncThunk<number, number>(
  'LogisticContractSettings/deleteLogisticContract',
  (request: number) => {
    return LogisticContractsApi.deleteLogisticContract(request);
  },
);

const checkConnection = createAsyncThunk<CheckLogisticContractResponse, CheckLogisticContractRequest>(
  'LogisticContractSettings/checkConnection',
  (request: CheckLogisticContractRequest) => {
    return LogisticContractsApi.checkConnection(request);
  },
);

export const asyncActions = {
  createContract,
  updateContract,
  deleteContract,
  checkConnection,
};
