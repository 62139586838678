export enum UserCategoryType {
  B2C = 'B2C',
  B2B = 'B2B',
  Market = 'Market',
  Admin = 'Admin',
  Developer = 'Developer',
  Sales = 'Sales',
  SupportService = 'SupportService',
  Designer = 'Designer',
  Billing = 'Billing',
  Finance = 'Finance',
  Language = 'Language',
}
