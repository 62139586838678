import { useLocalization } from '@/Hooks';
import { ColumnInfo, ColumnType } from '@/Types';
import { EmailTemplateRow } from '@/Types/EmailTemplateRow';

export function useEmailTemplatesTableColumnInfo() {
  const { emailTemplateColumns } = useLocalization();

  const columnInfos: ColumnInfo<EmailTemplateRow>[] = [
    { columnType: ColumnType.String, fieldName: 'guid', visible: false },
    { columnType: ColumnType.String, fieldName: 'name', headerText: emailTemplateColumns.Templates },
  ];

  return columnInfos;
}
