import { DigitalProductModel } from '@/Models';
import { ApiRoutingUtils } from '@/Routing';
import {
  CreateDigitalProductRequest,
  CreateDigitalProductResponse,
  UpdateDigitalProductRequest,
  UpdateDigitalProductResponse,
} from './';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('digital-products');

async function getDigitalProducts(): Promise<DigitalProductModel[]> {
  return rest.get(url);
}

async function createDigitalProduct(request: CreateDigitalProductRequest): Promise<CreateDigitalProductResponse> {
  return rest.post(url, request);
}

async function updateDigitalProduct(request: UpdateDigitalProductRequest): Promise<UpdateDigitalProductResponse> {
  return rest.put(url, request);
}

async function deleteDigitalProduct(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, {});
}

export const DigitalProductsApi = {
  getDigitalProducts,
  createDigitalProduct,
  updateDigitalProduct,
  deleteDigitalProduct,
};
