import { ApiRoutingUtils } from '@/Routing';
import {
  CreateGenAdditionalServiceTypeRequest,
  CreateGenAdditionalServiceTypeResponse,
  UpdateGenAdditionalServiceTypeRequest,
  UpdateGenAdditionalServiceTypeResponse,
} from '.';
import { GenAdditionalServiceTypeModel } from '@/Models';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('gen-additional-service-types');

async function getAdditionalServiceTypes(): Promise<GenAdditionalServiceTypeModel[]> {
  return rest.get(url);
}

async function createAdditionalServiceType(
  request: CreateGenAdditionalServiceTypeRequest,
): Promise<CreateGenAdditionalServiceTypeResponse> {
  return rest.post(url, request);
}

async function updateAdditionalServiceType(
  request: UpdateGenAdditionalServiceTypeRequest,
): Promise<UpdateGenAdditionalServiceTypeResponse> {
  return rest.put(url, request);
}

async function deleteAdditionalServiceType(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

export const GenAdditionalServiceTypesApi = {
  getAdditionalServiceTypes,
  createAdditionalServiceType,
  updateAdditionalServiceType,
  deleteAdditionalServiceType,
};
