export enum CancelStatus {
  CancelInitializedByBuyer = 'CancelInitializedByBuyer',
  CancelRejectedBySeller = 'CancelRejectedBySeller',
  CanceledNotPaid = 'CanceledNotPaid',
  CanceledNoRefund = 'CanceledNoRefund',
  CanceledRefunded = 'CanceledRefunded',
  CanceledRefundFailed = 'CanceledRefundFailed',
  CanceledRefundPending = 'CanceledRefundPending',
  CanceledWithoutReturning = 'CanceledWithoutReturning',
  CanceledNotReturnedYet = 'CanceledNotReturnedYet',
  CanceledReturned = 'CanceledReturned',
  CancelCompleted = 'CancelCompleted',
  FulfillmentCancel = 'FulfillmentCancel',
  Invalid = 'Invalid',
}
