import { useFieldArray, useForm } from '@/Hooks/useFormWrapper';
import { useRequired } from '@/Hooks/Validation';
import { emailSendModalAsyncActions } from '@/ModalWindows/EmailSendModal/services/reducer';
import { EmailModel } from '@/Models/EmailModel';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { SimpleObject } from '@/Types';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export type FormData = Omit<EmailModel, 'attachments'> & {
  attachments: {
    name: string;
    body: string;
  }[];
};

export const useEmailPreviewModalForm = (data: { email: EmailModel; orderId: number }) => {
  const dispatch: AppDispatch = useDispatch();

  // Methods
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      to: data.email.to,
      subject: data.email.subject,
      cc: data.email.cc,
      bcc: data.email.bcc,
      body: data.email.body,
      attachments: Object.keys(data.email.attachments).map((key) => ({
        name: key,
        body: data.email.attachments[key] as string,
      })),
    },
  });

  const attachments = useFieldArray({
    name: 'attachments',
    control: control,
    keyName: 'array_id',
  });

  useEffect(() => {
    register('body');

    Object.keys(data.email.attachments).forEach((key, idx) => {
      register(`attachments.${idx}`);
    });
  }, [data]);

  // Rules
  const required = useRequired();

  // On form submit
  const onSubmit = useCallback(
    (formData: FormData) => {
      dispatch(
        emailSendModalAsyncActions.sendFormattedEmailForOrder({
          orderId: data.orderId,
          email: {
            ...formData,
            attachments: formData.attachments.reduce((prev: SimpleObject, curr) => {
              prev[curr.name] = curr.body;
              return prev;
            }, {}),
          },
        }),
      );
    },
    [data],
  );

  return {
    methods: {
      register,
      errors,
    },
    arrays: {
      attachments,
    },
    rules: {
      required,
    },
    onSubmit: handleSubmit(onSubmit),
  };
};
