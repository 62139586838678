export enum PaymentStatus {
  NotInitialized = 'NotInitialized',
  ReadyToBePaid = 'ReadyToBePaid',
  PartiallyPaid = 'PartiallyPaid',
  Paid = 'Paid',
  OverPaid = 'OverPaid',
  Dunning = 'Dunning',
}

const isOrderPaid = (paymentStatus: PaymentStatus) =>
  paymentStatus === PaymentStatus.OverPaid || paymentStatus === PaymentStatus.Paid;

export const paymentStatusUtils = {
  isOrderPaid,
};
