import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';
import { UploadTemplatesDataState } from './reducer';

const uploadTemplatesDataSelector = (root: RootState) => root.dynamicData.uploadTemplatesData;
const translation = (root: RootState) => root.global.translation;

const uploadTemplates = createSelector(
  uploadTemplatesDataSelector,
  (state: UploadTemplatesDataState) => state.uploadTemplates,
);

const uploadTemplatesTableRows = createSelector([uploadTemplates, translation], (uploadTemplates, translation) => {
  const {
    Enums: { AmountType },
  } = translation;

  return uploadTemplates.map((item) => ({
    ...item,
    postingAmountName: AmountType[item.postingAmount],
  }));
});

export const uploadTemplatesDataSelectors = {
  uploadTemplates,
  uploadTemplatesTableRows,
};
