import { DocumentTemplateType } from '@/Enums/DocumentTemplateType';
import { DocumentTemplateModel } from '@/Models';

const mappingDocumentTemplateModel = (
  guid: string,
  name: string,
  type: DocumentTemplateType,
): DocumentTemplateModel => {
  return {
    id: 0,
    guid: guid,
    name: name,
    type: type,
    documentTemplateSettings: {
      isOnlineArchive: false,
      fileName: '',
      separatingSign: '',
      directoryPath: '',
      isSaveWhenSending: false,
      isSaveWhenPrinting: false,
      documentTemplateFilePartNames: [],
      isForSale: false,
      isForPurchases: false,
      isForCustomers: false,
      isForWarehouse: false,
      countCopy: 1,
      isSortWhenPrinting: false,
      guid: '',
    },
    canRestore: false,
  };
};

export const documentTemplateMapping = {
  mappingDocumentTemplateModel,
};
