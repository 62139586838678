import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { ChangeOrderDeliveryStatusPartiallyByMarkerRequest } from '@/Api/MpOrdersMarking/Requests/ChangeOrderDeliveryStatusPartiallyByMarkerRequest';
import { DateFormatType, OrderMarker, OrderStatusFilterGroups } from '@/Enums';
import { useForm } from '@/Hooks/useFormWrapper';
import { useRequired } from '@/Hooks/Validation';
import { dateTimeUtils } from '@/Utils/DateTimeUtils';
import { useCallback } from 'react';
import { salesPageActions } from 'src/Pages/Sales/services';
import { useDispatch } from 'react-redux';

type FormData = ChangeOrderDeliveryStatusPartiallyByMarkerRequest;

export const useShippedAndNotShippedForm = (orderIds: number[], onClose: () => void) => {
  const dispatch = useDispatch();

  const methods = useForm<FormData>({
    defaultValues: {
      orderIds,
      orderMarker: OrderMarker.Shipped,
      overwritePreviousDates: false,
      shippingDate: dateTimeUtils.toFormattedString(new Date(), DateFormatType.LOCAL_DATE),
    },
  });

  // variables
  const needShowDate = methods.watch('orderMarker') === OrderMarker.Shipped;

  // rules
  const required = useRequired();
  const dateRequired = useRequired(needShowDate);

  const submit = useCallback(
    (data: FormData) => {
      onClose();
      MpOrdersMarkingApi.changeDeliveryStatusPartially({
        ...data,
        shippingDate: dateTimeUtils.localStringToUtcString(data?.shippingDate),
      }).then((response: any) => {
        if (!response.errors) {
          const markerToGroupMap = {
            [OrderMarker.NotShipped]: OrderStatusFilterGroups.NotShippedGroup,
            [OrderMarker.Shipped]: OrderStatusFilterGroups.ShippedGroup,
          };

          // If data.marker is shipped and filters is not shipped remove orders
          dispatch(
            salesPageActions.removeOrdersFromStateByCurrentFilters({
              orderIds: orderIds,
              filters: markerToGroupMap[data.orderMarker as keyof typeof markerToGroupMap] ?? [],
            }),
          );
        }
      });
    },
    [orderIds],
  );

  return {
    submit: methods.handleSubmit(submit),
    methods,
    needShowDate,
    rules: {
      required,
      dateRequired,
    },
  };
};
