import { createSlice } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';
import { UncompletedTransferModel } from '@/Models/Warehouse/Transfers/UncompletedTransferModel';

export type UncompletedTransferState = {
  uncompletedTransfers: UncompletedTransferModel[];
  uncompletedTransfersCount: number;
};

const initialState: UncompletedTransferState = {
  uncompletedTransfers: [],
  uncompletedTransfersCount: 0,
};

export const { actions: uncompletedTransferActions, reducer: uncompletedTransferReducer } = createSlice({
  name: 'uncompleted-transfer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getUncompletedTransfers.fulfilled, (state, action) => {
      state.uncompletedTransfers = action.payload.userWarehouseUncompletedTransfers;
      state.uncompletedTransfersCount = action.payload.resultsCount;
    });
  },
});

export const uncompletedTransferAsyncActions = asyncActions;
