import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Column,
  ColumnChooser,
  ColumnFixing,
  DataGrid,
  Editing,
  Export,
  FilterBuilderPopup,
  FilterRow,
  HeaderFilter,
  Item,
  MasterDetail,
  Pager,
  Paging,
  RowDragging,
  SearchPanel,
  Sorting,
  StateStoring,
  Summary,
  Toolbar,
} from 'devextreme-react/data-grid';
import { Column as TColumn } from '@devexpress/dx-react-grid';
import { ClassNameProps, ColumnInfo } from '@/Types';
import { DefaultOptions, DragOptions, EditOptions, ExportOptions, FilterOptions, SelectOptions } from './options';
import { useRerenderOnChangeLocale } from './hooks/useRerenderOnChangeLocale';
import { EMPTY_ACTION } from '@/Constants/SharedConstants';
import { useOnCellPrepared } from './hooks/useOnCellPrepared';
import { useOnContentReady } from './hooks/useOnContentReady';
import { useSelectionChanged } from './hooks/useSelectionChanged';
import { useOnReorder } from './hooks/useOnReorder';
import { useOnSaving } from './hooks/useOnSaving';
import { useOnExporting } from './hooks/useOnExporting';
import { FilterBuilderButton } from './modules/FilterBuilderButton/FilterBuilderButton';
import { ClearFiltersButton } from './modules/ClearFiltersButton/ClearFiltersButton';
import { TableSearchButton } from './modules/TableSearchButton/TableSearchButton';
import cn from 'classnames';
import { useKeyboardNavigation } from './hooks/useKeyboardNavigation';
import { useSummaryItems } from './hooks/useSummaryItems';
import { useColumns } from './hooks/useColumns';
import styles from './DataTable.scss';
import { useStateStoring } from './hooks/useStateStoring';
import { useOnFilterChanged } from './hooks/useOnFilterChanged';
import { CustomizationOptions } from './options/CustomizationOptions';
import { useOnRowPrepared } from './hooks/useOnRowPrepared';
import { DetailViewOptions } from './options/DetailViewOptions';
import { useOnRowsExpand } from '@/Components/DataTable/hooks/useOnRowsExpand';

export type DataTableProps<TData extends object> = {
  gridRef?: React.MutableRefObject<DataGrid<TData, keyof TData> | undefined>;

  /*
    Required!
    */
  rowKey: keyof TData | '';
  rows: TData[];
  seleсtedRowKeys?: (string | number)[];
  columns?: TColumn[];
  mergeColumnsMap?: Partial<{ [K in keyof TData]: boolean }>;
  columnInfos?: ColumnInfo<TData>[];
  stateStoringKey?: string;

  /*
    Options
  */
  defaultOptions?: DefaultOptions;
  selectOptions?: SelectOptions<TData>;
  editOptions?: EditOptions<TData>;
  dragOptions?: DragOptions<TData>;
  filterOptions?: FilterOptions<TData>;
  exportOptions?: ExportOptions;
  customizationOptions?: CustomizationOptions<TData>;
  detailViewOptions?: DetailViewOptions<TData>;
} & ClassNameProps;

function DataTableFunction<TData extends object>(props: DataTableProps<TData>) {
  const {
    gridRef,
    rowKey,
    rows,
    seleсtedRowKeys,
    mergeColumnsMap,
    columnInfos,
    stateStoringKey,
    className,

    defaultOptions: {
      height,
      allowColumnReordering = true,
      allowColumnResizing = true,
      columnAutoWidth = true,
      showBorders = true,
      showRowLines = true,
      showPagination = true,
      showPageSizeSelector = true,
    } = {},

    selectOptions: {
      onFocusedRowChanged,
      onSelectedRowsChanged,
      onRowDblClick = EMPTY_ACTION,
      showCheckBoxesMode = 'none',
    } = {},

    editOptions: { needEditing, showActionColumn, mode = 'row', onEditIconClick, onSave } = {},

    dragOptions: { showDragIcons, onDragEnd, onTableReorder, dragGroup, dragBetweenTablesId } = {},

    filterOptions: {
      needGlobalSearchPanel = true,
      needColumnChooser,
      needSearchFilterRow,
      needHeaderFilter = true,
      needSorting = true,
      needClearFiltersButton = true,
      needShowFiltersButton = true,
      showFilterBuilder = true,
      searchVisibleColumnsOnly = true,
      sortHighlightExcludeKey,
    } = {},

    exportOptions: {
      needExport = true,
      exportFileName = 'ship-cheaper.xlsx',
      exportWorksheetName,
      exportSelectedData = true,
    } = {},

    customizationOptions: { customizeRow } = {},

    detailViewOptions: {
      expandRows = false,
      onRowCollapsed,
      onRowExpanded,
      onRowCollapsing,
      onRowExpanding,
      detailView,
    } = {},
  } = props;

  // Table ref
  const dataGridRef = useRef<DataGrid<TData, keyof TData>>();
  const innerGridRef = gridRef ?? dataGridRef;
  const gridInstance = innerGridRef.current?.instance;

  // Table states
  const [isFilterRowVisible, setIsFilterRowVisible] = useState(needSearchFilterRow);
  const [isFilterBuilderPopupVisible, setIsFilterBuilderPopupVisible] = useState(false);

  // Table hooks
  const { shouldRerender, resetShouldRerender } = useRerenderOnChangeLocale();
  const { onCellPrepared } = useOnCellPrepared(rowKey, mergeColumnsMap);
  const { onContentReady } = useOnContentReady(onFocusedRowChanged, onSelectedRowsChanged, seleсtedRowKeys);
  const { selectionChanged } = useSelectionChanged(rowKey, innerGridRef, onSelectedRowsChanged, onFocusedRowChanged);
  const { onArrowDownClick, onArrowUpClick } = useKeyboardNavigation();
  const { onReorder } = useOnReorder(rows, onTableReorder);
  const { onSaving } = useOnSaving(onSave);
  const { onExporting } = useOnExporting(exportFileName, exportWorksheetName);
  const { saveState, loadState } = useStateStoring(stateStoringKey);
  const summaryItems = useSummaryItems(columnInfos);
  const columns = useColumns(columnInfos);
  const onFilterChanged = useOnFilterChanged(innerGridRef, sortHighlightExcludeKey);
  const { onRowPrepared } = useOnRowPrepared<TData>(customizeRow);
  const onRowsExpand = useOnRowsExpand(innerGridRef, expandRows);

  useEffect(() => {
    onRowsExpand();
  }, [gridInstance, rows, expandRows]);

  if (shouldRerender) {
    resetShouldRerender();
    return null;
  }

  return (
    <div className={styles.tableWrapper}>
      <DataGrid
        className={cn(className, styles.table)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={innerGridRef}
        width={'0'}
        id="dataGrid"
        keyExpr={(rowKey as string) ?? undefined}
        height={height}
        showBorders={showBorders}
        columnAutoWidth={columnAutoWidth}
        onCellPrepared={onCellPrepared}
        dataSource={rows}
        columnResizingMode={'widget'}
        onOptionChanged={(e) => {
          onFilterChanged(e);

          if (e.fullName === 'paging.pageIndex') {
            onRowsExpand();
          }

          if (e.fullName === 'paging.pageSize') {
            onRowsExpand();
          }
        }}
        onEditorPreparing={(e) => {
          if (e.parentType === 'searchPanel') {
            e.editorOptions.onKeyUp = (e: any) => {
              const text = e.component.option('value');
              e.component.option('value', text.trimStart());
            };
          }
        }}
        allowColumnResizing={allowColumnResizing}
        allowColumnReordering={allowColumnReordering}
        showRowLines={showRowLines}
        onSelectionChanged={selectionChanged}
        onKeyDown={(ev) => {
          switch (ev.event?.code) {
            case 'ArrowDown':
              ev.event?.preventDefault();
              onArrowDownClick(rowKey, innerGridRef);
              break;

            case 'ArrowUp':
              ev.event?.preventDefault();
              onArrowUpClick(innerGridRef);
              break;

            default:
              return;
          }
        }}
        onContentReady={onContentReady}
        onExporting={onExporting}
        selection={{
          mode: 'multiple',
          selectAllMode: 'allPages',
          showCheckBoxesMode: showCheckBoxesMode,
        }}
        onRowDblClick={(e: any) => {
          onRowDblClick?.(e.data);
        }}
        onSaving={onSaving}
        onRowPrepared={onRowPrepared}
        onRowCollapsed={(e) => {
          onRowCollapsed?.(e.key);
        }}
        onRowExpanded={(e) => {
          onRowExpanded?.(e.key);
        }}
        onRowCollapsing={(e) => {
          onRowCollapsing?.(e.key);
        }}
        onRowExpanding={(e) => {
          onRowExpanding?.(e.key);
        }}
      >
        {/* Detail view */}
        {detailView && <MasterDetail component={({ data }) => detailView(data.data)} enabled={true} />}

        {/* Column filters */}
        <ColumnFixing enabled={!detailView} />
        {needSorting && <Sorting mode="multiple" showSortIndexes={true} />}
        {needHeaderFilter && <HeaderFilter visible={true} />}

        {/* Toolbar */}
        {needGlobalSearchPanel && <SearchPanel visible={true} searchVisibleColumnsOnly={searchVisibleColumnsOnly} />}
        {needExport && <Export enabled={needExport} allowExportSelectedData={exportSelectedData} />}
        <FilterRow visible={isFilterRowVisible} showOperationChooser={true} />
        <FilterBuilderPopup
          visible={isFilterBuilderPopupVisible}
          onHiding={() => setIsFilterBuilderPopupVisible(false)}
        />
        {needColumnChooser && <ColumnChooser enabled={true} mode="select" />}

        <Toolbar>
          {needGlobalSearchPanel && <Item name={'searchPanel'} />}

          {showFilterBuilder && (
            <Item location={'after'}>
              <FilterBuilderButton onClick={() => setIsFilterBuilderPopupVisible(true)} />
            </Item>
          )}

          {needClearFiltersButton && (
            <Item location={'after'}>
              <ClearFiltersButton innerGridRef={innerGridRef} />
            </Item>
          )}

          {needShowFiltersButton && (
            <Item location={'after'}>
              <TableSearchButton onClick={() => setIsFilterRowVisible((prev) => !prev)} />
            </Item>
          )}

          {needExport && <Item name={'exportButton'} />}
          {needColumnChooser && <Item name={'columnChooserButton'} />}
        </Toolbar>

        {/* Pagination */}
        {showPagination && <Paging defaultPageSize={50} />}
        {showPagination && (
          <Pager
            visible={true}
            showInfo={true}
            showPageSizeSelector={showPageSizeSelector}
            allowedPageSizes={[50, 100, 200]}
          />
        )}

        {/* Drag and drop */}
        {(onDragEnd || onTableReorder) && (
          <RowDragging
            group={dragGroup}
            data={dragBetweenTablesId}
            allowReordering
            dropFeedbackMode="push"
            onReorder={onReorder}
            onDragEnd={(e: any) => {
              setTimeout(() => {
                onDragEnd?.(e);
              }, 500);
            }}
            showDragIcons={showDragIcons}
          />
        )}

        {/* State storing */}
        {stateStoringKey && <StateStoring enabled={true} customSave={saveState} customLoad={loadState} type="custom" />}

        {/* Editing */}
        <Editing useIcons={true} allowUpdating={needEditing} mode={mode} />
        {showActionColumn && (
          <Column caption={'Action'} type="buttons" fixed={true} fixedPosition={'left'}>
            <Button name={'edit'} onClick={onEditIconClick} />
          </Column>
        )}

        {columns}

        <Summary>{summaryItems}</Summary>
      </DataGrid>
    </div>
  );
}

export const DataTable = React.memo(DataTableFunction) as typeof DataTableFunction;
