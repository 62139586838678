import { DocumentFolderModel } from '@/Models/_Microservices/OnlineArchive/DocumentFolderModel';
import { ApiRoutingUtils } from '@/Routing';
import {
  CreateDocumentFolderRequest,
  CreateDocumentFolderResponse,
  UpdateDocumentFolderRequest,
  UpdateDocumentFolderResponse,
} from '.';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createOnlineArchiveUrl('document-folders');

function getDocumentFolders(): Promise<DocumentFolderModel[]> {
  return rest.get(url);
}

function createDocumentFolder(request: CreateDocumentFolderRequest): Promise<CreateDocumentFolderResponse> {
  return rest.post(url, request);
}

function updateDocumentFolder(request: UpdateDocumentFolderRequest): Promise<UpdateDocumentFolderResponse> {
  return rest.put(url, request);
}

function deleteDocumentFolder(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, {});
}

export const DocumentFoldersApi = {
  getDocumentFolders,
  createDocumentFolder,
  updateDocumentFolder,
  deleteDocumentFolder,
};
