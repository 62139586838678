import React from 'react';
import { useLocalization } from '@/Hooks';
import { stringUtils } from '@/Utils';
import { ModalDialog } from '@/Components/ModalDialog/ModalDialog';
import { useDocumentsModalDialogActions } from './hooks/useDocumentsModalDialogActions';
import { DocumentFolderModel } from '@/Models/_Microservices/OnlineArchive/DocumentFolderModel';

type Props = {
  documentIds: number[];
  documentFolderFrom: Pick<DocumentFolderModel, 'id' | 'name'>[];
  documentFolderTo: Pick<DocumentFolderModel, 'id' | 'name'>;
  isInOnlineArchive?: boolean;
  setVisibleOnlineArchiveModalWindow?: (value: React.SetStateAction<boolean>) => void;
  closeWindow?: () => void;
};

export function DocumentsModalDialog({
  documentIds,
  documentFolderFrom,
  documentFolderTo,
  isInOnlineArchive = true,
  closeWindow,
  setVisibleOnlineArchiveModalWindow,
}: Props): React.ReactElement {
  const {
    onlineArchive: {
      Allocate: { TableSection },
    },
    settingsPage: {
      OnlineArchiveSettings: {
        AllocateCategories: { DisabledCategories },
      },
    },
    buttons: { Yes, No },
  } = useLocalization();

  const deletedFolderName = DisabledCategories['Deleted'];
  const modalDialogText = stringUtils.insertParamsToString(
    deletedFolderName !== documentFolderTo.name
      ? TableSection.YouWantToChangeTheStatusOfDocumentsFromTo
      : TableSection.YouWantToChangeTheStatusOfDocumentsFromToDeleted,
    [
      String(documentIds.length),
      documentFolderFrom
        .map((a) => a.name)
        .filter((value, index, self) => self.indexOf(value) === index)
        .join(','),
      documentFolderTo.name,
    ],
  );

  const { onOk, onClose } = useDocumentsModalDialogActions(
    documentIds,
    Number(documentFolderTo.id),
    documentFolderFrom.map((a) => Number(a.id)),
    isInOnlineArchive,
    closeWindow,
    setVisibleOnlineArchiveModalWindow,
  );

  return (
    <ModalDialog
      title={TableSection.ChangeDocumentsAllocateCategory}
      text={modalDialogText}
      onClose={onClose}
      onOk={onOk}
      okButtonText={Yes}
      cancelButtonText={No}
    />
  );
}
