import React from 'react';
import { ClassNameProps } from '@/Types';
import styles from './Header.scss';
import cn from 'classnames';
import { UserMenu } from '@/Components/Header/modules/UserMenu/UserMenu';
import { Menu } from '@/Components/Header/modules/Menu/Menu';
import { AdaptiveDropDownContent } from '@/Components/AdaptiveDropDownContent/AdaptiveDropDownContent';
import { useLocalization } from '@/Hooks';

type Props = ClassNameProps;

export const Header: React.FC<Props> = ({ className }: Props) => {
  const {
    menu: { Title },
  } = useLocalization();

  return (
    <header className={cn(className, styles.header)}>
      <AdaptiveDropDownContent title={Title}>
        <Menu />
      </AdaptiveDropDownContent>
      <UserMenu className={styles.userMenu} />
    </header>
  );
};
