import { IDocumentScanService } from './IDocumentScanService';

class WebDocumentScanService implements IDocumentScanService {
  loadDocuments() {
    return [];
  }

  moveLoadedDocuments() {
    return [];
  }

  clearFolder() {
    return [];
  }

  checkIsPathExists() {
    return false;
  }
}

export const documentScanService = new WebDocumentScanService();
