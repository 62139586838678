import React, { useCallback, useRef } from 'react';
import { DataTableWithStoreRef } from '@/Components/DataTable/DataTableWithStore';
import { DtDragEvent } from '@/Components/DataTable/options';
import { PushType } from '@/Components/DataTable/types';

type UseDragAndDropTablesReturnType<TData> = {
  ref: React.MutableRefObject<DataTableWithStoreRef<TData>>;
  onDragEnd: (args: DtDragEvent<TData>) => void;
  removeTable: (tableKey: string) => void;
  isStoreEmpty: (tableKey: string) => boolean;
  getItemsByTables: () => { key: string; items: TData[] }[];
  hasEmptyTable: () => boolean;
};

export function useDragAndDropTables<TData>(): UseDragAndDropTablesReturnType<TData> {
  const ref = useRef<DataTableWithStoreRef<TData>>({});

  const isStoreEmpty = useCallback(
    (tableKey: string) => {
      const count = ref.current[tableKey]?.count();
      return count <= 0;
    },
    [ref.current],
  );

  const hasEmptyTable = useCallback(() => {
    for (const key of Object.keys(ref.current)) {
      const dataSource = ref.current[key];
      if (dataSource.count() === 0) {
        return true;
      }
    }
    return false;
  }, [ref.current]);

  const removeTable = useCallback(
    (tableKey: string) => {
      delete ref.current[tableKey];
    },
    [ref],
  );

  const onDragEnd = useCallback(
    (args: DtDragEvent<TData>) => {
      if (args.fromData === args.toData) {
        const table = ref.current[args.fromData];
        table.push([
          {
            type: PushType.remove,
            key: args.itemData[table.key()],
          },
        ]);
        table.push([
          {
            type: PushType.insert,
            data: args.itemData,
            index: args.toIndex,
          },
        ]);
        return;
      }

      const toTableId = args.toData;

      const tablesKeys = Object.keys(ref.current);
      tablesKeys.forEach((tableKey) => {
        const table = ref.current[tableKey];

        if (tableKey === toTableId) {
          table.push([
            {
              type: PushType.insert,
              data: args.itemData,
              index: args.toIndex,
            },
          ]);
        } else {
          table.push([
            {
              type: PushType.remove,
              key: args.itemData[table.key()],
            },
          ]);
        }
      });
    },
    [ref],
  );

  const getItemsByTables = useCallback(() => {
    return Object.keys(ref.current).map((key) => ({ key, items: ref.current[key].items() }));
  }, [ref]);

  return {
    ref,
    onDragEnd,
    removeTable,
    isStoreEmpty,
    getItemsByTables,
    hasEmptyTable,
  };
}
