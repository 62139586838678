import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateDocumentTypeRequest,
  CreateDocumentTypeResponse,
  UpdateDocumentTypeRequest,
  UpdateDocumentTypeResponse,
  DocumentTypesApi,
} from '@/Api/_Microservices/OnlineArchive/DocumentTypes';
import { DocumentTypeModel } from '@/Models/_Microservices/OnlineArchive/DocumentTypeModel';
import { GetDocumentsForDeleteCountRequest } from '@/Api/_Microservices/OnlineArchive/DocumentTypes/GetDocumentsForDeleteCountRequest';

const getDocumentTypes = createAsyncThunk<DocumentTypeModel[], void>('document-types/get-document-type', async () => {
  return DocumentTypesApi.getDocumentTypes();
});

const createDocumentType = createAsyncThunk<CreateDocumentTypeResponse, CreateDocumentTypeRequest>(
  'document-types/create-document-type',
  async (request: CreateDocumentTypeRequest) => {
    return DocumentTypesApi.createDocumentType(request);
  },
);

const updateDocumentType = createAsyncThunk<UpdateDocumentTypeResponse, UpdateDocumentTypeRequest>(
  'document-types/update-document-type',
  async (request: UpdateDocumentTypeRequest) => {
    return DocumentTypesApi.updateDocumentType(request);
  },
);

const deleteDocumentType = createAsyncThunk<number, number>(
  'document-types/delete-document-type',
  async (id: number) => {
    return DocumentTypesApi.deleteDocumentType(id);
  },
);

const getDocumentsForDeleteCount = createAsyncThunk<number, GetDocumentsForDeleteCountRequest>(
  'document-types/get-documents-for-delete-count',
  async (request) => {
    return DocumentTypesApi.getDocumentsForDeleteCount(request);
  },
);

export const asyncActions = {
  getDocumentTypes,
  createDocumentType,
  updateDocumentType,
  deleteDocumentType,
  getDocumentsForDeleteCount,
};
