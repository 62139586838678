import { AdminCountryHolidaysApi } from '@/Api/AdminCountryHolidaysApi/AdminCountryHolidaysApi';
import { CreateHolidayRequest } from '@/Api/AdminCountryHolidaysApi/CreateHolidayRequest';
import { CreateHolidayResponse } from '@/Api/AdminCountryHolidaysApi/CreateHolidayResponse';
import { DeleteHolidayRequest } from '@/Api/AdminCountryHolidaysApi/DeleteHolidayRequest';
import { DeleteHolidayResponse } from '@/Api/AdminCountryHolidaysApi/DeleteHolidayresponse';
import { GetHolidaysRequest } from '@/Api/AdminCountryHolidaysApi/GetHolidaysRequest';
import { GetHolidaysResponse } from '@/Api/AdminCountryHolidaysApi/GetHolidaysResponse';
import { UpdateHolidayRequest } from '@/Api/AdminCountryHolidaysApi/UpdateHolidayRequest';
import { UpdateHolidayResponse } from '@/Api/AdminCountryHolidaysApi/UpdateHolidayResponse';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getHolidays = createAsyncThunk<GetHolidaysResponse, GetHolidaysRequest>(
  'admin-holidays/get-holiday',
  async (request: GetHolidaysRequest) => {
    return AdminCountryHolidaysApi.getHolidays(request);
  },
);

const createCountryHoliday = createAsyncThunk<CreateHolidayResponse, CreateHolidayRequest>(
  'admin-holidays/create-holiday',
  async (request: CreateHolidayRequest) => {
    return AdminCountryHolidaysApi.createHoliday(request);
  },
);

const updateCountryHoliday = createAsyncThunk<UpdateHolidayResponse, UpdateHolidayRequest>(
  'admin-holidays/update-holiday',
  async (request: UpdateHolidayRequest) => {
    return AdminCountryHolidaysApi.updateHoliday(request);
  },
);

const deleteCountryHoliday = createAsyncThunk<DeleteHolidayResponse, DeleteHolidayRequest>(
  'admin-holidays/delete-holiday',
  async (request: DeleteHolidayRequest) => {
    return AdminCountryHolidaysApi.deleteHoliday(request);
  },
);

export const holidaysAsyncActions = {
  getHolidays,
  createCountryHoliday,
  updateCountryHoliday,
  deleteCountryHoliday,
};
