import React from 'react';
import { ClassNameProps, ColoredIconInfo, IconColor } from '@/Types';
import styles from './OrderStatusIcons.scss';
import cn from 'classnames';
import { Tooltip } from '@/Components/Tooltip/Tooltip';

type Props = {
  paymentIconInfo: ColoredIconInfo;
  shippingIconInfo: ColoredIconInfo;
  receivedFeedbackIconInfo: ColoredIconInfo;
  leftFeedbackIconInfo: ColoredIconInfo;
} & ClassNameProps;

export const OrderStatusIcons: React.FC<Props> = ({
  leftFeedbackIconInfo,
  receivedFeedbackIconInfo,
  paymentIconInfo,
  shippingIconInfo,
  className,
}: Props) => {
  return (
    <div className={cn(styles.orderStatusIcons, className)}>
      <Tooltip className={styles.icon} text={paymentIconInfo.tooltipText} shouldShowOnHover={true}>
        <svg
          className={getColorClass(paymentIconInfo.color)}
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.402 16.4904C14.4473 16.4904 16.1633 15.7624 17.55 14.3064L19.214 15.9444C18.3993 16.8804 17.394 17.591 16.198 18.0764C15.002 18.5617 13.6933 18.8044 12.272 18.8044C10.764 18.8044 9.36867 18.527 8.086 17.9724C6.80333 17.4004 5.72 16.603 4.836 15.5804C3.952 14.5577 3.328 13.3704 2.964 12.0184H0V10.5624H2.704C2.66933 10.1117 2.652 9.75635 2.652 9.49635C2.652 9.23635 2.66933 8.88102 2.704 8.43035H0V6.97435H2.964C3.328 5.62235 3.952 4.43502 4.836 3.41235C5.72 2.38969 6.80333 1.60102 8.086 1.04635C9.36867 0.474354 10.764 0.188354 12.272 0.188354C13.7107 0.188354 15.0193 0.431021 16.198 0.916354C17.394 1.40169 18.3993 2.10369 19.214 3.02235L17.55 4.66035C16.1807 3.22169 14.4647 2.50235 12.402 2.50235C10.8247 2.50235 9.438 2.90969 8.242 3.72435C7.046 4.53902 6.20533 5.62235 5.72 6.97435H13.962V8.43035H5.356C5.304 8.75969 5.278 9.11502 5.278 9.49635C5.278 9.86035 5.304 10.2157 5.356 10.5624H13.962V12.0184H5.72C6.20533 13.3704 7.046 14.4537 8.242 15.2684C9.438 16.083 10.8247 16.4904 12.402 16.4904Z"
            fill="#C3D3DD"
          />
        </svg>
      </Tooltip>
      <Tooltip className={styles.icon} text={shippingIconInfo.tooltipText} shouldShowOnHover={true}>
        <svg
          className={getColorClass(shippingIconInfo.color)}
          width="23"
          height="23"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.1695 17.9814L0.369291 14.8847C0.194005 14.8262 0.0771484 14.651 0.0771484 14.4465V4.86418C0.0771484 4.74732 0.194005 4.65968 0.310863 4.68889L8.11107 7.81482C8.28636 7.87324 8.40321 8.04853 8.40321 8.25303V17.8061C8.40321 17.9522 8.28636 18.0398 8.1695 17.9814Z"
            fill="#C3D3DD"
          />
          <path
            d="M10.682 17.9814L18.4822 14.8847C18.6574 14.8262 18.7743 14.651 18.7743 14.4465V4.86418C18.7743 4.74732 18.6575 4.65968 18.5406 4.68889L10.7404 7.84403C10.5651 7.90246 10.4482 8.07774 10.4482 8.28224V17.8353C10.4482 17.9522 10.5651 18.0398 10.682 17.9814Z"
            fill="#C3D3DD"
          />
          <path
            d="M8.9871 6.26646L0.573394 2.96525C0.398108 2.90682 0.427322 2.67311 0.573394 2.61468L9.04553 0.0438214C9.27924 -0.0146071 9.51296 -0.0146071 9.71746 0.0438214L18.1896 2.64389C18.3649 2.70232 18.3649 2.93603 18.1896 2.99446L9.86353 6.26646C9.57139 6.3541 9.27924 6.3541 8.9871 6.26646Z"
            fill="#C3D3DD"
          />
        </svg>
      </Tooltip>
      <Tooltip className={styles.icon} text={leftFeedbackIconInfo.tooltipText} shouldShowOnHover={true}>
        <svg
          className={getColorClass(leftFeedbackIconInfo.color)}
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9357 18.7158C13.9357 15.7555 16.3091 13.3821 19.2694 13.3821V5.9046L14.0122 0.749481H6.12643C5.0801 0.749481 4.2124 1.59165 4.2124 2.66351V17.6695C4.2124 18.7158 5.05458 19.5835 6.12643 19.5835H13.9867C13.9612 19.2773 13.9357 18.9966 13.9357 18.7158ZM13.9357 2.20414L17.7127 5.98116L13.9357 6.00668V2.20414ZM7.5811 8.86497H15.9007C16.258 8.86497 16.5643 9.14569 16.5643 9.5285C16.5643 9.88578 16.2836 10.192 15.9007 10.192H7.5811C7.22381 10.192 6.91757 9.9113 6.91757 9.5285C6.94309 9.14569 7.22381 8.86497 7.5811 8.86497ZM7.5811 11.9529H15.9007C16.258 11.9529 16.5643 12.2337 16.5643 12.6165C16.5643 12.9737 16.2836 13.28 15.9007 13.28H7.5811C7.22381 13.28 6.91757 12.9993 6.91757 12.6165C6.94309 12.2592 7.22381 11.9529 7.5811 11.9529ZM12.7873 16.368H7.5811C7.22381 16.368 6.91757 16.0872 6.91757 15.7044C6.91757 15.3471 7.19829 15.0409 7.5811 15.0409H12.7873C13.1445 15.0409 13.4508 15.3216 13.4508 15.7044C13.4508 16.0617 13.1445 16.368 12.7873 16.368Z"
            fill="#C3D3DD"
          />
          <path
            d="M14.1912 19.7112H6.12672C4.9783 19.7112 4.05957 18.7924 4.05957 17.644V2.66353C4.05957 1.51511 4.9783 0.596375 6.12672 0.596375H14.0891L19.4484 5.82806V13.5352H19.2952C16.437 13.5352 14.1146 15.8576 14.1146 18.7159C14.1146 18.9711 14.1401 19.2518 14.1912 19.558V19.7112ZM6.12672 0.902619C5.15695 0.902619 4.36582 1.69375 4.36582 2.66353V17.6695C4.36582 18.6393 5.15695 19.4304 6.12672 19.4304H13.8084C13.7828 19.1752 13.7573 18.9455 13.7573 18.7414C13.7573 15.7555 16.1307 13.3311 19.0911 13.2545V5.95566L13.936 0.902619H6.12672ZM12.7875 16.5211H7.58139C7.14754 16.5211 6.76473 16.1638 6.76473 15.7044C6.76473 15.2706 7.12202 14.8878 7.58139 14.8878H12.7875C13.2214 14.8878 13.6042 15.2451 13.6042 15.7044C13.6042 16.1638 13.2469 16.5211 12.7875 16.5211ZM7.58139 15.2196C7.30066 15.2196 7.0965 15.4492 7.0965 15.7044C7.0965 15.9597 7.32618 16.1893 7.58139 16.1893H12.7875C13.0683 16.1893 13.2724 15.9597 13.2724 15.7044C13.2724 15.4492 13.0428 15.2196 12.7875 15.2196H7.58139ZM15.901 13.4076H7.58139C7.14754 13.4076 6.76473 13.0503 6.76473 12.591C6.76473 12.1571 7.12202 11.7743 7.58139 11.7743H15.901C16.3349 11.7743 16.7177 12.1316 16.7177 12.591C16.7177 13.0503 16.3349 13.4076 15.901 13.4076ZM7.58139 12.1061C7.30066 12.1061 7.0965 12.3358 7.0965 12.591C7.0965 12.8717 7.32618 13.0758 7.58139 13.0758H15.901C16.1818 13.0758 16.3859 12.8462 16.3859 12.591C16.3859 12.3102 16.1562 12.1061 15.901 12.1061H7.58139ZM15.901 10.3196H7.58139C7.14754 10.3196 6.76473 9.96236 6.76473 9.50299C6.76473 9.06915 7.12202 8.68634 7.58139 8.68634H15.901C16.3349 8.68634 16.7177 9.04363 16.7177 9.50299C16.7177 9.96236 16.3349 10.3196 15.901 10.3196ZM7.58139 9.01811C7.30066 9.01811 7.0965 9.24779 7.0965 9.50299C7.0965 9.7582 7.32618 9.98788 7.58139 9.98788H15.901C16.1818 9.98788 16.3859 9.7582 16.3859 9.50299C16.3859 9.24779 16.1562 9.01811 15.901 9.01811H7.58139ZM13.7573 6.15982V1.82135L18.0703 6.1343L13.7573 6.15982ZM14.0891 2.58697V5.85358L17.3302 5.82806L14.0891 2.58697Z"
            fill="#C3D3DD"
          />
          <path
            d="M19.2695 15.0664L20.3924 17.3377L22.919 17.7205L21.0815 19.4814L21.5153 22.008L19.2695 20.8085L16.9982 22.008L17.4321 19.4814L15.6201 17.7205L18.1466 17.3377L19.2695 15.0664Z"
            fill="#C3D3DD"
          />
          <path
            d="M1.53263 3.45467C0.792535 3.45467 0.205566 4.04164 0.205566 4.78173V16.6487H1.27742C1.53263 16.6487 1.73679 16.8529 1.73679 17.1081C1.73679 17.3633 1.53263 17.5674 1.27742 17.5674H0.205566C0.231087 17.7206 0.282127 17.8992 0.384208 18.0268L1.20086 19.558C1.35398 19.8132 1.71127 19.8132 1.86439 19.558L2.68104 18.0268C2.80865 17.8226 2.85969 17.6185 2.85969 17.3633V4.78173C2.85969 4.04164 2.27272 3.45467 1.53263 3.45467Z"
            fill="#C3D3DD"
          />
        </svg>
      </Tooltip>
      <Tooltip className={styles.icon} text={receivedFeedbackIconInfo.tooltipText} shouldShowOnHover={true}>
        <svg
          className={getColorClass(receivedFeedbackIconInfo.color)}
          width="32"
          height="20"
          viewBox="0 0 32 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.9528 12.4064C24.0007 11.2452 25.5017 10.5372 27.1726 10.5372V2.40897L18.8462 9.03615L22.9528 12.4064Z"
            fill="#C3D3DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.3139 2.11526V10.6788H27.1723C25.5425 10.6788 24.0793 11.3691 23.0576 12.5012L22.9671 12.6015L18.6206 9.03445L27.3139 2.11526ZM19.0711 9.03782L22.9391 12.2122C23.9777 11.1133 25.4234 10.4349 27.0307 10.3972V2.70265L19.0711 9.03782Z"
            fill="#C3D3DD"
          />
          <path
            d="M26.0399 0.766328C25.9266 0.738007 25.8416 0.738007 25.7283 0.738007H2.73145L14.3148 10.084L26.0399 0.766328Z"
            fill="#C3D3DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.733 0.596375C25.8442 0.596369 25.9441 0.596364 26.0743 0.628924L26.3529 0.698553L14.3143 10.2654L2.33057 0.596375L25.733 0.596375ZM25.6698 0.879588H3.1326L14.3157 9.90259L25.6698 0.879588Z"
            fill="#C3D3DD"
          />
          <path
            d="M21.4801 16.2297C21.4801 15.2951 21.7067 14.4172 22.1032 13.6525L17.6284 9.99905L15.561 11.6417C15.1928 11.9249 14.768 12.0665 14.3149 12.0665C13.8617 12.0665 13.4369 11.9249 13.0687 11.6134L11.0862 10.0274L2.30664 17.1927C2.36328 17.1927 2.44825 17.221 2.50489 17.221H21.5651C21.5085 16.9094 21.4801 16.5696 21.4801 16.2297Z"
            fill="#C3D3DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6297 9.81721L22.2819 13.6155L22.2289 13.7177C21.8427 14.4625 21.6218 15.3182 21.6218 16.2297C21.6218 16.5621 21.6495 16.8934 21.7044 17.1956L21.7348 17.3626H2.5049C2.4621 17.3626 2.41446 17.353 2.38348 17.3468C2.38159 17.3464 2.37976 17.346 2.378 17.3457C2.33783 17.3376 2.31788 17.3342 2.30665 17.3342H1.90918L11.0854 9.8453L13.1602 11.5052C13.5015 11.7939 13.8934 11.9249 14.3149 11.9249C14.7366 11.9249 15.1307 11.7937 15.4738 11.5301L17.6297 9.81721ZM17.6273 10.1809L15.6474 11.7539C15.2543 12.0563 14.799 12.2081 14.3149 12.2081C13.8307 12.2081 13.3736 12.0562 12.9788 11.7227L11.0871 10.2094L2.66941 17.0794H21.3984C21.3584 16.8063 21.3385 16.5177 21.3385 16.2297C21.3385 15.3137 21.551 14.4501 21.9255 13.6902L17.6273 10.1809Z"
            fill="#C3D3DD"
          />
          <path
            d="M1.08887 1.98415C1.08887 2.04079 1.06055 2.12575 1.06055 2.1824V15.7766C1.06055 15.9465 1.08887 16.0881 1.14551 16.2298L9.89679 9.09279L1.08887 1.98415Z"
            fill="#C3D3DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.947268 1.68787L10.1216 9.09219L1.08563 16.4613L1.01404 16.2823C0.950561 16.1236 0.918945 15.9641 0.918945 15.7766V2.18237C0.918945 2.13957 0.928525 2.09194 0.934756 2.06096C0.935136 2.05907 0.935504 2.05724 0.935857 2.05548C0.943892 2.0153 0.947268 1.99535 0.947268 1.98413V1.68787ZM1.20216 2.25753V15.7766C1.20216 15.8522 1.20832 15.9207 1.22061 15.9858L9.67203 9.09334L1.20216 2.25753Z"
            fill="#C3D3DD"
          />
          <path
            d="M27.173 12.2364L28.4474 14.757L31.2513 15.1818L29.2121 17.136L29.6936 19.9398L27.173 18.6087L24.6807 19.9398L25.1622 17.136L23.123 15.1818L25.9269 14.757L27.173 12.2364Z"
            fill="#C3D3DD"
          />
        </svg>
      </Tooltip>
    </div>
  );
};

function getColorClass(color: IconColor) {
  switch (color) {
    case IconColor.Grey:
      return styles.grey;
    case IconColor.Blue:
      return styles.blue;
    case IconColor.Yellow:
      return styles.yellow;
    case IconColor.Red:
      return styles.red;
    case IconColor.Lila:
      return styles.lila;
    case IconColor.Orange:
      return styles.orange;
    default:
      return styles.grey;
  }
}
