import { FormData as MergeOrdersFormData } from '../../../hooks/useMergeDiffForm';
import { OrderTableRow } from '@/Types';
import { useEffect } from 'react';
import { useRequired } from '@/Hooks/Validation';
import { useFormContext } from '@/Hooks/useFormWrapper';

export type FormData = Pick<
  MergeOrdersFormData,
  'buyerInfoSourceOrderId' | 'buyerEmail' | 'buyerNote' | 'buyerMarketplaceAccount' | 'buyerVatNumber'
>;

export const useMergeOrdersBuyerSectionForm = (orders: OrderTableRow[]) => {
  const { register, setValue, watch, formState } = useFormContext<FormData>();

  const buyerInfoSourceId = watch('buyerInfoSourceOrderId');

  useEffect(() => {
    if (!buyerInfoSourceId) return;

    const order = orders.find((o) => o.id == buyerInfoSourceId);
    if (!order) return;

    setValue('buyerEmail', order.buyerEmail);
    setValue('buyerNote', order.buyerNote);
    setValue('buyerMarketplaceAccount', order.buyerMarketplaceAccount);
    setValue('buyerVatNumber', order.buyerVatNumber);
  }, [buyerInfoSourceId, orders, setValue]);

  const required = useRequired();

  return {
    register,
    setValue,
    errors: formState.errors,
    rules: {
      required,
    },
  };
};
