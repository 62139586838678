import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@/Redux/RootReducer';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { WarehouseTransferHub } from '@/SignalR/Hubs/WarehouseTransferHub';
import { ProductTransferModel } from '@/Models/ProductModel';
import { Subject } from 'rxjs';
import { SoundUtils } from '@/Utils/SoundUtils';
import { Audios } from '@/Assets/Audios';
import { concatMap } from 'rxjs/operators';
import { transferPageActions } from '@/Pages/Products/services/transfer';

export class WarehouseTransferHubSubscriptionInitializer {
  private static instance: WarehouseTransferHubSubscriptionInitializer;
  private _dispatch!: ThunkDispatch<RootState, unknown, AnyAction>;
  private _taskHub!: WarehouseTransferHub;

  private _productTransferModelSubject = new Subject<ProductTransferModel>();

  constructor(dispatch: AppDispatch) {
    if (WarehouseTransferHubSubscriptionInitializer.instance)
      return WarehouseTransferHubSubscriptionInitializer.instance;

    WarehouseTransferHubSubscriptionInitializer.instance = this;

    this._dispatch = dispatch;
    this._taskHub = new WarehouseTransferHub();

    this._productTransferModelSubject
      .asObservable()
      .pipe(
        concatMap((product: ProductTransferModel) => {
          let sound: HTMLAudioElement;

          if (product.errors?.length) {
            sound = SoundUtils.playSound(Audios.Barcode.BarcodeError, { playbackRate: 1.6 });
          } else {
            sound = SoundUtils.playSound(Audios.Barcode.BarcodeSuccess, { playbackRate: 1.6 });
          }

          return new Promise<ProductTransferModel>((resolve) => {
            sound.onended = () => {
              resolve(product);
            };
          });
        }),
      )
      .subscribe();
  }

  public start() {
    this._taskHub.start();
  }

  public stop() {
    this._taskHub.stop();
  }

  public initialize() {
    this._taskHub.onGetWarehouseTransferProduct(this.onGetWarehouseTransferProductTask);
  }

  private onGetWarehouseTransferProductTask = (productTransferWarehouse: ProductTransferModel) => {
    this._productTransferModelSubject.next(productTransferWarehouse);
    this._dispatch(transferPageActions.addTransferProducts(productTransferWarehouse));
  };
}
