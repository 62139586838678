import { IFolderBrowserDialogService } from './IFolderBrowserDialogService';

class WebFolderBrowserDialogService implements IFolderBrowserDialogService {
  canOpen(): boolean {
    return false;
  }
  open(): string {
    return '';
  }
}

export const folderBrowserDialogService = new WebFolderBrowserDialogService();
