import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CookieState = {
  cookieData: {
    isModalVisible: boolean;
  };
};

const initialState: CookieState = {
  cookieData: {
    isModalVisible: false,
  },
};

export const cookieSlice = createSlice({
  name: 'cookie',
  initialState,
  reducers: {
    setModalVisible: (state, action: PayloadAction<boolean>) => {
      state.cookieData.isModalVisible = action.payload;
    },
  },
});

export const cookieActions = cookieSlice.actions;
export const cookieReducer = cookieSlice.reducer;
