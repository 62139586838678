export enum OrderMarker {
  Paid = 'Paid',
  NotPaid = 'NotPaid',
  Shipped = 'Shipped',
  NotShipped = 'NotShipped',
  'Received' = 'Received',
  'NotReceived' = 'NotReceived',
  Notified = 'Notified',
  NotNotified = 'NotNotified',
  CanceledNotPaid = 'CanceledNotPaid',
  NotCanceledNotPaid = 'NotCanceledNotPaid',
  RefundedPaid = 'RefundedPaid',
  NotRefundedPaid = 'NotRefundedPaid',
  RefundedAndNotReturned = 'RefundedAndNotReturned',
  NotRefundedAndNotReturned = 'NotRefundedAndNotReturned',
  Archived = 'Archived',
  NotArchived = 'NotArchived',
  Exported = 'Exported',
  NotExported = 'NotExported',
  FeedbackSubmitted = 'FeedbackSubmitted',
  NotFeedbackSubmitted = 'NotFeedbackSubmitted',
  OrderEditEnabled = 'OrderEditEnabled',
  OrderEditDisabled = 'OrderEditDisabled',
  AddressEditEnabled = 'AddressEditEnabled',
  AddressEditDisabled = 'AddressEditDisabled',
}
