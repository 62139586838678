import { createAsyncThunk } from '@reduxjs/toolkit';
import { LocalizationType } from '@/Enums';
import { TranslationsApi } from '@/Api/Translations/TranslationsApi';
import { LanguageResourceType } from '@/Enums/LanguageResourceType';

const getTranslation = createAsyncThunk('global/get-translation', async (localization: LocalizationType) => {
  const result = await TranslationsApi.getTranslation({
    languageResourceType: LanguageResourceType.Erp,
    localization: localization,
  });

  return {
    localization: localization,
    translations: result.translations,
  };
});

const getDocumentDesignerTranslation = createAsyncThunk(
  'global/get-document-designer-translation',
  async (localization: LocalizationType) => {
    const result = await TranslationsApi.getTranslation({
      languageResourceType: LanguageResourceType.DocumentTemplates,
      localization: localization,
    });

    return {
      translations: result.translations,
    };
  },
);

const getProductsTranslation = createAsyncThunk(
  'global/get-products-translation',
  async (localization: LocalizationType) => {
    const result = await TranslationsApi.getTranslation({
      languageResourceType: LanguageResourceType.Products,
      localization: localization,
    });

    return {
      translations: result.translations,
    };
  },
);

export const globalAsyncActions = {
  getTranslation,
  getDocumentDesignerTranslation,
  getProductsTranslation,
};
