import { useLocalization } from '@/Hooks';

export const useShouldBeCurrency = () => {
  const { validation } = useLocalization();

  return {
    pattern: {
      value: /^(\d{1,3}(,\d{3})*|\d+)(\.\d{1,2})?$/,
      message: validation.ValueMustBeNumber,
    },
  };
};
