import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';

const transferPage = (state: RootState) => state.productsPage.transfer;

const transferPoints = createSelector(transferPage, (state) => state.transferPoints);

const transferModal = createSelector(transferPage, (state) => state.transferProducts);

const productReports = createSelector(transferPage, (state) => state.productReports.reports);

const selectedReportIds = createSelector(transferPage, (state) => state.productReports.selectedReportIds);

const deleteReportsModalOpen = createSelector(transferPage, (state) => state.productReports.isDeleteModalOpen);

const transferErrors = createSelector(transferPage, (state) => state.transferErrors);

export const transferPageSelectors = {
  transferPoints,
  transferModal,
  productReports,
  selectedReportIds,
  deleteReportsModalOpen,
  transferErrors,
};
