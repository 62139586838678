import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pair } from '@/Types';

export type LogisticTypesDataState = {
  internationalTypes: Pair[];
  countryCodes: Pair[];
  shippingServiceTypes: Pair[];
};

const initialState: LogisticTypesDataState = {
  internationalTypes: [],
  countryCodes: [],
  shippingServiceTypes: [],
};

const logisticTypesDataSlice = createSlice({
  name: 'logisticDataTypesState',
  initialState: initialState,
  reducers: {
    setInternationalTypes: (state, action: PayloadAction<Pair[]>) => {
      state.internationalTypes = action.payload;
    },
    setCountryCodes: (state, action: PayloadAction<Pair[]>) => {
      state.countryCodes = action.payload;
    },
    setShippingServiceTypes: (state, action: PayloadAction<Pair[]>) => {
      state.shippingServiceTypes = action.payload;
    },
  },
});

export const logisticTypesDataReducer = logisticTypesDataSlice.reducer;
export const logisticTypesDataActions = logisticTypesDataSlice.actions;
