import React from 'react';

type Props = {
  current: number;
  maximum: number;
  id: number;
};

export function BackgroundTaskItem({ maximum, current, id }: Props) {
  return (
    <div style={{ display: 'flex' }}>
      <progress id={id.toString()} value={current} max={maximum} style={{ marginRight: 5, flex: 1 }} />
      {`${current}/${maximum}`}
    </div>
  );
}
