import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ApiRouting } from '@/Routing';
import { RetryPolicy } from '@/SignalR';
import { LogUtil } from '@/Utils';
import { ProductTransferModel } from '@/Models/ProductModel';
import { WarehouseTransferMethod } from '@/SignalR/Data/WarehouseTransferMethod';

export class WarehouseTransferHub {
  public static getInstance() {
    return WarehouseTransferHub.instance;
  }

  private static instance: WarehouseTransferHub;
  private connection!: HubConnection;

  constructor() {
    if (WarehouseTransferHub.instance) {
      return WarehouseTransferHub.instance;
    }

    WarehouseTransferHub.instance = this;

    this.connection = new HubConnectionBuilder()
      .withUrl(ApiRouting.Hubs.WarehouseTransferHubRoute)
      .withAutomaticReconnect(new RetryPolicy())
      .configureLogging(LogLevel.Error)
      .build();

    this.connection.onreconnected(() => LogUtil.ColorLog('WarehouseTransferHub reconnected'));
  }

  public start = async () => {
    try {
      await this.connection?.start();
      LogUtil.ColorLog('WarehouseTransferHub connected success');
    } catch (err) {
      LogUtil.LogError('WarehouseTransferHub connection error', err);
      setTimeout(this.start, 5000);
    }
  };

  public stop = async () => {
    try {
      await this.connection?.stop();
      LogUtil.ColorLog('WarehouseTransferHub disconnected success');
    } catch (err) {
      LogUtil.ColorLog('WarehouseTransferHub connection error');
      setTimeout(this.stop, 5000);
    }
  }; // stop

  public onGetWarehouseTransferProduct = (handle: (data: ProductTransferModel) => void) => {
    this.connection.on(WarehouseTransferMethod.GET_WAREHOUSE_TRANSFER_PRODUCT, (data: ProductTransferModel) => {
      LogUtil.Info('new WarehouseTransferProduct signalR', data);
      handle(data);
    });
  };
}
