import { TranslationType } from '@/Localization/LanguageKeys';
import { Nullable } from '@/Types';

export enum InternationalType {
  Domestic = 'Domestic',
  International = 'International',
  InternationalDuty = 'InternationalDuty',
}

const getInternationalTypeNameByType = (
  internationalType: Nullable<InternationalType>,
  translation: TranslationType,
): string => {
  if (!internationalType) return '';

  const {
    Enums: { LogisticsInternationalType: LogisticsInternationalTypeLocalization },
  } = translation;

  const internationalTypeMap = {
    [InternationalType.Domestic]: LogisticsInternationalTypeLocalization.National,
    [InternationalType.International]: LogisticsInternationalTypeLocalization.International,
    [InternationalType.InternationalDuty]: LogisticsInternationalTypeLocalization.InternationalDuty,
  };

  return internationalTypeMap[internationalType];
};

export const internationalTypeUtils = {
  getInternationalTypeNameByType,
};
