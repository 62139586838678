export enum TimeZoneRegion {
  Europe = 'Europe',
  America = 'America',
  Atlantic = 'Atlantic',
  Africa = 'Africa',
  Asia = 'Asia',
  IndianOcean = 'IndianOcean',
  Australia = 'Australia',
  Pacific = 'Pacific',
  Antarctica = 'Antarctica',
  Other = 'Other',
}
