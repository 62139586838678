import React, { useMemo } from 'react';
import { ColumnInfo } from '@/Types';
import { TotalItem } from 'devextreme-react/data-grid';
import { getColumnRender } from '../services/utils';

export const useSummaryItems = <TData extends object>(columnInfos?: ColumnInfo<TData>[]) => {
  return useMemo(() => {
    return columnInfos?.map(({ columnType, summary, fieldName }) => {
      if (!summary || !columnType) return null;

      return (
        <TotalItem
          key={fieldName as string}
          column={fieldName}
          summaryType={summary.type}
          displayFormat={'{0}'}
          customizeText={(value: any) => {
            return getColumnRender(columnType, value.value);
          }}
        />
      );
    });
  }, [columnInfos]);
};
