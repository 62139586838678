export enum AutomaticallyGeneratedDocumentType {
  // Pack List
  PL = 'PL',

  // Order (sales)
  SORD = 'SORD',

  // Order (purchases)
  PORD = 'PORD',

  // Invoice
  INV = 'INV',

  // Cancel Invoice
  CINV = 'CINV',

  // Document print
  DPRNT = 'DPRNT',
}
