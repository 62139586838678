import { ApiRoutingUtils } from '@/Routing';
import {
  UpdateDocumentsRequest,
  UpdateDocumentsResponse,
  GetDocumentsRequest,
  CreateDocumentsRequest,
  CreateDocumentsResponse,
} from './';
import { DocumentInfoModel } from '@/Models/_Microservices/OnlineArchive/DocumentInfoModel';
import { rest } from '@/Api/Rest';
import { ChangeDocumentFolderRequest } from './ChangeDocumentFolderRequest';
import { ChangeDocumentReadedFlagRequest } from 'src/Api/_Microservices/OnlineArchive/Documents/ChangeDocumentReadedFlagRequest';
import { ChangeDocumentReadedFlagResponse } from 'src/Api/_Microservices/OnlineArchive/Documents/ChangeDocumentReadedFlagResponse';
import { GetDocumentsForPrintRequest } from '@/Api/_Microservices/OnlineArchive/Documents/GetDocumentsForPrintRequest';
import { GetDocumentsForPrintResponse } from '@/Api/_Microservices/OnlineArchive/Documents/GetDocumentsForPrintResponse';

const url = ApiRoutingUtils.createOnlineArchiveUrl('documents');

function getDocuments(request: GetDocumentsRequest): Promise<DocumentInfoModel[]> {
  return rest.get(url, request);
}

function createDocuments(
  request: CreateDocumentsRequest,
  onUploadProgress: (progressEvent: any) => void,
): Promise<CreateDocumentsResponse> {
  return rest.post(url, request, onUploadProgress);
}

function updateDocuments(request: UpdateDocumentsRequest): Promise<UpdateDocumentsResponse> {
  return rest.put(url, request);
}

function changeDocumentFolderByDocumentId(request: ChangeDocumentFolderRequest): Promise<null> {
  return rest.put(`${url}/change-document-folder`, request);
}

function changeDocumentReadedFlag(request: ChangeDocumentReadedFlagRequest): Promise<ChangeDocumentReadedFlagResponse> {
  return rest.put(`${url}/change-document-readed-flag `, request);
}

function getDocumentsForPrint(request: GetDocumentsForPrintRequest): Promise<GetDocumentsForPrintResponse> {
  return rest.get(`${url}/get-documents-for-print`, request);
}

export const DocumentsApi = {
  getDocuments,
  createDocuments,
  updateDocuments,
  changeDocumentFolderByDocumentId,
  changeDocumentReadedFlag,
  getDocumentsForPrint,
};
