import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetLogisticLogosResponse } from './Responses/GetLogisticLogosResponse';
import { CreateLogisticLogoResponse } from './Responses/CreateLogisticLogoResponse';
import { CreateLogisticLogoRequest } from './Requests/CreateLogisticLogoRequest';
import { UpdateLogisticLogoRequest } from './Requests/UpdateLogisticLogoRequest';
import { UpdateLogisticLogoResponse } from './Responses/UpdateLogisticLogoResponse';
import { DeleteLogisticLogoResponse } from './Responses/DeleteLogisticLogoResponse';

const url = ApiRoutingUtils.createUrl('logistics-logo');

async function getLogisticLogos(): Promise<GetLogisticLogosResponse> {
  return rest.get(url);
}

async function createLogisticLogo(request: CreateLogisticLogoRequest): Promise<CreateLogisticLogoResponse> {
  return rest.post(url, request);
}

async function updateLogisticLogo(request: UpdateLogisticLogoRequest): Promise<UpdateLogisticLogoResponse> {
  return rest.put(url, request);
}

async function deleteLogisticLogo(request: number): Promise<DeleteLogisticLogoResponse> {
  return rest.delete(`${url}/${request}`, request);
}

export const LogisticsLogosApi = {
  getLogisticLogos,
  createLogisticLogo,
  updateLogisticLogo,
  deleteLogisticLogo,
};
