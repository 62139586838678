import React, { useMemo, useState } from 'react';
import { ClassNameProps } from '@/Types';
import { DeliveryProgressStepType, LogisticType, MarketplaceType } from '@/Enums';
import styles from './DeliveryStatusIcons.scss';
import cn from 'classnames';
import processingIcon from './images/processing.png';
import { Images } from '@/Static';
import { ProgressList } from '@/Components/ProgressList/ProgressList';
import { ProgressListItem } from '@/Components/ProgressList/modules/ProgressListItem/ProgressListItem';
import { Icons, ModuleIcons } from '@/Static/IconsRes';
import { MpOrdersApi } from '@/Api/MpOrders/MpOrdersApi';
import { useTriggeredValue } from '@/Hooks';
import { DeliveryProgressTimeModal } from '@/ModalWindows/DeliveryProgressTimeModal/DeliveryProgressTimeModal';
import { ShippingStatusModel } from '@/Models/ShippingStatusModel';

type Props = {
  deliveryProgressInfo: ShippingStatusModel[];
  isFirst?: boolean;
} & ClassNameProps;

export const DeliveryStatusIcons: React.FC<Props> = ({ deliveryProgressInfo, isFirst }: Props) => {
  const [orderDeliveryStatuses] = useState([
    DeliveryProgressStepType.Invoice,
    DeliveryProgressStepType.Logistics,
    DeliveryProgressStepType.Delivery,
    DeliveryProgressStepType.Office,
    DeliveryProgressStepType.Pickup,
    DeliveryProgressStepType.Complete,
  ]);

  const {
    status,
    trackingNumber,
    marketplace,
    packageName,
    orderId,
    logisticsType,
    isOwnContract,
  } = deliveryProgressInfo.reduce((prev, current) =>
    orderDeliveryStatuses.indexOf(prev?.status) > orderDeliveryStatuses.indexOf(current?.status) ? prev : current,
  );

  const progressStepType = status ?? DeliveryProgressStepType.Invoice;

  const index = orderDeliveryStatuses.indexOf(progressStepType);

  const { value: isSynchModalOpen, on: openSynchModal, off: closeSynchModal } = useTriggeredValue(false, true, false);

  const marketPlaceImage = useMemo(() => {
    switch (marketplace) {
      case MarketplaceType.Ebay:
        return Images.Ebay;
      case MarketplaceType.Amazon:
        return Images.Amazon;
      case MarketplaceType.Kaufland:
        return Images.Kaufland;
    }
  }, [marketplace]);

  const logisticsImage = useMemo(() => {
    switch (logisticsType) {
      case LogisticType.DeutschePost:
        return Images.DeutschePost;
      case LogisticType.Dhl:
        return Images.Dhl;
      case LogisticType.Gls:
        return Images.Gls;
      case LogisticType.Ups:
        return Images.Ups;
      case LogisticType.DhlExpress:
        return Images.DhlExpress;
    }
  }, [logisticsType]);

  const synchOrderShippingStatus = () => {
    MpOrdersApi.synchShippingsStatusForOrder({ orderId: orderId }).then(() => {
      return;
    });
  };

  return (
    <div className={styles.wrapper}>
      {isSynchModalOpen && (
        <DeliveryProgressTimeModal deliveryProgressHistory={deliveryProgressInfo} closeModalWindow={closeSynchModal} />
      )}
      {isFirst && (
        <div className={styles.firstRow}>
          {!isOwnContract && (
            <div className={styles.imgWrapper}>
              <img src={Images.ShipCheaper} alt={'img'} />
            </div>
          )}
          <div className={styles.imgWrapper}>
            <img src={marketPlaceImage} alt={'img'} />
          </div>
          <div className={styles.imgWrapper}>
            <img src={logisticsImage} alt={'img'} />
          </div>
          <div className={styles.imgWrapper}>
            <img src={processingIcon} alt={'processing'} />
          </div>
          <div className={styles.imgWrapper}>
            <img
              className={styles.imgSynchWrapper}
              onClick={synchOrderShippingStatus}
              src={ModuleIcons.Synchronize}
              alt="Synchronize"
            />
          </div>
        </div>
      )}

      <div className={styles.wrapper}>
        <span className={styles.shipCheaper}>{packageName}</span>
      </div>

      <div className={styles.iconsWrapper}>
        <ProgressList className={styles.progressList}>
          <ProgressListItem isDone={index >= 0} className={styles.progressItem} inProgressColor={'white'}>
            <svg
              className={cn({ [styles.svg]: true, [styles.svg__done]: index >= 0 })}
              width="24"
              height="27"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5848 21.2455C11.5848 17.7663 14.3977 14.9904 17.8399 14.9904V6.21853L11.6958 0.185547H2.4428C1.2214 0.185547 0.185059 1.18488 0.185059 2.44329V20.0611C0.185059 21.2825 1.18439 22.3188 2.4428 22.3188H11.6958C11.6218 21.9487 11.5848 21.6156 11.5848 21.2455ZM11.5848 1.88811L16.0263 6.32957L11.5848 6.36658V1.88811ZM4.14536 9.69767H13.9166C14.3237 9.69767 14.6938 10.0308 14.6938 10.4749C14.6938 10.8821 14.3607 11.2522 13.9166 11.2522H4.14536C3.73823 11.2522 3.3681 10.9191 3.3681 10.4749C3.3681 10.0308 3.73823 9.69767 4.14536 9.69767ZM4.14536 13.3249H13.9166C14.3237 13.3249 14.6938 13.658 14.6938 14.1021C14.6938 14.5093 14.3607 14.8794 13.9166 14.8794H4.14536C3.73823 14.8794 3.3681 14.5463 3.3681 14.1021C3.3681 13.695 3.73823 13.3249 4.14536 13.3249ZM10.2524 18.5066H4.14536C3.73823 18.5066 3.3681 18.1735 3.3681 17.7293C3.3681 17.3222 3.70121 16.9521 4.14536 16.9521H10.2524C10.6595 16.9521 11.0296 17.2852 11.0296 17.7293C11.0296 18.1735 10.6965 18.5066 10.2524 18.5066Z"
                fill="white"
              />
              <path
                d="M11.8809 22.4664H2.4428C1.11036 22.4664 0 21.393 0 20.0236V2.4428C0 1.07335 1.07335 0 2.4428 0H11.7699L18.0619 6.14402V15.175H17.8769C14.5088 15.175 11.8069 17.9139 11.8069 21.245C11.8069 21.5411 11.8439 21.8742 11.8809 22.2073V22.4664ZM2.4428 0.370122C1.29543 0.370122 0.370122 1.29543 0.370122 2.4428V20.0606C0.370122 21.208 1.29543 22.1333 2.4428 22.1333H11.4738C11.4368 21.8372 11.3997 21.5781 11.3997 21.319C11.3997 17.8399 14.2127 14.9529 17.6548 14.8789V6.29207L11.6218 0.370122H2.4428ZM10.2524 18.6911H4.14536C3.62719 18.6911 3.18305 18.247 3.18305 17.7288C3.18305 17.2107 3.62719 16.7665 4.14536 16.7665H10.2524C10.7705 16.7665 11.2147 17.2107 11.2147 17.7288C11.2147 18.247 10.7705 18.6911 10.2524 18.6911ZM4.14536 17.1736C3.81225 17.1736 3.55317 17.4327 3.55317 17.7658C3.55317 18.0989 3.81225 18.358 4.14536 18.358H10.2524C10.5855 18.358 10.8446 18.0989 10.8446 17.7658C10.8446 17.4327 10.5855 17.1736 10.2524 17.1736H4.14536ZM13.9166 15.064H4.14536C3.62719 15.064 3.18305 14.6198 3.18305 14.1016C3.18305 13.5835 3.62719 13.1393 4.14536 13.1393H13.9166C14.4347 13.1393 14.8789 13.5835 14.8789 14.1016C14.8419 14.6198 14.4347 15.064 13.9166 15.064ZM4.14536 13.5094C3.81225 13.5094 3.55317 13.7685 3.55317 14.1016C3.55317 14.4347 3.81225 14.6938 4.14536 14.6938H13.9166C14.2497 14.6938 14.5088 14.4347 14.5088 14.1016C14.5088 13.7685 14.2497 13.5094 13.9166 13.5094H4.14536ZM13.9166 11.3997H4.14536C3.62719 11.3997 3.18305 10.9556 3.18305 10.4374C3.18305 9.91926 3.62719 9.47511 4.14536 9.47511H13.9166C14.4347 9.47511 14.8789 9.91926 14.8789 10.4374C14.8789 10.9556 14.4347 11.3997 13.9166 11.3997ZM4.14536 9.88225C3.81225 9.88225 3.55317 10.1413 3.55317 10.4744C3.55317 10.8076 3.81225 11.0666 4.14536 11.0666H13.9166C14.2497 11.0666 14.5088 10.8076 14.5088 10.4744C14.5088 10.1413 14.2497 9.88225 13.9166 9.88225H4.14536ZM11.3997 6.55115V1.44347L16.4704 6.51414L11.3997 6.55115ZM11.7699 2.33177V6.18103L15.5821 6.14402L11.7699 2.33177Z"
                fill="white"
              />
              <path
                d="M17.8398 16.0635C14.9529 16.0635 12.6211 18.3952 12.6211 21.2822C12.6211 24.1691 14.9529 26.5009 17.8398 26.5009C20.7268 26.5009 23.0585 24.1691 23.0585 21.2822C23.0585 18.3952 20.7268 16.0635 17.8398 16.0635ZM20.9858 19.5796L17.8398 23.4659C17.7658 23.5769 17.6177 23.614 17.5067 23.614C17.4327 23.614 17.3216 23.5769 17.2476 23.5399L14.8048 21.9484C14.6198 21.8004 14.5457 21.5413 14.6938 21.3192C14.8418 21.1341 15.1009 21.0601 15.323 21.2082L17.3957 22.6146L20.2826 19.0244C20.4307 18.8394 20.7268 18.8024 20.9118 18.9504C21.0969 19.1355 21.1339 19.3946 20.9858 19.5796Z"
                fill="white"
              />
            </svg>
          </ProgressListItem>
          <ProgressListItem isDone={index >= 1} className={styles.progressItem} inProgressColor={'white'}>
            <svg
              className={cn({ [styles.svg]: true, [styles.svg__done]: index >= 1 })}
              width="25"
              height="29"
              viewBox="0 0 25 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.2634 18.8307C24.0393 18.756 23.7404 18.8307 23.5537 18.868C22.0969 19.2416 20.7895 20.026 19.3701 20.5116C18.0253 21.0719 16.5685 21.4455 15.0744 21.6696C14.5514 21.7069 14.0285 21.7816 13.4682 21.7443C13.3935 21.7443 13.3561 21.7443 13.3561 21.7443C13.1694 21.7443 13.0947 21.6322 13.0947 21.4828C13.132 21.3707 13.2814 21.2587 13.4308 21.2587C13.4308 21.296 14.7756 21.3707 17.3156 20.6984C17.3903 20.6984 17.5024 20.661 17.5024 20.5863C17.7639 19.5778 15.2612 19.4657 14.7382 19.4284C13.8791 19.391 13.0947 19.3536 12.2355 19.1669C11.4884 19.0548 10.7414 18.756 10.069 18.5692C6.63247 17.7101 4.50331 20.2128 4.50331 20.2128L3.94301 20.7731L7.93985 24.1723C9.09781 23.3131 10.5173 23.5746 10.5173 23.5746C12.6838 23.6867 15.7468 23.7987 15.7468 23.7987C18.6977 23.5746 20.3413 22.4914 20.3413 22.4914C21.4619 21.819 22.5825 21.0719 23.6284 20.2875C23.8899 20.1007 24.114 19.914 24.3007 19.6898C24.5622 19.4284 24.8237 19.0922 24.2634 18.8307Z"
                fill="white"
              />
              <path
                d="M8.01469 26.4136L6.85673 27.721C6.48319 28.2066 5.81083 28.2439 5.32523 27.8331L0.394548 23.6494C-0.0910495 23.2759 -0.128403 22.6035 0.282487 22.1179L1.44045 20.8106C1.81399 20.325 2.48635 20.2876 2.97195 20.6985L7.90263 24.9195C8.35088 25.293 8.38823 25.9654 8.01469 26.4136Z"
                fill="white"
              />
              <path
                d="M12.3479 17.3738L5.17597 14.4975C5.02656 14.4228 4.9145 14.2734 4.9145 14.0867V5.30855C4.9145 5.19648 5.02656 5.12178 5.13862 5.15913L12.3105 8.03536C12.4599 8.11007 12.572 8.25948 12.572 8.44625V17.2244C12.572 17.3364 12.4599 17.4111 12.3479 17.3738Z"
                fill="white"
              />
              <path
                d="M14.664 17.3738L21.8359 14.4975C21.9853 14.4228 22.0974 14.2734 22.0974 14.0867V5.30855C22.0974 5.19648 21.9853 5.12178 21.8733 5.15913L14.7014 8.03536C14.5519 8.11007 14.4399 8.25948 14.4399 8.44625V17.2244C14.4399 17.3364 14.5519 17.4111 14.664 17.3738Z"
                fill="white"
              />
              <path
                d="M13.0951 6.57876L5.36288 3.59046C5.21346 3.51576 5.21346 3.29164 5.36288 3.25428L13.1698 0.863648C13.3566 0.78894 13.5807 0.78894 13.8048 0.863648L21.6117 3.25428C21.7611 3.29164 21.7611 3.51576 21.6117 3.59046L13.8795 6.57876C13.6554 6.69082 13.3566 6.69082 13.0951 6.57876Z"
                fill="white"
              />
            </svg>
          </ProgressListItem>
          <ProgressListItem isDone={index >= 2} className={styles.progressItem} inProgressColor={'white'}>
            <svg
              className={cn({ [styles.svg]: true, [styles.svg__done]: index >= 2 })}
              width="34"
              height="24"
              viewBox="0 0 34 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.43968 23.4292C10.1363 23.4292 11.5117 22.0538 11.5117 20.3572C11.5117 18.6605 10.1363 17.2852 8.43968 17.2852C6.74306 17.2852 5.36768 18.6605 5.36768 20.3572C5.36768 22.0538 6.74306 23.4292 8.43968 23.4292Z"
                fill="white"
              />
              <path
                d="M26.9089 23.4292C28.6055 23.4292 29.9809 22.0538 29.9809 20.3572C29.9809 18.6605 28.6055 17.2852 26.9089 17.2852C25.2123 17.2852 23.8369 18.6605 23.8369 20.3572C23.8369 22.0538 25.2123 23.4292 26.9089 23.4292Z"
                fill="white"
              />
              <path
                d="M33.6819 12.9543V12.7692L31.2762 7.73554C30.795 6.6992 29.7587 6.07 28.6113 6.07H24.3549V1.48049C24.3549 0.666219 23.6887 0 22.8744 0H4.22026C3.40599 0 2.73977 0.666219 2.73977 1.48049V4.47847H11.4006C12.2519 4.47847 12.9551 5.1817 12.9551 6.03298C12.9551 6.88426 12.2519 7.58749 11.4006 7.58749H2.73977H1.48136C0.852157 7.58749 0.333984 8.10566 0.333984 8.73487C0.333984 9.36408 0.852157 9.88225 1.48136 9.88225H2.73977H6.95916C7.81044 9.88225 8.51367 10.5855 8.51367 11.4368C8.51367 12.288 7.81044 12.9913 6.95916 12.9913H2.73977V18.284C2.73977 19.0983 3.40599 19.7645 4.22026 19.7645H4.25728C4.55337 17.7288 6.29294 16.1373 8.43965 16.1373C10.5493 16.1373 12.3259 17.7288 12.622 19.7645H21.3569H22.8004C23.0965 17.7288 24.836 16.1373 26.9827 16.1373C29.0924 16.1373 30.869 17.7288 31.1651 19.7645H32.6086C33.2378 19.7645 33.756 19.2463 33.756 18.6171L33.6819 12.9543ZM28.6113 7.55048C29.1665 7.55048 29.6846 7.88359 29.9437 8.40176L32.1274 12.9543H24.3919V7.55048H28.6113Z"
                fill="white"
              />
            </svg>
          </ProgressListItem>
          <ProgressListItem isDone={index >= 3} className={styles.progressItem} inProgressColor={'white'}>
            <svg
              className={cn({ [styles.svg]: true, [styles.svg__done]: index >= 3 })}
              width="26"
              height="19"
              viewBox="0 0 26 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1173 0.166555C13.6361 -0.0555182 13.0809 -0.0555182 12.5998 0.166555L0.755859 5.53332V18.0434C0.755859 18.4876 1.12598 18.8207 1.53312 18.8207H6.41872C6.86286 18.8207 7.19597 18.4506 7.19597 18.0434V8.42027C7.19597 7.97612 7.5661 7.64301 7.97323 7.64301H19.0769C19.521 7.64301 19.8541 8.01313 19.8541 8.42027V18.0434C19.8541 18.4876 20.2243 18.8207 20.6314 18.8207H25.1839C25.628 18.8207 25.9611 18.4506 25.9611 18.0434V5.53332L14.1173 0.166555Z"
                fill="white"
              />
              <path
                d="M18.5587 9.67882H8.34336C8.1583 9.67882 8.01025 9.53077 8.01025 9.34571V9.30869C8.01025 9.12363 8.1583 8.97559 8.34336 8.97559H18.5587C18.7438 8.97559 18.8918 9.12363 18.8918 9.30869V9.34571C18.8918 9.53077 18.7438 9.67882 18.5587 9.67882Z"
                fill="white"
              />
              <path
                d="M18.5587 9.86339H8.34336C8.04727 9.86339 7.8252 9.64132 7.8252 9.34522V9.30821C7.8252 9.01211 8.04727 8.79004 8.34336 8.79004H18.5587C18.8548 8.79004 19.0769 9.01211 19.0769 9.30821V9.34522C19.0769 9.64132 18.8548 9.86339 18.5587 9.86339ZM8.34336 9.16016C8.26934 9.16016 8.19532 9.23418 8.19532 9.30821V9.34522C8.19532 9.41925 8.26934 9.49327 8.34336 9.49327H18.5587C18.6327 9.49327 18.7068 9.41925 18.7068 9.34522V9.30821C18.7068 9.23418 18.6327 9.16016 18.5587 9.16016H8.34336Z"
                fill="white"
              />
              <path
                d="M18.5587 11.8985H8.34336C8.1583 11.8985 8.01025 11.7505 8.01025 11.5654V11.5284C8.01025 11.3434 8.1583 11.1953 8.34336 11.1953H18.5587C18.7438 11.1953 18.8918 11.3434 18.8918 11.5284V11.5654C18.8918 11.7505 18.7438 11.8985 18.5587 11.8985Z"
                fill="white"
              />
              <path
                d="M18.5587 12.0841H8.34336C8.04727 12.0841 7.8252 11.862 7.8252 11.5659V11.5289C7.8252 11.2328 8.04727 11.0107 8.34336 11.0107H18.5587C18.8548 11.0107 19.0769 11.2328 19.0769 11.5289V11.5659C19.0769 11.862 18.8548 12.0841 18.5587 12.0841ZM8.34336 11.3809C8.26934 11.3809 8.19532 11.4549 8.19532 11.5289V11.5659C8.19532 11.6399 8.26934 11.714 8.34336 11.714H18.5587C18.6327 11.714 18.7068 11.6399 18.7068 11.5659V11.5289C18.7068 11.4549 18.6327 11.3809 18.5587 11.3809H8.34336Z"
                fill="white"
              />
              <path
                d="M18.5587 14.1202H8.34336C8.1583 14.1202 8.01025 13.9722 8.01025 13.7871V13.7501C8.01025 13.565 8.1583 13.417 8.34336 13.417H18.5587C18.7438 13.417 18.8918 13.565 18.8918 13.7501V13.7871C18.8918 13.9722 18.7438 14.1202 18.5587 14.1202Z"
                fill="white"
              />
              <path
                d="M18.5587 14.3048H8.34336C8.04727 14.3048 7.8252 14.0827 7.8252 13.7866V13.7496C7.8252 13.4535 8.04727 13.2314 8.34336 13.2314H18.5587C18.8548 13.2314 19.0769 13.4535 19.0769 13.7496V13.7866C19.0769 14.0827 18.8548 14.3048 18.5587 14.3048ZM8.34336 13.6016C8.26934 13.6016 8.19532 13.6756 8.19532 13.7496V13.7866C8.19532 13.8607 8.26934 13.9347 8.34336 13.9347H18.5587C18.6327 13.9347 18.7068 13.8607 18.7068 13.7866V13.7496C18.7068 13.6756 18.6327 13.6016 18.5587 13.6016H8.34336Z"
                fill="white"
              />
              <path
                d="M18.5587 16.378H8.34336C8.1583 16.378 8.01025 16.23 8.01025 16.0449V16.0079C8.01025 15.8229 8.1583 15.6748 8.34336 15.6748H18.5587C18.7438 15.6748 18.8918 15.8229 18.8918 16.0079V16.0449C18.8918 16.23 18.7438 16.378 18.5587 16.378Z"
                fill="white"
              />
              <path
                d="M18.5587 16.5626H8.34336C8.04727 16.5626 7.8252 16.3405 7.8252 16.0444V16.0074C7.8252 15.7113 8.04727 15.4893 8.34336 15.4893H18.5587C18.8548 15.4893 19.0769 15.7113 19.0769 16.0074V16.0444C19.0769 16.3035 18.8548 16.5626 18.5587 16.5626ZM8.34336 15.8224C8.26934 15.8224 8.19532 15.8964 8.19532 15.9704V16.0074C8.19532 16.0815 8.26934 16.1555 8.34336 16.1555H18.5587C18.6327 16.1555 18.7068 16.0815 18.7068 16.0074V15.9704C18.7068 15.8964 18.6327 15.8224 18.5587 15.8224H8.34336Z"
                fill="white"
              />
              <path
                d="M18.5587 18.5987H8.34336C8.1583 18.5987 8.01025 18.4507 8.01025 18.2656V18.2286C8.01025 18.0436 8.1583 17.8955 8.34336 17.8955H18.5587C18.7438 17.8955 18.8918 18.0436 18.8918 18.2286V18.2656C18.8918 18.4507 18.7438 18.5987 18.5587 18.5987Z"
                fill="white"
              />
              <path
                d="M18.5587 18.7833H8.34336C8.04727 18.7833 7.8252 18.5612 7.8252 18.2651V18.2281C7.8252 17.932 8.04727 17.71 8.34336 17.71H18.5587C18.8548 17.71 19.0769 17.932 19.0769 18.2281V18.2651C19.0769 18.5242 18.8548 18.7833 18.5587 18.7833ZM8.34336 18.0431C8.26934 18.0431 8.19532 18.1171 8.19532 18.1911V18.2281C8.19532 18.3022 8.26934 18.3762 8.34336 18.3762H18.5587C18.6327 18.3762 18.7068 18.3022 18.7068 18.2281V18.1911C18.7068 18.1171 18.6327 18.0431 18.5587 18.0431H8.34336Z"
                fill="white"
              />
            </svg>
          </ProgressListItem>
          <ProgressListItem isDone={index >= 4} className={styles.progressItem} inProgressColor={'white'}>
            <svg
              className={cn({ [styles.svg]: true, [styles.svg__done]: index >= 4 })}
              width="33"
              height="27"
              viewBox="0 0 33 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.1063 4.58951C16.3736 4.58951 17.401 3.56211 17.401 2.29475C17.401 1.0274 16.3736 0 15.1063 0C13.8389 0 12.8115 1.0274 12.8115 2.29475C12.8115 3.56211 13.8389 4.58951 15.1063 4.58951Z"
                fill="white"
              />
              <path
                d="M29.9482 23.54C29.8001 23.2439 29.43 23.1698 29.1709 23.3179L26.7281 24.6873L20.4731 13.8058C20.4361 13.7317 20.0659 13.0655 19.4737 12.6954C18.6965 12.1772 17.8082 12.3623 17.6601 12.3993L16.4017 12.8435L13.0336 10.7708L14.3291 8.36498C14.3291 8.36498 15.2544 6.62541 14.3291 5.47803C14.033 4.95986 13.3297 4.51571 12.7745 4.44169C11.7752 4.33065 10.8499 4.7748 10.3317 5.55205L7.62985 10.5857C7.51881 10.8078 7.29675 11.2149 7.18571 11.548C7.11168 11.6591 6.96364 12.1032 6.8526 12.6214L5.85327 18.2842L1.74492 19.9868C0.338454 20.579 0.856624 23.0958 3.04034 22.4666L7.55583 20.542C7.70388 20.468 7.85193 20.3569 7.96297 20.2459C7.99998 20.2459 7.99997 20.2089 7.99997 20.2089C8.11101 20.0978 8.18504 19.9498 8.25906 19.8017L8.29607 19.7647L9.25839 16.7667L11.8492 19.5427L10.9609 25.4646C10.9239 27.4633 13.4408 27.3522 13.6628 25.4646L14.5141 19.6907C14.5511 19.0615 14.3291 18.4693 14.144 18.1362L13.8479 17.655L11.22 14.0649L11.9973 12.6214C13.3297 13.4727 15.0323 14.509 15.2544 14.657C16.0316 15.1382 16.7719 14.583 16.8829 13.8798L17.9192 13.5097C17.9192 13.5097 18.4004 13.3986 18.7705 13.6577C19.0666 13.8428 19.3257 14.2499 19.3997 14.3609L23.434 21.3933C22.8789 20.8381 22.1016 20.505 21.2503 20.505C19.4737 20.505 18.0303 21.9484 18.0303 23.725C18.0303 25.5016 19.4737 26.9451 21.2503 26.9451C23.0269 26.9451 24.4704 25.5016 24.4704 23.725C24.4704 23.503 24.4334 23.2809 24.3964 23.0588L25.9509 25.7607C26.0619 25.9458 26.247 26.0568 26.469 26.0568C26.5801 26.0568 26.6541 26.0198 26.7651 25.9828L29.7261 24.3172C29.9852 24.1692 30.0962 23.799 29.9482 23.54ZM21.2873 24.8724C20.6581 24.8724 20.14 24.3542 20.14 23.725C20.14 23.0958 20.6581 22.5777 21.2873 22.5777C21.9166 22.5777 22.4347 23.0958 22.4347 23.725C22.4347 24.3542 21.9536 24.8724 21.2873 24.8724Z"
                fill="white"
              />
              <path
                d="M31.6138 21.4296L28.6528 23.1321C27.6905 23.6873 26.5061 23.3542 25.9509 22.3919L23.3601 17.9134C22.8049 16.9511 23.138 15.7667 24.1003 15.2115L27.0613 13.509C28.0236 12.9538 29.208 13.2869 29.7632 14.2492L32.354 18.7277C32.8722 19.653 32.5761 20.8744 31.6138 21.4296Z"
                fill="white"
              />
            </svg>
          </ProgressListItem>
          <ProgressListItem isDone={index >= 5} className={styles.progressItem} inProgressColor={'white'}>
            <svg
              className={cn({ [styles.svg]: true, [styles.svg__done]: index >= 5 })}
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.6314 0.370117H2.67912C1.71681 0.370117 0.976562 1.11036 0.976562 2.07268V18.0619C0.976562 18.9872 1.71681 19.7645 2.67912 19.7645H18.6684C19.5937 19.7645 20.3709 19.0242 20.3709 18.0619V2.07268C20.3339 1.11036 19.5937 0.370117 18.6314 0.370117ZM17.8171 6.55115L10.8588 15.175C10.5997 15.4711 10.2296 15.6561 9.85948 15.6561C9.6004 15.6561 9.34131 15.5821 9.11924 15.4341L3.78949 11.8439C3.19729 11.4368 3.04924 10.6225 3.45638 10.0303C3.86351 9.4381 4.67778 9.29005 5.26997 9.69718L9.63741 12.6211L15.7814 4.92261C16.2256 4.36743 17.0398 4.29341 17.595 4.73755C18.1872 5.1817 18.2612 5.99597 17.8171 6.55115Z"
                fill="white"
              />
              <path
                d="M18.6314 20.0976H2.67913C1.53175 20.0976 0.606445 19.1723 0.606445 18.0249V2.07268C0.606445 0.925304 1.53175 0 2.67913 0H18.6684C19.8158 0 20.7411 0.925304 20.7411 2.07268V18.0619C20.7041 19.1723 19.7787 20.0976 18.6314 20.0976ZM2.67913 0.740243C1.93888 0.740243 1.34669 1.33244 1.34669 2.07268V18.0619C1.34669 18.8022 1.93888 19.3944 2.67913 19.3944H18.6684C19.4086 19.3944 20.0008 18.8022 20.0008 18.0619V2.07268C20.0008 1.33244 19.4086 0.740243 18.6684 0.740243H2.67913ZM9.85949 16.0263C9.52638 16.0263 9.19327 15.9152 8.93418 15.7302L3.56742 12.14C2.79016 11.6218 2.6051 10.5855 3.12327 9.84524C3.64144 9.06798 4.67778 8.88292 5.41803 9.40109L9.48936 12.14L15.4853 4.73756C15.7814 4.40445 16.1516 4.18237 16.5957 4.14536C17.0398 4.10835 17.484 4.21939 17.8171 4.51548C18.5203 5.10768 18.6314 6.14402 18.0762 6.84725L11.1549 15.3971C10.8218 15.8042 10.3406 16.0263 9.85949 16.0263ZM4.49272 9.84524C4.19662 9.84524 3.90053 9.99328 3.71547 10.2524C3.41937 10.6595 3.53041 11.2517 3.97455 11.5478L9.3043 15.138C9.67443 15.3971 10.2666 15.323 10.5257 14.9529L17.484 6.36609C17.632 6.18103 17.7061 5.92195 17.6691 5.69987C17.632 5.44079 17.521 5.21871 17.3359 5.07067C17.1509 4.92262 16.8918 4.84859 16.6697 4.88561C16.4106 4.92262 16.1886 5.03365 16.0405 5.21872L9.85949 12.8432C9.74845 12.9913 9.52638 13.0283 9.37833 12.9172L5.01089 9.99328C4.86284 9.88225 4.67778 9.84524 4.49272 9.84524Z"
                fill="white"
              />
            </svg>
          </ProgressListItem>
        </ProgressList>
        <img className={styles.iconNearProgressWrapper} src={Icons.Show} alt={'show'} onClick={openSynchModal} />
      </div>
      <span className={styles.number}>{trackingNumber}</span>
    </div>
  );
};
