import React, { useEffect } from 'react';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { FormData } from '@/ModalWindows/OrderAutomatizationModal/hooks/useOrderAutomatizationModalForm';
import { Tooltip } from '@/Components/Tooltip/Tooltip';
import { PackageSelect } from '../_Shared/PackageSelect/PackageSelect';
import { useOwnContractLogisticForm } from './hooks/useOwnContractLogisticForm';
import { Button } from '@/Components/Controls/Button/Button';
import { globalHistory } from '@/GlobalHistory';
import { PagesRouting } from '@/Routing';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { useOwnContractLogisticOptions } from './hooks/useOwnContractLogisticOptions';
import { useOwnContractLogisticTooltips } from './hooks/useOwnContractLogisticTooltips';
import { useLocalization } from '@/Hooks';
import { OrderAutomatizaionRow } from '@/Types';
import styles from './OwnContractLogistic.scss';

type Props = {
  rowIndex: number;
  rowData: OrderAutomatizaionRow;
  disabled?: boolean;
};

export const OwnContractLogistic = ({ rowIndex, rowData, disabled = false }: Props) => {
  const {
    modalWindows: { orderLinkWithProduct },
  } = useLocalization();

  const {
    methods: { register, errors, watch, setValue },
    rules: { required },
    selectedPackages: { array, append, remove },
  } = useOwnContractLogisticForm(rowIndex);

  const { packageOptions, productOptions } = useOwnContractLogisticOptions(rowData.orderId, array);
  const { productTooltipMessage } = useOwnContractLogisticTooltips(array.filter((i) => i.key.length).length);

  const isProductSelectDisabled = productOptions.length === 0;

  const selectedPackage = watch((`prepareLabels.${rowIndex}.packages.${0}.key` as unknown) as keyof FormData);
  const selectedUserProduct = watch((`prepareLabels.${rowIndex}.userProductId` as unknown) as keyof FormData);

  useEffect(() => {
    setValue(
      `prepareLabels.${rowIndex}.logisticTypeId`,
      productOptions.find((x) => x.key == Number(selectedUserProduct))?.logisticTypeId ?? 0,
    );
  }, [selectedUserProduct]);

  useEffect(() => {
    setValue(`prepareLabels.${rowIndex}.userProductId`, productOptions[0]?.key);
  }, [selectedPackage]);

  if (!packageOptions) return null;

  return (
    <div className={styles.logisticRow}>
      {packageOptions.length > 0 ? (
        <>
          {array.map((p, idx) => (
            <PackageSelect
              key={p.array_id}
              index={idx}
              rowIndex={rowIndex}
              isAddActive={array.length < 5}
              onAdd={() => append({ key: '', weight: '' })}
              onDelete={() => remove(idx)}
              packageOptions={packageOptions}
              disabled={disabled}
            />
          ))}

          <Tooltip text={productTooltipMessage} shouldShowOnHover={isProductSelectDisabled}>
            <FormSelectInput<FormData>
              name={(`prepareLabels.${rowIndex}.userProductId` as unknown) as keyof FormData}
              register={register}
              className={styles.select}
              needClearButton={false}
              options={[{ key: '', value: orderLinkWithProduct.Placeholders.SelectLogisticProduct }, ...productOptions]}
              rules={required}
              disabled={isProductSelectDisabled || disabled}
              error={errors}
            />
          </Tooltip>
        </>
      ) : (
        <div className={styles.noLogisticsMessage}>
          <span>{orderLinkWithProduct.OwnShippingContract.YouHaveNotYetActivatedAnyLogisticsProducts}</span>
          <Button
            text={orderLinkWithProduct.OwnShippingContract.Activate}
            theme={BUTTON_THEMES.PRIMARY}
            onClick={() => globalHistory.push(PagesRouting.SettingsPages.LogisticSettings)}
          />
        </div>
      )}
    </div>
  );
};
