const needLogging = true;

export class LogUtil {
  public static Info(key: string, msg?: unknown) {
    if (needLogging) console.info(key + ': ', msg);
  }

  public static Log(key: string, msg?: unknown) {
    if (needLogging) console.log(key + ': ', msg);
  }

  public static ColorLog(key: string, msg?: unknown, color = 'blue') {
    if (needLogging) console.log(`%c ${key} : ${msg ?? ''}`, `background: white; color: ${color}`);
  }

  public static LogError(key: string, msg?: unknown) {
    if (needLogging) console.error(key + ': ', msg);
  }
}
