import { ProductModel } from '@/Models';
import { ApiRoutingUtils } from '@/Routing';
import { CreateProductRequest } from '@/Api/Products/Product/Requests/CreateProductRequest';
import { CreateProductResponse } from '@/Api/Products/Product/Responses/CreateProductResponse';
import { UpdateProductRequest } from '@/Api/Products/Product/Requests/UpdateProductRequest';
import { UpdateProductResponse } from '@/Api/Products/Product/Responses/UpdateProductResponse';
import { GetProductsParams } from '@/Api/Products/Product/Requests/GetProductsParams';
import { rest } from '@/Api/Rest';
import { CreateDynamicColumnRequest } from '@/Api/Products/Product/Requests/CreateDynamicColumnRequest';
import { CreateDynamicColumnResponse } from '@/Api/Products/Product/Responses/CreateDynamicColumnResponse';
import { UpdateDynamicColumnRequest } from '@/Api/Products/Product/Requests/UpdateDynamicColumnRequest';
import { UpdateDynamicColumnResponse } from '@/Api/Products/Product/Responses/UpdateDynamicColumnResponse';
import { GetDynamicColumnsResponse } from '@/Api/Products/Product/Responses/GetDynamicColumnsResponse';
import { AddColumnsToSelectedProductsRequest } from '@/Api/Products/Product/Requests/AddColumnsToSelectedProductsRequest';
import { DeleteDynamicColumnResponse } from '@/Api/Products/Product/Responses/DeleteDynamicColumnResponse';
import { GetProductOriginalImageResponse } from '@/Api/Products/Product/Responses/GetProductOriginalImageResponse';

const url = ApiRoutingUtils.createUrl('products');
const adminUrl = ApiRoutingUtils.createUrl('admin-products');

async function getProducts(request: GetProductsParams, isAdmin: boolean): Promise<ProductModel[]> {
  return rest.get(isAdmin ? adminUrl : url, request);
}

async function createProduct(request: CreateProductRequest, isAdmin: boolean): Promise<CreateProductResponse> {
  return rest.post(isAdmin ? adminUrl : url, request);
}

async function updateProduct(request: UpdateProductRequest, isAdmin: boolean): Promise<UpdateProductResponse> {
  return rest.put(isAdmin ? adminUrl : url, request);
}

async function deleteProduct(request: number, isAdmin: boolean): Promise<number> {
  return rest.delete(`${isAdmin ? adminUrl : url}/product/${request}`, {});
}

async function deleteProductPackage(request: number, isAdmin: boolean): Promise<number> {
  return rest.delete(`${isAdmin ? adminUrl : url}/product-package/${request}`, {});
}

async function getDynamicColumns(): Promise<GetDynamicColumnsResponse> {
  return rest.get(`${url}/dynamic-column`);
}

async function createDynamicColumn(request: CreateDynamicColumnRequest): Promise<CreateDynamicColumnResponse> {
  return rest.post(`${url}/dynamic-column`, request);
}

async function updateDynamicColumn(request: UpdateDynamicColumnRequest): Promise<UpdateDynamicColumnResponse> {
  return rest.put(`${url}/dynamic-column`, request);
}

async function deleteDynamicColumn(request: number): Promise<DeleteDynamicColumnResponse> {
  return rest.delete(`${url}/dynamic-column/${request}`, {});
}

async function addColumnsToSelectedProducts(request: AddColumnsToSelectedProductsRequest): Promise<null> {
  return rest.post(`${url}/dynamic-column/add-to-selected-products`, request);
}

async function getProductOriginalImage(request: number): Promise<GetProductOriginalImageResponse> {
  return rest.get(`${url}/full-image/${request}`);
}

export const ProductsApi = {
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  deleteProductPackage,
  getDynamicColumns,
  createDynamicColumn,
  updateDynamicColumn,
  deleteDynamicColumn,
  addColumnsToSelectedProducts,
  getProductOriginalImage,
};
