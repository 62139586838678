import { CurrenciesApi } from '@/Api/Currencies/CurrenciesApi';
import { CurrencyModel } from '@/Models/CurrencyModel';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getCurrencies = createAsyncThunk<CurrencyModel[], void>('selectCurrencyModal/get-currencies', async () => {
  return CurrenciesApi.getCurrencies();
});

export const asyncActions = {
  getCurrencies,
};
