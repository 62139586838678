import { Redirect, Route, RouteProps } from 'react-router';
import React, { FC, useCallback } from 'react';
import { UserSegmentType } from '@/Enums';
import { userSelectors } from '@/Redux/User';
import { useSelector } from 'react-redux';

type Props = {
  redirectPath: string;
  allowedSegments: UserSegmentType[];
} & RouteProps;

export const ProtectedRoute: FC<Props> = ({ children, redirectPath, allowedSegments, ...props }: Props) => {
  const userSegment = useSelector(userSelectors.userSegment);

  const render = useCallback(() => {
    if (!userSegment || !allowedSegments.some((allowedSegment) => allowedSegment === userSegment)) {
      return <Redirect to={redirectPath} />;
    }

    return children;
  }, [userSegment, children, allowedSegments, userSegment, redirectPath]);
  return <Route {...props} render={render} />;
};
