import { DeepPartial } from 'devextreme/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

// Sort type
export enum SortType {
  Asc = 'asc',
  Desc = 'desc',
}

/** Data table source */
export class DataTableSource<TData> {
  private readonly _dataSource: DataSource<TData, string | number>;

  constructor(key: keyof TData, data: TData[]) {
    this._dataSource = new DataSource({
      store: new ArrayStore<TData>({
        data: data,
        key: key as string,
      }),
      reshapeOnPush: true,
      pushAggregationTimeout: 0,
    });

    this._dataSource.load();
  }

  get dataSource(): DataSource<TData, string | number> {
    return this._dataSource;
  } // dataSource

  get key(): keyof TData {
    return this._dataSource.key() as keyof TData;
  } // key

  get data(): TData[] {
    return this._dataSource.items();
  } // data

  public remove(keys: (string | number)[]): void {
    this._dataSource.store().push(
      keys.map((key) => ({
        type: 'remove',
        key: key,
      })),
    );
  } // remove

  public update(changes: { key: string | number; data: DeepPartial<TData> }[]): void {
    this._dataSource.store().push(
      changes.map((change) => ({
        type: 'update',
        ...change,
      })),
    );
  } // update
}
