import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BackgroundTaskForProgressList } from '@/Types/BackgroundTaskForProgressList';
import { BackgroundTaskProgress } from '@/Models/BackgroundTaskProgress';

export type BackgroundTasksState = {
  tasks: BackgroundTaskForProgressList[];
};

const initialState: BackgroundTasksState = {
  tasks: [],
};

const backgroundTasksSlice = createSlice({
  name: 'backgroundTasks',
  initialState,
  reducers: {
    addTask: (state, action: PayloadAction<BackgroundTaskForProgressList>) => {
      if (state.tasks.some((t) => t.id === action.payload.id)) {
        return;
      }
      state.tasks = [action.payload, ...state.tasks];
    },
    addTasks: (state, action: PayloadAction<BackgroundTaskForProgressList[]>) => {
      const tasks = action.payload.filter((t) => !state.tasks.some((exists) => exists.id === t.id));

      state.tasks = [...tasks, ...state.tasks];
    },
    finishTask: (state, action: PayloadAction<number>) => {
      state.tasks = state.tasks.filter((t) => t.id !== action.payload);
    },
    updateTask: (state, action: PayloadAction<BackgroundTaskProgress>) => {
      state.tasks = state.tasks.map((task) => {
        return task.id === action.payload.id
          ? {
              ...task,
              ...action.payload,
            }
          : task;
      });
    },
  },
});

export const backgroundTasksReducer = backgroundTasksSlice.reducer;
export const backgroundTasksActions = backgroundTasksSlice.actions;
