import React from 'react';
import cn from 'classnames';
import { useFormError } from '@/Hooks';
import { ClassNameProps, InputRefProps, Pair } from '@/Types';
import { ErrorTooltip, errorTooltipClass } from '@/Components/Controls/ErrorTooltip/ErrorTooltip';
import styles from './SignInput.scss';

type Props<TFormData extends object> = {
  placeholder?: string;
  options: Pair<any>[];
  hidden?: boolean;
} & ClassNameProps &
  InputRefProps<HTMLSelectElement, TFormData, string | number>;

export function SignSelectInput<TFormData extends object>({
  name,
  register,
  rules,
  valueForSet = '',
  options,
  error,
  hidden = false,
  placeholder,
  className,
}: Props<TFormData>) {
  const errorMessage = useFormError(name, error);

  return (
    <div className={cn(className, styles.signInputGroup)} hidden={hidden}>
      <ErrorTooltip error={errorMessage}>
        <select
          name={name as string}
          {...register?.(name as any, rules)}
          defaultValue={valueForSet}
          placeholder={' '}
          className={cn(styles.signInput, {
            [errorTooltipClass]: errorMessage,
          })}
        >
          {options.map((option) => (
            <option key={option.key} value={option.key}>
              {option.value}
            </option>
          ))}
        </select>

        <label className={styles.signLabel}>{placeholder}</label>
      </ErrorTooltip>
    </div>
  );
}
