import { createSlice } from '@reduxjs/toolkit';
import { LogisticContractStatus } from '@/Enums';
import { Nullable, Pair } from '@/Types';
import { DeutschePostInternetmarkeContractModel } from '@/Models';
import { asyncActions } from './asyncThunks';

export type DeutschePostInternetmarkeContractState = {
  contract: Nullable<DeutschePostInternetmarkeContractModel>;
  formats: Pair<string | number>[];
  connectionStatus: Nullable<LogisticContractStatus>;
};

const initialState: DeutschePostInternetmarkeContractState = {
  contract: null,
  formats: [],
  connectionStatus: null,
};

const deutschePostInternetmarkeContractSlice = createSlice({
  name: 'DeutschePostInternetmarkeContract',
  initialState,
  reducers: {
    resetConnectionStatus(state) {
      state.connectionStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getContract.fulfilled, (state, action) => {
      state.contract = action.payload.contract;
    });
    builder.addCase(asyncActions.getMarkeFormats.fulfilled, (state, action) => {
      state.formats = action.payload.formats.map((x) => {
        return { key: x.id, value: x.name };
      });
    });
    builder.addCase(asyncActions.checkConnection.fulfilled, (state, action) => {
      state.connectionStatus = action.payload.status;
    });
  },
});

const { actions, reducer } = deutschePostInternetmarkeContractSlice;

export const deutschePostInternetmarkeContractActions = actions;
export const deutschePostInternetmarkeContractReducer = reducer;
export const deutschePostInternetmarkeContractAsyncActions = asyncActions;
