import { CountryCode } from '@/Enums';
import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';

const warehouseLogisticsPageState = (state: RootState) => state.warehouseLogisticsPage.warehouseLogisticsPage;

const productModal = createSelector(warehouseLogisticsPageState, (state) => state.productModal);

const countryZones = (logisticTypeId: string | number, country?: CountryCode) =>
  createSelector(warehouseLogisticsPageState, (state) => {
    let zones = state.countryZones;
    if (country) {
      zones = zones.filter((x) => x.countryCode == country);
    }

    return zones
      .filter((x) => x.logisticTypeId == logisticTypeId)
      .map((x) => {
        return {
          key: x.id,
          value: x.name,
          levels: x.zoneLevels.map((level) => {
            return {
              key: level.id,
              value: level.name,
            };
          }),
        };
      });
  });

export const warehouseLogisticsPageSelectors = {
  productModal,
  countryZones,
};
