import React, { PropsWithChildren } from 'react';

import styles from './ButtonsSection.scss';
import { ClassNameProps } from '@/Types';

type Props = ClassNameProps;

export function ButtonsWrapper({ children }: PropsWithChildren<Props>) {
  return <div className={styles.wrapper}>{children}</div>;
}
