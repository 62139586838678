import { GenSubProductTypeModel } from '@/Models';
import { createSlice } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';

export type GenSubProductTypesDataState = {
  subProductTypes: GenSubProductTypeModel[];
};

const initialState: GenSubProductTypesDataState = {
  subProductTypes: [],
};

const genSubProductTypesDataSlice = createSlice({
  name: 'genSubProductTypesData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getSubProductTypes.fulfilled, (state, action) => {
        state.subProductTypes = action.payload;
      })
      .addCase(asyncActions.createSubProductType.fulfilled, (state, action) => {
        state.subProductTypes.push(action.payload.subProductType);
      })
      .addCase(asyncActions.updateSubProductType.fulfilled, (state, action) => {
        state.subProductTypes = state.subProductTypes.map((item) =>
          item.id === action.payload.subProductType.id ? action.payload.subProductType : item,
        );
      })
      .addCase(asyncActions.deleteSubProductType.fulfilled, (state, action) => {
        state.subProductTypes = state.subProductTypes.filter((item) => item.id !== action.payload);
      });
  },
});

export const genSubProductTypesDataReducer = genSubProductTypesDataSlice.reducer;
export const genSubProductTypesDataActions = genSubProductTypesDataSlice.actions;
export const genSubProductTypesDataAsyncActions = asyncActions;
