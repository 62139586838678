import { createSlice } from '@reduxjs/toolkit';
import { externalTransferAsyncActions } from './asyncActions';
import { Nullable } from 'src/Types';
import { UserWarehouseUnitWithPostingsModel } from 'src/Models/Warehouse/UserWarehouseUnitWithPostingsModel';
import { UserWarehousePostingBatchModel } from 'src/Models/Warehouse/UserWarehousePostingBatchModel';
import { UserWarehouseShortModel } from 'src/Models/Warehouse/UserWarehouseModel';
import { UserWarehouseWithPostingsModel } from 'src/Models/Warehouse/UserWarehouseWithPostingsModel';

export type ExternalTransferState = {
  unitInfo: Nullable<UserWarehouseUnitWithPostingsModel>;
  batchInfo: Nullable<UserWarehousePostingBatchModel>;
  availableOutWarehouses: UserWarehouseShortModel[];
  warehouseInfo: Nullable<UserWarehouseWithPostingsModel>;
};

const initialState: ExternalTransferState = {
  unitInfo: null,
  batchInfo: null,
  warehouseInfo: null,
  availableOutWarehouses: [],
};

export const { actions: externalTransferActions, reducer: externalTransferReducer } = createSlice({
  name: 'external-transfer',
  initialState,
  reducers: {
    clean: (state) => {
      state.batchInfo = null;
      state.unitInfo = null;
      state.warehouseInfo = null;
      state.availableOutWarehouses = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(externalTransferAsyncActions.createBatch.fulfilled, (state, action) => {
        state.batchInfo = {
          state: action.payload.state,
          id: action.payload.batchId,
          postings: [],
        };
      })
      .addCase(externalTransferAsyncActions.scanBarcode.fulfilled, (state, action) => {
        state.unitInfo = action.payload.transferState.unitInfo;
        state.batchInfo = action.payload.transferState.batchInfo;
        state.warehouseInfo = action.payload.transferState.warehouseInfo;
      })
      .addCase(externalTransferAsyncActions.getBatch.fulfilled, (state, action) => {
        state.unitInfo = action.payload.transferState.unitInfo;
        state.batchInfo = action.payload.transferState.batchInfo;
        state.warehouseInfo = action.payload.transferState.warehouseInfo;
      })
      .addCase(externalTransferAsyncActions.getAvailableWarehouses.fulfilled, (state, action) => {
        state.availableOutWarehouses = action.payload.warehouses;
      })
      .addCase(externalTransferAsyncActions.setBatchState.fulfilled, (state, action) => {
        if (state.batchInfo) {
          state.batchInfo.state = action.payload.newState;
        }
      });
  },
});
