import { useCallback } from 'react';
import { OnCellPrepared } from '@/Types';
import styles from '../DataTable.scss';

export const useOnCellPrepared = <TData>(
  rowKey?: '' | keyof TData,
  mergeColumnsMap?: Partial<{ [K in keyof TData]: boolean }>,
) => {
  const onCellPrepared = useCallback(
    (e: OnCellPrepared) => {
      switch (e.rowType) {
        case 'data': {
          e.cellElement?.classList.add(styles.cell_data);
          break;
        }
        case 'filter': {
          e.cellElement?.classList.add(styles.cell_filter);
          break;
        }
        case 'header': {
          e.cellElement?.classList.add(styles.cell_header);
          break;
        }
      }

      if (!rowKey || !mergeColumnsMap) return;

      if ((mergeColumnsMap as { [key: string]: any })[e.column?.name ?? '']) {
        if (e.rowIndex != undefined) {
          const nextRow = e.component?.getVisibleRows()[e.rowIndex + 1];
          if (nextRow && nextRow.data[rowKey] == e.row?.data[rowKey]) {
            if (!e.cellElement?.classList.contains(styles.cell__border)) {
              e.cellElement?.classList.add(styles.cell__border);
            }
          }

          const prevRow = e.component?.getVisibleRows()[e.rowIndex - 1];
          if (prevRow && prevRow.data[rowKey] == e.row?.data[rowKey]) {
            if (!e.cellElement?.classList.contains(styles.cell__color)) {
              e.cellElement?.classList.add(styles.cell__color);
            }
          }
        }
      }
    },
    [rowKey, mergeColumnsMap],
  );

  return {
    onCellPrepared,
  };
};
