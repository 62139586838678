import { UnshippedOrderModel } from '@/Models/Warehouse/UnshippedOrderModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '@/Types';
import { UserWarehouseUnitWithPostingsModel } from '@/Models/Warehouse/UserWarehouseUnitWithPostingsModel';
import { customerTransferAsyncActions } from './asyncActions';
import { WarehouseUnitInfoModel } from 'src/Models/Warehouse/WarehouseUnitInfoModel';
import { UnshippedOrderItemWithReservedQuantityModel } from 'src/Types/UserWarehouse/Transfer/CustomerTransfer/UnshippedOrderItemWithReservedQuantityModel';

export type CustomerTransferState = {
  unitInfo: Nullable<UserWarehouseUnitWithPostingsModel>;
  unshippedOrders: UnshippedOrderModel[];
  unshippedOrdersCount: number;
  productUnits: WarehouseUnitInfoModel[];
  batchId: Nullable<number>;
  selectedOrderItems: UnshippedOrderItemWithReservedQuantityModel[];
};

const initialState: CustomerTransferState = {
  unitInfo: null,
  unshippedOrders: [],
  unshippedOrdersCount: 0,
  productUnits: [],
  batchId: null,
  selectedOrderItems: [],
};

export const { actions: customerTransferActions, reducer: customerTransferReducer } = createSlice({
  name: 'customer-transfer',
  initialState,
  reducers: {
    setSelectedOrderItems: (state, action: PayloadAction<UnshippedOrderItemWithReservedQuantityModel[]>) => {
      state.selectedOrderItems = action.payload;
    },
    clear: (state) => {
      state.selectedOrderItems = [];
      state.batchId = null;
      state.unitInfo = null;
      state.unshippedOrders = [];
      state.productUnits = [];
      state.unshippedOrdersCount = 0;
    },
    setBatchId: (state, action: PayloadAction<number>) => {
      state.batchId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(customerTransferAsyncActions.scanBarcode.fulfilled, (state, action) => {
      state.unitInfo = action.payload.unitInfo;

      if (action.payload.productSku) {
        state.selectedOrderItems = state.selectedOrderItems.map((x) =>
          x.sku == action.payload.productSku
            ? { ...x, reservedQuantity: x.reservedQuantity + action.payload.quantity }
            : x,
        );
      }
    });

    builder.addCase(customerTransferAsyncActions.getUnshippedOrders.fulfilled, (state, action) => {
      state.unshippedOrders = action.payload.orders;
      state.unshippedOrdersCount = action.payload.resultsCount;
    });

    builder.addCase(customerTransferAsyncActions.getProductUnits.fulfilled, (state, action) => {
      state.productUnits = action.payload.units;
    });

    builder.addCase(customerTransferAsyncActions.createBatch.fulfilled, (state, action) => {
      state.batchId = action.payload.batchId;
    });

    builder.addCase(customerTransferAsyncActions.removeProductFromBatch.fulfilled, (state, action) => {
      state.selectedOrderItems = state.selectedOrderItems.map((x) =>
        x.sku == action.payload.sku ? { ...x, reservedQuantity: x.reservedQuantity - action.payload.quantity } : x,
      );
    });

    builder.addCase(customerTransferAsyncActions.getBatch.fulfilled, (state, action) => {
      state.unitInfo = action.payload.transferState.unitInfo;

      const { orderId, postings } = action.payload.transferState.batchInfo;
      const order = state.unshippedOrders.find((item) => item.id == orderId);

      if (order) {
        state.selectedOrderItems = order.orderItems
          .map((x) => ({ ...x, reservedQuantity: 0 }))
          .map((order) => {
            const changedOrder = postings.find((posting) => posting.productSku == order.sku);

            if (changedOrder)
              return {
                ...order,
                reservedQuantity: order.reservedQuantity + changedOrder.quantity,
              };

            return order;
          });
      }
    });
  },
});
