import { DataGrid } from 'devextreme-react/data-grid';
import { useCallback } from 'react';

export function useOnRowsExpand<TData>(
  innerGridRef: React.MutableRefObject<DataGrid<TData, keyof TData> | undefined>,
  expandRows: boolean,
) {
  const gridInstance = innerGridRef.current?.instance;

  return useCallback(() => {
    setTimeout(() => {
      const visibleRows = gridInstance?.getVisibleRows();

      if (gridInstance && visibleRows) {
        const rowKeys = visibleRows.map((item) => item.key);

        if (expandRows) {
          rowKeys.forEach((key) => {
            gridInstance.expandRow(key);
          });
        } else {
          rowKeys.forEach((key) => {
            gridInstance.collapseRow(key);
          });
        }
      }
    }, 100);
  }, [gridInstance, expandRows]);
}
