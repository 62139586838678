import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetCategoriesResponse } from '@/Api/UserProductCategory/Responses/GetCategoriesResponse';
import { CreateCategoryRequest } from '@/Api/UserProductCategory/Requests/CreateCategoryRequest';
import { CreateCategoryResponse } from '@/Api/UserProductCategory/Responses/CreateCategoryResponse';
import { UpdateCategoryRequest } from '@/Api/UserProductCategory/Requests/UpdateCategoryRequest';
import { UpdateCategoryResponse } from '@/Api/UserProductCategory/Responses/UpdateCategoryResponse';
import { DeleteCategoryRequest } from '@/Api/UserProductCategory/Requests/DeleteCategoryRequest';
import { DeleteCategoryResponse } from '@/Api/UserProductCategory/Responses/DeleteCategoryResponse';

const url = ApiRoutingUtils.createUrl('user-product-category');

async function getCategories(): Promise<GetCategoriesResponse> {
  return rest.get(url);
}

async function createCategory(request: CreateCategoryRequest): Promise<CreateCategoryResponse> {
  return rest.post(url, request);
}

async function updateCategory(request: UpdateCategoryRequest): Promise<UpdateCategoryResponse> {
  return rest.put(url, request);
}

async function deleteCategory(request: DeleteCategoryRequest): Promise<DeleteCategoryResponse> {
  return rest.delete(`${url}/${request.id}`, null);
}

export const UserProductCategoryApi = {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
};
