import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateDocumentFolderRequest,
  CreateDocumentFolderResponse,
  UpdateDocumentFolderRequest,
  UpdateDocumentFolderResponse,
  DocumentFoldersApi,
} from '@/Api/_Microservices/OnlineArchive/DocumentFolders';
import { DocumentFolderModel } from '@/Models/_Microservices/OnlineArchive/DocumentFolderModel';

const getDocumentFolders = createAsyncThunk<DocumentFolderModel[], void>(
  'document-folders/get-document-folders',
  async () => {
    return DocumentFoldersApi.getDocumentFolders();
  },
);

const createDocumentFolder = createAsyncThunk<CreateDocumentFolderResponse, CreateDocumentFolderRequest>(
  'document-folders/create-document-folder',
  async (request: CreateDocumentFolderRequest) => {
    return DocumentFoldersApi.createDocumentFolder(request);
  },
);

const updateDocumentFolder = createAsyncThunk<UpdateDocumentFolderResponse, UpdateDocumentFolderRequest>(
  'document-folders/update-document-folder',
  async (request: UpdateDocumentFolderRequest) => {
    return DocumentFoldersApi.updateDocumentFolder(request);
  },
);

const deleteDocumentFolder = createAsyncThunk<number, number>(
  'document-folders/delete-document-folder',
  async (id: number) => {
    return DocumentFoldersApi.deleteDocumentFolder(id);
  },
);

export const asyncActions = {
  getDocumentFolders,
  createDocumentFolder,
  updateDocumentFolder,
  deleteDocumentFolder,
};
