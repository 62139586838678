const getFormattedFileName = (name: string) => {
  return name.replace(/#+/g, '№').trim();
}; // getFormattedFileName

const getFileSizeString = (sizeInKb: number, localizedSizes: any) => {
  if (sizeInKb < 1000) return sizeInKb.toFixed(2) + ' ' + localizedSizes['Kb'];
  if (sizeInKb >= 1000 && sizeInKb < 1000000) return (sizeInKb / 1000).toFixed(2) + ' ' + localizedSizes['Mb'];
  return (sizeInKb / 1000000).toFixed(2) + ' ' + localizedSizes['Gb'];
}; // getFileSizeString

const getFileExtension = (name: string) => {
  return name.split('.').slice(-1)[0];
}; // getFileExtension

const getFileNameWithoutExtension = (name: string) => {
  return name.slice(0, name.lastIndexOf('.'));
}; // getFileNameWithoutExtension

const getFileSizeInKb = (size: number, unit: 'Kb' | 'Mb' | 'Gb'): number => {
  switch (unit) {
    case 'Mb':
      return size * 1000;

    case 'Gb':
      return size * 1000000;

    case 'Kb':
    default:
      return size;
  }
}; // getFileSizeInKb

const saveBinaryFile = (data: Buffer, contentType: string, fileName: string) => {
  const fileLink = document.createElement('a');
  fileLink.download = fileName.replace(/"/g, '');
  const blob = new Blob([data], { type: contentType });
  fileLink.href = window.URL.createObjectURL(blob);
  fileLink.click();
}; // saveBlobFile

export const fileUtils = {
  getFileSizeString,
  getFormattedFileName,
  getFileExtension,
  getFileNameWithoutExtension,
  getFileSizeInKb,
  saveBinaryFile,
};
