import { TreeItemType } from '@/Components/TreeView/TreeView';
import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';
import { GenLogisticTypesDataState } from './reducer';

const genLogisticTypesDataSelector = (root: RootState) => root.dynamicData.genLogisticTypesData;

const logisticTypes = createSelector(
  genLogisticTypesDataSelector,
  (state: GenLogisticTypesDataState) => state.logisticTypes,
);

const logisticTypesOptions = createSelector(genLogisticTypesDataSelector, (state: GenLogisticTypesDataState) =>
  state.logisticTypes.map((x) => {
    return { key: x.id, value: x.locName ?? x.name };
  }),
);

const logisticTypesTreeViewOptionsMemo = createSelector(
  [logisticTypesOptions],
  (logisticTypesOptions): TreeItemType[] => {
    return logisticTypesOptions.map(
      (item) =>
        ({
          key: item.key,
          value: item.value,
          expanded: true,
        } as TreeItemType),
    );
  },
);

export const genLogisticTypesDataSelectors = {
  logisticTypes,
  logisticTypesOptions,
  logisticTypesTreeViewOptionsMemo,
};
