import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from '@/Hooks/useFormWrapper';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { globalHistory } from '@/GlobalHistory';
import { PagesRouting } from '@/Routing';
import { EmailType } from '@/Enums';
import { withAuthorizationModules } from '@/Hocs';
import { useLocalization } from '@/Hooks';
import { useRequired, useShouldBeEmail } from '@/Hooks/Validation';
import { userActionsAsync } from '@/Redux/User';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { SignInput } from '@/Components/Controls/SignInputs/SignInput';
import styles from './ResetPassword.scss';

type FormData = {
  userEmail: string;
};

function ResetPasswordPageComponent() {
  const dispatch: AppDispatch = useDispatch();

  const {
    authorizationPage: { Buttons, ResetPassword },
  } = useLocalization();

  const [isEmailSent, setIsEmailSent] = useState<boolean>();

  const {
    register,
    getValues,
    formState: { isDirty, isValid, errors },
    trigger,
  } = useForm<FormData>({
    defaultValues: { userEmail: '' },
    mode: 'onChange',
  });
  const required = useRequired();
  const valueMustBeEmail = useShouldBeEmail();

  const onSubmit = () => {
    trigger();

    if (isValid) {
      dispatch(userActionsAsync.getEmailToken({ ...getValues(), emailType: EmailType.ResetPassword })).then(() => {
        setIsEmailSent(true);
      });
    } // if
  };

  return (
    <div className={styles.authorizationInfo}>
      {!isEmailSent ? (
        <>
          <div className={styles.authorizationInfoText}>
            <h4>{ResetPassword.PleaseEnterYourEmailAddressToResetYourPassword}</h4>
            <p>{ResetPassword.WeWillSendALinkToResetYourPasswordToYourEmail}</p>
          </div>

          <SignInput<FormData>
            name={'userEmail'}
            register={register}
            rules={{ ...required, ...valueMustBeEmail }}
            placeholder={ResetPassword.EmailAddress}
            error={errors}
          />

          <Button
            text={Buttons.SendEmail}
            theme={BUTTON_THEMES.SUCCESS}
            disabled={!isDirty || !isValid}
            onClick={() => onSubmit()}
          />
        </>
      ) : (
        <>
          <div className={styles.authorizationInfoText}>
            <h4>{ResetPassword.EmaiSentSuccessfully}</h4>
            <p>{ResetPassword.CheckYourEmailForALinkToResetYourPassword}</p>
          </div>

          <Button
            text={Buttons.GoToLogin}
            theme={BUTTON_THEMES.SUCCESS}
            disabled={!isDirty || !isValid}
            onClick={() => globalHistory.push(PagesRouting.AuthorizationPages.LoginPage)}
          />
        </>
      )}
    </div>
  );
}

export const ResetPasswordPage = withAuthorizationModules(ResetPasswordPageComponent);
