import { useMemo } from 'react';
import { NumberGenerationType } from '@/Enums';
import { DocumentTemplateRow } from '@/Types';
import { useSelector } from 'react-redux';
import { emailSendModalSelectors } from '@/ModalWindows/EmailSendModal/services/selectors';
import { documentPrintModalSelectors } from '@/ModalWindows/DocumentPrintModal/services/selectors';
import { EmailTemplateRow } from '@/Types/EmailTemplateRow';

export function useGenerateDocumentSelectTemplate(numberGenerationType?: NumberGenerationType) {
  const documentTemplates = useSelector(documentPrintModalSelectors.rows);
  const emailTemplates = useSelector(emailSendModalSelectors.rows);

  return useMemo(() => {
    let resultDocumentTemplate: DocumentTemplateRow | undefined;
    let resultEmailTemplate: EmailTemplateRow | undefined;

    switch (numberGenerationType) {
      case NumberGenerationType.Invoice: {
        resultDocumentTemplate = documentTemplates.find((item) => item.isDefaultForInvoice);
        resultEmailTemplate = emailTemplates.find((item) => item.isDefaultForInvoice);
        break;
      }
      case NumberGenerationType.CancelInvoice: {
        resultDocumentTemplate = documentTemplates.find((item) => item.isDefaultForCancelInvoice);
        resultEmailTemplate = emailTemplates.find((item) => item.isDefaultForCancelInvoice);
        break;
      }
      case NumberGenerationType.PackList: {
        resultDocumentTemplate = documentTemplates.find((item) => item.isDefaultForDeliveryNote);
        resultEmailTemplate = emailTemplates.find((item) => item.isDefaultForDeliveryNote);
        break;
      }
      case NumberGenerationType.SaleOrder: {
        resultDocumentTemplate = documentTemplates.find((item) => item.isDefaultForSale);
        resultEmailTemplate = emailTemplates.find((item) => item.isDefaultForSale);
        break;
      }
      case NumberGenerationType.PurchaseOrder: {
        resultDocumentTemplate = documentTemplates.find((item) => item.isDefaultForPurchase);
        resultEmailTemplate = emailTemplates.find((item) => item.isDefaultForPurchase);
        break;
      }
    }

    return {
      selectedPrintTemplate: resultDocumentTemplate ?? null,
      selectedEmailTemplate: resultEmailTemplate ?? null,
    };
  }, [numberGenerationType, documentTemplates, emailTemplates]);
}
