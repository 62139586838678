import { UserWarehouseTypeModel } from 'src/Models/Warehouse/UserWarehouseTypeModel';
import { createSlice } from '@reduxjs/toolkit';
import { userWarehouseTypeAsyncActions } from 'src/Pages/Settings/modules/WarehouseSettings/services/userWarehouseTypes/asyncActions';

export type UserWarehouseTypeSettingsState = {
  warehouseTypes: UserWarehouseTypeModel[];
};

const initialState: UserWarehouseTypeSettingsState = {
  warehouseTypes: [],
};

export const { actions: userWarehouseTypeSettingsActions, reducer: userWarehouseTypeSettingsReducer } = createSlice({
  name: 'userWarehouseTypeSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Types
    builder.addCase(userWarehouseTypeAsyncActions.getUserWarehouseTypes.fulfilled, (state, action) => {
      state.warehouseTypes = action.payload.userWarehouses;
    });
    builder.addCase(userWarehouseTypeAsyncActions.createUserWarehouseType.fulfilled, (state, action) => {
      state.warehouseTypes = [...state.warehouseTypes, action.payload.userWarehouseType];
    });
    builder.addCase(userWarehouseTypeAsyncActions.updateUserWarehouseType.fulfilled, (state, action) => {
      state.warehouseTypes = state.warehouseTypes.map((x) =>
        x.id === action.payload.userWarehouseType.id ? action.payload.userWarehouseType : x,
      );
    });
    builder.addCase(userWarehouseTypeAsyncActions.deleteUserWarehouseType.fulfilled, (state, action) => {
      state.warehouseTypes = state.warehouseTypes.filter((x) => x.id !== action.payload.id);
    });
  },
});
