import React from 'react';
import { useLocalization } from '@/Hooks';
import { MergeDiff } from '@/ModalWindows/MergeOrdersModal/modules/MergeDiff/MergeDiff';
import { Modal } from '@/Components/Modal/Modal';
import { ModalDialog } from '@/Components/ModalDialog/ModalDialog';
import { useMergeOrdersModal } from '@/ModalWindows/MergeOrdersModal/hooks/useMergeOrdersModal';
import { ModalProps } from '@/Types';

type Props = ModalProps;

export function MergeOrdersModal({ closeModalWindow }: Props) {
  const {
    saveMergeResult,
    isDiffMergeDialogShown,
    isConfirmMergeDialogShown,
    diff,
    mergeOrders,
  } = useMergeOrdersModal();

  const {
    buttons: { Yes, No },
    modalWindows: { mergeOrders: mergeOrdersLocalization },
  } = useLocalization();

  return (
    <Modal onClose={closeModalWindow}>
      {isDiffMergeDialogShown && <MergeDiff diff={diff} onCancel={closeModalWindow} onSave={saveMergeResult} />}
      {isConfirmMergeDialogShown && (
        <ModalDialog
          title={''}
          text={mergeOrdersLocalization.MergeConfirmText}
          okButtonText={Yes}
          cancelButtonText={No}
          onClose={closeModalWindow}
          onOk={mergeOrders}
        />
      )}
    </Modal>
  );
}
