import React, { CSSProperties, PropsWithChildren } from 'react';
import { ClassNameProps } from '@/Types';
import cn from 'classnames';
import styles from './ProgressListItem.scss';

type Props = {
  isDone: boolean;
  completeColor?: string;
  inProgressColor?: string;
} & ClassNameProps;

export const ProgressListItem: React.FC<Props> = ({
  children,
  isDone,
  className = '',
  completeColor = '#83BA63',
  inProgressColor = '#D7E1E8',
}: PropsWithChildren<Props>) => {
  const style: CSSProperties = {
    backgroundColor: isDone ? completeColor : inProgressColor,
  };
  return (
    <div
      style={style}
      className={cn({ [styles.progressItem]: true, [styles.progressItem__done]: isDone, [className]: true })}
    >
      {children}
    </div>
  );
};
