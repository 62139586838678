import { ClassNameProps } from '@/Types';
import { Icons } from '@/Static/IconsRes';
import styles from './NotifyCircle.scss';
import React from 'react';
import cn from 'classnames';

type Props = {
  displayNumber: number | undefined;
  offsets?: {
    right: number;
    top: number;
  };
  showEmpty?: boolean;
} & ClassNameProps;

export function NotifyCircle({ displayNumber = 0, className, offsets, showEmpty = false }: Props) {
  const hide = displayNumber === 0;

  if (hide && !showEmpty) return null;

  return (
    <div className={cn(styles.notifyCircle, className)} style={offsets && { right: offsets?.right, top: offsets?.top }}>
      <img className={styles.image} src={Icons.NotifyCircle} alt={`notifications:${displayNumber}`} />
      <span className={cn({ [styles.displayNumber]: true, [styles.displayNumber__small]: displayNumber > 99 })}>
        {displayNumber || ''}
      </span>
    </div>
  );
}
