import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetBillingProductCartsResponse } from './Responses/GetBillingProductCartsResponse';

const url = ApiRoutingUtils.createUrl('billing-product-carts');

async function getBillingProductCarts(): Promise<GetBillingProductCartsResponse> {
  return rest.get(url);
}

export const BillingProductCartsApi = {
  getBillingProductCarts,
};
