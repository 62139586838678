import React, { useState } from 'react';
import cn from 'classnames';
import { useFormError } from '@/Hooks';
import { ClassNameProps, InputRefProps } from '@/Types';
import { ErrorTooltip, errorTooltipClass } from '@/Components/Controls/ErrorTooltip/ErrorTooltip';
import styles from './SignInput.scss';
import { Icons } from '@/Static/IconsRes';

type Props<TFormData extends object> = {
  type?: 'text' | 'password' | 'number';
  placeholder?: string;
  needShowCheckbox?: boolean;
  autocomplete?: string;
} & ClassNameProps &
  InputRefProps<HTMLInputElement, TFormData, string | number>;

export function SignInput<TFormData extends object>({
  name,
  register,
  rules,
  valueForSet = '',
  error,
  type = 'text',
  placeholder,
  autocomplete = 'off',
  needShowCheckbox = false,
  className,
}: Props<TFormData>) {
  const errorMessage = useFormError(name, error);

  const [inputType, setInputType] = useState(type);

  return (
    <div className={cn(className, styles.signInputGroup)}>
      <ErrorTooltip error={errorMessage}>
        <input
          name={name as string}
          {...register?.(name as any, rules)}
          defaultValue={valueForSet}
          placeholder={' '}
          autoComplete={autocomplete}
          type={inputType}
          className={cn(styles.signInput, {
            [errorTooltipClass]: errorMessage,
            [styles.signInputWithShowPassword]: needShowCheckbox,
          })}
        />

        <label className={styles.signLabel}>{placeholder}</label>

        {needShowCheckbox && (
          <label
            className={styles.signShowPassword}
            onClick={() => setInputType(inputType == 'password' ? 'text' : 'password')}
          >
            {inputType == 'text' ? <img src={Icons.Show} alt={'show'} /> : <img src={Icons.Hide} alt={'hide'} />}
          </label>
        )}
      </ErrorTooltip>
    </div>
  );
}
