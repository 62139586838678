import React from 'react';
import { ClassNameProps } from '@/Types';
import styles from './UserMenu.scss';
import { PagesRouting } from '@/Routing';
import { Link } from '@/Components/Controls/Link/Link';
import { TopMenuIcons } from '@/Static/IconsRes';
import { DropMenu } from '@/Components/Header/modules/DropMenu/DropMenu';
import { DropDownContent } from '@/Components/DropDownContent/DropDownContent';
import { BackgroundTasks } from '@/Components/BackgroundTasks/BackgroundTasks';
import { ThemeSwitcher } from '../ThemeSwitcher/ThemeSwitcher';
import cn from 'classnames';
import { tourActions } from '@/Redux/Tours/reducer';
import { useDispatch } from 'react-redux';

type Props = ClassNameProps;

export const UserMenu: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch();

  return (
    <div className={cn(styles.userMenu, className)}>
      <DropDownContent className={styles.dropDown} title={'Tasks'} dropDownBodyClassName={styles.backgroundTasks}>
        <BackgroundTasks />
      </DropDownContent>
      <ThemeSwitcher />
      <img className={styles.userMenu__item} src={TopMenuIcons.Notification} alt="notifications" />
      <img
        className={styles.userMenu__item}
        src={TopMenuIcons.Learn}
        alt="notifications"
        onClick={() => dispatch(tourActions.setRun(true))}
      />
      <Link to={PagesRouting.SettingsPages.AccountSettings}>
        <img className={styles.userMenu__item} src={TopMenuIcons.SettingsIcon} alt="notifications" />
      </Link>
      <DropMenu>
        <img className={styles.userMenu__item} src={TopMenuIcons.User} alt="notifications" />
      </DropMenu>
    </div>
  );
};
