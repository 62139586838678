import { AdminUsersApi } from '@/Api/AdminUserApi/AdminUsersApi';
import { UpdateAppUserInfoRequest } from '@/Api/AdminUserApi/UpdateAppUserInfoRequest';
import { AppUserInfoModel } from '@/Models/AppUserInfoModel';
import { AppUserShortModel } from '@/Models/AppUserShortModel';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getUsers = createAsyncThunk<AppUserShortModel[], void>('admin-users/get-users', async () => {
  return AdminUsersApi.getUsers();
});

const getUserInfo = createAsyncThunk<AppUserInfoModel, number>('admin-users/get-user-info', async (id: number) => {
  return AdminUsersApi.getUserInfo(id);
});

const updateUserInfo = createAsyncThunk<void, UpdateAppUserInfoRequest>(
  'admin-users/update-user-info',
  async (request: UpdateAppUserInfoRequest) => {
    return AdminUsersApi.updateUserInfo(request);
  },
);

export const adminUsersAsyncActions = {
  getUsers,
  getUserInfo,
  updateUserInfo,
};
