import { DeleteAutomationRuleRequest } from '@/Api/ShippingAutomation';
import { ShippingAutomationRuleType } from '@/Enums';
import {
  AmountAutomationRuleModel,
  ShippingAutomationRuleModel,
  PackagingAutomationRuleModel,
  MixCategoryAutomationRuleModel,
} from '@/Models';
import { ModalDataType } from '@/Types/ModalDataType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual, difference } from 'underscore';
import { asyncActions } from './asyncActions';
import { arrayUtils } from '@/Utils/ArrayUtils';

export type ShippingAutomationSettingsState = {
  amountAutomationRules: AmountAutomationRuleModel[];
  shippingAutomationRules: ShippingAutomationRuleModel[];
  packagingAutomationRules: PackagingAutomationRuleModel[];
  mixCategoryAutomationRules: MixCategoryAutomationRuleModel[];
  deleteModaData: ModalDataType<DeleteAutomationRuleRequest>;
};

const initialState: ShippingAutomationSettingsState = {
  amountAutomationRules: [],
  shippingAutomationRules: [],
  packagingAutomationRules: [],
  mixCategoryAutomationRules: [],
  deleteModaData: { visible: false },
};

const shippingAutomationSettingsSlice = createSlice({
  name: 'packagesSettings',
  initialState,
  reducers: {
    setDeletedModalData: (state, action: PayloadAction<ModalDataType<DeleteAutomationRuleRequest>>) => {
      state.deleteModaData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getAutomationRules.fulfilled, (state, action) => {
        state.amountAutomationRules = action.payload.amountAutomationRules ?? state.amountAutomationRules;
        state.shippingAutomationRules = action.payload.shippingAutomationRules ?? state.shippingAutomationRules;
        state.packagingAutomationRules = action.payload.packagingAutomationRules ?? state.packagingAutomationRules;
        state.mixCategoryAutomationRules =
          action.payload.mixCategoryAutomationRules ?? state.mixCategoryAutomationRules;
      })
      .addCase(asyncActions.createAutomationRule.fulfilled, (state, action) => {
        if (action.payload.amountAutomationRule) {
          state.amountAutomationRules.push(action.payload.amountAutomationRule);
        } // if

        if (action.payload.packagingAutomationRule) {
          state.packagingAutomationRules.push(action.payload.packagingAutomationRule);
        } // if

        if (action.payload.shippingAutomationRule) {
          state.shippingAutomationRules.push(action.payload.shippingAutomationRule);
          state.shippingAutomationRules = arrayUtils.sortBy(state.shippingAutomationRules, 'moreThanQuantity');
        } // if

        if (action.payload.mixCategoryAutomationRule) {
          state.mixCategoryAutomationRules.push(action.payload.mixCategoryAutomationRule);
        } // if
      })
      .addCase(asyncActions.updateAutomationRules.fulfilled, (state, action) => {
        state.amountAutomationRules = state.amountAutomationRules.map(
          (item) => action.payload.amountAutomationRules?.find((rule) => rule.id === item.id) ?? item,
        );

        state.shippingAutomationRules = state.shippingAutomationRules.map(
          (item) => action.payload.shippingAutomationRules?.find((rule) => rule.id === item.id) ?? item,
        );

        state.packagingAutomationRules = state.packagingAutomationRules.map(
          (item) => action.payload.packagingAutomationRules?.find((rule) => rule.id === item.id) ?? item,
        );

        // TODO fix
        // state.mixCategoryAutomationRules = state.mixCategoryAutomationRules.map(
        //   (item) => action.payload.mixCategoryAutomationRules?.find((rule) => rule.packageIds.find(item.)) ?? item,
        // );
      })
      .addCase(asyncActions.deleteAutomationRule.fulfilled, (state, action) => {
        switch (action.payload.shippingAutomationRuleType) {
          case ShippingAutomationRuleType.AmountAutomation:
            state.amountAutomationRules = state.amountAutomationRules.filter((item) => {
              return !isEqual(item.ruleIds, action.payload.deletedAutomationRuleIds);
            });
            break;

          case ShippingAutomationRuleType.ShippingAutomation:
            state.shippingAutomationRules = state.shippingAutomationRules.filter((item) => {
              return difference(item.ruleIds, action.payload.deletedAutomationRuleIds).length !== 0;
            });
            break;

          case ShippingAutomationRuleType.PackagesAutomation:
            state.packagingAutomationRules = state.packagingAutomationRules.filter((item) => {
              return difference(item.ruleIds, action.payload.deletedAutomationRuleIds).length !== 0;
            });
            break;

          case ShippingAutomationRuleType.MixCategoriesAutomation:
            // TODO fix
            // state.mixCategoryAutomationRules = state.mixCategoryAutomationRules.filter((item) => {
            //   return difference(item.id, action.payload.deletedAutomationRuleIds).length !== 0;
            // });
            break;
        } // switch
      });
  },
});

const { actions, reducer } = shippingAutomationSettingsSlice;

export const shippingAutomationSettingsActions = actions;
export const shippingAutomationSettingsReducer = reducer;
export const shippingAutomationSettingsAsyncActions = asyncActions;
