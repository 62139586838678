import { TranslationType } from '@/Localization/LanguageKeys';
import { Nullable } from '@/Types';

export enum ShippingServiceType {
  PickupToPickup = 'PickupToPickup',
  PickupToCurier = 'PickupToCurier',
  CurierToPickup = 'CurierToPickup',
  CurierToCurier = 'CurierToCurier',
}

const getShippingServiceTypeNameByType = (
  shippingServiceType: Nullable<ShippingServiceType>,
  translation: TranslationType,
): string => {
  if (!shippingServiceType) return '';

  const {
    Enums: { LogisticShippingServiceType: LogisticShippingServiceTypeLocalization },
  } = translation;

  const shippingServiceMap = {
    [ShippingServiceType.PickupToPickup]: LogisticShippingServiceTypeLocalization.PickupToPickup,
    [ShippingServiceType.PickupToCurier]: LogisticShippingServiceTypeLocalization.PickupToCurier,
    [ShippingServiceType.CurierToPickup]: LogisticShippingServiceTypeLocalization.CurierToPickup,
    [ShippingServiceType.CurierToCurier]: LogisticShippingServiceTypeLocalization.CurierToCurier,
  };

  return shippingServiceMap[shippingServiceType];
};

export const shippingServiceTypeUtils = {
  getShippingServiceTypeNameByType,
};
