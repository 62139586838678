import { CreateOrderAutomationSettingsRequest } from '@/Api/OrderAutomation/CreateOrderAutomationSettingsRequest';
import { CreateOrderAutomationSettingsResponse } from '@/Api/OrderAutomation/CreateOrderAutomationSettingsResponse';
import { OrderAutomationApi } from '@/Api/OrderAutomation/OrderAutomationApi';
import { OrderAutomationSettingsModel } from '@/Models/OrderAutomation/OrderAutomationSettingsModel';
import { createAsyncThunk } from '@reduxjs/toolkit';

const createOrderAutomationSettings = createAsyncThunk<
  CreateOrderAutomationSettingsResponse,
  CreateOrderAutomationSettingsRequest
>('order-automation/create-settings', async (request: CreateOrderAutomationSettingsRequest) => {
  return OrderAutomationApi.createOrderAutomationSettings(request);
});

const updateOrderAutomationSettings = createAsyncThunk<
  CreateOrderAutomationSettingsResponse,
  CreateOrderAutomationSettingsRequest
>('order-automation/update-settings', async (request: CreateOrderAutomationSettingsRequest) => {
  return OrderAutomationApi.updateOrderAutomationSettings(request);
});

const getOrderAutomationSettings = createAsyncThunk<OrderAutomationSettingsModel, void>(
  'order-automation/get-settings',
  async () => {
    return OrderAutomationApi.getOrderAutomationSettings();
  },
);

export const orderAutomationSettingsAsyncActions = {
  createOrderAutomationSettings,
  updateOrderAutomationSettings,
  getOrderAutomationSettings,
};
