import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ShippingMethodsApi,
  CreateShippingMethodRequest,
  CreateShippingMethodResponse,
  UpdateShippingMethodRequest,
  UpdateShippingMethodResponse,
} from '@/Api/ShippingMethods';

const getShippingMethods = createAsyncThunk('shipping-methods/get-shipping-methods', async () => {
  return ShippingMethodsApi.getShippingMethods();
});

const createShippingMethod = createAsyncThunk<CreateShippingMethodResponse, CreateShippingMethodRequest>(
  'shipping-methods/create',
  async (request: CreateShippingMethodRequest) => {
    return ShippingMethodsApi.createShippingMethod(request);
  },
);

const updateShippingMethod = createAsyncThunk<UpdateShippingMethodResponse, UpdateShippingMethodRequest>(
  'shipping-methods/update',
  async (request: UpdateShippingMethodRequest) => {
    return ShippingMethodsApi.updateShippingMethod(request);
  },
);

const deleteShippingMethod = createAsyncThunk<number, number>('shipping-methods/delete', async (id: number) => {
  return ShippingMethodsApi.deleteShippingMethod(id);
});

export const asyncActions = {
  getShippingMethods,
  createShippingMethod,
  updateShippingMethod,
  deleteShippingMethod,
};
