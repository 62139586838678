import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FunctionGroupModel, SubscriptionModel } from '@/Models';
import { asyncActions } from './asyncActions';
import { Nullable } from '@/Types';
import { ModalDataType } from '@/Types/ModalDataType';

export type SubscriptionsState = {
  subscriptions: SubscriptionModel[];
  selectedSubscription: Nullable<SubscriptionModel>;
  functionGroups: FunctionGroupModel[];
  modalState?: ModalDataType<SubscriptionModel>;
};

const initialState: SubscriptionsState = {
  subscriptions: [],
  selectedSubscription: null,
  functionGroups: [],
};

const subscriptionsSlice = createSlice({
  name: 'subscriptionsPage',
  initialState,
  reducers: {
    setSelectedSubscription: (state, action: PayloadAction<Nullable<SubscriptionModel>>) => {
      state.selectedSubscription = action.payload;
    },
    setModalState: (state, action: PayloadAction<ModalDataType<SubscriptionModel>>) => {
      state.modalState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getSubscriptions.fulfilled, (state, action) => {
        state.subscriptions = action.payload.subscriptions;
      })
      .addCase(asyncActions.createSubscription.fulfilled, (state, action) => {
        const newSubscription = action.payload.subscription;

        state.subscriptions.push(newSubscription);
        state.selectedSubscription = newSubscription;

        if (newSubscription.isSpecialOffer || newSubscription.isDefault) {
          state.subscriptions.forEach((subscription) => {
            if (subscription.id !== newSubscription.id) {
              subscription.isSpecialOffer = newSubscription.isSpecialOffer ? false : subscription.isSpecialOffer;
              subscription.isDefault = newSubscription.isDefault ? false : subscription.isDefault;
            } // if
          });
        } // if
      })
      .addCase(asyncActions.updateSubscription.fulfilled, (state, action) => {
        const updatedSubscription = action.payload.subscription;

        state.subscriptions = state.subscriptions.map((item) =>
          item.id === updatedSubscription.id ? updatedSubscription : item,
        );

        state.selectedSubscription = updatedSubscription;

        if (updatedSubscription.isSpecialOffer || updatedSubscription.isDefault) {
          state.subscriptions.forEach((subscription) => {
            if (subscription.id !== updatedSubscription.id) {
              subscription.isSpecialOffer = updatedSubscription.isSpecialOffer ? false : subscription.isSpecialOffer;
              subscription.isDefault = updatedSubscription.isDefault ? false : subscription.isDefault;
            } // if
          });
        } // if
      })
      .addCase(asyncActions.deleteSubscription.fulfilled, (state, action) => {
        state.subscriptions = state.subscriptions.filter((item) => item.id !== action.payload);
        state.selectedSubscription = null;
      })
      .addCase(asyncActions.getFunctions.fulfilled, (state, action) => {
        state.functionGroups = action.payload;
      });
  },
});

const { actions, reducer } = subscriptionsSlice;

export const subscriptionsPageActions = actions;
export const subscriptionsPageReducer = reducer;
export const subscriptionsAsyncActions = asyncActions;
