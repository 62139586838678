import { RootState } from '@/Redux/RootReducer';
import { Pair } from '@/Types';
import { createSelector } from 'reselect';
import { ShippingMethodsDataState } from './reducer';
import { ObjectUtils } from '@/Utils';

const shippingMethodsDataSelector = (root: RootState) => root.dynamicData.shippingMethodsData;
const translation = (root: RootState) => root.global.translation;

const shippingMethods = createSelector(
  shippingMethodsDataSelector,
  (state: ShippingMethodsDataState) => state.shippingMethods,
);
const shippingMethodsOptions = createSelector(
  [shippingMethodsDataSelector, translation],
  (state: ShippingMethodsDataState, translation): Pair<number>[] => {
    const { Enums } = translation;
    return state.shippingMethods.map((x) => {
      return {
        key: x.id,
        value: ObjectUtils.getObjectPathValue(Enums, `ShippingMethod.${x.code}`) ?? x.code,
      };
    });
  },
);

export const shippingMethodsDataSelectors = {
  shippingMethods,
  shippingMethodsOptions,
};
