import { useMemo } from 'react';
import { useLocalization } from '@/Hooks';
import { ColumnInfo, ColumnType } from '@/Types';
import { DraggableBetweenTablesData } from '@/Components/DataTable/types';
import { OrderItemModel } from '@/Models';

export function useSplitOrderColumnInfo() {
  const { orderColumns } = useLocalization();

  return useMemo<ColumnInfo<OrderItemModel & DraggableBetweenTablesData>[]>(
    () => [
      {
        columnType: ColumnType.String,
        visible: true,
        width: 50,
        fieldName: 'quantity',
        headerText: orderColumns.quantity,
      },
      {
        columnType: ColumnType.String,
        visible: true,
        width: 300,
        fieldName: 'variantTitle',
        headerText: orderColumns.title,
      },
      {
        columnType: ColumnType.String,
        visible: true,
        width: 90,
        fieldName: 'salesTaxPercent',
        headerText: orderColumns.salesTaxPercent,
      },
      {
        columnType: ColumnType.Currency,
        visible: true,
        width: 90,
        fieldName: 'priceNetto',
        headerText: orderColumns.priceNetto,
      },
      {
        columnType: ColumnType.Currency,
        visible: true,
        width: 90,
        fieldName: 'totalPriceNettoWithoutShipping',
        headerText: orderColumns.totalPriceNettoWithoutShipping,
      },
      {
        columnType: ColumnType.Currency,
        visible: true,
        width: 90,
        fieldName: 'shippingCostNetto',
        headerText: orderColumns.shippingCostNettoPerPosition,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'dragTableId',
        visible: false,
      },
    ],
    [orderColumns],
  );
}
