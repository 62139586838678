import { useMemo, useState } from 'react';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { useSelector } from 'react-redux';
import { logisticSettingsSelectors } from '@/Pages/Settings/modules/LogisticsSettings/services/selectors';
import { treeViewUtils } from '@/Utils';
import { LogisticTypeKeys } from '@/Enums';

export function useActiveLogisticTypesForUserTreeViewOptions() {
  const logisticTypes = useSelector(logisticSettingsSelectors.activeLogisticTypesForUserTreeView);

  const [selectedLogisticTypes, setSelectedLogisticTypes] = useState<LogisticTypeKeys[]>(() => {
    const selectedKeys = treeViewUtils.getSelectedKeys(
      logisticTypes.map((logisticType) => ({
        ...logisticType,
        items: logisticType.items?.map((item) => item),
        selected: true,
      })),
    );

    return selectedKeys as LogisticTypeKeys[];
  });

  const activeLogisticTypesForUserTreeViewItems = useMemo<TreeItemType<LogisticTypeKeys>[]>(() => {
    return logisticTypes.map((item) => ({ ...item, selected: true }));
  }, [logisticTypes]);

  const logisticIds = useMemo(() => selectedLogisticTypes.filter((type) => type), [selectedLogisticTypes]);

  return {
    activeLogisticTypesForUserTreeViewItems,
    setSelectedLogisticTypes,
    logisticIds,
  };
}
