import { ProductType } from '@/Enums';
import { ShopCartItemModel } from '@/Models/ShopCartItemModel';
import { SubscriptionShopModel } from '@/Models';
import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';
import { shopPageUtils } from './utils';

const shopPage = (state: RootState) => state.shopPage;
const translation = (state: RootState) => state.global.translation;
const translationProducts = (state: RootState) => state.global.translationProducts;
const localization = (state: RootState) => state.global.selectedLanguage.key;

const subscriptions = createSelector(
  [shopPage, translationProducts, translation, localization],
  (state, translationProducts, translation, localization): SubscriptionShopModel[] => {
    return state.subscriptions.map((subscription) => ({
      ...subscription,
      name: shopPageUtils.getProductName(subscription, ProductType.SubscriptionProduct, translation),
      description: shopPageUtils.getProductDescription(
        subscription,
        ProductType.SubscriptionProduct,
        translationProducts,
        translation,
        localization,
      ),
    }));
  },
);

const digitalProducts = createSelector(
  [shopPage, translationProducts, translation, localization],
  (state, translationProducts, translation, localization) => {
    return state.digitalProducts.map((digitalProduct) => ({
      ...digitalProduct,
      name: shopPageUtils.getProductName(digitalProduct, ProductType.DigitalProduct, translation),
      description: shopPageUtils.getProductDescription(
        digitalProduct,
        ProductType.DigitalProduct,
        translationProducts,
        translation,
        localization,
      ),
    }));
  },
);

const documentTemplates = createSelector(
  [shopPage, translationProducts, translation, localization],
  (state, translationProducts, translation, localization) => {
    return state.documentTemplates.map((digitalProduct) => ({
      ...digitalProduct,
      name: shopPageUtils.getProductName(digitalProduct, ProductType.DigitalProduct, translation),
      description: shopPageUtils.getProductDescription(
        digitalProduct,
        ProductType.DigitalProduct,
        translationProducts,
        translation,
        localization,
      ),
    }));
  },
);

const shopCartItems = createSelector(
  [shopPage, translationProducts, translation, localization],
  (state, translationProducts, translation, localization): ShopCartItemModel[] => {
    return state.shopCartItems.map((item) => {
      const product = {
        ...(item.digitalProduct ?? item.subscriptionProduct),
        numberOfUses: item.digitalProduct ? item.digitalProduct.numberOfUses : 0,
        contractTerm: item.subscriptionProduct ? item.subscriptionProduct.contractTerm : 0,
        marketPrice: item.marketPrice,
        marketCurrency: item.marketCurrency,
        userPrice: item.userPrice,
        userCurrency: item.userCurrency,
      };

      return {
        ...item,
        productName: shopPageUtils.getProductName(product, item.productType, translation),
        productDescription: shopPageUtils.getProductDescription(
          product,
          item.productType,
          translationProducts,
          translation,
          localization,
        ),
      };
    });
  },
);

const shopCartSummary = createSelector([shopCartItems], (shopCartItems) => {
  return {
    amountNet: shopPageUtils.getShopItemAmount('userAmountNet', shopCartItems),
    amountTax: shopPageUtils.getShopItemAmount('userTaxAmount', shopCartItems),
    amountGross: shopPageUtils.getShopItemAmount('userAmountGross', shopCartItems),
  };
});

export const shopPageSelectors = {
  subscriptions,
  digitalProducts,
  documentTemplates,
  shopCartItems,
  shopCartSummary,
};
