import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppUserModel, UserSettingModel } from '@/Models';
import { AppUserApi, UpdateUserRequest, UpdateUserResponse } from '@/Api/AppUser';
import {
  AppAuthApi,
  AppAuthorizeRequest,
  ChangePasswordRequest,
  ChangePasswordResponse,
  CheckEmailTokenRequest,
  CheckEmailTokenResponse,
  GetEmailTokenRequest,
  GetEmailTokenResponse,
  LoginResponse,
  RegisterRequest,
  RegisterResponse,
} from '@/Api/AppAuth';
import { UserSettingsApi } from '@/Api/UserSetting';
import { Nullable } from '@/Types';
import { AppUserSettingNameType, LocalizationType } from '@/Enums';
import { globalActions } from '../Global/reducer';
import { RootState } from '../RootReducer';
import { UpdateRegionalSettingsResponse } from '@/Api/UserSetting/UpdateRegionalSettingsResponse';
import { UpdateRegionalSettingsRequest } from '@/Api/UserSetting/UpdateRegionalSettingsRequest';
import { backgroundTasksActions } from '@/Redux/BackgroundTasks/reducer';
import { backgroundTaskMapping } from '@/Mapping/backgroundTaskMapping';

const loginRequest = createAsyncThunk<LoginResponse, AppAuthorizeRequest>(
  'user/login',
  async (authorizeRequest: AppAuthorizeRequest) => {
    return AppAuthApi.login(authorizeRequest);
  },
);

const registerUser = createAsyncThunk<RegisterResponse, RegisterRequest>(
  'user/register',
  async (registerRequest: RegisterRequest) => {
    return AppAuthApi.registerUser(registerRequest);
  },
);

const updateUser = createAsyncThunk<UpdateUserResponse, UpdateUserRequest>(
  'user/update-user',
  async (request: UpdateUserRequest) => {
    return AppUserApi.updateUser(request);
  },
);

const getCurrentUserInfo = createAsyncThunk<AppUserModel, object>('user/get-current-user-info', async (_, thunkApi) => {
  const response = await AppUserApi.getCurrentUserInfo();
  const dispatch = thunkApi.dispatch;
  const state = thunkApi.getState() as RootState;

  // Set language settings
  const usersLanguageSetting = response.userSettings.find(
    (setting) => setting.name === AppUserSettingNameType.UiLanguage,
  );

  if (response.backgroundTasks?.length) {
    const forProgressList = response.backgroundTasks.map(backgroundTaskMapping.toBackgroundTaskForProgressList);
    dispatch(backgroundTasksActions.addTasks(forProgressList));
  }

  dispatch(
    globalActions.setLanguage(
      usersLanguageSetting ? (usersLanguageSetting.value as LocalizationType) : state.global.selectedLanguage.key,
    ),
  );

  return response;
});

const getEmailToken = createAsyncThunk<GetEmailTokenResponse, GetEmailTokenRequest>(
  'user/get-email-token',
  async (request: GetEmailTokenRequest) => {
    return AppAuthApi.getEmailToken(request);
  },
);

const checkEmailToken = createAsyncThunk<CheckEmailTokenResponse, CheckEmailTokenRequest>(
  'user/check-email-token',
  async (request: CheckEmailTokenRequest) => {
    return AppAuthApi.checkEmailToken(request);
  },
);

const changeUserPassword = createAsyncThunk<ChangePasswordResponse, ChangePasswordRequest>(
  'user/change-user-password',
  async (request: ChangePasswordRequest) => {
    return AppAuthApi.changeUserPassword(request);
  },
);

const logoutRequest = createAsyncThunk<void, void>('user/logout', async () => {
  return AppAuthApi.logout();
});

const addOrUpdateSetting = createAsyncThunk<UserSettingModel | null, UserSettingModel>(
  'user/add-or-update-setting',
  async (request: Nullable<UserSettingModel>) => {
    if (!request) {
      return Promise.resolve(null);
    }
    return UserSettingsApi.addOrUpdateSetting(request);
  },
);

const updateRegionalSettings = createAsyncThunk<UpdateRegionalSettingsResponse, UpdateRegionalSettingsRequest>(
  'user/update-regional-settings',
  async (request: UpdateRegionalSettingsRequest) => {
    return UserSettingsApi.updateRegionalSettings(request);
  },
);

export const userAsyncActions = {
  loginRequest,
  registerUser,
  updateUser,
  getCurrentUserInfo,
  getEmailToken,
  checkEmailToken,
  changeUserPassword,
  logoutRequest,
  addOrUpdateSetting,
  updateRegionalSettings,
};
