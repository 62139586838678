import React from 'react';
import styles from './Icons.scss';
import cn from 'classnames';
import { IconProps, Icons } from './IconsRes';
import { getIconClasses } from '@/Static/IconUtils';

export const ModalCloseIcon = ({ size = 'small', onClick, className }: IconProps) => {
  return (
    <img
      src={Icons.ModalClose}
      alt="img"
      className={cn(styles.icon, getIconClasses(size), className)}
      onClick={onClick}
    />
  );
};
