import { FeedbackModel } from './';
import { Nullable } from '@/Types';
import { SaleFormat } from '@/Enums';

export type OrderItemModel = {
  id: number;
  marketplaceId: string;
  transactionId: string;
  ebayPlus: Nullable<boolean>;
  sku: string;
  warehousePlace: string;
  title: string;
  variantTitle: string;
  shippingCostBrutto: number;
  shippingCostNetto: number;

  priceNetto: number;
  priceBrutto: number;
  totalPriceNettoWithShipping: number;
  totalPriceNettoWithoutShipping: number;
  totalPriceBruttoWithoutShipping: number;
  totalPriceBruttoWithShipping: number;
  salesTaxTotalWithShipping: number;
  salesTaxPercent: number;
  transactionVariableFee: number;
  promotionFee: number;
  saleFormat: SaleFormat;

  quantity: number;
  shippingQuantity: number;
  trackingNumber: string;
  sellerFeedback?: FeedbackModel;
  buyerFeedback?: FeedbackModel;
  category: string;

  variantName1: string;
  variantValue1: string;

  variantName2: string;
  variantValue2: string;

  variantName3: string;
  variantValue3: string;

  variantName4: string;
  variantValue4: string;

  variantName5: string;
  variantValue5: string;

  //#region App generaited fields
  purchaseNet: number;
  //#endregion

  marketplacePayout: number;
};

function getVariants(orderItem: OrderItemModel): string {
  return [
    orderItem.variantValue1,
    orderItem.variantValue2,
    orderItem.variantValue3,
    orderItem.variantValue4,
    orderItem.variantValue5,
  ]
    .filter((x) => x)
    .join(', ');
}

export const orderItemModelUtils = {
  getVariants,
};
