import { TreeItemType } from '@/Components/TreeView/TreeView';
import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';
import { GenPackageTypesDataState } from './reducer';
import { ObjectUtils } from '@/Utils';

const logisticPackageTypesDataSelector = (root: RootState) => root.dynamicData.genPackageTypesData;
const translation = (root: RootState) => root.global.translation;

const packageTypes = createSelector(
  logisticPackageTypesDataSelector,
  (state: GenPackageTypesDataState) => state.packageTypes,
);

const packageTypesOptions = createSelector(
  [logisticPackageTypesDataSelector, translation],
  (state: GenPackageTypesDataState, translation) => {
    const {
      Pages: {
        WarehouseLogistics: { SettingsProducts },
      },
    } = translation;
    return state.packageTypes.map((x) => {
      return {
        key: x.id,
        value: ObjectUtils.getObjectPathValue(SettingsProducts, `Types.GenPackageType.${x.name}`) ?? x.name,
      };
    });
  },
);

const packageTypesTreeViewOptionsMemo = createSelector([packageTypesOptions], (packageTypesOptions): TreeItemType[] => {
  return packageTypesOptions.map(
    (item) =>
      ({
        key: item.key,
        value: item.value,
        expanded: true,
      } as TreeItemType),
  );
});

export const genPackageTypesDataSelectors = {
  packageTypes,
  packageTypesOptions,
  packageTypesTreeViewOptionsMemo,
};
