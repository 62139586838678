import { ApiRoutingUtils } from '@/Routing';
import {
  GetAutomationRulesRequest,
  GetAutomationRulesResponse,
  CreateAutomationRuleRequest,
  CreateAutomationRuleResponse,
  UpdateAutomationRulesRequest,
  UpdateAutomationRulesResponse,
  DeleteAutomationRuleRequest,
  DeleteAutomationRuleResponse,
  CreateOrderMixAutomationRuleRequest,
  CreateOrderMixAutomationRuleResponse,
} from './';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('shipping-automation');

async function getAutomationRules(request: GetAutomationRulesRequest): Promise<GetAutomationRulesResponse> {
  return rest.get(url, request);
}

async function createAutomationRule(request: CreateAutomationRuleRequest): Promise<CreateAutomationRuleResponse> {
  return rest.post(url, request);
}

async function updateAutomationRules(request: UpdateAutomationRulesRequest): Promise<UpdateAutomationRulesResponse> {
  return rest.put(url, request);
}

async function deleteAutomationRule(request: DeleteAutomationRuleRequest): Promise<DeleteAutomationRuleResponse> {
  return rest.delete(url, request);
}

async function createOrderMixAutomationRules(
  request: CreateOrderMixAutomationRuleRequest,
): Promise<CreateOrderMixAutomationRuleResponse> {
  return rest.post(`${url}/order-mix-rules`, request);
}

export const ShippingAutomationApi = {
  getAutomationRules,
  createAutomationRule,
  updateAutomationRules,
  deleteAutomationRule,
  createOrderMixAutomationRules,
};
