import { DRAG_BETWEEN_TABLES_KEY, DraggableBetweenTablesData } from '@/Components/DataTable/types';

function toDraggableData<T>(objects: T[], key: string): (DraggableBetweenTablesData & T)[] {
  return objects.map((obj) => ({
    ...obj,
    [DRAG_BETWEEN_TABLES_KEY]: key,
  }));
}

export const sharedMapping = {
  toDraggableData,
};
