import { createAsyncThunk } from '@reduxjs/toolkit';
import { MergeOrdersRequest } from '@/Api/MpOrders/Requests/MergeOrdersRequest';
import { MpOrdersApi } from '@/Api/MpOrders';
import { MergeOrdersResponse } from '@/Api/MpOrders/Responses/MergeOrdersResponse';

const mergeOrders = createAsyncThunk<MergeOrdersResponse, MergeOrdersRequest>(
  'mergeOrdersModal/split',
  (request: MergeOrdersRequest) => {
    return MpOrdersApi.mergeOrders(request);
  },
);

export const mergeOrdersAsyncActions = {
  mergeOrders,
};
