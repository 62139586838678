import { Action, combineReducers } from 'redux';
import { globalReducer } from '@/Redux/Global/reducer';
import { userReducer } from '@/Redux/User';
import { settingsPageReducer } from '@/Pages/Settings/services/reducer';
import { salesPageReducer } from '@/Pages/Sales/services/reducer';
import { AppDispatch } from './ConfigureStore';
import { productsPageReducer } from '@/Pages/Products/services/reducer';
import { warehousePageReducer } from '@/Pages/Warehouse/services/reducer';
import { warehouseLogisticsPageCombineReducer } from '@/Pages/Administration/LogisticProducts/services/reducer';
import { purchasesPageReducer } from '@/Pages/Purchases/services/reducer';
import { adminUsersPageReducer } from '@/Pages/Administration/Users/services/reducer';
import { modalWindowsReducer } from '@/ModalWindows/services';
import { subscriptionsPageReducer } from '@/Pages/Administration/Subscriptions/services/reducer';
import { dynamicDataReducer } from '@/Redux/DynamicData';
import { logisticTypesDataReducer } from './Logistic/reducer';
import { onlineArchivePageReducer } from '@/Pages/OnlineArchive/services';
import { userAsyncActions } from '@/Redux/User/asyncActions';
import { digitalProductsPageReducer } from '@/Pages/Administration/DigitalProducts/services/reducer';
import { shopPageReducer } from '@/Pages/Shop/services';
import { backgroundTasksReducer } from '@/Redux/BackgroundTasks/reducer';
import { accountingPageReducer } from '@/Pages/Administration/Accounting/services/reducer';
import { suppliersPageReducer } from '@/Pages/Suppliers/services/reducer';
import { customersPageReducer } from '@/Pages/Customers/services/reducer';
import { languageEditorPageReducer } from '@/Pages/Administration/LanguageEditor/services/reducer';
import { adminCalendarPageReducer } from '@/Pages/Administration/Calendar/services/reducer';
import { customerCartPageReducer } from '@/Pages/Administration/Users/modules/UserInfo/modules/ShoppingCard/services/reducer';
import { dashboardPageReducer } from '@/Pages/Dashboard/services/reducer';
import { billingPageReducer } from '@/Pages/Administration/Billing/services/reducer';
import { themeReducer } from '@/Redux/Theme/reducer';
import { tourReducer } from '@/Redux/Tours/reducer';

const appReducer = combineReducers({
  global: globalReducer,
  theme: themeReducer,
  tour: tourReducer,
  user: userReducer,
  settingsPage: settingsPageReducer,
  salesPage: salesPageReducer,
  purchasesPage: purchasesPageReducer,
  productsPage: productsPageReducer,
  warehousePage: warehousePageReducer,
  warehouseLogisticsPage: warehouseLogisticsPageCombineReducer,
  usersPage: adminUsersPageReducer,
  modalWindows: modalWindowsReducer,
  subscriptionsPage: subscriptionsPageReducer,
  dynamicData: dynamicDataReducer,
  logisticWarehouseData: logisticTypesDataReducer,
  onlineArchivePage: onlineArchivePageReducer,
  digitalProductsPage: digitalProductsPageReducer,
  shopPage: shopPageReducer,
  backgroundTasks: backgroundTasksReducer,
  accountingPage: accountingPageReducer,
  suppliersPage: suppliersPageReducer,
  customersPage: customersPageReducer,
  languageEditorPage: languageEditorPageReducer,
  adminCalendarPage: adminCalendarPageReducer,
  customerCartPage: customerCartPageReducer,
  dashboardPage: dashboardPageReducer,
  billingPage: billingPageReducer,
});

export const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === userAsyncActions.logoutRequest.fulfilled.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type AppThunkApiConfig = {
  state: RootState;
  dispatch: AppDispatch;
  extra?: unknown;
  rejectValue?: unknown;
};

export type RootState = ReturnType<typeof appReducer>;
