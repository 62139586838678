import { rest } from '@/Api/Rest';
import { PaymentInfoModel } from '@/Models/PaymentInfoModel';
import { ApiRoutingUtils } from '@/Routing';
import { CreatePaymentMethodRequest } from './CreatePaymentMethodRequest';
import { CreatePaymentMethodResponse } from './CreatePaymentMethodResponse';
import { GetPaymentMethodsResponse } from './GetPaymentMethodsResponse';

const url = ApiRoutingUtils.createUrl('payment-methods');

async function createPaymentMethod(request: CreatePaymentMethodRequest): Promise<CreatePaymentMethodResponse> {
  return rest.post(`${url}`, request);
}

async function getUserPaymentMethods(): Promise<GetPaymentMethodsResponse> {
  return rest.get(`${url}`);
}

async function deletePaymentMethod(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, {});
}

async function getMarketUserPaymentInfo(): Promise<PaymentInfoModel> {
  return rest.get(`${url}/market-user`);
}

export const PaymentMethodsApi = {
  createPaymentMethod,
  getUserPaymentMethods,
  deletePaymentMethod,
  getMarketUserPaymentInfo,
};
