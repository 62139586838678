import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateDocumentCategoryRequest,
  CreateDocumentCategoryResponse,
  UpdateDocumentCategoryRequest,
  UpdateDocumentCategoryResponse,
  DocumentCategoriesApi,
} from '@/Api/_Microservices/OnlineArchive/DocumentCategories';
import { DocumentCategoryModel } from '@/Models/_Microservices/OnlineArchive/DocumentCategoryModel';

const getDocumentCategories = createAsyncThunk<DocumentCategoryModel[], void>(
  'document-categories/get-document-categories',
  async () => {
    return DocumentCategoriesApi.getDocumentCategories();
  },
);

const createDocumentCategory = createAsyncThunk<CreateDocumentCategoryResponse, CreateDocumentCategoryRequest>(
  'document-categories/create-document-category',
  async (request: CreateDocumentCategoryRequest) => {
    return DocumentCategoriesApi.createDocumentCategory(request);
  },
);

const updateDocumentCategory = createAsyncThunk<UpdateDocumentCategoryResponse, UpdateDocumentCategoryRequest>(
  'document-categories/update-document-category',
  async (request: UpdateDocumentCategoryRequest) => {
    return DocumentCategoriesApi.updateDocumentCategory(request);
  },
);

const deleteDocumentCategory = createAsyncThunk<number, number>(
  'document-categories/delete-document-category',
  async (id: number) => {
    return DocumentCategoriesApi.deleteDocumentCategory(id);
  },
);

export const asyncActions = {
  getDocumentCategories,
  createDocumentCategory,
  updateDocumentCategory,
  deleteDocumentCategory,
};
