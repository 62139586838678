import { useAppDispatch } from '@/Redux/ConfigureStore';
import { cookieActions } from '@/ModalWindows/CookieModal/services/reducer';
import React from 'react';

// Styles import
import styles from './AuthorizationFooter.scss';

export function AuthorizationFooter() {
  const dispatch = useAppDispatch();

  return (
    <footer className={styles.authorizationFooter}>
      <a onClick={() => dispatch(cookieActions.setModalVisible(true))}>Cookie</a>
      <a href="https://site.ship-cheaper.com/policy" target="_blank" rel="noreferrer">
        Policy
      </a>
      <a href="https://site.ship-cheaper.com/eula" target="_blank" rel="noreferrer">
        Eula
      </a>
      <a href="https://site.ship-cheaper.com/imprint" target="_blank" rel="noreferrer">
        Imprint
      </a>
    </footer>
  );
}
