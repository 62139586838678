import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserWarehouseTransferBatchApi } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/UserWarehouseTransferBatchApi';
import { ScanBarcodeIncomingResponse } from 'src/Api/UserWarehouse/Transfers/Incoming/Responses/ScanBarcodeIncomingResponse';
import { ScanBarcodeIncomingRequest } from 'src/Api/UserWarehouse/Transfers/Incoming/Requests/ScanBarcodeIncomingRequest';
import { WarehouseIncomingTransferApi } from 'src/Api/UserWarehouse/Transfers/Incoming/WarehouseIncomingTransferApi';
import { GetIncomingTransfersByWarehouseRequest } from 'src/Api/UserWarehouse/Transfers/Incoming/Requests/GetIncomingTransfersByWarehouseRequest';
import { GetIncomingTransfersByWarehouseResponse } from 'src/Api/UserWarehouse/Transfers/Incoming/Responses/GetIncomingTransfersByWarehouseResponse';
import { CloseUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CloseUserWarehouseBatchResponse';
import { CloseUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CloseUserWarehouseBatchRequest';
import { RemoveUserWarehousePostingBatchItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/RemoveUserWarehousePostingBatchItemResponse';
import { RemoveUserWarehousePostingBatchItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/RemoveUserWarehousePostingBatchItemRequest';
import { GetFulfillmentTransferBatchResponse } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Responses/GetFulfillmentTransferBatchResponse';
import { GetFulfillmentTransferBatchRequest } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Requests/GetFulfillmentTransferBatchRequest';
import { CancelUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CancelUserWarehouseBatchResponse';
import { CancelUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CancelUserWarehouseBatchRequest';

const scanBarcode = createAsyncThunk<ScanBarcodeIncomingResponse, ScanBarcodeIncomingRequest>(
  'incoming-transfer/scan-barcode',
  async (request) => {
    return WarehouseIncomingTransferApi.scanBarcode(request);
  },
);

const getIncomingTransfers = createAsyncThunk<
  GetIncomingTransfersByWarehouseResponse,
  GetIncomingTransfersByWarehouseRequest
>('incoming-transfer/get-incoming-transfers', async (request) => {
  return WarehouseIncomingTransferApi.getIncomingTransfers(request);
});

const closeBatch = createAsyncThunk<CloseUserWarehouseBatchResponse, CloseUserWarehouseBatchRequest>(
  'incoming-transfer/close-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.closeBatch(request);
  },
);

const cancelBatch = createAsyncThunk<CancelUserWarehouseBatchResponse, CancelUserWarehouseBatchRequest>(
  'incoming-transfer/cancel-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.cancelBatch(request);
  },
);

const removeBatchItem = createAsyncThunk<
  RemoveUserWarehousePostingBatchItemResponse,
  RemoveUserWarehousePostingBatchItemRequest
>('incoming-transfer/remove-batch-item', async (request) => {
  return UserWarehouseTransferBatchApi.removeBatchItem(request);
});

const getBatch = createAsyncThunk<GetFulfillmentTransferBatchResponse, GetFulfillmentTransferBatchRequest>(
  'incoming-transfer/get-batch',
  async (request) => {
    return WarehouseIncomingTransferApi.getBatch(request);
  },
);

export const incomingTransferAsyncActions = {
  getBatch,
  scanBarcode,
  getIncomingTransfers,
  closeBatch,
  removeBatchItem,
  cancelBatch,
};
