import cn from 'classnames';
import styles from './Icons.scss';
import React from 'react';
import { IconProps, Icons } from './IconsRes';
import { getIconClasses } from './IconUtils';

type Props = {
  onClick?: () => void;
} & IconProps;

export const CopyIcon = ({ onClick, size = 'small', className }: Props) => {
  return (
    <img src={Icons.Copy} alt="img" className={cn(styles.icon, getIconClasses(size), className)} onClick={onClick} />
  );
};
