import React, { useMemo } from 'react';
import { DataTable } from '@/Components/DataTable/DataTable';
import { useEmailTemplatesTableColumnInfo } from './ColumnInfo';
import { useDispatch, useSelector } from 'react-redux';
import styles from './EmailTemplatesTable.scss';
import { emailSendModalActions } from '../../services/reducer';
import { emailSendModalSelectors } from '@/ModalWindows/EmailSendModal/services/selectors';

export const EmailTemplatesTable = (): React.ReactElement => {
  const dispatch = useDispatch();
  const columnInfos = useEmailTemplatesTableColumnInfo();

  const emailTemplates = useSelector(emailSendModalSelectors.rows);

  return useMemo(() => {
    return (
      <DataTable
        rowKey={'guid'}
        rows={emailTemplates}
        columnInfos={columnInfos}
        className={styles.table}
        filterOptions={{
          needGlobalSearchPanel: false,
          needColumnChooser: false,
          needClearFiltersButton: false,
          needShowFiltersButton: false,
          needHeaderFilter: false,
          needSearchFilterRow: false,
          showFilterBuilder: false,
        }}
        exportOptions={{
          exportFileName: '',
          needExport: false,
        }}
        selectOptions={{
          onFocusedRowChanged: (data) => {
            dispatch(emailSendModalActions.setSelectedEmailTemplate(data));
          },
        }}
      />
    );
  }, [emailTemplates, columnInfos]);
};
