import React from 'react';
import { ClassNameProps, ColumnInfo } from '@/Types';
import cn from 'classnames';
import styles from './Table.scss';
import { tableUtils } from './services/utils';

type Props<T> = {
  columns: Pick<ColumnInfo<T>, 'headerText' | 'columnType' | 'fieldName' | 'render'>[];
  rows: T[];
} & ClassNameProps;

export const Table = <T extends object>({ columns, rows, className }: Props<T>): React.ReactElement => {
  return (
    <div className={cn(styles.tableWrapper, className)}>
      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.headerText}>{column.headerText}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.map((row, idx) => (
            <tr key={idx}>
              {columns.map((column) => {
                if (!column.fieldName || !column.columnType) return;

                if (column.render) return <td key={column.headerText}>{column.render(row, idx)}</td>; // if

                return (
                  <td key={column.headerText}>
                    {tableUtils.getCellValue(
                      (row[column.fieldName] as unknown) as string | number | boolean,
                      column.columnType,
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
