import React, { forwardRef } from 'react';
import { BeaconRenderProps } from 'react-joyride';
import styles from '../../Tour.scss';

export const Beacon = forwardRef<HTMLDivElement, BeaconRenderProps>((props, ref) => {
  return (
    <div ref={ref} {...props} className={styles.beacon}>
      <div className={styles.beaconInner} />
    </div>
  );
});

Beacon.displayName = 'Beacon';
