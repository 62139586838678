import { DocumentTypeModel } from '@/Models/_Microservices/OnlineArchive/DocumentTypeModel';
import { ApiRoutingUtils } from '@/Routing';
import {
  CreateDocumentTypeRequest,
  CreateDocumentTypeResponse,
  UpdateDocumentTypeRequest,
  UpdateDocumentTypeResponse,
} from './';
import { rest } from '@/Api/Rest';
import { GetDocumentsForDeleteCountRequest } from './GetDocumentsForDeleteCountRequest';

const url = ApiRoutingUtils.createOnlineArchiveUrl('document-types');

function getDocumentTypes(): Promise<DocumentTypeModel[]> {
  return rest.get(url);
}

function createDocumentType(request: CreateDocumentTypeRequest): Promise<CreateDocumentTypeResponse> {
  return rest.post(url, request);
}

function updateDocumentType(request: UpdateDocumentTypeRequest): Promise<UpdateDocumentTypeResponse> {
  return rest.put(url, request);
}

function deleteDocumentType(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, {});
}

function getDocumentsForDeleteCount(request: GetDocumentsForDeleteCountRequest): Promise<number> {
  return rest.post(`${url}/get-document-for-delete-count`, request);
}

export const DocumentTypesApi = {
  getDocumentTypes,
  createDocumentType,
  updateDocumentType,
  deleteDocumentType,
  getDocumentsForDeleteCount,
};
