import { orderAutomatizationSelectors } from '@/ModalWindows/OrderAutomatizationModal/services/selectors';
import { OrderAutomatizationProductModel } from '@/Models/OrderUserProducts/OrderAutomatizationProductModel';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useOwnContractLogisticOptions = (
  orderId: number,
  selectedPackageKeys: { key: string; weight: string | number }[],
) => {
  const orderPackages = useSelector(orderAutomatizationSelectors.orderPackages);

  const currentOrderPackageOptions = useMemo(() => {
    const currentOrderPackages = orderPackages.find((op) => op.orderId === orderId && op.isOwnContract);
    return currentOrderPackages?.packages;
  }, [orderId, orderPackages]);

  const currentOrderProductOptions = useMemo(() => {
    const currentOrderPackages = orderPackages.find((op) => op.orderId === orderId && op.isOwnContract);

    if (currentOrderPackages) {
      const selectedPackageOptions = currentOrderPackages.packages.filter((p) =>
        selectedPackageKeys.map((p) => p.key).includes(p.key),
      );

      return selectedPackageOptions
        .reduce((arr: { product: OrderAutomatizationProductModel; count: number }[], curr) => {
          curr.products.forEach((p) => {
            const existsProduct = arr.find((i) => i.product.id === p.id);

            if (existsProduct) {
              existsProduct.count++;
              return;
            } // if

            arr.push({ product: p, count: 1 });
          });

          return arr;
        }, [])
        .filter((p) => p.count === selectedPackageKeys.length)
        .map((p) => ({
          key: p.product.id,
          value: p.product.description,
          logisticTypeId: p.product.logisticTypeId,
        }));
    } // if

    return [];
  }, [orderId, orderPackages, selectedPackageKeys]);

  return {
    packageOptions: currentOrderPackageOptions,
    productOptions: currentOrderProductOptions,
  };
};
