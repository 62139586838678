import { createAsyncThunk } from '@reduxjs/toolkit';
import { UploadTemplatesApi } from '@/Api/_Microservices/OnlineArchive/UploadTemplates/UploadTemplatesApi';
import { UploadTemplateModel } from '@/Models/_Microservices/OnlineArchive/UploadTemplateModel';
import { CreateUploadTemplateResponse } from '@/Api/_Microservices/OnlineArchive/UploadTemplates/CreateUploadTemplateResponse';
import { CreateUploadTemplateRequest } from '@/Api/_Microservices/OnlineArchive/UploadTemplates/CreateUploadTemplateRequest';
import { UpdateUploadTemplateResponse } from '@/Api/_Microservices/OnlineArchive/UploadTemplates/UpdateUploadTemplateResponse';
import { UpdateUploadTemplateRequest } from '@/Api/_Microservices/OnlineArchive/UploadTemplates/UpdateUploadTemplateRequest';
import { GetUploadTemplatesRequest } from '@/Api/_Microservices/OnlineArchive/UploadTemplates/GetUploadTemplatesRequest';

const getUploadTemplates = createAsyncThunk<UploadTemplateModel[], GetUploadTemplatesRequest>(
  'upload-templates/get-upload-templates',
  async (request: GetUploadTemplatesRequest) => {
    return UploadTemplatesApi.getUploadTemplates(request);
  },
);

const createUploadTemplate = createAsyncThunk<CreateUploadTemplateResponse, CreateUploadTemplateRequest>(
  'upload-templates/create-upload-template',
  async (request: CreateUploadTemplateRequest) => {
    return UploadTemplatesApi.createUploadTemplate(request);
  },
);

const updateUploadTemplate = createAsyncThunk<UpdateUploadTemplateResponse, UpdateUploadTemplateRequest>(
  'upload-templates/update-upload-template',
  async (request: UpdateUploadTemplateRequest) => {
    return UploadTemplatesApi.updateUploadTemplate(request);
  },
);

const deleteUploadTemplate = createAsyncThunk<number, number>(
  'upload-templates/delete-upload-template',
  async (id: number) => {
    return UploadTemplatesApi.deleteUploadTemplate(id);
  },
);

export const asyncActions = {
  getUploadTemplates,
  createUploadTemplate,
  updateUploadTemplate,
  deleteUploadTemplate,
};
