import { MpOrdersMarkingApi } from '@/Api/MpOrdersMarking/MpOrdersMarkingApi';
import { ChangeOrderPaymentStatusPartiallyByMarkerRequest } from '@/Api/MpOrdersMarking/Requests';
import { DateFormatType, OrderMarker, OrderStatusFilterGroups } from '@/Enums';
import { useForm } from '@/Hooks/useFormWrapper';
import { useRequired } from '@/Hooks/Validation';
import { purchasesPageAsyncActions } from '@/Pages/Purchases/services';
import { salesPageActions } from '@/Pages/Sales/services';
import { dateTimeUtils } from '@/Utils/DateTimeUtils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Toasts } from '@/Components/Toast/Toast';
import { stringUtils } from '@/Utils';
import { useLocalization } from '@/Hooks';

type FormData = ChangeOrderPaymentStatusPartiallyByMarkerRequest;

export const usePaidAndNotPaidForm = (orderIds: number[], isSalesPage: boolean, onClose: () => void) => {
  const dispatch = useDispatch();

  const {
    notifications: {
      ChangePaymentStatusCompleted: { title, text },
    },
  } = useLocalization();

  const methods = useForm<FormData>({
    defaultValues: {
      orderIds,
      orderMarker: OrderMarker.Paid,
      overwritePreviousDates: false,
      paymentDate: dateTimeUtils.toFormattedString(new Date(), DateFormatType.LOCAL_DATE),
    },
  });

  // variables
  const needShowDate = methods.watch('orderMarker') === OrderMarker.Paid;

  // rules
  const required = useRequired();
  const dateRequired = useRequired(needShowDate);

  // function
  const submit = useCallback(
    (data: FormData) => {
      MpOrdersMarkingApi.changeOrderPaymentStatusPartially({
        ...data,
        paymentDate: dateTimeUtils.localStringToUtcString(data?.paymentDate),
      }).then((response) => {
        if (isSalesPage) {
          dispatch(
            salesPageActions.removeOrdersFromStateByCurrentFilters({
              orderIds: data.orderIds,
              filters: data.orderMarker === OrderMarker.NotPaid ? OrderStatusFilterGroups.NotPaidGroup : [],
            }),
          );

          setTimeout(() => {
            dispatch(salesPageActions.ordersUpdate(response));
          }, 100);
        } else {
          dispatch(purchasesPageAsyncActions.getPurchasesWithCurrentFilters());
        }

        if (response) {
          response.forEach((order) => {
            Toasts.showSuccess({ title: title, text: stringUtils.insertParamsToString(text, [order.userOrderId]) });
          });
        }

        onClose();
      });
    },
    [orderIds, onClose, isSalesPage],
  );

  return {
    methods,
    needShowDate,
    submit: methods.handleSubmit(submit),
    rules: {
      required,
      dateRequired,
    },
  };
};
