import { combineReducers } from 'redux';

import {
  marketplacesSettingsReducer,
  MarketplacesSettingsState,
} from '@/Pages/Settings/modules/MarketplacesSettings/services/reducer'; // Error while importing from index.ts
import {
  documentTemplateReducer,
  DocumentTemplateSettingsState,
} from '@/Pages/Settings/modules/DocumentTemplateSettings/services/reducer';

import {
  packagesSettingsReducer,
  PackagesSettingsState,
} from '@/Pages/Settings/modules/PackageSettings/services/reducer';

import {
  shippingAutomationSettingsReducer,
  ShippingAutomationSettingsState,
} from '@/Pages/Settings/modules/ShippingAutomationSettings/services/reducer';

import { addressBookSettingsReducer, AddressBookSettingsState } from '../modules/AddressBookSettings/services/reducer';
import {
  paymentMethodsReducer,
  PaymentMethodsState,
} from '../modules/AccountSettings/modules/PaymentMethods/services/reducer';
import {
  logisticSettingsCombineReducer,
  LogisticSettingsCombineState,
} from '../modules/LogisticsSettings/services/reducer';
import { emailTemplatesReducer, EmailTemplatesSettingsState } from '../modules/EmailTemplatesSettings/services/reducer';
import { feedbackSettingsReducer, FeedbackSettingsState } from '../modules/FeedbacksSettings/services/reducer';
import { userAccountSettingsReducer, UserAccountSettingsState } from '../modules/AccountSettings/services/reducer';
import {
  orderAutomationSettingsReducer,
  OrderAutomationSettingsState,
} from '../modules/OrderAutomation/services/reducer';
import { mandatSettingsReducer, MandatSettingsState } from '../modules/MandatsSettings/services/reducer';
import {
  warehouseSettingsPageReducer,
  WarehouseSettingsState,
} from '@/Pages/Settings/modules/WarehouseSettings/services/reducer';
import {
  productsSettingsReducer,
  ProductsSettingsState,
} from '@/Pages/Settings/modules/ProductsSettings/services/reducer';

export type SettingsPageState = {
  marketplacesSettings: MarketplacesSettingsState;
  documentTemplateSettings: DocumentTemplateSettingsState;
  packagesSettings: PackagesSettingsState;
  shippingAutomationSettings: ShippingAutomationSettingsState;
  addressBookSettings: AddressBookSettingsState;
  paymentMethodsSettings: PaymentMethodsState;
  logisticCombineSettings: LogisticSettingsCombineState;
  emailTemplatesSettings: EmailTemplatesSettingsState;
  feedbackSettings: FeedbackSettingsState;
  userAccountSettings: UserAccountSettingsState;
  orderAutomationSettings: OrderAutomationSettingsState;
  mandatSettings: MandatSettingsState;
  warehouseSettings: WarehouseSettingsState;
  productsSettings: ProductsSettingsState;
};

export const settingsPageReducer = combineReducers<SettingsPageState>({
  marketplacesSettings: marketplacesSettingsReducer,
  documentTemplateSettings: documentTemplateReducer,
  packagesSettings: packagesSettingsReducer,
  shippingAutomationSettings: shippingAutomationSettingsReducer,
  addressBookSettings: addressBookSettingsReducer,
  paymentMethodsSettings: paymentMethodsReducer,
  logisticCombineSettings: logisticSettingsCombineReducer,
  emailTemplatesSettings: emailTemplatesReducer,
  feedbackSettings: feedbackSettingsReducer,
  userAccountSettings: userAccountSettingsReducer,
  orderAutomationSettings: orderAutomationSettingsReducer,
  mandatSettings: mandatSettingsReducer,
  warehouseSettings: warehouseSettingsPageReducer,
  productsSettings: productsSettingsReducer,
});
