import { OrderCancelInvoiceModel, OrderInvoiceModel, OrderItemModel } from '../index';
import {
  OrderStatus,
  OrderType,
  PaymentStatus,
  PaymentMethod,
  CancelStatus,
  RefundStatus,
  RefundType,
  InvoiceType,
  CountryCode,
  MarketplaceType,
} from '@/Enums';
import { Nullable, OrderTableRow } from '@/Types';
import { TermsOfPaymentType } from '@/Enums/TermsOfPaymentType';
import { PaymentsProgramType } from '@/Enums/PaymentsProgramType';
import { OrderHistoryModel } from '../OrderHistoryModel';
import { ShipmentServiceLevelCategory } from '@/Enums/ShipmentServiceLevelCategory';
import { FulfillmentChannel } from '@/Enums/FulfillmentChannel';
import { OrderWarningType } from '@/Enums/Sales/OrderWarningType';
import { ShippingType } from '@/Enums/ShippingType';
import { ShippingStatusModel } from '../ShippingStatusModel';
import { DeliveryStatus } from 'src/Enums/DeliveryStatus';

export type OrderModel = {
  id: number;
  userOrderId: number;
  userProductId?: number;
  marketplaceType: MarketplaceType;
  parentOrder?: OrderModel;
  children: OrderModel[];
  orderItems: OrderItemModel[];
  orderStatus: OrderStatus;
  deliveryStatus: DeliveryStatus;
  cancelStatus: CancelStatus;
  orderType: OrderType;
  paymentsProgramType?: PaymentsProgramType;
  mpOrderNumber: string;
  extendedOrderId: string;
  buyerMarketplaceAccount: string;
  sellerMarketplaceAccount: string;
  accountName: string;
  buyerEmail: string;
  buyerVatNumber: string;
  termsOfPayment: TermsOfPaymentType;
  paymentDueDate: string;
  paymentDueDays: number;
  invoiceType: InvoiceType;
  mpAccountId: number;
  marketplace: string;
  history: OrderHistoryModel[];

  refundTime: Nullable<string>;
  refundAmount: number;
  refundType: Nullable<RefundType>;
  refundStatus: Nullable<RefundStatus>;
  shippingStatuses: ShippingStatusModel[];

  orderBalance: number;
  priceBruttoWithShipping: number;
  totalNettoWithShipping: number;
  amountPaid: number;
  shippingPrice: number;
  openAmount: number;
  orderFixFee: number;
  marketplaceFee: number;
  marketplacePayout: number;
  currencyId: string;
  category: string;

  commonVat1Percent: number;
  commonVat1Amount: number;
  commonNettoVat1Amount: number;
  commonVat2Percent?: number;
  commonVat2Amount?: number;
  commonNettoVat2Amount?: number;
  commonVat3Percent?: number;
  commonVat3Amount?: number;
  commonNettoVat3Amount?: number;
  commonVat4Percent?: number;
  commonVat4Amount?: number;
  commonNettoVat4Amount?: number;
  commonVat5Percent?: number;
  commonVat5Amount?: number;
  commonNettoVat5Amount?: number;
  countryVatId: string;
  vatCountry?: CountryCode;

  paymentStatus: PaymentStatus;
  paymentMethod: PaymentMethod;
  appCreatedTime: string;
  appLastModifiedTime: string;
  synchTime: string;
  appDeleted: string;
  mpLastModifiedTime: string;
  mpCreatedTime: string;
  paidTime: string;
  isDeliveryRequired: boolean;
  shippingMethodCode: string;
  shippingMethodDescription: string;
  shippedTime: string;
  estimatedDeliveryTimeMax: string;
  estimatedDeliveryTimeMin: string;
  handleByTime: string;
  actualDeliveryTime?: string;
  shipFromCountry?: CountryCode;
  exportOutsideEu?: boolean;
  invoiceConfirmedOnMarketplace: string;

  shippingCompanyName: string;
  shippingFirstName: string;
  shippingLastName: string;
  shippingAdditionalLine: string;
  shippingStreet: string;
  shippingHouseNo: string;
  shippingPostalCode: string;
  shippingCityName: string;
  shippingCountryCode: CountryCode;
  shippingCountryName: string;
  shippingPhoneNumber: string;

  originalShippingCompanyName: string;
  originalShippingFirstName: string;
  originalShippingLastName: string;
  originalShippingAdditionalLine: string;
  originalShippingStreet: string;
  originalShippingHouseNo: string;
  originalShippingPostalCode: string;
  originalShippingCityName: string;
  originalShippingCountryCode: CountryCode;
  originalShippingCountryName: string;
  originalShippingPhoneNumber: string;

  invoiceCompanyName: string;
  invoiceFirstName: string;
  invoiceLastName: string;
  invoiceAdditionalLine: string;
  invoiceStreet: string;
  invoiceHouseNo: string;
  invoicePostalCode: string;
  invoiceCityName: string;
  invoiceCountryCode: CountryCode;
  invoiceCountryName: string;
  invoicePhoneNumber: string;

  returnCompanyName: string;
  returnFirstName: string;
  returnLastName: string;
  returnAdditionalLine: string;
  returnStreet: string;
  returnHouseNo: string;
  returnPostalCode: string;
  returnCityName: string;
  returnCountryCode: CountryCode;
  returnCountryName: string;
  returnPhoneNumber: string;

  packageName1: string;
  packageName2: string;
  packageName3: string;
  packageName4: string;
  packageName5: string;

  //#region Application generation fields
  customerNumber?: string;
  customerNumberCreatedTime?: string;

  deliveryNoteNumber?: string;
  deliveryNoteNumberCreatedTime?: string;
  deliveryNotePrinted?: string;
  deliveryNoteMailed?: string;

  orderNumber?: string;
  orderNumberCreatedTime?: string;
  orderPrinted?: string;
  orderMailed?: string;

  invoices?: OrderInvoiceModel[];
  currentInvoice?: OrderInvoiceModel;
  currentCancelInvoice?: OrderCancelInvoiceModel;

  //TODO remove. It is temp decision for order create/update modal.
  cancelInvoiceNumber?: string;
  invoiceNumber?: string;

  internalNote: string;
  externalNote: string;

  actualShippingMethodCode1: string;
  actualShippingMethodCode2: string;
  actualShippingMethodCode3: string;
  actualShippingMethodCode4: string;
  actualShippingMethodCode5: string;
  actualShippingMethodDescription: string;
  actualShippingCostNetto: number;

  shippingType1: ShippingType;
  shippingType2: ShippingType;
  shippingType3: ShippingType;
  shippingType4: ShippingType;
  shippingType5: ShippingType;

  profit: number;

  isBlocked?: string;
  archived?: string;
  exported?: string;
  canceledNotPaid?: string;
  canceledRefunded?: string;
  notifiedTime?: string;

  addressEditDisabled?: string;
  orderEditDisabled?: string;
  //#endregion Application generation fields

  buyerNote: string;
  sellerNote: string;

  isOriginalShippingModified: boolean;

  secondContactPersonEmail: string;
  secondContactPersonName: string;
  secondContactPersonNotes: string;
  secondContactPersonPhones: string;
  secondContactPersonTitle: string;

  isAmazonPrime: boolean;
  isPremiumOrder: boolean;
  isBusinessOrder: boolean;
  shipmentServiceLevelCategory: ShipmentServiceLevelCategory;
  fulfillmentChannel: FulfillmentChannel;
  mock?: string;
  warnings: OrderWarningType[];
  mandatName: string;
  mandatId: number;
  useShippingAutomation: boolean;

  taxAddressRole: string;
  taxReportingScheme: string;
  jurisdictionName: string;

  exchangeRate: number;
  exchangeRateDate: string;
  exchangeRateCurrencyTo: string;
};

function isOrderPayed(orderRow: OrderTableRow): boolean {
  return [PaymentStatus.Paid, PaymentStatus.OverPaid].includes(orderRow.paymentStatus) && orderRow.refundAmount === 0;
}

function isOrderShipped(orderRow: OrderTableRow): boolean {
  return Boolean(orderRow.shippedTime);
}

function isOrderReceived(orderRow: OrderTableRow): boolean {
  return Boolean(orderRow.actualDeliveryTime);
}

export const orderModelExtensions = {
  isOrderPayed,
  isOrderShipped,
  isOrderReceived,
};
