import { ApiRoutingUtils } from '@/Routing';
import {
  CreateGeneratorProductRequest,
  CreateGeneratorProductResponse,
  UpdateGeneratorProductRequest,
  UpdateGeneratorProductResponse,
  GenerateUserProductResponse,
  GetAllGeneratorProductResponse,
} from '.';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('generator-products');

async function getGeneratorProducts(): Promise<GetAllGeneratorProductResponse> {
  return rest.get(url);
}

async function createGeneratorProduct(request: CreateGeneratorProductRequest): Promise<CreateGeneratorProductResponse> {
  return rest.post(url, request);
}

async function updateGeneratorProduct(request: UpdateGeneratorProductRequest): Promise<UpdateGeneratorProductResponse> {
  return rest.put(url, request);
}

async function deleteGeneratorProduct(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

async function generateProducts(id: number): Promise<GenerateUserProductResponse> {
  return rest.post(`${url}/generate/${id}`, null);
}

export const GeneratorProductsApi = {
  getGeneratorProducts,
  createGeneratorProduct,
  updateGeneratorProduct,
  deleteGeneratorProduct,
  generateProducts,
};
