import { RegistrationModel } from '@/Models';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from '@/Hooks/useFormWrapper';

type FormData = RegistrationModel;

export const useSignUpAddressForm = (firstName: string, lastName: string, companyName: string) => {
  const { setValue, watch, register } = useFormContext<FormData>();
  const [needShowReturnAddress, setNeedShowReturnAddress] = useState(false);

  useEffect(() => {
    const companyLocation = watch('companyLocation');
    register('invoiceFirstName');
    register('invoiceLastName');
    register('invoiceCompany');
    register('invoiceCountry');
    register('returnCountry');

    setValue('invoiceFirstName', firstName);
    setValue('invoiceLastName', lastName);
    setValue('invoiceCompany', companyName);
    setValue('invoiceCountry', companyLocation);
  }, []);

  const removeReturnAddress = useCallback(() => {
    setValue('returnCompany', undefined);
    setValue('returnFirstName', undefined);
    setValue('returnLastName', undefined);
    setValue('returnStreet', undefined);
    setValue('returnHouseNo', undefined);
    setValue('returnZip', undefined);
    setValue('returnCity', undefined);
    setValue('returnCountry', undefined);
    setValue('returnPhone', undefined);
  }, []);

  const setReturnCountryAsInvoiceCountry = () => {
    const invoiceCountry = watch('invoiceCountry');

    setValue('returnCountry', invoiceCountry);
  };

  const onReturnAddressButtonClick = () => {
    setNeedShowReturnAddress((prev) => {
      if (prev) {
        removeReturnAddress();
      } else {
        setReturnCountryAsInvoiceCountry();
      }
      return !prev;
    });
  };

  return {
    removeReturnAddress,
    setReturnCountryAsInvoiceCountry,
    needShowReturnAddress,
    onReturnAddressButtonClick,
  };
};
