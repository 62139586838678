import React, { useState } from 'react';
import { Modal } from '@/Components/Modal/Modal';
import { ModalProps } from '@/Types';
import { Button } from '@/Components/Controls/Button/Button';
import { Checkbox } from '@/Components/Controls/Checkbox/Checkbox';
import { Label } from '@/Components/Controls/Label/Label';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { useLocalization } from '@/Hooks';
import { useSelector } from 'react-redux';
import { synchOrdersCompleteModalSelectors } from './services/selectors';
import { stringUtils } from '@/Utils';
import { AppUserSettingNameType } from '@/Enums';
import { userSelectors } from '@/Redux/User';
import { useSynchOrdersCompleteModal } from './hooks/useSynchOrdersCompleteModal';
import styles from './SynchOrdersCompleteModal.scss';

type Props = ModalProps;

export function SynchOrdersCompleteModal({ closeModalWindow }: Props) {
  const {
    buttons,
    modalWindows: { synchOrdersCompleteModal },
  } = useLocalization();

  const [needToRemember, setNeedToRemember] = useState(false);

  const synchronizeSetting = useSelector(userSelectors.getSetting)(AppUserSettingNameType.Synchronize);
  const newSalesCount = useSelector(synchOrdersCompleteModalSelectors.newSalesCount);
  const newPurchasesCount = useSelector(synchOrdersCompleteModalSelectors.newPurchasesCount);
  const updatedSalesCount = useSelector(synchOrdersCompleteModalSelectors.updatedSalesCount);
  const updatedPurchasesCount = useSelector(synchOrdersCompleteModalSelectors.updatedPurchasesCount);

  const { onLater, onUpdate } = useSynchOrdersCompleteModal(
    needToRemember,
    synchronizeSetting,
    newSalesCount,
    newPurchasesCount,
    closeModalWindow,
  );

  if (!newSalesCount && !newPurchasesCount) return null;

  return (
    <Modal onClose={() => null} needCloseIcon={false} className={styles.synchOrdersCompleteModal}>
      <p
        className={styles.synchOrdersCompleteModalText}
        dangerouslySetInnerHTML={{
          __html: stringUtils.insertParamsToString(synchOrdersCompleteModal.Text, [
            newSalesCount,
            updatedSalesCount,
            newPurchasesCount,
            updatedPurchasesCount,
          ]),
        }}
      />

      <div className={styles.synchOrdersCompleteModalButtons}>
        <Button autoFocus={true} text={buttons.Update} theme={BUTTON_THEMES.SUCCESS} onClick={onUpdate} />

        <Button text={buttons.Later} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} onClick={onLater} />
      </div>

      <Label text={synchOrdersCompleteModal.TextForCheckbox} nowrap reverse>
        <Checkbox valueForSet={needToRemember} onChange={(value) => setNeedToRemember(value)} />
      </Label>
    </Modal>
  );
}
