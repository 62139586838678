import { TranslationType } from '@/Localization/LanguageKeys';

export enum MeasurePackageType {
  Size = 'Size',
  DeterminingGirth = 'DeterminingGirth',
  ShortestLongestSize = 'ShortestLongestSize',
}

const getMeasurePackageTypeNameByType = (
  measurePackageType: MeasurePackageType | undefined,
  translation: TranslationType,
): string => {
  if (!measurePackageType) return '';

  const {
    Enums: { LogisticMeasurePackageType: LogisticMeasurePackageTypeLocalization },
  } = translation;

  const measurePackageMap = {
    [MeasurePackageType.Size]: LogisticMeasurePackageTypeLocalization.Size,
    [MeasurePackageType.DeterminingGirth]: LogisticMeasurePackageTypeLocalization.DeterminingGirth,
    [MeasurePackageType.ShortestLongestSize]: LogisticMeasurePackageTypeLocalization.ShortestLongestSize,
  };

  return measurePackageMap[measurePackageType];
};

export const measurePackageTypeUtils = {
  getMeasurePackageTypeNameByType,
};
