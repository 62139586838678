import { RootState } from '@/Redux/RootReducer';
import { AppNotification, NotificationCode, NotificationType } from '@/SignalR/Data';
import { localizationUtils } from '@/Utils/localizationUtils';
import { Toasts } from '@/Components/Toast/Toast';
import { globalSelectors } from '@/Redux/Global/selectors';
import { LogUtil } from '@/Utils';
import { OnlineArchiveNotificationHub } from '@/SignalR/Hubs/OnlineArchiveNotificationHub';

export class OnlineArchiveNotificationSubscriptionInitializer {
  private static instance: OnlineArchiveNotificationSubscriptionInitializer;
  private getState!: () => RootState;
  private _taskHub!: OnlineArchiveNotificationHub;

  constructor(getState: () => RootState) {
    if (OnlineArchiveNotificationSubscriptionInitializer.instance)
      return OnlineArchiveNotificationSubscriptionInitializer.instance;

    OnlineArchiveNotificationSubscriptionInitializer.instance = this;

    this.getState = getState;
    this._taskHub = new OnlineArchiveNotificationHub();
  }

  public initialize() {
    this._taskHub.onNewNotification(this.onNewNotification);
  }

  private onNewNotification = (notification: AppNotification) => {
    this.notificationHandleToast(notification);
  };

  private notificationHandleToast(notification: AppNotification) {
    const { title, message, notificationType, notificationCode, params } = notification;

    let _title = title;
    let _message = message ?? '';
    if (notificationCode != null) {
      const res = this.getNotificationText(notificationCode, params);
      _title = res.title;
      _message = res.text;
    }

    switch (notificationType) {
      case NotificationType.Info:
        Toasts.showInfo({ title: _title, text: _message });
        break;
      case NotificationType.Success:
        Toasts.showSuccess({ title: _title, text: _message });
        break;
      case NotificationType.Error:
        Toasts.showError({ title: _title, text: _message });
        break;
    }
  }

  private getNotificationText = (
    code: string | NotificationCode,
    params: string[] | undefined,
  ): { title: string; text: string } => {
    const translation = globalSelectors.translation(this.getState());
    const localization = globalSelectors.localization(this.getState());

    let notificationTranslation: {
      text: string;
      title: string;
      parts?: { [key: string]: { [key: string]: string } };
    } = { text: '', title: '', parts: {} };
    if (code in translation.Notifications) {
      notificationTranslation = translation.Notifications[code as keyof typeof translation.Notifications];
    }

    if (code in translation.Errors) {
      console.log('code is errors', code);
      notificationTranslation = translation.Errors[code as keyof typeof translation.Errors];
      console.log(notificationTranslation);
    }

    if (!notificationTranslation) {
      LogUtil.LogError(`There is no NotificationCode = ${code} in localization!`);
    }

    const localNotifText = localizationUtils.insertTranslatedParamsToString(
      notificationTranslation.text,
      params || [],
      notificationTranslation.parts,
      localization,
    );

    return { title: notificationTranslation.title, text: localNotifText };
  };
}
