import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';
import { accountChartMapping } from '@/Mapping/_Microservices/OnlineArchive/AccountChartMapping';
import { TreeItemType } from '@/Components/TreeView/TreeView';

const accountChartsDataState = (state: RootState) => state.dynamicData.accountChartsData;

const accountChartsInfo = createSelector(accountChartsDataState, (state) => state.accountChartsInfo);

const accountChartOptions = createSelector(accountChartsDataState, (state) => {
  return accountChartMapping.modelsToTitleWithValuePair(state.accountChartsInfo.accountCharts);
});

const accountChartTreeViewItems = createSelector(accountChartsDataState, (state) => {
  return accountChartMapping.modelsToTitleWithValuePair(state.accountChartsInfo.accountCharts).map(
    ({ key, value }): TreeItemType<number> => ({
      key,
      value,
      selected: false,
    }),
  );
});

const accountChartModalData = createSelector(accountChartsDataState, (state) => state.accountChartModalData);

const accountChartSettingModalData = createSelector(
  accountChartsDataState,
  (state) => state.accountChartSettingModalData,
);

const selectedAccountChart = createSelector(accountChartsDataState, (state) => state.selectedAccountChart);

export const accountChartsDataSelectors = {
  accountChartsInfo,
  accountChartOptions,
  accountChartTreeViewItems,
  accountChartModalData,
  accountChartSettingModalData,
  selectedAccountChart,
};
