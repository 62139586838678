export enum UserSubcategoryType {
  Market = 'Market',
  Market1 = 'Market1',
  Market2 = 'Market2',
  Market3 = 'Market3',
  B2CSmall = 'B2CSmall',
  B2CMedium = 'B2CMedium',
  B2CLarge = 'B2CLarge',
  B2BSoHo = 'B2BSoHo',
  B2BSMB = 'B2BSMB',
  B2BSME = 'B2BSME',
  B2BEnterprise = 'B2BEnterprise',
  Admin1 = 'Admin1',
  Admin2 = 'Admin2',
  Admin3 = 'Admin3',
  Developer1 = 'Developer1',
  Developer2 = 'Developer2',
  Developer3 = 'Developer3',
  Sales1 = 'Sales1',
  Sales2 = 'Sales2',
  Sales3 = 'Sales3',
  SupportService1 = 'SupportService1',
  SupportService2 = 'SupportService2',
  SupportService3 = 'SupportService3',
  Designer1 = 'Designer1',
  Designer2 = 'Designer2',
  Designer3 = 'Designer3',
  Billing1 = 'Billing1',
  Billing2 = 'Billing2',
  Billing3 = 'Billing3',
  Finance1 = 'Finance1',
  Finance2 = 'Finance2',
  Finance3 = 'Finance3',
  Language1 = 'Language1',
  Language2 = 'Language2',
  Language3 = 'Language3',
}
