import React from 'react';

// Hooks import
import { useLocalization } from '@/Hooks';

// Styles import
import styles from './SignUpTerms.scss';

export function SignUpTerms(): React.ReactElement {
  const {
    authorizationPage: {
      SignUp: { UserTerms },
    },
  } = useLocalization();

  return (
    <div className={styles.signUpTerms}>
      <p>
        {UserTerms.ByCreating}
        <a>{UserTerms.UserAgreement}</a>
        {UserTerms.AndThe}
        <a>{UserTerms.UserPrivacyNotice}</a>.
      </p>
    </div>
  );
}
