import { useHistory } from 'react-router';

export function usePageNavigation() {
  const history = useHistory();
  const createGoToPage = (path: string) => () => history.push(path);

  return {
    createGoToPage,
  };
}
