import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentTemplateApi, GetAllDocumentTemplateResponse } from '@/Api/DocumentTemplates';
import { DocumentTemplatePrintType } from '@/Models';
import { DocumentTemplateType } from '@/Enums/DocumentTemplateType';
import { GetDocumentsForPrintResponse } from '@/Api/_Microservices/OnlineArchive/Documents/GetDocumentsForPrintResponse';
import { GetDocumentsForPrintRequest } from '@/Api/_Microservices/OnlineArchive/Documents/GetDocumentsForPrintRequest';
import { DocumentsApi } from '@/Api/_Microservices/OnlineArchive/Documents';

const getTemplatesRequest = createAsyncThunk<GetAllDocumentTemplateResponse, DocumentTemplatePrintType>(
  'documentPrintModalSlice/getAll',
  async (documentType: DocumentTemplatePrintType) => {
    return DocumentTemplateApi.getAllDocumentTemplates(DocumentTemplateType.Document, documentType);
  },
);

const getDocumentsForPrint = createAsyncThunk<GetDocumentsForPrintResponse, GetDocumentsForPrintRequest>(
  'documentPrintModalSlice/get-documents-for-print',
  async (request) => {
    return DocumentsApi.getDocumentsForPrint(request);
  },
);

export const asyncActions = {
  getTemplatesRequest,
  getDocumentsForPrint,
};
