import React, { PropsWithChildren } from 'react';
import { ClassNameProps } from '@/Types';
import styles from './ProgressList.scss';
import cn from 'classnames';

type Props = ClassNameProps;

export const ProgressList: React.FC<Props> = ({ children, className }: PropsWithChildren<Props>) => {
  return <div className={cn(styles.list, className)}>{children}</div>;
};
