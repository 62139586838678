import { useLocalization } from '@/Hooks';

export const useShouldBeDecimal = () => {
  const { validation } = useLocalization();

  return {
    pattern: {
      value: /^-?\d+(\.\d{1,5})?$/,
      message: validation.ValueMustBeNumber,
    },
  };
};
