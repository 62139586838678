import { DigitalProductModel, FunctionGroupModel } from '@/Models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DigitalProductsApi,
  CreateDigitalProductRequest,
  CreateDigitalProductResponse,
  UpdateDigitalProductRequest,
  UpdateDigitalProductResponse,
} from '@/Api/DigitalProducts';
import { FunctionsApi } from '@/Api/Functions/FunctionsApi';
import { GetTemplateSettingsResponse } from '@/Api/DocumentTemplateAdminSettings/Responses/GetTemplateSettingsResponse';
import { DocumentTemplateAdminSettingsApi } from '@/Api/DocumentTemplateAdminSettings/DocumentTemplateAdminSettingsApi';
import { CreateTemplateSettingsResponse } from '@/Api/DocumentTemplateAdminSettings/Responses/CreateTemplateSettingsResponse';
import { CreateTemplateSettingsRequest } from '@/Api/DocumentTemplateAdminSettings/Requests/CreateTemplateSettingsRequest';
import { UpdateTemplateSettingsResponse } from '@/Api/DocumentTemplateAdminSettings/Responses/UpdateTemplateSettingsResponse';
import { UpdateTemplateSettingsRequest } from '@/Api/DocumentTemplateAdminSettings/Requests/UpdateTemplateSettingsRequest';
import { DeleteTemplateSettingsResponse } from '@/Api/DocumentTemplateAdminSettings/Responses/DeleteTemplateSettingsResponse';
import { DeleteTemplateSettingsRequest } from '@/Api/DocumentTemplateAdminSettings/Requests/DeleteTemplateSettingsRequest';
import { GetTemplatesCreatedByMarketUserResponse } from '@/Api/DocumentTemplates/Responses/GetTemplatesCreatedByMarketUserResponse';
import { GetTemplatesCreatedByMarketUserRequest } from '@/Api/DocumentTemplates/Requests/GetTemplatesCreatedByMarketUserRequest';
import { DocumentTemplateApi } from '@/Api/DocumentTemplates';

const getDigitalProducts = createAsyncThunk<DigitalProductModel[], void>(
  'digitalProductsPage/get-digital-products',
  async () => {
    return DigitalProductsApi.getDigitalProducts();
  },
);

const createDigitalProduct = createAsyncThunk<CreateDigitalProductResponse, CreateDigitalProductRequest>(
  'digitalProductsPage/create-digital-product',
  async (request: CreateDigitalProductRequest) => {
    return DigitalProductsApi.createDigitalProduct(request);
  },
);

const updateDigitalProduct = createAsyncThunk<UpdateDigitalProductResponse, UpdateDigitalProductRequest>(
  'digitalProductsPage/update-digital-product',
  async (request: UpdateDigitalProductRequest) => {
    return DigitalProductsApi.updateDigitalProduct(request);
  },
);

const deleteDigitalProduct = createAsyncThunk<number, number>(
  'digitalProductsPage/delete-digital-product',
  async (id: number) => {
    return DigitalProductsApi.deleteDigitalProduct(id);
  },
);

const getFunctions = createAsyncThunk<FunctionGroupModel[], void>('digitalProductsPage/get-functions', async () => {
  return FunctionsApi.getFunctions();
});

// Template settings
const getTemplateSettings = createAsyncThunk<GetTemplateSettingsResponse>(
  'digitalProductsPage/get-template-settings',
  async () => {
    return DocumentTemplateAdminSettingsApi.getTemplateSettings();
  },
);

const getTemplatesCreatedByMarket = createAsyncThunk<
  GetTemplatesCreatedByMarketUserResponse,
  GetTemplatesCreatedByMarketUserRequest
>('digitalProductsPage/get-templates-created-by-market', async (request) => {
  return DocumentTemplateApi.getTemplatesCreatedByMarketUser(request);
});

const createTemplateSettings = createAsyncThunk<CreateTemplateSettingsResponse, CreateTemplateSettingsRequest>(
  'digitalProductsPage/create-template-settings',
  async (request) => {
    return DocumentTemplateAdminSettingsApi.createTemplateSettings(request);
  },
);

const updateTemplateSettings = createAsyncThunk<UpdateTemplateSettingsResponse, UpdateTemplateSettingsRequest>(
  'digitalProductsPage/update-template-settings',
  async (request) => {
    return DocumentTemplateAdminSettingsApi.updateTemplateSettings(request);
  },
);

const deleteTemplateSettings = createAsyncThunk<DeleteTemplateSettingsResponse, DeleteTemplateSettingsRequest>(
  'digitalProductsPage/delete-template-settings',
  async (request) => {
    return DocumentTemplateAdminSettingsApi.deleteTemplateSettings(request);
  },
);

export const asyncActions = {
  getDigitalProducts,
  createDigitalProduct,
  updateDigitalProduct,
  deleteDigitalProduct,
  getFunctions,
  getTemplateSettings,
  getTemplatesCreatedByMarket,
  createTemplateSettings,
  updateTemplateSettings,
  deleteTemplateSettings,
};
