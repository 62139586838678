import { NumberGenerationType } from '@/Enums';
import { useLocalization, useSelectedOrdersRows } from '@/Hooks';
import { generateDocumentNumberConfirmationModalSelectors } from '@/ModalWindows/GenerateDocumentNumberConfirmationModal/services/selectors';
import { modalWindowsSelectors } from '@/ModalWindows/services';
import { allocateSectionAsyncActions, allocateSectionSelectors } from '@/Pages/OnlineArchive/modules/Allocate/services';
import { purchasesPageAsyncActions } from '@/Pages/Purchases/services';
import { salesPageAsyncActions } from '@/Pages/Sales/services';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
export function useAlreadyExistsDocumentNumberConfirmation() {
  const dispatch: AppDispatch = useDispatch();
  const { orderIds, isSalesPage, isPurchasesPage } = useSelectedOrdersRows();
  const numberGenerationRequest = useSelector(generateDocumentNumberConfirmationModalSelectors.numberGenerateRequest);
  const documentInfoUpdated = useSelector(allocateSectionSelectors.documentInfoUpdated);
  const [visibleOnlineArchiveModalWindow, setVisibleOnlineArchiveModalWindow] = useState(false);

  const currentModalParams = useSelector(modalWindowsSelectors.modalParams);

  const {
    modalWindows: { generateAlreadyExistsDocumentNumbers },
  } = useLocalization();

  const confirmationText = generateAlreadyExistsDocumentNumbers.ConfirmationText;

  const onGenerateAlreadyExistsNumberClick = useCallback(async () => {
    if (!isSalesPage && !isPurchasesPage) {
      if (documentInfoUpdated)
        dispatch(
          allocateSectionAsyncActions.updateDocuments({
            documentInfos: [documentInfoUpdated],
            isForceUpdate: true,
          }),
        );
      return;
    }

    if (orderIds.length <= 0 || numberGenerationRequest == null) return;

    if (isSalesPage) {
      dispatch(salesPageAsyncActions.generateNumberForOrder(numberGenerationRequest));
    } else if (isPurchasesPage && numberGenerationRequest?.genType === NumberGenerationType.PurchaseOrder) {
      dispatch(purchasesPageAsyncActions.generateNumberForOrder(numberGenerationRequest));
    } // if
  }, [isSalesPage, isPurchasesPage, orderIds, numberGenerationRequest]);

  const onNoOrCancelClick = useCallback(async () => {
    setVisibleOnlineArchiveModalWindow(true);
  }, [orderIds, numberGenerationRequest]);

  return {
    onGenerateAlreadyExistsNumberClick,
    onNoOrCancelClick,
    confirmationText: confirmationText,
    modalWindowTitle: generateAlreadyExistsDocumentNumbers.ModalWindowTitle,
    visibleOnlineArchiveModalWindow,
    currentModalParams,
    setVisibleOnlineArchiveModalWindow,
  };
}
