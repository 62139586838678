import { DataTableSource } from '@/Components/DataTableV2/services/types';
import { userProductsCartMapping } from '@/Mapping/UserProductsCartMapping';
import { CustomerShoppingCardModel } from '@/Models/CustomerShoppingCardModel';
import { CustomerShoppingCardTableRow } from '@/Types/CustomerShoppingCardTableRow';
import { createSlice } from '@reduxjs/toolkit';
import { customerCartItemsAsyncActions } from './asyncActions';

export type CustomerCartPageState = {
  customerCartItems: CustomerShoppingCardModel[];
  customerCartItemsDataSource: DataTableSource<CustomerShoppingCardTableRow> | null;
};

const initialState: CustomerCartPageState = {
  customerCartItems: [],
  customerCartItemsDataSource: null,
};

const customerCartPageSlice = createSlice({
  name: 'customerCartsPage',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(customerCartItemsAsyncActions.getCustomerCartItems.fulfilled, (state, action) => {
        state.customerCartItems = action.payload.userProductsCarts;
        state.customerCartItemsDataSource = new DataTableSource<CustomerShoppingCardTableRow>(
          'id',
          state.customerCartItems.map((item) => userProductsCartMapping.modelToAllocateTableRow(item)),
        );
      })
      .addCase(customerCartItemsAsyncActions.getUserProductCartForUser.fulfilled, (state, action) => {
        state.customerCartItems = action.payload.userProductsCarts;
        state.customerCartItemsDataSource = new DataTableSource<CustomerShoppingCardTableRow>(
          'id',
          state.customerCartItems.map((item) => userProductsCartMapping.modelToAllocateTableRow(item)),
        );
      });
  },
});

export const customerCartPageReducer = customerCartPageSlice.reducer;
export const customerCartPageActions = customerCartPageSlice.actions;
