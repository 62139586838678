import { rest } from 'src/Api/Rest';
import { ApiRoutingUtils } from 'src/Routing';
import { ScanBarcodeExternalRequest } from './Requests/ScanBarcodeExternalRequest';
import { ScanBarcodeExternalResponse } from './Responses/ScanBarcodeExternalResponse';
import { SetBatchOutWarehouseRequest } from './Requests/SetBatchOutWarehouseRequest';
import { SetBatchOutWarehouseResponse } from './Responses/SetBatchOutWarehouseResponse';
import { GetExternalTransferBatchResponse } from './Responses/GetExternalTransferBatchResponse';
import { GetExternalTransferBatchRequest } from './Requests/GetExternalTransferBatchRequest';

const transferUrl = ApiRoutingUtils.createUrl('user-warehouse-external-transfer');

async function scanBarcode(request: ScanBarcodeExternalRequest): Promise<ScanBarcodeExternalResponse> {
  return rest.post(`${transferUrl}/scan-barcode`, request);
}

async function setBatchOutWarehouse(request: SetBatchOutWarehouseRequest): Promise<SetBatchOutWarehouseResponse> {
  return rest.post(`${transferUrl}/set-out-warehouse`, request);
}

async function getBatch(request: GetExternalTransferBatchRequest): Promise<GetExternalTransferBatchResponse> {
  return rest.get(transferUrl, request);
}

export const WarehouseExternalTransferApi = {
  scanBarcode,
  setBatchOutWarehouse,
  getBatch,
};
