import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { UserProductDuplicatedModel, GeneratorProductCountryModel, GeneratorProductModel } from '@/Models';
import { GeneratorProductModalType, InternationalType } from '@/Enums';
import { Pair } from '@/Types';
import { GeneratorProductsApi } from '@/Api/GeneratorProducts/GeneratorProductsApi';
import {
  CreateGeneratorProductRequest,
  CreateGeneratorProductResponse,
  GenerateUserProductResponse,
  UpdateGeneratorProductRequest,
  UpdateGeneratorProductResponse,
} from '@/Api/GeneratorProducts';

const getGeneratorProductsRequest = createAsyncThunk('generatorProducts/get-generator-products', async () => {
  return GeneratorProductsApi.getGeneratorProducts();
});

const createGeneratorProductRequest = createAsyncThunk<CreateGeneratorProductResponse, CreateGeneratorProductRequest>(
  'generatorProducts/create-generator-product',
  async (request: CreateGeneratorProductRequest) => {
    const response = await GeneratorProductsApi.createGeneratorProduct(request);
    return response;
  },
);

const updateGeneratorProductRequest = createAsyncThunk<UpdateGeneratorProductResponse, UpdateGeneratorProductRequest>(
  'generatorProducts/update-generator-product',
  async (request: UpdateGeneratorProductRequest) => {
    const response = await GeneratorProductsApi.updateGeneratorProduct(request);
    return response;
  },
);

const deleteGeneratorProductRequest = createAsyncThunk<number, number>(
  'generatorProducts/delete-generator-product',
  async (id: number) => {
    return GeneratorProductsApi.deleteGeneratorProduct(id);
  },
);

const generateProductsRequest = createAsyncThunk<GenerateUserProductResponse, number>(
  'generatorProducts/generate-products',
  async (id: number) => {
    return GeneratorProductsApi.generateProducts(id);
  },
);

const updateGeneratorProduct = (
  templates: GeneratorProductCountryModel[],
  model: GeneratorProductModel,
  selectedTemplateUuid = '',
) => {
  const templateCountry = templates.find((x) => x.country === model.countryCode);

  if (templateCountry) {
    const templateLogistic = templateCountry.logistics.find((x) => x.logisticId == model.logisticTypeId);
    if (templateLogistic) {
      templateLogistic.templates = templateLogistic.templates.map((x) => {
        if (selectedTemplateUuid !== '') {
          return x.uuid == selectedTemplateUuid ? model : x;
        }
        return x.id === model.id ? model : x;
      });
    }
  }
};

export type GeneratorProductState = {
  templates: GeneratorProductCountryModel[];
  selectedTemplateUuid: string;
  logisticTypeOptions: Pair<string | number>[];
  modalWindowType: GeneratorProductModalType;
  duplicatedProducts: UserProductDuplicatedModel[];
};

const initialState: GeneratorProductState = {
  templates: [],
  selectedTemplateUuid: '',
  logisticTypeOptions: [],
  modalWindowType: GeneratorProductModalType.None,
  duplicatedProducts: [],
};

const generationProductsSlice = createSlice({
  name: 'GeneratorProductsState',
  initialState,
  reducers: {
    setLogisticTypes(state, action: PayloadAction<Pair<string | number>[]>) {
      state.logisticTypeOptions = action.payload;
    },
    setSelectedTemplateUuid(state, action: PayloadAction<string>) {
      state.selectedTemplateUuid = action.payload;
    },
    addGeneratorProduct(state, action: PayloadAction<GeneratorProductModel>) {
      const templateCountry = state.templates.find((x) => x.country === action.payload.countryCode);
      if (templateCountry) {
        let templateLogistic = templateCountry.logistics.find((x) => x.logisticId == action.payload.logisticTypeId);
        if (templateLogistic) {
          templateLogistic.templates.push(action.payload);
        } else {
          templateLogistic = {
            country: action.payload.countryCode,
            logisticId: Number(action.payload.logisticTypeId),
            templates: [action.payload],
          };
          templateCountry.logistics.push(templateLogistic);
        }
      }
    },
    deleteGeneratorProduct(state, action: PayloadAction<GeneratorProductModel>) {
      const templateCountry = state.templates.find((x) => x.country === action.payload.countryCode);
      if (templateCountry) {
        const templateLogistic = templateCountry.logistics.find((x) => x.logisticId == action.payload.logisticTypeId);
        if (templateLogistic) {
          templateLogistic.templates = templateLogistic.templates.filter((x) => x.uuid != action.payload.uuid);

          if (templateLogistic.templates.length === 0) {
            templateCountry.logistics = templateCountry.logistics.filter((x) => x.country != templateLogistic.country);
          }
        }
      }
    },
    resetToDomesticGeneratorProduct(state, action: PayloadAction<GeneratorProductModel>) {
      const templateCountry = state.templates.find((x) => x.country === action.payload.countryCode);
      if (templateCountry) {
        const templateLogistic = templateCountry.logistics.find((x) => x.logisticId == action.payload.logisticTypeId);
        if (templateLogistic) {
          const template = templateLogistic.templates.find((x) => x.uuid === action.payload.uuid);
          if (template) {
            template.internationalType = InternationalType.Domestic;
            template.countryZoneRecepientId = '';
          }
        }
      }
    },
    openModal(state, action: PayloadAction<GeneratorProductModalType>) {
      state.modalWindowType = action.payload;
    },
    closeModal(state) {
      state.modalWindowType = GeneratorProductModalType.None;
    },
    clearData(state) {
      state.logisticTypeOptions = [];
      state.templates = [];
      state.modalWindowType = GeneratorProductModalType.None;
      state.selectedTemplateUuid = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneratorProductsRequest.fulfilled, (state, action) => {
        state.templates = action.payload.templates.sort((x, y) => (x.countryName > y.countryName ? 1 : -1));
      })
      .addCase(createGeneratorProductRequest.fulfilled, (state, action) => {
        updateGeneratorProduct(state.templates, action.payload.template, state.selectedTemplateUuid);
        state.selectedTemplateUuid = '';
      })
      .addCase(updateGeneratorProductRequest.fulfilled, (state, action) => {
        updateGeneratorProduct(state.templates, action.payload.template);
        state.selectedTemplateUuid = '';
      })
      .addCase(deleteGeneratorProductRequest.fulfilled, (state, action) => {
        state.templates.forEach((x) => {
          x.logistics.forEach((y) => (y.templates = y.templates.filter((z) => z.id !== action.payload)));
          x.logistics = x.logistics.filter((y) => y.templates.length !== 0);
        });
      })
      .addCase(generateProductsRequest.fulfilled, (state, action) => {
        if (action.payload.duplicatedProducts.length > 0) {
          state.duplicatedProducts = action.payload.duplicatedProducts;
          state.modalWindowType = GeneratorProductModalType.UserProductDuplicated;
        } else {
          state.duplicatedProducts = [];
        }

        updateGeneratorProduct(state.templates, action.payload.template);
      });
  },
});

const { actions, reducer } = generationProductsSlice;

export const generatorProductsActions = actions;
export const generatorProductsReducer = reducer;
export const generatorProductsAsyncActions = {
  getGeneratorProductsRequest,
  createGeneratorProductRequest,
  updateGeneratorProductRequest,
  deleteGeneratorProductRequest,
  generateProductsRequest,
};
