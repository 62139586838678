import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { CountryCode } from '@/Enums';
import { GetAllApplicationAccessCountryZoneResponse } from './GetAllApplicationAccessCountryZoneResponse';
import { GetAllApplicationAccessCountryZoneByCountryResponse } from './GetAllApplicationAccessCountryZoneByCountryResponse';
import { CreateApplicationAccessCountryZoneRequest } from './CreateApplicationAccessCountryZoneRequest';
import { CreateApplicationAccessCountryZoneResponse } from './CreateApplicationAccessCountryZoneResponse';
import { UpdateApplicationAccessCountryZoneRequest } from './UpdateApplicationAccessCountryZoneRequest';
import { UpdateApplicationAccessCountryZoneResponse } from './UpdateApplicationAccessCountryZoneResponse';
import { CheckApplicationAccessConnectionResponse } from './CheckApplicationAccessConnectionResponse';
import { CheckApplicationAccessConnectionRequest } from './CheckApplicationAccessConnectionRequest';

const url = ApiRoutingUtils.createUrl('application-access-country-zones');

async function getApplicationAccessCountryZones(): Promise<GetAllApplicationAccessCountryZoneResponse> {
  return rest.get(url);
}

async function getApplicationAccessCountryZonesGroupedByCountry(
  countryCode: CountryCode,
): Promise<GetAllApplicationAccessCountryZoneByCountryResponse> {
  return rest.get(`${url}/grouped-by-country/${countryCode}`);
}

async function createApplicationAccessCountryZone(
  request: CreateApplicationAccessCountryZoneRequest,
): Promise<CreateApplicationAccessCountryZoneResponse> {
  return rest.post(url, request);
}

async function updateApplicationAccessCountryZone(
  request: UpdateApplicationAccessCountryZoneRequest,
): Promise<UpdateApplicationAccessCountryZoneResponse> {
  return rest.put(url, request);
}

async function deleteApplicationAccessCountryZone(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

async function checkApplicationAccessConnection(
  request: CheckApplicationAccessConnectionRequest,
): Promise<CheckApplicationAccessConnectionResponse> {
  return rest.post(`${url}/check-connection`, request);
}

export const ApplicationAccessCountryZonesApi = {
  getApplicationAccessCountryZones,
  getApplicationAccessCountryZonesGroupedByCountry,
  createApplicationAccessCountryZone,
  updateApplicationAccessCountryZone,
  deleteApplicationAccessCountryZone,
  checkApplicationAccessConnection,
};
