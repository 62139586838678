import { PageModalType, PaymentMethod } from '@/Enums';
import { PaymentInfoModel } from '@/Models/PaymentInfoModel';
import { Nullable } from '@/Types/Nullable';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paymentMethodsAsyncActions } from './asyncActions';
import { UserPaymentMethodModel } from '@/Models/UserPaymentMethodModel';

export type PaymentMethodsState = {
  paymentMethods: UserPaymentMethodModel[];
  marketUserPaymentInfo: Nullable<PaymentInfoModel>;
  paymentMethodModal: { modalType: PageModalType; paymentMethod?: PaymentMethod };
};

const initialState: PaymentMethodsState = {
  paymentMethods: [],
  marketUserPaymentInfo: null,
  paymentMethodModal: { modalType: PageModalType.None },
};

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setPaymentMethodModalType: (
      state,
      action: PayloadAction<{ modalType: PageModalType; paymentMethod?: PaymentMethod }>,
    ) => {
      state.paymentMethodModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(paymentMethodsAsyncActions.createPaymentMethod.fulfilled, (state, action) => {
      if (action.payload.paymentMethod.directDebit) {
        state.paymentMethods = state.paymentMethods.filter((p) => !p.directDebit);
      }
      state.paymentMethods = [...state.paymentMethods, action.payload.paymentMethod];
    });
    builder.addCase(paymentMethodsAsyncActions.getUserPaymentMethods.fulfilled, (state, action) => {
      state.paymentMethods = action.payload.paymentMethods;
    });
    builder.addCase(paymentMethodsAsyncActions.deletePaymentMethod.fulfilled, (state, action) => {
      state.paymentMethods = state.paymentMethods.filter((p) => p.id !== action.payload);
    });
    builder.addCase(paymentMethodsAsyncActions.getMarketUserPaymentInfo.fulfilled, (state, action) => {
      state.marketUserPaymentInfo = action.payload;
    });
  },
});

const { actions, reducer } = paymentMethodsSlice;

export const paymentMethodsActions = actions;
export const paymentMethodsReducer = reducer;
