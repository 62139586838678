import { OrderType } from '@/Enums/OrderType';
import { UseEnumOptions } from '@/Types';
import { useLocalization } from '@/Hooks';
import { useMemo } from 'react';
import { OrderMarker } from '@/Enums/OrderMarker';

export function useOrderMarkerOptions(orderType: OrderType, excludedMarkers: OrderMarker[] = []): UseEnumOptions {
  const {
    enums: { OrderMarker: OrderMarkerLocalization },
  } = useLocalization();

  const result = useMemo(() => {
    let dynamicItems;

    switch (orderType) {
      case OrderType.Sale:
        dynamicItems = [
          { key: OrderMarker.Shipped, value: OrderMarkerLocalization.Shipped },
          { key: OrderMarker.NotShipped, value: OrderMarkerLocalization.NotShipped },
        ];
        break;

      case OrderType.Purchase:
        dynamicItems = [
          { key: OrderMarker.Received, value: OrderMarkerLocalization.Received },
          { key: OrderMarker.NotReceived, value: OrderMarkerLocalization.NotReceived },
        ];
        break;

      default:
        dynamicItems = [
          { key: OrderMarker.Shipped, value: OrderMarkerLocalization.Shipped },
          { key: OrderMarker.NotShipped, value: OrderMarkerLocalization.NotShipped },
          { key: OrderMarker.Received, value: OrderMarkerLocalization.Received },
          { key: OrderMarker.NotReceived, value: OrderMarkerLocalization.NotReceived },
        ];
        break;
    }

    const markers = [
      { key: OrderMarker.Paid, value: OrderMarkerLocalization.Paid },
      { key: OrderMarker.NotPaid, value: OrderMarkerLocalization.NotPaid },
      ...dynamicItems,
      { key: OrderMarker.Notified, value: OrderMarkerLocalization.Notified },
      { key: OrderMarker.NotNotified, value: OrderMarkerLocalization.NotNotified },
      { key: OrderMarker.CanceledNotPaid, value: OrderMarkerLocalization.CanceledNotPaid },
      { key: OrderMarker.NotCanceledNotPaid, value: OrderMarkerLocalization.NotCanceledNotPaid },
      { key: OrderMarker.RefundedPaid, value: OrderMarkerLocalization.RefundedPaid },
      { key: OrderMarker.NotRefundedPaid, value: OrderMarkerLocalization.NotRefundedPaid },
      { key: OrderMarker.RefundedAndNotReturned, value: OrderMarkerLocalization.RefundedAndNotReturned },
      { key: OrderMarker.NotRefundedAndNotReturned, value: OrderMarkerLocalization.NotRefundedAndNotReturned },
      { key: OrderMarker.Archived, value: OrderMarkerLocalization.Archived },
      { key: OrderMarker.NotArchived, value: OrderMarkerLocalization.NotArchived },
      { key: OrderMarker.Exported, value: OrderMarkerLocalization.Exported },
      { key: OrderMarker.NotExported, value: OrderMarkerLocalization.NotExported },
      { key: OrderMarker.FeedbackSubmitted, value: OrderMarkerLocalization.FeedbackSubmitted },
      { key: OrderMarker.NotFeedbackSubmitted, value: OrderMarkerLocalization.NotFeedbackSubmitted },
      { key: OrderMarker.OrderEditEnabled, value: OrderMarkerLocalization.OrderEditEnabled },
      { key: OrderMarker.OrderEditDisabled, value: OrderMarkerLocalization.OrderEditDisabled },
      { key: OrderMarker.AddressEditEnabled, value: OrderMarkerLocalization.AddressEditEnabled },
      { key: OrderMarker.AddressEditDisabled, value: OrderMarkerLocalization.AddressEditDisabled },
    ];

    return markers.filter((m) => !excludedMarkers.includes(m.key));
  }, [orderType, excludedMarkers]);

  return {
    options: result,
    placeholder: OrderMarkerLocalization.Placeholder,
    itemUnitName: OrderMarkerLocalization.ItemUnitName,
  };
}
