import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/Redux/RootReducer';

const documentPrintModalState = (state: RootState) => state.modalWindows.documentPrintModalState;

const rows = createSelector(documentPrintModalState, (state) => state.rows);

const selectedRow = createSelector(documentPrintModalState, (state) => state.selectedRow);

const documentNumbers = createSelector(documentPrintModalState, (state) => state.documentNumbers);

const selectedDocumentNumbers = createSelector(documentPrintModalState, (state) => state.selectedDocumentNumbers);

export const documentPrintModalSelectors = {
  rows,
  selectedRow,
  documentNumbers,
  selectedDocumentNumbers,
};
