export enum OrderStatusFilter {
  // Purchases
  // Statuses group
  PaidNotReceived = 'PaidNotReceived',
  NotPaidNotReceived = 'NotPaidNotReceived',
  ReceivedToday = 'ReceivedToday',
  ReceivedOn = 'ReceivedOn',
  ReceivedNotFeedbackReceived = 'ReceivedNotFeedbackReceived',
  ReceivedNoFeedbackSubmitted = 'ReceivedNoFeedbackSubmitted',

  // Sales
  // Payment group
  NotPaidAll = 'NotPaidAll',
  NotPaidOverdue = 'NotPaidOverdue',
  NotPaidArchived = 'NotPaidArchived',
  NotPaidFulfillment = 'NotPaidFulfillment',
  // Shipping group
  NotShippedAll = 'NotShippedAll',
  NotShippedFulfillment = 'NotShippedFulfillment',
  NotShippedShippingToday = 'NotShippedShippingToday',
  NotShippedShippingLater = 'NotShippedShippingLater',
  NotShippedShippingOverdue = 'NotShippedShippingOverdue',
  NotShippedNoShippingRequired = 'NotShippedNoShippingRequired',
  NotShippedArchived = 'NotShippedArchived',
  ShippedToday = 'ShippedToday',
  ShippedOn = 'ShippedOn',

  // Shared
  FeedbackReceivedButNotSubmitted = 'FeedbackReceivedButNotSubmitted',
  FeedbackSubmittedButNotReceived = 'FeedbackSubmittedButNotReceived',
  AllNotArchived = 'AllNotArchived',
  AllArchived = 'AllArchived',
  All = 'All',
}

const NotShippedGroup = [
  OrderStatusFilter.NotShippedAll,
  OrderStatusFilter.NotShippedFulfillment,
  OrderStatusFilter.NotShippedShippingToday,
  OrderStatusFilter.NotShippedShippingLater,
  OrderStatusFilter.NotShippedShippingOverdue,
  OrderStatusFilter.NotShippedNoShippingRequired,
  OrderStatusFilter.NotShippedArchived,
] as const;

const ShippedGroup = [OrderStatusFilter.ShippedToday, OrderStatusFilter.ShippedOn] as const;

const NotPaidGroup = [
  OrderStatusFilter.NotPaidAll,
  OrderStatusFilter.NotPaidOverdue,
  OrderStatusFilter.NotPaidArchived,
  OrderStatusFilter.NotPaidFulfillment,
  OrderStatusFilter.NotPaidNotReceived,
] as const;

export const OrderStatusFilterGroups = {
  NotShippedGroup,
  ShippedGroup,
  NotPaidGroup,
};
