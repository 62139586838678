import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';

const allocateSection = (state: RootState) => state.onlineArchivePage.allocateSection;

const documentInfos = createSelector(allocateSection, (state) => state.documentInfos);

const documentInfosDataSource = createSelector(allocateSection, (state) => state.documentInfosDataSource);

const selectedDocumentInfo = createSelector(allocateSection, (state) => state.selectedDocumentInfo);

const selectedDocumentInfoIds = createSelector(allocateSection, (state) => state.selectedDocumentInfoIds);

const selectedFolder = createSelector(allocateSection, (state) => state.selectedFolder);

const documentsModalDialog = createSelector(allocateSection, (state) => state.documentsModalDialog);

const documentInfoModal = createSelector(allocateSection, (state) => state.documentInfoModal);

const documentInfoUpdated = createSelector(allocateSection, (state) => state.documentInfoUpdated);

export const allocateSectionSelectors = {
  documentInfos,
  documentInfosDataSource,
  selectedDocumentInfo,
  selectedDocumentInfoIds,
  selectedFolder,
  documentsModalDialog,
  documentInfoModal,
  documentInfoUpdated,
};
