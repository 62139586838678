import { createSelector } from '@reduxjs/toolkit';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { ColoredIconInfo, IconColor } from '@/Types';
import { FeedbackRatingType } from '@/Enums';

export const salePaymentIconInfo = createSelector(
  purchasesPageSelectors.selectedPurchase,
  (purchase): ColoredIconInfo => {
    if (!purchase) {
      return {
        color: IconColor.Grey,
        tooltipText: '',
      };
    }

    const orderPaid = purchase.amountPaid > 0;
    const paymentRefund = purchase.refundAmount < 0;

    if (!orderPaid && !paymentRefund) {
      return {
        color: IconColor.Grey,
        tooltipText: 'Order not paid',
      };
    }

    if (orderPaid && paymentRefund) {
      return {
        color: IconColor.Orange,
        tooltipText: `Order paid on ${purchase.paidTime} and partial refunded on ${purchase.refundTime}`,
      };
    }

    if (!orderPaid && paymentRefund) {
      return {
        color: IconColor.Red,
        tooltipText: `Order paid on ${purchase.paidTime} and refunded on ${purchase.refundTime}`,
      };
    }

    if (orderPaid && purchase.amountPaid < purchase.priceBruttoWithShipping) {
      return {
        color: IconColor.Yellow,
        tooltipText: 'Order not paid in full',
      };
    }

    if (orderPaid && purchase.amountPaid === purchase.priceBruttoWithShipping) {
      return {
        color: IconColor.Blue,
        tooltipText: `Order paid on ${purchase.paidTime}`,
      };
    }

    if (orderPaid && purchase.amountPaid > purchase.priceBruttoWithShipping) {
      return {
        color: IconColor.Lila,
        tooltipText: `Order overpaid on ${purchase.paidTime}`,
      };
    }

    return {
      color: IconColor.Grey,
      tooltipText: 'Order not paid',
    };
  },
);

export const saleShippingIconInfo = createSelector(
  purchasesPageSelectors.selectedPurchase,
  (purchase): ColoredIconInfo => {
    if (!purchase) {
      return {
        color: IconColor.Grey,
        tooltipText: '',
      };
    }

    if (!purchase?.shippedTime) {
      return {
        color: IconColor.Grey,
        tooltipText: 'Not shipped',
      };
    }

    return {
      color: IconColor.Blue,
      tooltipText: `Shipped on ${purchase?.shippedTime}`,
    };
  },
);

const sellerFeedbackIconInfo = createSelector(
  purchasesPageSelectors.selectedPurchase,
  (purchase): ColoredIconInfo => {
    if (!purchase) {
      return {
        color: IconColor.Grey,
        tooltipText: '',
      };
    }

    if (!purchase?.sellerFeedbackTime) {
      return {
        color: IconColor.Grey,
        tooltipText: 'Not submitted Feedback',
      };
    }

    return {
      color: IconColor.Blue,
      tooltipText: `Feedback submitted on ${purchase.sellerFeedbackTime}`,
    };
  },
);

const buyerFeedbackIconInfo = createSelector(
  purchasesPageSelectors.selectedPurchase,
  (purchase): ColoredIconInfo => {
    if (!purchase) {
      return {
        color: IconColor.Grey,
        tooltipText: '',
      };
    }

    if (!purchase?.buyerFeedbackTime) {
      return {
        color: IconColor.Grey,
        tooltipText: 'Not received Feedback',
      };
    }

    if (purchase?.buyerFeedbackTime && purchase?.buyerFeedbackType == FeedbackRatingType.Positive) {
      return {
        color: IconColor.Blue,
        tooltipText: `Feedbach received on ${purchase?.buyerFeedbackTime}`,
      };
    }

    if (purchase?.buyerFeedbackTime && purchase?.buyerFeedbackType == FeedbackRatingType.Neutral) {
      return {
        color: IconColor.Yellow,
        tooltipText: `Feedbach received on ${purchase?.buyerFeedbackTime}`,
      };
    }

    if (purchase?.buyerFeedbackTime && purchase?.buyerFeedbackType == FeedbackRatingType.Negative) {
      return {
        color: IconColor.Red,
        tooltipText: `Feedbach received on ${purchase?.buyerFeedbackTime}`,
      };
    }

    return {
      color: IconColor.Grey,
      tooltipText: 'Not received Feedback',
    };
  },
);

export const tableButtonsSectionSelectors = {
  salePaymentIconInfo,
  sellerFeedbackIconInfo,
  buyerFeedbackIconInfo,
  saleShippingIconInfo,
};
