import React, { useEffect, useState } from 'react';
import { Nullable, OrderAutomatizaionRow } from '@/Types';
import styles from './ShipCheaperLogistic.scss';
import { LogisticProductSelect } from '../_Shared/LogisticProductSelect/LogisticProductSelect';
import cn from 'classnames';
import { Icon } from '@/Components/Icon/Icon';
import {
  useLogisticProductIcons,
  useShippingServiceIcons,
} from '../_Shared/LogisticProductSelect/hooks/useLogisticProductSelectIcons';
import { Icons } from '@/Static/IconsRes';
import { useShipCheaperLogisticForm } from './hooks/useShipCheaperLogisticForm';
import { useShipCheaperLogisticOptions } from './hooks/useShipCheaperLogisticOptions';
import { FormData } from '@/ModalWindows/OrderAutomatizationModal/hooks/useOrderAutomatizationModalForm';
import { PackageSelect } from '@/ModalWindows/OrderAutomatizationModal/modules/OrderAutomatizationTable/modules/OrderPackagesCell/modules/_Shared/PackageSelect/PackageSelect';
import { OrderAutomatizationProductModel } from '@/Models/OrderUserProducts/OrderAutomatizationProductModel';
import { useSelector } from 'react-redux';
import { userSelectors } from '@/Redux/User';

type Props = {
  rowIndex: number;
  rowData: OrderAutomatizaionRow;
  disabled?: boolean;
};

export const ShipCheaperLogistic = ({ rowIndex, rowData, disabled = false }: Props) => {
  const [logisticProduct, setLogisticProduct] = useState<Nullable<OrderAutomatizationProductModel>>(null);

  const {
    methods: { watch, setValue },
    selectedPackages: { array, append, remove },
  } = useShipCheaperLogisticForm(rowIndex);

  const { getLogisticProductIconByType } = useLogisticProductIcons();
  const { getShippingServiceIconByType } = useShippingServiceIcons();
  const defaultCurrency = useSelector(userSelectors.userDefaultCurrencySymbol);
  const { packageOptions, productOptions } = useShipCheaperLogisticOptions(rowData.orderId, array);

  const selectedUserProduct = watch((`prepareLabels.${rowIndex}.userProductId` as unknown) as keyof FormData);

  useEffect(() => {
    setValue(
      `prepareLabels.${rowIndex}.logisticTypeId`,
      productOptions.find((x) => x.id == Number(selectedUserProduct))?.logisticTypeId ?? 0,
    );
  }, [selectedUserProduct]);

  useEffect(() => {
    setLogisticProduct(null);
    setValue(`prepareLabels.${rowIndex}.userProductId`, '');
  }, [productOptions.length]);

  if (!packageOptions) return null;

  return (
    <div className={styles.logisticRow}>
      {array.map((p, idx) => (
        <PackageSelect
          key={p.array_id}
          index={idx}
          rowIndex={rowIndex}
          isAddActive={array.length < 5}
          onAdd={() => append({ key: '', weight: '' })}
          onDelete={() => remove(idx)}
          packageOptions={packageOptions}
          disabled={disabled}
          isOwnContract={false}
          orderId={rowData.orderId}
        />
      ))}

      {!logisticProduct ? (
        <LogisticProductSelect
          products={productOptions}
          currency={defaultCurrency}
          onChange={(product) => {
            if (product) {
              setLogisticProduct(product);
              setValue(`prepareLabels.${rowIndex}.userProductId`, product.id);
              setValue(`prepareLabels.${rowIndex}.deliveryDate`, product.deliveryDate);
              setValue(`prepareLabels.${rowIndex}.userProductPrice`, product.userProductPrice);
            }
          }}
        />
      ) : (
        <div className={cn(styles.logisticProduct)}>
          <div className={styles.logisticType}>
            <Icon src={getLogisticProductIconByType(logisticProduct.logisticTypeId)} alt={'error'} />
            <div>{logisticProduct.productTypeName?.slice(0, 6)}</div>
          </div>
          <div className={styles.shippingFrom}>
            <Icon src={getShippingServiceIconByType(logisticProduct.shippingServiceType)[0].icon} alt={'shipping'} />
            <div>{getShippingServiceIconByType(logisticProduct.shippingServiceType)[0].type}</div>
          </div>
          <div className={styles.shippingTo}>
            <Icon src={getShippingServiceIconByType(logisticProduct.shippingServiceType)[1].icon} alt={'shipping'} />
            <div>{getShippingServiceIconByType(logisticProduct.shippingServiceType)[1].type}</div>
          </div>
          <div className={styles.shippingPrice}>
            {logisticProduct.userProductPrice} {defaultCurrency}
          </div>
          <div className={styles.shippingAction}>
            <Icon
              src={Icons.Delete}
              alt={'delete'}
              onClick={() => {
                setLogisticProduct(null);
                setValue(`prepareLabels.${rowIndex}.userProductId`, '');
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
