import { createSlice } from '@reduxjs/toolkit';
import { asyncActions } from '../../../services/asyncActions';

// eslint-disable-next-line @typescript-eslint/ban-types
export type UploadSectionState = {};

const initialState: UploadSectionState = {};

const uploadSectionSlice = createSlice({
  name: 'onlineArchivePage/uploadSection',
  initialState,
  reducers: {},
});

export const uploadSectionReducer = uploadSectionSlice.reducer;
export const uploadSectionActions = uploadSectionSlice.actions;
export const uploadSectionAsyncActions = asyncActions;
