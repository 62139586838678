import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '@/Types';
import { asyncActions } from './asyncActions';
import { DocumentTemplateModel } from '@/Models';
import { documentTemplateMapping } from '@/Mapping';
import { ModalDataType } from '@/Types/ModalDataType';
import { DocumentTemplateType } from '@/Enums/DocumentTemplateType';

export type EmailTemplatesSettingsState = {
  selectedEmailTemplate: Nullable<DocumentTemplateModel>;
  modalData: ModalDataType<DocumentTemplateModel>;
  emailTemplates: DocumentTemplateModel[];
};

const initialState: EmailTemplatesSettingsState = {
  selectedEmailTemplate: null,
  modalData: { visible: false },
  emailTemplates: [],
};

const emailTemplatesSettingsSlice = createSlice({
  name: 'emailTemplatesSettings',
  initialState,
  reducers: {
    setSelectedEmailTemplate(state, action: PayloadAction<Nullable<DocumentTemplateModel>>) {
      state.selectedEmailTemplate = action.payload;
    },
    setUpdatedTemplate(state, action: PayloadAction<DocumentTemplateModel>) {
      const index = state.emailTemplates.findIndex(({ guid }) => guid == action.payload.guid);
      if (index == -1) {
        state.emailTemplates = state.emailTemplates.map((x) => {
          if (x.automaticallyGeneratedDocumentType === action.payload.automaticallyGeneratedDocumentType) {
            x.automaticallyGeneratedDocumentType = undefined;
          } // if

          return x;
        });

        state.emailTemplates.push(action.payload);
      } else {
        state.emailTemplates = state.emailTemplates.map((x) => {
          if (x.guid === action.payload.guid) return action.payload;

          if (x.automaticallyGeneratedDocumentType === action.payload.automaticallyGeneratedDocumentType) {
            x.automaticallyGeneratedDocumentType = undefined;
          } // if

          return x;
        });
      } // if

      state.selectedEmailTemplate = action.payload;
      state.modalData = { visible: false };
    },
    setModalData(state, action: PayloadAction<ModalDataType<DocumentTemplateModel>>) {
      state.modalData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getEmailTemplates.fulfilled, (state, action) => {
        state.emailTemplates = action.payload.templates;
      })
      .addCase(asyncActions.updateEmailTemplate.fulfilled, (state, action) => {
        const updatedEmailTemplate = action.payload.template;

        state.emailTemplates = state.emailTemplates.map((i) =>
          i.guid === updatedEmailTemplate.guid ? updatedEmailTemplate : i,
        );
        state.modalData = { visible: false };
      })
      .addCase(asyncActions.copyEmailTemplate.fulfilled, (state, action) => {
        const template = documentTemplateMapping.mappingDocumentTemplateModel(
          action.payload.templateGuid,
          action.payload.templateName,
          DocumentTemplateType.Email,
        );
        state.emailTemplates.push(template);
      })
      .addCase(asyncActions.restoreEmailTemplate.fulfilled, (state, action) => {
        const template = state.emailTemplates.find(({ guid }) => guid == action.payload.templateGuid);
        if (template) {
          template.name = action.payload.templateName;
        }
      })
      .addCase(asyncActions.deleteEmailTemplate.fulfilled, (state, action) => {
        state.emailTemplates = state.emailTemplates.filter((i) => i.guid !== action.payload.guid);
      });
  },
});

export const emailTemplatesActions = emailTemplatesSettingsSlice.actions;
export const emailTemplatesReducer = emailTemplatesSettingsSlice.reducer;
export const emailTemplatesAsyncActions = asyncActions;
