import { ColumnInfo, ColumnType, PaymentTableRow } from '@/Types';
import { useLocalization } from '@/Hooks';
import { useMemo } from 'react';

export function usePaymentsColumnInfo() {
  const { paymentColumns } = useLocalization();

  return useMemo((): ColumnInfo<PaymentTableRow>[] => {
    return [
      {
        columnType: ColumnType.String,
        fieldName: 'date',
        headerText: paymentColumns.Date,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'payId',
        headerText: paymentColumns.PayId,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'batchId',
        headerText: paymentColumns.BatchId,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'payAmount',
        headerText: paymentColumns.PayAmount,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'accountBalance',
        headerText: paymentColumns.AccountBalance,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'relatedInvoiceNumbers',
        headerText: paymentColumns.RelatedInvoiceNumbers,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'paymentReference',
        headerText: paymentColumns.PaymentReference,
      },
      {
        columnType: ColumnType.String,
        fieldName: 'status',
        headerText: paymentColumns.Status,
      },
    ];
  }, [paymentColumns]);
}
