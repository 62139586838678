import { ApiRoutingUtils } from '@/Routing';
import {
  CreateMpAccountRequest,
  UpdateMpAccountRequest,
  DeleteMpAccountRequest,
  GetMpAccountsResponse,
  CreateMpAccountResponse,
  UpdateMpAccountResponse,
  DeleteMpAccountResponse,
} from './';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('mp-account');

function getMpAccounts(): Promise<GetMpAccountsResponse> {
  return rest.get(url);
}

function createMpAccount(request: CreateMpAccountRequest): Promise<CreateMpAccountResponse> {
  return rest.post(url, request);
}

function updateMpAccount(request: UpdateMpAccountRequest): Promise<UpdateMpAccountResponse> {
  return rest.put(url, request);
}

function deleteMpAccount(request: DeleteMpAccountRequest): Promise<DeleteMpAccountResponse> {
  return rest.delete(url, request);
}

export const MpAccountApi = {
  createMpAccount,
  getMpAccounts,
  updateMpAccount,
  deleteMpAccount,
};
