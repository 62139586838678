import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';

const editorSettings = (state: RootState) => state.languageEditorPage.editorSettings;

const languageEditorSettings = createSelector(editorSettings, (state) => state.languageEditorSettings);
const languageAccessSettings = createSelector(editorSettings, (state) => state.languageAccessSettings);

export const editorSettingsSelectors = {
  languageEditorSettings,
  languageAccessSettings,
};
