import React from 'react';
import { OrderTableRow } from '@/Types';
import styles from './PurchaseTable.scss';
import { useTabs } from '@/Hooks';
import { useSelector } from 'react-redux';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { MERGED_COLUMNS } from './MergeColumns';
import { useSalesColumnInfo } from './ColumnInfo';
import { AppUserSettingNameType } from '@/Enums';
import { useTableStateStoringKey } from '@/Hooks/useTableStateStoringKey';
import { DataTable } from '@/Components/DataTable/DataTable';
import { TabItem } from '@/Components/Tabs/modules/TabItem/TabItem';
import { TabsPanel } from '@/Components/Tabs/modules/TabPanel/TabPanel';
import { TabList } from '@/Components/Tabs/modules/TabList/TabList';
import { Tabs } from '@/Components/Tabs/Tabs';
import { usePurchaseTableSelectOptions } from './hooks/usePurchaseTableSelectOptions';

export const PurchaseTableComponent = () => {
  const { columnInfo } = useSalesColumnInfo();
  const { setActiveTab, isActive } = useTabs(0);
  const purchases = useSelector(purchasesPageSelectors.purchases);

  const selectOptions = usePurchaseTableSelectOptions();

  const storingKey1 = useTableStateStoringKey(AppUserSettingNameType.TablePurchaseTab1);
  const storingKey2 = useTableStateStoringKey(AppUserSettingNameType.TablePurchaseTab2);

  return (
    <Tabs className={styles.wrapper}>
      <TabList>
        <TabItem idx={0} tabName={'Tab1'} isActive={isActive(0)} onClick={setActiveTab} />
        <TabItem idx={1} tabName={'Tab2'} isActive={isActive(1)} onClick={setActiveTab} />
      </TabList>
      <TabsPanel isActive={isActive(0)} className={styles.tableTab}>
        <DataTable<OrderTableRow>
          rowKey="id"
          rows={purchases}
          columnInfos={columnInfo}
          stateStoringKey={storingKey1}
          mergeColumnsMap={MERGED_COLUMNS}
          className={styles.table}
          filterOptions={{
            needColumnChooser: true,
          }}
          selectOptions={{
            ...selectOptions,
          }}
        />
      </TabsPanel>
      <TabsPanel isActive={isActive(1)} className={styles.tableTab}>
        <DataTable<OrderTableRow>
          rows={purchases}
          rowKey="id"
          columnInfos={columnInfo}
          className={styles.table}
          stateStoringKey={storingKey2}
          mergeColumnsMap={MERGED_COLUMNS}
          filterOptions={{
            needColumnChooser: true,
          }}
          selectOptions={{
            ...selectOptions,
          }}
        />
      </TabsPanel>
    </Tabs>
  );
};

export const PurchasesTable = React.memo(PurchaseTableComponent);
