import React from 'react';
import cn from 'classnames';
import styles from './CrudExtended.scss';

type Props = {
  iconItems?: IconItem[];
  className?: string;
};

export type IconItem = {
  src: string;
  onClick: () => void;
  active?: boolean;
  className?: string;
};

export const CrudExtended = ({ iconItems, className }: Props) => {
  return (
    <div className={cn(className, styles.wrapper)}>
      {iconItems?.map((item) => {
        return (
          <img
            key={item.src}
            src={item.src}
            className={cn(styles.icon, item.className, { [styles.icon_disabled]: !item.active })}
            alt="insert"
            onClick={item.active ? item.onClick : undefined}
          />
        );
      })}
    </div>
  );
};
