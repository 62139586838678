import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';
import { DocumentCategoriesDataState } from './reducer';

const documentCategoriesDataSelector = (root: RootState) => root.dynamicData.documentCategoriesData;

const documentCategories = createSelector(
  documentCategoriesDataSelector,
  (state: DocumentCategoriesDataState) => state.documentCategories,
);

export const documentCategoriesDataSelectors = {
  documentCategories,
};
