import React from 'react';
import styles from './Icons.scss';
import cn from 'classnames';
import { IconProps, Icons } from './IconsRes';
import { getIconClasses } from '@/Static/IconUtils';

export const PrintIcon = ({ size = 'small', className, onClick, disabled = false, dataAttributes }: IconProps) => {
  return (
    <img
      src={Icons.Printer}
      onClick={onClick}
      className={cn(styles.icon, styles.button, getIconClasses(size, disabled), className)}
      {...dataAttributes}
    />
  );
};
