import { ShippingMethodModel } from '@/Models';
import { RootState } from '@/Redux/RootReducer';
import { ObjectUtils } from '@/Utils';

export function getLocalizationName(x: ShippingMethodModel, state: RootState): string {
  return getLocalizationNameByCode(x.code, state);
}

export function getLocalizationNameByCode(code: string, state: RootState): string {
  const path = `ShippingMethod.${code}`;
  const enums = state.global.translation.Enums;
  return ObjectUtils.getObjectPathValue(enums, path) || code;
}

export const shippingMethodsUtils = {
  getLocalizationName,
  getLocalizationNameByCode,
};
