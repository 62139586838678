import { useMemo, useState } from 'react';
import { TreeItemType } from '@/Components/TreeView/TreeView';

// Logistic type of product tree view options for form inputs
export function useLogisticTypesTreeViewOptions(allTypes: TreeItemType[], defaultValues?: (string | number)[]) {
  const [needToReset, setNeedToReset] = useState(false);

  const defaultIds = defaultValues;

  const typesTreeViewItems = useMemo<TreeItemType[]>(() => {
    setNeedToReset(false);

    return allTypes.map((item) =>
      defaultIds?.includes(item.key) ? { ...item, selected: true } : { ...item, selected: false },
    );
  }, [allTypes, needToReset, defaultValues]);

  return {
    typesTreeViewItems,
    setNeedToReset,
  };
}
