import React from 'react';
import { Icon } from '@/Components/Icon/Icon';
import { ControlIcons } from '@/Static/IconsRes';
import Button from 'devextreme-react/button';
import styles from './FilterBuilderButton.scss';

type Props = {
  onClick: () => void;
};

export const FilterBuilderButton = ({ onClick }: Props): React.ReactElement => {
  return (
    <Button onClick={onClick}>
      <Icon alt="filterBuilderIcon" src={ControlIcons.TableFilter} className={styles.icon} />
    </Button>
  );
};
