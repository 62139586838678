import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Store from 'devextreme/data/abstract_store';

export const DRAG_BETWEEN_TABLES_KEY = 'dragTableId';

export type DraggableBetweenTablesData = {
  [DRAG_BETWEEN_TABLES_KEY]: string;
};

export enum PushType {
  insert = 'insert',
  update = 'update',
  remove = 'remove',
}

export enum SortType {
  Asc = 'asc',
  Desc = 'desc',
}

type PushOptions<TData, TKey extends keyof TData> = {
  type: PushType;
  data?: Partial<TData>;
  key?: TData[TKey];
  index?: number;
};

interface IStoreOptions<TData, TKey extends keyof TData> {
  data: TData[];
  key: keyof TData;
  onChanged?: (instance: IDataTableRef<TData, TKey>) => void;
}

export interface IDataTableRef<TData, TKey extends keyof TData = keyof TData> {
  readonly store: Store;
  readonly dataSource: DataSource;

  byKey(key: keyof TData): Promise<TData>;
  key(): keyof TData;
  keyOf(obj: TData): keyof TData;
  push(changes: PushOptions<TData, TKey>[]): void;
  totalCount(obj: { filter?: any; group?: any }): Promise<number>;
  count(): number;
  items(): TData[];
}

export class DataTabDataSourceType<TData, TKey extends keyof TData = keyof TData>
  implements IDataTableRef<TData, TKey> {
  private readonly _dataSource: DataSource;

  get dataSource() {
    return this._dataSource;
  }

  get store(): Store {
    return this._dataSource.store();
  }

  constructor(options: IStoreOptions<TData, TKey>) {
    this._dataSource = new DataSource({
      store: new ArrayStore({
        data: options.data,
        key: options.key as string,
      }),
      requireTotalCount: true,
      onChanged: () => options.onChanged?.(this),
    });
  }

  byKey(key: keyof TData): Promise<TData> {
    return this.store.byKey(key);
  }

  key(): keyof TData {
    return this.store.key() as keyof TData;
  }

  keyOf(obj: TData): keyof TData {
    return this.store.keyOf(obj);
  }

  push(changes: PushOptions<TData, TKey>[]): void {
    this.store.push(changes);
  }

  totalCount(obj: { filter?: any; group?: any }): Promise<number> {
    return this.store.totalCount(obj);
  }

  count(): number {
    return this._dataSource.items().length;
  }

  items(): TData[] {
    return this._dataSource.items();
  }
}
