import { AddProductToCartRequest } from '@/Api/Shop/Requests/AddProductToCartRequest';
import { AddProductToCartResponse } from '@/Api/Shop/Responses/AddProductToCartResponse';
import { CreateShopOrderRequest } from '@/Api/Shop/Requests/CreateShopOrderRequest';
import { GetShopProductsResponse } from '@/Api/Shop/Responses/GetProductsResponse';
import { ShopApi } from '@/Api/Shop/ShopApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetTemplatesForShopResponse } from '@/Api/Shop/Responses/GetTemplatesForShopResponse';
import { GetTemplatesForShopRequest } from '@/Api/Shop/Requests/GetTemplatesForShopRequest';

const getShopProducts = createAsyncThunk<GetShopProductsResponse, undefined>('shopPage/get-shop-products', async () => {
  return ShopApi.getShopProducts();
});

const appProductToCart = createAsyncThunk<AddProductToCartResponse, AddProductToCartRequest>(
  'shopPage/add-product-to-cart',
  async (request) => {
    return ShopApi.addProductToCart(request);
  },
);

const createOrder = createAsyncThunk<object, CreateShopOrderRequest>('shopPage/create-order', async (request) => {
  return ShopApi.createOrderRequest(request);
});

const deleteProductFromCart = createAsyncThunk<number, number>('shopPage/delete-product-from-cart', async (request) => {
  return ShopApi.deleteProductFromCart(request);
});

const getTemplatesForShop = createAsyncThunk<GetTemplatesForShopResponse, GetTemplatesForShopRequest>(
  'shopPage/get-templates-for-shop',
  async (request) => {
    return ShopApi.getTemplatesForShopByCountry(request);
  },
);

export const asyncActions = {
  getShopProducts,
  appProductToCart,
  createOrder,
  deleteProductFromCart,
  getTemplatesForShop,
};
