import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  enTranslation,
  TranslationType,
  enTranslationDocumentDesigner,
  TranslationDocumentDesignerType,
  TranslationProducts,
} from '@/Localization/LanguageKeys';
import { LANGUAGE_SELECT_OPTIONS_MAP } from '@/Localization/constants';
import {
  convertLocaleToLocalization,
  convertLocalizationToLocale,
  Locale,
  LocalizationType,
} from '@/Enums/LocalizationType'; // Error while importing from index.ts
import { Pair } from '@/Types';
import { LoadingType } from '../../Enums/LoadingType';
import { translationMapping } from '@/Mapping/TranslationMapping';

export type GlobalState = {
  loadingType: LoadingType;
  translation: TranslationType;
  translationDocumentDesigner: TranslationDocumentDesignerType;
  translationProducts: TranslationProducts;
  selectedLanguage: Pair<LocalizationType>;
  isConnectionLost: boolean;
};
const browserLanguage =
  LANGUAGE_SELECT_OPTIONS_MAP[convertLocaleToLocalization(navigator.language as Locale)] ??
  LANGUAGE_SELECT_OPTIONS_MAP[LocalizationType.enEN];

const initialState: GlobalState = {
  loadingType: LoadingType.None,
  translation: enTranslation,
  translationDocumentDesigner: enTranslationDocumentDesigner,
  translationProducts: {},
  selectedLanguage: browserLanguage,
  isConnectionLost: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTranslation(state, action: PayloadAction<TranslationType>) {
      state.translation = action.payload;
    },
    setLoadingType(state, action: PayloadAction<{ loadingType: LoadingType; url?: string }>) {
      state.loadingType = action.payload.loadingType;
    },
    setLanguage(state, action: PayloadAction<LocalizationType>) {
      state.selectedLanguage = { key: action.payload, value: action.payload };
    },
    setConnectionLost(state, action: PayloadAction<boolean>) {
      state.isConnectionLost = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('global/get-translation/fulfilled', (state, action: any) => {
        state.selectedLanguage = LANGUAGE_SELECT_OPTIONS_MAP[action.payload.localization];

        window.localStorage.setItem('lang', convertLocalizationToLocale(action.payload.localization));

        state.translation = translationMapping.shortModelsToType<TranslationType>(action.payload.translations);
      })
      .addCase('global/get-document-designer-translation/fulfilled', (state, action: any) => {
        state.translationDocumentDesigner = translationMapping.shortModelsToType<TranslationDocumentDesignerType>(
          action.payload.translations,
        );
      })
      .addCase('global/get-products-translation/fulfilled', (state, action: any) => {
        state.translationProducts = translationMapping.shortModelsToType<TranslationProducts>(
          action.payload.translations,
        );
      });
  },
});

export const globalActions = globalSlice.actions;
export const globalReducer = globalSlice.reducer;
