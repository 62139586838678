import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from '@/Components/DataTable/DataTable';
import { OrderLabelPrintRow } from '@/Types';
import { useOrderLabelTableColumnInfo } from './ColumnInfo';
import { orderLabelPrintActions, orderLabelPrintModalSelectors } from '../../services';
import styles from './OrderLabelTable.scss';

type Props = {
  editRowClick: (row: OrderLabelPrintRow) => void;
};

export function OrderLabelTableComponent({ editRowClick }: Props): React.ReactElement {
  const dispatch = useDispatch();

  const rows = useSelector(orderLabelPrintModalSelectors.orderLabelPrintRows);
  const selectedRowKeys = useSelector(orderLabelPrintModalSelectors.selectedRowKeys);

  return (
    <DataTable<OrderLabelPrintRow>
      rows={rows}
      rowKey={'orderId'}
      seleсtedRowKeys={selectedRowKeys}
      columnInfos={useOrderLabelTableColumnInfo(editRowClick)}
      className={styles.table}
      filterOptions={{
        needShowFiltersButton: false,
        needHeaderFilter: false,
        needColumnChooser: false,
        showFilterBuilder: false,
      }}
      editOptions={{
        needEditing: false,
      }}
      selectOptions={{
        showCheckBoxesMode: 'onClick',
        onSelectedRowsChanged: (data) => {
          if (data.length > 0) dispatch(orderLabelPrintActions.setSelectedOrderLabelPrints(data));
        },
      }}
    />
  );
}

export const OrderLabelTable = React.memo(OrderLabelTableComponent);
