import { ApiRoutingUtils } from 'src/Routing';

import { rest } from 'src/Api/Rest';
import { ScanBarcodeIncomingResponse } from 'src/Api/UserWarehouse/Transfers/Incoming/Responses/ScanBarcodeIncomingResponse';
import { ScanBarcodeIncomingRequest } from 'src/Api/UserWarehouse/Transfers/Incoming/Requests/ScanBarcodeIncomingRequest';
import { GetIncomingTransfersByWarehouseResponse } from 'src/Api/UserWarehouse/Transfers/Incoming/Responses/GetIncomingTransfersByWarehouseResponse';
import { GetIncomingTransfersByWarehouseRequest } from 'src/Api/UserWarehouse/Transfers/Incoming/Requests/GetIncomingTransfersByWarehouseRequest';
import { GetIncomingTransferBatchRequest } from 'src/Api/UserWarehouse/Transfers/Incoming/Requests/GetIncomingTransferBatchRequest';
import { GetIncomingTransferBatchResponse } from 'src/Api/UserWarehouse/Transfers/Incoming/Responses/GetIncomingTransferBatchResponse';

const transferUrl = ApiRoutingUtils.createUrl('user-warehouse-incoming-transfer');

async function scanBarcode(request: ScanBarcodeIncomingRequest): Promise<ScanBarcodeIncomingResponse> {
  return rest.post(`${transferUrl}/scan-barcode`, request);
}

async function getIncomingTransfers(
  request: GetIncomingTransfersByWarehouseRequest,
): Promise<GetIncomingTransfersByWarehouseResponse> {
  return rest.get(`${transferUrl}/incoming-transfers`, request);
}

async function getBatch(request: GetIncomingTransferBatchRequest): Promise<GetIncomingTransferBatchResponse> {
  return rest.get(transferUrl, request);
}

export const WarehouseIncomingTransferApi = {
  scanBarcode,
  getIncomingTransfers,
  getBatch,
};
