import { ApiRoutingUtils } from '@/Routing';
import { GetShoppingCartsResponse } from './GetShoppingCartsResponse';
import { rest } from '@/Api/Rest';
import { GetUserProductCartForUserResponse } from '@/Api/ShoppingCarts/GetUserProductCartForUserResponse';

const url = ApiRoutingUtils.createUrl('user-product-carts');

function getUserProductCarts(): Promise<GetShoppingCartsResponse> {
  return rest.get(url);
}

function getUserProductCartForUser(userId: number): Promise<GetUserProductCartForUserResponse> {
  const requestUrl = `${url}/get-user-product-cart?userId=${userId}`;
  return rest.get(requestUrl);
}

export const CustomerShoppingCartApi = {
  getUserProductCarts,
  getUserProductCartForUser,
};
