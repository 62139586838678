import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetUncompletedTransfersResponse } from '@/Api/UserWarehouse/Transfers/Uncompleted/Responses/GetUncompletedTransfersResponse';
import { UncompletedTransferApi } from '@/Api/UserWarehouse/Transfers/Uncompleted/UncompletedTransferApi';
import { GetUncompletedTransfersRequest } from '@/Api/UserWarehouse/Transfers/Uncompleted/Requests/GetUncompletedTransfersRequest';

const getUncompletedTransfers = createAsyncThunk<GetUncompletedTransfersResponse, GetUncompletedTransfersRequest>(
  'incoming-manual-transfer/scan-barcode',
  async (request) => {
    return UncompletedTransferApi.getUncompletedTransfers(request);
  },
);

export const asyncActions = {
  getUncompletedTransfers,
};
