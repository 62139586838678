import { useLocalization } from '@/Hooks';
import { stringUtils } from '@/Utils';
import { useMemo } from 'react';

export const useShouldBeMoreThan = (value: number, step = 1) => {
  const { validation } = useLocalization();

  const message = useMemo(() => {
    return stringUtils.insertParamsToString(validation.ValueMustBeMoreThan, [value]);
  }, [validation, value]);

  return {
    min: {
      value: value + step,
      message,
    },
  };
};
