import { allocateSectionActions, allocateSectionAsyncActions } from '@/Pages/OnlineArchive/modules/Allocate/services';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { documentFoldersDataActions } from '@/Redux/DynamicData/DocumentFoldersData/reducer';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useDocumentsModalDialogActions = (
  documentIds: number[],
  documentFolderToId: number,
  documentFolderFromIds: number[],
  isInOnlineArchive: boolean,
  closeWindow?: () => void,
  setVisibleOnlineArchiveModalWindow?: (value: React.SetStateAction<boolean>) => void,
) => {
  const dispatch: AppDispatch = useDispatch();

  const onOk = useCallback(() => {
    if (isInOnlineArchive) {
      dispatch(
        allocateSectionAsyncActions.changeDocumentsFolderFromOnlineArchive({
          ids: documentIds,
          documentFolderId: documentFolderToId,
        }),
      ).then(() => {
        documentFolderFromIds.forEach((documentFolderFromId) => {
          dispatch(
            documentFoldersDataActions.decreaseFolderDocumentsCount({
              id: Number(documentFolderFromId),
              value: documentIds.length,
            }),
          );
        });

        dispatch(
          documentFoldersDataActions.increaseFolderDocumentsCount({
            id: Number(documentFolderToId),
            value: documentIds.length,
          }),
        );
      });
    } else {
      dispatch(
        allocateSectionAsyncActions.changeDocumentsFolder({
          ids: documentIds,
          documentFolderId: documentFolderToId,
        }),
      ).then(() => {
        documentFolderFromIds.forEach((documentFolderFromId) => {
          dispatch(
            documentFoldersDataActions.decreaseFolderDocumentsCount({
              id: Number(documentFolderFromId),
              value: documentIds.length,
            }),
          );
        });

        dispatch(
          documentFoldersDataActions.increaseFolderDocumentsCount({
            id: Number(documentFolderToId),
            value: documentIds.length,
          }),
        );
      });
    }
  }, [documentIds, documentFolderToId, documentFolderFromIds]);

  const onClose = useCallback(() => {
    dispatch(
      allocateSectionActions.setDocumentsModalDialog({
        visible: false,
      }),
    );
    if (closeWindow && setVisibleOnlineArchiveModalWindow && !isInOnlineArchive) {
      setVisibleOnlineArchiveModalWindow(false);
      closeWindow();
    }
  }, []);

  return {
    onOk,
    onClose,
  };
};
