import { createAsyncThunk } from '@reduxjs/toolkit';
import { ScanBarcodeIMTResponse } from 'src/Api/UserWarehouse/Transfers/IncomingManual/Responses/ScanBarcodeIMTResponse';
import { ScanBarcodeIMTRequest } from 'src/Api/UserWarehouse/Transfers/IncomingManual/Requests/ScanBarcodeIMTRequest';
import { CreatePostingsIMTResponse } from 'src/Api/UserWarehouse/Transfers/IncomingManual/Responses/CreatePostingsIMTResponse';
import { CreatePostingsIMTRequest } from 'src/Api/UserWarehouse/Transfers/IncomingManual/Requests/CreatePostingsIMTRequest';
import { WarehouseIncomingManualTransferApi } from 'src/Api/UserWarehouse/Transfers/IncomingManual/WarehouseIncomingManualTransferApi';

const scanBarcode = createAsyncThunk<ScanBarcodeIMTResponse, ScanBarcodeIMTRequest>(
  'incoming-manual-transfer/scan-barcode',
  async (request) => {
    return WarehouseIncomingManualTransferApi.scanBarcode(request);
  },
);

const createPostings = createAsyncThunk<CreatePostingsIMTResponse, CreatePostingsIMTRequest>(
  'incoming-manual-transfer/create-postings',
  async (request) => {
    return WarehouseIncomingManualTransferApi.createPostings(request);
  },
);

export const asyncActions = {
  scanBarcode,
  createPostings,
};
