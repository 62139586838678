import React from 'react';
import { ModalProps } from '@/Types';
import { AddressForm } from '@/Components/Forms/AddressForm/AddressForm';
import { useForm, FormProvider } from '@/Hooks/useFormWrapper';
import styles from './AddressValidationResolvingModal.scss';
import { AddressValidationResultModel } from '@/Models/AddressValidationResultModel';
import { AddressModel } from '@/Models';
import { AddressValidationResultType, CountryCode, ValidationType } from '@/Enums';
import { useLocalization } from '@/Hooks';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Modal } from '@/Components/Modal/Modal';
import { countryTypeUtils } from '@/Utils/CountryTypesUtils';
import { useSelector } from 'react-redux';
import { globalSelectors } from '@/Redux/Global/selectors';

type Props = {
  totalConflictsCount: number;
  currentConflictIndex: number;
  onSubmit: (orderId: number, addressModel: AddressModel) => void;
  currentConflict: AddressValidationResultModel | undefined;
} & ModalProps;

type FormData = {
  originalHouseNo: string;
  originalStreet: string;
  originalAdditionalLine: string;
  originalCity: string;
  originalCountry: string;
  originalCountryCode: CountryCode;
  originalPostalCode: string;
  originalCompany: string;
  originalFirstName: string;
  originalLastName: string;
  originalEmail: string;
  originalPhone: string;

  suggestedHouseNo: string;
  suggestedStreet: string;
  suggestedAdditionalLine: string;
  suggestedCity: string;
  suggestedCountry: string;
  suggestedCountryCode: CountryCode;
  suggestedPostalCode: string;
  suggestedCompany: string;
  suggestedFirstName: string;
  suggestedLastName: string;
  suggestedEmail: string;
  suggestedPhone: string;
};

export function AddressValidationResolvingModal({
  closeModalWindow,
  onSubmit,
  currentConflict,
  currentConflictIndex,
  totalConflictsCount,
}: Props) {
  const {
    buttons: { Cancel, Save },
    modalWindows: {
      addressValidationResolving: { AddressNotFound, Title, Warning, OriginalShippingAddress, NewShippingAddress },
    },
  } = useLocalization();
  const translation = useSelector(globalSelectors.translation);

  const methods = useForm<FormData>();

  if (!currentConflict) {
    return null;
  }

  const { originalAddress, suggestedAddress, result } = currentConflict;

  return (
    <Modal modalClassName={styles.modal} onClose={closeModalWindow} shouldCloseOnOverlayClick={false}>
      <h3 className={styles.modalTitle}>
        {Title}
        <span>
          ({currentConflictIndex + 1}/{totalConflictsCount})
        </span>
      </h3>
      <p className={styles.warningText}>{Warning}</p>
      {result === AddressValidationResultType.NotFound && <p className={styles.errorText}>{AddressNotFound}</p>}
      <FormProvider {...methods}>
        <form
          className={styles.formWrapper}
          onSubmit={methods.handleSubmit((values) => {
            const addressModel: AddressModel = {
              additionalLine: values.suggestedAdditionalLine,
              city: values.suggestedCity,
              company: values.suggestedCompany,
              country: values.suggestedCountry,
              countryCode: values.suggestedCountryCode,
              email: values.suggestedEmail,
              firstName: values.suggestedFirstName,
              houseNo: values.suggestedHouseNo,
              lastName: values.suggestedLastName,
              phone: values.suggestedPhone,
              postalCode: values.suggestedPostalCode,
              street: values.suggestedStreet,
            };
            onSubmit(currentConflict.orderId, addressModel);
          })}
        >
          <div className={styles.inputsWrapper}>
            <div>
              <h4 className={styles.formSectionTitle}>{OriginalShippingAddress}</h4>
              <AddressForm<FormData>
                disabled
                fieldsInfo={{
                  company: { value: originalAddress.company, name: 'suggestedCompany' },
                  country: {
                    value: countryTypeUtils.getCountryNameByCountryCode(suggestedAddress.countryCode, translation),
                    name: 'originalCountry',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'country', true),
                  },
                  countryCode: {
                    value: originalAddress.countryCode,
                    name: 'originalCountryCode',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'countryCode', true),
                  },
                  city: {
                    value: originalAddress.city,
                    name: 'originalCity',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'city', true),
                  },
                  zip: {
                    value: originalAddress.postalCode,
                    name: 'originalPostalCode',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'postalCode', true),
                  },
                  street: {
                    value: originalAddress.street,
                    name: 'originalStreet',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'street', true),
                  },
                  houseNo: {
                    value: originalAddress.houseNo,
                    name: 'originalHouseNo',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'houseNo', true),
                  },
                  lastName: { value: originalAddress.lastName, name: 'originalLastName' },
                  firstName: { value: originalAddress.firstName, name: 'originalFirstName' },
                  additional: { value: originalAddress.additionalLine, name: 'originalAdditionalLine' },
                  email: { value: originalAddress.email, name: 'originalEmail' },
                  phone: { value: originalAddress.phone, name: 'originalPhone' },
                }}
              />
            </div>

            <div>
              <h4 className={styles.formSectionTitle}>{NewShippingAddress}</h4>
              <AddressForm<FormData>
                fieldsInfo={{
                  company: { value: suggestedAddress.company, name: 'suggestedCompany' },
                  country: {
                    value: countryTypeUtils.getCountryNameByCountryCode(suggestedAddress.countryCode, translation),
                    name: 'suggestedCountry',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'country', false),
                  },
                  countryCode: {
                    value: suggestedAddress.countryCode,
                    name: 'suggestedCountryCode',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'countryCode', false),
                  },
                  city: {
                    value: suggestedAddress.city,
                    name: 'suggestedCity',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'city', false),
                  },
                  zip: {
                    value: suggestedAddress.postalCode,
                    name: 'suggestedPostalCode',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'postalCode', false),
                  },
                  street: {
                    value: suggestedAddress.street,
                    name: 'suggestedStreet',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'street', false),
                  },
                  houseNo: {
                    value: suggestedAddress.houseNo,
                    name: 'suggestedHouseNo',
                    validationType: getValidationType(originalAddress, suggestedAddress, 'houseNo', false),
                  },
                  lastName: { value: suggestedAddress.lastName, name: 'suggestedLastName' },
                  firstName: { value: suggestedAddress.firstName, name: 'suggestedFirstName' },
                  additional: { value: suggestedAddress.additionalLine, name: 'suggestedAdditionalLine' },
                  email: { value: suggestedAddress.email, name: 'suggestedEmail' },
                  phone: { value: suggestedAddress.phone, name: 'suggestedPhone' },
                }}
              />
            </div>
          </div>
          <div className={styles.buttonsWrapper}>
            <Button text={Cancel} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} onClick={closeModalWindow} />
            <Button text={Save} theme={BUTTON_THEMES.SUCCESS} type={'submit'} />
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

function getValidationType(
  originalAddress: Partial<AddressModel>,
  suggestedAddress: Partial<AddressModel>,
  key: keyof AddressModel,
  isForOriginalAddress: boolean,
): ValidationType {
  if (originalAddress[key]?.toLocaleLowerCase() !== suggestedAddress[key]?.toLocaleLowerCase()) {
    return isForOriginalAddress ? ValidationType.Invalid : ValidationType.Valid;
  }

  return ValidationType.None;
}
