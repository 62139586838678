import { AppDispatch } from '@/Redux/ConfigureStore';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { emailSendModalAsyncActions } from '../services/reducer';
import { EmailTemplateRow } from '@/Types/EmailTemplateRow';

export const useEmailSendModalActions = (
  selectedEmailTemplate: EmailTemplateRow | null,
  selectedSalesIds: number[],
  closeModalWindow?: () => void,
) => {
  const dispatch: AppDispatch = useDispatch();

  const sendEmailForOrders = useCallback(() => {
    if (selectedEmailTemplate) {
      dispatch(
        emailSendModalAsyncActions.sendEmailForOrders({
          templateGuid: selectedEmailTemplate.guid,
          orderIds: selectedSalesIds,
        }),
      ).then((response: any) => {
        if (!response.errors) {
          closeModalWindow?.();
        }
      });
    } // if
  }, [selectedEmailTemplate, selectedSalesIds, closeModalWindow]);

  const getEmailPreviewForOrder = useCallback(() => {
    if (selectedEmailTemplate) {
      dispatch(
        emailSendModalAsyncActions.getEmailPreviewForOrder({
          templateGuid: selectedEmailTemplate.guid,
          orderId: selectedSalesIds[0],
        }),
      );
    } // if
  }, [selectedEmailTemplate, selectedSalesIds]);

  const sendEmail = useCallback(() => {
    if (selectedEmailTemplate?.previewBeforeSending && selectedSalesIds.length === 1) {
      getEmailPreviewForOrder();
    } else {
      sendEmailForOrders();
    } // if
  }, [selectedEmailTemplate, selectedSalesIds, sendEmailForOrders, getEmailPreviewForOrder]);

  return {
    sendEmail,
  };
};
