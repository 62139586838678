import { orderAutomatizationAsyncActions } from '@/ModalWindows/OrderAutomatizationModal/services/reducer';
import { OrderAutomatizaionRow } from '@/Types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/Redux/ConfigureStore';

export const useOrderPackagesLoader = (rowData: OrderAutomatizaionRow, ownContract: '' | 'yes' | 'no') => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (ownContract) {
      dispatch(
        orderAutomatizationAsyncActions.getPackagesForOrderRequest({
          orderId: rowData.orderId,
          isOwnContract: ownContract === 'yes',
        }),
      );
    } // if
  }, [rowData, ownContract]);

  return {};
};
