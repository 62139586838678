import React from 'react';
import { TourStep } from '@/Components/Tour/Tour';

export function useAddressBookSettings() {
  const tour1: TourStep[] = [
    {
      content: <div>Address book settings 1</div>,
      placement: 'auto',
      target: '.item1',
      title: 'Address book',
      disableBeacon: true,
    },
    {
      content: <div>Address book settings 2</div>,
      placement: 'auto',
      target: '.item2',
      title: 'Address book',
      disableBeacon: true,
    },
  ];

  return {
    tour1,
  };
}
