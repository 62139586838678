import { ApiRoutingUtils } from '@/Routing';
import { OriginalProductModel } from '@/Models';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('original-products');

async function getProducts(): Promise<OriginalProductModel[]> {
  return rest.get(url);
}

export const OriginalProductsApi = {
  getProducts,
};
