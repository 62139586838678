import { useMpAccountPairOptions } from '@/Hooks/TreeViewOptions';
import { MarketplaceType } from '@/Enums';

export const useUpdateByFileTabOptions = () => {
  const mpOptions = useMpAccountPairOptions([MarketplaceType.Amazon]);

  return {
    mpOptions,
  };
};
