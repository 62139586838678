import { createAsyncThunk } from '@reduxjs/toolkit';
import { ScanBarcodeExternalResponse } from 'src/Api/UserWarehouse/Transfers/External/Responses/ScanBarcodeExternalResponse';
import { ScanBarcodeExternalRequest } from 'src/Api/UserWarehouse/Transfers/External/Requests/ScanBarcodeExternalRequest';
import { WarehouseExternalTransferApi } from 'src/Api/UserWarehouse/Transfers/External/WarehouseExternalTransferApi';
import { SetBatchOutWarehouseRequest } from 'src/Api/UserWarehouse/Transfers/External/Requests/SetBatchOutWarehouseRequest';
import { SetBatchOutWarehouseResponse } from 'src/Api/UserWarehouse/Transfers/External/Responses/SetBatchOutWarehouseResponse';
import { UserWarehouseTransferBatchApi } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/UserWarehouseTransferBatchApi';
import { GetAvailableOutWarehousesForTransferResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/GetAvailableOutWarehousesForTransferResponse';
import { GetAvailableOutWarehousesForTransferRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/GetAvailableOutWarehousesForTransferRequest';
import { CreateWarehousePostingBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CreateWarehousePostingBatchRequest';
import { CreateWarehousePostingBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CreateWarehousePostingBatchResponse';
import { SetUserWarehouseBatchStateResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/SetUserWarehouseBatchStateResponse';
import { SetUserWarehouseBatchStateRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/SetUserWarehouseBatchStateRequest';
import { CloseUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CloseUserWarehouseBatchRequest';
import { CloseUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CloseUserWarehouseBatchResponse';
import { RemoveUserWarehousePostingBatchItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/RemoveUserWarehousePostingBatchItemResponse';
import { RemoveUserWarehousePostingBatchItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/RemoveUserWarehousePostingBatchItemRequest';
import { GetExternalTransferBatchRequest } from 'src/Api/UserWarehouse/Transfers/External/Requests/GetExternalTransferBatchRequest';
import { GetExternalTransferBatchResponse } from 'src/Api/UserWarehouse/Transfers/External/Responses/GetExternalTransferBatchResponse';
import { CancelUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CancelUserWarehouseBatchResponse';
import { CancelUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CancelUserWarehouseBatchRequest';

const scanBarcode = createAsyncThunk<ScanBarcodeExternalResponse, ScanBarcodeExternalRequest>(
  'external-transfer/scan-barcode',
  async (request) => {
    return WarehouseExternalTransferApi.scanBarcode(request);
  },
);

const setBatchOutWarehouse = createAsyncThunk<SetBatchOutWarehouseResponse, SetBatchOutWarehouseRequest>(
  'external-transfer/set-batch-out-warehouse',
  async (request) => {
    return WarehouseExternalTransferApi.setBatchOutWarehouse(request);
  },
);

const getAvailableWarehouses = createAsyncThunk<
  GetAvailableOutWarehousesForTransferResponse,
  GetAvailableOutWarehousesForTransferRequest
>('external-transfer/get-available-warehouses', async (request) => {
  return UserWarehouseTransferBatchApi.getAvailableOutWarehouses(request);
});

const createBatch = createAsyncThunk<CreateWarehousePostingBatchResponse, CreateWarehousePostingBatchRequest>(
  'external-transfer/create-posting-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.createBatch(request);
  },
);

const cancelBatch = createAsyncThunk<CancelUserWarehouseBatchResponse, CancelUserWarehouseBatchRequest>(
  'external-transfer/cancel-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.cancelBatch(request);
  },
);

const setBatchState = createAsyncThunk<SetUserWarehouseBatchStateResponse, SetUserWarehouseBatchStateRequest>(
  'external-transfer/set-batch-state',
  async (request) => {
    return UserWarehouseTransferBatchApi.setBatchState(request);
  },
);

const closeBatch = createAsyncThunk<CloseUserWarehouseBatchResponse, CloseUserWarehouseBatchRequest>(
  'external-transfer/close-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.closeBatch(request);
  },
);

const removeBatchItem = createAsyncThunk<
  RemoveUserWarehousePostingBatchItemResponse,
  RemoveUserWarehousePostingBatchItemRequest
>('external-transfer/remove-batch-item', async (request) => {
  return UserWarehouseTransferBatchApi.removeBatchItem(request);
});

const getBatch = createAsyncThunk<GetExternalTransferBatchResponse, GetExternalTransferBatchRequest>(
  'external-transfer/get-batch',
  async (request) => {
    return WarehouseExternalTransferApi.getBatch(request);
  },
);

export const externalTransferAsyncActions = {
  scanBarcode,
  setBatchOutWarehouse,
  getAvailableWarehouses,
  createBatch,
  setBatchState,
  closeBatch,
  removeBatchItem,
  getBatch,
  cancelBatch,
};
