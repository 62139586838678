import { LocalizationType } from '@/Enums';
import { CardinalPronounsType } from '@/Enums/CardinalPronounsType';

function insertParams(text: string, parameters: (string | number)[]): string {
  parameters.forEach((param, idx) => {
    text = text.replace(`{${idx}}`, param.toString());
  });

  return text;
} // insertParams

function getCardinalPronounByCount(sourceCount: number, localization?: LocalizationType): CardinalPronounsType {
  if (sourceCount === 0) {
    return CardinalPronounsType.Empty;
  }

  const count = sourceCount % 100;
  if (count >= 11 && count <= 19) {
    return CardinalPronounsType.Many;
  }

  const i = count % 10;
  switch (i) {
    case 1:
      if (sourceCount > 1 && localization !== LocalizationType.ruRU) return CardinalPronounsType.Many;
      return CardinalPronounsType.One;
    case 2:
    case 3:
    case 4:
      return CardinalPronounsType.Few;
    default:
      return CardinalPronounsType.Many;
  }
} // getCardinalPronounByCount

function insertTranslatedParamsToString(
  str: string,
  params: (string | number)[],
  transaction?: { [key: string]: { [key: string]: string } },
  localization?: LocalizationType,
) {
  if (!str) return '';

  params.forEach((param, idx) => {
    str = str.replace(`{${idx}}`, String(param)); // Replace {0} parameters
    if (isNaN(+param)) {
      return;
    }
    if (transaction) {
      str = getFillAdditionalParameters(str, String(param), idx, transaction, localization);
    }
  });

  return str;
} // insertTranslatedParamsToString

function getFillAdditionalParameters(
  str: string,
  param: string,
  idx: number,
  transaction: { [key: string]: { [key: string]: string } },
  localizationType?: LocalizationType,
) {
  const numberParam = param ? +param : 0;

  let startIndex = 0;
  do {
    startIndex = str.indexOf(`{${idx}_`);
    if (startIndex !== -1) {
      const fieldStartIndex = startIndex + idx.toString().length + 2;
      const endIndex = fieldStartIndex + str.substring(fieldStartIndex).indexOf('}');

      const localizationKey = str.substring(fieldStartIndex, endIndex);
      const noun = transaction[localizationKey as keyof typeof transaction];
      if (noun) {
        const cardinalPronounsType = getCardinalPronounByCount(numberParam, localizationType);
        str = str.replace(str.slice(startIndex, endIndex + 1), noun[cardinalPronounsType as keyof typeof noun] ?? '');
      } else {
        startIndex = -1;
      }
    }
  } while (startIndex !== -1);

  return str;
} // getFillAdditionalParameters

export const localizationUtils = {
  insertParams,
  getCardinalPronounByCount,
  insertTranslatedParamsToString,
};
