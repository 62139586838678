import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetAvailableOutWarehousesForTransferResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/GetAvailableOutWarehousesForTransferResponse';
import { GetAvailableOutWarehousesForTransferRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/GetAvailableOutWarehousesForTransferRequest';
import { UserWarehouseTransferBatchApi } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/UserWarehouseTransferBatchApi';
import { CreateWarehousePostingBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CreateWarehousePostingBatchResponse';
import { CreateWarehousePostingBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CreateWarehousePostingBatchRequest';
import { ScanBarcodeFulfillmentResponse } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Responses/ScanBarcodeFulfillmentResponse';
import { ScanBarcodeFulfillmentRequest } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Requests/ScanBarcodeFulfillmentRequest';
import { WarehouseFulfillmentTransferApi } from 'src/Api/UserWarehouse/Transfers/Fulfillment/WarehouseFulfillmentTransferApi';
import { SendFulfillmentTransferRequest } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Requests/SendFulfillmentTransferRequest';
import { SendFulfillmentTransferResponse } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Responses/SendFulfillmentTransferResponse';
import { SetUserWarehouseBatchStateResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/SetUserWarehouseBatchStateResponse';
import { SetUserWarehouseBatchStateRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/SetUserWarehouseBatchStateRequest';
import { CloseUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CloseUserWarehouseBatchResponse';
import { CloseUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CloseUserWarehouseBatchRequest';
import { RemoveUserWarehousePostingBatchItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/RemoveUserWarehousePostingBatchItemResponse';
import { RemoveUserWarehousePostingBatchItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/RemoveUserWarehousePostingBatchItemRequest';
import { GetFulfillmentTransferBatchResponse } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Responses/GetFulfillmentTransferBatchResponse';
import { GetFulfillmentTransferBatchRequest } from 'src/Api/UserWarehouse/Transfers/Fulfillment/Requests/GetFulfillmentTransferBatchRequest';
import { CancelUserWarehouseBatchResponse } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Responses/CancelUserWarehouseBatchResponse';
import { CancelUserWarehouseBatchRequest } from 'src/Api/UserWarehouse/UserWarehouseTransferBatch/Requests/CancelUserWarehouseBatchRequest';

const getAvailableWarehouses = createAsyncThunk<
  GetAvailableOutWarehousesForTransferResponse,
  GetAvailableOutWarehousesForTransferRequest
>('fulfillment-transfer/get-available-warehouses', async (request) => {
  return UserWarehouseTransferBatchApi.getAvailableOutWarehouses(request);
});

const createBatch = createAsyncThunk<CreateWarehousePostingBatchResponse, CreateWarehousePostingBatchRequest>(
  'fulfillment-transfer/create-posting-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.createBatch(request);
  },
);

const scanBarcode = createAsyncThunk<ScanBarcodeFulfillmentResponse, ScanBarcodeFulfillmentRequest>(
  'fulfillment-transfer/scan-barcode',
  async (request) => {
    return WarehouseFulfillmentTransferApi.scanBarcode(request);
  },
);

const sendFulfillment = createAsyncThunk<SendFulfillmentTransferResponse, SendFulfillmentTransferRequest>(
  'fulfillment-transfer/send-fulfillment',
  async (request) => {
    return WarehouseFulfillmentTransferApi.sendFulfillment(request);
  },
);

const setBatchState = createAsyncThunk<SetUserWarehouseBatchStateResponse, SetUserWarehouseBatchStateRequest>(
  'fulfillment-transfer/set-batch-state',
  async (request) => {
    return UserWarehouseTransferBatchApi.setBatchState(request);
  },
);

const closeBatch = createAsyncThunk<CloseUserWarehouseBatchResponse, CloseUserWarehouseBatchRequest>(
  'fulfillment-transfer/close-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.closeBatch(request);
  },
);

const cancelBatch = createAsyncThunk<CancelUserWarehouseBatchResponse, CancelUserWarehouseBatchRequest>(
  'fulfillment-transfer/cancel-batch',
  async (request) => {
    return UserWarehouseTransferBatchApi.cancelBatch(request);
  },
);

const removeBatchItem = createAsyncThunk<
  RemoveUserWarehousePostingBatchItemResponse,
  RemoveUserWarehousePostingBatchItemRequest
>('fulfillment-transfer/remove-batch-item', async (request) => {
  return UserWarehouseTransferBatchApi.removeBatchItem(request);
});

const getBatch = createAsyncThunk<GetFulfillmentTransferBatchResponse, GetFulfillmentTransferBatchRequest>(
  'fulfillment-transfer/get-batch',
  async (request) => {
    return WarehouseFulfillmentTransferApi.getBatch(request);
  },
);

export const fulfillmentTransferAsyncActions = {
  scanBarcode,
  sendFulfillment,
  getAvailableWarehouses,
  createBatch,
  setBatchState,
  closeBatch,
  removeBatchItem,
  getBatch,
  cancelBatch,
};
