import { createSlice } from '@reduxjs/toolkit';
import { globalHistory } from '@/GlobalHistory';
import { AppUserModel, UserSettingModel } from '@/Models';
import { Nullable } from '@/Types';
import { userAsyncActions } from './asyncActions';
import { userUtils } from './utils';
import { PagesRouting } from '@/Routing';
import { AppUserSettingNameType } from '@/Enums';
import { dateTimeUtils } from '@/Utils';
import { mpAccountsAsyncThunks } from '@/Pages/Settings/modules/MarketplacesSettings/services/asyncThunks';
import { mpAccountMapping } from '@/Mapping/MpAccountMapping';

export type UserState = {
  currentUser: Nullable<AppUserModel>;
  settings: { [key in AppUserSettingNameType]?: UserSettingModel };
};

const initialState: UserState = {
  currentUser: null,
  settings: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userAsyncActions.loginRequest.fulfilled, (state, action) => {
        state.currentUser = action.payload.user;

        if (!action.payload.user.isEmailConfirmed) {
          setTimeout(() => globalHistory.push(PagesRouting.AuthorizationPages.EmailConfirmationPage), 500);
        } else {
          setTimeout(() => globalHistory.push(userUtils.getRedirectRoute(action.payload.user)), 500);
        } // if
      })
      .addCase(userAsyncActions.registerUser.fulfilled, (state, action) => {
        state.currentUser = action.payload.user;
        setTimeout(() => globalHistory.push(PagesRouting.AuthorizationPages.EmailConfirmationPage), 500);
      })
      .addCase(userAsyncActions.updateUser.fulfilled, (state, action) => {
        state.currentUser = action.payload.appUser;
      })
      .addCase(userAsyncActions.getCurrentUserInfo.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.currentUser.userSettings.forEach((setting) => {
          state.settings[setting.name] = setting;
        });

        dateTimeUtils.setDefaultSettings(state.settings);
      })
      .addCase(userAsyncActions.checkEmailToken.fulfilled, (state, action) => {
        state.currentUser = action.payload.user;
      })
      .addCase(userAsyncActions.changeUserPassword.fulfilled, (state, action) => {
        state.currentUser = action.payload.user;
      })
      .addCase(userAsyncActions.logoutRequest.fulfilled, (state) => {
        state.currentUser = null;
        setTimeout(() => globalHistory.push(PagesRouting.AuthorizationPages.LoginPage), 500);
      })
      .addCase(userAsyncActions.addOrUpdateSetting.fulfilled, (state, action) => {
        const setting = action.payload;

        if (setting != null) {
          state.settings[setting.name] = setting;
        }
      })
      .addCase(userAsyncActions.updateRegionalSettings.fulfilled, (state, action) => {
        action.payload.calendarSettings.forEach((setting) => {
          state.settings[setting.name] = setting;
        });

        if (state.currentUser) {
          state.currentUser.currencies = action.payload.currencies;

          if (!state.currentUser.isManualMpAccountCreateStepCompleted) {
            setTimeout(() => globalHistory.push(PagesRouting.SettingsPages.MarketplacesSettings), 500);
          } // if
        } // if
      })

      // Mp Accounts changes
      .addCase(mpAccountsAsyncThunks.createMpAccount.fulfilled, (state, action) => {
        if (state.currentUser) {
          state.currentUser.mpAccount.push(mpAccountMapping.toShortModel(action.payload.account));
        }
      })
      .addCase(mpAccountsAsyncThunks.deleteMpAccount.fulfilled, (state, action) => {
        if (state.currentUser) {
          state.currentUser.mpAccount = state.currentUser.mpAccount.filter((acc) => acc.id !== action.payload.id);
        }
      })
      .addCase(mpAccountsAsyncThunks.updateMpAccount.fulfilled, (state, action) => {
        const updatedMpAccount = action.payload.mpAccount;

        if (state.currentUser) {
          state.currentUser.mpAccount = state.currentUser.mpAccount.map((acc) => {
            return acc.id === updatedMpAccount.id ? mpAccountMapping.toShortModel(updatedMpAccount) : acc;
          });
        }
      });
  },
});

const { actions, reducer } = userSlice;

export const userReducer = reducer;
export const userActions = actions;
export const userActionsAsync = userAsyncActions;
