import { BackgroundTaskForProgressList } from '@/Types/BackgroundTaskForProgressList';
import { BackgroundTask } from '@/Models/BackgroundTask';

function toBackgroundTaskForProgressList(backgroundTask: BackgroundTask): BackgroundTaskForProgressList {
  return {
    id: backgroundTask.id,
    current: 0,
    maximum: 0,
    taskType: backgroundTask.taskType,
  };
}

export const backgroundTaskMapping = {
  toBackgroundTaskForProgressList,
};
