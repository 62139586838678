import cn from 'classnames';
import styles from './SideMenu.scss';

export function getModuleItemClassNames(modulePaths: string | string[], pathName: string): string {
  return cn({
    [styles.modules__item]: true,
    [styles.modules__item_selected]: Array.isArray(modulePaths)
      ? modulePaths.includes(pathName)
      : modulePaths === pathName,
  });
}
