import { GenPackageTypeModel } from '@/Models';
import { createSlice } from '@reduxjs/toolkit';
import { asyncActions } from './asyncActions';

export type GenPackageTypesDataState = {
  packageTypes: GenPackageTypeModel[];
};

const initialState: GenPackageTypesDataState = {
  packageTypes: [],
};

const genPackageTypesDataSlice = createSlice({
  name: 'genPackageTypesData',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getPackageTypes.fulfilled, (state, action) => {
        state.packageTypes = action.payload;
      })
      .addCase(asyncActions.createPackageType.fulfilled, (state, action) => {
        state.packageTypes.push(action.payload.packageType);
      })
      .addCase(asyncActions.updatePackageType.fulfilled, (state, action) => {
        state.packageTypes = state.packageTypes.map((item) =>
          item.id === action.payload.packageType.id ? action.payload.packageType : item,
        );
      })
      .addCase(asyncActions.deletePackageType.fulfilled, (state, action) => {
        state.packageTypes = state.packageTypes.filter((item) => item.id !== action.payload);
      });
  },
});

export const genPackageTypesDataReducer = genPackageTypesDataSlice.reducer;
export const genPackageTypesDataActions = genPackageTypesDataSlice.actions;
export const genPackageTypesDataAsyncActions = asyncActions;
