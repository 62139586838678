import { ApiRoutingUtils } from '@/Routing';
import { BinaryResponse } from '@/Api';
import {
  GetAllDocumentTemplateResponse,
  UpdateDocumentTemplateResponse,
  UpdateDocumentTemplateRequest,
  RestoreTemplateRequest,
  RestoreTemplateResponse,
  CopyTemplateRequest,
  CopyTemplateResponse,
  PrinterSettingsResponse,
  PrintUnionDocumentRequest,
} from './';
import { DocumentTemplatePrintType } from '@/Models';
import { rest } from '@/Api/Rest';
import { DocumentTemplateType } from '@/Enums/DocumentTemplateType';
import { DeleteDocumentTemplateResponse } from './Responses/DeleteDocumentTemplateResponse';
import { GetTemplatesCreatedByMarketUserRequest } from '@/Api/DocumentTemplates/Requests/GetTemplatesCreatedByMarketUserRequest';
import { GetTemplatesCreatedByMarketUserResponse } from '@/Api/DocumentTemplates/Responses/GetTemplatesCreatedByMarketUserResponse';
import { PrintUnionPdfFilesRequest } from '@/Api/DocumentTemplates/Requests/PrintUnionPdfFilesRequest';
import { GetTemplateByTypeRequest } from '@/Api/DocumentTemplates/Requests/GetTemplateByTypeRequest';
import { GetTemplateByTypeResponse } from '@/Api/DocumentTemplates/Responses/GetTemplateByTypeResponse';

const url = ApiRoutingUtils.createUrl('document-templates');

async function getAllDocumentTemplates(
  type: DocumentTemplateType,
  printType?: DocumentTemplatePrintType,
): Promise<GetAllDocumentTemplateResponse> {
  return rest.get(url, { type: type, printType: printType });
}

async function updateDocumentTemplate(request: UpdateDocumentTemplateRequest): Promise<UpdateDocumentTemplateResponse> {
  return rest.put(`${url}`, request);
}

async function deleteDocumentTemplate(guid: string): Promise<DeleteDocumentTemplateResponse> {
  return rest.delete(`${url}/${guid}`, null);
}

async function restoreDocumentTemplate(request: RestoreTemplateRequest): Promise<RestoreTemplateResponse> {
  return rest.post(`${url}/restore`, request);
}

async function printDocument(guid: string, orderId: number): Promise<BinaryResponse> {
  return rest.getBinary(`${url}/print/${guid}/${orderId}`);
}

async function printUnionDocument(guid: string, request: PrintUnionDocumentRequest): Promise<BinaryResponse> {
  return rest.postBinary<PrintUnionDocumentRequest>(`${url}/print/${guid}`, request);
}

async function getTemplateByType(request: GetTemplateByTypeRequest): Promise<GetTemplateByTypeResponse> {
  return rest.post(`${url}/template-by-type`, request);
}

async function printUnionPdfFiles(request: PrintUnionPdfFilesRequest): Promise<BinaryResponse> {
  return rest.postBinary(`${url}/print-union-pdf-files`, request);
}

async function copyDocumentTemplate(request: CopyTemplateRequest): Promise<CopyTemplateResponse> {
  return rest.post(`${url}/copy`, request);
}

async function printerSettings(guid: string): Promise<PrinterSettingsResponse> {
  return rest.get(`${url}/printer-settings/${guid}`);
}

async function getTemplatesCreatedByMarketUser({
  type,
  printType,
}: GetTemplatesCreatedByMarketUserRequest): Promise<GetTemplatesCreatedByMarketUserResponse> {
  return rest.get(`${url}/created-by-market-user`, { type, printType });
}

export const DocumentTemplateApi = {
  getAllDocumentTemplates,
  updateDocumentTemplate,
  deleteDocumentTemplate,
  restoreDocumentTemplate,
  printDocument,
  printUnionDocument,
  copyDocumentTemplate,
  printerSettings,
  getTemplatesCreatedByMarketUser,
  printUnionPdfFiles,
  getTemplateByType,
};
