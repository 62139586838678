import { useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { useMemo } from 'react';
import { useLocalization } from '@/Hooks';

export function useIsOrderSplittingDisabled() {
  const selectedSales = useSelector(salesPageSelectors.selectedSales);
  const selectedSale = useSelector(salesPageSelectors.selectedSale);

  const {
    menu: { SplitOrder },
  } = useLocalization();

  const isMoreThanOneOrderSelected = selectedSales.length > 1;
  const isOnlyOnePositionInOrder = (selectedSale?.orderItems.length ?? 0) <= 1;
  const isBlocked = Boolean(selectedSale?.isBlocked);
  const isInvoiceWithoutCancelInvoice = Boolean(selectedSale?.currentInvoiceNumber);

  const ordersSplittingDisabledReason = useMemo(() => {
    if (isMoreThanOneOrderSelected) {
      return SplitOrder.MultiOrdersSelectedMessage;
    }
    if (isOnlyOnePositionInOrder) {
      return SplitOrder.OnlyOnePositionMessage;
    }
    if (isBlocked) {
      return SplitOrder.OrderBlockedMessage;
    }
    if (isInvoiceWithoutCancelInvoice) {
      return SplitOrder.InvoiceWithoutCancelMessage;
    }
    return '';
  }, [isMoreThanOneOrderSelected, isOnlyOnePositionInOrder]);
  return {
    isOrdersSplittingDisabled: !selectedSale || isMoreThanOneOrderSelected || isOnlyOnePositionInOrder,
    ordersSplittingDisabledReason,
  };
}
