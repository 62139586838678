import React from 'react';
import { useFormContext } from '@/Hooks/useFormWrapper';
import cn from 'classnames';
import { useLocalization } from '@/Hooks';
import { useRequired, useShouldBeEmail } from '@/Hooks/Validation';
import { UserCategoryType } from '@/Enums';
import { RegistrationModel } from '@/Models';
import { RefDocsIcon } from '@/Static';
import styles from './SignUpInfoForm.scss';
import sharedStyles from '../../SignUp.scss';
import { useCountryOptions } from '@/Enums/CountryTypeHooks';
import { SignInput } from '@/Components/Controls/SignInputs/SignInput';
import { SignSelectInput } from '@/Components/Controls/SignInputs/SignSelectInput';
import { FormRadio } from '@/Components/Controls/Radio/FormRadio';
import { useShouldBePassword } from '@/Hooks/Validation/useShouldBePassword';

type FormData = Pick<
  RegistrationModel,
  'category' | 'invoiceFirstName' | 'invoiceLastName' | 'invoiceCompany' | 'email' | 'password' | 'companyLocation'
>;

export function SignUpInfoForm(): React.ReactElement {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<FormData>();

  const {
    authorizationPage: {
      SignUp: { InfoForm },
    },
  } = useLocalization();
  const required = useRequired();
  const valueMustBeEmail = useShouldBeEmail();
  const valueMustBePassword = useShouldBePassword();

  const countryOptions = useCountryOptions();

  return (
    <>
      <div className={cn(styles.signUpUserSegment, { [styles.titleHidden]: watch('category') })}>
        <h3 className={cn(styles.signUpUserSegmentTitle, { [sharedStyles.hidden]: watch('category') })}>
          {InfoForm.WouldYouLikeToRegisterAPrivateOrCompanyAccount}
        </h3>

        <div className={styles.signUpUserSegmentRadioButtons}>
          <FormRadio<FormData>
            name={'category'}
            register={register}
            labelText={InfoForm.PrivateAccount}
            value={UserCategoryType.B2C}
            className={styles.signUpUserSegmentRadioButtonText}
            asButton
          />

          <FormRadio<FormData>
            name={'category'}
            register={register}
            labelText={InfoForm.CompanyAccount}
            value={UserCategoryType.B2B}
            className={styles.signUpUserSegmentRadioButtonText}
            asButton
          />

          <RefDocsIcon size={'normal'} />
        </div>
      </div>

      <div className={cn(sharedStyles.signUpFormFields, { [sharedStyles.hidden]: !watch('category') })}>
        <div className={sharedStyles.signUpNameWrapper}>
          <SignInput<FormData>
            name={'invoiceFirstName'}
            register={register}
            placeholder={InfoForm.FirstName}
            error={errors}
          />

          <SignInput<FormData>
            name={'invoiceLastName'}
            register={register}
            placeholder={InfoForm.LastName}
            error={errors}
          />
        </div>

        {watch('category') === UserCategoryType.B2B && (
          <SignInput<FormData>
            name={'invoiceCompany'}
            register={register}
            placeholder={InfoForm.TheNameOfTheCompany}
            error={errors}
          />
        )}

        <SignInput<FormData>
          name={'email'}
          register={register}
          rules={{ ...required, ...valueMustBeEmail }}
          placeholder={InfoForm.Email}
          error={errors}
        />

        <SignInput<FormData>
          name={'password'}
          register={register}
          rules={{
            ...required,
            ...valueMustBePassword,
          }}
          placeholder={InfoForm.Password}
          needShowCheckbox
          type={'password'}
          autocomplete={'new-password'}
          error={errors}
        />

        <SignSelectInput<FormData>
          name={'companyLocation'}
          register={register}
          placeholder={InfoForm.CompanyLocation}
          options={countryOptions}
        />
      </div>
    </>
  );
}
