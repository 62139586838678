import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from '@/Hooks/useFormWrapper';
import cn from 'classnames';
import { withAuthorizationModules } from '@/Hocs';
import { UserCategoryType, UserSegmentType } from '@/Enums';
import { RegistrationModel } from '@/Models';
import { useLocalization, useProgressSteps } from '@/Hooks';
import { userActionsAsync } from '@/Redux/User';
import { SignUpInfoForm, SignUpAddressForm, SignUpOrdersForm, SignUpTerms } from './modules';
import styles from './SignUp.scss';
import { globalSelectors } from '@/Redux/Global/selectors';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { Captcha } from '@/Components/Controls/Captcha/Captcha';

type FormData = RegistrationModel;

function SignUpPageComponent(): React.ReactElement {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(globalSelectors.selectedLanguage);
  const {
    authorizationPage: { Buttons },
    buttons,
  } = useLocalization();

  const methods = useForm<FormData>({ mode: 'onChange' });
  const isFormValid = methods.formState.isValid;

  const { setNext, isStepActive, activeStep, setPrev } = useProgressSteps(2, 0);

  const [formData, setFormData] = useState<FormData>();
  const [captchaToken, setCaptchaToket] = useState<string | null>(null);

  // Form submit
  const onSubmit = (data: FormData) => {
    data.selectedLanguage = selectedLanguage.key;
    dispatch(userActionsAsync.registerUser({ newUser: { ...formData, ...data, segment: UserSegmentType.SimpleUser } }));
    setCaptchaToket(null);
  }; // onSubmit

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={cn(styles.signUp, [{ [styles.signUpTwoColumns]: isStepActive(1) }])}
      >
        {isStepActive(0) && <SignUpInfoForm />}

        {isStepActive(1) && (
          <>
            <SignUpAddressForm
              category={formData?.category ?? UserCategoryType.B2C}
              firstName={formData?.invoiceFirstName ?? ''}
              lastName={formData?.invoiceLastName ?? ''}
              companyName={formData?.invoiceCompany ?? ''}
            />

            <div className={styles.signUpFormsGroup}>
              <SignUpOrdersForm />
              <Captcha token={captchaToken} onChange={setCaptchaToket} />
              <SignUpTerms />
            </div>
          </>
        )}

        {activeStep === 0 && (
          <Button
            text={buttons.Continue}
            theme={BUTTON_THEMES.SUCCESS}
            className={cn({ [styles.hidden]: !methods.watch('category') })}
            onClick={() => {
              methods.trigger();

              if (isFormValid) {
                setFormData((prev) => ({ ...prev, ...methods.getValues() }));
                setNext();
              } // if
            }}
          />
        )}

        {activeStep > 0 && (
          <div className={styles.signUpButtons}>
            <Button text={buttons.PreviousPage} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} onClick={setPrev} />

            {activeStep === 1 && (
              <Button
                text={Buttons.SignUp}
                theme={BUTTON_THEMES.SUCCESS}
                onClick={() => null}
                disabled={!captchaToken}
                type={'submit'}
              />
            )}
          </div>
        )}
      </form>
    </FormProvider>
  );
}

export const SignUpPage = withAuthorizationModules(SignUpPageComponent);
