import { ClassNameProps, OrderFilterModel } from '@/Types';
import React, { FC, useState } from 'react';
import { OrderType } from '@/Enums';
import { useSelector } from 'react-redux';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import cn from 'classnames';
import { useLocalization } from '@/Hooks';
import { useMpAccountTreeViewOptions } from '@/Hooks/TreeViewOptions';
import { FormDateInput } from '@/Components/Controls/DateInput/FormDateInput';
import { useOrderStatusFilterOptions } from '@/Enums/OrderStatusFilterHooks';
import { TreeViewSelectInput } from '@/Components/Controls/SelectInput/TreeViewSelectInput';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import styles from './TableButtonsSection.scss';
import { usePurchaseTableFiltersForm } from './hooks/usePurchaseTableFiltersForm';
import { usePurchaseTableFiltersUpdate } from './hooks/usePurchaseTableFiltersUpdate';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { OrderStatusFilter } from '@/Enums/OrderStatusFilter';

type Props = ClassNameProps;

type FormData = OrderFilterModel & {
  dateRangeTuple: string;
};

export const TableButtonsSection: FC<Props> = ({ className }) => {
  const {
    selectOptions: { Accounts },
    purchasesPage: {
      TableButtonSection: { Update },
    },
  } = useLocalization();

  const newPurchasesCount = useSelector(purchasesPageSelectors.newPurchasesCount);
  const updatedPurchasesExists = useSelector(purchasesPageSelectors.updatedPurchasesExists);
  const { mpTreeViewItems } = useMpAccountTreeViewOptions(undefined, [], true);
  const orderFilters = useSelector(purchasesPageSelectors.orderFilters);
  const { orderStatusFilterOptions } = useOrderStatusFilterOptions(OrderType.Purchase);

  // Form
  const {
    methods: { register, setValue, errors, handleSubmit, watch, getValues },
    rules: { required },
    onSubmit,
  } = usePurchaseTableFiltersForm(orderFilters);

  // Update
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const { executeTask } = usePurchaseTableFiltersUpdate(onSubmit, orderFilters, watch, getValues, setIsFilterChanged);

  const statusFilter = watch('orderStatusFilter');

  return (
    <form
      className={cn(styles.form, className)}
      onSubmit={handleSubmit(() => {
        executeTask();
        setIsFilterChanged(false);
      })}
    >
      <FormSelectInput<FormData>
        valueForSet={orderFilters.orderStatusFilter || undefined}
        setValue={setValue}
        needClearButton={false}
        register={register}
        className={cn(styles.orderStatus)}
        placeholder={'Select status'}
        name="orderStatusFilter"
        options={orderStatusFilterOptions}
      />
      <div className={styles.emptyCell} />

      <div className={styles.container}>
        <TreeViewSelectInput
          itemName={Accounts.ItemUnitName}
          onChange={(values: TreeItemType<string | number>[]) => {
            const ids = values.filter((el) => !isNaN(+el.key)).map((el) => +el.key);
            setValue('mpAccountIds', ids);
          }}
          options={mpTreeViewItems}
          className={styles.account}
        />

        {(statusFilter == OrderStatusFilter.All ||
          statusFilter == OrderStatusFilter.AllArchived ||
          statusFilter == OrderStatusFilter.AllNotArchived ||
          statusFilter == OrderStatusFilter.ReceivedOn) && (
          <FormDateInput<FormData>
            name={'dateRangeTuple'}
            register={register}
            rules={{ ...required }}
            pickerSetValue={setValue}
            error={errors}
            rangesType={'orderDateRanges'}
            className={styles.date}
            needTimePicker={true}
            singleDatePicker={false}
            showCustomRangeLabel={true}
            showCalendar={false}
          />
        )}
      </div>

      {(updatedPurchasesExists || isFilterChanged) && (
        <Button
          notificationNumber={newPurchasesCount}
          showEmptyNotification={updatedPurchasesExists}
          className={styles.searchButton}
          text={Update}
          type={'submit'}
          enableAnimation
          theme={BUTTON_THEMES.PRIMARY}
        />
      )}
    </form>
  );
};
