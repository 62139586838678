import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetCountryVatInfosResponse } from '@/Api/AdminCountryVatRates/GetCountryVatInfosResponse';
import { UpdateCountryVatRatesRequest } from '@/Api/AdminCountryVatRates/UpdateCountryVatRatesRequest';
import { CreateCountryVatRatesRequest } from '@/Api/AdminCountryVatRates/CreateCountryVatRatesRequest';

const url = ApiRoutingUtils.createUrl('admin-country-vat');

async function getCountryVatInfos(): Promise<GetCountryVatInfosResponse> {
  return rest.get(url);
}

async function createCountryVatRates(request: CreateCountryVatRatesRequest): Promise<null> {
  return rest.post(url, request);
}

async function updateCountryVatRates(request: UpdateCountryVatRatesRequest): Promise<null> {
  return rest.put(url, request);
}

export const AdminCountryVatRatesApi = {
  getCountryVatInfos,
  createCountryVatRates,
  updateCountryVatRates,
};
