import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  DocumentTemplateApi,
  GetAllDocumentTemplateResponse,
  UpdateDocumentTemplateResponse,
  UpdateDocumentTemplateRequest,
  RestoreTemplateResponse,
  RestoreTemplateRequest,
  CopyTemplateResponse,
  CopyTemplateRequest,
} from '@/Api/DocumentTemplates';
import { DocumentTemplateModel } from '@/Models';
import { Nullable, Pair } from '@/Types';
import { printService, documentStoreService } from '@/Electron';
import { IPrinterInfo } from '@/Electron/services/PrintService/IPrintService';
import { IDocumentPrinterInfo } from '@/Electron/services/DocumentStoreService/IDocumentStoreService';
import { DocumentTemplateModalType } from '@/Enums';
import { documentTemplateMapping } from '@/Mapping';
import { DocumentTemplateType } from '@/Enums/DocumentTemplateType';
import { DeleteDocumentTemplateResponse } from '@/Api/DocumentTemplates/Responses/DeleteDocumentTemplateResponse';

const getAllDocumentTemplateRequest = createAsyncThunk<GetAllDocumentTemplateResponse>(
  'documentTemplateSettings/getAll',
  async () => {
    return DocumentTemplateApi.getAllDocumentTemplates(DocumentTemplateType.Document);
  },
);

const updateDocumentTemplateRequest = createAsyncThunk<UpdateDocumentTemplateResponse, UpdateDocumentTemplateRequest>(
  'documentTemplateSettings/update',
  async (request: UpdateDocumentTemplateRequest) => {
    return DocumentTemplateApi.updateDocumentTemplate(request);
  },
);

const deleteDocumentTemplateRequest = createAsyncThunk<DeleteDocumentTemplateResponse, string>(
  'documentTemplateSettings/delete',
  async (guid: string) => {
    return DocumentTemplateApi.deleteDocumentTemplate(guid);
  },
);

const restoreDocumentTemplateRequest = createAsyncThunk<RestoreTemplateResponse, RestoreTemplateRequest>(
  'documentTemplateSettings/restore',
  async (request: RestoreTemplateRequest) => {
    return DocumentTemplateApi.restoreDocumentTemplate(request);
  },
);

const getAllPrinterRequest = createAsyncThunk<IPrinterInfo[]>('documentTemplateSettings/getPrinters', async () => {
  return printService.getPrinters();
});

const getDefaultPrinterRequest = createAsyncThunk<Nullable<IPrinterInfo>>(
  'documentTemplateSettings/getDefaultPrinter',
  async () => {
    return printService.getDefaultPrinter();
  },
);

const getSavedPrinterInfoRequest = createAsyncThunk<Nullable<IDocumentPrinterInfo>, string>(
  'documentTemplateSettings/getSavedPrinterInfo',
  async (guid: string) => {
    return documentStoreService.getByDocumentGuid(guid);
  },
);

const savePrinterInfoRequest = createAsyncThunk<IDocumentPrinterInfo, IDocumentPrinterInfo>(
  'documentTemplateSettings/savePrinterInfoRequest',
  async (documentPrinterInfo: IDocumentPrinterInfo) => {
    documentStoreService.addOrUpdate(documentPrinterInfo);
    return documentPrinterInfo;
  },
);

const removePrinterInfoRequest = createAsyncThunk(
  'documentTemplateSettings/removePrinterInfoRequest',
  async (documentGuid: string) => {
    documentStoreService.removeByDocumentGuid(documentGuid);
  },
);

const copyDocumentTemplateRequest = createAsyncThunk<CopyTemplateResponse, CopyTemplateRequest>(
  'documentTemplateSettings/copy',
  async (request: CopyTemplateRequest) => {
    return DocumentTemplateApi.copyDocumentTemplate(request);
  },
);

export type DocumentTemplateSettingsState = {
  modalWindowType: Nullable<DocumentTemplateModalType>;
  selectedTemplate: Nullable<DocumentTemplateModel>;
  templates: DocumentTemplateModel[];
  printers: Pair<any>[];
  defaultPrinter: Nullable<IPrinterInfo>;
  selectedPrinterName: string | undefined;
  isPrintPreview: boolean;
};

const initialState: DocumentTemplateSettingsState = {
  modalWindowType: null,
  selectedTemplate: null,
  templates: [],
  printers: [],
  defaultPrinter: null,
  selectedPrinterName: '',
  isPrintPreview: true,
};

const documentTemplateSettingsSlice = createSlice({
  name: 'documentTemplateSettings',
  initialState,
  reducers: {
    setSelectedTemplate(state, action: PayloadAction<Nullable<DocumentTemplateModel>>) {
      state.selectedTemplate = action.payload;
    },
    setUpdatedTemplate(state, action: PayloadAction<DocumentTemplateModel>) {
      const index = state.templates.findIndex(({ guid }) => guid == action.payload.guid);
      if (index == -1) {
        state.templates.unshift(action.payload);
      } else {
        state.templates = state.templates.map((x) => {
          return x.guid === action.payload.guid ? action.payload : x;
        });
      }
      state.selectedTemplate = action.payload;
    },
    setDirectoryPath(state, action: PayloadAction<string>) {
      if (state.selectedTemplate?.documentTemplateSettings) {
        state.selectedTemplate.documentTemplateSettings.directoryPath = action.payload;
      }
    },
    openModal(state, action: PayloadAction<DocumentTemplateModalType>) {
      state.modalWindowType = action.payload;
    },
    closeModal(state) {
      state.modalWindowType = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDocumentTemplateRequest.fulfilled, (state, action) => {
        state.templates = action.payload.templates?.sort((a, b) => a.name.localeCompare(b.name));
        const guids = state.templates.map((x) => x.guid);
        documentStoreService.upToDateByDocuments(guids);
      })
      .addCase(getAllPrinterRequest.fulfilled, (state, action) => {
        const printers = action.payload;
        state.printers = printers.map((value) => {
          return {
            key: value.name,
            value: value.displayName,
          };
        });
        const names = printers.map((x) => x.name);
        documentStoreService.upToDateByDocuments(names);
      })
      .addCase(deleteDocumentTemplateRequest.fulfilled, (state, action) => {
        state.templates = state.templates.filter((x) => x.guid !== action.payload.guid);
      })
      .addCase(getDefaultPrinterRequest.fulfilled, (state, action) => {
        state.defaultPrinter = action.payload;
        if (state.selectedPrinterName === '' || state.selectedPrinterName === undefined) {
          state.selectedPrinterName = state.defaultPrinter?.name;
        }
      })
      .addCase(getSavedPrinterInfoRequest.fulfilled, (state, action) => {
        state.selectedPrinterName = action.payload?.printerName ?? state.defaultPrinter?.name;
        state.isPrintPreview = action.payload?.isPreview ?? true;
      })
      .addCase(savePrinterInfoRequest.fulfilled, (state, action) => {
        state.selectedPrinterName = action.payload.printerName;
        state.isPrintPreview = action.payload.isPreview;
      })
      .addCase(restoreDocumentTemplateRequest.fulfilled, (state, action) => {
        state.modalWindowType = null;
        const template = state.templates.find(({ guid }) => guid == action.payload.templateGuid);
        if (template) {
          template.name = action.payload.templateName;
        }
      })
      .addCase(copyDocumentTemplateRequest.fulfilled, (state, action) => {
        state.modalWindowType = null;
        const template = documentTemplateMapping.mappingDocumentTemplateModel(
          action.payload.templateGuid,
          action.payload.templateName,
          DocumentTemplateType.Document,
        );
        state.templates.push(template);
      });
  },
});

const { actions, reducer } = documentTemplateSettingsSlice;

export const documentTemplateActions = actions;
export const documentTemplateReducer = reducer;
export const documentTemplateAsyncActions = {
  getAllDocumentTemplateRequest,
  deleteDocumentTemplateRequest,
  updateDocumentTemplateRequest,
  restoreDocumentTemplateRequest,
  getAllPrinterRequest,
  getDefaultPrinterRequest,
  getSavedPrinterInfoRequest,
  savePrinterInfoRequest,
  removePrinterInfoRequest,
  copyDocumentTemplateRequest,
};
