import { ApiRoutingUtils } from '@/Routing';
import { GetDynamicColumnsResponse } from '@/Api/Products/ProductDynamicColumn/Responses/GetDynamicColumnsResponse';
import { rest } from '@/Api/Rest';
import { CreateDynamicColumnRequest } from '@/Api/Products/ProductDynamicColumn/Requests/CreateDynamicColumnRequest';
import { CreateDynamicColumnResponse } from '@/Api/Products/ProductDynamicColumn/Responses/CreateDynamicColumnResponse';
import { UpdateDynamicColumnRequest } from '@/Api/Products/ProductDynamicColumn/Requests/UpdateDynamicColumnRequest';
import { UpdateDynamicColumnResponse } from '@/Api/Products/ProductDynamicColumn/Responses/UpdateDynamicColumnResponse';
import { DeleteDynamicColumnResponse } from '@/Api/Products/ProductDynamicColumn/Responses/DeleteDynamicColumnResponse';
import { AddColumnsToSelectedProductsRequest } from '@/Api/Products/ProductDynamicColumn/Requests/AddColumnsToSelectedProductsRequest';

const url = ApiRoutingUtils.createUrl('dynamic-column');
async function getDynamicColumns(): Promise<GetDynamicColumnsResponse> {
  return rest.get(`${url}`);
}

async function createDynamicColumn(request: CreateDynamicColumnRequest): Promise<CreateDynamicColumnResponse> {
  return rest.post(`${url}`, request);
}

async function updateDynamicColumn(request: UpdateDynamicColumnRequest): Promise<UpdateDynamicColumnResponse> {
  return rest.put(`${url}`, request);
}

async function deleteDynamicColumn(request: number): Promise<DeleteDynamicColumnResponse> {
  return rest.delete(`${request}`, {});
}

async function addColumnsToSelectedProducts(request: AddColumnsToSelectedProductsRequest): Promise<null> {
  return rest.post('add-to-selected-products', request);
}

export const ProductDynamicColumnsApi = {
  getDynamicColumns,
  createDynamicColumn,
  updateDynamicColumn,
  deleteDynamicColumn,
  addColumnsToSelectedProducts,
};
