import { BinaryResponse } from '@/Api';
import { DocumentTemplateApi, PrintUnionDocumentRequest } from '@/Api/DocumentTemplates';
import { Nullable } from '@/Types';
import { IPrinterInfo, IPrintService, PrinterSettings, PrintResult } from './IPrintService';

class WebPrintService implements IPrintService {
  getPrinters(): IPrinterInfo[] {
    return [];
  }

  getDefaultPrinter(): Nullable<IPrinterInfo> {
    return null;
  }

  print(
    guid: string,
    orderIds: number[],
    printerSettings: PrinterSettings,
    callback?: (params: number[]) => void,
  ): Promise<PrintResult> {
    const request: PrintUnionDocumentRequest = {
      orderIds: orderIds,
      copyCount: printerSettings.copyCount,
      isSortWhenPrinting: printerSettings.isSortWhenPrinting,
      automaticallyGeneratedDocumentType: printerSettings.automaticallyGeneratedDocumentType,
    };

    return DocumentTemplateApi.printUnionDocument(guid, request)
      .then((response) => {
        callback?.(orderIds);
        return this.openInWindow(response);
      })
      .catch((error) => {
        console.log('error', error);
        return PrintResult.ErrorDownloading;
      });
  }

  printDocument(documentBase64: string): Promise<PrintResult> {
    const response: BinaryResponse = {
      contentType: '',
      fileName: '',
      data: Buffer.from(documentBase64, 'base64'),
    };

    return Promise.resolve(this.openInWindow(response));
  }

  printDirect(response: BinaryResponse): Promise<PrintResult> {
    return Promise.resolve(this.openInWindow(response));
  }

  openInWindow(response: BinaryResponse) {
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    window.open(url);

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 10000);

    return PrintResult.Success;
  }
}

export const printService = new WebPrintService();
