import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { LogUtil } from '@/Utils';
import { AppNotification } from '@/SignalR/Data';
import { NotificationHubMethod } from '../Data/NotificationHubMethod';
import { BackgroundTask } from '@/Models/BackgroundTask';
import { BackgroundTaskProgress } from '@/Models/BackgroundTaskProgress';
import { RetryPolicy } from '@/SignalR';

export class BaseNotificationHub {
  protected connection!: HubConnection;

  constructor(url: string) {
    this.connection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect(new RetryPolicy())
      .configureLogging(LogLevel.Error)
      .build();

    this.start();

    this.connection.onreconnected(() => LogUtil.ColorLog('TaskHub reconnected'));
  }

  protected start = async () => {
    try {
      await this.connection.start();
      LogUtil.ColorLog('TaskHub connected success');
    } catch (err) {
      LogUtil.LogError('TaskHub connection error', err);
      setTimeout(this.start, 5000);
    }
  };

  public onNewNotification = (handle: (data: AppNotification) => void) => {
    this.connection.on(NotificationHubMethod.NEW_NOTIFICATION, (data: AppNotification) => {
      LogUtil.Info('new Notification signalR', data);
      handle(data);
    });
  };

  public onNewBackgroundTask = (handle: (data: BackgroundTask) => void) => {
    this.connection.on(NotificationHubMethod.NEW_BACKGROUND_TASK, (data: BackgroundTask) => {
      LogUtil.Info('new BackgroundTask signalR', data);
      handle(data);
    });
  };

  public onUpdateBackgroundTask = (handle: (data: BackgroundTaskProgress) => void) => {
    this.connection.on(NotificationHubMethod.UPDATE_BACKGROUND_TASK, (data: BackgroundTaskProgress) => {
      LogUtil.Info('new BackgroundTaskProgress signalR', data);
      handle(data);
    });
  };

  public onFinishBackgroundTask = (handle: (data: number) => void) => {
    this.connection.on(NotificationHubMethod.FINISH_BACKGROUND_TASK, (data: number) => {
      LogUtil.Info('new delete background task signalR', data);
      handle(data);
    });
  };
}
