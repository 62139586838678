import { useLocalization } from '@/Hooks';

export const useShouldBeNumber = () => {
  const { validation } = useLocalization();

  return {
    pattern: {
      value: /^\d+$/,
      message: validation.ValueMustBeNumber,
    },
  };
};
