import { AccountChartsApi } from '@/Api/_Microservices/OnlineArchive/AccountCharts/AccountChartsApi';
import { CreateAdminAccountChartRequest } from '@/Api/_Microservices/OnlineArchive/AccountCharts/Admin/CreateAdminAccountChartRequest';
import { DeleteAdminAccountChartRequest } from '@/Api/_Microservices/OnlineArchive/AccountCharts/Admin/DeleteAdminAccountChartRequest';
import { GetAdminAccountChartsRequest } from '@/Api/_Microservices/OnlineArchive/AccountCharts/Admin/GetAdminAccountChartsRequest';
import { UpdateAdminAccountChartRequest } from '@/Api/_Microservices/OnlineArchive/AccountCharts/Admin/UpdateAdminAccountChartRequest';
import { AccountChartTypesApi } from '@/Api/_Microservices/OnlineArchive/AccountChartTypes/AccountChartTypesApi';
import { CreateAdminAccountChartTypeRequest } from '@/Api/_Microservices/OnlineArchive/AccountChartTypes/Admin/CreateAdminAccountChartTypeRequest';
import { DeleteAdminAccountChartTypeRequest } from '@/Api/_Microservices/OnlineArchive/AccountChartTypes/Admin/DeleteAdminAccountChartTypeRequest';
import { UpdateAdminAccountChartTypeRequest } from '@/Api/_Microservices/OnlineArchive/AccountChartTypes/Admin/UpdateAdminAccountChartTypeRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getAccountCharts = createAsyncThunk(
  'accountCharts/admin-get-accout-charts',
  async (request: GetAdminAccountChartsRequest) => {
    return AccountChartsApi.admin.getAccountChartsForAdmin(request);
  },
);

const createAccountChart = createAsyncThunk(
  'accountCharts/admin-create-account-chart',
  async (request: CreateAdminAccountChartRequest) => {
    return AccountChartsApi.admin.createAccountChartForAdmin(request);
  },
);

const updateAccountChart = createAsyncThunk(
  'accountCharts/admin-update-account-chart',
  async (request: UpdateAdminAccountChartRequest) => {
    return AccountChartsApi.admin.updateAccountChartForAdmin(request);
  },
);

const deleteAccountChart = createAsyncThunk(
  'accountCharts/admin-delete-account-chart',
  async (request: DeleteAdminAccountChartRequest) => {
    return AccountChartsApi.admin.deleteAccountChartForAdmin(request);
  },
);

const createAccountChartType = createAsyncThunk(
  'accountCharts/admin-create-account-chart-type',
  async (request: CreateAdminAccountChartTypeRequest) => {
    return AccountChartTypesApi.admin.createAccountChartType(request);
  },
);

const updateAccountChartType = createAsyncThunk(
  'accountCharts/admin-update-account-chart-type',
  async (request: UpdateAdminAccountChartTypeRequest) => {
    return AccountChartTypesApi.admin.updateAccountChartType(request);
  },
);

const deleteAccountChartType = createAsyncThunk(
  'accountCharts/admin-delete-account-chart-type',
  async (request: DeleteAdminAccountChartTypeRequest) => {
    return AccountChartTypesApi.admin.deleteAccountChartType(request);
  },
);

export const asyncActions = {
  getAccountCharts,
  createAccountChart,
  updateAccountChart,
  deleteAccountChart,
  createAccountChartType,
  updateAccountChartType,
  deleteAccountChartType,
};
