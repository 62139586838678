import { ApiRoutingUtils } from '@/Routing';
import { UploadTemplateModel } from '@/Models/_Microservices/OnlineArchive/UploadTemplateModel';
import { rest } from '@/Api/Rest';
import { CreateUploadTemplateRequest } from './CreateUploadTemplateRequest';
import { CreateUploadTemplateResponse } from './CreateUploadTemplateResponse';
import { UpdateUploadTemplateRequest } from './UpdateUploadTemplateRequest';
import { UpdateUploadTemplateResponse } from './UpdateUploadTemplateResponse';
import { GetUploadTemplatesRequest } from './GetUploadTemplatesRequest';

const url = ApiRoutingUtils.createOnlineArchiveUrl('upload-templates');

const getUploadTemplates = (request: GetUploadTemplatesRequest): Promise<UploadTemplateModel[]> => {
  return rest.get(url, request);
};

const createUploadTemplate = (request: CreateUploadTemplateRequest): Promise<CreateUploadTemplateResponse> => {
  return rest.post(url, request);
};

const updateUploadTemplate = (request: UpdateUploadTemplateRequest): Promise<UpdateUploadTemplateResponse> => {
  return rest.put(url, request);
};

const deleteUploadTemplate = (id: number): Promise<number> => {
  return rest.delete(`${url}/${id}`, {});
};

export const UploadTemplatesApi = {
  getUploadTemplates,
  createUploadTemplate,
  updateUploadTemplate,
  deleteUploadTemplate,
};
