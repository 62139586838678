import { useSelector } from 'react-redux';
import { userSelectors } from '@/Redux/User';
import { UserSegmentType } from '@/Enums';
import { PagesRouting } from '@/Routing';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

export function usePermissionDeniedRedirectRoute(): string {
  const segment = useSelector(userSelectors.userSegment);
  const { pathname } = useLocation();

  return useMemo(() => {
    switch (segment) {
      case undefined:
      case UserSegmentType.SimpleUser: {
        return PagesRouting.MainPages.SalesPage.Sales;
      }
      case UserSegmentType.BusinessUser:
      case UserSegmentType.MarketUser: {
        return pathname.indexOf('/admin') > 0
          ? PagesRouting.AdminPages.AdminPermissionDenied
          : PagesRouting.MainPages.PermissionDenied;
      }

      default: {
        return PagesRouting.MainPages.SalesPage.Sales;
      }
    }
  }, [segment, pathname]);
}
