function createUrl(controllerName: string, version = 1, host = API_HOST): string {
  return `${host}/api/v${version}/${controllerName}`;
}

function createStaticFilesUrl(staticFilesFolder: string, host = API_HOST): string {
  return `${host}/${staticFilesFolder}`;
}

function createOnlineArchiveUrl(controllerName: string, version = 1, host = ONLINE_ARCHIVE_HOST): string {
  return `${host}/online-archive/v${version}/${controllerName}`;
}

function createSyncTranslationsUrl(controllerName: string, version = 1, host = SYNC_TRANSLATIONS_HOST): string {
  return `${host}/api/v${version}/${controllerName}`;
}

function createOnlineArchiveFilesUrl(
  controllerName: string,
  fileName: string,
  actionName?: string,
  version = 1,
  host = ONLINE_ARCHIVE_HOST,
): string {
  return `${host}/online-archive/v${version}/${controllerName}/${
    actionName ? `${actionName}/${fileName}` : `${fileName}`
  }`;
}

export const ApiRoutingUtils = {
  createUrl,
  createStaticFilesUrl,
  createOnlineArchiveUrl,
  createOnlineArchiveFilesUrl,
  createSyncTranslationsUrl,
};

const AppAuthRoute = 'app-auth';
const AppUserRoute = 'app-user';

export const ApiRouting = {
  AppAuth: {
    Login: `/${AppAuthRoute}/login`,
  },
  AppUser: {
    Register: `/${AppUserRoute}/register`,
  },

  Hubs: {
    NotificationHubRoute: `${API_HOST}/notification-hub`,
    OnlineArchiveNotificationHubRoute: `${ONLINE_ARCHIVE_HOST}/notification-hub`,
    SessionsHubRoute: `${API_HOST}/sessions-hub`,
    WarehouseTransferHubRoute: `${API_HOST}/warehouse-transfer-hub`,
    SalesHubRoute: `${API_HOST}/sales-hub`,
  },
};
