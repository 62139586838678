import { EmailApi } from '@/Api/Email/EmailApi';
import { GetEmailPreviewForOrderRequest } from '@/Api/Email/GetEmailPreviewForOrderRequest';
import { GetEmailPreviewForOrderResponse } from '@/Api/Email/GetEmailPreviewForOrderResponse';
import { SendEmailForOrdersRequest } from '@/Api/Email/SendEmailForOrdersRequest';
import { SendFormattedEmailForOrderRequest } from '@/Api/Email/SendFormattedEmailForOrderRequest';
import { LocalizationType } from '@/Enums';
import { TranslationType } from '@/Localization/LanguageKeys';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentTemplateApi } from '@/Api/DocumentTemplates';
import { DocumentTemplateType } from '@/Enums/DocumentTemplateType';

const sendEmailForOrders = createAsyncThunk(
  'emailSendModalSlice/send-email-for-orders',
  async (request: SendEmailForOrdersRequest, thunkApi) => {
    const result = await EmailApi.sendEmailForOrders(request);

    const { global } = thunkApi.getState() as any;

    return {
      result: result,
      translation: global.translation as TranslationType,
      localization: global.localization as LocalizationType,
    };
  },
);

const sendFormattedEmailForOrder = createAsyncThunk(
  'emailSendModalSlice/send -formatted-email-for-order',
  async (request: SendFormattedEmailForOrderRequest, thunkApi) => {
    const result = await EmailApi.sendFormatterEmailForOrder(request);

    const { global } = thunkApi.getState() as any;

    return {
      result,
      translation: global.translation as TranslationType,
      localization: global.localization as LocalizationType,
    };
  },
);

const getEmailPreviewForOrder = createAsyncThunk<GetEmailPreviewForOrderResponse, GetEmailPreviewForOrderRequest>(
  'emailSendModalSlice/get-email-preview-for-order',
  async (request) => {
    return EmailApi.getEmailPreviewForOrder(request);
  },
);

const getEmailTemplates = createAsyncThunk('emailSendModalSlice/get-email-templates', async () => {
  return DocumentTemplateApi.getAllDocumentTemplates(DocumentTemplateType.Email);
});

export const asyncActions = {
  sendEmailForOrders,
  sendFormattedEmailForOrder,
  getEmailPreviewForOrder,
  getEmailTemplates,
};
