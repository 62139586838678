import { ClassNameProps, DisabledProps } from '@/Types';
import cn from 'classnames';
import styles from './TreeViewSelectInput.scss';
import DropDownBox from 'devextreme-react/drop-down-box';
import React, { useEffect, useRef, useState } from 'react';
import { TreeView as DevextremeTreeView } from 'devextreme-react/tree-view';
import { ErrorTooltip, errorTooltipClass } from '../ErrorTooltip/ErrorTooltip';
import { useFormError, useLocalization } from '@/Hooks';
import { FieldErrors } from 'react-hook-form';
import { TreeItemType, TreeView } from '@/Components/TreeView/TreeView';
import { treeViewUtils } from '@/Utils/TreeViewUtils';

type Props<T extends string | number> = {
  name?: string;
  placeholder?: string;
  /**
   * Pass this prop with useMemo
   */
  options: TreeItemType<T>[];
  onChange?: (values: TreeItemType<T>[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  itemName?: string;
  error?: FieldErrors;
  needClearButton?: boolean;
  selectionMode?: 'single' | 'singleCheckbox' | 'multiple';
  checkboxesMode?: 'single' | 'singleCheckbox' | 'multiple';
  selectOnlyLastLevel?: boolean;
  needSearch?: boolean;
} & ClassNameProps &
  DisabledProps;

export function TreeViewSelectInput<T extends string | number = string>({
  name = '',
  options,
  className,
  onChange,
  onFocus,
  onBlur,
  readOnly = false,
  itemName = 'item(s)',
  error,
  needClearButton = true,
  selectionMode = 'multiple',
  checkboxesMode = 'multiple',
  disabled = false,
  selectOnlyLastLevel = false,
  needSearch,
}: Props<T>) {
  const {
    common: { All },
  } = useLocalization();

  const errorMessage = useFormError(name, error);
  const treeViewRef = useRef<DevextremeTreeView<T>>();
  const [dropDownBoxValue, setDropDownBoxValue] = useState('');
  const allChildNodes = treeViewUtils.getFlattenedTreeViewItems(options)?.filter((el) => !el.items);

  const getSelectionMode = () => {
    if (selectionMode === 'single' || selectionMode === 'singleCheckbox') return 'single';
    return 'multiple';
  };

  const getCheckboxesMode = () => {
    if (readOnly) return 'normal';
    if (checkboxesMode === 'single') return 'none';
    if (checkboxesMode === 'singleCheckbox') return 'normal';
    return 'selectAll';
  };

  useEffect(() => {
    const selectedNodesCount = treeViewUtils.getSelectedKeys(options, true).length;
    setDropDownBoxValue(allChildNodes?.length != selectedNodesCount ? `${selectedNodesCount} ${itemName}` : All);
  }, [options, All]);

  const TreeViewSelect = () => (
    <TreeView
      treeViewRef={treeViewRef}
      width={'auto'}
      className={styles.dropDownTreeView}
      selectionMode={getSelectionMode()}
      checkboxesMode={getCheckboxesMode()}
      items={readOnly ? options.filter((item) => item.selected) : options}
      readOnly={readOnly}
      hoverStateEnabled={true}
      onBlur={onBlur}
      needSearch={needSearch}
      selectOnlyLastLevel={selectOnlyLastLevel}
      onSelectionChanged={(selected) => {
        if (readOnly) return;

        onChange?.(selected.nodes);
        const selectedNodesCount = selected.nodes.length;
        setDropDownBoxValue(allChildNodes?.length != selectedNodesCount ? `${selectedNodesCount} ${itemName}` : All);
      }}
    />
  );

  return (
    <div className={cn(styles.selectWrapper, className)}>
      <ErrorTooltip error={errorMessage}>
        <DropDownBox
          name={name}
          placeholder={dropDownBoxValue}
          deferRendering={false}
          showClearButton={needClearButton}
          className={cn(styles.select, { [errorTooltipClass]: errorMessage })}
          disabled={disabled}
          onFocusIn={onFocus}
          dropDownOptions={{
            minWidth: '100%',
            width: 'auto',
          }}
          onValueChanged={(ev) => {
            if (readOnly || !ev.previousValue?.length) return;

            if (!ev.value) {
              setDropDownBoxValue(`0 ${itemName}`);
              treeViewRef?.current?.instance?.unselectAll();
              setDropDownBoxValue(ev.value);
              return;
            } // if

            if (!Number(ev.value.replace(` ${itemName}`, ''))) {
              onChange?.([]);
              treeViewRef?.current?.instance?.unselectAll();
              setDropDownBoxValue(ev.value);
            } // if
          }}
          contentRender={TreeViewSelect}
        />
      </ErrorTooltip>
    </div>
  );
}
