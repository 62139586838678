import { GenProductTypeModel } from '@/Models';
import { RootState } from '@/Redux/RootReducer';
import { ObjectUtils } from '@/Utils';

export function getLocalizationName(x: GenProductTypeModel, state: RootState): string {
  return getLocalizationNameByName(x.name, state);
}

export function getLocalizationNameByName(name: string, state: RootState): string {
  const settingsProducts = state.global.translation.Pages.WarehouseLogistics.SettingsProducts;
  const path = `Types.GenLogisticType.${name}`;
  return ObjectUtils.getObjectPathValue(settingsProducts, path) || name;
}

export const genLogisticTypesUtils = {
  getLocalizationName,
  getLocalizationNameByName,
};
