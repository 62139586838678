import React from 'react';
import styles from './Icons.scss';
import cn from 'classnames';
import { IconProps, Icons } from '@/Static/IconsRes';
import { getIconClasses } from '@/Static/IconUtils';

type Props = {
  onClick: () => void;
} & IconProps;

export const DeleteIcon = ({ onClick, size = 'small', className }: Props) => {
  return (
    <img src={Icons.Delete} alt="img" className={cn(styles.icon, className, getIconClasses(size))} onClick={onClick} />
  );
};
