import { ApiRoutingUtils } from '@/Routing';
import {
  CreateGeneratorCountryZoneRequest,
  CreateGeneratorCountryZoneResponse,
  UpdateGeneratorCountryZoneRequest,
  UpdateGeneratorCountryZoneResponse,
  GetAllGeneratorCountryZoneResponse,
  GetAllGeneratorCountryZoneByCountryResponse,
} from '../GeneratorCountryZones';
import { rest } from '@/Api/Rest';
import { CountryCode } from '@/Enums';

const url = ApiRoutingUtils.createUrl('generator-country-zones');

async function getGeneratorCountryZones(): Promise<GetAllGeneratorCountryZoneResponse> {
  return rest.get(url);
}

async function getGeneratorCountryZonesGroupedByCountry(
  countryCode: CountryCode,
): Promise<GetAllGeneratorCountryZoneByCountryResponse> {
  return rest.get(`${url}/grouped-by-country/${countryCode}`);
}

async function createGeneratorCountryZone(
  request: CreateGeneratorCountryZoneRequest,
): Promise<CreateGeneratorCountryZoneResponse> {
  return rest.post(url, request);
}

async function updateGeneratorCountryZone(
  request: UpdateGeneratorCountryZoneRequest,
): Promise<UpdateGeneratorCountryZoneResponse> {
  return rest.put(url, request);
}

async function deleteGeneratorCountryZone(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, null);
}

export const GeneratorCountryZonesApi = {
  getGeneratorCountryZones,
  getGeneratorCountryZonesGroupedByCountry,
  createGeneratorCountryZone,
  updateGeneratorCountryZone,
  deleteGeneratorCountryZone,
};
