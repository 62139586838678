/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IDocumentPrinterInfo, IDocumentStoreService } from './IDocumentStoreService';
import { Nullable } from '@/Types';

class WebDocumentStoreService implements IDocumentStoreService {
  upToDateByPrinters(names: string[]): void {
    console.log(names);
  }

  upToDateByDocuments(documentGuids: string[]): void {
    console.log(documentGuids);
  }

  addOrUpdate(printerInfo: IDocumentPrinterInfo): void {
    console.log(printerInfo);
  }

  removeByDocumentGuid(documentGuid: string): void {
    console.log(documentGuid);
  }

  getByDocumentGuid(documentGuid: string): Nullable<IDocumentPrinterInfo> {
    return null;
  }
}

export const documentStoreService = new WebDocumentStoreService();
