import React from 'react';
import { Modal } from '@/Components/Modal/Modal';
import styles from './SplitOrdersModal.scss';
import { DataTableWithStore } from '@/Components/DataTable/DataTableWithStore';
import { useDragAndDropTables } from '@/Hooks/useDragAndDropTables';
import { Button } from '@/Components/Controls/Button/Button';
import { ButtonsWrapper } from '@/Components/Forms/ButtonsSection/ButtonsSection';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { DraggableBetweenTablesData } from '@/Components/DataTable/types';
import { useSplitOrdersModal } from '@/ModalWindows/SplitOrdersModal/hooks/useSplitOrdersModal';
import { OrderItemModel } from '@/Models';
import { useSplitOrderColumnInfo } from '@/ModalWindows/SplitOrdersModal/ColumnInfos';
import { DeleteIcon } from '@/Static';
import { EMPTY_ACTION } from '@/Constants/SharedConstants';
import cn from 'classnames';
import { useForceUpdate } from '@/Hooks/useForceUpdate';
import { useModalDialog } from '@/Components/ModalDialog/hooks/useModalDialog';
import { ModalDialog } from '@/Components/ModalDialog/ModalDialog';
import { useLocalization } from '@/Hooks';
import { Tooltip } from '@/Components/Tooltip/Tooltip';
import { ModalProps } from '@/Types';

type TData = OrderItemModel & DraggableBetweenTablesData;

type Props = ModalProps;

export function SplitOrdersModal({ closeModalWindow }: Props) {
  const { ref, onDragEnd, removeTable, isStoreEmpty, getItemsByTables, hasEmptyTable } = useDragAndDropTables<TData>();
  const {
    newOrders,
    initialDraggableOrderItems,
    addNewOrder,
    removeOrder,
    orderForSplitting,
    splitOrder,
    isAddOrderButtonDisabled,
  } = useSplitOrdersModal();
  const columnInfos = useSplitOrderColumnInfo();

  const forceUpdate = useForceUpdate();

  const {
    isDialogOpened: isSaveDialogOpened,
    openDialog: openSaveDialog,
    closeDialog: closeSaveDialog,
  } = useModalDialog();
  const {
    isDialogOpened: isCancelDialogOpened,
    openDialog: openCancelDialog,
    closeDialog: closeCancelDialog,
  } = useModalDialog();

  const {
    modalWindows: {
      splitOrders: {
        SaveSplitOrderDialog,
        CancelSplitOrderDialog,
        AddNewOrderButton,
        DeleteEmptyOrdersMessage,
        CreateMoreThanOneOrderMessage,
        Description,
      },
    },
    buttons: { Save, Cancel, Yes, No },
  } = useLocalization();

  if (!orderForSplitting?.orderItems?.length) {
    return null;
  }

  const isOneOfOrdersEmpty = hasEmptyTable();
  const isOnlyOneOrder = newOrders.length === 1;

  const isSaveButtonDisabled = isOneOfOrdersEmpty || isOnlyOneOrder;

  let saveButtonTooltip = '';
  if (isSaveButtonDisabled) {
    if (isOneOfOrdersEmpty) {
      saveButtonTooltip = DeleteEmptyOrdersMessage;
    } else if (isOnlyOneOrder) {
      saveButtonTooltip = CreateMoreThanOneOrderMessage;
    }
  }

  return (
    <>
      {isSaveDialogOpened && (
        <ModalDialog
          okButtonText={Yes}
          cancelButtonText={No}
          title={SaveSplitOrderDialog.Title}
          text={SaveSplitOrderDialog.Text}
          onClose={closeSaveDialog}
          onOk={() => {
            const items = getItemsByTables();
            splitOrder(items);
          }}
        />
      )}
      {isCancelDialogOpened && (
        <ModalDialog
          okButtonText={Yes}
          cancelButtonText={No}
          title={CancelSplitOrderDialog.Title}
          text={CancelSplitOrderDialog.Text}
          onClose={closeCancelDialog}
          onOk={() => {
            closeCancelDialog();
            closeModalWindow();
          }}
        />
      )}
      <Modal onClose={openCancelDialog}>
        <div className={styles.modalDescription}>
          <h4>{Description.Title}</h4>
          <p>{Description.Text}</p>
        </div>
        <div className={styles.bodyWrapper}>
          {newOrders.map((order) => (
            <div className={styles.tableWrapper} key={order}>
              <button
                className={cn(styles.removeOrderButton, {
                  [styles.removeOrderButton__disabled]: !isStoreEmpty(String(order)),
                  [styles.removeOrderButton__firstOrder]: order === 1,
                })}
                disabled={!isStoreEmpty(String(order)) || order === 1}
                onClick={() => {
                  removeOrder(order);
                  removeTable(String(order));
                }}
              >
                <DeleteIcon onClick={EMPTY_ACTION} />
              </button>
              <DataTableWithStore<TData>
                onChanged={forceUpdate}
                columnInfos={columnInfos}
                defaultOptions={{
                  showPagination: false,
                }}
                filterOptions={{
                  needShowFiltersButton: false,
                  needColumnChooser: false,
                  needHeaderFilter: false,
                }}
                dtRef={ref}
                rows={order === 1 ? initialDraggableOrderItems : []}
                rowKey={'id'}
                className={styles.table}
                dragOptions={{
                  dragBetweenTablesId: String(order),
                  dragBetweenTables: true,
                  dragGroup: 'SplitOrdersTable',
                  onDragEnd,
                  showDragIcons: true,
                }}
              />
            </div>
          ))}
        </div>
        <Button
          disabled={isAddOrderButtonDisabled}
          text={AddNewOrderButton}
          className={styles.addNewOrder}
          theme={BUTTON_THEMES.FILLED_BLUE}
          onClick={addNewOrder}
        />
        <ButtonsWrapper>
          <Button text={Cancel} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} onClick={openCancelDialog} />
          <Tooltip text={saveButtonTooltip} shouldShowOnHover={true} className={styles.tooltip}>
            <Button
              text={Save}
              theme={BUTTON_THEMES.SUCCESS}
              onClick={openSaveDialog}
              disabled={isSaveButtonDisabled}
            />
          </Tooltip>
        </ButtonsWrapper>
      </Modal>
    </>
  );
}
