import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable, OrderFilterModel, OrderTableRow } from '@/Types';
import { orderMapping } from '@/Mapping';
import { AddressValidationResultType, DateFormatType, OrderStatusFilter } from '@/Enums';
import { shippingLabelModalAsyncActions } from '@/ModalWindows/ShippingLabelModal/services';
import { salesAsyncActions } from './asyncActions';
import { splitOrdersModalAsyncActions } from '@/ModalWindows/SplitOrdersModal/services/asyncActions';
import { mergeOrdersAsyncActions } from '@/ModalWindows/MergeOrdersModal/services/asyncActions';
import { orderTableRowUtils } from '@/Types/OrderTableRowUtils';
import { ModalDataType } from '@/Types/ModalDataType';
import { ShippingStatusModel } from '@/Models/ShippingStatusModel';
import { AddressModel, OrderModel } from '@/Models';
import { dateTimeUtils } from '@/Utils';

export type SalesPageState = {
  sales: OrderTableRow[];
  selectedSale: Nullable<OrderTableRow>;
  selectedSales: OrderTableRow[];
  orderFilters: Nullable<OrderFilterModel>;
  orderFormModalData: ModalDataType<{ order: Nullable<OrderTableRow>; modalType: 'create' | 'update' | 'copy' }>;
  cantChangeOrderDialog: ModalDataType<null>;
  deleteOrderDialog: ModalDataType<number>;
  copyOrderModal: ModalDataType<number>;
  newSalesCount: number;
  updatedOrdersExists: boolean;
  needUpdateLogistics: boolean;
  shippingStatuses: Nullable<ShippingStatusModel>;
  previewData: Nullable<OrderTableRow>;
};

const initialState: SalesPageState = {
  sales: [],
  selectedSale: null,
  selectedSales: [],
  orderFilters: null,
  orderFormModalData: {
    visible: false,
  },
  cantChangeOrderDialog: {
    visible: false,
  },
  deleteOrderDialog: {
    visible: false,
  },
  copyOrderModal: {
    visible: false,
  },
  updatedOrdersExists: false,
  needUpdateLogistics: false,
  newSalesCount: 0,
  shippingStatuses: null,
  previewData: null,
};

const salesPageSlice = createSlice({
  name: 'salesPage',
  initialState,
  reducers: {
    setSelectedSale: (state, action: PayloadAction<Nullable<number>>) => {
      state.selectedSale = getSaleRowById(action.payload, state.sales);
    },
    setSelectedSales: (state, action: PayloadAction<OrderTableRow[]>) => {
      state.selectedSales = action.payload;
    },
    setOrderFilters: (state, action: PayloadAction<OrderFilterModel>) => {
      state.orderFilters = action.payload;
    },
    showOrderModal: (
      state,
      action: PayloadAction<{ modalType: 'create' | 'update' | 'copy'; order?: OrderTableRow }>,
    ) => {
      if (state.selectedSale?.currentInvoiceNumber && action.payload.modalType === 'update') {
        state.cantChangeOrderDialog = { visible: true };
        return;
      } // if

      let selectedSale: Nullable<OrderTableRow> = null;
      if (action.payload.modalType === 'update') {
        selectedSale = state.selectedSale;
      } // if

      state.orderFormModalData = {
        data: {
          order: action.payload.order ?? selectedSale,
          modalType: action.payload.modalType,
        },
        visible: true,
      };
    },
    showCopyOrderModal: (state) => {
      state.copyOrderModal = { visible: true };
    },
    closeOrderModal: (state) => {
      state.orderFormModalData = { visible: false };
    },
    closeCantChangeOrderDialog: (state) => {
      state.cantChangeOrderDialog = { visible: false };
    },
    showDeleteOrderDialog: (state, action: PayloadAction<number>) => {
      state.deleteOrderDialog = { visible: true, data: action.payload };
    },
    closeDeleteOrderDialog: (state) => {
      state.deleteOrderDialog = { visible: false };
    },
    closeCopyModal: (state) => {
      state.copyOrderModal = { visible: false };
    },
    setNewSalesCount: (state, action: PayloadAction<number>) => {
      state.newSalesCount = action.payload;
    },
    addNewSalesCount: (state, action: PayloadAction<number>) => {
      state.newSalesCount += action.payload;
    },
    setUpdatedOrdersExists: (state) => {
      state.updatedOrdersExists = true;
    },
    setUpdatedShippingStatusesExists: (state, action: PayloadAction<boolean>) => {
      state.needUpdateLogistics = action.payload;
    },
    orderUpdate: (state, action: PayloadAction<OrderTableRow[]>) => {
      state.sales = [...action.payload, ...state.sales.filter((x) => x.id !== action.payload[0].id)];
    },
    ordersUpdate: (state, action: PayloadAction<OrderModel[]>) => {
      const updatedOrders = action.payload;
      const updatedOrderIds = updatedOrders.map((x) => x.id);
      const updatedOrderRows = orderMapping.convertOrdersToOrderTableRows(updatedOrders);

      state.sales = state.sales.map((orderRow) => {
        const updatedOrderRow = updatedOrderRows.find((updatedOrder) => updatedOrder.id == orderRow.id);
        return updatedOrderRow ?? orderRow;
      });

      state.selectedSales = state.selectedSales.map((orderRow) => {
        const updatedOrderRow = updatedOrderRows.find((updatedOrder) => updatedOrder.id == orderRow.id);
        return updatedOrderRow ?? orderRow;
      });

      if (state.selectedSale?.id && updatedOrderIds.includes(state.selectedSale?.id)) {
        state.selectedSale = orderMapping.convertOrderToOrderTableRows(
          updatedOrders.find((x) => x.id === state.selectedSale!.id)!,
        )?.[0];
      }
    },

    updateValidatedAddress: (
      state: SalesPageState,
      action: PayloadAction<{ orderId: number; address: AddressModel }>,
    ) => {
      const address = action.payload.address;
      const orderId = action.payload.orderId;

      if (state.selectedSale?.id === orderId) {
        updateShippingAddress(state.selectedSale, address);
      }

      state.selectedSales.forEach((x) => {
        if (x.id === orderId) {
          updateShippingAddress(x, address);
        }
      });

      state.sales.forEach((x) => {
        if (x.id === orderId) {
          updateShippingAddress(x, address);
        }
      });
    },

    removeOrdersFromStateByCurrentFilters: (
      state: SalesPageState,
      action: PayloadAction<{ filters: readonly OrderStatusFilter[]; orderIds: number[] }>,
    ) => {
      if (state.orderFilters && action.payload.filters.includes(state.orderFilters.orderStatusFilter)) {
        state.sales = state.sales.filter((sale) => !action.payload.orderIds.includes(sale.id));
        state.selectedSales = state.selectedSales.filter((sale) => !action.payload.orderIds.includes(sale.id));
        if (state.selectedSale && action.payload.orderIds.includes(state.selectedSale.id)) {
          state.selectedSale = null;
        }
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(salesAsyncActions.getSalesWithCurrentFilters.fulfilled, (state, action) => {
      state.sales = orderMapping.convertOrdersToOrderTableRows(action.payload);
      state.newSalesCount = 0;
      state.updatedOrdersExists = false;
      state.needUpdateLogistics = false;
      if (state.selectedSale) {
        state.selectedSale = state.sales.find((x) => x.id === state.selectedSale?.id) ?? null;
      }
      if (state.selectedSales.length) {
        state.selectedSales = state.selectedSales
          .map((selectedSale) => state.sales.find((s) => s.id === selectedSale.id))
          .filter((selectedSale): selectedSale is OrderTableRow => !!selectedSale);
      }
    });
    builder.addCase(shippingLabelModalAsyncActions.applyAddressChanges.fulfilled, (state, action) => {
      const changedOrder = action.payload.order;
      state.sales = state.sales.map((s) =>
        s.id === action.payload.order.id ? orderMapping.copyShippingInfo(s, changedOrder) : s,
      );
      state.selectedSales = state.selectedSales.map((s) =>
        s.id === action.payload.order.id ? orderMapping.copyShippingInfo(s, changedOrder) : s,
      );
      if (state.selectedSale && state.selectedSale.mpOrderNumber === changedOrder.mpOrderNumber) {
        state.selectedSale = orderMapping.copyShippingInfo(state.selectedSale, changedOrder);
      }
    });
    builder.addCase(shippingLabelModalAsyncActions.validateOrderAddresses.fulfilled, (state, action) => {
      const filtered = action.payload.compares.filter(
        (c) => c.result === AddressValidationResultType.FoundWithFullCompliance,
      );
      state.sales = state.sales.map((s) => {
        const model = filtered.find((f) => f.orderId === s.id);
        return model ? orderMapping.fillOrderRowWithAddress(s, model.suggestedAddress) : s;
      });
      state.selectedSales = state.selectedSales.map((s) => {
        const model = filtered.find((f) => f.orderId === s.id);
        if (!model) return s;

        return s.id === model.orderId
          ? {
              ...s,
              isOriginalShippingModified: true,
              ...orderMapping.toShippingInfo(model.suggestedAddress),
            }
          : s;
      });

      if (state.selectedSale) {
        const model = filtered.find((f) => f.orderId === state.selectedSale?.id);
        if (model) {
          state.selectedSale = {
            ...state.selectedSale,
            isOriginalShippingModified: true,
            ...orderMapping.toShippingInfo(model.suggestedAddress),
          };
        }
      }
    });
    builder.addCase(salesAsyncActions.createOrder.fulfilled, (state, action) => {
      state.orderFormModalData = { visible: false };
      const orderModel = action.payload;
      const orderTableRows = orderMapping.convertOrdersToOrderTableRows([orderModel]);

      state.selectedSales = orderTableRows;
      state.selectedSale = orderTableRows[0];
      state.sales = [...orderTableRows, ...state.sales];
    });
    builder.addCase(salesAsyncActions.updateOrder.fulfilled, (state, action) => {
      state.orderFormModalData = { visible: false };

      const { selectedOrder, orderRows, selectedOrders } = orderTableRowUtils.updateOrderTableRows(
        {
          selectedOrder: state.selectedSale,
          orderRows: state.sales,
          selectedOrders: state.selectedSales,
        },
        [action.payload],
      );

      state.selectedSale = selectedOrder;
      state.sales = orderRows;
      state.selectedSales = selectedOrders;
    });
    builder.addCase(salesAsyncActions.copyOrder.fulfilled, (state, action) => {
      state.orderFormModalData = { visible: false };
      const orderModel = action.payload;
      const orderTableRows = orderMapping.convertOrdersToOrderTableRows([orderModel]);

      state.selectedSales = orderTableRows;
      state.selectedSale = orderTableRows[0];
      state.sales = [...state.sales, ...orderTableRows];
    });
    builder.addCase(splitOrdersModalAsyncActions.splitOrder.fulfilled, (state, action) => {
      const newOrderRows = orderMapping.convertOrdersToOrderTableRows(action.payload.newOrders);
      state.sales = [...newOrderRows, ...state.sales.filter((sale) => sale.id !== action.payload.splittedOrderId)];
    });
    builder.addCase(mergeOrdersAsyncActions.mergeOrders.fulfilled, (state, action) => {
      const newOrderRows = orderMapping.convertOrderToOrderTableRows(action.payload.mergedOrder);
      state.sales = [
        ...newOrderRows,
        ...state.sales.filter((sale) => !action.payload.mergeOrdersIds.some((id) => id === sale.id)),
      ];
    });
    builder.addCase(salesAsyncActions.getOrdersByIds.fulfilled, (state, action) => {
      const { selectedOrder, orderRows, selectedOrders } = orderTableRowUtils.updateOrderTableRows(
        {
          selectedOrder: state.selectedSale,
          orderRows: state.sales,
          selectedOrders: state.selectedSales,
        },
        action.payload,
      );

      state.selectedSale = selectedOrder;
      state.sales = orderRows;
      state.selectedSales = selectedOrders;
    });
    builder.addCase(salesAsyncActions.updateOrderNote.fulfilled, (state, action) => {
      const { selectedOrder, orderRows, selectedOrders } = orderTableRowUtils.updateOrderTableRows(
        {
          selectedOrder: state.selectedSale,
          orderRows: state.sales,
          selectedOrders: state.selectedSales,
        },
        [action.payload.order],
      );

      state.selectedSale = selectedOrder;
      state.sales = orderRows;
      state.selectedSales = selectedOrders;
    });
    builder.addCase(salesAsyncActions.createOrderPreview.fulfilled, (state, action) => {
      state.previewData = convertOrderPreviewData(action.payload);
    });
    builder.addCase(salesAsyncActions.updateOrderPreview.fulfilled, (state, action) => {
      state.previewData = convertOrderPreviewData(action.payload);
    });
    builder.addCase(salesAsyncActions.copyOrderPreview.fulfilled, (state, action) => {
      state.previewData = convertOrderPreviewData(action.payload);
    });
  },
});

const convertOrderPreviewData = (order: OrderModel) => {
  const previewData = orderMapping.convertOrderToOrderTableRows(order)[0];

  return {
    ...previewData,
    paymentDueDate: dateTimeUtils.changeDateFormat(
      previewData?.paymentDueDate,
      DateFormatType.SERVER_DATE_TIME,
      DateFormatType.LOCAL_DATE,
    ),
  };
};

const getSaleRowById = (id: Nullable<number>, sales: OrderTableRow[]): Nullable<OrderTableRow> => {
  if (id == null) {
    return null;
  }

  return sales.find((sale) => sale.id === id) ?? null;
};

const updateShippingAddress = (orderTableRow: Nullable<OrderTableRow>, addressModel: AddressModel) => {
  if (!orderTableRow) {
    return;
  }

  orderTableRow.shippingStreet = addressModel.street;
  orderTableRow.shippingHouseNo = addressModel.houseNo;
  orderTableRow.shippingStreet = addressModel.street;
  orderTableRow.shippingAdditionalLine = addressModel.additionalLine;
  orderTableRow.shippingCityName = addressModel.city;
  orderTableRow.shippingCountryCode = addressModel.countryCode;
  orderTableRow.shippingPostalCode = addressModel.postalCode;
  orderTableRow.shippingCompanyName = addressModel.company;
  orderTableRow.shippingFirstName = addressModel.firstName;
  orderTableRow.shippingLastName = addressModel.lastName;
  orderTableRow.isOriginalShippingModified = true;
};

export const salesPageReducer = salesPageSlice.reducer;
export const salesPageActions = salesPageSlice.actions;
export const salesPageAsyncActions = salesAsyncActions;
