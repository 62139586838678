import { ColumnType } from '@/Types';
import { dateTimeUtils } from '@/Utils';

const getCellValue = (value: string | number | boolean, columnType: ColumnType): string | number | boolean => {
  if (value == 0 && columnType === ColumnType.Currency) return (0).toCurrencyString();

  if (!value) return '';

  switch (columnType) {
    case ColumnType.Boolean:
      return value;

    case ColumnType.Currency:
      return Number(value).toCurrencyString();

    case ColumnType.Number:
      return Number(value).toIntegerString();

    case ColumnType.Weight:
      return Number(value).toWeightString();

    case ColumnType.DateTime:
      return dateTimeUtils.utcStringToLocalString(value.toString());

    case ColumnType.Date:
      return dateTimeUtils.utcStringToLocalString(value.toString(), true);

    default:
      return value;
  } // switch
};

export const tableUtils = {
  getCellValue,
};
