import { ApiRoutingUtils } from '@/Routing';
import { PageableRequest, PageableResponse } from '@/Api';
import { PackageModel } from '@/Models';
import {
  CreatePackageRequest,
  CreatePackageResponse,
  GetPackagesParams,
  UpdatePackageRequest,
  UpdatePackageResponse,
  DeletePackageRequest,
} from './';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createUrl('package');

async function createPackage(request: CreatePackageRequest): Promise<CreatePackageResponse> {
  return rest.post(`${url}`, request);
}

async function getPackages(request: PageableRequest<GetPackagesParams>): Promise<PageableResponse<PackageModel>> {
  return rest.get(`${url}`, request);
}

async function updatePackage(request: UpdatePackageRequest): Promise<UpdatePackageResponse> {
  return rest.put(`${url}`, request);
}

async function deletePackage(request: DeletePackageRequest): Promise<number> {
  return rest.delete(`${url}`, request);
}

export const PackagesApi = {
  createPackage,
  getPackages,
  updatePackage,
  deletePackage,
};
