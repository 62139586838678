import React, { useEffect, useState } from 'react';
import { DataTable, DataTableProps } from './DataTable';
import { DataTabDataSourceType, IDataTableRef } from '@/Components/DataTable/types';

export type DataTableWithStoreRef<TData> = {
  [key: string]: DataTabDataSourceType<TData>;
};

export type DataTableWithStoreProps<TData extends object> = DataTableProps<TData> & {
  dtRef: React.MutableRefObject<DataTableWithStoreRef<TData>>;
  onChanged?: (instance: IDataTableRef<TData>) => void;
};

function DataTableWithStoreComponent<TData extends object>({ dtRef, ...props }: DataTableWithStoreProps<TData>) {
  const [, setState] = useState(false);

  useEffect(() => {
    if (!props.dragOptions?.dragBetweenTablesId) return;
    dtRef.current[props.dragOptions.dragBetweenTablesId] = new DataTabDataSourceType({
      data: props.rows,
      key: props.rowKey as keyof TData,
      onChanged: props.onChanged,
    });
    setState((prev) => !prev);
  }, []);

  if (!dtRef.current) return null;

  const dataSource =
    (props.dragOptions?.dragBetweenTablesId && dtRef.current[props.dragOptions.dragBetweenTablesId]?.dataSource) || [];

  return <DataTable {...props} rows={dataSource as any} rowKey={props.rowKey} />;
}

export const DataTableWithStore = React.memo(DataTableWithStoreComponent) as typeof DataTableWithStoreComponent;
