import React from 'react';
import { ClassNameProps } from '@/Types';
import styles from './OrderItemsTable.scss';
import cn from 'classnames';
import { TableButtonsSection, OrderItemsSection, OrderItemsInfoSection } from './modules';
import { useSelector } from 'react-redux';
import { tableButtonsSectionSelectors } from './services/selectors';
import { OrderStatusIcons } from '@/Components/OrderStatusIcons/OrderStatusIcons';

type Props = ClassNameProps;

export const OrderItemsTable = ({ className }: Props) => {
  const buyerFeedbackIconInfo = useSelector(tableButtonsSectionSelectors.buyerFeedbackIconInfo);
  const sellerFeedbackIconInfo = useSelector(tableButtonsSectionSelectors.sellerFeedbackIconInfo);
  const saleShippingIconInfo = useSelector(tableButtonsSectionSelectors.saleShippingIconInfo);
  const salePaymentIconInfo = useSelector(tableButtonsSectionSelectors.salePaymentIconInfo);

  return (
    <div className={cn(className, styles.wrapper)}>
      <div className={styles.filterAndIconsWrapper}>
        <TableButtonsSection />
        <OrderStatusIcons
          className={styles.orderStatusIcons}
          leftFeedbackIconInfo={sellerFeedbackIconInfo}
          receivedFeedbackIconInfo={buyerFeedbackIconInfo}
          paymentIconInfo={salePaymentIconInfo}
          shippingIconInfo={saleShippingIconInfo}
        />
      </div>

      <div className={styles.orderItemsContainer}>
        <OrderItemsSection className={styles.orderItemsSection} />
        <OrderItemsInfoSection className={styles.orderItemsInfoSection} />
      </div>
    </div>
  );
};
