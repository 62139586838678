import { ModalDialog } from '@/Components/ModalDialog/ModalDialog';
import React from 'react';
import { useLocalization, useShowGenerateDocumentNumberConfirmationDialog } from '@/Hooks';
import { NumberGenerationType } from '@/Enums';

type Props = {
  onClose: () => void;
  numberGenerationType: NumberGenerationType;
};

export function CreateDocumentModal({ onClose, numberGenerationType }: Props) {
  const {
    modalWindows: {
      documentPrintModal: {
        CreateDocumentModal: { Title, Text },
      },
    },
  } = useLocalization();

  const { showGenerateDocumentNumberConfirmationDialog } = useShowGenerateDocumentNumberConfirmationDialog();

  return (
    <ModalDialog
      title={Title}
      text={Text}
      onClose={onClose}
      onOk={() => showGenerateDocumentNumberConfirmationDialog(numberGenerationType)}
    />
  );
}
