import React, { Fragment } from 'react';
import { EmailModel } from '@/Models/EmailModel';
import { Modal } from '@/Components/Modal/Modal';
import { Label } from '@/Components/Controls/Label/Label';
import { FormInput } from '@/Components/Controls/Input/FormInput';
import { FormData, useEmailPreviewModalForm } from './hooks/useEmailPreviewModalForm';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { DeleteIcon } from '@/Static';
import styles from './EmailPreviewModal.scss';
import { useLocalization } from '@/Hooks';

type Props = {
  data: {
    email: EmailModel;
    orderId: number;
  };
  onClose: () => void;
};

export const EmailPreviewModal = ({ data, onClose }: Props): React.ReactElement => {
  const {
    buttons,
    modalWindows: {
      emailSendModal: { EmailPreviewModal },
    },
  } = useLocalization();

  const {
    methods: { register, errors },
    arrays: { attachments },
    rules: { required },
    onSubmit,
  } = useEmailPreviewModalForm(data);

  return (
    <Modal title={EmailPreviewModal.Title} onClose={onClose}>
      <form className={styles.emailPreviewModal} onSubmit={onSubmit}>
        <Label text={EmailPreviewModal.To} />
        <FormInput<FormData> name={'to'} register={register} rules={required} error={errors} />

        <Label text={EmailPreviewModal.Subject} />
        <FormInput<FormData> name={'subject'} register={register} rules={required} error={errors} />

        <Label text={EmailPreviewModal.Cc} />
        <FormInput<FormData> name={'cc'} register={register} />

        <Label text={EmailPreviewModal.Bcc} />
        <FormInput<FormData> name={'bcc'} register={register} />

        <Label text={EmailPreviewModal.Attachments} className={styles.attachmentsPreviewLabel} />
        <div className={styles.attachmentsPreview}>
          {attachments.fields.length > 0 ? (
            attachments.fields.map((field, idx) => (
              <Fragment key={field.array_id}>
                <span>{field.name}</span>
                <DeleteIcon onClick={() => attachments.remove(idx)} />
              </Fragment>
            ))
          ) : (
            <span className={styles.attachmentsPreviewEmptyMessage}>{EmailPreviewModal.ThereAreNoAttachments}</span>
          )}
        </div>

        <iframe srcDoc={data.email.body} className={styles.htmlPreview} />

        <div className={styles.buttons}>
          <Button text={buttons.Cancel} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} onClick={onClose} />
          <Button text={buttons.Send} type={'submit'} theme={BUTTON_THEMES.SUCCESS} />
        </div>
      </form>
    </Modal>
  );
};
