import { RootState } from '@/Redux/RootReducer';
import { createSelector } from 'reselect';

const documentTypesDataSelector = (root: RootState) => root.dynamicData.documentTypesData;
const translation = (root: RootState) => root.global.translation;

const documentTypes = createSelector([documentTypesDataSelector, translation], (state, translation) => {
  const documentTypesTranslation =
    translation.Pages.Settings.OnlineArchiveSettings.DocumentCategories.DisabledDocumentTypes;

  return state.documentTypes
    .map((documentType) => ({
      ...documentType,
      name: documentTypesTranslation[documentType.name as keyof typeof documentTypesTranslation] ?? documentType.name,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
});

export const documentTypesDataSelectors = {
  documentTypes,
};
