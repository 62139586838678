import { useCallback, useEffect, useState } from 'react';
import { ChangedOptionInfo } from 'devextreme/events';
import { DataGrid } from 'devextreme-react/data-grid';
import { dxDataGridColumn } from 'devextreme/ui/data_grid';
import { SortType } from '@/Components/DataTable/types';

export const useOnFilterChanged = <TData>(
  innerGridRef: React.MutableRefObject<DataGrid<TData, keyof TData> | undefined>,
  sortHighlightExcludeKey?: { key: keyof TData; sortType: SortType },
) => {
  const [fullNames, setFullNames] = useState<string[]>([]);
  const dataGridHeader: HTMLElement | null = document.querySelector('.dx-datagrid-headers');

  useEffect(() => {
    if (dataGridHeader) {
      if (fullNames.length > 0) {
        const columns = innerGridRef?.current?.instance.state()?.columns;

        if (sortHighlightExcludeKey) {
          const { key, sortType } = sortHighlightExcludeKey;
          const sortedColumn: dxDataGridColumn = columns.find((item: dxDataGridColumn) => item.name == key);

          if (sortedColumn.sortOrder == sortType) {
            dataGridHeader.style.backgroundColor = 'transparent';
          } else {
            dataGridHeader.style.backgroundColor = '#fff0cc';
          }
        } else {
          dataGridHeader.style.backgroundColor = '#fff0cc';
        }
      } else {
        dataGridHeader.style.backgroundColor = 'transparent';
      }
    }
  }, [fullNames]);

  const addFullName = (e: ChangedOptionInfo) => setFullNames([...fullNames, e.fullName]);
  const removeFullName = (e: ChangedOptionInfo) => setFullNames(fullNames.filter((x) => x != e.fullName));

  return useCallback(
    (e: ChangedOptionInfo) => {
      switch (e.name) {
        case 'filterValue':
        case 'searchPanel':
          if (e.value) {
            addFullName(e);
          } else {
            removeFullName(e);
          }
          break;

        case 'columns':
          if (e.value === undefined) {
            setFullNames([]);
            return;
          }

          if (e.value === 'include' || e.value === null || e.value === true || e.value === false) {
            removeFullName(e);
          } else {
            addFullName(e);
          }
          break;
      }
    },
    [setFullNames, fullNames],
  );
};
