import axios, { AxiosResponse } from 'axios';
import { globalHistory } from '@/GlobalHistory';
import { PagesRouting } from '@/Routing';
import { BaseResponse, BinaryResponse, ErrorHandler } from './';
import qs from 'qs';
import { LoadingHandler } from './LoadingHandler';

const LOADING_DELAY = 1000;

axios.interceptors.response.use(
  function (response) {
    const baseResponse = response.data as BaseResponse;
    if (baseResponse?.errors) {
      ErrorHandler.getInstance().handle(baseResponse.errors);
    } // if

    return response;
  },
  function (error) {
    if (error.response.status) {
      switch (error.response.status) {
        case 500: {
          const baseError: BaseResponse = {
            errors: [{ errorCode: 'ServerIsNotAllowed' }],
          };
          LoadingHandler.getInstance().errorLoading(error.config?.url);
          ErrorHandler.getInstance().handle(baseError.errors!);

          break;
        }

        case 401: {
          LoadingHandler.getInstance().cancelLoading(error.config?.url);
          globalHistory.push(PagesRouting.AuthorizationPages.LoginPage);

          break;
        }
        case 403: {
          LoadingHandler.getInstance().cancelLoading(error.config?.url);
          globalHistory.push(PagesRouting.MainPages.PermissionDenied);
          break;
        }
        case 400: {
          LoadingHandler.getInstance().errorLoading(error.config?.url);

          if (Array.isArray(error.response.data.errors)) {
            ErrorHandler.getInstance().handle(error.response.data.errors);
          } else {
            console.error(error.response.data);
          } // if

          break;
        }

        default: {
          LoadingHandler.getInstance().errorLoading(error.config?.url);
          break;
        }
      } // switch
    } // if

    return Promise.reject(error);
  },
);

async function get<TResponse>(url: string, params: Record<string, any> | undefined = undefined): Promise<TResponse> {
  const query = params == undefined ? '' : qs.stringify(params, { allowDots: true });

  if (query) {
    url = url + '?' + query;
  }

  const lang = window.localStorage.getItem('lang');
  const timeOut = startLoading(url);

  try {
    const response = await axios.get(url, { headers: { lang: lang }, withCredentials: true });
    return parseResult<TResponse>(response);
  } finally {
    finishLoading(timeOut, url);
  }
}

async function getBinary(url: string, params: Record<string, any> | undefined = undefined): Promise<BinaryResponse> {
  const query = params == undefined ? '' : qs.stringify(params, { allowDots: true });

  if (query) {
    url = url + '?' + query;
  }
  const timeOut = startLoading(url);
  const response = await axios.get(url, { responseType: 'arraybuffer', withCredentials: true });
  finishLoading(timeOut, url);

  return {
    contentType: response.headers['content-type'],
    data: Buffer.from(response.data, 'binary'),
    fileName: getFileNameResult(response),
  };
}

async function post<TRequest, TResponse>(
  url: string,
  data: TRequest,
  onUploadProgress?: (progressEvent: any) => void,
): Promise<TResponse> {
  const lang = window.localStorage.getItem('lang');

  const timeOut = !onUploadProgress ? startLoading(url) : null;

  const response = await axios.post(url, data, {
    headers: { lang: lang },
    withCredentials: true,
    onUploadProgress: onUploadProgress,
  });

  if (timeOut) finishLoading(timeOut, url);
  return parseResult<TResponse>(response);
}

async function postBinary<TRequest>(url: string, data: TRequest): Promise<BinaryResponse> {
  const timeOut = startLoading(url);

  const response = await axios.post(url, data, { responseType: 'arraybuffer', withCredentials: true });
  finishLoading(timeOut, url);

  return {
    contentType: response.headers['content-type'],
    data: Buffer.from(response.data, 'binary'),
    fileName: getFileNameResult(response),
  };
}

async function put<TRequest, TResponse>(url: string, data: TRequest): Promise<TResponse> {
  const lang = window.localStorage.getItem('lang');

  const timeOut = startLoading(url);
  const response = await axios.put(url, data, { headers: { lang: lang }, withCredentials: true });
  finishLoading(timeOut, url);
  return parseResult<TResponse>(response);
}

async function patch<TRequest, TResponse>(url: string, data: TRequest): Promise<TResponse> {
  const lang = window.localStorage.getItem('lang');

  const timeOut = startLoading(url);
  const response = await axios.patch(url, data, { headers: { lang: lang }, withCredentials: true });
  finishLoading(timeOut, url);
  return parseResult<TResponse>(response);
}

async function deleteRequest<TRequest, TResponse>(url: string, data: TRequest): Promise<TResponse> {
  const lang = window.localStorage.getItem('lang');

  const timeOut = startLoading(url);
  const response = await axios.delete(url, { headers: { lang: lang }, withCredentials: true, data });
  finishLoading(timeOut, url);
  return parseResult<TResponse>(response);
}

function parseResult<TResponse>(response: AxiosResponse): TResponse {
  return response.data as TResponse;
}

function getFileNameResult(response: AxiosResponse): string {
  const contentDisposition = response.headers['content-disposition'];
  if (contentDisposition) {
    const match = contentDisposition.match('filenames*=s*(.+).*?(?=;)');
    if (match && match.length >= 1) {
      return match[1];
    }
  }

  return '';
}

function startLoading(url: string): NodeJS.Timeout {
  return setTimeout(() => {
    console.log('LOADING');
    LoadingHandler.getInstance().startLoading(url);
  }, LOADING_DELAY);
}

function finishLoading(timeOut: NodeJS.Timeout, url: string): void {
  console.log('LOADED');
  clearTimeout(timeOut);
  LoadingHandler.getInstance().cancelLoading(url);
}

export const rest = {
  get,
  getBinary,
  post,
  postBinary,
  put,
  patch,
  delete: deleteRequest,
};
