import { createSelector } from '@reduxjs/toolkit';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { OrderTableRow, Nullable } from '@/Types';

const shippingCost = createSelector(
  [purchasesPageSelectors.selectedPurchase],
  (purchase: Nullable<OrderTableRow>) => purchase?.shippingPrice.toCurrencyString() || (0).toCurrencyString(),
);

const netAmount = createSelector(
  [purchasesPageSelectors.selectedPurchase],
  (purchase: Nullable<OrderTableRow>) =>
    purchase?.orderItems
      .reduce((acc, oi) => {
        acc = acc + oi.totalPriceNettoWithShipping;
        return acc;
      }, 0)
      .toCurrencyString() || (0).toCurrencyString(),
);

const VAT = createSelector(
  [purchasesPageSelectors.selectedPurchase],
  (purchase: Nullable<OrderTableRow>) =>
    purchase?.orderItems.reduce((acc, oi) => acc + oi.salesTaxTotalWithShipping, 0).toCurrencyString() ||
    (0).toCurrencyString(),
);

const totalAmount = createSelector(
  [purchasesPageSelectors.selectedPurchase],
  (purchase: Nullable<OrderTableRow>) => purchase?.priceBruttoWithShipping.toCurrencyString() || (0).toCurrencyString(),
);

export const orderItemInfoSectionSelectors = {
  shippingCost,
  netAmount,
  VAT,
  totalAmount,
};
