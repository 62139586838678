import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderLabelPrintRow, Pair } from '@/Types';
import { CountryCode } from '@/Enums';
import { orderMapping } from '@/Mapping';
import { OrderLabelStatus } from '@/Enums/OrderLabelStatus';
import { asyncActions } from './asyncActions';

export type OrderLabelPrintModalState = {
  orderLabelPrintRows: OrderLabelPrintRow[];
  selectedOrderLabelPrintRows: OrderLabelPrintRow[];
  countryCodeOptions: Pair<CountryCode>[];
  logisticTypeOptions: Pair<string | number>[];
  filterLogistics: (string | number)[];
  filterStatus: string;
  isAllLabelsIsPickup: boolean;
};

const initialState: OrderLabelPrintModalState = {
  orderLabelPrintRows: [],
  selectedOrderLabelPrintRows: [],
  countryCodeOptions: [],
  logisticTypeOptions: [],
  filterLogistics: [],
  filterStatus: OrderLabelStatus.All,
  isAllLabelsIsPickup: false,
};

const orderLabelPrintSlice = createSlice({
  name: 'orderLabelPrintModal',
  initialState,
  reducers: {
    setSelectedOrderLabelPrints(state, action: PayloadAction<OrderLabelPrintRow[]>) {
      state.selectedOrderLabelPrintRows = action.payload;
    },
    setFilterLogistics(state, action: PayloadAction<string[]>) {
      state.filterLogistics = action.payload;
    },
    setFilterStatus(state, action: PayloadAction<string>) {
      state.filterStatus = action.payload;
    },
    setCountryOptions(state, action: PayloadAction<Pair<CountryCode>[]>) {
      state.countryCodeOptions = action.payload;
    },
    setLogisticTypes(state, action: PayloadAction<Pair<string | number>[]>) {
      state.logisticTypeOptions = action.payload;
      state.filterLogistics = action.payload.map((x) => x.key);
    },
    resetState: (state) => {
      state.orderLabelPrintRows = [];
      state.selectedOrderLabelPrintRows = [];
      state.filterLogistics = [];
      state.filterStatus = OrderLabelStatus.All;
      state.countryCodeOptions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getOrderLabelInfoRequest.fulfilled, (state, action) => {
        state.orderLabelPrintRows = orderMapping.convertToOrderPrintRows(action.payload.orderLabels);
        state.selectedOrderLabelPrintRows = state.orderLabelPrintRows;
        state.isAllLabelsIsPickup = action.payload.orderLabels.every((label) => label.isPickup);
      })
      .addCase(asyncActions.generateLabelsRequest.fulfilled, (state, action) => {
        action.payload.labels?.forEach((x) => {
          x.labels.forEach((y) => {
            const labelPrintRow = state.orderLabelPrintRows.find((row) => row.orderId === y.orderId);
            if (labelPrintRow) {
              labelPrintRow.id = y.orderLabelId;
              labelPrintRow.isCreated = y.isCreated;
            }

            const selected = state.selectedOrderLabelPrintRows.find((row) => row.orderId === y.orderId);
            if (selected) {
              selected.id = y.orderLabelId;
              selected.isCreated = y.isCreated;
            }
          });
        });
      })
      .addCase(asyncActions.setPrintedStatusRequest.fulfilled, (state, action) => {
        action.payload.orderLabelIds?.forEach((x) => {
          const labelPrintRow = state.orderLabelPrintRows.find((row) => row.id === x);
          if (labelPrintRow) {
            labelPrintRow.isPrinted = true;
          }

          const selected = state.selectedOrderLabelPrintRows.find((row) => row.id === x);
          if (selected) {
            selected.isPrinted = true;
          }
        });
      });
  },
});

export const orderLabelPrintReducer = orderLabelPrintSlice.reducer;
export const orderLabelPrintActions = orderLabelPrintSlice.actions;
export const orderLabelPrintAsyncActions = asyncActions;
