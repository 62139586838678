import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { splitOrdersModalActions } from '@/ModalWindows/SplitOrdersModal/services/reducer';
import { modalWindowsActions } from '@/ModalWindows/services';
import { ModalType } from '@/Enums';

type UseSplitAndMergeOrdersReturnType = {
  openSplitOrdersModal: () => void;
};

export function useSplitAndMergeOrders(): UseSplitAndMergeOrdersReturnType {
  const dispatch = useDispatch();
  const openSplitOrdersModal = useCallback(() => {
    dispatch(modalWindowsActions.openModal({ modalType: ModalType.None }));
    dispatch(modalWindowsActions.openModal({ modalType: ModalType.SplitOrders }));
    dispatch(splitOrdersModalActions.setInitialNewOrdersCount(2));
  }, []);

  return {
    openSplitOrdersModal,
  };
}
