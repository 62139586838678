import { createSlice } from '@reduxjs/toolkit';
import { LogisticContractStatus } from '@/Enums';
import { Nullable } from '@/Types';
import { asyncActions } from './asyncThunks';

export type LogisticContractState = {
  connectionStatus: Nullable<LogisticContractStatus>;
};

const initialState: LogisticContractState = {
  connectionStatus: null,
};

const logisticContractSlice = createSlice({
  name: 'LogisticContractState',
  initialState,
  reducers: {
    resetConnectionStatus(state) {
      state.connectionStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.checkConnection.fulfilled, (state, action) => {
      state.connectionStatus = action.payload.status;
    });
  },
});

const { actions, reducer } = logisticContractSlice;

export const logisticContractActions = actions;
export const logisticContractReducer = reducer;
export const logisticContractAsyncActions = asyncActions;
