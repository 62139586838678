import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatorCountryZoneSelectors } from '@/Pages/Administration/LogisticProducts/modules/GeneratorCountryZones/services';

export function useExcludeCountriesInZoneLevel(countryZoneId: number | string, countryZoneLevelId: number | string) {
  const countryZone = useSelector(generatorCountryZoneSelectors.countryZoneById(countryZoneId));

  const countryZonesItems = useMemo<(string | number)[]>(() => {
    if (countryZone) {
      return countryZone.zoneLevels
        .filter((x) => x.id != countryZoneLevelId)
        .map((x) => x.countriesRecipient)
        .reduce((countries, val) => countries.concat(val), []);
    }

    return [];
  }, [countryZone, countryZoneId]);

  return {
    countryZonesItems,
  };
}
