import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Pair } from '@/Types';
import { RootState } from '@/Redux/RootReducer';
import { CountryCode, GeneratorCountryZoneModalType, LogisticContractStatus } from '@/Enums';
import { countryZoneUtils } from './utils';
import { ApplicationAccessCountryZoneCountryModel } from '@/Models/ApplicationAccessCountryZones/ApplicationAccessCountryZoneCountryModel';
import { ApplicationAccessCountryZoneModel } from '@/Models/ApplicationAccessCountryZones/ApplicationAccessCountryZoneModel';
import { ApplicationAccessCountryZonesApi } from '@/Api/ApplicationAccessCountryZones/ApplicationAccessCountryZonesApi';
import { CreateApplicationAccessCountryZoneRequest } from '@/Api/ApplicationAccessCountryZones/CreateApplicationAccessCountryZoneRequest';
import { CreateApplicationAccessCountryZoneResponse } from '@/Api/ApplicationAccessCountryZones/CreateApplicationAccessCountryZoneResponse';
import { UpdateApplicationAccessCountryZoneRequest } from '@/Api/ApplicationAccessCountryZones/UpdateApplicationAccessCountryZoneRequest';
import { UpdateApplicationAccessCountryZoneResponse } from '@/Api/ApplicationAccessCountryZones/UpdateApplicationAccessCountryZoneResponse';
import { CheckApplicationAccessConnectionRequest } from '@/Api/ApplicationAccessCountryZones/CheckApplicationAccessConnectionRequest';
import { CheckApplicationAccessConnectionResponse } from '@/Api/ApplicationAccessCountryZones/CheckApplicationAccessConnectionResponse';

const getApplicationAccessCountryZonesRequest = createAsyncThunk(
  'generatorCountryZones/get-generator-country-zones',
  async (params: CountryCode, api) => {
    const state: RootState = api.getState() as RootState;
    const response = await ApplicationAccessCountryZonesApi.getApplicationAccessCountryZonesGroupedByCountry(params);
    response.countryZones.forEach((x) => countryZoneUtils.fillLocalization(x, state));
    return response;
  },
);

const createApplicationAccessCountryZoneRequest = createAsyncThunk<
  CreateApplicationAccessCountryZoneResponse,
  CreateApplicationAccessCountryZoneRequest
>(
  'applicationAccessCountryZones/create-generator-country-zone',
  async (request: CreateApplicationAccessCountryZoneRequest) => {
    const response = await ApplicationAccessCountryZonesApi.createApplicationAccessCountryZone(request);
    return response;
  },
);

const updateApplicationAccessCountryZoneRequest = createAsyncThunk<
  UpdateApplicationAccessCountryZoneResponse,
  UpdateApplicationAccessCountryZoneRequest
>(
  'applicationAccessCountryZones/update-generator-country-zone',
  async (request: UpdateApplicationAccessCountryZoneRequest) => {
    const response = await ApplicationAccessCountryZonesApi.updateApplicationAccessCountryZone(request);
    return response;
  },
);

const deleteApplicationAccessCountryZoneRequest = createAsyncThunk<number, number>(
  'applicationAccessCountryZones/delete-generator-country-zone',
  async (id: number) => {
    return ApplicationAccessCountryZonesApi.deleteApplicationAccessCountryZone(id);
  },
);

const checkApplicationAccessConnectionRequest = createAsyncThunk<
  CheckApplicationAccessConnectionResponse,
  CheckApplicationAccessConnectionRequest
>('applicationAccessCountryZones/check-connection', async (request: CheckApplicationAccessConnectionRequest) => {
  const response = await ApplicationAccessCountryZonesApi.checkApplicationAccessConnection(request);
  return response;
});

export type ApplicationAccessState = {
  countryZones: ApplicationAccessCountryZoneCountryModel[];
  selectedUuid: string;
  logisticTypeOptions: Pair<string | number>[];
  modalWindowType: GeneratorCountryZoneModalType;
  selectedCountryCode: CountryCode | null;
  connectionStatus: LogisticContractStatus;
};

const initialState: ApplicationAccessState = {
  countryZones: [],
  selectedUuid: '',
  logisticTypeOptions: [],
  selectedCountryCode: null,
  modalWindowType: GeneratorCountryZoneModalType.None,
  connectionStatus: LogisticContractStatus.Unknown,
};

const applicationAccessProductsSlice = createSlice({
  name: 'generatorCountryZonesState',
  initialState,
  reducers: {
    setLogisticTypes(state, action: PayloadAction<Pair<string | number>[]>) {
      state.logisticTypeOptions = action.payload;
    },
    setSelectedUuid(state, action: PayloadAction<string>) {
      state.selectedUuid = action.payload;
    },
    setSelectedCountryCode: (state, action: PayloadAction<CountryCode>) => {
      state.selectedCountryCode = action.payload;
    },
    addCountryZone(state, action: PayloadAction<ApplicationAccessCountryZoneModel>) {
      countryZoneUtils.addZone(state.countryZones, action.payload);
    },
    resetConnectionStatus(state) {
      state.connectionStatus = LogisticContractStatus.Unknown;
    },
    deleteCountryZone(state, action: PayloadAction<ApplicationAccessCountryZoneModel>) {
      countryZoneUtils.deleteZone(state.countryZones, action.payload.id);
    },
    openModal(state, action: PayloadAction<GeneratorCountryZoneModalType>) {
      state.modalWindowType = action.payload;
    },
    closeModal(state) {
      state.modalWindowType = GeneratorCountryZoneModalType.None;
    },
    clearData(state) {
      state.logisticTypeOptions = [];
      state.countryZones = [];
      state.selectedUuid = '';
      state.modalWindowType = GeneratorCountryZoneModalType.None;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationAccessCountryZonesRequest.fulfilled, (state, action) => {
        state.countryZones = action.payload.countryZones.sort((x, y) => (x.countryName > y.countryName ? 1 : -1));
      })
      .addCase(createApplicationAccessCountryZoneRequest.fulfilled, (state, action) => {
        countryZoneUtils.updateZone(state.countryZones, action.payload.countryZone, state.selectedUuid);
        state.selectedUuid = '';
      })
      .addCase(updateApplicationAccessCountryZoneRequest.fulfilled, (state, action) => {
        countryZoneUtils.updateZone(state.countryZones, action.payload.countryZone);
        state.selectedUuid = '';
      })
      .addCase(deleteApplicationAccessCountryZoneRequest.fulfilled, (state, action) => {
        countryZoneUtils.deleteZone(state.countryZones, action.payload);
        state.selectedUuid = '';
      })
      .addCase(checkApplicationAccessConnectionRequest.fulfilled, (state, action) => {
        state.connectionStatus = action.payload?.status;
      });
  },
});

const { actions, reducer } = applicationAccessProductsSlice;

export const applicationAccessCountryZonesActions = actions;
export const applicationAccessReducer = reducer;
export const applicationAccessCountryZonesAsyncActions = {
  getApplicationAccessCountryZonesRequest: getApplicationAccessCountryZonesRequest,
  createApplicationAccessCountryZoneRequest: createApplicationAccessCountryZoneRequest,
  updateApplicationAccessCountryZoneRequest: updateApplicationAccessCountryZoneRequest,
  deleteApplicationAccessCountryZoneRequest: deleteApplicationAccessCountryZoneRequest,
  checkApplicationAccessConnectionRequest: checkApplicationAccessConnectionRequest,
};
