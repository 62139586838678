import { UserWarehouseTemplatesApi } from 'src/Api/UserWarehouse/UserWarehouseTemplates/UserWarehouseTemplatesApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetUserWarehouseTemplatesResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/GetUserWarehouseTemplatesResponse';
import { CreateUserWarehouseTemplateResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/CreateUserWarehouseTemplateResponse';
import { CreateUserWarehouseTemplateRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/CreateUserWarehouseTemplateRequest';
import { UpdateUserWarehouseTemplateResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/UpdateUserWarehouseTemplateResponse';
import { UpdateUserWarehouseTemplateRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/UpdateUserWarehouseTemplateRequest';
import { DeleteUserWarehouseTemplateResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/DeleteUserWarehouseTemplateResponse';
import { DeleteUserWarehouseTemplateRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/DeleteUserWarehouseTemplateRequest';
import { CreateUserWarehouseTemplateItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/CreateUserWarehouseTemplateItemRequest';
import { CreateUserWarehouseTemplateItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/CreateUserWarehouseTemplateItemResponse';
import { UpdateUserWarehouseTemplateItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/UpdateUserWarehouseTemplateItemResponse';
import { UpdateUserWarehouseTemplateItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/UpdateUserWarehouseTemplateItemRequest';
import { DeleteUserWarehouseTemplateItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/DeleteUserWarehouseTemplateItemResponse';
import { DeleteUserWarehouseTemplateItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/DeleteUserWarehouseTemplateItemRequest';
import { GetUserWarehouseTemplateItemsResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/GetUserWarehouseTemplateItemsResponse';

const getUserWarehouseTemplates = createAsyncThunk<GetUserWarehouseTemplatesResponse, void>(
  'user-warehouse-templates/get',
  () => {
    return UserWarehouseTemplatesApi.getUserWarehouseTemplates();
  },
);

const createUserWarehouseTemplate = createAsyncThunk<
  CreateUserWarehouseTemplateResponse,
  CreateUserWarehouseTemplateRequest
>('user-warehouse-templates/create', (request) => {
  return UserWarehouseTemplatesApi.createUserWarehouseTemplate(request);
});

const updateUserWarehouseTemplate = createAsyncThunk<
  UpdateUserWarehouseTemplateResponse,
  UpdateUserWarehouseTemplateRequest
>('user-warehouse-templates/update', (request) => {
  return UserWarehouseTemplatesApi.updateUserWarehouseTemplate(request);
});

const deleteUserWarehouseTemplate = createAsyncThunk<
  DeleteUserWarehouseTemplateResponse,
  DeleteUserWarehouseTemplateRequest
>('user-warehouse-templates/delete', (request) => {
  return UserWarehouseTemplatesApi.deleteUserWarehouseTemplate(request);
});

const getUserWarehouseTemplateItems = createAsyncThunk<GetUserWarehouseTemplateItemsResponse, void>(
  'user-warehouse-templates/get-items',
  () => {
    return UserWarehouseTemplatesApi.getUserWarehouseTemplateItems();
  },
);

const createUserWarehouseTemplateItem = createAsyncThunk<
  CreateUserWarehouseTemplateItemResponse,
  CreateUserWarehouseTemplateItemRequest
>('user-warehouse-templates/create-item', (request) => {
  return UserWarehouseTemplatesApi.createUserWarehouseTemplateItem(request);
});

const updateUserWarehouseTemplateItem = createAsyncThunk<
  UpdateUserWarehouseTemplateItemResponse,
  UpdateUserWarehouseTemplateItemRequest
>('user-warehouse-templates/update-item', (request) => {
  return UserWarehouseTemplatesApi.updateUserWarehouseTemplateItem(request);
});

const deleteUserWarehouseTemplateItem = createAsyncThunk<
  DeleteUserWarehouseTemplateItemResponse,
  DeleteUserWarehouseTemplateItemRequest
>('user-warehouse-templates/delete-item', (request) => {
  return UserWarehouseTemplatesApi.deleteUserWarehouseTemplateItem(request);
});

export const userWarehouseTemplateAsyncActions = {
  getUserWarehouseTemplates,
  createUserWarehouseTemplate,
  updateUserWarehouseTemplate,
  deleteUserWarehouseTemplate,

  getUserWarehouseTemplateItems,
  createUserWarehouseTemplateItem,
  updateUserWarehouseTemplateItem,
  deleteUserWarehouseTemplateItem,
};
