import { useLocalization } from 'src/Hooks';
import { useAppRouting } from 'src/Hooks/useAppRouting';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from 'src/Pages/Sales/services';
import { purchasesPageSelectors } from 'src/Pages/Purchases/services';
import { globalSelectors } from 'src/Redux/Global/selectors';
import { useMemo } from 'react';
import { localizationUtils } from 'src/Utils/localizationUtils';
import { FieldState } from 'src/Types';
import { orderTableRowUtils } from 'src/Types/OrderTableRowUtils';

export const useCancelInvoiceTooltipMessage = (): FieldState => {
  const {
    models: {
      OrderTableRow: { CancelInvoiceNumber, parts, CommonErrors },
    },
  } = useLocalization();
  const { isSalesPage, isPurchasesPage } = useAppRouting();
  const selectedSales = useSelector(salesPageSelectors.selectedSales);
  const selectedPurchases = useSelector(purchasesPageSelectors.selectedPurchases);
  const localization = useSelector(globalSelectors.localization);

  return useMemo(() => {
    const orders = isSalesPage ? selectedSales : selectedPurchases;

    const result = orders.reduce(
      (res, current) => {
        if (current.currentCancelInvoiceDate) {
          res.withCancelInvoiceNumbers.add(current.currentCancelInvoiceNumber ?? '');
        }

        if (orderTableRowUtils.isOrderIsAmazonPending(current)) {
          res.amazonInProcessOrders.add(current.userOrderId.toString());
        }
        return res;
      },
      {
        withCancelInvoiceNumbers: new Set<string>(),
        amazonInProcessOrders: new Set<string>(),
      },
    );

    const withCancelInvoiceNumbers = [...result.withCancelInvoiceNumbers];
    const amazonInProcessOrders = [...result.amazonInProcessOrders];

    const isEverySalesWithInvoices = selectedSales.every((sale) => sale.invoices?.length);

    let disabledDocumentMessage: string | undefined = undefined;

    if (withCancelInvoiceNumbers.length) {
      disabledDocumentMessage = localizationUtils.insertTranslatedParamsToString(
        CancelInvoiceNumber.DisableHeaderDocumentMessage,
        [withCancelInvoiceNumbers.join(', '), withCancelInvoiceNumbers.length],
        parts,
        localization,
      );
    } else if (!isEverySalesWithInvoices) {
      disabledDocumentMessage = CancelInvoiceNumber.NoInvoicesCreatedYetMessage;
    } else if (amazonInProcessOrders.length) {
      disabledDocumentMessage = CommonErrors.AmazonOrderPending.DisableHeaderDocumentMessage;
    } // if

    return {
      value: '',
      isDocumentIconDisabled: Boolean(disabledDocumentMessage) || !isEverySalesWithInvoices || isPurchasesPage,
      disabledDocumentMessage,
    };
  }, [localization, selectedSales, selectedPurchases, isSalesPage, isPurchasesPage]);
};
