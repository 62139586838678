import { useCallback } from 'react';
import { CookieName } from '../../../Enums/CookieName';
import { cookieActions } from '../services/reducer';
import { cookieUtils } from '../../../Utils/cookieUtils';
import { useAppDispatch } from '../../../Redux/ConfigureStore';

export const useCookieModalActions = () => {
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    cookieUtils.setCookie(CookieName.CookieAgreement, 'true', 360);
    dispatch(cookieActions.setModalVisible(false));
  }, []);

  return { onClose };
};
