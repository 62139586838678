import { TranslationShortModel } from '@/Models/TranslationShortModel';
import { SimpleObject } from '@/Types';

function shortModelsToType<T extends object>(translations: TranslationShortModel[]): T {
  const translationType: SimpleObject = {};

  translations.forEach((t) => {
    if (t.childTranslations.length > 0) {
      translationType[t.key] = childenToObjectRecursively(t.childTranslations);
    } else {
      translationType[t.key] = t.translation ?? '';
    } // if
  });

  return translationType as T;
} // shortModelsToType

function childenToObjectRecursively(translations: TranslationShortModel[]): SimpleObject {
  const translationType: SimpleObject = {};
  translations.forEach((t) => {
    if (t.childTranslations.length > 0) {
      translationType[t.key] = childenToObjectRecursively(t.childTranslations);
    } else {
      translationType[t.key] = t.translation ?? '';
    } // if
  });

  return translationType;
} // childenToObjectRecursively

export const translationMapping = {
  shortModelsToType,
};
