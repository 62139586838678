import React from 'react';
import { Images } from '@/Static';
import { useLocalization } from '@/Hooks';
import { useLocation } from 'react-router';
import { PagesRouting } from '@/Routing';
import styles from './WithAuthorizationModules.scss';
import { AuthorizationHeader } from '@/Components/Header/AuthorizationHeader';
import { AuthorizationFooter } from '@/Components/Footer/AuthorizationFooter';
import { Icon } from '@/Components/Icon/Icon';
import { ToastContainerWrapper } from '@/Components/Toast/Toast';
import { CookieModal } from '@/ModalWindows/CookieModal/CookieModal';
import { useCheckCookie } from '@/Hooks/useCheckCookie';

export function withAuthorizationModules<Props extends object>(Component: React.FC<Props>): React.FC<Props> {
  return function WithAuthorizationModulesComponent(props: Props) {
    const {
      authorizationPage: { Titles },
    } = useLocalization();

    const { pathname: route } = useLocation();
    useCheckCookie();

    const getTitle = () => {
      switch (route) {
        case PagesRouting.AuthorizationPages.LoginPage:
          return Titles.Comeback;
        case PagesRouting.AuthorizationPages.RegistrationPage:
          return Titles.Welcome;

        case PagesRouting.AuthorizationPages.EmailConfirmationPage:
          return Titles.ConfirmEmail;

        case PagesRouting.AuthorizationPages.ResetPasswordPage:
          return Titles.ResetPassword;

        case PagesRouting.AuthorizationPages.ChangePasswordPage:
          return Titles.ChangePassword;
      }
    };

    return (
      <>
        <ToastContainerWrapper />

        <div className={styles.authorization}>
          <AuthorizationHeader />

          <main className={styles.authorizationBody}>
            <div className={styles.authorizationBodyTitle}>
              <Icon alt={'logo'} src={Images.Frog} />
              <h2>{getTitle()}</h2>
            </div>

            <Component {...props} />
          </main>

          <CookieModal />
          <AuthorizationFooter />
        </div>
      </>
    );
  };
}
