import { DocumentCategoryModel } from '@/Models/_Microservices/OnlineArchive/DocumentCategoryModel';
import { ApiRoutingUtils } from '@/Routing';
import {
  CreateDocumentCategoryRequest,
  CreateDocumentCategoryResponse,
  UpdateDocumentCategoryRequest,
  UpdateDocumentCategoryResponse,
} from './';
import { rest } from '@/Api/Rest';

const url = ApiRoutingUtils.createOnlineArchiveUrl('document-categories');

function getDocumentCategories(): Promise<DocumentCategoryModel[]> {
  return rest.get(url);
}

function createDocumentCategory(request: CreateDocumentCategoryRequest): Promise<CreateDocumentCategoryResponse> {
  return rest.post(url, request);
}

function updateDocumentCategory(request: UpdateDocumentCategoryRequest): Promise<UpdateDocumentCategoryResponse> {
  return rest.put(url, request);
}

function deleteDocumentCategory(id: number): Promise<number> {
  return rest.delete(`${url}/${id}`, {});
}

export const DocumentCategoriesApi = {
  getDocumentCategories,
  createDocumentCategory,
  updateDocumentCategory,
  deleteDocumentCategory,
};
