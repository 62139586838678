import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateGenPackageTypeRequest,
  CreateGenPackageTypeResponse,
  UpdateGenPackageTypeRequest,
  UpdateGenPackageTypeResponse,
  GenPackageTypesApi,
} from '@/Api/GeneratorLogisticTypes/GenPackageTypes';

const getPackageTypes = createAsyncThunk('gen-package-types/get-types', async () => {
  return GenPackageTypesApi.getPackageTypes();
});

const createPackageType = createAsyncThunk<CreateGenPackageTypeResponse, CreateGenPackageTypeRequest>(
  'gen-package-types/create',
  async (request: CreateGenPackageTypeRequest) => {
    return GenPackageTypesApi.createPackageType(request);
  },
);

const updatePackageType = createAsyncThunk<UpdateGenPackageTypeResponse, UpdateGenPackageTypeRequest>(
  'gen-package-types/update',
  async (request: UpdateGenPackageTypeRequest) => {
    return GenPackageTypesApi.updatePackageType(request);
  },
);

const deletePackageType = createAsyncThunk<number, number>('gen-package-types/delete', async (id: number) => {
  return GenPackageTypesApi.deletePackageType(id);
});

export const asyncActions = {
  getPackageTypes,
  createPackageType,
  updatePackageType,
  deletePackageType,
};
