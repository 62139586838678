import { useLocalization } from '@/Hooks';
import { OrderLabelStatus } from '@/Enums/OrderLabelStatus';

export const useOrderLabelStatusOptions = () => {
  const {
    enums: { OrderLabelStatus: OrderLabelStatusLocalization },
  } = useLocalization();

  return [
    { key: OrderLabelStatus.All, value: OrderLabelStatusLocalization.All },
    { key: OrderLabelStatus.New, value: OrderLabelStatusLocalization.New },
    { key: OrderLabelStatus.NotPrinted, value: OrderLabelStatusLocalization.NotPrinted },
    { key: OrderLabelStatus.Printed, value: OrderLabelStatusLocalization.Printed },
  ];
};
