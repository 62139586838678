import { ApiRoutingUtils } from '@/Routing';
import { rest } from '../Rest';
import { ChangeUserPasswordRequest } from './ChangeUserPasswordRequest';
import { ChangeUserPasswordResponse } from './ChangeUserPasswordResponse';
import { GetUserAccountInfoResponse } from './GetUserAccountInfoResponse';
import { UpdateUserAccountFieldRequest } from './UpdateUserAccountFieldRequest';
import { UpdateUserAccountFieldResponse } from './UpdateUserAccountFieldResponse';

const url = ApiRoutingUtils.createUrl('user-account');

async function getUserAccountInfo(): Promise<GetUserAccountInfoResponse> {
  return rest.get(url);
}

async function changeUserPassword(request: ChangeUserPasswordRequest): Promise<ChangeUserPasswordResponse> {
  return rest.post(`${url}/change-password`, request);
}

async function updateUserAccountInfoField(
  request: UpdateUserAccountFieldRequest,
): Promise<UpdateUserAccountFieldResponse> {
  return rest.post(url, request);
}

export const UserAccountApi = {
  getUserAccountInfo,
  changeUserPassword,
  updateUserAccountInfoField,
};
