import { TransferReportModel } from '@/Models';
import { TransferReportTableRow } from '@/Types/TransferReportTableRow';

const modelToTableRow = (model: TransferReportModel): TransferReportTableRow => {
  return {
    id: model.id,
    reportNumber: model.reportNumber,
    fileName: model.fileName,
    createdTime: model.createdTime,
  };
};

export const productReportMapping = {
  modelToTableRow,
};
