import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from '@/Hooks/useFormWrapper';
import { PagesRouting } from '@/Routing';
import { withAuthorizationModules } from '@/Hocs';
import { useLocalization } from '@/Hooks';
import { userActionsAsync } from '@/Redux/User';
import { Link } from '@/Components/Controls/Link/Link';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { Captcha } from '@/Components/Controls/Captcha/Captcha';
import { SignInput } from '@/Components/Controls/SignInputs/SignInput';
import styles from './SignIn.scss';

type FormData = {
  login: string;
  password: string;
};

function SignInPageComponent(): React.ReactElement {
  const dispatch = useDispatch();
  const {
    authorizationPage: { SignIn, Buttons },
  } = useLocalization();

  const { register, handleSubmit } = useForm<FormData>();

  const [reCatpchaToken, setReCaptchaToken] = useState<string | null>(null);

  // Default login
  const onClick = ({ login, password }: FormData) => {
    dispatch(userActionsAsync.loginRequest({ login, password }));
    setReCaptchaToken(null);
  };

  return (
    <div className={styles.signIn}>
      <div className={styles.signInTitle}>
        <h3>{SignIn.PleaseLogInToShipCheaperErpSystem}</h3>
        <small>{SignIn.Or}</small>
        <Link to={PagesRouting.AuthorizationPages.RegistrationPage}>
          <Button text={Buttons.SignUp} theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} />
        </Link>
      </div>

      <form onSubmit={handleSubmit(onClick)} className={styles.signInForm}>
        <SignInput<FormData> register={register} name="login" placeholder={SignIn.Email} />
        <SignInput<FormData>
          register={register}
          name="password"
          placeholder={SignIn.Password}
          type="password"
          needShowCheckbox
        />
        {!IS_DEVELOPMENT && <Captcha token={reCatpchaToken} onChange={setReCaptchaToken} />}

        <Button
          type="submit"
          text={Buttons.Login}
          theme={BUTTON_THEMES.SUCCESS}
          className={styles.loginButton}
          disabled={!IS_DEVELOPMENT && !reCatpchaToken}
        />

        <Link to={PagesRouting.AuthorizationPages.ResetPasswordPage} className={styles.resetPasswordLink}>
          {SignIn.ForgorYourPassword}
        </Link>
      </form>
    </div>
  );
}

export const SignInPage = withAuthorizationModules(SignInPageComponent);
