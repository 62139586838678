import { OrderType, OrderMarker } from '@/Enums';
import { useOrderMarkerOptions } from '@/Enums/OrderMarkerHooks';
import { useRouting } from '@/Hooks';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { PagesRouting } from '@/Routing';
import { useSelector } from 'react-redux';

export const useChangeOtherStatusesOptions = () => {
  const { currentPage } = useRouting();

  const isSalesPage = currentPage === PagesRouting.MainPages.SalesPage.Sales;

  const currentOrderType = isSalesPage ? OrderType.Sale : OrderType.Purchase;
  const { options } = useOrderMarkerOptions(currentOrderType, [
    OrderMarker.Paid,
    OrderMarker.NotPaid,
    OrderMarker.Shipped,
    OrderMarker.NotShipped,
  ]);
  const selectedOrders = useSelector(
    isSalesPage ? salesPageSelectors.selectedSales : purchasesPageSelectors.selectedPurchases,
  );

  return {
    isSalesPage,
    selectedOrders,
    options,
  };
};
