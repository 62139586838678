import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { GetLastSynchDateResponse } from '@/Api/BackgroundTasks/Responses/GetLastSynchDateResponse';

const url = ApiRoutingUtils.createUrl('background-task');

async function getLastSynchDate(): Promise<GetLastSynchDateResponse> {
  return rest.get(`${url}/get-last-synch-date`);
}

export const BackgroundTasksApi = {
  getLastSynchDate,
};
