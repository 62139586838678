import { useMemo, useState } from 'react';
import { TreeItemType } from '@/Components/TreeView/TreeView';
import { useSelector } from 'react-redux';
import { generatorCountryZoneSelectors } from '@/Pages/Administration/LogisticProducts/modules/GeneratorCountryZones/services';
import { logisticTypesDataSelectors } from '@/Redux/Logistic/selector';

export function useCountryCodesZoneLevelTreeViewOptions(
  countryZoneId: string | number,
  defaultValues?: (string | number)[],
  excludeValues?: (string | number)[],
) {
  const countryZone = useSelector(generatorCountryZoneSelectors.countryZoneById(countryZoneId));
  const all = useSelector(logisticTypesDataSelectors.countryCodesTreeViewOptionsMemo);

  const [needToReset, setNeedToReset] = useState(false);

  const defaultIds = defaultValues;

  const countryCodesTreeViewItems = useMemo<TreeItemType[]>(() => {
    setNeedToReset(false);

    let countryCodeItems = all.filter((x) => countryZone?.countriesRecipient.some((y) => y === x.key));

    if (excludeValues) {
      countryCodeItems = countryCodeItems.filter((item) => !excludeValues.includes(item.key));
    }

    return countryCodeItems.map((item) =>
      defaultIds?.includes(item.key) ? { ...item, selected: true } : { ...item, selected: false },
    );
  }, [countryZone, needToReset, defaultValues, excludeValues]);

  return {
    countryCodesTreeViewItems,
    setNeedToReset,
  };
}
