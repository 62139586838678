import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentTemplateRow, Nullable } from '@/Types';
import { AutomaticallyGeneratedDocumentType } from '@/Enums/AutomaticallyGeneratedDocumentType';
import { asyncActions } from './asyncActions';
import { DocumentForPrintModel } from '@/Models/DocumentForPrintModel';

export type SetCountCopy = {
  guid: string;
  count: number;
};

export type SetIsSortWhenPrinting = {
  guid: string;
  isSort: boolean;
};

export type DocumentPrintModalState = {
  selectedRow: Nullable<DocumentTemplateRow>;
  rows: DocumentTemplateRow[];
  documentNumbers: DocumentForPrintModel[];
  selectedDocumentNumbers: DocumentForPrintModel[];
};

const initialState: DocumentPrintModalState = {
  selectedRow: null,
  rows: [],
  documentNumbers: [],
  selectedDocumentNumbers: [],
};

const documentPrintSlice = createSlice({
  name: 'documentPrintModalSlice',
  initialState,
  reducers: {
    setSelectedTemplate(state, action: PayloadAction<DocumentTemplateRow>) {
      state.selectedRow = action.payload;
    },
    setSelectedDocumentNumbers(state, action: PayloadAction<DocumentForPrintModel[]>) {
      state.selectedDocumentNumbers = action.payload;
    },
    setCountCopy(state, action: PayloadAction<SetCountCopy>) {
      const row = state.rows.find((x) => x.guid === action.payload.guid);
      if (row) {
        row.countCopy = action.payload.count;
      }

      if (state.selectedRow?.guid === action.payload.guid) {
        state.selectedRow.countCopy = action.payload.count;
      }
    },
    setIsSortWhenPrinting(state, action: PayloadAction<SetIsSortWhenPrinting>) {
      const row = state.rows.find((x) => x.guid == action.payload.guid);
      if (row) {
        row.isSortWhenPrinting = action.payload.isSort;
      }

      if (state.selectedRow?.guid === action.payload.guid) {
        state.selectedRow.isSortWhenPrinting = action.payload.isSort;
      }
    },
    setGenerateNumberCountCopy(state, action: PayloadAction<SetCountCopy>) {
      const row = state.rows.find((x) => x.guid === action.payload.guid);
      if (row) {
        row.generateNumberCountCopy = action.payload.count;
      }
    },
    setGenerateNumberSortWhenPrinting(state, action: PayloadAction<SetIsSortWhenPrinting>) {
      const row = state.rows.find((x) => x.guid == action.payload.guid);
      if (row) {
        row.generateNumberSortWhenPrinting = action.payload.isSort;
      }
    },
    setDocumentNumberCountCopy(state, action: PayloadAction<SetCountCopy>) {
      const row = state.documentNumbers.find((x) => x.documentNumber === action.payload.guid);
      const selectedRow = state.selectedDocumentNumbers.find((x) => x.documentNumber === action.payload.guid);

      if (row) {
        row.countCopy = action.payload.count;
      }

      if (selectedRow) {
        selectedRow.countCopy = action.payload.count;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getTemplatesRequest.fulfilled, (state, action) => {
      state.rows = action.payload.templates.map((x) => {
        return {
          guid: x.guid,
          name: x.name,
          countCopy: x.documentTemplateSettings.countCopy,
          isSortWhenPrinting: x.documentTemplateSettings.isSortWhenPrinting,
          isSaveWhenPrinting: x.documentTemplateSettings.isSaveWhenPrinting,
          pathSaveWhenPrinting: x.documentTemplateSettings.directoryPath,
          isDefaultForDeliveryNote: x.automaticallyGeneratedDocumentType === AutomaticallyGeneratedDocumentType.PL,
          isDefaultForSale: x.automaticallyGeneratedDocumentType === AutomaticallyGeneratedDocumentType.SORD,
          isDefaultForPurchase: x.automaticallyGeneratedDocumentType === AutomaticallyGeneratedDocumentType.SORD,
          isDefaultForInvoice: x.automaticallyGeneratedDocumentType === AutomaticallyGeneratedDocumentType.INV,
          isDefaultForCancelInvoice: x.automaticallyGeneratedDocumentType === AutomaticallyGeneratedDocumentType.CINV,
          generateNumberCountCopy: x.generatingNumberSettings?.countCopy,
          generateNumberSortWhenPrinting: x.generatingNumberSettings?.isSortWhenPrinting,
        };
      });
    });

    builder.addCase(asyncActions.getDocumentsForPrint.fulfilled, (state, action) => {
      state.documentNumbers = action.payload.models.map((item) => ({
        ...item,
        countCopy: 1,
      }));
    });
  },
});

const { actions, reducer } = documentPrintSlice;

export const documentPrintModalReducer = reducer;
export const documentPrintModalActions = actions;
export const documentPrintModalAsyncActions = asyncActions;
