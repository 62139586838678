import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateGenSubProductTypeRequest,
  CreateGenSubProductTypeResponse,
  UpdateGenSubProductTypeRequest,
  UpdateGenSubProductTypeResponse,
  GenSubProductTypesApi,
} from '@/Api/GeneratorLogisticTypes/GenSubProductTypes';

const getSubProductTypes = createAsyncThunk('gen-sub-product-types/get-types', async () => {
  return GenSubProductTypesApi.getSubProductTypes();
});

const createSubProductType = createAsyncThunk<CreateGenSubProductTypeResponse, CreateGenSubProductTypeRequest>(
  'gen-sub-product-types/create',
  async (request: CreateGenSubProductTypeRequest) => {
    return GenSubProductTypesApi.createSubProductType(request);
  },
);

const updateSubProductType = createAsyncThunk<UpdateGenSubProductTypeResponse, UpdateGenSubProductTypeRequest>(
  'gen-sub-product-types/update',
  async (request: UpdateGenSubProductTypeRequest) => {
    return GenSubProductTypesApi.updateSubProductType(request);
  },
);

const deleteSubProductType = createAsyncThunk<number, number>('gen-sub-product-types/delete', async (id: number) => {
  return GenSubProductTypesApi.deleteSubProductType(id);
});

export const asyncActions = {
  getSubProductTypes,
  createSubProductType,
  updateSubProductType,
  deleteSubProductType,
};
