import { MpFeedbacksApi } from '@/Api/MpFeedback';
import { CreateFeedbackSettingRequest } from '@/Api/MpFeedback/CreateFeedbackSettingRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateUpdateFeedbackSettingResponse } from '@/Api/MpFeedback/CreateUpdateFeedbackSettingResponse';

const getFeedbackSettings = createAsyncThunk('feedbackSettings/get-feedback-settings', async () => {
  return MpFeedbacksApi.getFeedbacks();
});

const createFeedbackSettings = createAsyncThunk(
  'feedbackSettings/create-feedback-settings',
  async (request: CreateFeedbackSettingRequest) => {
    return MpFeedbacksApi.createFeedbacks(request);
  },
);

const updateFeedbackSettings = createAsyncThunk<CreateFeedbackSettingRequest, CreateUpdateFeedbackSettingResponse>(
  'feedbackSettings/update-feedback-settings',
  async (request: CreateUpdateFeedbackSettingResponse) => {
    return MpFeedbacksApi.updateFeedbacks(request);
  },
);

export const asyncActions = {
  getFeedbackSettings,
  createFeedbackSettings,
  updateFeedbackSettings,
};
