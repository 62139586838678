import { ApiRoutingUtils } from 'src/Routing';
import { rest } from 'src/Api/Rest';
import { GetUserWarehouseTemplatesResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/GetUserWarehouseTemplatesResponse';
import { CreateUserWarehouseTemplateItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/CreateUserWarehouseTemplateItemRequest';
import { CreateUserWarehouseTemplateItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/CreateUserWarehouseTemplateItemResponse';
import { CreateUserWarehouseTemplateRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/CreateUserWarehouseTemplateRequest';
import { CreateUserWarehouseTemplateResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/CreateUserWarehouseTemplateResponse';
import { UpdateUserWarehouseTemplateResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/UpdateUserWarehouseTemplateResponse';
import { DeleteUserWarehouseTemplateRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/DeleteUserWarehouseTemplateRequest';
import { DeleteUserWarehouseTemplateResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/DeleteUserWarehouseTemplateResponse';
import { GetUserWarehouseTemplateItemsResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/GetUserWarehouseTemplateItemsResponse';
import { UpdateUserWarehouseTemplateItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/UpdateUserWarehouseTemplateItemRequest';
import { UpdateUserWarehouseTemplateItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/UpdateUserWarehouseTemplateItemResponse';
import { DeleteUserWarehouseTemplateItemRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/DeleteUserWarehouseTemplateItemRequest';
import { DeleteUserWarehouseTemplateItemResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/DeleteUserWarehouseTemplateItemResponse';
import { UpdateUserWarehouseTemplateRequest } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Requests/UpdateUserWarehouseTemplateRequest';
import { GetWarehouseTemplatesInfoResponse } from 'src/Api/UserWarehouse/UserWarehouseTemplates/Responses/GetWarehouseTemplatesInfoResponse';

const url = ApiRoutingUtils.createUrl('user-warehouse-templates');

async function getUserWarehouseTemplates(): Promise<GetUserWarehouseTemplatesResponse> {
  return rest.get<GetUserWarehouseTemplatesResponse>(url);
}

async function createUserWarehouseTemplate(
  request: CreateUserWarehouseTemplateRequest,
): Promise<CreateUserWarehouseTemplateResponse> {
  return rest.post(url, request);
}

async function updateUserWarehouseTemplate(
  request: UpdateUserWarehouseTemplateRequest,
): Promise<UpdateUserWarehouseTemplateResponse> {
  return rest.put(url, request);
}

async function deleteUserWarehouseTemplate(
  request: DeleteUserWarehouseTemplateRequest,
): Promise<DeleteUserWarehouseTemplateResponse> {
  return rest.delete(`${url}/${request.templateId}`, null);
}

async function getUserWarehouseTemplateItems(): Promise<GetUserWarehouseTemplateItemsResponse> {
  return rest.get<GetUserWarehouseTemplateItemsResponse>(`${url}/items`);
}

async function createUserWarehouseTemplateItem(
  request: CreateUserWarehouseTemplateItemRequest,
): Promise<CreateUserWarehouseTemplateItemResponse> {
  return rest.post(`${url}/item`, request);
}

async function updateUserWarehouseTemplateItem(
  request: UpdateUserWarehouseTemplateItemRequest,
): Promise<UpdateUserWarehouseTemplateItemResponse> {
  return rest.put(`${url}/item`, request);
}

async function deleteUserWarehouseTemplateItem(
  request: DeleteUserWarehouseTemplateItemRequest,
): Promise<DeleteUserWarehouseTemplateItemResponse> {
  return rest.delete(`${url}/item/${request.id}`, null);
}

async function getWarehouseTemplatesInfo(): Promise<GetWarehouseTemplatesInfoResponse> {
  return rest.get<GetWarehouseTemplatesInfoResponse>(`${url}/templates`);
}
export const UserWarehouseTemplatesApi = {
  getUserWarehouseTemplates,
  createUserWarehouseTemplate,
  updateUserWarehouseTemplate,
  deleteUserWarehouseTemplate,
  getWarehouseTemplatesInfo,

  getUserWarehouseTemplateItems,
  createUserWarehouseTemplateItem,
  updateUserWarehouseTemplateItem,
  deleteUserWarehouseTemplateItem,
};
