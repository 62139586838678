import { useState } from 'react';

type DropDownItem = {
  level: number;
  index: number;
};

export const useDropDownContent = (initialItem: DropDownItem) => {
  const [activeItem, setActiveItem] = useState<DropDownItem>(initialItem);

  return {
    activeItem,
    setActiveItem,
    isItemActive: (lvl: number, idx: number) => {
      return lvl === activeItem.level && idx === activeItem.index;
    },
  };
};
