import { WINDOW_MODAL_TYPE } from '@/ModalWindows/services';
import { useDispatch, useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { documentPrintModalSelectors } from '@/ModalWindows/DocumentPrintModal/services/selectors';
import { useEffect, useMemo } from 'react';
import {
  documentPrintModalActions,
  documentPrintModalAsyncActions,
} from '@/ModalWindows/DocumentPrintModal/services/reducer';
import { Nullable } from '@/Types';
import { NumberGenerationType } from '@/Enums';
import { salesUtils } from '@/Pages/Sales/services/utils';
import { useDocumentPrintOrderTypeOptions } from '@/Enums/DocumentPrintOrderTypeHooks';

export function useDocumentPrintModalOptions() {
  const dispatch = useDispatch();

  const documentPrintType = WINDOW_MODAL_TYPE[location.pathname];
  const selectedSales = useSelector(salesPageSelectors.selectedSales);
  const selectedSalesIds = useSelector(salesPageSelectors.selectedSalesIds);
  const documentTemplates = useSelector(documentPrintModalSelectors.rows);
  const selectedTemplate = useSelector(documentPrintModalSelectors.selectedRow);
  const orderTypeOptions = useDocumentPrintOrderTypeOptions();

  const { numberGenerationType, documentNumbers } = useMemo((): {
    numberGenerationType: Nullable<NumberGenerationType>;
    documentNumbers: { orderId: number; numbers: string[] }[];
  } => {
    let numberGenerationType: Nullable<NumberGenerationType> = null;
    let documentNumbers: { orderId: number; numbers: string[] }[] = [];

    if (selectedTemplate?.isDefaultForSale) {
      numberGenerationType = NumberGenerationType.SaleOrder;
      documentNumbers = selectedSales.map((order) => ({
        numbers: order.orderNumber ? [order.orderNumber] : [],
        orderId: order.id,
      }));
    }

    if (selectedTemplate?.isDefaultForDeliveryNote) {
      numberGenerationType = NumberGenerationType.PackList;
      documentNumbers = selectedSales.map((order) => ({
        numbers: order.deliveryNoteNumber ? [order.deliveryNoteNumber] : [],
        orderId: order.id,
      }));
    }

    if (selectedTemplate?.isDefaultForInvoice) {
      numberGenerationType = NumberGenerationType.Invoice;
      documentNumbers = selectedSales.map((order) => ({
        numbers: salesUtils.getInvoiceNumbers(order),
        orderId: order.id,
      }));
    }

    if (selectedTemplate?.isDefaultForCancelInvoice) {
      numberGenerationType = NumberGenerationType.CancelInvoice;
      documentNumbers = selectedSales.map((order) => ({
        numbers: salesUtils.getCancelInvoiceNumbers(order),
        orderId: order.id,
      }));
    }

    return { documentNumbers, numberGenerationType };
  }, [selectedTemplate, selectedSales]);

  useEffect(() => {
    if (!documentTemplates.length) {
      dispatch(documentPrintModalAsyncActions.getTemplatesRequest(documentPrintType));
    }
  }, []);

  useEffect(() => {
    const documentNumbersCount = documentNumbers[0]?.numbers?.length;

    if (selectedSales.length == 1 && documentNumbersCount == 1) {
      dispatch(
        documentPrintModalActions.setSelectedDocumentNumbers([
          { documentNumber: documentNumbers[0].numbers[0], countCopy: 1 },
        ]),
      );
    }
  }, [documentNumbers]);

  return {
    orderTypeOptions,
    selectedTemplate,
    selectedSales,
    selectedSalesIds,
    documentTemplates,
    documentPrintType,
    numberGenerationType,
    documentNumbers,
  };
}
